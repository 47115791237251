import React from "react";

export interface Listenable {
  addEventListener?: (eventName: string, listener: EventListener) => void;
  removeEventListener?: (eventName: string, listener: EventListener) => void;
}

const useListener = (
  elementRef: React.RefObject<Listenable | undefined>,
  eventName: string,
  listener: EventListener
): void => {
  const savedListener = React.useRef<EventListener>();
  React.useEffect(() => {
    savedListener.current = listener;
  }, [listener]);

  React.useEffect(() => {
    const eventListener: EventListener = (event) =>
      savedListener.current?.(event);
    const element = elementRef.current;
    element?.addEventListener?.(eventName, eventListener);
    return () => {
      element?.removeEventListener?.(eventName, eventListener);
    };
  }, [eventName, elementRef.current]);
};

export default useListener;
