/**
 * Parse a date given an ISO string that may or may not contain a timezone
 * @param dateString The date, as a text string, to parse
 * @returns A date object parsed out of the given date string
 */
export const parseDate = (dateString: string): Date => {
  /**
   * Match ISO formats with or without timezone
   */
  const validDateMatcher =
    /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z|:\d{2}(.\d\d\d){0,1}))/;
  /**
   * Match timezone as numeric or zulu
   */
  const timeZoneMatcher = /([+-][0-2]\d:[0-5]\d|Z)/;
  /**
   * Check for any valid ISO string
   */
  const matches = validDateMatcher.exec(dateString);
  if (matches?.length) {
    const date = matches[0];
    // Check specifically if there is already a timestamp on there
    if (timeZoneMatcher.exec(date)?.length) {
      return new Date(date);
    }
    return new Date(`${date}+00:00`);
  }
  // If no matches, return now
  throw new Error("Invalid date supplied");
};

/**
 * Parse & format a date using JS's built-in toLocaleDateString
 * @param dateString The date, as a text string, to parse & format
 * @param options Options to provide to the formatter
 * @returns A formatted date or an empty string if no date is available
 */
export const formatDate = (
  dateString: string | null | undefined,
  options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }
): string => {
  if (!dateString) {
    return "";
  }
  return parseDate(dateString).toLocaleDateString("en-US", options);
};

/**
 * Format a date as a text string relative to some reference time
 * @param dateString The date to start with, in string format.
 * @param relativeLimit The max interval, in milliseconds, that may have elapsed
 * between the dateString and relativeDate before the "relative" formatting is
 * skipped in favor of just pretty-printing the date according to the format
 * provided by the options argument.
 * @param options Options for formatting a distant date string.
 * @param referenceDate The date to compare against. Defaults to now.
 * @returns A string containing the relative date in English.
 */
export const formatRelativeDate = (
  dateString: string | null | undefined,
  relativeLimit: number = MS_PER_DAY * 4,
  options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  },
  referenceDate: Date = new Date()
): string => {
  if (!dateString) {
    return "";
  }
  const date = parseDate(dateString);
  const delta = Math.abs(referenceDate.getTime() - date.getTime());
  if (delta > relativeLimit) {
    return formatDate(dateString, options);
  }
  return relativeDate(date, referenceDate);
};

/**
 * Creates a text representation of the interval between two dates
 * @param date The date
 * @param referenceDate The date to compare against (defaults to now).
 * @returns A string with a relative time period in English.
 */
const relativeDate = (date: Date, referenceDate: Date = new Date()): string => {
  /**
   * Difference between reference date and active date, in seconds
   */
  const d = Math.round((referenceDate.getTime() - date.getTime()) / 1000);

  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = day * 31;

  const delta = Math.abs(d);
  const direction = d >= 0 ? "past" : "future";

  let relative;

  switch (direction) {
    case "past":
      // Format a date in the past
      if (delta === 0) {
        relative = "now";
      } else if (delta < 30) {
        relative = "just now";
      } else if (delta < minute) {
        relative = `${delta} seconds ago`;
      } else if (delta < 2 * minute) {
        relative = "a minute ago";
      } else if (delta < hour) {
        relative = `${Math.floor(delta / minute)} minutes ago`;
      } else if (Math.floor(delta / hour) === 1) {
        relative = "an hour ago";
      } else if (delta < day) {
        const hours = Math.floor(delta / hour);
        if (hours < 5) {
          relative = `${hours} hours ago`;
        } else {
          // Dates are already represented in the local timezone, so just check
          // if the calendar date is the same to see if it was today or
          // yesterday :)
          const refDate = referenceDate.getDate();
          const realDate = date.getDate();
          relative = refDate === realDate ? "earlier today" : "yesterday";
        }
      } else if (delta < day * 2) {
        const refDate = referenceDate.getDate();
        const realDate = date.getDate();
        relative = refDate === realDate + 1 ? "yesterday" : "2 days ago";
      } else if (delta < week) {
        relative = `${Math.floor(delta / day)} days ago`;
      } else if (delta < week * 2) {
        relative = "last week";
      } else {
        relative = `${Math.floor(delta / week)} weeks ago`;
      }
      return relative;

    case "future":
      // Format a date in the future
      if (delta < minute) {
        relative = `in ${delta} seconds`;
      } else if (delta < 2 * minute) {
        relative = "in just a minute";
      } else if (delta < hour) {
        relative = `in ${Math.floor(delta / minute)} minutes`;
      } else if (Math.floor(delta / hour) === 1) {
        relative = "in an hour";
      } else if (delta < day) {
        const refDate = referenceDate.getDate();
        const realDate = date.getDate();
        const hours = Math.floor(delta / hour);
        relative =
          refDate === realDate || hours <= 5 ? `in ${hours} hours` : "tomorrow";
      } else if (delta < day * 2) {
        const refDate = referenceDate.getDate();
        const realDate = date.getDate();
        relative = refDate === realDate - 1 ? "tomorrow" : "in 2 days";
      } else if (delta < week) {
        relative = `on ${date.toLocaleString("en-US", {
          weekday: "long",
        })}`;
      } else if (delta < month) {
        const weeks = Math.floor(delta / week);
        if (weeks === 1) {
          relative = "next week";
        } else {
          relative = `in ${weeks} weeks`;
        }
      } else {
        relative =
          referenceDate.getFullYear() === date.getFullYear()
            ? date.toLocaleString("en-US", {
                weekday: "long",
                month: "short",
                day: "numeric",
              })
            : date.toLocaleString("en-US", {
                weekday: "long",
                month: "short",
                day: "numeric",
                year: "numeric",
              });
      }
      return relative;
  }
};

export const formatTime = (
  dateString: string | null | undefined,
  options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
  }
): string => {
  if (!dateString) {
    return "";
  }
  return parseDate(dateString).toLocaleTimeString("en-US", options);
};

const strPadLeft = (number: number, pad: string, length: number): string => {
  return `${new Array(length + 1).join(pad)}${number}`.slice(-length);
};

const durationToHms = (
  duration: number
): { hours: number; minutes: number; seconds: number } => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration - hours * 3600) / 60);
  const seconds = Math.round(duration - hours * 3600 - minutes * 60);
  return { hours, minutes, seconds };
};

export const formatDurationShort = (duration: number): string => {
  const { hours, minutes, seconds } = durationToHms(duration);
  let result = `${seconds}s`;
  if (minutes || hours) {
    result = `${minutes}m ${result}`;
  }
  if (hours) {
    result = `${hours}hr ${result}`;
  }
  return result;
};

export const formatDuration = (
  duration: number,
  leadingZero = true,
  includeHour = false
): string => {
  const { hours, minutes, seconds } = durationToHms(duration);
  const formattedSeconds = strPadLeft(seconds, "0", 2);
  const formattedMinutes =
    hours > 0 || leadingZero ? strPadLeft(minutes, "0", 2) : minutes;
  if (hours > 0 || includeHour) {
    return `${hours}:${formattedMinutes}:${formattedSeconds}`;
  }
  return `${formattedMinutes}:${formattedSeconds}`;
};

export const formatISODate = (date: Date): string => {
  return date.toISOString().split("T")[0];
};

export const formatDateSeconds = (
  dateSeconds: number | null | undefined
): string => {
  if (!dateSeconds) {
    return "";
  }
  const date = new Date(dateSeconds * 1000);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
};

export const MS_PER_DAY = 1000 * 60 * 60 * 24;
export const MS_PER_HOUR = 1000 * 60 * 60;

export const diffInDays = (a: Date, b: Date): number => {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  return Math.floor((utc2 - utc1) / MS_PER_DAY);
};

export const dateInSeconds = (dateString: string): number => {
  const date = parseDate(dateString);
  return Math.floor(date.getTime() / 1000);
};

export const secondsToDate = (seconds: number): Date =>
  new Date(seconds * 1000);

export const daysAgo = (days: number): Date =>
  new Date(Date.now() - MS_PER_DAY * days);

export const reduceToHours = (date: Date): Date =>
  new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours()
  );

export const reduceToDays = (date: Date): Date =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate());

export const reduceToWeeks = (date: Date): Date => {
  let startOfWeekAdj = 1;
  const days = date.getDate();
  if (days > 21) {
    startOfWeekAdj = 22;
  } else if (days > 14) {
    startOfWeekAdj = 15;
  } else if (days > 7) {
    startOfWeekAdj = 8;
  }
  return new Date(date.getFullYear(), date.getMonth(), startOfWeekAdj);
};

export const reduceToMonths = (date: Date): Date =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate());

export const startDateInSeconds = (date: Date): number => {
  return Math.floor(reduceToDays(date).getTime() / 1000);
};

export const endDateInSeconds = (date: Date): number => {
  return Math.floor((reduceToDays(date).getTime() + MS_PER_DAY) / 1000);
};

export const formatDatetime = (
  dateString: string | null | undefined,
  options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }
): string => {
  if (!dateString) {
    return "";
  }
  return parseDate(dateString).toLocaleDateString("en-US", options);
};

export type DateParams = {
  years?: number;
  months?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
};

export const getOffsetDateAsISOString = (
  options: DateParams = {},
  date: Date = new Date()
): string => {
  date.setUTCFullYear(date.getUTCFullYear() + (options.years ?? 0));
  date.setUTCMonth(date.getUTCMonth() + (options.months ?? 0));
  date.setUTCDate(date.getUTCDate() + (options.days ?? 0));
  date.setUTCHours(date.getUTCHours() + (options.hours ?? 0));
  date.setUTCMinutes(date.getUTCMinutes() + (options.minutes ?? 0));
  date.setUTCSeconds(date.getUTCSeconds() + (options.seconds ?? 0));
  return date.toISOString().substring(0, 19);
};

/**
 * Returns a human-readable duration in hours or minutes
 * @param minutes Number of minutes
 */
export const formatDurationLabel = (minutes: number): string => {
  if (minutes < 60) return `${minutes} min`;
  if (minutes % 60 === 0) return `${minutes / 60} hr`;
  const hours = Math.floor(minutes / 60);
  const mod = (minutes % 60) / 60;
  return `${hours + mod} hr`;
};

/**
 * Returns the abbreviated format for the option label
 * @param tz Timezone option
 */
export const formatTimezoneLabel = (tz?: {
  value?: string;
  abbrev?: string;
}): string => {
  if (tz?.value === "Asia/Kathmandu") return "NPT";
  if (tz?.value === "Asia/Rangoon") return "MYST";
  if (tz?.value === "America/Godthab") return "GNST";
  if (tz?.value === "America/Argentina/Buenos_Aires") return "ART";
  return tz?.abbrev ?? "UTC";
};

/**
 * Returns a new date set to the next given time coefficient
 * @param coefficient in milliseconds - defaults to 30min
 */
export const roundedDate = (
  date = new Date(),
  coefficient: number = 1000 * 60 * 30
): Date => {
  return new Date(Math.ceil(date.getTime() / coefficient) * coefficient);
};

/**
 * Returns the equivalent number of seconds from a hh:mm:ss string like "06:30" or "02:34:12"
 * @param timestamp as a string
 */
export const timestampToSeconds = (timestamp: string): number => {
  try {
    const split = timestamp.split(":").map((str) => parseInt(str));
    return split.length === 2
      ? split[0] * 60 + split[1]
      : split[0] * 3600 + split[1] * 60 + split[2];
  } catch (e) {
    return 0;
  }
};

export const getMostRecentDateString = (datesArray: string[]): string => {
  return datesArray.reduce((a, b) => {
    const aDate = new Date(a);
    const bDate = new Date(b);
    return aDate > bDate ? a : b;
  });
};
