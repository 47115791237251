import { Box } from "@chakra-ui/react";
import React from "react";

interface LoomEmbedProps {
  src: string;
  onClick?(): void;
}

const LoomEmbed: React.FC<LoomEmbedProps> = ({ src, onClick }) => (
  <Box position="relative" pb="56.25%" h="0" onClick={onClick}>
    <iframe
      src={src}
      frameBorder="0"
      // webkitallowfullscreen
      // mozallowfullscreen
      allowFullScreen
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
      }}
    />
  </Box>
);

export default LoomEmbed;
