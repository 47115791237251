import { createIcon } from "@chakra-ui/react";
import React from "react";

export const FilterIcon = createIcon({
  displayName: "FilterIcon",
  viewBox: "0 0 18 18",

  path: [
    <path
      d="M1.5 2.33333C1.5 1.8731 1.8731 1.5 2.33333 1.5H15.6667C16.1269 1.5 16.5 1.8731 16.5 2.33333V4.48816C16.5 4.70917 16.4122 4.92113 16.2559 5.07741L10.9107 10.4226C10.7545 10.5788 10.6667 10.7908 10.6667 11.0118V13.1667L7.33333 16.5V11.0118C7.33333 10.7908 7.24553 10.5788 7.08926 10.4226L1.74408 5.07741C1.5878 4.92113 1.5 4.70917 1.5 4.48816V2.33333Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
