import React, { useEffect, useState } from "react";

const useIntersectionObserver = (
  ref: React.RefObject<HTMLElement>,
  options: IntersectionObserverInit = {}
): boolean => {
  const { threshold = 0, root = null, rootMargin = "0%" } = options;

  const [inView, setInView] = useState<boolean>(false);

  const observer = new IntersectionObserver(
    (entries) => {
      if (entries[0].intersectionRatio > 0) {
        setInView(true);
      } else {
        setInView(false);
      }
    },
    {
      threshold,
      root,
      rootMargin,
    }
  );

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }

    const cleanup = (): void => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
    return cleanup;
  }, [ref.current]);

  return inView;
};

export default useIntersectionObserver;
