import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";

import { RouterLink } from "../../../../components";
import { formatRelativeDate } from "../../../../utils/datetime";
import { sentenceCase } from "../../../../utils/string";
import { Candidate, Position } from "../../../graphql";

type CompletedInterviewsMobileListItemProps = {
  itemName?: string | null;
  displayDate?: any;
  callId: string;
  candidate?: Pick<Candidate, "id" | "fullName"> | null;
  position?: Pick<Position, "id" | "displayTitle"> | null;
  menu?: React.ReactNode;
  footer?: React.ReactNode;
  onClickCandidate?(): void;
  onClickPosition?(): void;
  onClickInterview?(): void;
};

const CompletedInterviewsMobileListItem: React.FC<
  CompletedInterviewsMobileListItemProps
> = ({
  itemName,
  displayDate,
  callId,
  candidate,
  position,
  menu,
  footer,
  onClickCandidate,
  onClickInterview,
  onClickPosition,
}) => {
  return (
    <Flex direction="column" gap="1.5" position="relative">
      <Flex justifyContent="space-between">
        <Heading
          as="h6"
          size="sm"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          lineHeight="6"
          color={candidate ? "blue.600" : "gray.500"}
          fontWeight="semibold"
          mr="2"
        >
          {candidate && (
            <RouterLink
              to={`/candidate/${candidate.id}`}
              color="blue.600"
              fontWeight="inherit"
              onClick={onClickCandidate}
            >
              {candidate.fullName || "Candidate"}
            </RouterLink>
          )}
          {!candidate && "No candidate selected"}
        </Heading>
        <Box mt="-1.5">{menu}</Box>
      </Flex>
      {position && (
        <Box
          fontSize="sm"
          fontWeight="semibold"
          lineHeight="5"
          color={position ? "blue.600" : "gray.500"}
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {position && (
            <RouterLink
              to={`/position/${position.id}`}
              color="blue.600"
              fontWeight="inherit"
              onClick={onClickPosition}
            >
              {position.displayTitle}
            </RouterLink>
          )}
        </Box>
      )}
      <RouterLink
        to={`/interview/${callId}`}
        color="blue.600"
        onClick={onClickInterview}
      >
        <Heading
          as="h6"
          size="xs"
          lineHeight="5"
          wordBreak="break-word"
          textOverflow="ellipsis"
          fontWeight="semibold"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {itemName || "Untitled"}
        </Heading>
      </RouterLink>
      <Text
        fontSize="sm"
        fontWeight="medium"
        color="gray.500"
        lineHeight="5"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
      >
        {sentenceCase(formatRelativeDate(displayDate))}
      </Text>
      {footer && (
        <Flex mt="1.5" gap="2">
          {footer}
        </Flex>
      )}
    </Flex>
  );
};

export default CompletedInterviewsMobileListItem;
