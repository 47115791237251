import React from "react";
import { useLocation } from "react-router-dom";

import useSetPageTitle from "../main/hooks/useSetPageTitle";

type DocumentTitleProps = {
  base?: string;
  children: React.ReactNode;
};

export const DocumentTitle: React.FC<DocumentTitleProps> = ({
  base = "BrightHire",
  children,
}) => {
  const { pathname } = useLocation();

  useSetPageTitle({
    base,
    suffix:
      pathname === "/"
        ? " - Home"
        : pathname.startsWith("/clips")
        ? " - My Clips"
        : pathname.includes("/clip")
        ? " - Clip"
        : pathname.startsWith("/interview")
        ? " - Interview"
        : pathname.startsWith("/playlists")
        ? " - My Playlists"
        : pathname.startsWith("/playlist")
        ? " - Playlist"
        : "",
  });

  return <>{children}</>;
};
