import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";

import { RouterLink } from "../../../../components";
import { sentenceCase } from "../../../../utils/string";
import {
  AddBrightHireToInterviewsMutation,
  InterviewAssistantEntryPoint,
  ScheduledInterviewListItemFragment,
} from "../../../graphql";
import { formatDateline } from "./InterviewCandidatePositionSection";
import RecordingDisabledMessage from "./RecordingDisabledMessage";
import ScheduledInterviewLaunchButton from "./ScheduledInterviewLaunchButton";
import ScheduledInterviewMenu from "./ScheduledInterviewMenu";

type ScheduleInterviewsMobileListItemProps = {
  scheduledInterview: ScheduledInterviewListItemFragment;
  listPosition: number;
  showUpdating?: "updating" | "updateFailed";
  onAddBrightHire?: (results: AddBrightHireToInterviewsMutation) => void;
  onClickCandidate?(): void;
  onClickPosition?(): void;
};

const ScheduleInterviewsMobileListItem: React.FC<
  ScheduleInterviewsMobileListItemProps
> = ({
  scheduledInterview,
  listPosition,
  showUpdating,
  onAddBrightHire,
  onClickCandidate,
  onClickPosition,
}) => {
  const { candidate, position } = scheduledInterview;

  return (
    <Flex direction="column" gap="1" position="relative">
      <Text
        fontSize="sm"
        fontWeight="medium"
        color="gray.500"
        lineHeight="5"
        mr="8"
      >
        {sentenceCase(
          formatDateline(
            scheduledInterview.scheduledStart,
            scheduledInterview.scheduledEnd
          )
        )}
      </Text>
      <ScheduledInterviewMenu
        scheduledInterview={scheduledInterview}
        bgColor="transparent"
        position="absolute"
        right="0"
        top="-1.5"
      />
      <Heading
        as="h6"
        size="sm"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
        lineHeight="6"
        color={candidate ? "blue.600" : "gray.500"}
        fontWeight="semibold"
      >
        {candidate && (
          <RouterLink
            to={`/candidate/${candidate.id}`}
            color="blue.600"
            fontWeight="inherit"
            onClick={onClickCandidate}
          >
            {candidate.fullName || "Candidate"}
          </RouterLink>
        )}
        {!candidate && "No candidate selected"}
      </Heading>
      <Box
        fontSize="sm"
        fontWeight="semibold"
        lineHeight="5"
        color={
          position
            ? "blue.600"
            : scheduledInterview.name
            ? "gray.800"
            : "gray.500"
        }
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
      >
        {position && (
          <RouterLink
            to={`/position/${position.id}`}
            color="blue.600"
            fontWeight="inherit"
            onClick={onClickPosition}
          >
            {position.displayTitle}
          </RouterLink>
        )}
        {!position && (scheduledInterview.name || "No position selected")}
      </Box>
      <RecordingDisabledMessage scheduledInterview={scheduledInterview} />
      {scheduledInterview?.isInterviewer && (
        <ScheduledInterviewLaunchButton
          scheduledInterview={scheduledInterview}
          listPosition={listPosition}
          showUpdating={showUpdating}
          interviewAssistantEntryPoint={InterviewAssistantEntryPoint.HomePage}
          onAddBrightHire={onAddBrightHire}
          size="md"
          w="100%"
          mt="2"
          flex="1"
          fontSize="md"
          fontWeight="medium"
          lineHeight="6"
        />
      )}
    </Flex>
  );
};

export default ScheduleInterviewsMobileListItem;
