export default {
  baseStyle: {
    list: {
      minW: "150px",
    },
    item: {
      _checked: {
        fontWeight: "bold",
      },
      _focus: {
        bg: "white",
      },
      _hover: {
        bg: "gray.50",
      },
    },
  },
  variants: {
    new: {
      list: {
        px: 0,
        py: 2,
        borderRadius: "2px",
      },
      item: {
        fontSize: "sm",
        fontWeight: "medium",
        color: "blue.600",
        px: 4,
        py: 3,
        minHeight: "44px",
        minWidth: "143px",
        _disabled: {
          bg: "white",
          color: "gray.900",
          _hover: { bg: "white", color: "gray.900" },
        },
        _hover: { bg: "blue.100", color: "blue.600" },
        _focus: { bg: "blue.200", color: "blue.600" },
        _active: { bg: "blue.50", color: "blue.600" },
      },
      divider: {
        borderColor: "gray.100",
        my: 0,
      },
    },
    ghost: {
      list: {
        marginTop: "-4px",
      },
      item: {
        paddingTop: "4px",
        paddingBottom: "4px",
      },
    },
    blackAlpha: {
      list: {
        bg: "gray.800",
        opacity: "0.9",
        border: "0",
      },
      item: {
        paddingTop: "4px",
        paddingBottom: "4px",
        color: "white",
        _focus: {
          bg: "gray.800",
          opacity: "0.9",
        },
        _active: {
          bg: "gray.800",
          opacity: "0.9",
        },
        _expanded: {
          bg: "gray.800",
          opacity: "0.8",
        },
        _hover: {
          bg: "gray.800",
          opacity: "0.8",
        },
      },
    },
  },
};
