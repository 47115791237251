import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";

import { Alert } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { email as emailRegex } from "../../../utils/regex";
import { splitName } from "../../../utils/string";
import { CallFragment, useUpdateCallMutation } from "../../graphql";

interface FormData {
  candidateFirstName: string;
  candidateLastName: string;
  email: string;
}

export interface AddCandidateModalProps {
  call: Pick<CallFragment, "id">;
  candidateName?: string;
  isOpen: boolean;
  onClose: () => void;
}

const AddCandidateModal: React.FC<AddCandidateModalProps> = ({
  call,
  candidateName,
  isOpen,
  onClose,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>();
  const [updateCall, { loading, error }] = useUpdateCallMutation({
    onCompleted: (data) => {
      if (data?.updateCall?.call) {
        onClose();
      }
    },
  });
  const sendGAEvent = useSendGAEvent();

  const formRef = useRef<HTMLFormElement>(null);

  const onSubmit = handleSubmit((formValues) => {
    sendGAEvent("update", "candidates");
    updateCall({
      variables: {
        id: call.id,
        candidateFirstName: formValues.candidateFirstName,
        candidateLastName: formValues.candidateLastName,
        candidateEmail: formValues.email,
      },
    });
  });

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (event.key === "Enter" && formRef.current) {
      formRef.current.dispatchEvent(new Event("submit"));
    }
  };

  useEffect(() => {
    if (!candidateName) return;
    const [firstName, lastName] = splitName(candidateName);
    setValue("candidateFirstName", firstName);
    setValue("candidateLastName", lastName);
  }, [candidateName]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay>
        <ModalContent>
          <form onSubmit={onSubmit} autoComplete="on" ref={formRef}>
            <ModalHeader>Add Candidate</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {error && (
                <Alert
                  status="error"
                  title="Error adding candidate"
                  description={error?.message ?? ""}
                />
              )}
              <VStack spacing="8">
                <FormControl
                  id="candidateFirstName"
                  isRequired
                  isInvalid={errors.candidateFirstName !== undefined}
                >
                  <FormLabel>First name</FormLabel>
                  <Input
                    {...register("candidateFirstName")}
                    autoComplete="given-name"
                    onKeyPress={handleKeyPress}
                  />
                  {errors.candidateFirstName !== undefined && (
                    <FormErrorMessage>
                      {errors.candidateFirstName.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  id="candidateLastName"
                  isRequired
                  isInvalid={errors.candidateLastName !== undefined}
                >
                  <FormLabel>Last name</FormLabel>
                  <Input
                    {...register("candidateLastName")}
                    autoComplete="family-name"
                    onKeyPress={handleKeyPress}
                  />
                  {errors.candidateLastName !== undefined && (
                    <FormErrorMessage>
                      {errors.candidateLastName.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl id="email" isInvalid={errors.email !== undefined}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    {...register("email", {
                      pattern: {
                        value: emailRegex,
                        message: "Invalid email",
                      },
                    })}
                    type="email"
                    autoComplete="email"
                    onKeyPress={handleKeyPress}
                  />
                  {errors.email !== undefined && (
                    <FormErrorMessage>{errors.email.message}</FormErrorMessage>
                  )}
                </FormControl>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" isLoading={loading}>
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default AddCandidateModal;
