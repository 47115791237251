import { ApolloError, FetchResult } from "@apollo/client";
import invariant from "invariant";

import { errorToast, successToast, useToast } from "../../../components";
import useCurrentUser from "../../hooks/useCurrentUser";
import {
  CurrentUserScheduledInterviewsDocument,
  ScheduledInterviewerOptOutMutation,
  ScheduledInterviewListItemFragment,
  useScheduledInterviewerOptOutMutation,
} from "..";

type ScheduledInterviewOptOutParams = {
  scheduledInterview: Pick<
    ScheduledInterviewListItemFragment,
    "id" | "scheduledInterviewers" | "recordingDisabled" | "candidate"
  >;
  /**
   * This hook will show a toast in case of an
   * error, but this allows for any additional handling
   */
  onError?(e: ApolloError): void;
  onCompleted?(d: ScheduledInterviewerOptOutMutation): void;
};

type ScheduledInterviewOptOutReturn = {
  toggleOptOut(): Promise<FetchResult<ScheduledInterviewerOptOutMutation>>;
  loading: boolean;
  canDisableRecording: boolean;
  interviewerOptOutAllowed: boolean;
  currentUserRecordingDisabled: boolean;
  candidateRecordingDisabled: boolean;
  recordingDisabled: boolean;
};

export const useScheduledInterviewOptOut = ({
  scheduledInterview,
  onError,
  onCompleted,
}: ScheduledInterviewOptOutParams): ScheduledInterviewOptOutReturn => {
  const currentUser = useCurrentUser();
  const toast = useToast();

  const currentUserScheduledInterviewer =
    scheduledInterview.scheduledInterviewers.find(
      (si) => si.user && si.user.id === currentUser.id
    );
  const hasScheduledInterviewer = !!currentUserScheduledInterviewer;

  const [updateScheduledInterviewer, { loading }] =
    useScheduledInterviewerOptOutMutation({
      onError: (err) => {
        onError?.(err);
        errorToast(toast, err.message);
      },
      refetchQueries: [CurrentUserScheduledInterviewsDocument],
      onCompleted: (data) => {
        onCompleted?.(data);
        const scheduledInterviewer =
          data.scheduledInterviewerOptOut.scheduledInterview.scheduledInterviewers.find(
            (si) => si.user && si.user.id === currentUser.id
          );
        if (!scheduledInterviewer) {
          return;
        }
        successToast(
          toast,
          scheduledInterviewer.recordingDisabled
            ? "Your interview will not be recorded"
            : "You've opted back in to recording"
        );
      },
    });

  return {
    loading,
    toggleOptOut() {
      invariant(
        currentUserScheduledInterviewer,
        "Missing scheduled interviewer"
      );

      return updateScheduledInterviewer({
        variables: {
          id: currentUserScheduledInterviewer.id,
          recordingDisabled: !currentUserScheduledInterviewer.recordingDisabled,
        },
      });
    },
    canDisableRecording: hasScheduledInterviewer,
    interviewerOptOutAllowed: currentUser.organization.allowInterviewerOptOut,
    candidateRecordingDisabled:
      scheduledInterview.candidate?.recordingDisabled ?? false,
    currentUserRecordingDisabled:
      currentUserScheduledInterviewer?.recordingDisabled ?? false,
    recordingDisabled: scheduledInterview.recordingDisabled,
  };
};
