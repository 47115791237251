import { Avatar, AvatarProps } from "@chakra-ui/react";
import React from "react";

import { isExternalUser, isInternalUser, ShareUser } from "./types";

export type SharedWithAvatarProps = {
  user: ShareUser;
} & AvatarProps;

const SharedWithAvatar: React.FC<SharedWithAvatarProps> = ({
  user,
  size = "sm",
  ...rest
}) => {
  const getInitialsFromEmail = (): string => {
    const { email } = user;
    const [username] = email.split("@");
    const displayChars = username.match(/[a-zA-Z0-9]/g) || [];
    return displayChars[0] ?? "";
  };

  const props: AvatarProps = {
    size,
    cursor: "default",
    ...rest,
  };

  if (isInternalUser(user)) {
    props.src = user.profilePicUrl ?? undefined;
    props.name = user.fullName;
    props.backgroundColor = "green.100";
    props.color = "green.700";
  } else if (isExternalUser(user)) {
    props.getInitials = getInitialsFromEmail;
    // if `src` and `name` are undefined, chakra Avatar falls back to a generic user icon:
    // https://chakra-ui.com/docs/components/avatar#avatar-fallbacks
    props.name = getInitialsFromEmail() ? user.email : undefined;
    props.outline = "1px dashed #FF954C";
    props.backgroundColor = "orange.100";
    props.color = "orange.400";
    props.showBorder = false;
  }

  return <Avatar {...props} />;
};

export default SharedWithAvatar;
