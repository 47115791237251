import { Flex } from "@chakra-ui/react";
import React from "react";

import useCurrentBreakpoint from "../../../../hooks/useCurrentBreakpoint";
import { parseDate } from "../../../../utils/datetime";
import {
  AddBrightHireToInterviewsMutation,
  InterviewAssistantEntryPoint,
  ScheduledInterviewListItemFragment,
} from "../../../graphql";
import BaseListItem from "./BaseListItem";
import InterviewCandidatePositionSection from "./InterviewCandidatePositionSection";
import InterviewInformationSection from "./InterviewInterviewerSection";
import RecordingDisabledMessage from "./RecordingDisabledMessage";
import ScheduledInterviewLaunchButton from "./ScheduledInterviewLaunchButton";
import ScheduledInterviewMenu from "./ScheduledInterviewMenu";
import ScheduleInterviewsMobileListItem from "./ScheduleInterviewsMobileListItem";

interface ScheduledInterviewsListItemProps {
  scheduledInterview: ScheduledInterviewListItemFragment;
  listPosition: number;
  showUpdating?: "updating" | "updateFailed";
  onAddBrightHire?: (results: AddBrightHireToInterviewsMutation) => void;
  onClickCandidate?(): void;
  onClickPosition?(): void;
  onClickInterview?(): void;
}

const ScheduledInterviewsListItem: React.FC<
  ScheduledInterviewsListItemProps
> = ({
  scheduledInterview,
  listPosition = 1,
  showUpdating = undefined,
  onAddBrightHire,
  onClickCandidate,
  onClickPosition,
  onClickInterview,
}) => {
  const interviewers = scheduledInterview?.scheduledInterviewers?.map(
    (i) => i.user
  );
  const isSmallDesktop = useCurrentBreakpoint() === "lg";

  if (!interviewers) return null;
  let isHighlightedInterview = false;
  if (scheduledInterview.scheduledStart) {
    const scheduledStartDate = parseDate(scheduledInterview.scheduledStart);
    const now = new Date();
    const diff = now.valueOf() - scheduledStartDate.valueOf();
    const diffInMinutes = Math.abs(diff) / 1000 / 60;
    isHighlightedInterview =
      diffInMinutes <= 15 || (diff < 0 && diffInMinutes <= 30);
  }
  return (
    <BaseListItem
      pt={{ base: 4, lg: 4 }}
      pb={{ base: 6, lg: 4 }}
      pl={{ base: 5, lg: 7 }}
      pr={{ base: 5, lg: 4 }}
      {...(isHighlightedInterview ? { borderColor: "blueAlpha.300" } : {})}
      bg={isHighlightedInterview ? "blueAlpha.100" : undefined}
      data-testid={`upcoming-interviews-item-${listPosition}`}
      showMobile={isSmallDesktop}
      leftCol={
        <InterviewCandidatePositionSection
          scheduledInterview={scheduledInterview}
          onClickCandidate={onClickCandidate}
          onClickPosition={onClickPosition}
        />
      }
      centerCol={
        <InterviewInformationSection
          scheduledInterview={scheduledInterview}
          onClickInterview={onClickInterview}
        />
      }
      rightCol={
        <Flex h="100%" direction="column" alignItems="flex-end">
          {/* TODO: open IA and launch zoom; update method for integration */}
          <Flex>
            {scheduledInterview?.isInterviewer && (
              <ScheduledInterviewLaunchButton
                scheduledInterview={scheduledInterview}
                listPosition={listPosition}
                showUpdating={showUpdating}
                interviewAssistantEntryPoint={
                  InterviewAssistantEntryPoint.HomePage
                }
                onAddBrightHire={(results) => {
                  if (onAddBrightHire) {
                    onAddBrightHire(results);
                  }
                }}
              />
            )}
            <ScheduledInterviewMenu
              scheduledInterview={scheduledInterview}
              bgColor="transparent"
            />
          </Flex>
          <RecordingDisabledMessage
            scheduledInterview={scheduledInterview}
            mr="3"
            mt="auto"
          />
        </Flex>
      }
      mobile={
        <ScheduleInterviewsMobileListItem
          scheduledInterview={scheduledInterview}
          listPosition={listPosition}
          showUpdating={showUpdating}
          onAddBrightHire={onAddBrightHire}
          onClickCandidate={onClickCandidate}
          onClickPosition={onClickPosition}
        />
      }
    />
  );
};

export default ScheduledInterviewsListItem;
