import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  SystemProps,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { HiDotsVertical } from "react-icons/hi";

import { useToast } from "../../../components";
import {
  CurrentUserFragment,
  ScheduledInterviewListItemFragment,
  ScheduledInterviewType,
  useDeleteScheduledInterviewMutation,
  useScheduledInterviewerOptOutMutation,
} from "../../graphql";
import { EditScheduledInterviewModal } from "../ScheduledInterviewModal";

type ScheduledInterviewMenuProps = {
  scheduledInterview: ScheduledInterviewListItemFragment;
  currentUser: CurrentUserFragment;
  buttonSize?: string;
} & SystemProps;

const ScheduledInterviewMenu: React.FC<ScheduledInterviewMenuProps> = ({
  scheduledInterview,
  currentUser,
  buttonSize,
  ...styles
}) => {
  const toast = useToast();
  const [
    updateScheduledInterviewer,
    { loading: scheduledInterviewerOptOutLoading },
  ] = useScheduledInterviewerOptOutMutation({
    update: (cache, { data }) => {
      const scheduledInterview =
        data?.scheduledInterviewerOptOut.scheduledInterview;
      if (scheduledInterview) {
        cache.modify({
          id: cache.identify(scheduledInterview),
          fields: {
            scheduledInterviewers: (prev) => {
              return [...scheduledInterview.scheduledInterviewers];
            },
          },
        });
      }
    },
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
    onCompleted: ({ scheduledInterviewerOptOut: { scheduledInterview } }) => {
      if (!scheduledInterview) return;
      const scheduledInterviewer =
        scheduledInterview.scheduledInterviewers.find(
          (si) => si.user && si.user.id === currentUser.id
        );
      if (!scheduledInterviewer) {
        return;
      }
      const description = scheduledInterviewer.recordingDisabled
        ? "Your interview will not be recorded"
        : "You've opted back in to recording";
      toast({
        title: "Success",
        status: "success",
        description,
      });
    },
  });

  const [
    deleteScheduledInterview,
    { loading: deleteScheduledInterviewLoading },
  ] = useDeleteScheduledInterviewMutation({
    update(cache, { data }) {
      const scheduledInterview =
        data?.deleteScheduledInterview?.scheduledInterview;
      if (scheduledInterview) {
        const scheduledInterviewId = cache.identify(scheduledInterview);
        if (scheduledInterviewId) {
          cache.evict({ id: scheduledInterviewId });
          cache.gc();
        }
      }
    },
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
    onCompleted: ({ deleteScheduledInterview }) => {
      if (!deleteScheduledInterview?.scheduledInterview) return;
      toast({
        title: "Success",
        status: "success",
        description: "Interview deleted.",
      });
    },
  });

  const {
    isOpen: isEditOpen,
    onClose: onCloseEdit,
    onOpen: onOpenEdit,
  } = useDisclosure();

  const showOptOut = currentUser.organization.allowInterviewerOptOut;
  const showScheduledInterviewActions =
    currentUser.organization.allowManualScheduledInterviews;
  if (!(showOptOut || showScheduledInterviewActions)) {
    return null;
  }

  const currentUserScheduledInterviewer =
    scheduledInterview.scheduledInterviewers.find(
      (si) => si.user && si.user.id === currentUser.id
    );
  const canDisableRecording = !!currentUserScheduledInterviewer;
  const canEdit =
    scheduledInterview.scheduledType === ScheduledInterviewType.Manual ||
    scheduledInterview.scheduledType === ScheduledInterviewType.Email;
  const canDelete =
    scheduledInterview.scheduledType === ScheduledInterviewType.Manual;

  return (
    <>
      {isEditOpen && (
        <EditScheduledInterviewModal
          isOpen
          onClose={onCloseEdit}
          scheduledInterview={scheduledInterview}
        />
      )}
      <Menu autoSelect={false} variant="new">
        <MenuButton
          {...styles}
          as={IconButton}
          variant="icon"
          ml={2}
          size={buttonSize || "md"}
          icon={<HiDotsVertical size={20} />}
          _focus={{}}
          onClick={(e) => e.stopPropagation()}
          data-testid={`scheduled-interview-${scheduledInterview.id}-menu-button`}
        />
        <Portal>
          <MenuList>
            {showScheduledInterviewActions && (
              <Tooltip
                label={
                  !canEdit
                    ? "Only manually scheduled interviews can be edited"
                    : null
                }
              >
                {/* Use span as workaround for chakra disabled button tooltip issue */}
                <span>
                  <MenuItem
                    fontSize="sm"
                    isDisabled={!canEdit || deleteScheduledInterviewLoading}
                    data-testid={`scheduled-interview-${scheduledInterview.id}-menu-option-edit`}
                    onClick={onOpenEdit}
                  >
                    Edit
                  </MenuItem>
                </span>
              </Tooltip>
            )}
            {showOptOut && (
              <Tooltip
                label={
                  !canDisableRecording
                    ? "You don’t have access to change this setting"
                    : scheduledInterview.candidate?.recordingDisabled
                    ? "You must turn on recordings for this candidate"
                    : null
                }
              >
                {/* Use span as workaround for chakra disabled button tooltip issue */}
                <span>
                  <MenuItem
                    fontSize="sm"
                    isDisabled={
                      !canDisableRecording ||
                      scheduledInterview.candidate?.recordingDisabled ||
                      scheduledInterviewerOptOutLoading
                    }
                    data-testid={`scheduled-interview-${scheduledInterview.id}-menu-option-opt-out`}
                    onClick={() => {
                      if (!currentUserScheduledInterviewer) {
                        return;
                      }
                      updateScheduledInterviewer({
                        variables: {
                          id: currentUserScheduledInterviewer?.id,
                          recordingDisabled:
                            !currentUserScheduledInterviewer?.recordingDisabled,
                        },
                      });
                    }}
                  >
                    {currentUserScheduledInterviewer?.recordingDisabled ||
                    scheduledInterview.candidate?.recordingDisabled
                      ? "Opt back in to recording"
                      : "Do not record interview"}
                  </MenuItem>
                </span>
              </Tooltip>
            )}
            {showScheduledInterviewActions && (
              <Tooltip
                label={
                  !canDelete
                    ? "Only manually scheduled interviews can be deleted"
                    : null
                }
              >
                {/* Use span as workaround for chakra disabled button tooltip issue */}
                <span>
                  <MenuItem
                    data-testid={`scheduled-interview-${scheduledInterview.id}-menu-option-delete`}
                    color="red.500"
                    fontSize="sm"
                    isDisabled={!canDelete || deleteScheduledInterviewLoading}
                    onClick={() => {
                      deleteScheduledInterview({
                        variables: {
                          scheduledInterviewId: scheduledInterview.id,
                        },
                      });
                    }}
                  >
                    Delete
                  </MenuItem>
                </span>
              </Tooltip>
            )}
          </MenuList>
        </Portal>
      </Menu>
    </>
  );
};

export default ScheduledInterviewMenu;
