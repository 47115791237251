import { ApolloError } from "@apollo/client";
import { Box, BoxProps, Flex, Text } from "@chakra-ui/react";
import React, { useMemo } from "react";

import { Alert } from "../../../../components";
import ReadOnlyEmptyNotes from "../../../../components/Images/ReadOnlyEmptyNotes";
import {
  formatRelativeDate,
  getMostRecentDateString,
} from "../../../../utils/datetime";
import {
  Call,
  CallNoteFragment,
  ScorecardItemNotesFragment,
  useMarkScorecardQuestionMutation,
} from "../../../graphql";
import CallNotesSection from "../../CallNotes/beta/CallNotesSection";
// import useCurrentNote from "../../CallNotes/beta/useCurrentNote";
import { RenderContent } from "../../utils";
import ScorecardNoteBlock from "./NotesV3/ScorecardNoteBlock";
import { NotesV3CommonProps } from "./NotesV3/types";

export type NotesV3TabProps = BoxProps & {
  call: Pick<Call, "id">;
  scorecardItems: ScorecardItemNotesFragment[];
  generalNotes: CallNoteFragment[];
  headerSlot?: RenderContent;
  error?: ApolloError;
  loading?: boolean;
} & NotesV3CommonProps;

const NotesV3Tab: React.FC<NotesV3TabProps> = ({
  call,
  callId,
  clipId,
  callDuration,
  interviewerIds,
  notesReadOnly,
  scorecardItems,
  generalNotes,
  headerSlot,
  onClickTimestamp,
  setClipRange,
  onAddNote,
  playerTime,
  error,
  loading,
  ...rest
}) => {
  // TODO: revisit this since we replaced questions with scorecardItems
  // const currentNoteId = useCurrentNote(
  //   generalNotes,
  //   scorecardItems,
  //   playerTime
  // );

  const [markScorecardQuestion] = useMarkScorecardQuestionMutation();
  const onMarkQuestion = (
    markedTime: number,
    scorecardQuestionId: string
  ): void => {
    markScorecardQuestion({
      variables: {
        markedTime,
        scorecardQuestionId,
      },
      update(cache, { data: markScorecardQuestionData }) {
        const updatedScorecardItem =
          markScorecardQuestionData?.markScorecardQuestion?.scorecardItem;
        if (!updatedScorecardItem) return;
        cache.modify({
          id: cache.identify({
            __typename: "NotesScorecardItem",
            id: updatedScorecardItem.id,
          }),
          fields: {
            marked() {
              return updatedScorecardItem.marked;
            },
            markedTime() {
              return updatedScorecardItem.markedTime;
            },
          },
        });
      },
    });
  };

  const lastUpdatedAt = useMemo(() => {
    const allScorecardItemNotes = scorecardItems.reduce(
      (outerAcc, parentItem) => {
        const childItemNotes = parentItem.childItems.reduce(
          (childAcc, childItem) => {
            return [...childAcc, ...childItem.questionNotes];
          },
          [] as CallNoteFragment[]
        );
        return [...outerAcc, ...parentItem.questionNotes, ...childItemNotes];
      },
      [] as CallNoteFragment[]
    );
    const allNotes = generalNotes.concat(allScorecardItemNotes);
    if (!allNotes.length) return null;
    return getMostRecentDateString(allNotes.map((note) => note.updatedAt));
  }, [generalNotes, scorecardItems]);

  const commonProps: NotesV3CommonProps = {
    callId: call.id,
    clipId,
    callDuration,
    interviewerIds,
    playerTime,
    onAddNote,
    onClickTimestamp,
    // currentNoteId,
    notesReadOnly,
    setClipRange,
  };

  const showEmptyNotes =
    notesReadOnly && generalNotes.length === 0 && scorecardItems.length === 0;

  if (error) {
    return (
      <Alert
        status="error"
        title="Error fetching notes"
        description={error.message}
      />
    );
  }

  if (loading) {
    return null;
  }

  if (showEmptyNotes) {
    return (
      <Flex alignItems="center" flexDir="column" pt="36">
        <ReadOnlyEmptyNotes />
        <Box fontSize="medium" textAlign="center" pt="6">
          This interview has no recorded notes from interviewers.
        </Box>
      </Flex>
    );
  }

  return (
    <Box fontSize="sm" px={{ base: "1", md: "4" }} pb="3" {...rest}>
      <Flex
        alignItems="center"
        mt="2"
        mb="2"
        gap="2"
        justifyContent="space-between"
      >
        <Text color="gray.500" fontWeight="400" fontSize="sm">
          {lastUpdatedAt && `Last saved ${formatRelativeDate(lastUpdatedAt)}`}
        </Text>
        {headerSlot}
      </Flex>
      <Flex direction="column" gap="3">
        {(!notesReadOnly || generalNotes.length > 0) && (
          <Box
            borderRadius="lg"
            borderWidth="1px"
            borderColor="gray.200"
            overflow="hidden"
          >
            <Text p="4" fontWeight="600" color="gray.900">
              General Notes
            </Text>
            <CallNotesSection {...commonProps} notes={generalNotes} />
          </Box>
        )}

        {scorecardItems.map((item) => (
          <ScorecardNoteBlock
            key={item.id}
            item={item}
            onMarkQuestion={onMarkQuestion}
            {...commonProps}
          />
        ))}
      </Flex>
    </Box>
  );
};

NotesV3Tab.displayName = "NotesV3Tab";

export default React.memo(NotesV3Tab);
