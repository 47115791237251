import { DependencyList, EffectCallback, useEffect, useRef } from "react";

function useEffectAfterFirstRender(
  effect: EffectCallback,
  deps?: DependencyList
): void {
  const initial = useRef(true);

  useEffect(() => {
    if (initial.current) {
      initial.current = false;
    } else {
      return effect();
    }
  }, deps);
}

export default useEffectAfterFirstRender;
