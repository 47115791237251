import { useState } from "react";

import {
  dateRange6MonthsAgo,
  SelectDateRangeState,
} from "../../../components/SelectDateRange/SelectDateRange";
import {
  useEnumSearchParam,
  useNumberSearchParam,
  useSelectDateRangeParams,
  useStringArrayParam,
} from "../../../hooks/useSearchParam";
import { snakeCaseToTitleCase } from "../../../utils/string";
import { AnalyticsDimension, MetricName } from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import { ChartSort, SelectOption } from "./types";

type ChartLimit = number;

export type SingleSelect<T> = {
  value: T;
  setValue(value: T): void;
  options: SelectOption<T>[];
};

export type MultiSelect = {
  values: string[] | undefined;
  setValues(value: string[] | undefined): void;
  // Labels are used for the edge case the values are ids, and the label
  // is needed for drilldown to match the algolia indexing on label.
  labels?: string[];
  setLabels?: (value: string[] | undefined) => void;
};

export type AnalyticsConfig = {
  metric: MetricName;
  chartLimit: SingleSelect<ChartLimit>;
  chartSort: SingleSelect<ChartSort>;
  primaryDimension: SingleSelect<AnalyticsDimension>;
  secondaryDimension: SingleSelect<AnalyticsDimension>;
  positions: MultiSelect;
  interviewers: MultiSelect;
  departments: MultiSelect;
  stages: MultiSelect;
  dateRange: {
    value: SelectDateRangeState;
    setValue(value: SelectDateRangeState | undefined): void;
  };
  filters: {
    positions: string[];
    interviewers: string[];
    departments: string[];
    stages: string[];
  };
};

const defaultPrimaryDimension = AnalyticsDimension.Interviewer;
const defaultSecondaryDimension = AnalyticsDimension.None;
const defaultChartSort: ChartSort = "highest-first";
const defaultChartLimit = 20;

/**
 * Manages the state of user-selectable analytics configurations such as
 * filters, dimensions, and chart settings.
 *
 * Only manages the config, but not the query execution.
 */
const useAnalyticsConfig = (metric: MetricName): AnalyticsConfig => {
  const [primaryDimension, setPrimaryDimension] =
    useEnumSearchParam<AnalyticsDimension>("dimension");
  const groupByStageEnabled = useFeatureFlag("analytics:release2");
  const hcaMetricsEnabled = useFeatureFlag("analytics:hca-metrics");
  const [secondaryDimension, setSecondaryDimension] =
    useEnumSearchParam<AnalyticsDimension>("segment");
  const [chartLimit, setChartLimit] = useNumberSearchParam("limit");
  const [chartSort, setChartSort] = useEnumSearchParam<ChartSort>("sort");
  const [positions, setPositions] = useStringArrayParam("positions");
  const [interviewers, setInterviewers] = useStringArrayParam("interviewers");
  const [interviewerLabels, setInterviewerLabels] = useState<
    string[] | undefined
  >(undefined);
  const [departments, setDepartments] = useStringArrayParam("departments");
  const [stages, setStages] = useStringArrayParam("stages");
  const [dateRange, setDateRange] = useSelectDateRangeParams("dateRange");

  const filters = {
    positions: positions || [],
    interviewers: interviewers || [],
    departments: departments || [],
    stages: stages || [],
  };

  return {
    metric,
    primaryDimension: {
      value: primaryDimension || defaultPrimaryDimension,
      setValue: setPrimaryDimension,
      options: primaryDimensionOptions({
        groupByStage: groupByStageEnabled,
        groupByPerformance: hcaMetricsEnabled,
      }),
    },
    secondaryDimension: {
      value: secondaryDimension || defaultSecondaryDimension,
      setValue: setSecondaryDimension,
      options: secondaryDimensionOptions,
    },
    chartLimit: {
      value: chartLimit || defaultChartLimit,
      setValue: setChartLimit,
      options: chartLimitOptions(primaryDimension || defaultPrimaryDimension),
    },
    chartSort: {
      value: chartSort || defaultChartSort,
      setValue: setChartSort,
      options: hcaMetricsEnabled
        ? chartSortOptions.concat([
            {
              value: "highest-performance-first",
              label: "Highest Performance",
            },
            {
              value: "lowest-performance-first",
              label: "Lowest Performance",
            },
          ])
        : chartSortOptions,
    },
    positions: {
      values: positions,
      setValues: setPositions,
    },
    interviewers: {
      values: interviewers,
      setValues: setInterviewers,
      labels: interviewerLabels,
      setLabels: setInterviewerLabels,
    },
    departments: {
      values: departments,
      setValues: setDepartments,
    },
    stages: {
      values: stages,
      setValues: setStages,
    },
    dateRange: {
      // Default is defined inline because it dynamically calculates a range
      value: dateRange || dateRange6MonthsAgo(),
      setValue: setDateRange,
    },
    filters,
  };
};

export const secondaryDimensionOptions = [
  { value: AnalyticsDimension.None, label: "None" },
  { value: AnalyticsDimension.Gender, label: "Candidate Gender" },
];

export const chartSortOptions: SelectOption<ChartSort>[] = [
  { value: "highest-first", label: "Highest Number First" },
  { value: "lowest-first", label: "Lowest Number First" },
  { value: "most-interviews-first", label: "Most Interviews First" },
  { value: "least-interviews-first", label: "Fewest Interviews First" },
  { value: "name-a-z", label: "Name A-Z" },
  { value: "name-z-a", label: "Name Z-A" },
];

export const renameDimension = (stage: string): string =>
  stage.replace(/job/gi, "Interview");

export const chartLimitOptions = (
  primaryDimension: AnalyticsDimension
): SelectOption<ChartLimit>[] => {
  const renamedPrimaryDimension = renameDimension(primaryDimension);
  return [
    {
      value: 20,
      label: `20 ${snakeCaseToTitleCase(renamedPrimaryDimension)}s`,
    },
    {
      value: 50,
      label: `50 ${snakeCaseToTitleCase(renamedPrimaryDimension)}s`,
    },
    {
      value: 100,
      label: `100  ${snakeCaseToTitleCase(renamedPrimaryDimension)}s`,
    },
    {
      value: -1,
      label: `All ${snakeCaseToTitleCase(renamedPrimaryDimension)}s`,
    },
  ];
};

export const primaryDimensionOptions = (features: {
  groupByStage: boolean;
  groupByPerformance: boolean;
}): SelectOption<AnalyticsDimension>[] =>
  [
    { value: AnalyticsDimension.Interviewer, label: "Interviewer" },
    { value: AnalyticsDimension.Department, label: "Department" },
    { value: AnalyticsDimension.Position, label: "Position" },
  ]
    .concat(
      features.groupByStage
        ? [{ value: AnalyticsDimension.JobStage, label: "Interview Stage" }]
        : []
    )
    .concat(
      features.groupByPerformance
        ? [{ value: AnalyticsDimension.Performance, label: "Performance" }]
        : []
    );

export default useAnalyticsConfig;
