import { format } from "date-fns";
import type { History } from "history";
import { useCallback, useContext, useEffect } from "react";
import {
  createSearchParams,
  UNSAFE_NavigationContext as NavigationContext,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import {
  dateRange6MonthsAgo,
  dateRange7DaysAgo,
  dateRange30DaysAgo,
  dateRange90DaysAgo,
  dateRangeCustom,
  SelectDateRangeState,
} from "../components/SelectDateRange/SelectDateRange";

type ValueTypes =
  | string
  | number
  | boolean
  | Date
  | string[]
  | null
  | undefined;

export interface QueryParamConfig<D extends ValueTypes> {
  encode: (input: D) => string | null;
  decode: (input: string | null) => D;
}

export const StringParam: QueryParamConfig<string | undefined> = {
  encode: (input: string | undefined): string | null =>
    input === undefined || input === "" ? null : input,
  decode: (input: string | null): string | undefined =>
    input === null ? undefined : input,
};

export const StringArrayParam: QueryParamConfig<string[] | undefined> = {
  encode: (input: string[] | undefined): string | null => {
    if (input === undefined || input.length === 0) {
      return null;
    }
    // Ignore empty strings
    const prunedArray = input.filter((s) => s.length > 0);
    if (prunedArray.length === 0) {
      return null;
    }
    return prunedArray.join(",");
  },
  decode: (input: string | null): string[] | undefined =>
    input === null
      ? undefined
      : input.split(",").map((s) => decodeURIComponent(s)),
};

export const NumberParam: QueryParamConfig<number | null> = {
  encode: (input: number | null): string | null =>
    input !== null ? input.toString() : null,
  decode: (input: string | null): number | null => {
    if (input === null) {
      return null;
    }
    const result = +input;
    return Number.isNaN(result) ? 0 : result;
  },
};

export const BooleanParam: QueryParamConfig<boolean | undefined> = {
  encode: (bool: boolean | undefined): string | null =>
    bool !== undefined ? bool.toString() : null,
  decode: (input: string | null): boolean | undefined =>
    input === "true" ? true : input === "false" ? false : undefined,
};

export const DateParam: QueryParamConfig<Date | undefined> = {
  encode: (date: Date | undefined): string | null => {
    if (date === null || date === undefined) {
      return null;
    }
    return format(date, "yyyy-MM-dd");
  },
  decode: (input: string | null): Date | undefined => {
    if (input === null) {
      return undefined;
    }

    const parts = input.split("-");
    if (parts.length !== 3) {
      return undefined;
    }

    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const day = parseInt(parts[2], 10);

    const date = new Date(year, month, day);

    if (Number.isNaN(date.getTime())) {
      return undefined;
    }
    return date;
  },
};

export const DateTimeParam: QueryParamConfig<Date | undefined> = {
  encode: (date: Date | undefined): string | null => {
    if (date === undefined || date === null) {
      return null;
    }
    return date.toISOString();
  },
  decode: (input: string | null): Date | undefined => {
    if (input === null) {
      return undefined;
    }

    const date = new Date(input);
    if (Number.isNaN(date.getTime())) {
      return undefined;
    }
    return date;
  },
};

// Inspired by https://github.com/remix-run/react-router/issues/8287
function useSearchParamConfig<D extends ValueTypes>(
  name: string,
  config: QueryParamConfig<D>,
  defaultValue?: D
): [D, (newValue: D) => void] {
  const [searchParams] = useSearchParams();
  const { navigator } = useContext(NavigationContext);
  const navigate = useNavigate();

  const setValue = useCallback(
    (value: D): void => {
      // Use history's latest location in case other setters have been called in that same update.
      const { location } = navigator as unknown as History;
      const params = new URLSearchParams(location.search);

      const encodedValue = config.encode(value);
      if (encodedValue !== null) {
        params.set(name, encodedValue);
      } else {
        params.delete(name);
      }
      // Navigate instead of using setSearchParams because somehow react=routers
      // value of `location` diverges in this hook. At a page level, the location is
      // accurate and tracks browser history. But within this hook, it has a stale value,
      // and internally within setSearchParams it uses the stale value, navigating backwards
      // but keeping search params. This bug triggers on the analytics page, which
      // both changes path and preserves search params across those paths.
      // This can be reverted to `setSearchParams` if the stale location value can be solved.
      navigate({
        pathname: location.pathname,
        search: params.toString(),
      });
    },
    [navigator, navigate, searchParams, name]
  );

  const params = createSearchParams(searchParams);

  useEffect(() => {
    if (defaultValue !== undefined && !params.has(name)) {
      setValue(defaultValue);
    }
  }, []);

  const decodedValue = config.decode(params.get(name));
  return [decodedValue, setValue];
}

export function useSearchParam(
  name: string,
  defaultValue?: string
): [string | undefined, (newValue: string | undefined) => void] {
  return useSearchParamConfig(name, StringParam, defaultValue);
}

export function useEnumSearchParam<T>(
  name: string,
  defaultValue?: T
): [T | undefined, (newValue: T | undefined) => void] {
  const [value, setValue] = useSearchParamConfig(
    name,
    StringParam,
    defaultValue as string | undefined
  );
  const enumValue: T = value as unknown as T;
  const setEnumValue = setValue as unknown as (newValue: T | undefined) => void;
  return [enumValue, setEnumValue];
}

export function useNumberSearchParam(
  name: string,
  defaultValue?: number
): [number | null, (newValue: number | null) => void] {
  return useSearchParamConfig(name, NumberParam, defaultValue);
}

export function useBooleanSearchParam(
  name: string,
  defaultValue?: boolean
): [boolean | undefined, (newValue: boolean | undefined) => void] {
  return useSearchParamConfig(name, BooleanParam, defaultValue);
}

export function useDateSearchParam(
  name: string,
  defaultValue?: Date
): [Date | undefined, (newValue: Date | undefined) => void] {
  return useSearchParamConfig(name, DateParam, defaultValue);
}

export function useDateTimeSearchParam(
  name: string,
  defaultValue?: Date
): [Date | undefined, (newValue: Date | undefined) => void] {
  return useSearchParamConfig(name, DateTimeParam, defaultValue);
}

export function useStringArrayParam(
  name: string,
  defaultValue?: string[]
): [string[] | undefined, (newValue: string[] | undefined) => void] {
  return useSearchParamConfig(name, StringArrayParam, defaultValue);
}

export function useSelectDateRangeParams(
  name: string,
  defaultValue?: SelectDateRangeState
): [
  SelectDateRangeState | undefined,
  (newValue: SelectDateRangeState | undefined) => void
] {
  const [date, setDate] = useSearchParam("date");
  const [dateMin, setDateMin] = useDateSearchParam("datemin");
  const [dateMax, setDateMax] = useDateSearchParam("datemax");

  const dateRangeState = (): SelectDateRangeState => {
    if (date === "last_7") {
      return dateRange7DaysAgo();
    }
    if (date === "last_30") {
      return dateRange30DaysAgo();
    }
    if (date === "last_90") {
      return dateRange90DaysAgo();
    }
    if (date === "last_6m" || dateMin === undefined || dateMax === undefined) {
      return dateRange6MonthsAgo();
    }
    return dateRangeCustom(dateMin, dateMax);
  };

  return [
    dateRangeState(),
    (newValue) => {
      if (newValue === undefined) {
        return;
      }
      setDate(newValue.type);
      if (newValue.type === "custom") {
        setDateMin(newValue.start);
        setDateMax(newValue.end);
      } else {
        setDateMin(undefined);
        setDateMax(undefined);
      }
    },
  ];
}
