import { cssVar, mode } from "@chakra-ui/theme-tools";

const $arrowBg = cssVar("popper-arrow-bg");

function baseStyle(props: Record<string, any>): Record<string, any> {
  return {
    bg: mode("gray.700", "gray.300")(props),
    color: mode("white", "gray.900")(props),
    pointerEvents: "none",
    whiteSpace: "pre-wrap",
  };
}

export default {
  baseStyle,
  variants: {
    light: {
      bg: "white",
      color: "gray.800",
      borderWidth: "1px",
      borderColor: "gray.100",
      p: "4",
      fontWeight: "400",
      borderRadius: "6px",
      fontSize: "sm",
      [$arrowBg.variable]: "white",
    },
  },
};
