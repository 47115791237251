/* eslint-disable react/no-danger */
import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { MdTimer } from "react-icons/md";

import { defaultPillProps, RouterLink, StarRating } from "../../../components";
import { formatDateSeconds, formatDuration } from "../../../utils/datetime";
import { escapeSearchResults } from "../../../utils/escape";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import useCurrentUser from "../../hooks/useCurrentUser";
import MonospacedText from "../MonospacedText";
import { SearchResultsAnalytics } from "./SearchResultAnalytics";
import { SearchHit } from "./types";

export const SearchResult: React.FC<{ hit: SearchHit }> = ({ hit }) => {
  const currentUser = useCurrentUser();
  const sendGAEvent = useSendGAEvent();
  return (
    <Box
      borderBottom="1px"
      color="gray.500"
      borderColor="border"
      fontSize="sm"
      py={6}
    >
      <Flex>
        <Flex
          width="150px"
          alignItems="center"
          whiteSpace="nowrap"
          flexShrink={0}
        >
          <Box>
            <Icon as={MdTimer} mt="-2px" mr="1" />
            {formatDuration(hit.duration ?? 0)}
          </Box>
        </Flex>
        <Box w="350px">
          <Box>
            {hit.candidate && (
              <>
                <RouterLink
                  to={`/candidate/${hit.candidate.id}`}
                  dangerouslySetInnerHTML={{
                    __html: escapeSearchResults(
                      hit.candidate.name ||
                        hit.candidate.phoneNumber ||
                        hit.candidate.email ||
                        "Candidate"
                    ),
                  }}
                  onClick={() =>
                    sendGAEvent("follow_link", "search", "candidate")
                  }
                />
                &nbsp;&nbsp;|&nbsp;&nbsp;
              </>
            )}
            <RouterLink
              to={`/interview/${hit.callId}`}
              dangerouslySetInnerHTML={{
                __html: escapeSearchResults(
                  hit.name ? hit.name : "Interview Details"
                ),
              }}
              onClick={() => sendGAEvent("follow_link", "search", "call")}
            />
          </Box>
          {hit.position && (
            <RouterLink
              to={`/position/${hit.position.id}`}
              dangerouslySetInnerHTML={{
                __html: escapeSearchResults(hit.position.displayTitle),
              }}
              onClick={() => sendGAEvent("follow_link", "search", "position")}
            />
          )}
          {hit.stage && (
            <Text as="span" fontWeight="bold">
              {` (${hit.stage})`}
            </Text>
          )}
          <Box>
            {formatDateSeconds(hit.startTime)},{" "}
            {hit.interviewers?.map((interviewer, index) => (
              <React.Fragment key={interviewer.id}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: escapeSearchResults(interviewer.name),
                  }}
                />
                {hit.interviewers &&
                  index < hit.interviewers.length - 1 &&
                  ", "}
              </React.Fragment>
            ))}
          </Box>
          {hit.rating && currentUser.organization.showStarRatings ? (
            <StarRating
              rating={hit.rating}
              readOnly
              ratingDescriptions={currentUser.organization.ratingDescriptions}
            />
          ) : null}
          <SearchResultsAnalytics hit={hit} />
        </Box>
      </Flex>
      {hit.notes.length > 0 && (
        <Box mt={2} ml="86px">
          {hit.notes.map((note: any, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <Flex mt={2} key={index}>
              <Box width="40px" mr={6}>
                <RouterLink
                  to={`/interview/${hit.callId}?t=${note.time}`}
                  onClick={() => sendGAEvent("follow_link", "search", "note")}
                  whiteSpace="nowrap"
                >
                  <MonospacedText text={formatDuration(note.time)} />
                </RouterLink>
              </Box>
              <Box
                as="span"
                {...(note.type === "CUE" ? defaultPillProps : {})}
                dangerouslySetInnerHTML={{
                  __html: escapeSearchResults(note.text),
                }}
              />
            </Flex>
          ))}
        </Box>
      )}
      {hit.transcriptSegments.length > 0 && (
        <Box
          mt={2}
          ml="86px"
          borderTop={hit.notes.length > 0 ? "1px" : "0"}
          borderColor="border"
        >
          {hit.transcriptSegments.map((segment, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Flex mt={2} key={index}>
              <Box width="40px" mr={6}>
                <RouterLink
                  to={`/interview/${hit.callId}?t=${segment.startTime}`}
                  onClick={() =>
                    sendGAEvent("follow_link", "search", "transcript")
                  }
                  whiteSpace="nowrap"
                >
                  <MonospacedText text={formatDuration(segment.startTime)} />
                </RouterLink>
              </Box>
              <Box>
                <strong>[{segment.speaker}]</strong>
                <Box
                  as="span"
                  ml={2}
                  dangerouslySetInnerHTML={{
                    __html: escapeSearchResults(segment.text),
                  }}
                />
              </Box>
            </Flex>
          ))}
        </Box>
      )}
    </Box>
  );
};
