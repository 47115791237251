import { Box } from "@chakra-ui/react";
import React from "react";

import {
  CallSpeakerFragment,
  TranscriptSegmentFragment,
} from "../../../graphql";

type SpeakerBarSegmentProps = {
  callDuration: number;
  candidateOnly?: boolean;
  speaker: CallSpeakerFragment;
  segment: TranscriptSegmentFragment;
  onClickSegment: (startTime: number) => void;
};
const SpeakerBarSegment: React.FC<SpeakerBarSegmentProps> = ({
  callDuration,
  candidateOnly = false,
  speaker,
  segment,
  onClickSegment,
}) => {
  // `left` and `width` are both in the `[0, 1]` range and represent proportions
  const left = Math.min(0.99, segment.startTime / callDuration);

  // ensure no overflow by putting a ceiling on width (`width + left <= 0.99`)
  const width = Math.min(
    0.99 - left,
    (segment.endTime - segment.startTime) / callDuration
  );

  return (
    <Box
      key={segment.id}
      role="group"
      position="absolute"
      left={`${left * 100}%`}
      width={`${width * 100}%`}
      minWidth="4px"
      h="16px"
      m="0 1px"
      cursor="pointer"
      onClick={() => {
        onClickSegment(segment.startTime);
      }}
    >
      <Box
        h="4px"
        mt="6px"
        width="100%"
        bg={speaker.isCandidate ? "purple.300" : "gray.100"}
        borderRadius="3px"
        opacity={candidateOnly && !speaker.isCandidate ? 0.2 : 1}
        transition="opacity 0.2s ease"
        _groupHover={{
          bg: speaker.isCandidate ? "purple.550" : "gray.300",
        }}
      />
    </Box>
  );
};

export default SpeakerBarSegment;
