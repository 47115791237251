import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  TextProps,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React from "react";

import TrainingNameModal from "./TrainingNameModal";

interface WithChild {
  children: React.ReactNode;
}

const ContentContainer: React.FC<WithChild> = ({ children }) => {
  return (
    <VStack align="center" mt="-6.6%" mx="15%" spacing="0">
      {children}
    </VStack>
  );
};

const HeaderText: React.FC<WithChild> = ({ children }) => {
  return (
    <Text fontSize={20} fontWeight={500} textAlign="center" mb="18px">
      {children}
    </Text>
  );
};
const ContentText: React.FC<TextProps> = ({ ...props }) => {
  return (
    <Text fontSize={14} fontWeight={400} textAlign="center" {...props}>
      {props.children}
    </Text>
  );
};

interface ManagerEmptyStateProps {
  onAddTraining(name: string, description: string): void;
}

export const ManagerEmptyState: React.FC<ManagerEmptyStateProps> = ({
  onAddTraining,
}) => {
  const nameModal = useDisclosure({});
  return (
    <Flex backgroundColor="white" minW="350px">
      <Box mx="auto" width="100%">
        <Image src="/static/images/trainings_blank_state.png" />
        <ContentContainer>
          <HeaderText>
            Train and upskill your interviewers with real world examples from
            your best role sellers, agenda setters, objection handlers, and
            more.
          </HeaderText>
          <VStack align="center" mx="15% !important" spacing="0">
            <ContentText>
              We recommend each training trainingProgram to be targeted towards
              specific interviews that a trainee is focused on, such as:
            </ContentText>
            <ContentText mt="14px !important">Recruiter Screen</ContentText>
            <ContentText>Engineering Challenge</ContentText>
            <ContentText>Core Values Interview</ContentText>
            <Text fontSize={14} fontWeight={100}>
              And more...
            </Text>
            <Button
              size="sm"
              fontWeight="medium"
              mt="24px !important"
              onClick={nameModal.onOpen}
            >
              New training program
            </Button>
          </VStack>
        </ContentContainer>
      </Box>
      {nameModal.isOpen && (
        <TrainingNameModal
          onClose={nameModal.onClose}
          onSave={onAddTraining}
          mode="create"
        />
      )}
    </Flex>
  );
};

export const TraineeEmptyState: React.FC = () => {
  return (
    <Flex backgroundColor="white" minW="350px">
      <Box mx="auto" width="100%">
        <Image src="/static/images/trainings_blank_state.png" />
        <ContentContainer>
          <HeaderText>
            Upskill your interviewing with real examples of role selling, agenda
            setting, objection handling, and more.
          </HeaderText>
          <ContentText>
            All training programs you&apos;ve been added to will show up here.
          </ContentText>
        </ContentContainer>
      </Box>
    </Flex>
  );
};
