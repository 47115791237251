import { Box, Flex, Icon, ListItem, UnorderedList } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { HiStar } from "react-icons/hi2";

import { formatRelativeDate } from "../../../../utils/datetime";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { CandidatePositionScorecardFragment } from "../../../graphql";
import ExpandableGroup, {
  ExpandableGroupListItem,
} from "../../PositionScorecard/ExpandableGroup";
import ScoresTabColumn from "./ScoresTabColumn";
import useAverageScores from "./useAverageScores";

type ScoresTabProps = {
  scorecards: CandidatePositionScorecardFragment[];
};

const ScoresTab: React.FC<ScoresTabProps> = ({ scorecards }) => {
  const sendGAEvent = useSendGAEvent();
  useEffect(() => {
    sendGAEvent("review_scores", "scoring", "Candidate Page");
  }, []);

  const {
    /* the commented values are used for highest and lowest labels
      these will be added back in the form of a table later */

    // scores,
    // minScore,
    // maxScore,
    averageInterviewScore,
    interviewers,
    competencies,
    // averageCompetencyScores,
    // minCompetencyScore,
    // maxCompetencyScore,
  } = useAverageScores(scorecards);

  return (
    <Flex pt="5" gap="6" alignItems="flex-start" fontSize="sm" lineHeight="5">
      <ScoresTabColumn
        heading="INTERVIEWERS"
        subHeading={averageInterviewScore ? "Interview score" : null}
        subHeadingTooltip={
          averageInterviewScore ? (
            <>
              <b>Interview score</b> is derived from the overall interview
              scores given by interviewers for this candidate
              <UnorderedList ml="4" mt="2">
                <ListItem>
                  Score data appears when an interviewer has submitted their
                  score.
                </ListItem>
                <ListItem>
                  Score pending appears when an interviewer still needs to
                  complete their score.
                </ListItem>
                <ListItem>
                  Score disabled appears when the interviewer&apos;s guide did
                  not have overall interview scoring enabled.
                </ListItem>
              </UnorderedList>
            </>
          ) : null
        }
      >
        {averageInterviewScore && (
          <Flex
            alignItems="center"
            gap="2.5"
            borderRadius="md"
            bg="blueAlpha.50"
            pl="3"
            pr="6"
            py="3"
          >
            <Icon
              as={HiStar}
              color="blue.600"
              bg="blueAlpha.200"
              borderRadius="full"
              boxSize="8"
              p="1.5"
            />
            <Box flex="1" fontWeight="medium">
              Average interview score
            </Box>
            <Flex gap="1" alignItems="center" fontWeight="semibold">
              {averageInterviewScore}
              <Icon as={HiStar} color="blue.500" boxSize="4" />
            </Flex>
          </Flex>
        )}
        {interviewers.map(({ id, fullName, scorecards }) => (
          <Flex
            key={id}
            direction="column"
            pt="4"
            pb="3"
            pr="2"
            fontWeight="semibold"
            color="gray.800"
            gap="1"
            borderBottom="1px solid"
            borderColor="gray.100"
            _last={{ borderBottom: "none" }}
          >
            <Box mb="1">{fullName}</Box>
            {scorecards.map((scorecard) => {
              const { id, overallNotes, overallScore, competencies } =
                scorecard;

              const scorecardItems: ExpandableGroupListItem[] = [];

              if (scorecard.overallScoringEnabled) {
                scorecardItems.push({
                  id,
                  heading: "Interview score",
                  notes: overallNotes,
                  score: overallScore,
                });
              }

              scorecardItems.push(
                ...competencies.map((competency) => ({
                  id: competency.id,
                  heading: competency.itemText,
                  notes: competency.notes,
                  score: competency.score,
                }))
              );

              return (
                <ExpandableGroup
                  key={id}
                  groupHeader={scorecard.interviewName ?? "Interview"}
                  subHeader={formatRelativeDate(scorecard.interviewDate, 0)}
                  scoringEnabled={scorecard.overallScoringEnabled}
                  score={overallScore}
                  listItems={scorecardItems}
                />
              );
            })}
          </Flex>
        ))}
      </ScoresTabColumn>
      {competencies.length > 0 && (
        <ScoresTabColumn
          heading="COMPETENCIES"
          subHeading="Avg. score"
          subHeadingTooltip={
            <>
              <b>Average competency score</b> is calculated from scores given by
              interviewers for this candidate in the specific competency.
              <UnorderedList ml="4" mt="2">
                <ListItem>
                  Score data appears when an interviewer has submitted their
                  score.
                </ListItem>
                <ListItem>
                  Score pending appears when an interviewer still needs to
                  complete their score.
                </ListItem>
              </UnorderedList>
            </>
          }
        >
          {competencies.map(({ id, itemText, scorecards, averageScore }) => (
            <Box key={id} borderTopWidth="1px" borderColor="gray.100" py="2.5">
              <ExpandableGroup
                groupHeader={itemText}
                score={averageScore}
                scoringEnabled
                listItems={scorecards.map(({ interviewer, notes, score }) => ({
                  id: interviewer.id,
                  heading: interviewer.fullName,
                  notes,
                  score,
                }))}
              />
            </Box>
          ))}
        </ScoresTabColumn>
      )}
    </Flex>
  );
};

export default ScoresTab;
