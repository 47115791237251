import { useEffect } from "react";

import {
  CallBetaFragment,
  CallNotesBetaQuery,
  CallNotesV3Query,
  useCallClipsLazyQuery,
  useCallScorecardQuery,
} from "../../../graphql";
import { ClipsTabProps } from "../../Recording/SidebarTabs/ClipsTab";
import { ScoresTabProps } from "../../Recording/SidebarTabs/ScoresTab";
import { isScorecardComplete } from "../utils";
import useCallNotes from "./useCallNotes";
import useCallNotesV3 from "./useCallNotesV3";
import useCallTrackers from "./useCallTrackers";
import useCallTraining from "./useCallTraining";
import useCallTranscript from "./useCallTranscript";

export enum CallTab {
  NOTES = "notes",
  POIS = "trackers",
  CLIPS = "clips",
  TRANSCRIPT = "transcript",
  TRAINING = "training",
  AI_NOTES = "ai_notes",
  SCORES = "scores",
}

export const isCallTab = (s: string): s is CallTab =>
  [
    CallTab.NOTES,
    CallTab.POIS,
    CallTab.CLIPS,
    CallTab.TRANSCRIPT,
    CallTab.TRAINING,
    CallTab.AI_NOTES,
    CallTab.SCORES,
  ].includes(s as any);

type CallSidebarParams = {
  call: Pick<
    CallBetaFragment,
    | "id"
    | "greenhouseScorecardLinkWithId"
    | "leverInterviewLinkWithId"
    | "interviewerIds"
    | "scoringEnabled"
    | "iaVersion"
  >;
  currentTab: CallTab | undefined;
  onCallNotesQueryCompleted?: (data: CallNotesBetaQuery) => void;
  onCallNotesV3QueryCompleted?: (data: CallNotesV3Query) => void;
  disableAutofill?: boolean;
  notesForCurrentUserOnly: boolean;
};

/**
 * The types listed here are a bit esoteric in an effort to be DRY. See
 * the definitions of the various helper hooks for more useful types
 */
type CallSidebarReturn = {
  trackers: ReturnType<typeof useCallTrackers>;
  clips: Pick<ClipsTabProps, "clips" | "loading" | "error">;
  training: ReturnType<typeof useCallTraining>;
  notes: Omit<ReturnType<typeof useCallNotes>, "getCallNotes">;
  notesV3: Omit<ReturnType<typeof useCallNotesV3>, "getCallNotesV3">;
  transcript: ReturnType<typeof useCallTranscript>;
  scorecard: Pick<
    ScoresTabProps,
    | "currentUserScorecard"
    | "otherUsersScorecards"
    | "showViewCandidate"
    | "loading"
    | "error"
  > & { isCurrentUserScorecardComplete: boolean };
};

export function useCallSidebar({
  call,
  currentTab,
  onCallNotesQueryCompleted,
  onCallNotesV3QueryCompleted,
  disableAutofill,
  notesForCurrentUserOnly,
}: CallSidebarParams): CallSidebarReturn {
  const callId = call.id;
  const trackers = useCallTrackers({ callId });
  const training = useCallTraining({ callId });
  const transcript = useCallTranscript({ callId, notesForCurrentUserOnly });
  const { getCallNotes, ...notes } = useCallNotes({
    call,
    onCallNotesQueryCompleted,
    disableAutofill,
    notesForCurrentUserOnly,
  });
  const { getCallNotesV3, ...notesV3 } = useCallNotesV3({
    call,
    onCallNotesV3QueryCompleted,
    disableAutofill,
    notesForCurrentUserOnly,
  });

  const [getCallClips, callClipsQuery] = useCallClipsLazyQuery({
    variables: { callId },
  });

  const {
    data: scorecardResults,
    loading: scorecardLoading,
    error: scorecardError,
  } = useCallScorecardQuery({
    variables: { callId },
    returnPartialData: true,
  });

  useEffect(() => {
    if (currentTab === CallTab.CLIPS) {
      getCallClips();
    } else if (currentTab === CallTab.NOTES) {
      if (call.iaVersion === 3.0) {
        getCallNotesV3();
      } else {
        getCallNotes();
      }
    }
  }, [currentTab]);

  return {
    trackers,
    training,
    transcript,
    notes,
    notesV3,
    clips: {
      loading: callClipsQuery.loading,
      error: callClipsQuery.error?.message,
      clips:
        callClipsQuery.data?.call?.callClips ??
        callClipsQuery.previousData?.call?.callClips ??
        [],
    },
    scorecard: {
      loading: scorecardLoading,
      error: scorecardError?.message,
      currentUserScorecard:
        scorecardResults?.call?.currentUserScorecard ?? undefined,
      otherUsersScorecards:
        scorecardResults?.call?.otherUsersScorecards ?? undefined,
      showViewCandidate: scorecardResults?.call?.canViewOtherScores,
      isCurrentUserScorecardComplete: isScorecardComplete(scorecardResults),
    },
  };
}
