import { TranscriptSegmentFragment, TranscriptWord } from "../../../graphql";
import { ClipPlayRange, ClipRange } from "./types";

export const between = (
  value: number,
  lower: number,
  upper: number
): boolean => {
  return value >= lower && value < upper;
};

export const betweenInclusive = (
  value: number,
  lower: number,
  upper: number
): boolean => {
  return value >= lower && value <= upper;
};

export const clipLengthLabel = (
  startTime: number,
  endTime: number,
  shortLabel: boolean
): string => {
  const seconds = endTime - startTime;
  const minutes = Math.floor(seconds / 60);
  if (shortLabel) return seconds > 60 ? `${minutes}m` : `${seconds}s`;

  if (seconds < 60) return `${seconds} second`;

  return `${minutes} minute`;
};

export const getSafeRange = (
  desiredRange: ClipRange,
  segmentBounds: ClipRange
): ClipRange => {
  if (
    segmentBounds.start > desiredRange.end ||
    segmentBounds.end < desiredRange.start
  ) {
    return segmentBounds;
  }
  return desiredRange;
};

export const clipDefaultEndTime = (
  callDuration: number | null | undefined,
  clipStartTime: number
): number => {
  return callDuration && callDuration < 300
    ? callDuration
    : clipStartTime + 300;
};

type TranscriptSelection = {
  startSegmentIdx: number;
  endSegmentIdx: number;

  startWord: TranscriptWord;
  endWord: TranscriptWord;

  // There is no current segment if the playhead is before/after all segments
  playSegmentIdx?: number;
  // There is no current word if the playhead is before/after/between words
  playWord?: TranscriptWord;
};

export const findSelectedSegments = (
  transcript: TranscriptSegmentFragment[],
  playRange: ClipPlayRange
): TranscriptSelection => {
  let startSegmentIdx: number | undefined;
  let playSegmentIdx: number | undefined;
  let endSegmentIdx: number | undefined;

  let startWord: TranscriptWord | undefined;
  let endWord: TranscriptWord | undefined;
  let playWord: TranscriptWord | undefined;

  transcript.forEach((segment, segmentIndex) => {
    const segmentWordStart = segment.words[0].startTime;
    const segmentWordEnd = segment.words[segment.words.length - 1].endTime;

    let prevWord = segment.words[0];
    segment.words.forEach((word) => {
      if (!startWord && playRange.start < word.endTime) {
        startSegmentIdx = segmentIndex;
        startWord = word;
      }
      if (!endWord) {
        if (playRange.end < word.startTime) {
          endSegmentIdx = segmentIndex;
          endWord = prevWord;
        } else if (
          betweenInclusive(playRange.end, word.startTime, word.endTime)
        ) {
          endSegmentIdx = segmentIndex;
          endWord = word;
        }
      }
      if (
        !playWord &&
        betweenInclusive(playRange.play, word.startTime, word.endTime)
      ) {
        playWord = word;
      }
      if (
        !playSegmentIdx &&
        betweenInclusive(playRange.play, segmentWordStart, segmentWordEnd)
      ) {
        playSegmentIdx = segmentIndex;
      }
      prevWord = word;
    });
  });

  const finalSegment = transcript[transcript.length - 1];
  const finalWord = finalSegment.words[finalSegment.words.length - 1];
  const lastSegmentIdx = transcript.length - 1;
  // The startWord and startSegmentIdx should always be set, but this satisfies
  // the type checker.
  if (startWord === undefined) {
    // eslint-disable-next-line prefer-destructuring
    startWord = finalWord;
  }
  if (startSegmentIdx === undefined) {
    startSegmentIdx = lastSegmentIdx;
  }

  // Handle case when selection is past the final word of the transcript
  if (endWord === undefined) {
    endWord = finalWord;
    endSegmentIdx = lastSegmentIdx;
  }
  if (endSegmentIdx === undefined) {
    endSegmentIdx = lastSegmentIdx;
  }
  return {
    startSegmentIdx,
    playSegmentIdx,
    endSegmentIdx,
    startWord,
    playWord,
    endWord,
  };
};
