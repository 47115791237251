import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
} from "@chakra-ui/react";
import React from "react";

import { useToast } from "../../../components";
import {
  ScheduledInterviewListItemFragment,
  ScheduledInterviewType,
  UserInfoFragment,
  useUpdateScheduledInterviewMutation,
} from "../../graphql";
import { ScheduledInterviewForm } from "../ScheduledInterviewForm";
import { FormValues } from "../ScheduledInterviewForm/ScheduledInterviewForm";

type EditScheduledInterviewModalProps = {
  scheduledInterview: ScheduledInterviewListItemFragment;
} & Omit<ModalProps, "children">;

const EditScheduledInterviewModal: React.FC<
  EditScheduledInterviewModalProps
> = ({
  scheduledInterview,
  size = "xl",
  closeOnOverlayClick = false,
  onClose,
  ...rest
}) => {
  const toast = useToast();
  const [updateScheduledInterview, { loading }] =
    useUpdateScheduledInterviewMutation({
      onError: (err) => {
        toast({
          title: "Error",
          description: err.message,
          status: "error",
        });
      },
      onCompleted(data) {
        const scheduledInterview =
          data.updateScheduledInterview?.scheduledInterview;
        if (!scheduledInterview) return;
        toast({
          title: "Success",
          description: <Text>Interview updated.</Text>,
          status: "success",
          isClosable: true,
        });
      },
    });
  const onSubmit = (formValues: FormValues): void => {
    updateScheduledInterview({
      variables: {
        id: scheduledInterview.id,
        ...formValues,
      },
    });
    onClose();
  };

  const scheduledStart = new Date(
    scheduledInterview.scheduledStart
  ).toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: scheduledInterview.timezoneName ?? undefined,
  });

  const editRestriction =
    scheduledInterview.scheduledType === ScheduledInterviewType.Email;

  return (
    <Modal
      size={size}
      closeOnOverlayClick={closeOnOverlayClick}
      onClose={onClose}
      {...rest}
    >
      <ModalOverlay>
        <ModalContent>
          <ModalHeader data-testid="edit-scheduled-interview-modal-header">
            Edit Interview {editRestriction ? "(From Calendar Invite)" : ""}
          </ModalHeader>
          <ModalCloseButton data-testid="edit-scheduled-interview-modal-close" />
          <ModalBody height="auto" overflow="scroll" pb={12}>
            {editRestriction && (
              <Box p={6} mb={6} bg="yellow.100">
                Changes to interview date, time, participants, and meeting type
                for this scheduled interview are managed in the calendar invite.
              </Box>
            )}
            <ScheduledInterviewForm
              mode="update"
              editRestriction={editRestriction}
              submitButtonText="Save & Close"
              onSubmit={onSubmit}
              isLoading={loading}
              defaultScheduledStartTime={scheduledStart}
              defaultTimezoneName={scheduledInterview.timezoneName ?? undefined}
              defaultDurationMinutes={
                scheduledInterview.durationMinutes ?? undefined
              }
              defaultInterviewType={
                scheduledInterview.interviewType ?? undefined
              }
              defaultPhoneNumber={scheduledInterview.phoneNumber ?? undefined}
              defaultGoogleMeetMeetingUrl={
                scheduledInterview.googleMeetMeetingUrl ?? undefined
              }
              defaultZoomJoinUrl={
                scheduledInterview.zoomJoinUrl ||
                scheduledInterview.zoomMeetingUrl ||
                undefined
              }
              defaultTeamsMeetingUrl={
                scheduledInterview.teamsMeetingUrl ?? undefined
              }
              defaultCandidate={scheduledInterview.candidate ?? undefined}
              defaultInterviewers={scheduledInterview.scheduledInterviewers.reduce(
                (acc: UserInfoFragment[], si) => {
                  if (si.user) {
                    acc.push(si.user);
                  }
                  return acc;
                },
                []
              )}
              defaultPositionId={scheduledInterview.position?.id}
              defaultCallGuideId={scheduledInterview.callGuide?.id}
              defaultName={scheduledInterview.name ?? undefined}
            />
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default EditScheduledInterviewModal;
