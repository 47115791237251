import React, { useState } from "react";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";

import { usePageTracker } from "../../../utils/googleAnalytics";
import { MetricName } from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../../hooks/useCurrentUser";
import { AnalyticsPageLayout } from "./AnalyticsPageLayout";
import useEnabledMetrics from "./useEnabledMetrics";
import { canViewAllMetrics } from "./utils";

const AnalyticsPage: React.FC = () => {
  usePageTracker("analytics");
  const currentUser = useCurrentUser();
  const [selectedOrgId, setSelectedOrgId] = useState<string>(
    currentUser.organization.id
  );
  const [showBHDemoDashboard, setShowBHDemoDashboard] = useState<boolean>(true);

  const { metric: metricParam } = useParams<{ metric: string }>();
  const metric = metricParam?.toUpperCase() as MetricName;
  const isDashboard = !metric;
  const dashboardEnabled = useFeatureFlag("analytics:dashboard");

  const enabledMetrics = useEnabledMetrics();
  const dashboardMetrics = [
    MetricName.OnTimeInterviews,
    MetricName.CandidateTalkRatio,
    MetricName.ScorecardCompletionRate,
    MetricName.ScorecardCompletionTime,
    MetricName.QuestionsAsked,
  ];
  const allDashboardMetricsEnabled = !dashboardMetrics.some(
    (m) => !enabledMetrics.includes(m)
  );
  const dashboardAvailable = dashboardEnabled && allDashboardMetricsEnabled;

  // personal analytics demo
  let isPersonalInsights = false;
  const myInsightsEnabled = useFeatureFlag("personal-insights-demo");
  const location = useLocation();
  if (location.pathname === "/insights/my-insights") {
    if (myInsightsEnabled) {
      isPersonalInsights = true;
    } else {
      return <Navigate to="/insights/overview" replace />;
    }
  }

  if (!canViewAllMetrics(currentUser)) {
    return <Navigate to="/" replace />;
  }

  if (metric && !enabledMetrics.includes(metric)) {
    return <Navigate to="/insights/overview" replace />;
  }

  if (isDashboard && !dashboardAvailable) {
    return <Navigate to="/insights/total_interviews" replace />;
  }

  // main content should have gray background for metrics
  const grayBgProps = {
    bg: "unset",
    border: "0",
  };

  // sidebar styles for release 4 with dashboard
  const sidebarProps = {
    ...grayBgProps,
    width: "304px",
    minWidth: "304px",
    mr: "0",
  };

  return (
    <AnalyticsPageLayout
      metric={metric}
      data-tour-id="analytics-page"
      sidebarProps={
        dashboardEnabled || isPersonalInsights ? sidebarProps : undefined
      }
      mainContentProps={metric || isPersonalInsights ? undefined : grayBgProps}
      maxW={metric ? undefined : "1200px"}
      dashboardAvailable={allDashboardMetricsEnabled && dashboardEnabled}
      isPersonalInsights={isPersonalInsights}
    >
      <Outlet
        context={{
          selectedOrgId,
          setSelectedOrgId,
          showBHDemoDashboard,
          setShowBHDemoDashboard,
        }}
      />
    </AnalyticsPageLayout>
  );
};

export default AnalyticsPage;
