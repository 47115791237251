import { MutableRefObject, useEffect, useRef, useState } from "react";

const useIsOverflowing = <T extends HTMLElement>(options?: {
  width?: boolean;
}): { ref: MutableRefObject<T | null>; isOverflowing: boolean } => {
  const ref = useRef<T | null>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (!ref.current) return;

    const el = ref.current;
    const obs = new ResizeObserver(() => {
      const contentSize = options?.width ? el.scrollWidth : el.scrollHeight;
      const displayedSize = options?.width ? el.clientWidth : el.clientHeight;
      setIsOverflowing(contentSize > displayedSize);
    });

    obs.observe(el);
    return () => obs.unobserve(el);
  }, [ref.current]);

  return { ref, isOverflowing };
};

export default useIsOverflowing;
