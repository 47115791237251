import "./scrollbar.css";

import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { PropsWithChildren, useEffect } from "react";

import { useIsSmallScreen } from "../../hooks/useIsSmallScreen";
import useIsOverflowing from "../../main/hooks/useIsOverflowing";
import { SpotlightSearchState } from "../SpotlightSearch";
import AnimatedLogo from "./AnimatedLogo";
import { COLLAPSED_WIDTH, sidebarAnimation } from "./animations";
import MobileHeader from "./MobileHeader";
import SidebarNavItem, { NavItem } from "./SidebarNavItem";
import useSidebarState from "./useSidebarState";

type SidebarNavBaseProps = {
  app: "main" | "plan";
  navItems: NavItem[];
  footerNavItems: NavItem[];
  skipAnimationDelay?: boolean;
  searchState?: SpotlightSearchState;
  onAnimationComplete?(): void;
  onShowSearch?(): void;
  isImpersonated?: boolean;
};

export const SidebarNavBase: React.FC<
  PropsWithChildren<SidebarNavBaseProps>
> = ({
  app,
  navItems,
  footerNavItems,
  skipAnimationDelay,
  searchState,
  onShowSearch,
  isImpersonated = false,
  onAnimationComplete,
  children,
}) => {
  const isSmallScreen = useIsSmallScreen();

  const { ref: navItemsContainerRef, isOverflowing: navItemsOverflowing } =
    useIsOverflowing<HTMLDivElement>();

  const [state, updateState] = useSidebarState();
  const { expandedItems } = state;

  const onExpand = (navItem: NavItem): void =>
    updateState({
      expandedItems: expandedItems.concat(navItem.label),
    });
  const onCollapse = (navItem: NavItem): void =>
    updateState({
      expandedItems: expandedItems.filter((label) => label !== navItem.label),
    });

  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: state.isOpen,
  });

  useEffect(() => {
    if (!isOpen) {
      updateState({ isOpen: false, expandedItems: [] });
    } else {
      updateState({ isOpen: true });
    }
  }, [isOpen]);

  useEffect(() => {
    updateState({ skipAnimationDelay });
  }, [skipAnimationDelay]);

  if (isSmallScreen) {
    return (
      <MobileHeader
        app={app}
        onShowSearch={onShowSearch}
        searchState={searchState}
        navItems={navItems}
        bottomNavItems={footerNavItems.slice(0, -1)}
        footerNavItems={footerNavItems.slice(-1)}
        onClose={() => updateState({ expandedItems: [] })}
        isImpersonated={isImpersonated}
      >
        {children}
      </MobileHeader>
    );
  }

  return (
    <>
      <Box ml={`${COLLAPSED_WIDTH}px`}>{children}</Box>

      <Box
        position="fixed"
        top="0"
        bottom="0"
        left="0"
        zIndex="5"
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        bg={isImpersonated ? "navBackgroundImpersonated" : "navBackground"}
      >
        <motion.div
          {...sidebarAnimation}
          style={{ height: "100%" }}
          custom={{ skipAnimationDelay }}
          onAnimationComplete={onAnimationComplete}
          animate={isOpen ? "expanded" : "collapsed"}
        >
          <Flex direction="column" py="4" color="white" h="100%">
            <AnimatedLogo
              app={app}
              isOpen={isOpen}
              skipAnimationDelay={skipAnimationDelay}
            />

            <Box
              id="sidebar-nav-items"
              overflowY="auto"
              overflowX="hidden"
              mt="4"
              ref={navItemsContainerRef}
            >
              {navItems.map((navItem) => (
                <SidebarNavItem
                  sidebarIsOpen={isOpen}
                  skipAnimationDelay={skipAnimationDelay}
                  key={navItem.label}
                  navItem={navItem}
                  isExpanded={expandedItems.includes(navItem.label)}
                  onExpand={() => onExpand?.(navItem)}
                  onCollapse={() => onCollapse?.(navItem)}
                />
              ))}
            </Box>

            <Box
              mt="auto"
              flexShrink="0"
              borderTop="1px solid"
              borderColor={
                navItemsOverflowing ? "whiteAlpha.300" : "transparent"
              }
            >
              {footerNavItems.map((navItem) => (
                <SidebarNavItem
                  sidebarIsOpen={isOpen}
                  skipAnimationDelay={skipAnimationDelay}
                  key={navItem.label}
                  navItem={navItem}
                />
              ))}
            </Box>
          </Flex>
        </motion.div>
      </Box>
    </>
  );
};
