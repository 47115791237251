import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";
import { HiOutlineExclamationCircle } from "react-icons/hi";

export const CheckMarkCircle = (props: IconProps): JSX.Element => (
  <Icon color="green.400" {...props}>
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m9 12l2 2l4-4m6 2a9 9 0 1 1-18 0a9 9 0 0 1 18 0Z"
    />
  </Icon>
);

export const ExclamationCircle = (): JSX.Element => (
  <Icon
    as={HiOutlineExclamationCircle}
    mr="1"
    color="red.400"
    mt="1"
    fontSize="2xl"
  />
);

export const PlanLeftArrow = (props: IconProps): JSX.Element => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.53033 0.46967C8.82322 0.762563 8.82322 1.23744 8.53033 1.53033L2.81066 7.25L19 7.25C19.4142 7.25 19.75 7.58579 19.75 8C19.75 8.41421 19.4142 8.75 19 8.75L2.81066 8.75L8.53033 14.4697C8.82322 14.7626 8.82322 15.2374 8.53033 15.5303C8.23744 15.8232 7.76256 15.8232 7.46967 15.5303L0.46967 8.53033C0.397763 8.45842 0.343509 8.37555 0.306909 8.28709C0.270239 8.19866 0.25 8.10169 0.25 8C0.25 7.89831 0.270239 7.80134 0.306909 7.71291C0.343509 7.62445 0.397763 7.54158 0.46967 7.46967L7.46967 0.46967C7.76256 0.176777 8.23744 0.176777 8.53033 0.46967Z"
      fill="currentColor"
    />
  </Icon>
);
