import { Box, Flex, Tag, TagLabel } from "@chakra-ui/react";
import React, {
  forwardRef,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  components,
  GroupBase,
  MenuListProps,
  OptionProps,
  SingleValueProps,
} from "react-select";

import { Alert, LoadingIndicator, Select } from "../../../components";
import useDebounce from "../../../hooks/useDebounce";
import useSelectTheme from "../../../hooks/useSelectTheme";
import { distinct } from "../../../utils/array";
import {
  CallGuideListItemFragment,
  useIaCallGuidesLazyQuery,
} from "../../graphql";

type OptionType = {
  label: string;
  value: CallGuideItem;
};

type GroupedOptions = GroupBase<OptionType>;

type CallGuideItem = CallGuideListItemFragment & {
  name: string;
  positionName: string;
  recent: boolean;
  suggested: boolean;
  assigned: boolean;
};

type OptionLabelProps = OptionType & { hidePosition?: boolean };

const OptionLabel: React.FC<OptionLabelProps> = ({
  label,
  value,
  hidePosition = false,
}) => (
  <>
    <Flex alignItems="center">
      {label}
      {value?.assigned && (
        <Tag bgColor="pink.50" fontSize="xs" ml={3}>
          <TagLabel>Assigned</TagLabel>
        </Tag>
      )}
      {value?.recent && (
        <Tag colorScheme="gray" fontSize="xs" ml={3}>
          <TagLabel>Recently Used</TagLabel>
        </Tag>
      )}
    </Flex>
    {value?.positionName && !hidePosition && (
      <Box color="gray.400" mt="0.5">
        {value.positionName}
      </Box>
    )}
  </>
);

const formatGroupLabel: React.FC<GroupedOptions> = ({ label }) => (
  <Box pl="3" fontWeight="bold" color="gray.600" fontSize="md">
    {label}
  </Box>
);

interface CGSortable {
  updatedAt: string;
  isTemplate?: boolean;
}

const sortCallGuides = (a: CGSortable, b: CGSortable): number => {
  if (a.isTemplate && b.isTemplate) {
    return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
  }
  if (a.isTemplate) return -1;
  if (b.isTemplate) return 1;
  return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
};

interface CallGuideSelectProps {
  name?: string;
  placeholder?: string;
  defaultCallGuideId?: string | null;
  onSelect?: (callGuide: CallGuideItem | undefined) => void;
  isDisabled?: boolean;
  positionId?: string;
  autoFocus?: boolean;
  overrideTheme?: any;
  hidePosition?: boolean;
  autoSelectAssignedGuide?: boolean;
}

const CallGuideSelect = forwardRef<any, CallGuideSelectProps>(
  (
    {
      name,
      placeholder,
      defaultCallGuideId,
      onSelect,
      isDisabled = false,
      positionId,
      autoFocus = false,
      overrideTheme = {},
      hidePosition = false,
      autoSelectAssignedGuide = false,
    },
    ref
  ) => {
    const { groupHeading = {}, option = {}, ...rest } = overrideTheme;
    const [theme, styles] = useSelectTheme({
      groupHeading: (provided: Record<string, any>) => ({
        ...provided,
        textTransform: "none",
        ...groupHeading,
      }),
      option: (provided: Record<string, any>, state: { isFocused: any }) => ({
        ...provided,
        backgroundColor: state.isFocused ? "#EDF8FE" : "white",
        color: "primary",
        ...option,
      }),
      indicatorsContainer: (provided: Record<string, any>) => {
        const result = { ...provided };
        if (isDisabled) result.display = "none";
        return result;
      },
      ...rest,
    });

    const [query, setQuery] = useState("");
    const [didUserClearSelect, setDidUserClearSelect] = useState(false);
    const debouncedQuery = useDebounce<string>(query, 200);
    const [callGuide, setCallGuide] = useState<CallGuideItem | null>();

    const [
      getIaCallGuides,
      {
        loading: callGuidesLoading,
        error: callGuidesError,
        data: callGuidesData,
      },
    ] = useIaCallGuidesLazyQuery();

    useEffect(() => {
      getIaCallGuides({
        variables: {
          query,
          defaultCallGuideId: defaultCallGuideId || undefined,
          limit: 50,
        },
      });
    }, [debouncedQuery]);

    const allGuides = useMemo<CallGuideItem[]>(() => {
      const userGuides = (callGuidesData?.currentUser?.callGuides ?? [])
        .concat()
        .sort(sortCallGuides);
      const sharedGuides = (callGuidesData?.currentUser?.sharedCallGuides ?? [])
        .concat()
        .sort(sortCallGuides);
      const recentGuides = callGuidesData?.currentUser?.recentCallGuides ?? [];
      const assignedGuides =
        callGuidesData?.currentUser?.assignedCallGuides ?? [];
      const guides = distinct([
        ...userGuides,
        ...sharedGuides,
        ...recentGuides,
        ...assignedGuides,
      ]) as CallGuideItem[];
      const finalGuides = guides.map((guide) => {
        return {
          ...guide,
          name: (guide.name ?? "").trim(),
          positionName: guide.position?.title?.trim() ?? "No Position",
          recent: !!recentGuides.find((g) => g.id === guide.id),
          assigned: !!assignedGuides.find((g) => g.id === guide.id),
          suggested: !!(positionId && guide.position?.id === positionId),
        };
      });
      return finalGuides
        .filter((guide) => !!guide.name)
        .sort((a, b) => a.name.localeCompare(b.name))
        .sort((a, b) => a.positionName.localeCompare(b.positionName))
        .sort((a, b) => Number(b.suggested) - Number(a.suggested))
        .sort((a, b) => Number(b.recent) - Number(a.recent))
        .sort((a, b) => Number(b.assigned) - Number(a.assigned));
    }, [callGuidesData, positionId]);

    useEffect(() => {
      // only set the default or assigned guide if one isn't already selected
      if (callGuide) return;
      const assignedGuides = allGuides.filter((guide) => guide.assigned);
      if (
        assignedGuides.length === 1 &&
        autoSelectAssignedGuide &&
        !didUserClearSelect &&
        assignedGuides[0].position
      ) {
        setCallGuide(assignedGuides[0]);
        if (onSelect) onSelect(assignedGuides[0]);
      }
      const defaultGuide = allGuides.find(
        (guide) => guide.id === defaultCallGuideId
      );
      if (defaultGuide) {
        setCallGuide(defaultGuide);
        if (onSelect) onSelect(defaultGuide);
      }
    }, [defaultCallGuideId, allGuides]);

    const selectOptions = useMemo(() => {
      return allGuides.map((guide) => ({
        label: guide.name,
        value: guide,
      }));
    }, [allGuides]);

    const value = callGuide
      ? {
          label: callGuide.name,
          value: callGuide,
        }
      : null;

    if (callGuidesError) {
      return <Alert status="error" description="Error loading guides" />;
    }
    return (
      <Select
        id={`call-guide-select${name ? `-${name}` : ""}`}
        ref={ref}
        name={name}
        theme={theme}
        styles={styles}
        autoFocus={autoFocus}
        isClearable
        menuShouldBlockScroll
        menuShouldScrollIntoView
        menuPlacement="auto"
        placeholder={placeholder}
        components={{
          SingleValue: hidePosition ? CustomValueNoPosition : CustomValue,
          MenuList: CustomMenuList as React.FC<
            MenuListProps<
              PropsWithChildren<OptionType>,
              false,
              PropsWithChildren<GroupedOptions>
            >
          >,
          Option: CustomOption,
        }}
        minMenuHeight={200}
        noOptionsMessage={(value) => ""}
        isLoading={callGuidesLoading}
        value={value}
        inputValue={query}
        filterOption={null}
        options={selectOptions}
        formatGroupLabel={formatGroupLabel}
        onInputChange={(value, action) => {
          if (action.action === "input-change") {
            setQuery(value.toLowerCase());
          }
        }}
        blurInputOnSelect
        onBlur={() => {
          setQuery("");
        }}
        onChange={(option, action) => {
          if (action.action === "clear") {
            setDidUserClearSelect(true);
          }
          const value = (
            option as {
              value: CallGuideItem;
            }
          )?.value;
          setCallGuide(value);
          if (onSelect) onSelect(value);
        }}
        isDisabled={isDisabled}
        // Typescript doesn't like that we're using this data value to piggyback
        // custom data into the Select for its' children components. ESLint
        // doesn't like that we're using @ts-ignore
        //
        // eslint-disable-next-line
        // @ts-ignore
        data={{
          query,
          counts: {
            query: allGuides.length,
          },
          loading: callGuidesLoading || false,
        }}
      />
    );
  }
);

const CustomOption: React.FC<
  OptionProps<
    PropsWithChildren<OptionType>,
    boolean,
    PropsWithChildren<GroupedOptions>
  >
> = (props) => {
  return (
    <components.Option {...props}>
      <Flex alignItems="center" data-testid={props.data.value.id}>
        {props.isSelected && (
          <Box pl="4" flexShrink={0}>
            <img
              width="13px"
              height="11px"
              src="/static/images/blue-checkmark.svg"
            />
          </Box>
        )}
        <Box mx={2}>
          <OptionLabel {...props.data} />
        </Box>
      </Flex>
    </components.Option>
  );
};

const CustomValue: React.FC<
  SingleValueProps<
    PropsWithChildren<OptionType>,
    boolean,
    PropsWithChildren<GroupedOptions>
  >
> = (props) => {
  return (
    <components.SingleValue {...props}>
      <OptionLabel {...props.getValue()[0]} />
    </components.SingleValue>
  );
};

const CustomValueNoPosition: React.FC<
  SingleValueProps<
    PropsWithChildren<OptionType>,
    boolean,
    PropsWithChildren<GroupedOptions>
  >
> = (props) => {
  return (
    <components.SingleValue {...props}>
      <OptionLabel {...props.getValue()[0]} hidePosition />
    </components.SingleValue>
  );
};

type CallGuidesData = {
  tab: string;
  setTab: (tab: string) => void;
  query: string;
  loading: boolean;
  counts: {
    query: number;
    all: number;
    recent: number;
    suggested: number;
  };
};

const CustomMenuList: React.FC<
  MenuListProps<
    PropsWithChildren<OptionType>,
    false,
    PropsWithChildren<GroupedOptions>
  > & { selectProps: { data: CallGuidesData } }
> = (props) => {
  const { data } = props.selectProps;
  if (data.loading) {
    return (
      <Flex p="4" width="100%">
        <LoadingIndicator />
      </Flex>
    );
  }
  return (
    <components.MenuList {...props}>
      <>
        {data.query.length ? (
          <Box ml="4" color="gray.500" mt="3" mb="2">
            {data.counts.query} results for &apos;{data.query}&apos; in{" "}
            {data.tab} guides
          </Box>
        ) : null}
        {props.options.length ? (
          props.children
        ) : data.query.length ? null : (
          <Box ml="4" color="gray.500" my="3">
            No guides found.
          </Box>
        )}
      </>
    </components.MenuList>
  );
};

CallGuideSelect.displayName = "CallGuideSelect";
export default CallGuideSelect;
