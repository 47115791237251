interface ThumbnailSize {
  width: string;
  borderRadius: string;
  iconSize: string;
  durationInset: string;
  durationFontSize: string;
  durationBorderRadius: string;
  durationHeight: string;
  durationPadding: string;
}

export const THUMBNAIL_SM_WIDTH = "120px";
export const THUMBNAIL_SM_HEIGHT = "80px";
export const THUMBNAIL_SM_BORDER_RADIUS = "4px";

export const THUMBNAIL_MD_WIDTH = "253px";
export const THUMBNAIL_MD_HEIGHT = "168px";
export const THUMBNAIL_MD_BORDER_RADIUS = "8px";

export const ThumbnailSizes: { [key: string]: ThumbnailSize } = {
  auto: {
    width: "100%",
    borderRadius: THUMBNAIL_MD_BORDER_RADIUS,
    iconSize: "40",
    durationInset: "4px",
    durationFontSize: "sm",
    durationBorderRadius: "4px",
    durationHeight: "6",
    durationPadding: "1.5",
  },
  sm: {
    width: THUMBNAIL_SM_WIDTH,
    borderRadius: THUMBNAIL_SM_BORDER_RADIUS,
    iconSize: "24",
    durationInset: "3px",
    durationFontSize: "sm",
    durationBorderRadius: "3px",
    durationHeight: "5",
    durationPadding: "1",
  },
  md: {
    width: THUMBNAIL_MD_WIDTH,
    borderRadius: THUMBNAIL_MD_BORDER_RADIUS,
    iconSize: "32",
    durationInset: "8px",
    durationFontSize: "sm",
    durationBorderRadius: "4px",
    durationHeight: "6",
    durationPadding: "1.5",
  },
};
