import { Box, Flex, Select } from "@chakra-ui/react";
import React from "react";

export const LimitSelect: React.FC<{
  limit?: number;
  limits: number[];
  onChange: (e: React.SyntheticEvent<HTMLSelectElement, Event>) => void;
}> = ({ limit, limits, onChange }) => {
  return (
    <Flex alignItems="center">
      <Box px={2} whiteSpace="nowrap">
        Max results
      </Box>
      <Select
        defaultValue={limit}
        size="sm"
        bg="white"
        width="120px"
        onChange={onChange}
      >
        {limits.map((l) => (
          <option key={l} value={l}>
            {l}
          </option>
        ))}
      </Select>
    </Flex>
  );
};
