export default {
  baseStyle: ({
    isCentered,
    scrollBehavior,
  }: Record<string, any>): Record<string, any> => ({
    overlay: {
      display: "flex",
      justifyContent: "center",
      alignItems: isCentered ? "center" : "flex-start",
      overflow: scrollBehavior === "inside" ? "hidden" : "auto",
      background: "rgb(26,32,44,0.4)",
    },
    closeButton: {
      color: "gray.400",
      top: "4",
      right: "4",
    },
    header: {
      fontSize: "21px",
      fontWeight: "bold",
      px: "8",
      py: "6",
    },
    body: {
      px: "8",
      pt: "4",
      pb: "8",
    },
    footer: {
      px: "8",
      py: "6",
    },
  }),
  variants: {
    closeOutside: {
      closeButton: {
        top: "-2",
        transform: "translateY(-100%);",
        right: 0,
        boxSize: "6",
        color: "white",
        cursor: "pointer",
        _hover: {
          bg: "none",
        },
      },
    },
    player: {
      overlay: {
        background: "blackAlpha.600",
        backdropFilter: "auto",
        backdropBlur: "1px",
      },
      content: {
        borderRadius: "lg",
        mx: "5",
      },
      body: {
        px: 0,
        py: 0,
        pt: 0,
        pb: 0,
        borderRadius: "lg",
        overflow: "hidden",
      },
    },
  },
};
