import { Flex, FlexProps } from "@chakra-ui/react";
import React from "react";

export const TabHeaderText: React.FC<FlexProps> = ({ children, ...rest }) => {
  return (
    <Flex
      alignItems="center"
      fontWeight="medium"
      fontSize="sm"
      color="gray.700"
      minH="67px"
      {...rest}
    >
      {children}
    </Flex>
  );
};

const TabHeader: React.FC<FlexProps> = ({ children, ...rest }) => {
  return (
    <Flex
      px="10"
      justifyContent="space-between"
      alignItems="center"
      borderBottom="gray.100"
      {...rest}
    >
      {children}
    </Flex>
  );
};

export default TabHeader;
