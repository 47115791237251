import { Box, Flex, Icon, StyleProps } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";
import { HiOutlineInformationCircle } from "react-icons/hi2";

type SettingsNoteProps = PropsWithChildren<
  StyleProps & {
    showIcon?: boolean;
  }
>;

/** Renders `children` inside a gray "info" note box */
const SettingsNote = React.forwardRef<HTMLDivElement, SettingsNoteProps>(
  ({ showIcon = true, children, ...styles }, ref) => (
    <Flex bg="gray.50" borderRadius="md" p="2" pb="2.5" gap="1" {...styles}>
      {showIcon && (
        <Icon
          as={HiOutlineInformationCircle}
          boxSize="4"
          m="0.5"
          color="gray.500"
        />
      )}
      <Box fontSize="sm" color="gray.600">
        {children}
      </Box>
    </Flex>
  )
);

export default SettingsNote;
