import {
  Flex,
  Icon,
  SystemProps,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { HiVideoCameraSlash } from "react-icons/hi2";

import { useIsSmallScreen } from "../../../../hooks/useIsSmallScreen";
import {
  InterviewImportDecisionReason,
  ScheduledInterviewListItemFragment,
} from "../../../graphql";

type RecordingDisabledMessageProps = {
  scheduledInterview: Pick<
    ScheduledInterviewListItemFragment,
    "isImported" | "isVideo" | "importDecision" | "importDecisionReason"
  >;
} & SystemProps;

/**
 * Renders a "no video" icon, "Recording disabled" text,
 * and an explanatory tooltip that is also mobile-friendly
 */
const RecordingDisabledMessage: React.FC<RecordingDisabledMessageProps> = ({
  scheduledInterview,
  ...styles
}) => {
  const isSmallScreen = useIsSmallScreen();
  const {
    isOpen: showingMobileTooltip,
    onOpen: showMobileTooltip,
    onClose: hideMobileTooltip,
  } = useDisclosure();

  /**
   * For mobile, clear tooltip after scroll or 2.5 seconds
   */
  useEffect(() => {
    if (!showingMobileTooltip) return;
    window.addEventListener("scroll", hideMobileTooltip, { once: true });
    const timeout = setTimeout(hideMobileTooltip, 2500);
    return () => {
      window.removeEventListener("scroll", hideMobileTooltip);
      clearTimeout(timeout);
    };
  }, [showingMobileTooltip]);

  let willRecord = scheduledInterview.isImported;
  // Special case - phone interviews don't use `isImported`
  if (scheduledInterview.isVideo === false) {
    willRecord = scheduledInterview.importDecision;
  }
  if (willRecord || !scheduledInterview.importDecisionReason) return null;

  const reasonDescriptions: Partial<
    Record<InterviewImportDecisionReason, string>
  > = {
    [InterviewImportDecisionReason.Exclude]:
      "An interviewer in this interviewer is in the exclude list.",
    [InterviewImportDecisionReason.OptOutInterviewer]:
      "An interviewer opted out of recording.",
    [InterviewImportDecisionReason.OptOutCandidate]:
      "The candidate opted out of recording.",
    [InterviewImportDecisionReason.NoActiveBhUser]:
      "There is no active BrightHire user scheduled for this interview.",
    [InterviewImportDecisionReason.NoBhUser]:
      "There is no invited BrightHire user scheduled for this interview.",
    [InterviewImportDecisionReason.ExcludeStage]:
      "The stage for this interview is excluded.",
    [InterviewImportDecisionReason.SomeoneOptedOut]:
      "An interviewer or candidate opted out.",
    [InterviewImportDecisionReason.PositionTurnedOff]:
      "Recording is not enabled for this position.",
    [InterviewImportDecisionReason.ApiOverrideTurnedOff]:
      "Recording was disabled through an ATS integration.",
    [InterviewImportDecisionReason.InPersonInterview]:
      "This is an in-person interview.",
  };
  const recordingDisabledReason =
    reasonDescriptions[scheduledInterview.importDecisionReason];

  return (
    <Flex
      alignItems="center"
      color="gray.500"
      onClick={showMobileTooltip}
      {...styles}
    >
      <Icon as={HiVideoCameraSlash} color="gray.500" boxSize="5" />
      <Tooltip
        label={recordingDisabledReason}
        isOpen={isSmallScreen ? showingMobileTooltip : undefined}
        maxW="240"
        placement={isSmallScreen ? "auto-end" : undefined}
      >
        <Text
          fontSize="sm"
          fontWeight="500"
          ml="2"
          lineHeight="5"
          data-testid="scheduled-interview-recording-disabled-text"
        >
          Recording disabled
        </Text>
      </Tooltip>
    </Flex>
  );
};

export default RecordingDisabledMessage;
