import { Flex } from "@chakra-ui/react";
import React from "react";

import useWindowDimensions from "../../../hooks/useWindowDimensions";
import DefaultLayout from "../../layouts/DefaultLayout";
import { ManagerEmptyState } from "./EmptyState";
import ManageTrainingSidebar, {
  ManageTrainingProgramSidebarItem,
} from "./ManageTrainingSidebar";

type ManageTrainingRedirectPageViewProps = {
  trainingPrograms: ManageTrainingProgramSidebarItem[];
  onCreateProgram: (name: string, description: string) => void;
  traineeCount: number;
};

const ManageTrainingRedirectPageView: React.FC<
  ManageTrainingRedirectPageViewProps
> = ({ trainingPrograms, onCreateProgram, traineeCount }) => {
  const { layoutHeight } = useWindowDimensions();
  return (
    <DefaultLayout>
      <Flex height={layoutHeight} overflowX="auto">
        <ManageTrainingSidebar
          trainingPrograms={trainingPrograms}
          onCreateProgram={onCreateProgram}
          traineeCount={traineeCount}
        />
        <ManagerEmptyState onAddTraining={onCreateProgram} />
      </Flex>
    </DefaultLayout>
  );
};

export default ManageTrainingRedirectPageView;
