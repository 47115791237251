import { Text } from "@chakra-ui/react";
import React from "react";

import { useToast } from "../../../../components";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import {
  CandidateFragment,
  useKeepCandidateMutation,
  useUnkeepCandidateMutation,
} from "../../../graphql";
import Modal from "../../Modal/Modal";

interface ToggleCandidateDeletionModalProps {
  candidate: CandidateFragment;
  isOpen: boolean;
  onClose: () => void;
}

const ToggleCandidateDeletionModal: React.FC<
  ToggleCandidateDeletionModalProps
> = ({ candidate, isOpen, onClose }) => {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();

  const [enableCandidateDeletion, { loading: enableLoading }] =
    useUnkeepCandidateMutation({
      onCompleted: () => {
        toast({
          title: "Success",
          status: "success",
        });
        onClose();
      },
      onError: () => {
        toast({
          title: "Error",
          description:
            "The operation could not be completed. Please try again later.",
          status: "error",
        });
      },
    });

  const [disableCandidateDeletion, { loading: disableLoading }] =
    useKeepCandidateMutation({
      onCompleted: () => {
        toast({
          title: "Success",
          status: "success",
        });
        onClose();
      },
      onError: () => {
        toast({
          title: "Error",
          description:
            "The operation could not be completed. Please try again later.",
          status: "error",
        });
      },
    });

  const canDelete = !candidate.skipRedaction;
  const mutation = canDelete
    ? disableCandidateDeletion
    : enableCandidateDeletion;
  const label = `${canDelete ? "Prevent" : "Allow"} deletion`;
  return (
    <Modal
      isOpen={isOpen}
      isLoading={enableLoading || disableLoading}
      headerText={label}
      completeButtonText={label}
      data-testid="candidate-delete-confirm-button"
      onComplete={() => {
        sendGAEvent("delete", "candidates");
        mutation({
          variables: { id: candidate.id },
        });
      }}
      onClose={onClose}
    >
      <Text color="gray.800" fontSize="sm">
        {canDelete
          ? "This will prevent this candidate from being deleted by your organization's data retention policy."
          : "This candidate will display in your organization's data retention section at the scheduled time."}
      </Text>
    </Modal>
  );
};

ToggleCandidateDeletionModal.displayName = "ToggleCandidateDeletionModal";

export default ToggleCandidateDeletionModal;
