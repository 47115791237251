import React from "react";
import { IconType } from "react-icons";

const StarWithCircle: IconType = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="24" fill="#0772D0" fillOpacity="0.08" />
      <g clipPath="url(#clip0_2170_12062)">
        <path
          d="M34.8308 19.6551L27.8882 18.6461L24.7847 12.3543C24.6999 12.182 24.5604 12.0426 24.3882 11.9578C23.9562 11.7445 23.4311 11.9223 23.2151 12.3543L20.1116 18.6461L13.169 19.6551C12.9776 19.6824 12.8026 19.7727 12.6686 19.9094C12.5067 20.0759 12.4174 20.2998 12.4205 20.5321C12.4236 20.7644 12.5187 20.9859 12.6851 21.148L17.7081 26.0453L16.5214 32.9605C16.4936 33.1214 16.5114 33.2869 16.5728 33.4381C16.6342 33.5894 16.7367 33.7204 16.8688 33.8163C17.0009 33.9123 17.1573 33.9693 17.3201 33.9809C17.4829 33.9925 17.6458 33.9583 17.7901 33.882L23.9999 30.6172L30.2097 33.882C30.3792 33.9723 30.5761 34.0023 30.7647 33.9695C31.2405 33.8875 31.5604 33.4363 31.4784 32.9605L30.2917 26.0453L35.3147 21.148C35.4515 21.0141 35.5417 20.8391 35.569 20.6477C35.6429 20.1691 35.3093 19.7262 34.8308 19.6551Z"
          fill="#0074DD"
        />
      </g>
      <defs>
        <clipPath id="clip0_2170_12062">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="translate(10 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StarWithCircle;
