import { Text, WrapItem } from "@chakra-ui/react";
import LogRocket from "logrocket";
import React, { useEffect } from "react";

import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { getTrackerStyles } from "./Styles";
import { LabeledTrackerTabGroup, TrackerTabState } from "./types";

export type TrackerGroupProps = {
  trackerGroup: LabeledTrackerTabGroup;
  setTrackerTabState: (trackerTabState: TrackerTabState) => void;
  trackerTabState?: TrackerTabState;
  index: number;
};

export const TrackerGroup: React.FC<TrackerGroupProps> = ({
  trackerGroup,
  setTrackerTabState,
  trackerTabState,
  index,
}) => {
  const sendGAEvent = useSendGAEvent();
  const sortedKeywords = [...(trackerGroup.trackerKeywords || [])].sort(
    (a, b) => (b?.count || 0) - (a?.count || 0)
  );
  const filteredKeywords = sortedKeywords.filter((kw) => (kw?.count || 0) > 0);
  useEffect(() => {
    if (index === 0) {
      setTrackerTabState({
        ...trackerTabState,
        filteredKeywords,
        labeledTrackerTabGroup: trackerGroup,
        selectedTracker: {
          group: {
            id: trackerGroup.id,
            name: trackerGroup.name,
            label: trackerGroup.label,
          },
        },
      });
    }
  }, [index]);

  if (trackerGroup.count === 0) {
    return null;
  }

  const selectedTrackerGroup = trackerTabState?.selectedTracker.group;
  return (
    <WrapItem
      py="1"
      px="2"
      mb="3"
      boxShadow={getTrackerStyles(trackerGroup, selectedTrackerGroup).boxShadow}
      textColor={getTrackerStyles(trackerGroup, selectedTrackerGroup).textColor}
      background={
        getTrackerStyles(trackerGroup, selectedTrackerGroup).background
      }
      borderColor={
        getTrackerStyles(trackerGroup, selectedTrackerGroup).borderColor
      }
      borderRadius="6px"
      cursor="pointer"
      fontSize="12"
      fontWeight="extrabold"
      color={getTrackerStyles(trackerGroup, selectedTrackerGroup).textColor}
      data-testid={`tracker-group-${trackerGroup.id}`}
      _hover={{
        background: getTrackerStyles(trackerGroup, selectedTrackerGroup)
          .hoverBackground,
      }}
      onClick={() => {
        setTrackerTabState({
          ...trackerTabState,
          filteredKeywords,
          labeledTrackerTabGroup: trackerGroup,
          selectedTracker: {
            group: {
              id: trackerGroup.id,
              name: trackerGroup.name,
              label: trackerGroup.label,
            },
          },
        });
        sendGAEvent("tracker_click", "call_review", trackerGroup.name);
        LogRocket.track("tracker-group-click");
      }}
    >
      <Text display="inline" fontWeight="700">
        {trackerGroup.label}
      </Text>
      <Text
        display="inline"
        ml="2"
        fontWeight="normal"
        textColor={
          getTrackerStyles(trackerGroup, selectedTrackerGroup).countTextColor
        }
      >
        {trackerGroup.count}
      </Text>
    </WrapItem>
  );
};
