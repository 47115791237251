import { CallGuide, GuideItem } from "./types";

export const stripTypenameFromCues = (cues: CallGuide["cues"]): GuideItem[] => {
  return [...cues].map((cue) => {
    const { __typename, ...itemWithoutTypeName } = cue;
    itemWithoutTypeName.childItems = itemWithoutTypeName.childItems.map(
      (child) => {
        const { __typename, ...childItemWithoutTypeName } = child;
        return childItemWithoutTypeName;
      }
    );
    return itemWithoutTypeName;
  });
};
