export default {
  variants: {
    info: {
      container: {
        bg: "yellow.300",
        _hover: { bg: "yellow.400" },
      },
    },
  },
};
