import { Box, Button } from "@chakra-ui/react";
import React from "react";

import { LoadingIndicator } from "../../../components/LoadingIndicator";
import { CandidateAlertListItemFragment } from "../../graphql";
import { CandidateAlertCandidateListItem } from "./CandidateAlertCandidateItem";

export const CandidateAlertCandidateList: React.FC<{
  alerts: CandidateAlertListItemFragment[];
  loading: boolean;
  showLoadMore: boolean;
  onLoadMore: () => void;
}> = ({ alerts, loading, showLoadMore, onLoadMore }) => {
  return (
    <Box>
      {loading && <LoadingIndicator delay={0} p="2" />}
      {!loading && (
        <>
          {alerts.map((a, idx) => {
            return (
              <CandidateAlertCandidateListItem
                key={a.id}
                isFirst={idx === 0}
                isLast={idx === alerts.length - 1}
                {...a}
              />
            );
          })}
          {showLoadMore && (
            <Button
              variant="ghost"
              colorScheme="gray"
              size="sm"
              fontWeight="medium"
              marginLeft="16px"
              onClick={onLoadMore}
            >
              Load more
            </Button>
          )}
        </>
      )}
    </Box>
  );
};
