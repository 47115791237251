export default {
  baseStyle: {
    borderRadius: "lg",
  },
  variants: {
    white: {
      backgroundColor: "white",
      boxShadow: "elevate",
    },
    gray: {
      backgroundColor: "gray.100",
      boxShadow: "none",
    },
    transparent: {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  defaultProps: {
    variant: "white",
  },
};
