import React from "react";

import { Alert } from "../../../components";
import DefaultLayout from "../../layouts/DefaultLayout";

type ErrorPageProps = {
  title: string;
  error?: { message: string };
};

// TODO: move to main/components once polished
const ErrorPage: React.FC<ErrorPageProps> = ({ title, error }) => {
  return (
    <DefaultLayout>
      <Alert status="error" title={title} description={error?.message} />
    </DefaultLayout>
  );
};

export default ErrorPage;
