import {
  Modal,
  ModalContent,
  ModalContentProps,
  ModalOverlay,
  ModalProps,
  Spinner,
  SpinnerProps,
} from "@chakra-ui/react";
import React from "react";

type AsyncModalProps = ModalProps & {
  loading: boolean;
  spinnerProps?: SpinnerProps;
  contentProps?: ModalContentProps;
};

const AsyncModal: React.FC<AsyncModalProps> = ({
  isOpen,
  onClose,
  loading,
  spinnerProps,
  contentProps,
  children,
  ...modalProps
}) => {
  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose} {...modalProps}>
      <ModalOverlay>
        {loading ? (
          <Spinner
            color="blue.300"
            mt="25%"
            size="xl"
            thickness="4px"
            sx={{ "--spinner-size": "4rem" }}
            {...spinnerProps}
          />
        ) : (
          <ModalContent {...contentProps}>{children}</ModalContent>
        )}
      </ModalOverlay>
    </Modal>
  );
};

export default AsyncModal;
