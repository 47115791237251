import { Flex, FlexProps, Spinner } from "@chakra-ui/react";
import React from "react";

import { RenderWithDelay, RenderWithDelayProps } from "./RenderWithDelay";

export type LoadingIndicatorProps = {
  size?: "xs" | "sm" | "md" | "lg";
} & RenderWithDelayProps &
  FlexProps;

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  delay,
  size = "lg",
  ...flexProps
}) => (
  <RenderWithDelay delay={delay}>
    <Flex
      data-testid="loading-indicator"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      color="blue.500"
      {...flexProps}
    >
      <Spinner
        thickness={size === "lg" ? "4px" : "2px"}
        speed="0.65s"
        emptyColor="transparent"
        size={size}
      />
    </Flex>
  </RenderWithDelay>
);
