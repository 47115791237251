import { Box, Flex, Icon, Link, LinkProps } from "@chakra-ui/react";
import React from "react";
import { MdSort } from "react-icons/md";
import { connectSortBy } from "react-instantsearch-dom";

import { useSendGAEvent } from "../../utils/googleAnalytics";

type SortByLinkProps = {
  isSelected: boolean;
};

const SortByLink: React.FC<SortByLinkProps & LinkProps> = ({
  children,
  isSelected,
  ...rest
}) => (
  <Link
    pl="6px"
    mr="-6px"
    pr="1px"
    ml={4}
    pb="6px"
    borderBottom="1px"
    borderBottomColor={isSelected ? "link.default" : "transparent"}
    fontWeight="inherit"
    color={isSelected ? "link.default" : "primary"}
    _hover={{
      color: isSelected ? "link.hover" : "primary",
      borderBottomColor: isSelected ? "link.hover" : "transparent",
    }}
    _active={{
      color: isSelected ? "link.active" : "primary",
      borderBottomColor: isSelected ? "link.active" : "transparent",
    }}
    {...rest}
  >
    {children}
    <Icon
      as={MdSort}
      visibility={isSelected ? "visible" : "hidden"}
      mt="-2px"
      ml="1"
    />
  </Link>
);

const SortBy = connectSortBy(({ items, refine, createURL }) => {
  const sendGAEvent = useSendGAEvent();

  return (
    <Flex>
      Sort By:
      <Box flex="none">
        {items.map((item: any) => (
          <SortByLink
            key={item.value}
            href={createURL(item.value)}
            isSelected={item.isRefined}
            onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              e.preventDefault();
              sendGAEvent(
                "sort",
                "search",
                item.label.toLowerCase().replace(" ", "_")
              );
              refine(item.value);
            }}
          >
            {item.label}
          </SortByLink>
        ))}
      </Box>
    </Flex>
  );
});

export default SortBy;
