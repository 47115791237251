import { ApolloError } from "@apollo/client";
import { Box, BoxProps, Flex } from "@chakra-ui/react";
import Color from "color";
import React, { useEffect, useRef, useState } from "react";

import { useTheme, useToast } from "../../../../components";
import { useIsSmallScreen } from "../../../../hooks/useIsSmallScreen";
import { CallNoteFragment, CallNoteType } from "../../../graphql";
import useDeleteCallNote from "../../../graphql/hooks/useDeleteCallNote";
import { ClipRange } from "../../Interview/Clip/types";
// TODO: These two components are brownfield and need to be checked / probably duplicated
import CallNoteList from "../CallNoteList";
import TimestampedNote from "../TimestampedNoteBeta";
import { notesListNoteTypes } from "../utils";

type CallNotesSectionProps = BoxProps & {
  callId: string;
  clipId?: string;
  callDuration: number;
  interviewerIds: string[];
  notes: CallNoteFragment[];
  onAddNote: (opts: { text: string; type: CallNoteType; time: number }) => void;
  onClickTimestamp?: (time: number) => void;
  setClipRange?(clipRange: ClipRange | null, adjustByWordTime: boolean): void;
  playerTime: number;
  currentNoteId?: string;
  notesReadOnly?: boolean;
};

const CallNotesSection: React.FC<CallNotesSectionProps> = ({
  callId,
  clipId,
  callDuration,
  interviewerIds,
  notes,
  onAddNote,
  onClickTimestamp,
  setClipRange,
  playerTime,
  currentNoteId,
  notesReadOnly,
  ...styles
}) => {
  const toast = useToast();
  const { colors } = useTheme();
  const isSmallScreen = useIsSmallScreen();

  const [draftNoteTimestamp, setDraftNoteTimestamp] = useState<number>();
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(false);

  const noteListRef = useRef<HTMLDivElement>(null);
  const noteRef = useRef<HTMLDivElement>(null);
  const scrollTargetRef = useRef<HTMLDivElement>(null);

  /*
   * By Will's edict: do not fuck with checkScroll or the next useEffect
   * without testing in Ty's browser because the .focus() call crashes
   * chrome.
   */
  const checkScroll = (): void => {
    if (autoScrollEnabled) {
      scrollTargetRef.current?.scrollIntoView?.({
        block: "nearest",
        inline: "nearest",
        behavior: "smooth",
      });
      setAutoScrollEnabled(false);
    }
  };
  useEffect(() => {
    if (noteListRef.current) {
      if (notes.length > 0 && autoScrollEnabled) {
        checkScroll();
        noteRef.current?.focus({ preventScroll: true });
      }
    }
  }, [notes.length, noteRef.current]);

  const [deleteNote, { loading: noteIsDeleting }] = useDeleteCallNote({
    onError: React.useCallback(
      (error: ApolloError) =>
        toast({
          title: "Error deleting note",
          description: error.message,
          status: "error",
        }),
      []
    ),
  });

  const hasListNotes = notes.some((n) => notesListNoteTypes.includes(n.type));
  const placeholderColor = new Color(colors.gray[400]).alpha(0.5).string();
  const placeholderColors = hasListNotes
    ? undefined
    : {
        default: placeholderColor,
        hover: placeholderColor,
        focus: placeholderColor,
      };

  return (
    <Flex
      ref={noteListRef}
      direction="column"
      position="relative"
      borderRadius="md"
      transition="all ease-in .1s"
      {...styles}
    >
      <Box mt={1} mb="2">
        {notes.length > 0 && (
          <CallNoteList
            notes={notes}
            currentNoteId={currentNoteId}
            showNotesEmptyState={false}
            limitedNoteControls={false}
            onClickTimestamp={onClickTimestamp}
            notesReadOnly={notesReadOnly}
            maxHeight="100%"
            setClipRange={setClipRange}
            interviewerIds={interviewerIds}
            timestampLeft
            onDeleteNote={(id) => deleteNote({ variables: { id } })}
            noteIsDeleting={noteIsDeleting}
            disableAutoScroll={isSmallScreen}
          />
        )}
        {!notesReadOnly && (
          <Box position="relative">
            <Box
              ref={scrollTargetRef}
              pos="absolute"
              width="100%"
              bottom="0"
              top="80px"
              pointerEvents="none"
            />
            <TimestampedNote
              ref={noteRef}
              callId={callId}
              clipId={clipId}
              callDuration={callDuration}
              time={Math.floor(playerTime)}
              timestamp={draftNoteTimestamp}
              onChangeTimestamp={setDraftNoteTimestamp}
              onAddNote={(text, type, time) => {
                setAutoScrollEnabled(true);
                onAddNote({ text, type, time });
              }}
              placeholder="Add note, reaction & tag others with @"
              placeholderColor={placeholderColors}
              px="2"
              py="1"
              hideEmptyInput={false}
              timestampLeft
            />
          </Box>
        )}
      </Box>
    </Flex>
  );
};

export default CallNotesSection;
