import { Box, Link } from "@chakra-ui/react";
import React from "react";

type CallNoteEmptyStateProps = {
  isForClip?: boolean;
};

const CallNoteEmptyState: React.FC<CallNoteEmptyStateProps> = ({
  isForClip,
}) => {
  return (
    <Box fontSize="sm" color="placeholder" px="6" pb="3">
      No notes have been added for this {isForClip ? "clip" : "interview"}. Add
      a note below or{" "}
      <Link
        target="_blank"
        rel="noopener"
        href="https://www.loom.com/share/cbed1e889de7437d9df34366fa7d1e89"
      >
        during an interview
      </Link>{" "}
      to mark important moments.
    </Box>
  );
};

export default CallNoteEmptyState;
