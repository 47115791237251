import { Box, Flex, Icon, SystemProps, Text, useTheme } from "@chakra-ui/react";
import React from "react";
import { IoIosPlayCircle } from "react-icons/io";
import { MdChatBubble, MdCheckCircle, MdInfo, MdPeople } from "react-icons/md";

import { BhAtIcon, RouterLink } from "../../../../components";
import { useIsSmallScreen } from "../../../../hooks/useIsSmallScreen";
import { formatRelativeDate } from "../../../../utils/datetime";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { ActivityFeedListItemFragment, ActivityType } from "../../../graphql";

type ActivityFeedItemProps = SystemProps & {
  item: ActivityFeedListItemFragment;
};

const ActivityFeedItem: React.FC<ActivityFeedItemProps> = ({
  item,
  ...rest
}) => {
  const sendGAEvent = useSendGAEvent();
  const isSmallScreen = useIsSmallScreen();
  const theme = useTheme();
  const { fontWeights, colors } = theme;
  let icon = MdInfo;
  let text = (
    <span>
      An event occurred, but we&apos;re missing a few details. They should
      appear here soon!
    </span>
  );
  const call = item.activity?.call;
  const callName = (
    <span
      style={{
        fontWeight: fontWeights.semibold,
        color: isSmallScreen ? colors.blue[600] : "black",
      }}
    >
      {call?.name && call?.name !== "" ? call.name : "Untitled"}
    </span>
  );

  const clip = item.activity?.clip;
  const myClip = clip && clip.userId === item.ownerId;
  const clipName = (
    <>
      {myClip ? "your clip" : "a clip"}{" "}
      {clip?.name && (
        <span
          style={{
            fontWeight: fontWeights.semibold,
            color: isSmallScreen ? colors.blue[600] : "black",
          }}
        >
          {clip.name}
        </span>
      )}
    </>
  );

  switch (item.activity.action) {
    case ActivityType.CallNoteComment:
      icon = MdChatBubble;
      text = (
        <span>
          {item.activity?.callNote?.user?.fullName ?? "Someone"} left a comment
          on {clip ? clipName : callName}
        </span>
      );
      break;
    case ActivityType.CallNoteMention:
      icon = BhAtIcon;
      text = (
        <span>
          {item.activity?.callNote?.user?.fullName ?? "Someone"} mentioned you
          in {clip ? clipName : callName}
        </span>
      );
      break;
    case ActivityType.CallShare:
      icon = MdPeople;
      text = (
        <span>
          {item.activity?.callShare?.sharedBy?.fullName ?? "Someone"} invited
          you to {callName}
        </span>
      );
      break;
    case ActivityType.CallTranscriptionComplete:
      icon = MdCheckCircle;
      text = <span>The transcription for {callName} is now complete.</span>;
      break;
    case ActivityType.CallView:
      icon = IoIosPlayCircle;
      text = (
        <span>
          {item.activity?.subject?.fullName ?? "Someone"} viewed the interview{" "}
          {callName}
        </span>
      );
      break;
    case ActivityType.ClipShare:
      icon = MdPeople;
      text = (
        <span>
          {item.activity?.clipShare?.sharedBy?.fullName ?? "Someone"} shared{" "}
          {clipName} with you
        </span>
      );
      break;
    case ActivityType.ExternalCallView:
      icon = IoIosPlayCircle;
      text = (
        <span>
          {item.activity?.externalSubject?.email ?? "Someone"} viewed the
          interview {callName}
        </span>
      );
      break;
    case ActivityType.ExternalClipView:
      icon = IoIosPlayCircle;
      text = (
        <span>
          {item.activity?.externalSubject?.email ?? "Someone"} viewed {clipName}
        </span>
      );
      break;
    case ActivityType.PlaylistShare:
      icon = MdPeople;
      text = (
        <span>
          {item.activity?.playlistShare?.sharedByUser?.fullName ?? "Someone"}{" "}
          shared a playlist with you
        </span>
      );
      break;
  }

  let href = `/interview/${call?.id ?? "unknown"}`;
  if (item.activity?.callNote?.highlightStartTime) {
    href += `?t=${item.activity?.callNote.highlightStartTime}`;
  }

  if (clip) {
    href = `/interview/${clip.callId}/clip/${clip.id}`;
  }

  if (item.activity.playlistShare?.absoluteUrl) {
    href = item.activity.playlistShare?.absoluteUrl;
  }

  return (
    <Flex
      as={RouterLink}
      to={href}
      fontWeight="normal"
      gap="3"
      transition="background-color 100ms linear"
      color="gray.400"
      _hover={{
        color: "blue.600",
        backgroundColor: "blue.50",
        borderRadius: "lg",
        textDecoration: "none",
      }}
      onClick={() =>
        sendGAEvent("Activity item click", "home_page", item.activity.action)
      }
      {...rest}
    >
      <Icon
        as={icon}
        boxSize={{ base: "5", lg: "18px" }}
        color={isSmallScreen ? "blue.600" : "inherit"}
      />

      <Box
        fontSize={{ base: "md", lg: "sm" }}
        lineHeight={{ base: "6", lg: "5" }}
      >
        <Text color="gray.900">{text}</Text>
        <Text color="gray.500" mt="1">
          {formatRelativeDate(item.createdAt)}
        </Text>
      </Box>
    </Flex>
  );
};

export default ActivityFeedItem;
