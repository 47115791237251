import {
  Popover,
  PopoverContent,
  PopoverContentProps,
  PopoverProps,
  PopoverTrigger,
} from "@chakra-ui/react";
import React, { forwardRef } from "react";

type PopoverBetaProps = {
  button: React.ReactNode;
  children: React.ReactNode;
  contentProps?: PopoverContentProps;
  trigger?: PopoverProps["trigger"];
};

const PopoverBeta: React.FC<PopoverBetaProps & PopoverProps> = forwardRef(
  ({ button, children, contentProps, trigger = "hover", ...rest }, ref) => {
    return (
      <Popover trigger={trigger} strategy="fixed" {...rest}>
        <PopoverTrigger>{button}</PopoverTrigger>
        <PopoverContent
          py="2"
          minW={{ base: "100%", lg: "max-content" }}
          borderRadius="2px"
          borderColor="gray.200"
          borderWidth="1px"
          boxShadow="0px 4px 6px -2px #0000000D, 0px 10px 15px -3px #0000001A"
          {...contentProps}
        >
          {children}
        </PopoverContent>
      </Popover>
    );
  }
);

export default PopoverBeta;
