import {
  Editable,
  EditableInput,
  EditablePreview,
  EditablePreviewProps,
  EditableProps,
  Flex,
  Icon,
} from "@chakra-ui/react";
import React from "react";
import { FiEdit2 } from "react-icons/fi";

export type EditableTextProps = {
  defaultValue?: string | null | undefined;
  placeholder?: string;
  autoComplete?: string;
  isDisabled?: boolean;
  showIcon?: boolean | "hover";
  maxLength?: number;
  onSubmit: (newValue: string) => void;
  previewProps?: EditablePreviewProps;
  variant?: "blue";
  hideFocus?: boolean;
} & Omit<EditableProps, "onSubmit" | "children" | "defaultValue">;

export const EditableText = React.forwardRef<HTMLDivElement, EditableTextProps>(
  (
    {
      defaultValue,
      placeholder = "",
      autoComplete,
      isDisabled,
      showIcon = true,
      maxLength,
      onSubmit,
      value,
      previewProps,
      variant,
      hideFocus,
      ...rest
    },
    ref
  ) => {
    const [iconVisible, setIconVisible] = React.useState(showIcon === true);
    const onMouseEnter = (): void => {
      if (showIcon === "hover") {
        setIconVisible(true);
      }
    };
    const onMouseLeave = (): void => {
      if (showIcon === "hover") {
        setIconVisible(false);
      }
    };

    return (
      <Editable
        selectAllOnFocus={false}
        defaultValue={value ?? defaultValue ?? ""}
        value={value}
        placeholder={placeholder}
        isDisabled={isDisabled}
        onSubmit={(newValue: any) => {
          if (typeof newValue === "string") {
            onSubmit(newValue);
          }
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        {...rest}
      >
        {({
          isEditing,
          onEdit,
        }: {
          isEditing: boolean;
          onEdit: () => void;
        }) => (
          <Flex ref={ref} h="100%" flexDirection="row" alignItems="center">
            <EditablePreview
              overflowX="hidden"
              textOverflow="ellipsis"
              {...previewProps}
            />
            {!isEditing && !isDisabled && iconVisible && (
              <Icon
                as={FiEdit2}
                ml="6"
                cursor="pointer"
                color={variant === "blue" ? "blue.400" : "gray.400"}
                boxSize="4"
                onClick={onEdit}
              />
            )}
            <EditableInput
              flex="1"
              maxLength={maxLength}
              autoComplete={autoComplete}
              _focus={hideFocus ? { boxShadow: "none " } : undefined}
            />
          </Flex>
        )}
      </Editable>
    );
  }
);
