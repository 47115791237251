import { Text, TextProps, Tooltip } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";

import { Maybe } from "../../graphql";

type TruncatedTextProps = {
  text?: Maybe<string> | undefined;
  styledTooltip?: boolean;
} & TextProps;

const TruncatedText: React.FC<TruncatedTextProps> = ({
  text,
  styledTooltip = false,
  ...rest
}) => {
  const textRef = useRef<HTMLInputElement | null>(null);
  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  useEffect(() => {
    const el = textRef.current;
    if (el) {
      setTooltipEnabled(el.scrollHeight > el.clientHeight);
    }
  }, [textRef.current]);

  const style = styledTooltip
    ? {
        bg: "white",
        color: "gray.600",
        py: 5,
        px: 4,
        hasArrow: true,
      }
    : {};

  return (
    <Tooltip isDisabled={!tooltipEnabled} label={text} {...style}>
      <Text ref={textRef} {...rest}>
        {text}
      </Text>
    </Tooltip>
  );
};

export default TruncatedText;
