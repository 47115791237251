import { Box, Grid, Icon, IconProps, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { IconType } from "react-icons";
import { IoLockClosedSharp, IoPeopleCircleSharp } from "react-icons/io5";
import { MdCheck, MdPeople } from "react-icons/md";
import Select, { components, ContainerProps, OptionProps } from "react-select";

import { useTheme } from "../../../components";
import useSelectTheme from "../../../hooks/useSelectTheme";
import {
  CallVisibility,
  useVisibilityLevelDescriptionsQuery,
} from "../../graphql";

export type VisibilitySelectProps = {
  onChangeVisibility: (v: CallVisibility) => void;
  visibilityLevels: CallVisibility[];
  visibility: CallVisibility;
};

type OptionType = {
  icon: IconType;
  title: string;
  subtitle: string;
  value: CallVisibility;
};

const BASE_OPTIONS: OptionType[] = [
  {
    value: CallVisibility.Organization,
    title: "Organization",
    subtitle: "Visible to any user at the company on BrightHire.",
    icon: OrganizationIcon as IconType,
  },
  {
    value: CallVisibility.Public,
    title: "Hiring Team",
    subtitle: `
      Visible to entire hiring team, all company recruiters, site admins, and
      anyone invited.
    `,
    icon: MdPeople,
  },
  {
    value: CallVisibility.Restricted,
    title: "Restricted",
    subtitle: `
      Visible to those on the hiring team who were in the recording, hiring
      team admins, site admins, and anyone invited.
    `,
    icon: IoPeopleCircleSharp,
  },
  {
    value: CallVisibility.Private,
    title: "Private",
    subtitle: `
      Visible only to interviewers in this recording, site admins, and anyone
      invited.
    `,
    icon: IoLockClosedSharp,
  },
];

const Option: React.FC<OptionProps<OptionType, false>> = ({
  children,
  ...props
}) => {
  const { icon, title, subtitle, value } = props.data;
  const [selected] = props.getValue();
  const isSelected = value === selected.value;

  return (
    <components.Option {...props}>
      <Grid p="5" gridTemplateColumns="30px 100px 1fr 36px">
        <Icon as={icon} w="18px" h="18px" mt="1" />
        <Text fontWeight="medium" fontSize="sm" mt="1">
          {title}
        </Text>
        <Text fontSize="xs">{subtitle}</Text>
        {isSelected && (
          <Icon
            as={MdCheck}
            w="18px"
            h="18px"
            color="blue.600"
            ml="auto"
            alignSelf="center"
          />
        )}
      </Grid>
    </components.Option>
  );
};

const SelectContainer: React.FC<ContainerProps<OptionType, false>> = ({
  children,
}) => (
  <Box data-testid="visibility-select" mb="4">
    {children}
  </Box>
);

const VisibilitySelect: React.FC<VisibilitySelectProps> = ({
  onChangeVisibility,
  visibilityLevels,
  visibility,
}) => {
  const { colors } = useTheme();
  const [menuOpen, setMenuOpen] = useState(false);

  const [theme, styles] = useSelectTheme({
    control: (provided: any) => ({
      ...provided,
      background: colors.gray[50],
      borderColor: colors.gray[100],
      "&:hover": {
        borderColor: colors.gray[200],
      },
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      fontSize: "14px",
      paddingLeft: "16px",
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      padding: "2px 8px 2px 0",
      " > *": {
        color: `${colors.gray[500]} !important`,
        transform: `rotate(${menuOpen ? "180" : "0"}deg)`,
      },
    }),
    option: (provided: any, { isSelected }: any) => ({
      ...provided,
      padding: 0,
      background: isSelected ? "transparent" : undefined,
      color: "inherit",
      "&:hover": {
        background: colors.blue[50],
      },
    }),
  });

  const { options, selected } = useVisibilityLevelOptions(
    visibilityLevels,
    visibility
  );

  return (
    <Select
      theme={theme}
      styles={styles}
      components={{
        IndicatorSeparator: () => null,
        Option,
        SelectContainer,
      }}
      value={selected}
      options={options}
      isSearchable={false}
      maxMenuHeight={Infinity}
      menuPosition="fixed"
      menuPlacement="bottom"
      menuShouldBlockScroll
      onMenuOpen={() => setMenuOpen(true)}
      onMenuClose={() => setMenuOpen(false)}
      getOptionLabel={getOptionLabel}
      getOptionValue={({ value }) => value}
      onChange={(option) => {
        if (option !== null && option.value !== selected.value) {
          onChangeVisibility(option.value);
        }
      }}
    />
  );
};

export default VisibilitySelect;

/**
 * Updates the default visibility levels to ensure that they match
 * the org's RBAC settings
 */
function useVisibilityLevelOptions(
  visibilityLevels: CallVisibility[],
  selected: CallVisibility
): { options: OptionType[]; selected: OptionType } {
  const [options, setOptions] = useState(
    visibilityLevels.map(
      (visibility) =>
        BASE_OPTIONS.find(({ value }) => value === visibility) as OptionType
    )
  );

  useVisibilityLevelDescriptionsQuery({
    onCompleted(data) {
      setOptions(
        options.map((option) => {
          const updatedOption = { ...option };
          const description = data?.visibilityLevelDescriptions.find(
            ({ visibilityLevel }) => visibilityLevel === option.value
          )?.description;
          if (description) {
            updatedOption.subtitle = description;
          }
          return updatedOption;
        })
      );
    },
  });

  return {
    options,
    selected: options.find(({ value }) => value === selected) as OptionType,
  };
}

function getOptionLabel({ title, value }: OptionType): string {
  switch (value) {
    case CallVisibility.Organization:
      return "All Organization + Anyone Invited";
    default:
      return `${title} + Anyone Invited`;
  }
}

export function OrganizationIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 24 24" fill="currentcolor" {...props}>
      <path d="M15 8.25V0.75H1.5V23.25H7.5V18.75H9V23.25H22.5V8.25H15ZM5.25 20.25H3.75V18.75H5.25V20.25ZM5.25 16.5H3.75V15H5.25V16.5ZM5.25 12.75H3.75V11.25H5.25V12.75ZM5.25 9H3.75V7.5H5.25V9ZM5.25 5.25H3.75V3.75H5.25V5.25ZM11.25 3.75H12.75V5.25H11.25V3.75ZM9 16.5H7.5V15H9V16.5ZM9 12.75H7.5V11.25H9V12.75ZM9 9H7.5V7.5H9V9ZM9 5.25H7.5V3.75H9V5.25ZM12.75 20.25H11.25V18.75H12.75V20.25ZM12.75 16.5H11.25V15H12.75V16.5ZM12.75 12.75H11.25V11.25H12.75V12.75ZM12.75 9H11.25V7.5H12.75V9ZM21 21.75H15V20.25H16.5V18.75H15V16.5H16.5V15H15V12.75H16.5V11.25H15V9.75H21V21.75Z" />
      <path d="M18 18.75H19.5V20.25H18V18.75ZM18 15H19.5V16.5H18V15ZM18 11.25H19.5V12.75H18V11.25Z" />
    </Icon>
  );
}
