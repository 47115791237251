import { useMemo } from "react";

import { CallAiSummaryFormat } from "../graphql";
import useFeatureFlag from "../graphql/hooks/useFeatureFlag";
import useCurrentUser from "./useCurrentUser";

export const useAvailableAiSummaryFormats = (): CallAiSummaryFormat[] => {
  const currentUser = useCurrentUser();

  const salesEnabled = useFeatureFlag(
    `ai-summary:${CallAiSummaryFormat.Sales.toLowerCase()}`
  );
  const scorecardEnabled = useFeatureFlag(
    `ai-summary:${CallAiSummaryFormat.Scorecard.toLowerCase()}`
  );
  const productDemoFeedbackEnabled = useFeatureFlag(
    `ai-summary:${CallAiSummaryFormat.ProductDemoFeedback.toLowerCase()}`
  );
  const customEnabled = currentUser.organization.allowAiCustomTemplates;

  return useMemo(() => {
    const partialFormats: CallAiSummaryFormat[] = [
      CallAiSummaryFormat.Qanda,
      CallAiSummaryFormat.Intake,
      CallAiSummaryFormat.Debrief,
    ];
    if (salesEnabled) {
      partialFormats.push(CallAiSummaryFormat.Sales);
    }
    if (scorecardEnabled) {
      partialFormats.push(CallAiSummaryFormat.Scorecard);
    }
    if (productDemoFeedbackEnabled) {
      partialFormats.push(CallAiSummaryFormat.ProductDemoFeedback);
    }
    if (customEnabled) {
      partialFormats.push(CallAiSummaryFormat.Custom);
    }
    return partialFormats;
  }, [salesEnabled, productDemoFeedbackEnabled]);
};
