import { Text } from "@chakra-ui/react";
import React from "react";
import { HiOutlineUserGroup } from "react-icons/hi";

import { AnalyticsDimension, MetricName } from "../../graphql";
import DashboardCard from "./DashboardCard";
import { DashboardCardWrapperProps } from "./types";
import useDashboardStat from "./useDashboardStat";
import { calculateMetricStats, groupBySegment } from "./utils";

const DashboardCardDEI: React.FC<DashboardCardWrapperProps> = ({
  alerts,
  dashboardConfig,
}) => {
  // talk ratio by gender
  const talkRatioStat = useDashboardStat(MetricName.CandidateTalkRatio, {
    secondaryDimension: AnalyticsDimension.Gender,
    ...dashboardConfig.queryOptions,
  });
  const talkRatioSegments = groupBySegment(talkRatioStat.dataPoints);

  const {
    metricAverageValue: talkRatioAverageValueM,
    numXValues: talkRatioNumXValuesM,
  } = calculateMetricStats(
    talkRatioSegments.M || [],
    MetricName.CandidateTalkRatio
  );

  const {
    metricAverageValue: talkRatioAverageValueF,
    numXValues: talkRatioNumXValuesF,
  } = calculateMetricStats(
    talkRatioSegments.F || [],
    MetricName.CandidateTalkRatio
  );

  // questions asked by gender
  const questionsAskedStat = useDashboardStat(
    MetricName.QuestionsAsked,
    {
      secondaryDimension: AnalyticsDimension.Gender,
      ...dashboardConfig.queryOptions,
    },
    { benchmarkFormat: "integer" }
  );
  const questionsSegments = groupBySegment(questionsAskedStat.dataPoints);

  const {
    metricAverageValue: questionsAverageValueM,
    numXValues: questionsNumXValuesM,
  } = calculateMetricStats(
    questionsSegments.M || [],
    MetricName.QuestionsAsked
  );

  const {
    metricAverageValue: questionsAverageValueF,
    numXValues: questionsNumXValuesF,
  } = calculateMetricStats(
    questionsSegments.F || [],
    MetricName.QuestionsAsked
  );

  const loading = talkRatioStat.loading || questionsAskedStat.loading;

  return (
    <DashboardCard
      title="Diversity, Equity & Inclusion"
      category="dei"
      icon={HiOutlineUserGroup}
      stats={[
        {
          title: "Candidate Talk Ratio",
          values: [
            {
              value:
                talkRatioNumXValuesM > 0 ? talkRatioAverageValueM : undefined,
              format: "percent",
              label: "Male",
            },
            {
              value:
                talkRatioNumXValuesF > 0 ? talkRatioAverageValueF : undefined,
              format: "percent",
              label: "Female",
            },
          ],
          loading,
          metric: MetricName.CandidateTalkRatio,
          segment: AnalyticsDimension.Gender,
          dashboardConfig,
        },
        {
          title: "Interviewer Questions Asked",
          values: [
            {
              value: questionsNumXValuesM ? questionsAverageValueM : undefined,
              format: "integer",
              label: "Male",
            },
            {
              value: questionsNumXValuesF ? questionsAverageValueF : undefined,
              format: "integer",
              label: "Female",
            },
          ],
          loading,
          metric: MetricName.QuestionsAsked,
          segment: AnalyticsDimension.Gender,
          dashboardConfig,
        },
      ]}
      alerts={alerts}
      dashboardConfig={dashboardConfig}
      infoContent={
        <>
          <Text mb="3">
            <Text as="b">Candidate Talk Ratio</Text> is calculated using the
            total time the candidate is speaking as a share of the total
            duration of the interview, averaged over the selected date range.
          </Text>
          <Text mb="3">
            Benchmark: 50% of interviews fall within this benchmark range of 39%
            - 60% based on data from our customer base.
          </Text>
          <Text mb="3">
            <Text as="b">Questions Asked</Text> is the number of questions the
            interviewer asked in an interview, averaged over the selected date
            range.
          </Text>
          <Text mb="3">
            Benchmark: 50% of interviews fall within 7 - 17 questions based on
            data from our customer base.
          </Text>
        </>
      }
    />
  );
};

export default DashboardCardDEI;
