import { ButtonProps } from "@chakra-ui/react";
import React from "react";

import { formatGreenhouseRating } from "../../utils/greenhouse";
import { GreenhouseIcon, RatingButton, useTheme } from "..";

const GreenhouseRatingButton: React.FC<{
  rating: string | undefined | null;
  greenhouseScorecardLink?: string;
  variant?: ButtonProps["variant"];
  showFullWidth?: boolean;
  size?: ButtonProps["size"];
}> = ({ rating, greenhouseScorecardLink, showFullWidth, size, variant }) => {
  const theme = useTheme();
  const { colors } = theme;
  if (!greenhouseScorecardLink) return null;
  return (
    <RatingButton
      link={greenhouseScorecardLink}
      text={rating ? formatGreenhouseRating(rating) : undefined}
      showFullWidth={showFullWidth}
      size={size}
      variant={variant}
      icon={
        <GreenhouseIcon
          fill={colors.blue[500]}
          width={size === "sm" ? "20px" : "14px"}
          height={size === "sm" ? "20px" : "14px"}
          marginRight="0"
        />
      }
    />
  );
};

export default GreenhouseRatingButton;
