export default {
  variants: {
    outline: {
      field: {
        bg: "white",
        "&::-webkit-search-cancel-button": {
          cursor: "pointer",
          padding: "2px",
        },
      },
    },
  },
};
