import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";

import { Pill } from "../../../components";

export interface FormData {
  callBack: string;
  preCall: string;
}

interface TextMessageTemplateProps {
  title: string;
  name: keyof FormData;
  value: string;
  register: UseFormRegister<FormData>;
  setValue: UseFormSetValue<FormData>;
  instructions: string[];
  isLoading: boolean;
  error?: string;
}

const TextMessageTemplate: React.FC<TextMessageTemplateProps> = ({
  title,
  name,
  value,
  register,
  setValue,
  instructions,
  error,
}) => {
  const registration = register(name, { required: true });
  const textAreaRef = useRef<HTMLTextAreaElement | undefined>();

  const setVar = (templateVar: string) => {
    return () => {
      const start = textAreaRef.current?.selectionStart || value.length;

      let text = `${value.slice(0, start).trimEnd()} `;
      text += templateVar;
      if (value.length >= start) {
        text += ` ${value.slice(start).trimStart()}`;
      }
      setValue(name, text);

      if (!textAreaRef.current) return;
      textAreaRef.current.focus();
      textAreaRef.current.selectionStart = start + value.length + 1;
      textAreaRef.current.selectionEnd = start + value.length + 1;
    };
  };

  return (
    <FormControl id={name} pb={3} isRequired isInvalid={!!error}>
      <FormLabel fontSize="md" fontWeight="semibold" color="gray.800">
        {title}
      </FormLabel>
      {instructions.map((instr) => (
        <FormHelperText key={instr} color="placeholder" mb={3} fontSize="sm">
          {instr}
        </FormHelperText>
      ))}

      <Textarea
        {...registration}
        ref={(r: HTMLTextAreaElement) => {
          textAreaRef.current = r;
          registration.ref(r);
        }}
        mb={4}
      />
      <Flex wrap="wrap">
        <Text color="placeholder" fontSize="sm" mr={2} mb={3} width="100%">
          Template Variables (click to add to template):
        </Text>

        <TemplateVar
          name="Your First Name"
          description="Your first name"
          disabled={value.includes("{userFirstName}")}
          onClick={setVar("{userFirstName}")}
        />
        <TemplateVar
          name="Your Last Name"
          description="Your last name"
          disabled={value.includes("{userLastName}")}
          onClick={setVar("{userLastName}")}
        />
        <TemplateVar
          name="Organization"
          description="Your organization name"
          disabled={value.includes("{orgName}")}
          onClick={setVar("{orgName}")}
        />
        <TemplateVar
          name="Callback Number"
          description="Your one-click call back number"
          disabled={value.includes("{callbackNumber}")}
          onClick={setVar("{callbackNumber}")}
        />
      </Flex>

      {!!error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

interface TemplateVarProps {
  name: string;
  description: string;
  disabled: boolean;
  onClick: () => void;
}

const TemplateVar: React.FC<TemplateVarProps> = ({
  name,
  description,
  disabled,
  onClick,
}) => {
  return (
    <Pill
      text={name}
      tooltip={description}
      userSelect="none"
      cursor={disabled ? "not-allowed" : "pointer"}
      mr={3}
      mb={3}
      color={disabled ? "blue.600" : "gray.800"}
      borderColor={disabled ? "blue.400" : "gray.200"}
      bg={disabled ? "blue.50" : "inherit"}
      onClick={() => {
        if (disabled) return;
        onClick();
      }}
    />
  );
};

export default TextMessageTemplate;
