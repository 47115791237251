import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

/**
 * Detects whether a global mousemove (or touchstart on mobile) event has fired
 * @returns true if mouse has moved, false if not
 */
const useMouseHasMoved = (): boolean => {
  const [hasMoved, setHasMoved] = useState(false);
  let mouseEvent = "mousemove";
  if (isMobile) {
    mouseEvent = "touchstart";
  }
  useEffect(() => {
    const hasMouseCheck = (): void => {
      setHasMoved(true);
      window.removeEventListener(mouseEvent, hasMouseCheck, false);
    };
    window.addEventListener(mouseEvent, hasMouseCheck, false);
    return function cleanup() {
      window.removeEventListener(mouseEvent, hasMouseCheck, false);
    };
  }, []);
  return hasMoved;
};

export default useMouseHasMoved;
