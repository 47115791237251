import { Flex, Icon } from "@chakra-ui/react";
import React from "react";
import { HiOutlineUser, HiOutlineUserGroup } from "react-icons/hi";

interface SharingLabelProps {
  id: string;
  visibility: string;
  onClick: (id: string, visibility: string) => void;
}

const visibilities: { [key: string]: string } = {
  PUBLIC: "Team",
  PRIVATE: "Private",
};

const SharingLabel: React.FC<SharingLabelProps> = ({
  id,
  visibility,
  onClick,
}) => (
  <Flex
    flexDirection="row"
    alignItems="center"
    cursor="pointer"
    onClick={() => onClick(id, visibility)}
  >
    <Icon
      mr={2}
      as={visibility === "PUBLIC" ? HiOutlineUserGroup : HiOutlineUser}
      color={visibility === "PUBLIC" ? "gray.800" : "secondary"}
    />
    {visibilities[visibility]}
  </Flex>
);

export default SharingLabel;
