import { useCallback } from "react";

import { useToast } from "../../../components";
import { copy } from "../../../utils/clipboard";
import { CallNoteFragment } from "../../graphql";
import { noteTimestampedLink } from "./utils";

const useCopyLink = (
  note: Pick<CallNoteFragment, "callId" | "time">,
  timestamp?: number
): { handleCopyLink: () => void } => {
  const toast = useToast();
  const timestampedLink = noteTimestampedLink(note, timestamp);
  const handleCopyLink = useCallback((): void => {
    copy(timestampedLink);
    toast({
      title: "Link copied to clipboard",
      status: "success",
    });
  }, [timestampedLink]);
  return {
    handleCopyLink,
  };
};

export default useCopyLink;
