import { useEffect, useState } from "react";

const useHover = (el: Element | null): boolean => {
  const [hover, setHover] = useState(false);
  const onMouseOver = (): void => setHover(true);
  const onMouseOut = (): void => setHover(false);
  useEffect(() => {
    if (el) {
      el.addEventListener("mouseover", onMouseOver);
      el.addEventListener("mouseout", onMouseOut);
      return () => {
        el.removeEventListener("mouseover", onMouseOver);
        el.removeEventListener("mouseout", onMouseOut);
      };
    }
  }, [el]);
  return hover;
};

export default useHover;
