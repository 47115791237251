import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { FC, useCallback, useMemo } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { HiOutlinePlus, HiOutlineTrash } from "react-icons/hi";
import CreatableSelect from "react-select/creatable";

import DragHandleDots from "../../../../components/Icons/DragHandleDots";
import {
  CallGuideItemChildFragment,
  useAddCompetencyMutation,
  useOrganizationCompetencyQuery,
} from "../../../graphql";
import { CommonGuideItemProps, GuideItemUpdate } from "../types";
import GuideItemLabel from "./GuideItemLabel";
import GuideItemQuestion from "./GuideItemQuestion";

type GuideItemCompetencyProps = CommonGuideItemProps & {
  childItems?: CallGuideItemChildFragment[];
  itemScoringEnabled: boolean;
  orgScoringEnabled: boolean;
  callGuideId: string;
  competencyId?: string;
  onAddQuestion(): void;
  onDeleteChild(parentId: string, childId: string): void;
  onUpdateChild(
    parentId: string,
    childId: string,
    update: GuideItemUpdate
  ): void;
  idsWithErrors: string[];
};

const GuideItemCompetency: FC<GuideItemCompetencyProps> = ({
  itemId,
  callGuideId,
  competencyId,
  childItems,
  isEditing,
  itemScoringEnabled,
  orgScoringEnabled,
  canDelete,
  onAddQuestion,
  onDelete,
  onDeleteChild,
  onEnter,
  onUpdate,
  onUpdateChild,
  idsWithErrors,
}) => {
  const toast = useToast();

  const {
    data,
    loading: competenciesLoading,
    refetch: refetchCompetencies,
  } = useOrganizationCompetencyQuery();
  const competencies = data?.currentUser?.organization.competencies ?? [];
  const filteredCompetencies = useMemo(() => {
    return competencies.filter((c) =>
      c.id === competencyId ? true : !c.isMigrated
    );
  }, [competencies, competencyId]);
  const existingCompetencyNames = useMemo(
    () => new Set(filteredCompetencies.map((c) => c.name.trim().toLowerCase())),
    [filteredCompetencies]
  );
  const competencyValue = useMemo(() => {
    return filteredCompetencies.find((c) => c.id === competencyId);
  }, [filteredCompetencies, competencyId]);

  const [addCompetency, { loading: addLoading }] = useAddCompetencyMutation({
    onCompleted: ({ addCompetency }) => {
      if (addCompetency?.competency.id) {
        onUpdate(itemId, { competencyId: addCompetency.competency.id });
        refetchCompetencies();
      }
    },
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
  });

  const handleMove = useCallback(
    (fromIndex: number, toIdx: number): void => {
      if (!childItems) {
        return;
      }
      const item = childItems[fromIndex];
      if (!item) return;
      if (toIdx >= childItems.length) return;

      let toIndex = toIdx;
      const movingDown = toIndex >= fromIndex;
      if (movingDown) toIndex += 1;

      const newList = [] as Array<CallGuideItemChildFragment>;
      for (let i = 0; i < childItems.length; i += 1) {
        // eslint-disable-next-line
        if (i === fromIndex) continue;
        if (i === toIndex) {
          newList.push(childItems[fromIndex]);
        }
        newList.push(childItems[i]);
      }
      if (toIndex === childItems.length) {
        newList.push(childItems[fromIndex]);
      }
      onUpdate(itemId, { childItems: newList });
    },
    [childItems, itemId, onUpdate]
  );

  const tooltipContent = (
    <Text>
      <Text as="span" fontWeight="600">
        Competencies
      </Text>{" "}
      can be used to assess candidates on specific skills, knowledge, or
      abilities. Competencies in BrightHire can be scored or unscored. Scored
      competency results are visible to Hiring Team Admins.
    </Text>
  );

  if (isEditing) {
    return (
      <Box>
        <GuideItemLabel text="Competency" tooltipContent={tooltipContent} />
        <HStack spacing="6" pr={4}>
          <Box width="100%">
            <CreatableSelect
              autoFocus
              placeholder="E.g. Problem Solving"
              isClearable
              value={competencyValue}
              onChange={(value) => {
                onUpdate(itemId, { competencyId: value?.id });
              }}
              onCreateOption={(name) => {
                addCompetency({
                  variables: {
                    callGuideId,
                    name,
                  },
                });
              }}
              options={filteredCompetencies}
              isLoading={competenciesLoading || addLoading}
              noOptionsMessage={() => "No competencies found"}
              getOptionLabel={(competency) => competency.name}
              getOptionValue={(competency) => competency.id}
              isValidNewOption={(inputValue) => {
                const compareValue = inputValue.trim().toLowerCase();
                return (
                  !!compareValue && !existingCompetencyNames.has(compareValue)
                );
              }}
              getNewOptionData={(name) => ({
                id: "",
                name: `Create "${name}"`,
                isMigrated: false,
              })}
            />
          </Box>
          <FormControl
            display="flex"
            width="auto"
            alignItems="center"
            hidden={!orgScoringEnabled}
          >
            <Switch
              id={`${itemId}-scoring-enabled`}
              size="sm"
              isChecked={itemScoringEnabled}
              onChange={() =>
                onUpdate(itemId, { scoringEnabled: !itemScoringEnabled })
              }
            />
            <FormLabel
              htmlFor={`${itemId}-scoring-enabled`}
              color="gray.900"
              pl={1.5}
              mb={0}
              mr={0}
              fontSize="sm"
            >
              Score
            </FormLabel>
          </FormControl>
          <IconButton
            variant="icon"
            aria-label="Delete Competency"
            color="red.400"
            icon={<HiOutlineTrash size={20} />}
            disabled={!canDelete}
            onClick={onDelete}
          />
        </HStack>
        <DragDropContext
          onDragEnd={(result) => {
            if (!result.destination) {
              return;
            }
            handleMove(result.source.index, result.destination.index);
          }}
        >
          <Droppable droppableId={`droppable=${itemId}`}>
            {(provided) => (
              // eslint-disable-next-line @typescript-eslint/unbound-method
              <Box {...provided.droppableProps} ref={provided.innerRef}>
                {childItems?.map((child, index) => (
                  <Draggable
                    key={child.id}
                    draggableId={child.id}
                    index={index}
                    isDragDisabled={false}
                  >
                    {(provided) => (
                      <Flex
                        key={child.id}
                        data-testid={`competency-question-${index}`}
                        alignItems="flex-start"
                        backgroundColor="gray.50"
                        pt={4}
                        pb={5}
                        pr={4}
                        border="1px solid"
                        borderColor={
                          idsWithErrors.includes(child.id) && !child.description
                            ? "red"
                            : "gray.200"
                        }
                        borderRadius="8px"
                        mt={5}
                        // eslint-disable-next-line @typescript-eslint/unbound-method
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <Flex
                          {...provided.dragHandleProps}
                          height={8}
                          width={8}
                          mr={1}
                          ml={2}
                          alignItems="center"
                          justifyContent="center"
                          data-testid={`drag-handle-${index}`}
                          visibility={
                            childItems.length > 1 ? "visible" : "hidden"
                          }
                        >
                          <DragHandleDots width={5} height={5} />
                        </Flex>
                        <GuideItemQuestion
                          itemId={child.id}
                          text={child.description}
                          isEditing={isEditing}
                          canDelete
                          onDelete={() => onDeleteChild(itemId, child.id)}
                          onEnter={onAddQuestion}
                          onUpdate={(childId, update) => {
                            onUpdateChild(itemId, childId, update);
                          }}
                        />
                      </Flex>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
        <Button
          data-testid="add-competency-question"
          size="sm"
          mt={5}
          justifyContent="flex-start"
          variant="ghost"
          fontSize="sm"
          fontWeight="500"
          leftIcon={<HiOutlinePlus />}
          onClick={onAddQuestion}
        >
          Add question
        </Button>
      </Box>
    );
  }
  return (
    <Box>
      <Flex
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pb={childItems?.length ? 1 : 0}
      >
        {competencyValue?.name && (
          <Text color="gray.800" fontWeight="600" fontSize="md">
            {competencyValue.name}
          </Text>
        )}
        {!competencyValue?.name && (
          <Text color="gray.600" as="i" fontSize="md">
            Add competency
          </Text>
        )}
        {orgScoringEnabled && (
          <Text
            fontSize="xs"
            fontWeight="600"
            py={0.5}
            px={1.5}
            color={`${itemScoringEnabled ? "green" : "gray"}.700`}
            backgroundColor={`${itemScoringEnabled ? "green" : "gray"}.100`}
          >
            Scoring {itemScoringEnabled ? "Enabled" : "Disabled"}
          </Text>
        )}
      </Flex>
      {childItems?.map((child) => (
        <Box
          key={child.id}
          borderRadius="8px"
          backgroundColor="gray.50"
          py={3.5}
          px={4}
          mt={3}
        >
          <GuideItemQuestion
            itemId={child.id}
            text={child.description}
            isEditing={false}
            onDelete={() => onDeleteChild(itemId, child.id)}
            onEnter={onEnter}
            onUpdate={(childId, update) => {
              onUpdateChild(itemId, childId, update);
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default GuideItemCompetency;
