import { Box, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import { BsDot } from "react-icons/bs";

import { useSendGAEvent } from "../../../utils/googleAnalytics";
import useCurrentUser from "../../hooks/useCurrentUser";
import ActivityFeed from "../../pages/home/ActivityFeed/ActivityFeed";
import CandidateAlertFeed from "../CandidateAlert/CandidateAlertFeed";
import { useCandidateAlertEnabled } from "../CandidateAlert/useCandidateAlertEnabled";
import { SidebarTab, SidebarTabs } from "../Recording";

enum FeedSidebarTab {
  ACTIVITY = "activity",
  ALERTS = "alerts",
}

const FeedSidebar: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(FeedSidebarTab.ALERTS);
  const [hasUnreadAlerts, setHasUnreadAlerts] = useState(false);
  const sendGAEvent = useSendGAEvent();
  const currentUser = useCurrentUser();

  const candidateAlertsEnabled = useCandidateAlertEnabled(
    currentUser.organization.id
  );

  if (!candidateAlertsEnabled) {
    return (
      <Box
        border="1px solid"
        borderColor="gray.100"
        borderRadius="lg"
        color="gray.900"
        bg="white"
        width="100%"
      >
        <ActivityFeed header="Recent activity" />
      </Box>
    );
  }

  return (
    <Box
      border="1px solid"
      borderColor="gray.100"
      borderRadius="lg"
      color="gray.900"
      bg="white"
      width="100%"
    >
      <SidebarTabs
        id="homepage-feed-sidebar"
        currentTab={currentTab}
        borderBottom="none"
        onTabChange={(newTab) => {
          if (newTab !== currentTab) {
            sendGAEvent("feed_sidebar_tab_change", "call_review", newTab);
          }
          setCurrentTab(newTab);
        }}
      >
        <SidebarTab
          id={FeedSidebarTab.ALERTS}
          displayName={
            <Flex alignItems="center">
              Alerts
              <BsDot
                visibility={hasUnreadAlerts ? "visible" : "hidden"}
                viewBox="4 4 12 12"
                size={20}
                color="red"
              />
            </Flex>
          }
        >
          <Flex direction="column" py="4" gap="4">
            <CandidateAlertFeed setHasUnreadAlerts={setHasUnreadAlerts} />
          </Flex>
        </SidebarTab>
        <SidebarTab id={FeedSidebarTab.ACTIVITY} displayName="Recent activity">
          <ActivityFeed />
        </SidebarTab>
      </SidebarTabs>
    </Box>
  );
};

export default FeedSidebar;
