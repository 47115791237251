import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";

function useControlledScroll<T extends HTMLElement>({
  ref,
  playing,
  buffer = 50,
}: UseScrollToArgs<T>): ScrollTo {
  const [lastScrollTo, setLastScrollTo] = useState(Infinity);
  const [autoScrolling, setAutoScrolling] = useState(true);
  const el = ref.current;

  // Enable auto-scrolling whenever the play state changes
  useEffect(() => {
    setAutoScrolling(true);
  }, [playing]);

  // If user scrolls while playing, stop auto-scroll
  useLayoutEffect(() => {
    if (!el) return;

    const onScroll = (): void => {
      const isUserScroll = now() - lastScrollTo > buffer;
      if (playing && isUserScroll) {
        setAutoScrolling(false);
      }
    };

    el.addEventListener("scroll", onScroll);
    return () => el.removeEventListener("scroll", onScroll);
  }, [el, playing, lastScrollTo, buffer]);

  // Only honor programmatic scrolls if auto-scroll is on
  const scrollTo = useCallback(
    (top: number): void => {
      if (!el?.scrollTo || !autoScrolling) return;
      el.scrollTo({ top, left: 0 });
      setLastScrollTo(now());
    },
    [el, autoScrolling]
  );

  return { scrollTo };
}

export default useControlledScroll;

function now(): number {
  return new Date().valueOf();
}

type UseScrollToArgs<T> = {
  ref: React.RefObject<T>;
  playing: boolean;
  buffer?: number;
};

type ScrollTo = {
  scrollTo: (y: number) => void;
};
