import React, { ReactElement, useEffect, useRef, useState } from "react";

import config from "../../config";

export interface RenderWithDelayProps {
  children?: React.ReactNode;
  delay?: number;
  placeholder?: ReactElement | null;
}

export const RenderWithDelay: React.FC<RenderWithDelayProps> = ({
  delay = 300,
  placeholder = null,
  children,
}) => {
  const [pastDelay, setPastDelay] = useState(false);
  const timeout = useRef<NodeJS.Timeout | null>(null);
  if (config.appEnv === "test") {
    // eslint-disable-next-line no-param-reassign
    delay = 0;
  }
  useEffect(() => {
    if (delay === 0) {
      setPastDelay(true);
      return;
    }
    timeout.current = setTimeout(() => {
      setPastDelay(true);
    }, delay);

    return function cleanup() {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [delay]);

  if (pastDelay) {
    return <>{children}</>;
  }
  return placeholder;
};
