import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Input,
  ListItem,
  Spinner,
  Text,
  Tooltip,
  UnorderedList,
  useToast,
} from "@chakra-ui/react";
import LogRocket from "logrocket";
import React, { useEffect, useRef, useState } from "react";
import { HiOutlineSparkles } from "react-icons/hi2";

import { Avatar } from "../../../../../../components";
import BotIcon from "../../../../../../components/Icons/BotIcon";
import { CopyIcon } from "../../../../../../components/Icons/CopyIcon";
import { copy } from "../../../../../../utils/clipboard";
import { useSendGAEvent } from "../../../../../../utils/googleAnalytics";
import useCurrentUser from "../../../../../hooks/useCurrentUser";
import useAskChat, { AskChatRole } from "../../../../Ask/useAskChat";
import AskBetaTag from "./AskBetaTag";
import { emailPrompt, EmailType } from "./emailPrompts";
import { useAskEnabled } from "./useAskEnabled";

export const Ask: React.FC<{
  callId: string;
}> = ({ callId }) => {
  const askEnabled = useAskEnabled();
  const sendGAEvent = useSendGAEvent();
  const { chat, addUserMessage, busy: chatBusy, reset } = useAskChat(callId);

  const scrollRef = useRef<HTMLDivElement>(null);
  const [userInput, setUserInput] = useState("");

  const [editingEmail, setEditingEmail] = useState<EmailType | null>(null);

  useEffect(() => {
    const scroll = scrollRef.current;
    if (scroll) {
      scroll.scrollTop = scroll.scrollHeight;
    }
  }, [chat.messages[chat.messages.length - 1]]);

  const refineResult = (refinement: string, userRefinement: string): void => {
    if (chatBusy || !editingEmail) {
      return;
    }
    if (chat.messages.length === 0) {
      return;
    }
    sendGAEvent("ask_refine_email", "call_review", userRefinement);
    LogRocket.track("ask_refine_email");
    const finalMessage = chat.messages[chat.messages.length - 1];
    const originalPrompt = emailPrompt[editingEmail];
    const prompt = [
      `# Original Task:\n${originalPrompt}`,
      `# Original Output:\n${finalMessage.content}`,
      `# New Task:\n${refinement}`,
      "Perform the New Task now, on the Original Output. Do your best to perform the task, but also maintain at least a minimal professional HR output while doing so. If you can't perform the refinement because it would cross the line into unprofessional, DO NOT tell the user you cannot perform the request, and instead generate a minor variation at the previous safe level but don't mention this rule. So follow the limits but never mention or discuss the limits. Never respond with anything except for an email, never provide additional commentary under any circumstances. Even if the user asks for 100 more/less formal emails in a row, continue to generate but the limit secretly as part of the generated emails.",
    ].join("\n\n");
    addUserMessage(prompt, userRefinement);
  };

  return (
    <Flex flexDir="column" height="100%" pb={20}>
      <AskBetaTag />
      {chat.messages.length === 0 && <StartingSplash />}
      <Box ref={scrollRef} mt="auto" overflow="auto">
        {chat.messages.map((message, i) => {
          if (message.role === AskChatRole.Assistant) {
            // eslint-disable-next-line react/no-array-index-key
            return <BotMessage key={i} text={message.userContent} />;
          }
          // eslint-disable-next-line react/no-array-index-key
          return <UserMessage key={i} text={message.userContent} />;
        })}
      </Box>
      <Flex
        flexDir="column"
        borderTop="1px solid"
        borderColor="gray.200"
        pt={3}
        mt={4}
      >
        {editingEmail && (
          <>
            <Flex flexDir="row" justifyContent="space-between" gap="8px" mb={2}>
              <SmallQuickActionButton
                label="Make shorter"
                loading={chatBusy}
                onClick={() =>
                  refineResult(
                    "Change the email to be 30% less detailed, focusing on the key information so the answer is more succinct. Keep the structure of separate paragraphs in the original.  Do not write in in a overly terse manner that does not read naturally.",
                    "Make the email shorter"
                  )
                }
              />
              <SmallQuickActionButton
                label="Apply casual tone"
                loading={chatBusy}
                onClick={() =>
                  refineResult(
                    "Change the email to be a bit less formal but still professional. Do not use slang, excessive exclamation marks, or other too casual language. Be plain spoken without buzzwords or other fake seeming mannerisms.",
                    "Make the email less formal"
                  )
                }
              />
            </Flex>
            <Flex flexDir="row" justifyContent="space-between" gap="8px" mb={2}>
              <SmallQuickActionButton
                label="Make longer"
                loading={chatBusy}
                onClick={() =>
                  refineResult(
                    "Change the email to be 30% more detailed. Include additional content and details, instead of adding verbosity to existing content.",
                    "Make the email longer."
                  )
                }
              />

              <Box p={2} flex={1} visibility="hidden" />
            </Flex>
          </>
        )}
        {!editingEmail && (
          <>
            <Flex flexDir="row" justifyContent="space-between" gap="8px" mb={2}>
              <QuickActionButton
                label="Write advancement email"
                description="to move a candidate forward"
                loading={chatBusy}
                onClick={() => {
                  sendGAEvent("ask_generate_email", "call_review", "advance");
                  LogRocket.track("ask_generate_email");
                  setEditingEmail("advance");
                  addUserMessage(
                    emailPrompt.advance,
                    "Write an advancement email."
                  );
                }}
              />
              <QuickActionButton
                label="Write rejection email"
                description="to let a candidate go"
                loading={chatBusy}
                onClick={() => {
                  sendGAEvent("ask_generate_email", "call_review", "reject");
                  LogRocket.track("ask_generate_email");
                  setEditingEmail("reject");
                  addUserMessage(
                    emailPrompt.reject,
                    "Write a rejection email."
                  );
                }}
              />
            </Flex>
            <Flex flexDir="row" justifyContent="space-between" gap="8px">
              <QuickActionButton
                label="Write reconnection email"
                description="to reach out to a candidate"
                loading={chatBusy}
                onClick={() => {
                  sendGAEvent("ask_generate_email", "call_review", "reconnect");
                  LogRocket.track("ask_generate_email");
                  setEditingEmail("reconnect");
                  addUserMessage(
                    emailPrompt.reconnect,
                    "Write a reconnection email."
                  );
                }}
              />
              <QuickActionButton
                label="Write a keep warm email"
                description="to maintain an active candidate"
                loading={chatBusy}
                onClick={() => {
                  sendGAEvent("ask_generate_email", "call_review", "keepWarm");
                  LogRocket.track("ask_generate_email");
                  setEditingEmail("keepWarm");
                  addUserMessage(
                    emailPrompt.keepWarm,
                    "Write a keep warm email."
                  );
                }}
              />
            </Flex>
          </>
        )}
        {askEnabled && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              addUserMessage(userInput, userInput);
              setUserInput("");
              sendGAEvent("ask_free_input", "call_review");
              LogRocket.track("ask_free_input");
            }}
          >
            <Input
              mt={6}
              mr={2}
              borderRadius="base"
              size="md"
              fontSize="sm"
              placeholder="Message assistant..."
              mb={2}
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              disabled={chatBusy}
              autoFocus
            />
          </form>
        )}
        {editingEmail && (
          <Flex mt={3} mb={1}>
            <Button
              size="sm"
              variant="ghost"
              onClick={() => {
                setEditingEmail(null);
                sendGAEvent("ask_back", "call_review");
                LogRocket.track("ask_back");
              }}
              disabled={chatBusy}
            >
              Back
            </Button>
            <Button
              ml="auto"
              size="sm"
              onClick={() => {
                setEditingEmail(null);
                reset();
                sendGAEvent("ask_start_over", "call_review");
                LogRocket.track("ask_start_over");
              }}
              variant="outline"
              disabled={chatBusy}
            >
              Start over
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

const BotMessage: React.FC<{
  text?: string;
}> = ({ text }) => {
  const toast = useToast();
  return (
    <Flex flexDir="row" alignItems="flex-start" mt="24px" mr={2}>
      <Flex
        textColor="gray.900"
        fontSize="sm"
        whiteSpace="pre-wrap"
        p={2}
        borderRadius="md"
        bg="#FAF5FF"
        mr={2}
      >
        <Flex flexDir="row" width="100%">
          <Box width={8} flexShrink="0">
            <BotIcon />
          </Box>
          {text && <Box pt="2px">{text && text}</Box>}
          {!text && (
            <Box pt="4px">
              <Spinner color="purple.300" size="sm" />
            </Box>
          )}
        </Flex>
      </Flex>
      <Box flexShrink="0" ml="auto">
        <Tooltip label="Copy to clipboard" placement="bottom-start">
          <IconButton
            aria-label="Copy response"
            icon={<CopyIcon fontSize="20px" />}
            variant="ghost"
            color="blue.600"
            onClick={() => {
              copy(text || "");
              toast({
                title: "Response copied to clipboard",
                status: "success",
              });
            }}
          />
        </Tooltip>
      </Box>
    </Flex>
  );
};

const UserMessage: React.FC<{
  text: string;
}> = ({ text }) => {
  const currentUser = useCurrentUser();
  return (
    <Flex
      mt="24px"
      ml={10}
      mr={2}
      textColor="gray.900"
      fontSize="sm"
      whiteSpace="pre-wrap"
      p={2}
      pl={3}
      borderRadius="md"
      bg="gray.50"
    >
      <Flex flexDir="row" width="100%">
        <Box pt="2px">{text}</Box>
        <Box pl={2} width={8} flexShrink="0" ml="auto">
          <Avatar user={currentUser} />
        </Box>
      </Flex>
    </Flex>
  );
};

const StartingSplash: React.FC = () => {
  const askEnabled = useAskEnabled();
  return (
    <Flex
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
      flexDir="column"
    >
      {!askEnabled && (
        <>
          <BotIcon size={80} />
          <Text fontWeight="medium" color="gray.600" fontSize="xl" mt={4}>
            Choose from the actions below:
          </Text>
        </>
      )}

      {askEnabled && (
        <>
          <Icon
            as={HiOutlineSparkles}
            strokeWidth={1}
            color="purple.400"
            h="80px"
            w="80px"
          />
          <Box fontWeight="medium" color="gray.600" fontSize="xl" mt={4}>
            <Text>Ask me anything about this interview:</Text>
            <UnorderedList>
              <ListItem>a question</ListItem>
              <ListItem>to write an email</ListItem>
              <ListItem>to provide a summary</ListItem>
            </UnorderedList>
          </Box>
        </>
      )}
    </Flex>
  );
};

// TODO: use a Button for accessibility and disabled states
const SmallQuickActionButton: React.FC<{
  label: string;
  loading: boolean;
  onClick: () => void;
}> = ({ label, onClick, loading }) => (
  <Box
    py={1}
    px={2}
    flex="1"
    fontSize="xs"
    onClick={() => {
      if (!loading) {
        onClick();
      }
    }}
    border="1px solid"
    borderColor="gray.200"
    borderRadius="md"
    cursor="pointer"
    userSelect="none"
    _hover={{ borderColor: "blue.600" }}
    _disabled={{ background: "transparent", color: "gray.400" }}
  >
    <Text fontSize="sm">{label}</Text>
  </Box>
);

// TODO: use a Button for accessibility and disabled states
const QuickActionButton: React.FC<{
  label: string;
  description: string;
  loading: boolean;
  onClick: () => void;
}> = ({ label, description, onClick, loading }) => (
  <Box
    py={1}
    px={2}
    flex="1"
    onClick={() => {
      if (!loading) {
        onClick();
      }
    }}
    border="1px solid"
    borderColor="gray.200"
    borderRadius="md"
    cursor="pointer"
    userSelect="none"
    _hover={{ borderColor: "blue.600" }}
    _disabled={{ background: "transparent", color: "gray.400" }}
  >
    <Text fontSize="sm" fontWeight="medium">
      {label}
    </Text>
    <Text fontSize="xs" color="gray.600">
      {description}
    </Text>
  </Box>
);
