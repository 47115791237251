import { Box } from "@chakra-ui/react";
import React from "react";

import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { TranscriptWord as TranscriptWordType } from "../../../graphql";
import { canViewRedactedWord } from "./utils";

interface TranscriptWordProps {
  word: TranscriptWordType;
  color?: string;
  backgroundColor?: string;
  onClick: (time: number) => void;
  isMultiHighlight?: boolean;
  isFinalPartOfMatch?: boolean;
}

const TranscriptWordBeta = React.forwardRef<
  HTMLDivElement,
  TranscriptWordProps
>(
  (
    {
      word,
      color,
      backgroundColor,
      onClick,
      isMultiHighlight,
      isFinalPartOfMatch,
    },
    ref
  ) => {
    const handleClick = (): void => {
      sendGAEvent("playback_control", "call_review", "transcript_play");
      onClick(word.startTime);
    };
    const displaySpace = word.word === "" ? "" : " ";
    const sendGAEvent = useSendGAEvent();
    const innerBackgroundColor =
      isMultiHighlight && !isFinalPartOfMatch ? undefined : backgroundColor;
    const outerBackgroundColor =
      isMultiHighlight && !isFinalPartOfMatch ? backgroundColor : undefined;
    let cursor: string | undefined = "text";
    if (word.isRedacted && !canViewRedactedWord(word)) {
      cursor = undefined;
    }
    return (
      <Box
        as="span"
        onClick={handleClick}
        backgroundColor={outerBackgroundColor}
        ref={ref}
        py="2px"
        userSelect={word.isRedacted ? "none" : undefined}
        cursor={cursor}
      >
        <Box
          as="span"
          display="inline-flex"
          alignItems="center"
          lineHeight="150%"
          color={color}
          backgroundColor={innerBackgroundColor}
        >
          {word.word}
        </Box>
        {displaySpace}
      </Box>
    );
  }
);

export default TranscriptWordBeta;
