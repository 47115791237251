import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import React, { FC } from "react";
import { HiOutlineInformationCircle } from "react-icons/hi";

interface GuideItemLabelProps {
  text: string;
  tooltipContent: JSX.Element;
}

const GuideItemLabel: FC<GuideItemLabelProps> = ({ text, tooltipContent }) => (
  <Box display="inline-block" mb={1}>
    <Flex direction="row" alignItems="center" color="gray.500">
      <Text color="gray.600" fontSize="sm" fontWeight="400" mr={1}>
        {text}
      </Text>
      <Tooltip
        shouldWrapChildren
        variant="light"
        hasArrow
        placement="top"
        label={tooltipContent}
      >
        <HiOutlineInformationCircle />
      </Tooltip>
    </Flex>
  </Box>
);

export default GuideItemLabel;
