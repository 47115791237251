import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import LogRocket from "logrocket";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { IoCopyOutline } from "react-icons/io5";

import { EmptyAIIcon } from "../../../../../../components/Images";
import { useSendGAEvent } from "../../../../../../utils/googleAnalytics";
import {
  CallAiSummaryFormat,
  CallAiSummaryProcessingStatus,
  CallBetaFragment,
  TranscriptionStatus,
  useCallAiSummaryLazyQuery,
  useUpdateCallAiSummaryFormatMutation,
} from "../../../../../graphql";
import useFeatureFlag from "../../../../../graphql/hooks/useFeatureFlag";
import { useAvailableAiSummaryFormats } from "../../../../../hooks/useAvailableAiSummaryFormats";
import useMouseHasMoved from "../../../../../hooks/useMouseHasMoved";
import { useUrlState } from "../../../../../pages/home/useTabUrlState";
import FormatSelector from "./NotesFormatSelector";
import NotesOtherFormats from "./NotesOtherFormats";
import AiNotesMessage from "./NotesQA/AiNotesMessage";
import NotesQandAFormat from "./NotesQAFormat";

type NotesProps = {
  call: Pick<
    CallBetaFragment,
    | "id"
    | "canEdit"
    | "candidate"
    | "position"
    | "startTime"
    | "speakers"
    | "transcriptionStatus"
    | "aiNotesFormat"
    | "aiNotesCustomFormat"
  >;
  hasLabeledCandidate: boolean;
  onClickTimestamp(t: number): void;
};

export const Notes: React.FC<NotesProps> = ({
  call,
  hasLabeledCandidate,
  onClickTimestamp,
}) => {
  const toast = useToast();
  const availableFormats = useAvailableAiSummaryFormats();
  const canUseOtherAISummaryFormats = availableFormats.length > 0;
  const copyNotesRef = useRef<(() => void) | null>(null);
  const registerCopyNotes = useCallback(
    (f: () => void) => {
      copyNotesRef.current = f;
    },
    [copyNotesRef]
  );
  const debugEnabled = useFeatureFlag("ai_notes:debug");

  const [fetchSummary, aiSummary] = useCallAiSummaryLazyQuery({
    onError: (err) => {
      toast({
        title: "Error",
        description: "Error getting Notes",
        status: "error",
        position: "top",
      });
    },
  });
  const [generateSummary] = useUpdateCallAiSummaryFormatMutation({
    onError: (err) => {
      toast({
        title: "Error",
        description: "Error generating summary",
        status: "error",
        position: "top",
      });
    },
  });

  const [currentUrlCustomId, setUrlCustomId] = useUrlState({
    key: "customTemplateId",
  });
  // This order matters -- Check if a template was shared by link first
  const [customTemplateId, setCustomTemplateId] = useState<string | null>(
    currentUrlCustomId ?? call.aiNotesCustomFormat?.customTemplateId ?? null
  );
  useEffect(() => {
    if (currentUrlCustomId !== customTemplateId) {
      setUrlCustomId(customTemplateId);
    }
  }, [customTemplateId]);
  const [format, setFormat] = useState<CallAiSummaryFormat>(
    currentUrlCustomId
      ? CallAiSummaryFormat.Custom
      : call.aiNotesCustomFormat?.format ??
          call.aiNotesFormat ??
          CallAiSummaryFormat.Qanda
  );

  const aiSummaryStatus = aiSummary.data?.callAiSummary?.status;
  const aiSummaryFormat = aiSummary.data?.callAiSummary?.format;
  const aiSummaryProcessingStartedAt =
    aiSummary.data?.callAiSummary?.processingStartedAt;
  const aiSummaryCustomId = aiSummary.data?.callAiSummary?.customTemplateId;

  useEffect(() => {
    if (!canUseOtherAISummaryFormats) {
      aiSummary.stopPolling();
      return;
    }
    if (format === CallAiSummaryFormat.Qanda) {
      aiSummary.stopPolling();
      return;
    }

    // Need to check format and custom template changes to trigger a re-render
    if (aiSummaryFormat === format && customTemplateId === aiSummaryCustomId) {
      if (
        aiSummaryStatus &&
        [
          CallAiSummaryProcessingStatus.Completed,
          CallAiSummaryProcessingStatus.Failed,
        ].includes(aiSummaryStatus)
      ) {
        aiSummary.stopPolling();
        return;
      }
    }

    if (!availableFormats.includes(format)) {
      aiSummary.stopPolling();
      return;
    }

    fetchSummary({
      variables: {
        callId: call.id,
        format,
        customTemplateId: currentUrlCustomId,
      },
      pollInterval: 5000,
      fetchPolicy: "network-only",
    });
  }, [
    canUseOtherAISummaryFormats,
    format,
    customTemplateId,
    currentUrlCustomId,
    fetchSummary,
    aiSummary.stopPolling,
    aiSummaryStatus,
    aiSummaryFormat,
    aiSummaryProcessingStartedAt,
    availableFormats,
    call.id,
  ]);
  const mouseHasMoved = useMouseHasMoved();
  useEffect(() => {
    LogRocket.track("ai-notes-tab-view");
    sendGAEvent("ai_notes_tab_view", "call_review");
  }, [mouseHasMoved]);
  const sendGAEvent = useSendGAEvent();
  const transcriptAvailable =
    call.transcriptionStatus === TranscriptionStatus.Completed;

  const commonWrapperProps = {
    copyNotesRef,
    format,
    customTemplateId,
    setFormat,
    setCustomTemplateId,
    callId: call.id,
  };

  if (!transcriptAvailable) {
    return (
      <NotesWrapper {...commonWrapperProps}>
        <AiNotesMessage>
          <EmptyAIIcon marginBottom="64px" />
          <Text align="center" maxW="360px">
            Hold tight! AI Notes are generated once the transcript for the
            interview is ready.
          </Text>
        </AiNotesMessage>
      </NotesWrapper>
    );
  }

  const retryFetchSummary = (): void => {
    fetchSummary({
      variables: { callId: call.id, format, customTemplateId },
      pollInterval: 5000,
      fetchPolicy: "network-only",
    });
  };

  const regenerate = (): void => {
    generateSummary({
      variables: {
        callId: call.id,
        format,
        customTemplateId,
        targetSpeakerTags: [],
        customTopics: [],
        forceRegenerate: true,
      },
    }).then(() => {
      retryFetchSummary();
    });
  };

  if (
    format !== CallAiSummaryFormat.Qanda &&
    availableFormats.includes(format)
  ) {
    if (aiSummary.data != null) {
      return (
        <NotesWrapper
          onRetry={retryFetchSummary}
          debugEnabled={debugEnabled}
          regenerate={regenerate}
          {...commonWrapperProps}
        >
          <NotesOtherFormats
            data={aiSummary.data}
            callId={call.id}
            onClickTimestamp={onClickTimestamp}
            loading={aiSummary.loading}
            registerCopyNotes={registerCopyNotes}
            onRetry={retryFetchSummary}
          />
        </NotesWrapper>
      );
    }
    return <NotesWrapper {...commonWrapperProps} />;
  }

  if (!hasLabeledCandidate) {
    return (
      <NotesWrapper {...commonWrapperProps}>
        <AiNotesMessage>
          <EmptyAIIcon marginBottom="64px" />
          <Text align="center" maxW="360px">
            AI Notes are not available for this interview because we were unable
            to identify a candidate.
          </Text>
          {canUseOtherAISummaryFormats && (
            <Text align="center" mt={3} mb={3} maxW="360px">
              If this was not an interview, try selecting a different AI Notes
              format.
            </Text>
          )}
        </AiNotesMessage>
      </NotesWrapper>
    );
  }

  return (
    <NotesWrapper {...commonWrapperProps}>
      <NotesQandAFormat
        call={call}
        onClickTimestamp={onClickTimestamp}
        registerCopyNotes={registerCopyNotes}
      />
    </NotesWrapper>
  );
};

interface NotesWrapperProps {
  format: CallAiSummaryFormat;
  customTemplateId?: string | null;
  setFormat: (f: CallAiSummaryFormat) => void;
  setCustomTemplateId: (f: string | null) => void;
  callId: string;
  copyNotesRef: React.MutableRefObject<(() => void) | null>;
  onRetry?: () => void;
  debugEnabled?: boolean;
  regenerate?: () => void;
}

const NotesWrapper: React.FC<React.PropsWithChildren<NotesWrapperProps>> = ({
  children,
  format,
  customTemplateId,
  setFormat,
  setCustomTemplateId,
  callId,
  copyNotesRef,
  onRetry,
  debugEnabled,
  regenerate,
}) => {
  return (
    <Flex
      data-tour-id="ai-notes-tab-content"
      flexDir="column"
      h="100%"
      position="relative"
    >
      <Flex
        flexDir="row"
        my="2"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Flex flexDir="column">
          <Flex
            alignItems="center"
            display="flex"
            fontSize="xs"
            color="gray.600"
            mb="1"
          >
            Notes template
            <Popover trigger="hover" placement="bottom">
              <PopoverTrigger>
                <Flex alignItems="center" pl="2px">
                  <Icon as={HiOutlineInformationCircle} color="gray.600" />
                </Flex>
              </PopoverTrigger>
              <PopoverContent
                minWidth="300px"
                borderColor="gray.100"
                bg="white"
                p="4"
              >
                <Box fontSize="sm" color="gray.800">
                  <strong>Templates</strong> allow you to change the format of
                  your AI notes.
                </Box>
              </PopoverContent>
            </Popover>
          </Flex>
          <FormatSelector
            format={format}
            customTemplateId={customTemplateId}
            setFormat={setFormat}
            setCustomTemplateId={setCustomTemplateId}
            callId={callId}
            onRetry={onRetry}
          />
        </Flex>
        <Tooltip label="Copy all notes" placement="bottom-start">
          <IconButton
            data-tour-id="ai-notes-copy-button"
            icon={<IoCopyOutline size={20} />}
            aria-label="Copy all notes"
            size="sm"
            p="2"
            variant="ghost"
            flexShrink={0}
            display={copyNotesRef.current == null ? "none" : ""}
            onClick={() => {
              copyNotesRef.current?.();
            }}
          />
        </Tooltip>
      </Flex>
      <Box flex="1" overflow="auto">
        {debugEnabled && regenerate && (
          <Flex
            bg="gray.50"
            borderRadius="8px"
            mb="2"
            justify="end"
            alignItems="center"
          >
            <Text fontSize="xs">
              Visible only if debug feature flag is enabled
            </Text>
            <Button variant="ghost" size="xs" onClick={() => regenerate()}>
              Regenerate
            </Button>
          </Flex>
        )}
        {children}
        <Box
          id="ai-notes-feedback"
          height="65px" // 65px is the height of the feedback bar
        />
      </Box>
    </Flex>
  );
};
