import { Flex } from "@chakra-ui/react";
import React from "react";

import { Card } from "../../components";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import DefaultLayout from "./DefaultLayout";

interface CenteredContentLayoutProps {
  children: React.ReactNode;
}
const CenteredContentLayout: React.FC<CenteredContentLayoutProps> = ({
  children,
}) => {
  const { layoutHeight } = useWindowDimensions();
  return (
    <DefaultLayout>
      <Flex height={layoutHeight} width="100%" direction="column">
        <Card p="pagePadding" mx="auto" mt="50px" mb="auto" maxW="750px">
          {children}
        </Card>
      </Flex>
    </DefaultLayout>
  );
};

export default CenteredContentLayout;
