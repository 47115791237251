import { Box, Heading, Text } from "@chakra-ui/react";
import React from "react";

import { Alert, LoadingIndicator } from "../../../components";
import useOffsetPagination from "../../../hooks/useOffsetPagination";
import {
  ClipListItemFragment,
  useCurrentUserClipsLazyQuery,
} from "../../graphql";
import { DefaultPageLayout } from "../../layouts";
import { ClipListItem } from "./ClipListItem";

const MyClips: React.FC = () => {
  const [getUserClips, { error: userClipsError }] =
    useCurrentUserClipsLazyQuery({
      fetchPolicy: "network-only",
      onCompleted: ({ currentUser }) => {
        if (currentUser?.clips?.results) {
          const clipsList = currentUser?.clips?.results;
          setUserClips(clipsList);
          setNumberOfUserClips(currentUser?.clips.pageInfo?.totalPages || 0);
        }
      },
    });

  const {
    listItems: userClips,
    setListItems: setUserClips,
    setNumberOfPages: setNumberOfUserClips,
    didFetchComplete: didUserClipsFetchComplete,
  } = useOffsetPagination<ClipListItemFragment>(getUserClips, {
    initialSortBy: { id: "updated_at", desc: true },
    ignoreURLParams: true,
  });

  if (userClipsError)
    return (
      <Alert
        status="error"
        title="Error loading clips"
        description={userClipsError.message}
      />
    );

  const clipListItems = userClips.length ? (
    userClips.map((clip: ClipListItemFragment, i: number) => (
      <ClipListItem key={clip.id} clip={clip} listPosition={i} />
    ))
  ) : (
    <Box px="10" textAlign="center" borderTop="gray.100">
      <Text color="gray.700" fontSize="sm" fontWeight="400" mt="60">
        You have not created any clips yet
      </Text>
    </Box>
  );

  return (
    <DefaultPageLayout maxWidth="1100px" px="4" pb="8">
      <Box pt="7">
        <Heading
          as="h1"
          mb="3"
          color="gray.900"
          fontSize="20px"
          fontWeight="500"
        >
          My clips
        </Heading>
        <Text
          fontWeight="400"
          fontSize="sm"
          color="gray.700"
          mb={{ base: "5", lg: "10" }}
        >
          All clips you create will show up here. This list is visible only to
          you.
        </Text>
      </Box>
      {!didUserClipsFetchComplete ? <LoadingIndicator /> : clipListItems}
    </DefaultPageLayout>
  );
};

export default MyClips;
