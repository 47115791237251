import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Link,
  Select,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { Controller, useForm } from "react-hook-form";

import { Alert, useToast } from "../../../components";
import {
  CurrentUserFragment,
  UpdateOrganizationAdvancedConfigurationsMutationVariables as FormData,
  useUpdateOrganizationAdvancedConfigurationsMutation,
} from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import SettingsPageContainer from "./shared/SettingsPageContainer";

interface AdvancedConfigurationsProps {
  currentUser: CurrentUserFragment;
}

const EXTERNAL_SHARE_DURATION_VALUES = [
  { value: 0.125, name: "3 hours" },
  { value: 0.25, name: "6 hours" },
  { value: 0.5, name: "12 hours" },
  { value: 1, name: "1 day" },
  { value: 5, name: "5 days" },
  { value: 7, name: "7 days" },
  { value: 14, name: "14 days" },
  { value: 21, name: "21 days" },
  { value: 28, name: "28 days" },
];

const AdvancedConfigurations: React.FC<AdvancedConfigurationsProps> = ({
  currentUser,
}) => {
  const toast = useToast();
  const [updateOrganizationSettings, { loading, error }] =
    useUpdateOrganizationAdvancedConfigurationsMutation({
      onCompleted: (data) => {
        if (data?.updateOrganizationAdvancedConfigurations?.currentUser) {
          toast({
            status: "success",
            title: "Settings saved",
          });
        }
      },
      onError: () => {
        toast({
          status: "error",
          title: "Organization settings",
          description: "There was a problem - please try again",
        });
      },
    });

  const autoAssignGuidesEnabled = useFeatureFlag(
    "assign-guides-interviewers:v1"
  );
  const inPersonEnabled = useFeatureFlag("in_person_interviews");

  const {
    hideOtherInterviewerNotesInExtension,
    assignGuidesToInterviewersEnabled,
    audioOnlyViewing,
    allowManualScheduledInterviews,
    allowInPersonMeetings,
    playInPersonRecordingDisclaimer,
    externalShareDefaultDurationDays,
    textBeforeCallEnabled,
  } = currentUser.organization;

  const { register, handleSubmit, control, watch } = useForm<FormData>({
    defaultValues: {
      hideOtherInterviewerNotesInExtension,
      assignGuidesToInterviewersEnabled,
      audioOnlyViewing,
      allowManualScheduledInterviews,
      allowInPersonMeetings,
      textBeforeCallEnabled,
      playInPersonRecordingDisclaimer,
      externalShareDefaultDurationDays,
    },
  });

  return (
    <SettingsPageContainer heading="Advanced configurations">
      <form
        autoComplete="on"
        onSubmit={handleSubmit((formData) => {
          updateOrganizationSettings({ variables: formData });
        })}
      >
        {error?.graphQLErrors?.map(({ message }, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Alert key={i} status="error" description={message} />
        ))}
        <VStack spacing="8" align="start">
          <FormControl id="hideOtherInterviewerNotesInExtension">
            <Checkbox
              {...register("hideOtherInterviewerNotesInExtension")}
              defaultChecked={hideOtherInterviewerNotesInExtension}
            >
              Prevent bias when filling out feedback
            </Checkbox>
            <FormHelperText>
              This hides other interviewers&apos; notes in the BrightHire
              sidepanel to prevent bias when inteviewers are filling out their
              scorecard or feedback form.
            </FormHelperText>
          </FormControl>
          <FormControl
            id="assignGuidesToInterviewersEnabled"
            hidden={!autoAssignGuidesEnabled}
          >
            <Checkbox
              {...register("assignGuidesToInterviewersEnabled")}
              defaultChecked={assignGuidesToInterviewersEnabled}
            >
              Assign guides to interviewers
            </Checkbox>
            <FormHelperText>
              This enables assigning guides to specific interviewers. If a
              position is also assigned, the interviewer will automatically see
              the guide for that position&apos;s interviews. If no position is
              assigned, the interviewer will need to choose that guide. If
              multiple guides are assigned to an interviewer, they will need to
              choose which guide to use.
            </FormHelperText>
          </FormControl>
          <FormControl id="audioOnlyViewing">
            <Checkbox
              {...register("audioOnlyViewing")}
              defaultChecked={audioOnlyViewing}
            >
              Disable video viewing
            </Checkbox>
            <FormHelperText>
              This disables video throughout the BrightHire application so that
              your users can only listen to the audio of the interview. This can
              help reduce bias.
            </FormHelperText>
          </FormControl>
          <FormControl id="allowManualScheduledInterviews">
            <Checkbox
              {...register("allowManualScheduledInterviews")}
              defaultChecked={allowManualScheduledInterviews}
            >
              Allow manually scheduled interviews
            </Checkbox>
            <FormHelperText>
              This reveals a &quot;Schedule Interview&quot; button on the
              homepage that allows any user to manually schedule interviews in
              BrightHire.{" "}
              <Link
                href="https://help.brighthire.ai/en/articles/6170599"
                target="_blank"
              >
                Learn more
              </Link>
            </FormHelperText>
          </FormControl>
          {inPersonEnabled && (
            <>
              <FormControl id="allowInPersonMeetings">
                <Checkbox
                  {...register("allowInPersonMeetings")}
                  defaultChecked={allowInPersonMeetings}
                >
                  Allow in-person interviews
                </Checkbox>
                <FormHelperText>
                  This enables users to create and launch any upcoming interview
                  as an in-person interview. In-person interviews use a computer
                  or mobile microphone to record the audio of an in-person
                  conversation.
                </FormHelperText>
              </FormControl>
              <FormControl id="playInPersonRecordingDisclaimer">
                <Checkbox
                  {...register("playInPersonRecordingDisclaimer")}
                  defaultChecked={playInPersonRecordingDisclaimer}
                  hidden={
                    allowInPersonMeetings === false &&
                    watch("allowInPersonMeetings") === false
                  }
                >
                  Call recording disclaimer - in-person interviews
                </Checkbox>
                <FormHelperText>
                  This plays an automated recording disclaimer to when an
                  in-person interview is started in BrightHire. This is
                  recommended.
                </FormHelperText>
              </FormControl>
            </>
          )}
          <FormControl id="textBeforeCallEnabled">
            <Checkbox
              {...register("textBeforeCallEnabled")}
              defaultChecked={textBeforeCallEnabled}
            >
              Allow text before call
            </Checkbox>
            <FormHelperText>
              This lets interviewers send a text to candidate before calling
              them so the candidate expects their call
            </FormHelperText>
          </FormControl>
          <FormControl id="externalShareDefaultDurationDays" maxW="400">
            <FormLabel fontSize="lg">Sharing settings</FormLabel>
            <FormLabel>
              Default time an external recipient can access an interview or
              clip:
            </FormLabel>
            <Controller
              control={control}
              name="externalShareDefaultDurationDays"
              defaultValue={externalShareDefaultDurationDays}
              render={({ field: { onChange, value, ref } }) => (
                <Select
                  name="externalShareDefaultDurationDays"
                  value={value}
                  ref={ref}
                  onChange={(v) => {
                    onChange(parseInt(v.target.value));
                  }}
                >
                  {EXTERNAL_SHARE_DURATION_VALUES.map((item) => (
                    <option key={item.name} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              )}
            />
          </FormControl>
          <Button type="submit" isLoading={loading} data-testid="save">
            Save
          </Button>
        </VStack>
      </form>
    </SettingsPageContainer>
  );
};

export default AdvancedConfigurations;
