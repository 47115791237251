import React from "react";

import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import NotFound from "../../pages/not-found/NotFoundPage";

type FeatureGateProps = {
  // Add active feature flags here as enum values to avoid typos
  flag: "plan-poc" | "assign-guides-interviewers:v1";
  children: JSX.Element;
};

const FeatureGate: React.FC<FeatureGateProps> = ({ flag, children }) => {
  const enabled = useFeatureFlag(flag);
  if (!enabled) {
    return <NotFound />;
  }
  return children;
};

export default FeatureGate;
