import { Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";

const SummaryUnavailable: React.FC<{ helperText: string }> = ({
  helperText,
}) => (
  <Flex direction="column" alignItems="center" pt="8">
    <Icon viewBox="0 0 241 179" w="241px" h="179" mt="7" fill="#FFFFFF">
      <path
        d="M10.3581 88.7169V90.7028"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M5.89777 89.7929L7.52052 91.4634"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4 94.2397H6.29455"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M5.71216 98.7658L7.33491 97.0953"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.3581 99.3083V97.3224"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M13.6679 97.9844L12.3439 96.6605"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M16.3157 94.6746H14.3298"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M14.9918 90.0409L13.6678 91.3648"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M116.934 2V3.32392"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M113 6H114.324"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M116.934 9.94355V8.61963"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M120.244 5.97177H118.92"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M40.1463 57.6048V59.5907"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M34.1887 63.5624H36.1745"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M40.1463 68.8581V66.8722"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M45.442 63.5624H43.4561"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M182.636 129.784C184.218 129.784 185.501 131.067 185.501 132.65C185.501 134.233 184.218 135.516 182.636 135.516C181.054 135.516 179.771 134.233 179.771 132.65"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M195.748 103.785C197.449 103.255 200.057 101.174 200.66 98.9358C201.208 100.915 203.502 103.255 205.573 103.432C203.242 104.257 201.014 106.708 200.66 108.634C200.42 106.671 197.338 104.071 195.748 103.785Z"
        fill="#EBECEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1301 128.901C21.9809 128.636 23.2841 127.596 23.5859 126.477C23.8596 127.466 25.0066 128.636 26.0418 128.725C24.8768 129.137 23.7629 130.363 23.5859 131.326C23.4656 130.344 21.9247 129.044 21.1301 128.901Z"
        fill="#EBECEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.9192 36.8173C36.9192 38.0449 35.9245 39.0393 34.6977 39.0393C33.4704 39.0393 32.4762 38.0449 32.4762 36.8173C32.4762 35.5901 33.4704 34.5952 34.6977 34.5952C35.9245 34.5952 36.9192 35.5901 36.9192 36.8173Z"
        fill="#D1D8DF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M236.692 32.7213C236.692 34.3486 235.374 35.6674 233.748 35.6674C232.121 35.6674 230.802 34.3486 230.802 32.7213C230.802 31.0939 232.121 29.7752 233.748 29.7752C235.374 29.7752 236.692 31.0939 236.692 32.7213Z"
        fill="#EBECEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.4369 170.315C78.4369 171.341 77.6058 172.173 76.5802 172.173C75.5551 172.173 74.7239 171.341 74.7239 170.315C74.7239 169.289 75.5551 168.458 76.5802 168.458C77.6058 168.458 78.4369 169.289 78.4369 170.315Z"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.3322 22.5418C77.3322 23.7695 76.3375 24.7639 75.1107 24.7639C73.8839 24.7639 72.8892 23.7695 72.8892 22.5418C72.8892 21.3147 73.8839 20.3198 75.1107 20.3198C76.3375 20.3198 77.3322 21.3147 77.3322 22.5418Z"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.729 159.322C208.309 159.863 206.721 159.149 206.181 157.73C205.64 156.31 206.353 154.72 207.773 154.181C209.192 153.64 210.781 154.353 211.321 155.773C211.862 157.193 211.148 158.782 209.729 159.322Z"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <rect
        x="47.2796"
        y="45.603"
        width="90"
        height="106"
        rx="4"
        transform="rotate(-17.6584 47.2796 45.603)"
        fill="#E5E7EA"
      />
      <path
        d="M111.878 35.8792L74.5179 47.7724C74.0332 47.9261 73.5073 47.8809 73.0554 47.6466C72.6035 47.4123 72.2626 47.0081 72.1075 46.5228L69.2573 37.5694C69.1032 37.0836 69.1478 36.5568 69.381 36.1044C69.6143 35.652 70.0173 35.311 70.5016 35.1562L107.861 23.2631C108.346 23.1094 108.872 23.1546 109.324 23.3889C109.776 23.6232 110.117 24.0273 110.272 24.5127L113.122 33.4661C113.276 33.9518 113.232 34.4787 112.998 34.9311C112.765 35.3835 112.362 35.7245 111.878 35.8792Z"
        fill="#C7CCD1"
      />
      <path
        d="M90.7118 24.9127C91.177 26.3739 90.3712 27.9291 88.9208 28.3908C87.4704 28.8525 85.9139 28.0493 85.4487 26.5881C84.9836 25.1269 85.7893 23.5717 87.2397 23.11C88.6901 22.6482 90.2466 23.4515 90.7118 24.9127Z"
        stroke="#C7CCD1"
        strokeWidth="3"
      />
      <mask
        id="mask0_2387_258"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="48"
        y="19"
        width="117"
        height="127"
      >
        <rect
          x="47.2796"
          y="45.603"
          width="90"
          height="106"
          rx="4"
          transform="rotate(-17.6584 47.2796 45.603)"
          fill="#F2F4F8"
        />
        <path
          d="M111.878 35.8792L74.5179 47.7724C74.0332 47.9261 73.5073 47.8809 73.0554 47.6466C72.6035 47.4123 72.2626 47.0081 72.1075 46.5228L69.2573 37.5694C69.1032 37.0836 69.1478 36.5568 69.381 36.1044C69.6143 35.652 70.0173 35.311 70.5016 35.1562L107.861 23.2631C108.346 23.1094 108.872 23.1546 109.324 23.3889C109.776 23.6232 110.117 24.0273 110.272 24.5127L113.122 33.4661C113.276 33.9518 113.232 34.4787 112.998 34.9311C112.765 35.3835 112.362 35.7245 111.878 35.8792Z"
          fill="#B8BEC5"
        />
        <path
          d="M90.2353 25.0643C90.6171 26.2634 89.9554 27.5367 88.7691 27.9144C87.5828 28.292 86.3069 27.6355 85.9252 26.4364C85.5435 25.2374 86.2051 23.9641 87.3914 23.5864C88.5777 23.2088 89.8536 23.8653 90.2353 25.0643Z"
          stroke="#B8BEC5"
          strokeWidth="4"
        />
      </mask>
      <g mask="url(#mask0_2387_258)">
        <g opacity="0.6">
          <rect x="90" y="43" width="90" height="106" rx="4" fill="#D3D8DE" />
          <path
            d="M154.504 53.3297H115.297C114.788 53.3291 114.301 53.1264 113.941 52.7661C113.582 52.4058 113.38 51.9173 113.379 51.4078V42.0116C113.38 41.502 113.582 41.0135 113.941 40.6532C114.301 40.2929 114.788 40.0902 115.297 40.0897H154.504C155.012 40.0902 155.5 40.2929 155.859 40.6532C156.219 41.0135 156.421 41.502 156.422 42.0116V51.4078C156.421 51.9173 156.219 52.4058 155.859 52.7661C155.5 53.1264 155.012 53.3291 154.504 53.3297Z"
            fill="#D3D8DE"
          />
          <path
            d="M137.662 36.4593C137.662 37.9928 136.422 39.2303 134.9 39.2303C133.378 39.2303 132.139 37.9928 132.139 36.4593C132.139 34.9259 133.378 33.6884 134.9 33.6884C136.422 33.6884 137.662 34.9259 137.662 36.4593Z"
            stroke="#D3D8DE"
            strokeWidth="3"
          />
        </g>
      </g>
      <rect x="95" y="48" width="90" height="106" rx="4" fill="#E8EAED" />
      <path
        d="M159.504 58.3297H120.297C119.788 58.3291 119.301 58.1264 118.941 57.7661C118.582 57.4058 118.38 56.9173 118.379 56.4078V47.0116C118.38 46.502 118.582 46.0135 118.941 45.6532C119.301 45.2929 119.788 45.0902 120.297 45.0897H159.504C160.012 45.0902 160.5 45.2929 160.859 45.6532C161.219 46.0135 161.421 46.502 161.422 47.0116V56.4078C161.421 56.9173 161.219 57.4058 160.859 57.7661C160.5 58.1264 160.012 58.3291 159.504 58.3297Z"
        fill="#C7CCD1"
      />
      <path
        d="M142.662 41.4593C142.662 42.9928 141.422 44.2303 139.9 44.2303C138.378 44.2303 137.139 42.9928 137.139 41.4593C137.139 39.9259 138.378 38.6884 139.9 38.6884C141.422 38.6884 142.662 39.9259 142.662 41.4593Z"
        stroke="#C7CCD1"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M161.909 12.8647C162.676 12.9835 163.17 13.6748 163.013 14.4088L160.999 23.8315C160.842 24.5655 160.094 25.0643 159.328 24.9455C158.562 24.8267 158.068 24.1355 158.225 23.4015L160.238 13.9787C160.395 13.2447 161.143 12.746 161.909 12.8647Z"
        fill="#E5E7EA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M168.419 39.6866C168.579 38.8406 169.297 38.2678 170.024 38.4071L179.349 40.1957C180.075 40.335 180.535 41.1337 180.375 41.9796C180.215 42.8255 179.497 43.3984 178.77 43.2591L169.445 41.4705C168.719 41.3312 168.26 40.5325 168.419 39.6866Z"
        fill="#E5E7EA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.569 16.0688C144.199 15.6203 145.027 15.7668 145.418 16.396L150.428 24.4435C150.82 25.0727 150.626 25.9463 149.996 26.3948C149.366 26.8432 148.538 26.6968 148.147 26.0676L143.137 18.0201C142.745 17.3909 142.939 16.5173 143.569 16.0688Z"
        fill="#E5E7EA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M177.229 23.3477C177.621 23.9769 177.428 24.8505 176.798 25.299L168.74 31.0354C168.11 31.4839 167.282 31.3374 166.89 30.7082C166.499 30.079 166.692 29.2054 167.322 28.7569L175.38 23.0205C176.01 22.5721 176.838 22.7185 177.229 23.3477Z"
        fill="#E5E7EA"
      />
    </Icon>
    <Text
      mt="5"
      mb="3"
      fontSize="lg"
      fontWeight="semibold"
      color="gray.700"
      lineHeight="6"
    >
      AI Summary unavailable
    </Text>
    <Text fontSize="md" color="gray.600" lineHeight="5">
      {helperText}
    </Text>
  </Flex>
);

export default SummaryUnavailable;
