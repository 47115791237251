/* eslint-disable max-classes-per-file */
const BREAKPOINT_SM = "480px";
const BREAKPOINT_MD = "768px";
const BREAKPOINT_LG = "1024px";
const BREAKPOINT_XL = "1200px";
const BREAKPOINT_2XL = "1500px";

/**
 * Actual values for breakpoints. Chakra uses "base" for any size that isn't
 * handled by an otherwise named breakpoint.
 */
export const breakpoints: BreakpointStyles = {
  sm: BREAKPOINT_SM,
  md: BREAKPOINT_MD,
  lg: BREAKPOINT_LG,
  xl: BREAKPOINT_XL,
  "2xl": BREAKPOINT_2XL,
};

/**
 * A list of Chakra-compatible text representations of breakpoints that are
 * considered "mobile" views whether or not they're actually on a mobile device
 */
export const mobileBreakpoints = ["base", "sm", "md"];

/**
 * Portrait vs landscape
 */
type MediaQueryMode = "portrait" | "landscape";

/**
 * Typing for Chakra breakpoints
 */
export type Breakpoint = "base" | "sm" | "md" | "lg" | "xl" | "2xl";

export type BreakpointStyles = {
  base?: string;
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
  "2xl"?: string;
  [key: string]: string | undefined;
};

type IndexableProperty = {
  [key: string]: any;
};

/**
 * For more fine-grained control over styles on mobile devices. For example, you
 * could target devices in portrait mode only.
 *
 * @example
 * // instead of applying one font size for all small and all large screens...
 * fontSize={{ sm: "10pt", lg: "12pt" }}
 * @example
 * // you could apply one size for all small portrait & another for all large
 * // portrait screens
 * sx={BreakpointMediaQuery.use({ sm: "10pt", lg: "12pt" }, "fontSize", "portrait") }
 */
export class BreakpointMediaQuery {
  [key: string]: any;

  constructor(
    styles: string | BreakpointStyles,
    property: string,
    mode: MediaQueryMode = "portrait"
  ) {
    if (typeof styles === "string") {
      const prop: IndexableProperty = {};
      prop[property] = styles;
      this[`@media screen and (orientation: ${mode})`] = prop;
    } else if (styles && Object.keys(styles).length) {
      Object.entries(styles).forEach((entry) => {
        const key = entry[0];
        const val = entry[1];
        const breakpoint = breakpoints[key] ? breakpoints[key] : null;
        const prop: IndexableProperty = {};
        prop[property] = val;
        if (key === "base") {
          this[`@media screen and (orientation: ${mode})`] = prop;
        } else if (breakpoint) {
          this[
            `@media screen and (max-width: ${breakpoint}) and (orientation: ${mode})`
          ] = prop;
        }
      });
    }
  }

  static use(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    styles: string | BreakpointStyles,
    property: string,
    mode?: MediaQueryMode
  ): BreakpointMediaQuery {
    return new BreakpointMediaQuery(styles, property, mode);
  }
}
