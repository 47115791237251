import { Text } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";

import {
  SelectionDragHandleEnd,
  SelectionDragHandleStart,
} from "./SelectionDragHandles";
import { WordState } from "./types";

type ClipWordProps = {
  word: string;
  wordStartTime: number;
  wordEndTime: number;
  seekAndPlay: (time: number) => void;
  scrollTo: (y: number) => void;
  state: WordState;
  onMouseOver: (start: number, end: number) => void;
  onSelectionEdgeDragStart: (position: "start" | "end") => void;
  selectionFirstWord: boolean;
  selectionLastWord: boolean;
  dragging: boolean;
  activeBrowserSelection?: boolean;
};

const scrollOffsetFromTopOfTranscript = 100;

const colorByWordState = {
  "active-word": "white",
  "in-clip": undefined,
  "out-of-clip": "gray.500",
};

const bgByWordState = {
  "active-word": "blue.400",
  "in-clip": "yellow.200",
  "out-of-clip": undefined,
};

const ClipWord: React.FC<ClipWordProps> = ({
  word,
  wordStartTime,
  wordEndTime,
  seekAndPlay,
  scrollTo,
  state,
  onMouseOver,
  onSelectionEdgeDragStart,
  dragging,
  selectionFirstWord,
  selectionLastWord,
  activeBrowserSelection,
}) => {
  const [el, setEl] = useState<HTMLDivElement | null>(null);
  const onWordClick = useCallback(() => {
    seekAndPlay(wordStartTime);
  }, [seekAndPlay, wordStartTime]);

  // Scroll to word when it transitions to being the active word
  useEffect(() => {
    if (state === "active-word" && el && !dragging) {
      // Do not scroll while selecting transcript, it jerkily moves the viewport.
      if (activeBrowserSelection) {
        return;
      }

      // Parent should be ClipSegment, which is position: relative,
      // so we need the words offset from the segment, and the segments
      // offset from the entire transcript.
      const parentOffsetTop = el.parentElement?.offsetTop || 0;
      scrollTo(
        el.offsetTop + parentOffsetTop - scrollOffsetFromTopOfTranscript
      );
    }
  }, [state === "active-word", el]);

  const commonLayoutProps = {
    display: "inline",
    py: "3px",
  };

  const selectionProps = {
    backgroundColor: bgByWordState[state],
    color: colorByWordState[state],
  };

  const onMouseOverCallback = useCallback(() => {
    if (dragging) {
      onMouseOver(wordStartTime, wordEndTime);
    }
  }, [dragging, wordStartTime, wordEndTime]);

  return (
    <>
      <Text
        ref={(node) => setEl(node)}
        bg={bgByWordState[state]}
        color={colorByWordState[state]}
        onClick={onWordClick}
        onMouseOver={onMouseOverCallback}
        cursor="text"
        data-starttime={wordStartTime}
        data-endtime={wordEndTime}
        _selection={{
          ...selectionProps,
        }}
        {...commonLayoutProps}
      >
        {`${word} `}
      </Text>
      {selectionFirstWord && el && (
        <SelectionDragHandleStart
          onDragStart={() => onSelectionEdgeDragStart("start")}
          offsetLeft={el.offsetLeft}
          offsetTop={el.offsetTop}
          activeBrowserSelection={activeBrowserSelection}
        />
      )}
      {selectionLastWord && el && (
        <SelectionDragHandleEnd
          onDragStart={() => onSelectionEdgeDragStart("end")}
          offsetLeft={el.offsetWidth + el.offsetLeft}
          offsetTop={el.offsetTop}
          activeBrowserSelection={activeBrowserSelection}
        />
      )}
    </>
  );
};

export default React.memo(ClipWord);
