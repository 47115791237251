import {
  Box,
  ButtonGroup,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import { IoEllipsisHorizontalSharp } from "react-icons/io5";

type MediaActionType = {
  icon: any;
  handler: () => void;
  label: string;
};

export type MediaActionsType = {
  [key: string]: MediaActionType[];
};

export const MediaActions: React.FC<{
  actions: MediaActionsType;
  size?: string;
  offset?: string;
}> = ({
  actions: { primary = [], more = [] },
  size = "xs",
  offset = "8px",
}) => {
  if (primary.length + more.length === 0) return null;

  let iconSize = "16px";
  switch (size) {
    case "sm":
      iconSize = "20px";
      break;
  }

  return (
    <ButtonGroup
      position="absolute"
      top={offset}
      left={offset}
      zIndex={1}
      variant="translucentDark"
      size={size}
    >
      {primary.length > 0 &&
        primary.map((action) => (
          <Tooltip key={action.label} label={action.label}>
            <IconButton
              borderRadius="full"
              aria-label={action.label}
              icon={<action.icon size={iconSize} />}
              onClick={(e) => {
                e.stopPropagation();
                action.handler();
              }}
            />
          </Tooltip>
        ))}
      {more.length > 0 && (
        <Box display="inline-flex" data-testid="media-actions-more-menu">
          <Menu>
            <MenuButton
              as={IconButton}
              borderRadius="full"
              icon={<IoEllipsisHorizontalSharp size={iconSize} />}
            />
            <MenuList>
              {more.map((action) => (
                <MenuItem
                  key={action.label}
                  icon={<action.icon />}
                  onClick={action.handler}
                >
                  {action.label}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Box>
      )}
    </ButtonGroup>
  );
};

export default MediaActions;
