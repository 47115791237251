import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const DragHandleDots = (props: IconProps): JSX.Element => (
  <Icon {...props} viewBox="0 0 20 20">
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.60352 16.25C6.91316 16.25 6.35352 15.6904 6.35352 15C6.35352 14.3096 6.91316 13.75 7.60352 13.75C8.29387 13.75 8.85352 14.3096 8.85352 15C8.85352 15.6904 8.29387 16.25 7.60352 16.25ZM7.60352 11.25C6.91316 11.25 6.35352 10.6904 6.35352 10C6.35352 9.30964 6.91316 8.75 7.60352 8.75C8.29387 8.75 8.85352 9.30964 8.85352 10C8.85352 10.6904 8.29387 11.25 7.60352 11.25ZM7.60352 6.25C6.91316 6.25 6.35352 5.69036 6.35352 5C6.35352 4.30964 6.91316 3.75 7.60352 3.75C8.29387 3.75 8.85352 4.30964 8.85352 5C8.85352 5.69036 8.29387 6.25 7.60352 6.25Z"
        fill="#B8BEC5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3945 16.25C11.7042 16.25 11.1445 15.6904 11.1445 15C11.1445 14.3096 11.7042 13.75 12.3945 13.75C13.0849 13.75 13.6445 14.3096 13.6445 15C13.6445 15.6904 13.0849 16.25 12.3945 16.25ZM12.3945 11.25C11.7042 11.25 11.1445 10.6904 11.1445 10C11.1445 9.30964 11.7042 8.75 12.3945 8.75C13.0849 8.75 13.6445 9.30964 13.6445 10C13.6445 10.6904 13.0849 11.25 12.3945 11.25ZM12.3945 6.25C11.7042 6.25 11.1445 5.69036 11.1445 5C11.1445 4.30964 11.7042 3.75 12.3945 3.75C13.0849 3.75 13.6445 4.30964 13.6445 5C13.6445 5.69036 13.0849 6.25 12.3945 6.25Z"
        fill="#B8BEC5"
      />
    </svg>
  </Icon>
);

export default DragHandleDots;
