import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Checkbox,
  Flex,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { HiOutlineTrash } from "react-icons/hi";

import { useToast } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { Call } from "../../graphql";
import useRedactCall from "../../graphql/hooks/useRedactCall";

interface DeleteInterviewButtonProps {
  call: Pick<Call, "id" | "name" | "canDelete">;
  buttonStyle?: "button" | "menuItem";
}

const DeleteInterviewButton: React.FC<DeleteInterviewButtonProps> = ({
  call,
  buttonStyle = "button",
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const sendGAEvent = useSendGAEvent();
  const toast = useToast();
  const onError = (): void => {
    toast({
      title: "This interview could not be deleted. Please try again later.",
      status: "error",
    });
  };
  const [warningAcknowledged, setWarningAcknowledged] = React.useState(false);

  const cancelRef = React.useRef<HTMLButtonElement | null>(null);
  const [deleteCall, { loading }] = useRedactCall({
    onCompleted: (data) => {
      if (data.redactCall?.success) {
        onClose();
      }
    },
    onError: () => {
      onClose();
      onError?.();
    },
  });
  const handleDelete = (): void => {
    sendGAEvent("delete", "call_review");
    deleteCall({ variables: { id: call.id } });
  };

  const openModal = (): void => {
    if (call.canDelete) {
      onOpen();
    }
  };

  return (
    <>
      {buttonStyle === "menuItem" ? (
        <Flex flexFlow="row nowrap" flex="1" onClick={openModal}>
          <Flex
            flexFlow="column nowrap"
            color={call.canDelete ? "red.600" : "gray.700"}
            flex="1"
          >
            Delete
            {!call.canDelete && (
              <div
                style={{
                  width: "100%",
                  fontSize: "12px",
                }}
              >
                Please contact your site administrator to request deletion of
                this interview.
              </div>
            )}
          </Flex>
        </Flex>
      ) : (
        <Tooltip label="Delete interview">
          <IconButton
            variant="icon"
            aria-label="Delete interview"
            icon={<HiOutlineTrash size={20} />}
            onClick={openModal}
          />
        </Tooltip>
      )}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => {
          setWarningAcknowledged(false);
          onClose();
        }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Are you sure you want to delete this interview?
            </AlertDialogHeader>
            <AlertDialogBody>
              <Text>
                Deleting
                {call.name ? (
                  <strong>{` ${call.name} `}</strong>
                ) : (
                  " this interview "
                )}
                will also permanently delete the recording and associated
                interview data from BrightHire. This will affect trainings that
                use this recording. This cannot be undone.
              </Text>
              <Checkbox
                mt={4}
                isChecked={warningAcknowledged}
                onChange={(e) => setWarningAcknowledged(e.target.checked)}
                data-testid="call-delete-confirm-checkbox"
              >
                I understand and would like to delete this interview.
              </Checkbox>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                variant="outline"
                size="sm"
                ref={cancelRef}
                onClick={() => {
                  setWarningAcknowledged(false);
                  onClose();
                }}
                isDisabled={loading}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                size="sm"
                data-testid="call-delete-confirm-button"
                isLoading={loading}
                onClick={handleDelete}
                isDisabled={!warningAcknowledged}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default DeleteInterviewButton;
