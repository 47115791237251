import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import { HiOutlineSearch } from "react-icons/hi";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import { useLocation } from "react-router-dom";

import { useIsSmallScreen } from "../../hooks/useIsSmallScreen";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import SearchBox from "../Search/SearchBox";

/**
 * Controls for showing and hiding spotlight search
 */
export type SpotlightSearchState = {
  isOpen: boolean;
  onOpen(): void;
  onClose(): void;
};

type SpotlightSearchProps = {
  /** UI for displaying search results */
  results: React.ReactNode;
  /** Whether to display search results */
  showResults?: boolean;
  /** The search input's change event */
  onChange?(query: string): void;
  /** The search input's onEnter event */
  onEnter?(): void;
  /** Provides a search URL that matches the search input's current value */
  onSearchUrl?(url: string): void;
  /** Fired upon clicking the search options icon */
  onGoToSearch?(): void;
  onOpen?(): void;
  onClose?(): void;
  children(searchState: SpotlightSearchState): React.ReactNode;
};

/**
 * Adds spotlight search modal to DOM and renders children with
 * access to spotlight search state
 *
 * Since this wraps /Search components, this component must
 * be a descendant of an `<InstantSearch />` component
 */
export const SpotlightSearchBase: React.FC<SpotlightSearchProps> = ({
  results,
  showResults = true,
  onChange,
  onEnter,
  onSearchUrl,
  onGoToSearch,
  onOpen: onOpenProp,
  onClose: onCloseProp,
  children,
}) => {
  const isSmallScreen = useIsSmallScreen();
  const { layoutHeight } = useWindowDimensions();

  const { isOpen, onOpen, onClose } = useDisclosure({
    onOpen: () => {
      window.Intercom?.("hide");
      onOpenProp?.();
    },
    onClose: onCloseProp,
  });

  const searchState: SpotlightSearchState = useMemo(
    () => ({ isOpen, onOpen, onClose }),
    [isOpen, onOpen, onClose]
  );

  const location = useLocation();
  useEffect(onClose, [location]);

  const queryToSearchUrl = (query: string): string => {
    let searchUrl = "/search";
    if (query) searchUrl += `?q=${encodeURIComponent(query)}`;
    return searchUrl;
  };

  const rightElement = showResults ? (
    <Button size="sm" variant="link" onClick={onGoToSearch}>
      More search options
    </Button>
  ) : (
    <Tooltip label="More search options" openDelay={500} placement="right">
      <Flex>
        <Icon
          as={HiOutlineAdjustmentsHorizontal}
          boxSize="6"
          color="blue.600"
          onClick={onGoToSearch}
        />
      </Flex>
    </Tooltip>
  );

  return (
    <>
      {children(searchState)}

      <Modal {...searchState}>
        {!isSmallScreen && <ModalOverlay />}
        <ModalContent
          borderRadius={{ base: "0", lg: "lg" }}
          borderWidth={{ base: "0", lg: "1px" }}
          borderColor="gray.200"
          maxW={{ base: "unset", lg: "600px" }}
          w={isSmallScreen ? "100vw" : undefined}
          maxH={isSmallScreen ? "unset" : "80vh"}
          h={isSmallScreen ? layoutHeight : undefined}
          mt={isSmallScreen ? 12 : undefined}
          mb={isSmallScreen ? 0 : undefined}
        >
          <ModalHeader
            px="4"
            py="3.5"
            borderBottomWidth={showResults || isSmallScreen ? "1px" : "0"}
            borderColor="gray.200"
          >
            <SearchBox
              autoFocus
              placeholder="Search"
              onChange={(e) => {
                const query = e.target.value;
                onChange?.(query);
                onSearchUrl?.(queryToSearchUrl(query));
              }}
              onEnter={onEnter}
              leftElement={
                <Icon as={HiOutlineSearch} color="gray.500" boxSize="6" />
              }
              leftElementProps={{ width: "unset", pointerEvents: "none" }}
              rightElement={rightElement}
              rightElementProps={{
                cursor: "pointer",
                width: showResults ? "auto" : undefined,
              }}
              pl="8"
              pr="10"
              type="text"
              variant="unstyled"
              h="7"
              fontSize="xl"
              lineHeight="7"
            />
          </ModalHeader>

          <ModalBody
            px="4"
            pt="3"
            pb="4"
            overflowY="auto"
            display={showResults ? undefined : "none"}
          >
            {results}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
