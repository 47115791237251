import {
  Box,
  BoxProps,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { HiOutlineSearch, HiOutlineX } from "react-icons/hi";

import { LoadingIndicator } from "../../../components";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import { CallHighlight } from "./types";

export type FilterOption = {
  id: string;
  displayName: string;
  active: boolean;
  count: number;
};

export interface HighlightsFiltersProps {
  /**
   * List of distinct `call.name`s for this candidate's calls
   */
  interviewFilterOptions: FilterOption[];
  /**
   * Toggles an element from `interviews` as being an active filter
   */
  toggleFilterInterview(i: FilterOption): void;
  /**
   * List of distinct `interviewer.fullName`s for this candidate's calls
   */
  interviewerFilterOptions: FilterOption[];
  /**
   * Toggles an element from `interviewers` as being an active filter
   */
  toggleFilterInterviewer(i: FilterOption): void;
  /**
   * List of highlight types for this candidate's highlights
   */
  typeFilterOptions: FilterOption[];
  /**
   * Toggles a type from `typeFilterOptions` as being an active filter
   */
  toggleFilterHighlightType(i: FilterOption): void;
  /**
   * The list of highlights that match the given filters
   */
  matchingHighlights: CallHighlight[];
  /**
   * Whether any filters are currently active
   */
  hasActiveFilters(): boolean;
  /**
   * Clear all active filters
   */
  clearFilters(): void;
  /**
   * Update the search query so we can filter down the list of highlights
   */
  updateSearchQuery(query: string): void;
  /**
   * The current search query (as stored in the useHighlightFilters hook)
   */
  searchQuery: string;
  /**
   * List of smart filter options
   */
  smartFilterOptions: FilterOption[];
  /**
   * Toggles an element from `smartfilters` as being an active filter
   */
  toggleFilterSmartFilters(i: FilterOption): void;
  /**
   * Indicates whether smart filter options are currently being generated by the backend
   */
  smartFiltersGenerating?: boolean;
}

const HighlightsFilters: React.FC<HighlightsFiltersProps & BoxProps> = ({
  interviewerFilterOptions,
  interviewFilterOptions,
  typeFilterOptions,
  smartFilterOptions,
  toggleFilterInterview,
  toggleFilterInterviewer,
  toggleFilterHighlightType,
  toggleFilterSmartFilters,
  matchingHighlights,
  hasActiveFilters,
  clearFilters,
  searchQuery,
  updateSearchQuery,
  smartFiltersGenerating,
  ...styles
}) => {
  const smartFiltersEnabled = useFeatureFlag("ai_note_tags:show");
  const filters: {
    title: string;
    options: FilterOption[];
    onChange(i: FilterOption): void;
    loading?: boolean;
  }[] = [
    {
      title: "Highlight type",
      options: typeFilterOptions,
      onChange: toggleFilterHighlightType,
    },
    {
      title: "Interview titles",
      options: interviewFilterOptions,
      onChange: toggleFilterInterview,
    },
    {
      title: "Interviewers",
      options: interviewerFilterOptions,
      onChange: toggleFilterInterviewer,
    },
  ];

  // add groups if smart filters are enabled
  if (smartFiltersEnabled) {
    filters.unshift({
      title: "Interview topic",
      options: smartFilterOptions,
      onChange: toggleFilterSmartFilters,
      loading: smartFiltersGenerating,
    });
  }

  return (
    <Box {...styles}>
      <Box borderBottom="1px solid" borderBottomColor="gray.100" mb="8" pb="8">
        <Flex alignItems="center" justifyContent="space-between" mb="6">
          <Text color="gray.500" fontSize="xs" fontWeight="700">
            Filters
          </Text>
          {hasActiveFilters() && (
            <Button
              onClick={clearFilters}
              size="xs"
              variant="link"
              fontWeight="500"
            >
              Clear all
            </Button>
          )}
        </Flex>
        <InputGroup>
          <InputLeftElement pointerEvents="none" color="gray.500">
            <HiOutlineSearch size="16px" />
          </InputLeftElement>
          <Input
            placeholder="Title, interviewer, keyword"
            borderColor="gray.200"
            borderRadius="2px"
            borderWidth="1px"
            fontSize="sm"
            color="gray.600"
            value={searchQuery}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateSearchQuery(event.currentTarget.value);
            }}
            data-tour-id="candidate-page-highlight-search"
          />

          <InputRightElement
            color="gray.500"
            visibility={searchQuery ? "visible" : "hidden"}
          >
            <Icon
              as={HiOutlineX}
              boxSize="4"
              onClick={() => updateSearchQuery("")}
            />
          </InputRightElement>
        </InputGroup>
      </Box>
      {filters.map(({ title, options, onChange, loading }, i) => (
        <React.Fragment key={title}>
          <Box
            mt={i === 0 ? undefined : "4"}
            mb="2"
            fontSize="sm"
            color="gray.600"
          >
            {title}
          </Box>
          <CheckboxGroup size="mdLg">
            <VStack align="flex-start" spacing={0}>
              {/* TODO: Make collapsible with Accordion */}
              {loading && <LoadingIndicator />}
              {!loading && options.length === 0 && (
                <Text fontSize="xs" color="gray.500" fontStyle="italic">
                  No options available
                </Text>
              )}
              {options.map((option) => (
                <Checkbox
                  variant="gray"
                  iconSize="0.6rem"
                  key={option.id}
                  isChecked={option.active}
                  disabled={!option.count}
                  onChange={() => onChange(option)}
                >
                  <>
                    {option.displayName} ({option.count})
                  </>
                </Checkbox>
              ))}
            </VStack>
          </CheckboxGroup>
        </React.Fragment>
      ))}
    </Box>
  );
};

export default HighlightsFilters;
