import * as Sentry from "@sentry/browser";
import React, { useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

import config from "../../../config";
import useBrowserExtensionTracking from "../../../hooks/useBrowserExtensionTracking";
import { checkCookieExistence } from "../../../utils/cookie";
import { identifyGoogleAnalytics } from "../../../utils/googleAnalytics";
import { useIntercom } from "../../../utils/intercom";
import { identifyLogrocket, initLogrocket } from "../../../utils/logrocket";
import { useSentry } from "../../../utils/sentry";
import { useCurrentUserQuery } from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import useUpdateCurrentUser from "../../graphql/hooks/useUpdateCurrentUser";
import { CurrentUserContext } from "../../hooks/useCurrentUser";

const isRegistrationRoute = (route: string): boolean => {
  if (route.endsWith("/register-phone")) return true;
  if (route.endsWith("/select-persona")) return true;
  if (route.endsWith("/register-info")) return true;
  if (route.endsWith("/sync-calendar")) return true;
  return false;
};

const isSettingsRoute = (route: string): boolean => {
  return route.indexOf("/settings") === 0 || route.indexOf("/users") === 0;
};

const getRedirectParams = (): URLSearchParams => {
  const params = new URLSearchParams(window.location.search);
  if (!params.get("from")) {
    const pathname = window.location.pathname.replace(
      /^(\/interview-assistant)/,
      ""
    );
    params.set("from", pathname + window.location.search);
  }
  return params;
};

export interface RequireAuthProps {
  children: React.ReactNode;
  hideIntercom?: boolean;
  useLogRocket?: boolean;
  shouldRefresh?: boolean;
  onSignInRedirect?: (path: string, params: URLSearchParams) => void;
}

// A wrapper that redirects to the login screen if you're not yet authenticated.
// Children should access currentUser with useCurrentUser().
export const RequireAuth: React.FC<RequireAuthProps> = ({
  hideIntercom = false,
  useLogRocket = true,
  shouldRefresh = true,
  onSignInRedirect,
  children,
}) => {
  const [searchParams] = useSearchParams();
  const { data, loading } = useCurrentUserQuery({
    pollInterval: 10 * 60 * 1000, // 10m
  });
  const currentUser = data?.currentUser;
  const planPocEnabled = useFeatureFlag("plan-poc");
  useBrowserExtensionTracking(useUpdateCurrentUser, currentUser);
  useIntercom(hideIntercom, currentUser);
  const isImpersonating = checkCookieExistence("is_impersonating");
  useEffect(() => {
    if (!data?.currentUser || isImpersonating) return;
    if (useLogRocket) {
      initLogrocket();
      identifyLogrocket(data.currentUser);
    }
    identifyGoogleAnalytics(data.currentUser);
  }, [data, useLogRocket, isImpersonating]);

  useSentry(useLogRocket, isImpersonating, currentUser?.id, currentUser?.email);

  let serverWebpackManifestHash: string | undefined | null;
  let shouldReloadOnWebpackManifestHashMismatch: string | undefined | null;
  try {
    serverWebpackManifestHash = localStorage.getItem(
      "server-webpack-manifest-hash"
    );
    shouldReloadOnWebpackManifestHashMismatch = localStorage.getItem(
      "should-reload-on-webpack-manifest-hash-mismatch"
    );
  } catch (e) {
    Sentry.captureException(e);
  }
  useEffect(() => {
    if (window.location.toString().includes("interview-assistant")) return;
    if (!shouldReloadOnWebpackManifestHashMismatch) return;
    if (!serverWebpackManifestHash) return;
    if (!config.webpackManifestHash) return;
    if (config.webpackManifestHash === "") return;
    if (serverWebpackManifestHash !== config.webpackManifestHash) {
      window.location.reload();
    }
  }, [
    serverWebpackManifestHash,
    shouldReloadOnWebpackManifestHashMismatch,
    config.webpackManifestHash,
    window.location,
  ]);

  if (loading) {
    return null;
  }

  if (!currentUser) {
    const redirectParams = getRedirectParams();
    const signInPath = "/sign-in";
    onSignInRedirect?.(signInPath, redirectParams);
    return <Navigate to={`${signInPath}?${redirectParams.toString()}`} />;
  }

  const registerInfoPath = "/register-info";
  if (
    currentUser.signUpState === "BASIC_INFO" &&
    !window.location.pathname.endsWith(registerInfoPath)
  ) {
    const redirectParams = getRedirectParams();
    return <Navigate to={`${registerInfoPath}?${redirectParams.toString()}`} />;
  }

  const selectPersonaPath = "/select-persona";
  if (
    currentUser.signUpState === "SELECT_PERSONA" &&
    !window.location.pathname.endsWith(selectPersonaPath)
  ) {
    const redirectParams = getRedirectParams();
    return (
      <Navigate to={`${selectPersonaPath}?${redirectParams.toString()}`} />
    );
  }

  const syncCalendarPath = "/sync-calendar";
  if (
    currentUser.signUpState === "SYNC_CALENDAR" &&
    !window.location.pathname.endsWith(syncCalendarPath)
  ) {
    const redirectParams = getRedirectParams();
    return <Navigate to={`${syncCalendarPath}?${redirectParams.toString()}`} />;
  }

  const registerPhonePath = "/register-phone";
  if (
    currentUser.signUpState === "PHONE_NUMBER" &&
    !window.location.pathname.endsWith(registerPhonePath)
  ) {
    const redirectParams = getRedirectParams();
    return (
      <Navigate to={`${registerPhonePath}?${redirectParams.toString()}`} />
    );
  }

  if (
    currentUser.signUpState === "COMPLETED" &&
    isRegistrationRoute(window.location.pathname)
  ) {
    const redirectTo = searchParams.get("from") ?? "/";
    return <Navigate to={redirectTo} />;
  }

  if (
    !currentUser.organization.interviewEnabled &&
    !isRegistrationRoute(window.location.pathname) &&
    !isSettingsRoute(window.location.pathname)
  ) {
    if (
      planPocEnabled &&
      currentUser.organization.planEnabled &&
      window.location.pathname === "/"
    ) {
      // user logs in and is on the home page, redirect to Plan
      window.location.href = "/plan";
      return null;
    }

    if (window.location.pathname !== "/upgrade") {
      // user navigates to a page that requires Plan, redirect to upgrade
      return <Navigate to="/upgrade" replace />;
    }
  }

  return (
    <CurrentUserContext.Provider value={currentUser}>
      {children}
    </CurrentUserContext.Provider>
  );
};
