import { ApolloCache, StoreObject } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import invariant from "invariant";
import { useState } from "react";

import { errorToast, successToast, useToast } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import {
  Clip,
  UpdateClipMutation,
  useClipShareLazyQuery,
  useRemoveClipShareMutation,
  useRemoveExternalClipShareMutation,
  useRenewExternalClipShareMutation,
  useShareClipExternallyMutation,
  useShareClipMutation,
  useUpdateClipMutation,
} from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";
import { clipLengthLabel } from "../Interview/Clip/utils";
import { Share, ShareModalProps } from "./types";

type UseClipShareModalParams = {
  clip: null | Pick<
    Clip,
    | "id"
    | "name"
    | "canEdit"
    | "visibility"
    | "startTime"
    | "endTime"
    | "visibleVisibilityLevels"
  >;
  onClose(): void;
  onUpdateClip?(
    clip: NonNullable<UpdateClipMutation["updateClip"]>["clip"]
  ): void;
};

type UseClipShareModal = Omit<
  ShareModalProps,
  "callId" | "clipId" | "onClose" | "positionId"
> & {
  getClipShareData(): Promise<any>;
  hasSelection: boolean;
  loading: boolean;
};

/**
 * Like useSharePlaylistModal() amd useCallShareModal(), this hook sets
 * up all of the queries and mutations required to run the share modal for
 * a clip and returns them as props that can be passed to the share modal
 */
export function useClipShareModal({
  clip,
  onClose,
  onUpdateClip,
}: UseClipShareModalParams): UseClipShareModal {
  const [hasSelection, setHasSelection] = useState(false);
  const toast = useToast();
  const currentUser = useCurrentUser();

  // query data
  const [getClipShareData, { data, loading }] = useClipShareLazyQuery({
    onError: (err) => {
      Sentry.captureException(err);
      onClose();
      errorToast(toast, `There was a problem sharing this clip`);
    },
  });

  const {
    trainingProgramCount,
    externalClipShares,
    canShare,
    canShareExternal,
    clipShares,
    shareableUsers,
    visibleTo,
  } = data?.clip ?? {
    canShare: false,
  };
  const sendGAEvent = useSendGAEvent();

  const internalShares: Share[] = clipShares || [];
  const externalShares: Share[] = externalClipShares || [];
  const sharedWithUnique = externalShares
    .concat(internalShares)
    .filter(
      (share, index, shares) =>
        shares.findIndex((s) => s.sharedTo?.id === share.sharedTo?.id) === index
    );

  const {
    id: clipId,
    name: clipName,
    canEdit,
    visibility,
    startTime,
    endTime,
    visibleVisibilityLevels: visibilityLevelsProp,
  } = clip ?? {};

  // If clip.visibility is not included in visibleVisibilityLevels for the current
  // user add it, this way it can be displayed on the read only selector
  const visibilityLevels =
    visibilityLevelsProp && visibility
      ? !visibilityLevelsProp.includes(visibility) && !canEdit
        ? visibilityLevelsProp.concat([visibility])
        : visibilityLevelsProp
      : [];

  const modalTitle = `Share ${
    clipName ||
    (startTime !== undefined && endTime !== undefined
      ? clipLengthLabel(startTime, endTime, false)
      : "")
  } Clip`;

  // visibility mutations
  const [updateClipVisibility] = useUpdateClipMutation({
    onError: (err) => {
      errorToast(toast, `Error updating clip privacy: ${err.message}`);
    },
    update(cache, { data }) {
      const clip = data?.updateClip?.clip;
      if (clip) {
        onUpdateClip?.(clip);
      }

      const call = data?.updateClip?.clip.call;
      if (call) {
        cache.modify({
          id: cache.identify(call),
          fields: {
            callClips: () => call.callClips,
          },
        });
      }
    },
    onCompleted: (data) => {
      if (data?.updateClip) {
        successToast(toast, "Clip visibility updated.");
      }
    },
  });

  // share mutations helpers
  const updateCache = <T extends StoreObject>(
    cache: ApolloCache<any>,
    clip: T | undefined,
    field: keyof T
  ): void => {
    if (clip) {
      cache.modify({
        id: cache.identify(clip),
        fields: {
          [field]: () => clip[field],
        },
      });
    }
  };

  // share mutations
  const [shareClip, { loading: shareMutationLoading }] = useShareClipMutation({
    update(cache, { data }) {
      const clip = data?.shareClip?.clip;
      updateCache(cache, clip, "clipShares");
    },
    onError: (err) => {
      errorToast(toast, `Failed to share clip: ${err.message}`);
    },
    onCompleted: (data) => {
      if (data?.shareClip) {
        successToast(toast, "Clip shared");
        onClose();
      }
    },
  });

  const [removeShare, { loading: removeShareLoading }] =
    useRemoveClipShareMutation({
      update(cache, { data }) {
        const clip = data?.removeClipShare?.clip;
        updateCache(cache, clip, "clipShares");
      },
      onError: (err) => {
        errorToast(toast, `Failed to remove share: ${err.message}`);
      },
      onCompleted: (data) => {
        if (data?.removeClipShare) {
          successToast(toast, "Removed share");
        }
      },
    });

  // external share mutations
  const [shareClipExternally, { loading: externalShareMutationLoading }] =
    useShareClipExternallyMutation({
      update(cache, { data }) {
        const clip = data?.shareClipExternally?.clip;
        updateCache(cache, clip, "externalClipShares");
      },
      onError(err) {
        errorToast(toast, `Failed to share clip externally: ${err.message}`);
      },
      onCompleted(data) {
        if (data.shareClipExternally) {
          successToast(toast, "Clip shared externally");
          onClose();
        }
      },
    });

  const [renewExternalShare] = useRenewExternalClipShareMutation({
    update(cache, { data }) {
      const clip = data?.renewExternalClipShare?.clip;
      updateCache(cache, clip, "externalClipShares");
    },
    onError(err) {
      errorToast(toast, `Failed to renew external share: ${err.message}`);
    },
    onCompleted(data) {
      if (data.renewExternalClipShare) {
        successToast(toast, "Successfully renewed external share");
      }
    },
  });

  const [removeExternalShare, { loading: removeExternalShareLoading }] =
    useRemoveExternalClipShareMutation({
      update(cache, { data }) {
        const clip = data?.removeExternalClipShare?.clip;
        updateCache(cache, clip, "externalClipShares");
      },
      onError: (err) => {
        errorToast(toast, `Failed to remove external share: ${err.message}`);
      },
      onCompleted: (data) => {
        if (data?.removeExternalClipShare) {
          successToast(toast, "Removed external share");
        }
      },
    });

  return {
    // We're disabling require-await because async is the easiest
    // way to guarantee a promise is returned
    // eslint-disable-next-line @typescript-eslint/require-await
    getClipShareData: async () =>
      clipId && getClipShareData({ variables: { clipId } }),
    hasSelection,
    loading,

    modalTitle,
    canShare,
    canShareExternal,
    externalShareDuration:
      currentUser.organization.externalShareDefaultDurationDays,
    trainingProgramCount,
    shareableUsers,
    sharedWith: sharedWithUnique,
    optionsLoading: loading,
    shareLoading: shareMutationLoading || externalShareMutationLoading,
    visibleTo,
    visibilityLevels,
    visibility,

    onChangeVisibility(visibility) {
      if (!clipId) return;
      updateClipVisibility({
        variables: { clipId, visibility },
      });
    },
    onSelection(selection) {
      setHasSelection(!!selection.length);
    },

    onShare(shareToUserIds, message) {
      if (!(clipId && shareToUserIds.length)) return;
      shareClip({
        variables: {
          clipId,
          shareToUserIds,
          message,
        },
      });
      sendGAEvent("clip_shared", "call_review");
    },
    removeShare({ id }) {
      if (!removeShareLoading) {
        removeShare({ variables: { id } });
      }
    },

    onShareExternal(shareToEmails, message) {
      invariant(shareToEmails.length > 0, "Must share to one or more emails");
      invariant(clipId, "Missing clip id");
      sendGAEvent("sharing_external", "call_review", "call_link");
      shareClipExternally({ variables: { clipId, shareToEmails, message } });
    },
    renewExternalShare({ id }) {
      renewExternalShare({ variables: { id } });
    },
    async removeExternalShare({ id }) {
      if (!removeExternalShareLoading) {
        await removeExternalShare({ variables: { id } });
      }
    },
  };
}
