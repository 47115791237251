import { Box, BoxProps, Tooltip } from "@chakra-ui/react";
import React from "react";

interface Pill {
  text: string;
  tooltip: string;
}

export const defaultPillProps = {
  minWidth: 12,
  paddingX: 2,
  paddingY: 1,
  fontSize: "12px",
  fontWeight: "medium" as const,
  textAlign: "center" as const,
  overflowX: "hidden" as const,
  borderRadius: "full" as const,
  borderColor: "border" as const,
  borderWidth: "1px",
  backgroundColor: "gray.100" as const,
  cursor: "default" as const,
};

interface PillProps extends BoxProps {
  text: string;
  tooltip: string;
}

const Pill = React.forwardRef<HTMLDivElement, PillProps>(
  ({ text, tooltip, children, ...rest }, ref) => (
    // use curser="pointer" so touch events fire click events on mobile
    <Tooltip label={tooltip} cursor="pointer">
      <Box ref={ref} {...defaultPillProps} {...rest}>
        {children ?? text}
      </Box>
    </Tooltip>
  )
);

export default Pill;
