import { ApolloError } from "@apollo/client";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Heading,
  Link,
} from "@chakra-ui/react";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";

import { Alert } from "../../../components";
import DisclaimerInputs, {
  DisclaimerFormData,
} from "../../../components/DisclaimerInputs/DisclaimerInputs";
import {
  CallType,
  Maybe,
  NotetakerRecorderDisclaimerType,
} from "../../graphql";

export interface FormData extends DisclaimerFormData {
  enabled: boolean;
  virtualOnsiteEnabled: boolean;
}

interface MsftTeamsSettingsFormProps {
  teamsNotetakerEnabled: boolean;
  teamsNotetakerDisclaimer?: Maybe<string>;
  teamsNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
  defaultDisclaimer: string;
  virtualOnsiteEnabled: boolean;
  showVirtualOnsiteSetting: boolean;
  error: ApolloError | undefined;
  isLoading: boolean;
  onSubmit: (formData: FormData) => void;
}

const MsftTeamsSettingsForm: React.FC<MsftTeamsSettingsFormProps> = ({
  teamsNotetakerEnabled,
  teamsNotetakerDisclaimer,
  teamsNotetakerDisclaimerType,
  defaultDisclaimer,
  virtualOnsiteEnabled,
  showVirtualOnsiteSetting,
  error,
  isLoading,
  onSubmit,
}) => {
  const formMethods = useForm<FormData>({
    defaultValues: {
      enabled: teamsNotetakerEnabled,
      disclaimer: teamsNotetakerDisclaimer ?? "",
      disclaimerType: teamsNotetakerDisclaimerType,
      defaultDisclaimer,
      virtualOnsiteEnabled,
    },
  });
  const { register, handleSubmit } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form
        autoComplete="on"
        onSubmit={handleSubmit((formData) => {
          onSubmit(formData);
        })}
      >
        {error?.graphQLErrors?.map(({ message }, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Alert mb={8} key={i} status="error" description={message} />
        ))}
        {/** Primary fields */}
        <FormControl id="teamsNotetakerEnabled" mb={8}>
          <Checkbox
            {...register("enabled")}
            defaultChecked={teamsNotetakerEnabled}
          >
            Enabled
          </Checkbox>
          <FormHelperText>
            This turns on the Microsoft Teams Notetaker for your organization.
          </FormHelperText>
        </FormControl>

        {/** Disclaimer fields */}
        <DisclaimerInputs callType={CallType.TeamsNotetaker} />

        <Box
          mb={6}
          hidden={!teamsNotetakerEnabled || !showVirtualOnsiteSetting}
        >
          <Divider mb={6} />
          <Heading as="h4" fontSize="md" mb={6}>
            Virtual Onsite
          </Heading>

          <FormControl id="virtualOnsiteEnabled">
            <Checkbox
              {...register("virtualOnsiteEnabled")}
              defaultChecked={virtualOnsiteEnabled}
              data-testid="virtual-onsite-enabled"
            >
              Enabled
            </Checkbox>
            <FormHelperText>
              Use interviewer attendance to automatically create separate
              interview recordings of sequential scheduled interviews that share
              a meeting meeting url. This will also provide interviewers more
              control over joining, starting, stopping the different sections of
              onsite via the Interview Assistant.
            </FormHelperText>
            <FormHelperText>
              <Link
                href="http://help.brighthire.ai/en/articles/6809892-virtual-onsites"
                target="_blank"
              >
                {" Learn More"}
              </Link>
            </FormHelperText>
          </FormControl>
        </Box>
        {/** Submit form */}
        <Divider mb={6} />
        <Button
          type="submit"
          mt={4}
          isLoading={isLoading}
          data-testid="teams-notetaker-settings-submit"
        >
          Save
        </Button>
      </form>
    </FormProvider>
  );
};

export default MsftTeamsSettingsForm;
