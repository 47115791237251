import {
  Box,
  ButtonProps,
  Flex,
  Grid,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { IconType } from "react-icons";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  HiArrowLeft,
  HiArrowRight,
  HiArrowUp,
  HiOutlineX,
} from "react-icons/hi";
import { IoCreateSharp } from "react-icons/io5";

import { RouterLink } from "../../../components";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { Thumbnail } from "../../components";
import EditClipModal from "../../components/Interview/Clip/EditClipModal";
import { Clip } from "../../graphql";

const ManagePlaylistMenuItem: React.FC<ButtonProps & { icon: IconType }> = ({
  icon,
  children,
  ...props
}) => (
  <MenuItem {...props}>
    <Icon as={icon} boxSize={5} mr={2} />
    {children}
  </MenuItem>
);

const EditTitle: React.FC<{
  clip: Pick<
    Clip,
    | "id"
    | "callId"
    | "duration"
    | "thumbnailImageUrl"
    | "displayName"
    | "canEdit"
    | "name"
    | "startTime"
    | "endTime"
  >;
}> = ({ clip }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  return (
    <Flex onClick={() => setIsEditModalOpen(clip.canEdit)}>
      <EditClipModal
        clip={clip}
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
        }}
        onSave={() => {
          setIsEditModalOpen(false);
        }}
      />
      Edit title
    </Flex>
  );
};

type ManagePlaylistItemsProps = {
  playlistId: string;
  clipCount: number;
  clips: Pick<
    Clip,
    | "id"
    | "callId"
    | "duration"
    | "thumbnailImageUrl"
    | "displayName"
    | "canEdit"
    | "name"
    | "startTime"
    | "endTime"
  >[];
  onReorderClips(clipIds: string[]): void;
  onRemoveClip(clipId: string): void;
};

const ManagePlaylistItems: React.FC<ManagePlaylistItemsProps> = ({
  playlistId,
  clipCount,
  clips,
  onReorderClips,
  onRemoveClip,
}) => {
  const { ref, numCols, columnGap, minCardWidth, maxCardWidth } =
    useClipListGridLayout({ clipCount });

  const moveClip = (idx: number, direction: "top" | "left" | "right"): void => {
    let newOrder: { id: string }[];

    if (direction === "top") {
      const before = clips.slice(0, idx);
      const [clip, ...after] = clips.slice(idx);
      newOrder = [clip, ...before, ...after];
    } else {
      const j = direction === "left" ? idx - 1 : idx + 1;
      newOrder = [...clips];
      [newOrder[idx], newOrder[j]] = [newOrder[j], newOrder[idx]];
    }

    const clipIds = newOrder.map(({ id }) => id);
    onReorderClips(clipIds);
  };

  return (
    <Grid
      ref={ref}
      rowGap="45"
      columnGap={`${columnGap}px`}
      templateColumns={`repeat(${numCols}, minmax(${minCardWidth}px, ${maxCardWidth}px))`}
      data-testid="manage-playlist-items"
    >
      {clips.map((clip, idx) => (
        <Box key={clip.id}>
          <RouterLink
            to={`/interview/${clip.callId}/clip/${clip.id}?playlistId=${playlistId}`}
            target="_blank"
            tabIndex={-1}
            onFocus={(e) =>
              e.relatedTarget
                ? (e.relatedTarget as HTMLElement).focus()
                : e.target.blur()
            }
          >
            <Thumbnail
              time={clip.duration}
              imageUrl={clip.thumbnailImageUrl}
              audioOnly={!clip.thumbnailImageUrl}
              aspectRatio={16 / 9}
              imageScale={1.3}
            />
          </RouterLink>

          <Flex alignItems="center" mt="2">
            <Text
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
              mr="2"
              fontSize="sm"
              fontWeight="medium"
              color="gray.800"
            >
              {clip.displayName}
            </Text>

            <Menu placement="bottom-end" variant="new" autoSelect={false}>
              <MenuButton
                as={IconButton}
                variant="white"
                size="sm"
                ml="auto"
                flexShrink={0}
                color="blue.600"
                icon={<BsThreeDotsVertical size={16} />}
              />

              <MenuList minW="unset">
                <ManagePlaylistMenuItem
                  icon={HiArrowUp}
                  isDisabled={idx === 0}
                  onClick={() => moveClip(idx, "top")}
                >
                  Move to top
                </ManagePlaylistMenuItem>

                <ManagePlaylistMenuItem
                  icon={HiArrowLeft}
                  isDisabled={idx === 0}
                  onClick={() => moveClip(idx, "left")}
                >
                  Move left
                </ManagePlaylistMenuItem>

                <ManagePlaylistMenuItem
                  icon={HiArrowRight}
                  isDisabled={idx === clips.length - 1}
                  onClick={() => moveClip(idx, "right")}
                >
                  Move right
                </ManagePlaylistMenuItem>

                <ManagePlaylistMenuItem
                  icon={IoCreateSharp}
                  isDisabled={!clip.canEdit}
                >
                  <EditTitle clip={clip} />
                </ManagePlaylistMenuItem>

                <MenuDivider />

                <ManagePlaylistMenuItem
                  icon={HiOutlineX}
                  onClick={() => onRemoveClip(clip.id)}
                  color="red.600"
                  _hover={{
                    bg: "red.100",
                    color: "red.500",
                  }}
                  _focus={{
                    color: "red.700",
                    bg: "red.200",
                  }}
                  _active={{ bg: "red.50", color: "red.400" }}
                >
                  Remove
                </ManagePlaylistMenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Box>
      ))}
    </Grid>
  );
};

export default ManagePlaylistItems;

function useClipListGridLayout(opts: { clipCount: number }): {
  ref: React.RefObject<HTMLDivElement>;
  numCols: number;
  columnGap: number;
  minCardWidth: number;
  maxCardWidth: number;
} {
  const [minCardWidth, maxCardWidth, columnGap] = [180, 240, 16];
  const { clipCount } = opts;

  const ref = useRef<HTMLDivElement>(null);
  const { windowWidth } = useWindowDimensions();

  const [numCols, setNumCols] = useState(1);

  useEffect(() => {
    const width = ref.current?.clientWidth;
    if (!width) return;

    const numColsForWidth = (w: number, tolerance = 80): number =>
      Math.max(1, (width + columnGap) / (w + columnGap) - tolerance / w);

    const maxCols = numColsForWidth(minCardWidth);
    const minCols = numColsForWidth(maxCardWidth);

    const bestGuess = Math.min(
      clipCount,
      Math.floor(maxCols),
      Math.ceil(minCols)
    );

    setNumCols(bestGuess);
  }, [ref.current, windowWidth, clipCount]);

  return { ref, numCols, columnGap, minCardWidth, maxCardWidth };
}
