import { Box, Text } from "@chakra-ui/react";
import LogRocket from "logrocket";
import React from "react";

import colors from "../../../theme/css-color-variables";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import {
  LabeledTrackerTabGroup,
  LabeledTrackerTabKeyword,
  TrackerTabState,
} from "./types";

export type TrackerKeywordProps = {
  trackerGroup: LabeledTrackerTabGroup;
  trackerKeyword: LabeledTrackerTabKeyword;
  setTrackerTabState: (trackerTabState: TrackerTabState) => void;
  trackerTabState: TrackerTabState;
  isActiveSpeaker: boolean;
};

const TrackerKeyword: React.FC<TrackerKeywordProps> = ({
  trackerGroup,
  trackerKeyword,
  setTrackerTabState,
  trackerTabState,
  isActiveSpeaker,
}) => {
  const sendGAEvent = useSendGAEvent();
  if (!trackerGroup) {
    return null;
  }

  if (!trackerKeyword) {
    return null;
  }
  const keywordCount = trackerKeyword.count || 0;
  return (
    <Box
      mt="2"
      mb="2"
      mr="3"
      px="2"
      py="1"
      borderRadius="6px"
      fontSize="12"
      cursor="pointer"
      display="inline-block"
      boxShadow={
        isActiveSpeaker
          ? `0 0 0 3px ${colors.blue[300]}`
          : `0 0 0 1px ${colors.gray[100]}`
      }
      data-testid={`tracker-keyword-${trackerKeyword.id}-${
        trackerKeyword.speakerTag || ""
      }`}
      _hover={{ background: colors.gray[50] }}
      onClick={() => {
        setTrackerTabState({
          ...trackerTabState,
          selectedTracker: {
            group: {
              id: trackerGroup.id,
              name: trackerGroup.name,
              label: trackerGroup.label,
            },
            keyword: {
              id: trackerKeyword.id,
              name: trackerKeyword.name,
              label: trackerKeyword.label,
            },
            speakerTag: trackerKeyword.speakerTag,
          },
        });
        sendGAEvent(
          "tracker_click",
          "call_review",
          trackerGroup.name,
          trackerKeyword.name
        );
        LogRocket.track("tracker-keyword-click");
      }}
    >
      <Text display="inline" fontWeight="700">
        {trackerKeyword.label}
      </Text>
      <Text display="inline" color={colors.gray[500]} ml="2">
        {keywordCount > 0 && trackerKeyword.count}
      </Text>
    </Box>
  );
};

export default TrackerKeyword;
