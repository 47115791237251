import { Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import { RouterLink } from "../../../components";
import { formatRelativeDate } from "../../../utils/datetime";
import { Thumbnail } from "../../components";
import AddToTrainingProgramButton from "../../components/AddToTrainingProgramButton/AddToTrainingProgramButtonBeta";
import ClipListItemMoreMenu from "../../components/Interview/Clip/ClipListItemMoreMenu";
import { ClipListItemFragment } from "../../graphql";
import BaseListItem from "../home/Interviews/BaseListItem";

interface ClipListItemProps {
  clip: ClipListItemFragment;
  listPosition?: number;
  size?: "auto" | "sm" | "md";
  displayDate?: any;
  readonly?: boolean;
  onClipLinkClick?(): void;
}

export const ClipListItem: React.FC<ClipListItemProps> = ({
  clip,
  listPosition = 1,
  size = "md",
  displayDate,
  readonly = false,
  onClipLinkClick,
}) => {
  const navigate = useNavigate();
  const clipUrl = `/interview/${clip.callId}/clip/${clip.id}`;

  const clipInfo = (
    <Flex direction="column" mr="6" minW={0} overflow="hidden">
      <Heading
        as="h6"
        size="sm"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
        lineHeight="6"
      >
        <RouterLink
          to={clipUrl}
          color={{ base: "blue.600", lg: "gray.900" }}
          _hover={{ textDecoration: "underline", color: "inherit" }}
          fontWeight="semibold"
          onClick={onClipLinkClick}
          data-testid={`clip-list-item-link-${listPosition}`}
        >
          {clip.displayName}
        </RouterLink>
      </Heading>
      <Flex fontSize="sm" fontWeight="500" lineHeight="5" mt="1.5">
        {clip.candidate?.fullName && (
          <Text
            fontWeight="normal"
            color="gray.600"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            {clip.candidate.fullName}
          </Text>
        )}
        {!clip.candidate?.fullName && (
          <Flex
            color="gray.500"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            No Candidate
          </Flex>
        )}
      </Flex>
      <Flex mt="1.5" alignItems="center">
        <Text
          fontSize="sm"
          fontWeight="500"
          color="gray.500"
          lineHeight="20px"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {formatRelativeDate(displayDate ?? clip.createdAt)}
        </Text>
      </Flex>
    </Flex>
  );

  return (
    <BaseListItem
      leftCol={
        <Flex flexFlow="row nowrap" position="relative">
          <Thumbnail
            size={size}
            isPlayable={clip.recordingReady}
            imageUrl={clip.thumbnailImageUrl}
            audioOnly={!clip.thumbnailImageUrl}
            time={clip.duration}
            mr={5}
            isClip
            onClick={() => {
              navigate(clipUrl);
              onClipLinkClick?.();
            }}
          />
          {clipInfo}
        </Flex>
      }
      rightCol={
        <Flex alignItems="flex-start">
          <AddToTrainingProgramButton
            callId={clip.callId}
            clipIds={[clip?.id]}
            size="sm"
            dataTestId={`add-to-training-program-button-${listPosition}`}
          />
          <ClipListItemMoreMenu
            clip={clip}
            listPosition={listPosition}
            readOnly={readonly}
            positionId={clip.position?.id}
          />
        </Flex>
      }
      mobile={
        <Flex direction="column" gap="1.5" position="relative">
          {clipInfo}
        </Flex>
      }
    />
  );
};
