import { Box, Flex, HStack, Switch, Text } from "@chakra-ui/react";
import React, { FC } from "react";

import StarWithCircle from "../../../components/Icons/StarWithCircle";
import { CallGuide } from "../../graphql";
import useUpdateCallGuide from "../../graphql/hooks/CallGuide/useUpdateCallGuide";

interface OverallScoringToggleProps {
  callGuide: Pick<CallGuide, "id" | "overallScoringEnabled">;
}

const OverallScoringToggle: FC<OverallScoringToggleProps> = ({ callGuide }) => {
  const { id, overallScoringEnabled } = callGuide;

  const [updateCallGuide] = useUpdateCallGuide({});

  return (
    <Flex
      px={6}
      py={3.5}
      bg="white"
      alignItems="center"
      justifyContent="space-between"
    >
      <HStack spacing="3">
        <StarWithCircle />
        <Box>
          <Text color="gray.900" fontWeight="700" fontSize="sm">
            Overall Scoring
          </Text>
          <Text color="gray.700" fontWeight="400" fontSize="sm" mt="0.5">
            Assess and rate the entire interview performance, distinct from
            individual competency scoring.
          </Text>
        </Box>
      </HStack>
      <Switch
        size="md"
        isChecked={overallScoringEnabled}
        onChange={() =>
          updateCallGuide({
            variables: {
              id,
              overallScoringEnabled: !overallScoringEnabled,
            },
          })
        }
      />
    </Flex>
  );
};

export default OverallScoringToggle;
