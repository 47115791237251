import { Box, Button, ButtonProps } from "@chakra-ui/react";
import React, { useState } from "react";

import { LeverIcon, useToast } from "../../../components";
import { useCreateLeverOauthRequestUrlMutation } from "../../graphql";

interface LeverOauthButtonProps {
  buttonProps?: ButtonProps;
}

const LeverOauthButton: React.FC<LeverOauthButtonProps> = ({ buttonProps }) => {
  const toast = useToast();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const [routeToLeverOauth] = useCreateLeverOauthRequestUrlMutation({
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
    onCompleted(data) {
      if (data.createLeverOauthRequestUrl) {
        window.location.href = data.createLeverOauthRequestUrl.leverOauthUrl;
      }
    },
  });

  const handleClick = (): void => {
    setIsDisabled(true);
    routeToLeverOauth();
  };

  return (
    <Box>
      <Button
        onClick={() => handleClick()}
        leftIcon={<LeverIcon width={18} height={18} />}
        aria-label="Lever"
        borderRadius="base"
        variant="outline"
        isDisabled={isDisabled}
        {...buttonProps}
      >
        Add lever oauth
      </Button>
    </Box>
  );
};

export default LeverOauthButton;
