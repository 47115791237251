import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const Caret = (props: IconProps): JSX.Element => (
  <Icon {...props} viewBox="0 0 13 8">
    <svg
      width="13"
      height="8"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.41195 0.200195L6.00195 4.7802L10.592 0.200195L12.002 1.6102L6.00195 7.6102L0.00195313 1.6102L1.41195 0.200195Z"
        fill="white"
      />
    </svg>
  </Icon>
);

export default Caret;
