import React from "react";
import { IconType } from "react-icons";

const ChatOval: IconType = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.55794 14.4785C3.43821 14.4669 3.31954 14.4505 3.20243 14.4295C3.0264 14.398 2.8806 14.2749 2.82006 14.1066C2.75951 13.9383 2.79344 13.7505 2.90904 13.6141C3.17791 13.2968 3.36836 12.9123 3.45215 12.4899C3.46738 12.4131 3.43714 12.2788 3.28247 12.1282C2.18306 11.0581 1.5 9.60571 1.5 8C1.5 4.64623 4.45199 2 8 2C11.548 2 14.5 4.64623 14.5 8C14.5 11.3538 11.548 14 8 14C7.44453 14 6.9046 13.9356 6.38878 13.8142C5.69631 14.2487 4.87687 14.5 4 14.5C3.85098 14.5 3.70344 14.4927 3.55794 14.4785Z"
        fill="#9CA5AE"
      />
    </svg>
  );
};

export default ChatOval;
