import { Box, Flex, FormControl, FormLabel, Select } from "@chakra-ui/react";
import React from "react";

import { diffInDays, MS_PER_DAY } from "../../utils/datetime";
import { CustomDateInput, DatePicker } from "..";

enum Interval {
  Day = "DAY",
  Week = "WEEK",
  Month = "MONTH",
  Year = "YEAR",
}

export type SelectOptionalDateRangeState = {
  start?: Date;
  end?: Date;
  interval: Interval;
  selected: string;
};

const getInterval = (start?: Date, end?: Date): Interval => {
  if (!start || !end) return Interval.Week;
  const diff = diffInDays(start, end);
  return diff > 7 ? Interval.Week : Interval.Day;
};

const today = new Date();

interface SelectOptionalDateRangeProps {
  disabled: boolean;
  state: SelectOptionalDateRangeState;
  onSelect: (state: SelectOptionalDateRangeState) => void;
  maxDate?: Date;
  layout?: "vertical" | "horizontal";
  showLabel?: boolean;
}

const SelectOptionalDateRange: React.FC<SelectOptionalDateRangeProps> = ({
  disabled,
  state,
  onSelect,
  maxDate = today,
  layout = "horizontal",
  showLabel = true,
}) => {
  const sevenDaysAgo = new Date(Date.now() - MS_PER_DAY * 7);
  const thirtyDaysAgo = new Date(Date.now() - MS_PER_DAY * 30);
  const { start, end, selected } = state;

  const handleStartDateChange = (startDate: Date): void => {
    onSelect({
      start: startDate,
      end,
      interval: getInterval(start, end),
      selected: "3",
    });
  };

  const handleEndDateChange = (endDate: Date): void => {
    onSelect({
      start,
      end: endDate,
      interval: getInterval(start, endDate),
      selected: "3",
    });
  };

  return (
    <FormControl
      id="date-range"
      display="flex"
      flex="1"
      flexFlow={layout === "horizontal" ? "row wrap" : "column nowrap"}
      alignItems="center"
      justifyContent="center"
    >
      {showLabel && (
        <FormLabel
          mb="0"
          minW="100px"
          color="secondary"
          fontSize="sm"
          fontWeight="bold"
        >
          TIME PERIOD
        </FormLabel>
      )}
      <Select
        my={1}
        isDisabled={disabled}
        value={selected}
        onChange={(
          e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
        ) => {
          switch (e.target.value) {
            case "1":
              onSelect({
                start: thirtyDaysAgo,
                end: today,
                interval: Interval.Week,
                selected: "1",
              });
              break;
            case "2":
              onSelect({
                start: sevenDaysAgo,
                end: today,
                interval: Interval.Day,
                selected: "2",
              });
              break;
            case "3":
              onSelect({
                start,
                end,
                interval: getInterval(start, end),
                selected: "3",
              });
              break;
          }
        }}
      >
        <option value="1">Last 30 days</option>
        <option value="2">Last 7 days</option>
        <option value="3">Custom</option>
      </Select>
      {selected === "3" && (
        <Flex flexFlow="row wrap" my={1} alignItems="center">
          <Flex flexFlow="column nowrap" mr="1">
            {layout === "vertical" && (
              <Box fontSize="x-small" fontWeight="600">
                Start date
              </Box>
            )}
            <DatePicker
              selected={start}
              maxDate={end}
              onChange={handleStartDateChange}
              disabled={disabled}
              customInput={<CustomDateInput />}
            />
          </Flex>
          {layout === "horizontal" && (
            <Box mx="2" textAlign="center">
              to
            </Box>
          )}
          <Flex flexFlow="column nowrap" ml="1">
            {layout === "vertical" && (
              <Box fontSize="x-small" fontWeight="600">
                End date
              </Box>
            )}
            <DatePicker
              selected={end}
              minDate={start}
              maxDate={maxDate}
              onChange={handleEndDateChange}
              disabled={disabled}
              customInput={<CustomDateInput />}
            />
          </Flex>
        </Flex>
      )}
    </FormControl>
  );
};

export default SelectOptionalDateRange;
