import React from "react";

import App from "../App";
import { DocumentTitle, ErrorBoundary } from "../components";
import getApolloClient from "../utils/apollo/getApolloClient";
import { AppRoutes } from "./AppRoutes";

const apolloClient = getApolloClient();

const Main: React.FC = () => {
  return (
    <App apolloClient={apolloClient}>
      <ErrorBoundary logoLinkUrl="https://app.brighthire.ai">
        <DocumentTitle>
          <AppRoutes />
        </DocumentTitle>
      </ErrorBoundary>
    </App>
  );
};

export default Main;
