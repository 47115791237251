import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { MdCheck } from "react-icons/md";

import Sidebar, {
  SidebarItem,
  SidebarSectionHeader,
} from "../../components/Sidebar";
import { EmptyName } from "./utils";

export type ViewTrainingProgramSidebarItem = {
  id: string;
  name: string;
  completed: boolean;
};

type ViewTrainingSidebarProps = {
  inProgress: ViewTrainingProgramSidebarItem[];
  completed: ViewTrainingProgramSidebarItem[];
  onClickProgram(id: string): void;
  activeProgramId?: string;
};

const ViewTrainingSidebar: React.FC<ViewTrainingSidebarProps> = ({
  inProgress,
  completed,
  onClickProgram,
  activeProgramId,
}) => {
  const renderItem = (
    program: ViewTrainingProgramSidebarItem
  ): React.ReactNode => (
    <SidebarItem
      key={program.id}
      text={program.name === "" ? EmptyName : program.name}
      active={program.id === activeProgramId}
      onClick={() => onClickProgram(program.id)}
      icon={program.completed ? MdCheck : undefined}
    />
  );

  const blankState = (
    <Text fontSize="14" color="gray.600" px="4" pt="3.5" fontWeight="500">
      No programs assigned yet
    </Text>
  );

  return (
    <Sidebar>
      <Box px="2">
        {inProgress.length || completed.length ? (
          <>
            <SidebarSectionHeader text="In Progress" />
            {inProgress.length > 0 ? inProgress.map(renderItem) : blankState}
            {completed.length > 0 && (
              <>
                <SidebarSectionHeader text="Completed" />
                {completed.map(renderItem)}
              </>
            )}
          </>
        ) : (
          <>
            <SidebarSectionHeader text="Training Programs" />
            {blankState}
          </>
        )}
      </Box>
    </Sidebar>
  );
};

export default ViewTrainingSidebar;
