import { Maybe, PaginationDelimiter } from "../main/graphql";

export const getResultsAfter = (
  list: Maybe<any[]>,
  dateProp: string
): Maybe<PaginationDelimiter> => {
  let results: Maybe<PaginationDelimiter> = null;
  if (list?.length && dateProp) {
    const item = list[list.length - 1];
    if (dateProp in item && item.id) {
      results = {
        timestamp: item[dateProp] || new Date().toISOString(),
        id: item.id,
      };
    }
  }
  return results;
};

/**
 * Get a list of possible values the user can select a limit from, including
 * an optional "wildcard"
 * @param limit Optional value that won't be found in the list to allow the
 * user to pick their own value in a nice way
 */
export const getAllowedLimitValues = (
  limit?: number,
  defaultSet: number[] = [10, 20, 50, 100]
): number[] => {
  const limits = defaultSet;
  if (limit) limits.push(Number(limit));
  return limits.filter((v, i, s) => s.indexOf(v) === i).sort((a, b) => a - b);
};
