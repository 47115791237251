import {
  Call,
  Candidate,
  Position,
  Scorecard,
  ScorecardItem,
} from "../../main/graphql";
import { formatDetails } from "../call";
import { copy } from ".";

/** Copies overall interview score + competency scores */
export const copyScores = ({
  call,
  scorecard,
  competencies,
  onComplete,
}: {
  scorecard: Pick<
    Scorecard,
    "overallScore" | "overallNotes" | "overallScoringEnabled"
  >;
  competencies: Pick<
    ScorecardItem,
    "itemText" | "score" | "notes" | "scoringEnabled"
  >[];
  call: Pick<Call, "id" | "startTime"> & {
    candidate?: Pick<Candidate, "fullName"> | null;
    position?: Pick<Position, "displayTitle"> | null;
  };
  onComplete?(): void;
}): void => {
  let plain = `${formatDetails(call)}\n\n`;
  let html = `${formatDetails(call, { html: true })}<br><br>`;

  const clipboardItems = competencies
    .filter((competency) => competency.scoringEnabled)
    .map(({ itemText, score, notes }) => ({
      itemText,
      score,
      notes,
    }));

  if (scorecard.overallScoringEnabled) {
    clipboardItems.splice(0, 0, {
      itemText: "Interview score",
      score: scorecard?.overallScore,
      notes: scorecard?.overallNotes,
    });
  }

  clipboardItems.forEach((clipboardItem) => {
    plain = plain.concat(clipboardItem.itemText);
    html = html.concat(`<b>${clipboardItem.itemText}</b>`);
    if (clipboardItem.score) {
      plain = plain.concat(` (${clipboardItem.score}/5)`).concat("\n");
      html = html.concat(` (${clipboardItem.score}/5)`).concat("<br>");
    } else {
      plain = plain.concat(" (Pending score)\n");
      html = html.concat(" (Pending score)<br>");
    }
    const notes = clipboardItem.notes?.trim();
    if (notes) {
      plain = plain.concat(`Notes: ${notes}`).concat("\n");
      html = html.concat(`Notes: ${notes}`).concat("<br>");
    }
    plain = plain.concat("\n");
    html = html.concat("<br>");
  });
  copy({ plain, html });
  onComplete?.();
};
