import { Box, Button, Flex, Icon, Stack } from "@chakra-ui/react";
import React from "react";
import { HiOutlineCheck, HiOutlineChevronRight } from "react-icons/hi2";

import SummarySection from "./SummarySection";
import { SummaryCallSection } from "./types";

type TopicWizardProps = {
  sections: SummaryCallSection[];
  selectedNoteIds: string[];
  /** Fired when a note is added or removed from the selection */
  onSelect(noteId: string): void;
  onComplete(): void;
};

/**
 * UI for the initial topic selection flow.
 *
 * TBD: When exactly is this shown? Where do we store data to determine
 * when this is shown?
 */
const TopicWizard: React.FC<TopicWizardProps> = ({
  sections,
  selectedNoteIds,
  onSelect,
  onComplete,
}) => {
  const filteredSections = sections.filter(
    (section) => section.text !== "Custom Topics"
  );
  let subtopicCount = 0;
  filteredSections.forEach((s) => {
    subtopicCount += s.notes?.length || 0;
  });

  // Summary.tsx returns an error screen if there are no subtopics or notes
  const numSelected = selectedNoteIds.length;
  const numRequired = subtopicCount < 3 ? subtopicCount : 3;

  return (
    <Box mt="3" borderRadius="lg" border="1px solid" borderColor="gray.200">
      <Flex direction="column" px="3" pt="3" pb="5">
        <Box
          fontSize="sm"
          color="gray.800"
          fontWeight="semibold"
          lineHeight="5"
          mb="1"
        >
          Select topics
        </Box>
        <Box fontSize="xs" color="gray.600" lineHeight="4" mb="3">
          Select at least 3 topics to generate your AI Summary.
        </Box>
        <Flex direction="column" gap="3">
          {filteredSections.map((section) => (
            <SummarySection
              key={section.id}
              section={section}
              selectedNoteIds={selectedNoteIds}
              onSelect={onSelect}
            />
          ))}
        </Flex>
      </Flex>
      <Stack
        direction={["column", "row"]}
        p="3"
        alignItems="center"
        borderTop="1px solid"
        borderColor="gray.200"
        fontWeight="semibold"
        fontSize="sm"
        lineHeight="5"
        color="gray.800"
        gap="1.5"
        justifyContent="space-between"
      >
        <Box>
          Choose at least {numRequired} topic{numRequired > 1 ? "s" : ""}
        </Box>
        <Stepper numSteps={numRequired} numCompleted={numSelected} />
        <Button
          fontWeight="medium"
          isDisabled={numSelected < numRequired}
          size="sm"
          iconSpacing="1.5"
          pr="2"
          flexShrink="0"
          rightIcon={<Icon as={HiOutlineChevronRight} boxSize="5" />}
          onClick={onComplete}
        >
          Generate summary
        </Button>
      </Stack>
    </Box>
  );
};

/**
 * Chakra has this component, but we need to upgrade to 2.8.* to get it.
 *
 * For now, just building it ourselves
 */
const Stepper: React.FC<{ numSteps: number; numCompleted: number }> = ({
  numSteps,
  numCompleted,
}) => {
  /** Gives `[1, 2, ..., numSteps]` */
  const steps = Array.from({ length: numSteps }, (_, i) => i + 1);

  return (
    <Flex mx="auto" alignItems="center">
      {steps.map((step) => {
        const isChecked = step <= numCompleted;
        const color = isChecked ? "blue.600" : "gray.200";
        return (
          <React.Fragment key={step}>
            {step > 1 && <Box w="10" h="3px" bg={color} />}
            <Flex
              boxSize="4"
              borderRadius="full"
              border="1.5px solid"
              borderColor={color}
              bg={isChecked ? color : undefined}
            >
              {isChecked && (
                <Icon
                  as={HiOutlineCheck}
                  boxSize="2.5"
                  strokeWidth="3px"
                  m="auto"
                  color="white"
                />
              )}
            </Flex>
          </React.Fragment>
        );
      })}
    </Flex>
  );
};

export default TopicWizard;
