import { Icon } from "@chakra-ui/react";
import React from "react";

/*
  A custom checkmark icon intended to be used as the 'icon' prop for Chakra's <Checkbox> component.
  It pairs best with a 'variant' prop of 'ghostOutline' (as used in the Assessment step of the create training program flow)
  or 'checkOnly' (as used in the training program sidebar filters).
*/

export function CheckmarkIcon(props: any): JSX.Element | null {
  const { isChecked, isIndeterminate, width, height, ...rest } = props;

  if (isChecked) {
    return (
      <Icon
        width={width || "14px"}
        height={height || "14px"}
        viewBox="0 0 10 7"
        fill="none"
        {...rest}
      >
        <path
          d="M1 3.5L3.66667 6L9 1"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Icon>
    );
  }

  return null;
}
