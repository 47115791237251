export const focusEnd = (element?: HTMLElement | null): void => {
  if (element) {
    element.focus();
    document.execCommand("selectAll", false);
    document.getSelection()?.collapseToEnd();
  }
};

export const setCaretPosition = (node: Node, position: number): void => {
  const range = document.createRange();
  range.setStart(node, position);
  range.setEnd(node, position);
  const sel = document.getSelection();
  sel?.removeAllRanges();
  sel?.addRange(range);
};
