import {
  CurrentUserFragment,
  useSignOutMutation,
  useUnimpersonateMutation,
} from "../graphql";

const useSignOut = ({
  currentUser,
}: {
  currentUser?: Pick<CurrentUserFragment, "isImpersonated"> | null;
} = {}): (() => void) => {
  const [signOut] = useSignOutMutation({
    onCompleted: () => {
      window.location.href = "/sign-in";
    },
  });
  const [unimpersonate] = useUnimpersonateMutation({
    onCompleted: (data) => {
      window.location.href = data.unimpersonate?.redirectTo ?? "/admin/users";
    },
    onError: () => {
      window.location.href = "/";
    },
  });

  return () => {
    if (currentUser?.isImpersonated) {
      unimpersonate();
      return;
    }
    signOut();
  };
};

export default useSignOut;
