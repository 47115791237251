import { useSearchParams } from "react-router-dom";

import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { CallHighlight } from "./types";

export type HighlightModal = {
  activeHighlight: CallHighlight | null;
  setActiveHighlight(highlightId: string | null, from?: string): void;
  goToNextHighlight?(): void;
  goToPrevHighlight?(): void;
};

export const useHighlightModal = (
  highlights: CallHighlight[]
): HighlightModal => {
  const sendGAEvent = useSendGAEvent();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeHighlightId = searchParams.get("highlight") ?? undefined;
  const idxActiveHighlight = highlights.findIndex(
    (h) => h.id === activeHighlightId
  );
  const activeHighlight =
    idxActiveHighlight !== -1 ? highlights[idxActiveHighlight] : null;

  const setActiveHighlight = (
    highlightId: string | null,
    from?: string
  ): void => {
    if (highlightId === null) searchParams.delete("highlight");
    else {
      sendGAEvent("view_highlight", "candidate", from);
      searchParams.set("highlight", highlightId);
    }
    setSearchParams(searchParams, { replace: true });
  };
  const goToNextHighlight =
    idxActiveHighlight < highlights.length - 1
      ? () =>
          setActiveHighlight(highlights[idxActiveHighlight + 1].id, "go_next")
      : undefined;
  const goToPrevHighlight =
    idxActiveHighlight > 0
      ? () =>
          setActiveHighlight(highlights[idxActiveHighlight - 1].id, "go_prev")
      : undefined;

  return {
    activeHighlight,
    setActiveHighlight,
    goToNextHighlight,
    goToPrevHighlight,
  };
};
