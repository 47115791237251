import { Box, Flex, FlexProps, Icon, Tooltip } from "@chakra-ui/react";
import LogRocket from "logrocket";
import React from "react";
import { IoPeople, IoPerson } from "react-icons/io5";

import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import useFeatureFlag from "../../../graphql/hooks/useFeatureFlag";

interface CandidateOnlyToggleProps extends FlexProps {
  candidateOnly: boolean;
  setCandidateOnly(candidateOnly: boolean): void;
}

const CandidateOnlyToggle: React.FC<CandidateOnlyToggleProps> = ({
  candidateOnly,
  setCandidateOnly,
  ...rest
}) => {
  const enabled = useFeatureFlag("candidate-only-playback");
  const sendGAEvent = useSendGAEvent();
  if (!enabled) return <Box />;

  return (
    <Flex
      alignItems="center"
      data-intercom="candidate-only"
      justifyContent="center"
      gap={2}
      position="relative"
      onClick={() => {
        LogRocket.track("candidate-only-toggle");
        sendGAEvent(
          "candidate_only_mode_toggle",
          "call_review",
          candidateOnly.toString()
        );
        setCandidateOnly(!candidateOnly);
      }}
      {...rest}
    >
      <Box
        transition="all 0.2s ease-in"
        transform={`${candidateOnly ? "translateX(26px)" : "translateX(0)"}`}
        position="absolute"
        cursor="pointer"
        left="0"
        w="30px"
        h="24px"
        bg="gray.100"
        borderRadius="full"
        pointerEvents="none"
        textAlign="center"
      />
      <Box
        alignItems="center"
        appearance="none"
        backgroundColor="rgba(255, 255, 255, 0.15)"
        border="none"
        borderRadius="full"
        cursor="pointer"
        display="flex"
        height="24px"
        position="relative"
        width="56px"
      >
        <Box left="4px" padding="2px" position="absolute">
          <Tooltip label="Play All Speakers">
            <Box display="flex" alignItems="center">
              <Icon
                as={IoPeople}
                color={candidateOnly ? "gray.100" : "gray.900"}
                transition="all 0.2s ease-in"
                w="18px"
                h="18px"
              />
            </Box>
          </Tooltip>
        </Box>
        <Box padding="2px" position="absolute" right="4px">
          <Tooltip label="Candidate Only Playback">
            <Box display="flex" alignItems="center">
              <Icon
                as={IoPerson}
                color={candidateOnly ? "gray.900" : "gray.100"}
                transition="all 0.2s ease-in"
                w="18px"
                h="18px"
              />
            </Box>
          </Tooltip>
        </Box>
      </Box>
    </Flex>
  );
};

export default CandidateOnlyToggle;
