import {
  Box,
  IconButton,
  Text,
  Tooltip as ChakraTooltip,
} from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { HiOutlineInformationCircle } from "react-icons/hi";

import { MetricName } from "../../graphql";

export type ValueFormat =
  | "percent"
  | "seconds"
  | "minutes"
  | "hours"
  | "days-hours"
  | "number"
  | "integer";

export const integerFormats: ValueFormat[] = [
  "integer",
  "hours",
  "minutes",
  "seconds",
];

type MetricConfigType = {
  [k in MetricName]: {
    displayName: string;
    question: string;
    answer: (
      formattedTotalValue: string,
      numInterviews: number,
      timeMessage: ReactElement,
      formattedAverage: string,
      countXValues: number,
      xUnit: string
    ) => ReactElement;
    /**
     * Whether the metric is a simple count (e.g. num interviews) or not
     * This affects how averages are calculated. For count metrics, they are
     * averaged across interviewers, for non-count metrics they are averaged
     * across (interviews x segment). E.g. if an interviewer had a talk ratio
     * of 50% for M and F candidates, they're average is 50%, not 100%. But if they
     * did 10 and 20 interviews, their average interviews per week is 30, not 15.
     */
    countMetric: boolean;
    valueFormat: ValueFormat;
    avgValueFormat: ValueFormat;
    detailsHTML: string; // note: this is rendered with dangerouslySetInnerHTML
  };
};

/**
 * Almost all of the per-Metric configuration needed to display a new metric.
 */
export const MetricConfig: MetricConfigType = {
  [MetricName.TotalInterviews]: {
    displayName: "Total Interviews",
    question: "How many interviews are our interviewers conducting?",
    answer: (
      formattedTotalValue: string,
      numInterviews: number,
      timeMessage: ReactElement,
      formattedAverage: string,
      countXValues: number,
      xUnit: string
    ): ReactElement => {
      return (
        <Text>
          Our interviewers conducted {formattedTotalValue} total{" "}
          {numInterviews === 1 ? "interview" : "interviews"} {timeMessage}, on
          average {formattedAverage} per the {countXValues}{" "}
          {pluralize(xUnit, countXValues)}{" "}
          <Box as="span" display="inline-block">
            selected.
            <TotalsTooltip />
          </Box>
        </Text>
      );
    },
    countMetric: true,
    valueFormat: "integer",
    avgValueFormat: "number",
    detailsHTML:
      "Total Interviews is calculated using the count of all interviews conducted during the selected date range.<br /><br />The data for this report includes all interviews over two minutes in length with all speakers labeled.",
  },
  [MetricName.WeeklyInterviews]: {
    displayName: "Weekly Interviews",
    question: "How many weekly interviews are our interviewers conducting?",
    answer: (
      formattedTotalValue: string,
      numInterviews: number,
      timeMessage: ReactElement,
      formattedAverage: string,
      countXValues: number,
      xUnit: string
    ): ReactElement => {
      return (
        <Text>
          Our interviewers had a rate of {formattedTotalValue}{" "}
          {numInterviews === 1 ? "interview" : "interviews"} per week{" "}
          {timeMessage}, and an average rate of {formattedAverage} per week for
          the {countXValues} {pluralize(xUnit, countXValues)}{" "}
          <Box as="span" display="inline-block">
            selected.
            <TotalsTooltip />
          </Box>
        </Text>
      );
    },
    countMetric: true,
    valueFormat: "number",
    avgValueFormat: "number",
    detailsHTML:
      "Weekly Interviews is calculated using the count of all interviews per week during the selected date range.<br /><br />The data for this report includes all interviews over two minutes in length with all speakers labeled.",
  },
  [MetricName.TotalInterviewTime]: {
    displayName: "Total Interview Time",
    countMetric: true,
    valueFormat: "hours",
    avgValueFormat: "hours",
    question:
      "How much total time are our interviewers spending in interviews?",
    answer: (
      formattedTotalValue: string,
      numInterviews: number,
      timeMessage: ReactElement,
      formattedAverage: string,
      countXValues: number,
      xUnit: string
    ): ReactElement => {
      return (
        <Text>
          Our interviewers conducted {formattedTotalValue} in total{" "}
          {numInterviews === 1 ? "interview" : "interviews"} {timeMessage}, on
          average {formattedAverage} per the {pluralize(xUnit, countXValues)}{" "}
          <Box as="span" display="inline-block">
            selected.
            <TotalsTooltip />
          </Box>
        </Text>
      );
    },
    detailsHTML:
      "Total Interview Time is calculated using the total duration across interviews conducted during the selected date range.<br /><br />The data for this report includes all interviews over two minutes in length with all speakers labeled.",
  },
  [MetricName.WeeklyInterviewTime]: {
    displayName: "Weekly Interview Time",
    question:
      "How much time per week are our interviewers spending in interviews?",
    answer: (
      formattedTotalValue: string,
      numInterviews: number,
      timeMessage: ReactElement,
      formattedAverage: string,
      countXValues: number,
      xUnit: string
    ): ReactElement => {
      return (
        <Text>
          Our interviewers have spent {formattedTotalValue} per week in
          interviews {timeMessage}, on average {formattedAverage} per the{" "}
          {countXValues} {pluralize(xUnit, countXValues)}{" "}
          <Box as="span" display="inline-block">
            selected.
            <TotalsTooltip />
          </Box>
        </Text>
      );
    },
    countMetric: true,
    valueFormat: "minutes",
    avgValueFormat: "minutes",
    detailsHTML:
      "Weekly Interview Time is calculated using the total duration across interviews per week during the selected date range.<br /><br />The data for this report includes all interviews over two minutes in length with all speakers labeled.",
  },
  [MetricName.CandidateTalkRatio]: {
    displayName: "Candidate Talk Ratio",
    question:
      "Do our interviewers give candidates enough time to speak in interviews?",
    countMetric: false,
    answer: (
      formattedTotalValue: string,
      numInterviews: number,
      timeMessage: ReactElement,
      formattedAverage: string,
      countXValues: number,
      xUnit: string
    ): ReactElement => {
      return (
        <Text>
          Our interviewers let candidates speak on average {formattedAverage} of
          the total time in interviews {timeMessage}, across {numInterviews}{" "}
          interviews and the {countXValues} {pluralize(xUnit, countXValues)}{" "}
          <Box as="span" display="inline-block">
            selected.
            <TotalsTooltip />
          </Box>
        </Text>
      );
    },
    valueFormat: "percent",
    avgValueFormat: "percent",
    detailsHTML:
      "Candidate Talk Ratio is calculated using the total time the candidate is speaking as a share of the total duration of the interview, averaged over the selected date range.<br /><br />The data for this report includes all interviews over two minutes in length with all speakers labeled.",
  },
  [MetricName.LongestMonologue]: {
    displayName: "Longest Monologue",
    question: "Do our interviewers leave space for others in the conversation?",
    answer: (
      formattedTotalValue: string,
      numInterviews: number,
      timeMessage: ReactElement,
      formattedAverage: string,
      countXValues: number,
      xUnit: string
    ): ReactElement => {
      return (
        <Text>
          Our interviewers&apos; longest monologue on average is{" "}
          {formattedAverage} {timeMessage}, across {numInterviews} interviews
          and the {countXValues} {pluralize(xUnit, countXValues)}{" "}
          <Box as="span" display="inline-block">
            selected.
            <TotalsTooltip />
          </Box>
        </Text>
      );
    },
    countMetric: false,
    valueFormat: "seconds",
    avgValueFormat: "seconds",
    detailsHTML:
      "Longest Monologue is calculated as the interviewer's longest speaking segment in the interview, averaged across interviews over the selected date range.<br /><br />The data for this report includes all interviews over two minutes in length with all speakers labeled.",
  },
  [MetricName.SpeakingRate]: {
    displayName: "Speaking Rate",
    question: "Are our interviewers speaking at a comfortable pace?",
    answer: (
      formattedTotalValue: string,
      numInterviews: number,
      timeMessage: ReactElement,
      formattedAverage: string,
      countXValues: number,
      xUnit: string
    ): ReactElement => {
      return (
        <Text>
          Our interviewers&apos; average speaking rate is {formattedAverage}{" "}
          words per minute {timeMessage}, across {numInterviews} interviews and
          the {countXValues} {pluralize(xUnit, countXValues)}{" "}
          <Box as="span" display="inline-block">
            selected.
            <TotalsTooltip />
          </Box>
        </Text>
      );
    },
    countMetric: false,
    valueFormat: "integer",
    avgValueFormat: "integer",
    detailsHTML:
      "Speaking Rate is calculated as the words per minute spoken by the interviewer.<br /><br />The data for this report includes all interviews over two minutes in length with all speakers labeled.",
  },
  [MetricName.Interactivity]: {
    displayName: "Interactivity",
    question:
      "Are interviewers allowing enough conversational back-and-forth in interviews?",
    answer: (
      formattedTotalValue: string,
      numInterviews: number,
      timeMessage: ReactElement,
      formattedAverage: string,
      countXValues: number,
      xUnit: string
    ): ReactElement => {
      return (
        <Text>
          Our interviewers&apos; average interactivity score is{" "}
          {formattedAverage} {timeMessage}, across {numInterviews} interviews
          and the {countXValues} {pluralize(xUnit, countXValues)}{" "}
          <Box as="span" display="inline-block">
            selected.
            <TotalsTooltip />
          </Box>
        </Text>
      );
    },
    countMetric: false,
    valueFormat: "number",
    avgValueFormat: "number",
    detailsHTML:
      "Interactivity is a measure of the relative count of “back-and-forths” that happened during the interview compared to all interviews across the platform from the prior three months.",
  },
  [MetricName.Rating]: {
    displayName: "Rating",
    question: "Are our interviewers well calibrated on average ratings?",
    answer: (
      formattedTotalValue: string,
      numInterviews: number,
      timeMessage: ReactElement,
      formattedAverage: string,
      countXValues: number,
      xUnit: string
    ): ReactElement => {
      return (
        <Text>
          Our interviewers conducted {formattedTotalValue} total interviews
          {timeMessage}, on average {formattedAverage} per the {countXValues}
          {pluralize(xUnit, countXValues)}{" "}
          <Box as="span" display="inline-block">
            selected.
            <TotalsTooltip />
          </Box>
        </Text>
      );
    },
    countMetric: false,
    valueFormat: "number",
    avgValueFormat: "number",
    detailsHTML: "",
  },
  [MetricName.StrongRatingPercent]: {
    displayName: "Strong Rating Percent",
    question: "Are our interviewers expressing strong opinions?",
    answer: (
      formattedTotalValue: string,
      numInterviews: number,
      timeMessage: ReactElement,
      formattedAverage: string,
      countXValues: number,
      xUnit: string
    ): ReactElement => {
      return (
        <Text>
          Our interviewers conducted {formattedTotalValue} total interviews
          {timeMessage}, on average {formattedAverage} per the {countXValues}
          {pluralize(xUnit, countXValues)}{" "}
          <Box as="span" display="inline-block">
            selected.
            <TotalsTooltip />
          </Box>
        </Text>
      );
    },
    countMetric: false,
    valueFormat: "percent",
    avgValueFormat: "percent",
    detailsHTML: "",
  },
  [MetricName.OnTimeInterviews]: {
    displayName: "On-Time Interview Starts",
    question: "Do our interviewers start their interviews on time?",
    answer: (
      formattedTotalValue: string,
      numInterviews: number,
      timeMessage: ReactElement,
      formattedAverage: string,
      countXValues: number,
      xUnit: string
    ): ReactElement => {
      return (
        <Text>
          Our interviews started on time on average {formattedAverage} of the
          time {timeMessage}, across {numInterviews} interviews and the{" "}
          {countXValues} {pluralize(xUnit, countXValues)}{" "}
          <Box as="span" display="inline-block">
            selected.
            <TotalsTooltip />
          </Box>
        </Text>
      );
    },
    countMetric: false,
    valueFormat: "percent",
    avgValueFormat: "percent",
    detailsHTML:
      "On-Time Start is calculated as how often the interviewer started speaking within five minutes of the scheduled interview start, averaged across interviews over the selected date range." +
      "<br /><br />The data for this report includes all interviews over two minutes in length with all speakers labeled. Interviews without a scheduled start time are excluded from this report.",
  },
  [MetricName.QuestionsAsked]: {
    displayName: "Questions Asked",
    question: "How many questions do our interviewers ask?",
    answer: (
      formattedTotalValue: string,
      numInterviews: number,
      timeMessage: ReactElement,
      formattedAverage: string,
      countXValues: number,
      xUnit: string
    ): ReactElement => {
      return (
        <Text>
          Our interviewers asked {formattedAverage} questions per interview{" "}
          {timeMessage} across {numInterviews} interviews and the {countXValues}{" "}
          {pluralize(xUnit, countXValues)}{" "}
          <Box as="span" display="inline-block">
            selected.
            <TotalsTooltip />
          </Box>
        </Text>
      );
    },
    countMetric: false,
    valueFormat: "number",
    avgValueFormat: "number",
    detailsHTML:
      "Questions Asked is the number of questions the interviewer asked in an interview, averaged over the selected date range.<br /><br />The data for this report includes all interviews over two minutes in length with all speakers labeled.",
  },
  [MetricName.WorkLocationCoverage]: {
    displayName: "Work Location",
    question: "Are our interviewers discussing work location?",
    answer: (
      formattedTotalValue: string,
      numInterviews: number,
      timeMessage: ReactElement,
      formattedAverage: string,
      countXValues: number,
      xUnit: string
    ): ReactElement => {
      return (
        <Text>
          Our interviewers&apos; average coverage of work location is{" "}
          {formattedAverage} {timeMessage}, across {numInterviews} interviews
          and the {countXValues} {pluralize(xUnit, countXValues)}{" "}
          <Box as="span" display="inline-block">
            selected.
            <TotalsTooltip />
          </Box>
        </Text>
      );
    },
    countMetric: false,
    valueFormat: "percent",
    avgValueFormat: "percent",
    detailsHTML: "",
  },
  [MetricName.CompensationCoverage]: {
    displayName: "Compensation",
    question: "Are our interviewers discussing compensation?",
    answer: (
      formattedTotalValue: string,
      numInterviews: number,
      timeMessage: ReactElement,
      formattedAverage: string,
      countXValues: number,
      xUnit: string
    ): ReactElement => {
      return (
        <Text>
          Our interviewers&apos; average coverage of compensation is{" "}
          {formattedAverage} {timeMessage}, across {numInterviews} interviews
          and the {countXValues} {pluralize(xUnit, countXValues)}{" "}
          <Box as="span" display="inline-block">
            selected.
            <TotalsTooltip />
          </Box>
        </Text>
      );
    },
    countMetric: false,
    valueFormat: "percent",
    avgValueFormat: "percent",
    detailsHTML: "",
  },
  [MetricName.CareLikeFamilyCoverage]: {
    displayName: "Care Like Family",
    question: 'Are our interviewers discussing "Care like Family"?',
    answer: (
      formattedTotalValue: string,
      numInterviews: number,
      timeMessage: ReactElement,
      formattedAverage: string,
      countXValues: number,
      xUnit: string
    ): ReactElement => {
      return (
        <Text>
          Our interviewers&apos; average coverage of &quot;Care like
          Family&quot; is {formattedAverage} {timeMessage}, across{" "}
          {numInterviews} interviews and the {countXValues}{" "}
          {pluralize(xUnit, countXValues)}{" "}
          <Box as="span" display="inline-block">
            selected.
            <TotalsTooltip />
          </Box>
        </Text>
      );
    },
    countMetric: false,
    valueFormat: "percent",
    avgValueFormat: "percent",
    detailsHTML: "",
  },
  [MetricName.Sentiment]: {
    displayName: "Candidate Sentiment",
    question: "Are candidates having a positive experience?",
    answer: (
      formattedTotalValue: string,
      numInterviews: number,
      timeMessage: ReactElement,
      formattedAverage: string,
      countXValues: number,
      xUnit: string
    ): ReactElement => {
      return (
        <Text>
          Our average customer sentiment is {formattedAverage} {timeMessage},
          across {numInterviews} interviews and the {countXValues}{" "}
          {pluralize(xUnit, countXValues)}{" "}
          <Box as="span" display="inline-block">
            selected.
            <TotalsTooltip />
          </Box>
        </Text>
      );
    },
    countMetric: false,
    valueFormat: "percent",
    avgValueFormat: "percent",
    detailsHTML: "",
  },
  // AtsTotalInterviews not shown in the UI - used for scorecard metrics hero
  [MetricName.AtsTotalInterviews]: {
    displayName: "Total Scheduled Interviews",
    question: "",
    answer: (
      formattedTotalValue: string,
      numInterviews: number,
      timeMessage: ReactElement,
      formattedAverage: string,
      countXValues: number,
      xUnit: string
    ): ReactElement => {
      return <> </>;
    },
    countMetric: true,
    valueFormat: "integer",
    avgValueFormat: "number",
    detailsHTML: "",
  },
  [MetricName.PassRate]: {
    displayName: "Positive Feedback Rate",
    question:
      "For what percentage of candidates do interviewers provide a positive rating?",
    answer: (
      formattedTotalValue: string,
      numInterviews: number,
      timeMessage: ReactElement,
      formattedAverage: string,
      countXValues: number,
      xUnit: string
    ): ReactElement => {
      return (
        <Text>
          Our interviewers&apos; average positive feedback rate is{" "}
          {formattedAverage} {timeMessage}, across {numInterviews} interviews
          and the {countXValues} {pluralize(xUnit, countXValues)}{" "}
          <Box as="span" display="inline-block">
            selected.
            <TotalsTooltip isScorecardMetric />
          </Box>
        </Text>
      );
    },
    countMetric: false,
    valueFormat: "percent",
    avgValueFormat: "percent",
    detailsHTML:
      "Positive Feedback Rate is the percentage of time that a user submits a positive recommendation for their candidate after an interview. <br/><br/> The data for this report includes all interviews with feedback ratings that are over two minutes in length with all speakers labeled.",
  },
  [MetricName.AlignmentRate]: {
    displayName: "Alignment Rate",
    question: "Placeholder question?",
    answer: (
      formattedTotalValue: string,
      numInterviews: number,
      timeMessage: ReactElement,
      formattedAverage: string,
      countXValues: number,
      xUnit: string
    ): ReactElement => {
      return (
        <Text>
          Our interviewers&apos; average alignment rate is {formattedAverage}{" "}
          {timeMessage}, across {numInterviews} interviews and the{" "}
          {countXValues} {pluralize(xUnit, countXValues)}{" "}
          <Box as="span" display="inline-block">
            selected.
            <TotalsTooltip />
          </Box>
        </Text>
      );
    },
    countMetric: false,
    valueFormat: "percent",
    avgValueFormat: "percent",
    detailsHTML: "",
  },
  [MetricName.ScorecardCompletionRate]: {
    displayName: "Feedback Submission Rate",
    question: "For what percentage of interviews is feedback submitted?",
    answer: (
      formattedTotalValue: string,
      numInterviews: number,
      timeMessage: ReactElement,
      formattedAverage: string,
      countXValues: number,
      xUnit: string
    ): ReactElement => {
      return (
        <Text>
          Our interviewers&apos; average submission rate is {formattedAverage}{" "}
          {timeMessage}, across {numInterviews} interviews and the{" "}
          {countXValues} {pluralize(xUnit, countXValues)}{" "}
          <Box as="span" display="inline-block">
            selected.
            <TotalsTooltip isScorecardMetric />
          </Box>
        </Text>
      );
    },
    countMetric: false,
    valueFormat: "percent",
    avgValueFormat: "percent",
    detailsHTML:
      "Feedback Submission rate is the percentage of time a user submits feedback after completing an interview. <br/><br/> The data for this report includes all interviews over two minutes in length with all speakers labeled.",
  },
  [MetricName.ScorecardCompletionTime]: {
    displayName: "Feedback Submission Time",
    countMetric: false,
    valueFormat: "hours",
    avgValueFormat: "hours",
    question:
      "What is the average time between an interview ending and feedback being submitted?",
    answer: (
      formattedTotalValue: string,
      numInterviews: number,
      timeMessage: ReactElement,
      formattedAverage: string,
      countXValues: number,
      xUnit: string
    ): ReactElement => {
      return (
        <Text>
          Our interviewers average a total of {formattedAverage} to submit their
          feedback {timeMessage} for the {countXValues}{" "}
          {pluralize(xUnit, countXValues)} selected.
          <TotalsTooltip isScorecardMetric />
        </Text>
      );
    },
    detailsHTML:
      "Feedback Submission time is the time between an interview ending and feedback being submitted, averaged over the selected date range.<br/><br/>The data for this report includes all interviews over two minutes in length with all speakers labeled.",
  },
  [MetricName.TopicTrends]: {
    displayName: "Topic Trends",
    question: "What is discussed in our interviews?",
    answer: (
      formattedTotalValue: string,
      numInterviews: number,
      timeMessage: ReactElement,
      formattedAverage: string,
      countXValues: number,
      xUnit: string
    ): ReactElement => {
      return <Text />;
    },
    countMetric: true,
    valueFormat: "integer",
    avgValueFormat: "number",
    detailsHTML: "",
  },
  [MetricName.QuestionTrends]: {
    displayName: "Question Trends",
    question: "What is being asked in our interviews?",
    answer: (
      formattedTotalValue: string,
      numInterviews: number,
      timeMessage: ReactElement,
      formattedAverage: string,
      countXValues: number,
      xUnit: string
    ): ReactElement => {
      return <Text />;
    },
    countMetric: true,
    valueFormat: "integer",
    avgValueFormat: "number",
    detailsHTML: "",
  },
};

/** Only works for current set of dimensions */
const pluralize = (singularWord: string, count: number): string => {
  if (count === 1) {
    return singularWord.toLocaleLowerCase();
  }
  return `${singularWord.toLocaleLowerCase()}s`;
};

const TotalsTooltip: React.FC<{ isScorecardMetric?: boolean }> = ({
  isScorecardMetric,
}): JSX.Element => (
  <ChakraTooltip
    bg="white"
    p="2"
    borderRadius="md"
    border="1px solid"
    borderColor="gray.200"
    color="gray.800"
    boxShadow="none"
    fontSize="sm"
    shouldWrapChildren
    fontWeight="normal"
    label={
      isScorecardMetric ? (
        <>
          Total interviews is calculated by summing the number of ATS scheduled
          interviews for each interviewer. This includes interviews redacted in
          BrightHire and interviews not captured in BrightHire. It can also be
          higher than the actual number of interviews conducted when there are
          multiple interviewers in the same interview, or lower than the actual
          number of interviews if there were unidentified speakers or
          unidentified candidates in the interview.
        </>
      ) : (
        <>
          Total interviews is calculated by summing the number of interviews for
          each interviewer (including redacted interviews). It can be higher
          than the actual number of interviews conducted when there are multiple
          interviewers in the same interview. It can also be lower than the
          actual number of interviews if there were unidentified speakers or
          unidentified candidates in the interview.
        </>
      )
    }
    placement="top"
  >
    <IconButton
      aria-label="Metric totals description"
      variant="unstyled"
      size="xxs"
      ml="1"
      color="gray.800"
      mb="3px"
      icon={<HiOutlineInformationCircle />}
    />
  </ChakraTooltip>
);
