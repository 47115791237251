export enum Interval {
  Day = "DAY",
  Week = "WEEK",
  Month = "MONTH",
  Year = "YEAR",
}

export type DataKey =
  | "numSignificantCalls"
  | "totalDuration"
  | "interviewerTalkRatio"
  | "interviewerLongestMonologue"
  | "interviewerWordsPerMinute"
  | "callInteractivity"
  | "weeklyCalls"
  | "weeklyDuration";

export type CallingMetrics = {
  numSignificantCalls: number;
  totalDuration: number;
  interviewerTalkRatio?: number | null;
  interviewerLongestMonologue?: number | null;
  interviewerWordsPerMinute?: number | null;
  callInteractivity?: number | null;
  weeklyCalls: number;
  weeklyDuration: number;
};

export type CallingMetricsInterval = {
  date: string;
} & Omit<CallingMetrics, "weeklyCalls" | "weeklyDuration" | "intervals">;

export type UserMetrics = {
  id: string;
  fullName: string;
  callingMetrics: CallingMetrics;
};

export type OrgCallingMetrics = {
  id: string;
  callingMetrics: CallingMetrics;
  users: Array<UserMetrics>;
};

export type KeywordHit = {
  objectID: string;
  callId: string;
  startTime: number;
  duration: number;
  name: string | null;
  rating: number | null;
  position: string | null;
  positionId: string | null;
  candidate: {
    id: string;
    name: string;
    email: string | null;
    phoneNumber: string | null;
  } | null;
  interviewers?: Array<{
    id: string;
    name: string;
    email: string;
  }>;
  transcriptSegments: Array<{
    speaker: string;
    startTime: number;
    text: string;
  }>;
  candidateSegments: Array<{
    speaker: string;
    startTime: number;
    text: string;
  }>;
  interviewSegments: Array<{
    speaker: string;
    startTime: number;
    text: string;
  }>;
  notes: Array<{
    id: string;
    type: string;
    time: number;
    text: string;
  }>;
  candidateMatchedWords: Set<string>;
  interviewerMatchedWords: Set<string>;
};

export type FilteredHits = {
  groupedHits: KeywordHit[];
  candidateHits: KeywordHit[];
  interviewerHits: KeywordHit[];
};

export enum DataCategories {
  ALL = "ALL",
  CANDIDATE = "CANDIDATE",
  INTERVIEWER = "INTERVIEWER",
}
