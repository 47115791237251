import React from "react";

import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { usePageTracker } from "../../../utils/googleAnalytics";
import ClipViewBeta from "../../components/Interview/Clip/beta/ClipView";
import { DefaultPageLayout } from "../../layouts";

const ClipPage: React.FC = () => {
  usePageTracker("call_details");
  const { layoutHeight } = useWindowDimensions();

  return (
    <DefaultPageLayout height={{ base: "auto", lg: layoutHeight }}>
      <ClipViewBeta />
    </DefaultPageLayout>
  );
};

export default ClipPage;
