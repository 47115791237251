import { BoxProps, Flex } from "@chakra-ui/react";
import React from "react";

import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { MetricName } from "../../graphql";
import DefaultLayout from "../../layouts/DefaultLayout";
import MainContent from "../training/MainContent";
import AnalyticsSidebar from "./AnalyticsSidebar";

type AnalyticsPageLayout = {
  children?: React.ReactNode;
  metric?: MetricName;
  maxW?: string;
  sidebarProps?: BoxProps;
  mainContentProps?: BoxProps;
  dashboardAvailable: boolean;
  isPersonalInsights?: boolean;
};

export const AnalyticsPageLayout: React.FC<AnalyticsPageLayout> = ({
  metric,
  maxW,
  children,
  sidebarProps,
  mainContentProps,
  dashboardAvailable,
  isPersonalInsights,
}) => {
  const { layoutHeight } = useWindowDimensions();
  return (
    <DefaultLayout>
      <Flex minHeight={layoutHeight} overflowX="auto">
        <AnalyticsSidebar
          path={metric || (isPersonalInsights ? "my-insights" : "overview")}
          dashboardAvailable={dashboardAvailable}
          {...sidebarProps}
        />
        <MainContent
          data-testid="analytics--page-content"
          px="6"
          pb="100px"
          minWidth={{ base: "100%", lg: "800px" }}
          overflow="inherit"
          {...mainContentProps}
        >
          <Flex
            flexDir="column"
            height="100%"
            mx="auto"
            maxWidth={maxW || "1012px"}
            boxSizing="initial"
          >
            {children}
          </Flex>
        </MainContent>
      </Flex>
    </DefaultLayout>
  );
};
