import {
  Box,
  Button,
  Slide,
  StyleProps,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { transparentize } from "@chakra-ui/theme-tools";
import React, { useEffect, useRef, useState } from "react";

import { SuccessCheckMark } from "../../../../components";
import { formatRelativeDate } from "../../../../utils/datetime";

type TrainingTabFooterProps = StyleProps & {
  isDisabled: boolean;
  lastSubmittedAt: string | null;
  questionsListEl: HTMLElement | null;
  tooltip?: string;
};

const TrainingTabFooter: React.FC<TrainingTabFooterProps> = ({
  isDisabled,
  lastSubmittedAt,
  questionsListEl,
  tooltip,
  ...styles
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const submitButtonStyles = lastSubmittedAt
    ? {
        border: "1px solid",
        borderColor: "blue.600",
        color: "blue.600",
        backgroundColor: transparentize("blue.600", 0.1) as unknown as string,
        _hover: {
          bg: "blue.100",
          _disabled: {
            bg: "gray.100",
          },
        },
      }
    : {
        border: "transparent",
        color: "white",
        backgroundColor: "blue.600",
        _hover: {
          bg: "blue.700",
          _disabled: {
            bg: "gray.100",
          },
        },
      };

  const showMoreQuestions = useShowMoreQuestions(questionsListEl);

  const scrollToBottom = (): void => {
    questionsListEl?.scrollTo({
      top: questionsListEl.clientHeight,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Tooltip label={tooltip} placement="end" mr="-5" maxW="180">
        <Box
          ref={ref}
          py="5"
          backgroundColor="white"
          boxShadow="0px -4px 32px rgba(0, 0, 0, 0.03)"
          display="flex"
          alignItems="center"
          borderTopWidth="1px"
          borderTopColor="gray.200"
          zIndex="1"
          {...styles}
        >
          <Button
            type="submit"
            colorScheme="blue"
            ml="5"
            mr="6"
            fontSize="sm"
            fontWeight="500"
            disabled={isDisabled}
            {...submitButtonStyles}
          >
            {lastSubmittedAt ? "Resubmit" : "Submit"}
          </Button>

          {lastSubmittedAt && (
            <Box display="flex" alignItems="baseline">
              <Box>
                <SuccessCheckMark />
              </Box>
              <Box pl="12px">
                <Text fontSize="14px" fontWeight="500">
                  Submitted
                </Text>
                <Text fontSize="12px" textTransform="capitalize">
                  {formatRelativeDate(new Date(lastSubmittedAt).toISOString())}
                </Text>
              </Box>
            </Box>
          )}
        </Box>
      </Tooltip>

      <Slide
        direction="bottom"
        in={showMoreQuestions}
        motionProps={{
          style: {
            position: "absolute",
            bottom: ref.current?.clientHeight ?? 0,
            left: "0",
            right: "0",
            zIndex: "0",
          },
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          pb={3}
          position="sticky"
          bottom="0"
          zIndex="1"
        >
          <Box
            as="span"
            fontSize="sm"
            fontWeight="500"
            backgroundColor="gray.100"
            borderRadius="14px"
            px={4}
            py={1}
            color="gray.800"
            filter="drop-shadow(1px 2px 8px rgba(0, 0, 0, 0.08))"
            cursor="pointer"
            onClick={scrollToBottom}
          >
            More questions below
          </Box>
        </Box>
      </Slide>
    </>
  );
};

export default TrainingTabFooter;

const useShowMoreQuestions = (el: HTMLElement | null): boolean => {
  const [hasOverflow, setHasOverflow] = useState(false);
  const [userHasScrolled, setUserHasScrolled] = useState(false);
  const onScroll = (): void => setUserHasScrolled(true);

  useEffect(() => {
    el?.addEventListener("scroll", onScroll);
    return () => el?.removeEventListener("scroll", onScroll);
  }, [el]);

  useEffect(() => {
    if (el) {
      setHasOverflow(el.scrollHeight > el.clientHeight);
    }
  }, [el?.scrollHeight, el?.clientHeight]);

  return hasOverflow && !userHasScrolled;
};
