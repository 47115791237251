/* eslint-disable no-underscore-dangle */
import invariant from "invariant";

import { KeywordHit } from "./types";

export const groupHits = (hits: any[]): KeywordHit[] => {
  const groupedHits: KeywordHit[] = [];

  hits.forEach((hit: any) => {
    if (!hit) {
      return;
    }
    // Group hits by call
    // See: https://www.algolia.com/doc/api-reference/api-parameters/distinct/?language=javascript
    const lastHit =
      groupedHits.length === 0 ? null : groupedHits[groupedHits.length - 1];

    // eslint-disable-next-line camelcase
    const snippetSegments = hit._snippetResult?.transcript_segments;
    const highlightedSegments = hit._highlightResult?.transcript_segments;
    const hitSegments = hit.transcript_segments;
    const candidateMatchedWords = new Set<string>();
    const interviewerMatchedWords = new Set<string>();

    const segments: Array<{
      speaker: string;
      startTime: number;
      text: string;
    }> = [];

    const candidateSegments: Array<{
      speaker: string;
      startTime: number;
      text: string;
    }> = [];

    const interviewerSegments: Array<{
      speaker: string;
      startTime: number;
      text: string;
    }> = [];

    if (snippetSegments) {
      snippetSegments.forEach((snippetSegment: any, index: number) => {
        if (snippetSegment.text.matchLevel !== "none") {
          const hitSegment = hitSegments[index];
          const segment = {
            speaker: hitSegment.speaker,
            startTime: hitSegment.start_offset_seconds,
            text: snippetSegment.text.value,
          };
          segments.push(segment);
          if (hitSegment.speaker === hit.candidate?.name) {
            candidateSegments.push(segment);
            highlightedSegments[index].text.matchedWords.forEach(
              (word: string) => candidateMatchedWords.add(word)
            );
          } else {
            interviewerSegments.push(segment);
            highlightedSegments[index].text.matchedWords.forEach(
              (word: string) => interviewerMatchedWords.add(word)
            );
          }
        }
      });
    }

    const hightlightedNotes = hit._highlightResult?.notes;
    const hitNotes = hit.notes;
    const notes: Array<{
      id: string;
      type: string;
      time: number;
      text: string;
    }> = [];
    if (hightlightedNotes) {
      hightlightedNotes.forEach((hightlightedNote: any, index: number) => {
        if (hightlightedNote.text.matchLevel !== "none") {
          const hitNote = hitNotes[index];
          notes.push({
            id: hitNote.note_id,
            type: hitNote.type,
            time: hitNote.time,
            text: hightlightedNote.text.value,
          });
        }
      });
    }

    if (lastHit?.callId === hit.call_id) {
      invariant(lastHit, "Missing lastHit");
      if (segments.length > 0) {
        lastHit.transcriptSegments = [
          ...lastHit.transcriptSegments,
          ...segments,
        ].slice(0, 3);
      }
      lastHit.notes = [...lastHit.notes, ...notes];
    } else {
      groupedHits.push({
        objectID: hit.objectID,
        callId: hit.call_id,
        startTime: hit.call_timestamp_seconds,
        duration: hit.call_duration_seconds,
        name: hit._highlightResult.name?.value,
        rating: hit.rating,
        position: hit._highlightResult.position?.value,
        positionId: hit.position_id,
        candidate: hit.candidate
          ? {
              id: hit.candidate.id,
              name: hit._highlightResult.candidate.name?.value,
              email: hit._highlightResult.candidate.email?.value,
              phoneNumber: hit._highlightResult.candidate.phoneNumber?.value,
            }
          : null,
        interviewers: hit._highlightResult.interviewers?.map(
          (interviewer: any) => ({
            id: interviewer.id,
            name: interviewer.name?.value,
            email: interviewer.email?.value,
          })
        ),
        transcriptSegments: segments.slice(0, 3),
        candidateSegments: candidateSegments.slice(0, 3),
        interviewSegments: interviewerSegments.slice(0, 3),
        notes,
        candidateMatchedWords,
        interviewerMatchedWords,
      });
    }
  });
  return groupedHits;
};
