import { Grid } from "@chakra-ui/react";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { Tab } from "../../utils/types";
import { Card } from "../Card";
import MasterListView from "./MasterListView";

const MasterDetail: React.FC<
  {
    tabs: Tab<any>[];
    urlPrefix: string;
    title?: string;
  } & {
    [k: string]: any;
  }
> = ({ tabs, urlPrefix, title, ...rest }) => (
  <Grid
    minW="pageMinWidth"
    gap="5"
    overflow="visible"
    templateColumns="300px auto"
    data-testid="master-detail--container"
  >
    <MasterListView tabs={tabs} title={title} urlPrefix={urlPrefix} />
    <Card p="8">
      <Routes>
        {tabs.map((tab, i) => (
          <React.Fragment key={`detail-${tab.path}`}>
            {i === 0 && (
              <Route
                path="/"
                element={<Navigate to={`${tab.path}`} replace />}
              />
            )}
            <Route
              path={`/${tab.path}`}
              element={<tab.Component {...rest} {...tab.attributes} />}
            />
          </React.Fragment>
        ))}
        <Route path="*" element={<Navigate to="/admin/not-found" />} />
      </Routes>
    </Card>
  </Grid>
);

export default MasterDetail;
