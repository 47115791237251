import { SystemProps } from "@chakra-ui/react";
import React from "react";

import { MetricName } from "../../../graphql";
import QuickStatsBase from "./QuickStatsBase";
import useQuickStat from "./useQuickStat";

/**
 * Quick stats with associated GraphQL queries
 */
const QuickStats: React.FC<SystemProps> = ({ ...styles }) => {
  const totalInterviewsStats = useQuickStat(MetricName.TotalInterviews);
  const talkRatioStats = useQuickStat(MetricName.CandidateTalkRatio);
  const questionsAskedStats = useQuickStat(MetricName.QuestionsAsked);

  return (
    <QuickStatsBase
      {...styles}
      numInterviews={totalInterviewsStats.value}
      talkRatio={talkRatioStats.value}
      talkRatioBenchmark={talkRatioStats.benchmarkRange}
      questionsAsked={questionsAskedStats.value}
      questionsAskedBenchmark={questionsAskedStats.benchmarkRange}
      loading={
        totalInterviewsStats.loading ||
        talkRatioStats.loading ||
        questionsAskedStats.loading
      }
    />
  );
};

export default QuickStats;
