const QUESTION_POSTFIX = "asked";

export const createQuestionLabel = (speakerLabel: string): string => {
  return `${speakerLabel || "-"}${" "}${QUESTION_POSTFIX} `;
};

export type GroupType = "action items" | "questions" | "other";

export type SelectedGroupType =
  | GroupType
  | ("action items-active" | "questions-active" | "other-active");
export const trackerGroupType = (name: string): GroupType => {
  const lowerName = name.toLowerCase();
  if (lowerName === "questions") return "questions";
  if (lowerName === "action items") return "action items";
  return "other";
};
