import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const PlugConnected = (props: IconProps): JSX.Element => (
  <Icon {...props} viewBox="0 0 16 16">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2 14L3.66667 12.3333M12.3333 3.66667L14 2M6.66667 7.33333L5.33333 8.66667M8.66667 9.33333L7.33333 10.6667M4.6667 8L8.00004 11.3333L7.00004 12.3333C6.78248 12.5581 6.52238 12.7373 6.23487 12.8605C5.94737 12.9837 5.63823 13.0484 5.32545 13.051C5.01267 13.0535 4.70251 12.9938 4.41304 12.8753C4.12358 12.7567 3.8606 12.5818 3.63942 12.3606C3.41825 12.1394 3.2433 11.8765 3.12477 11.587C3.00625 11.2975 2.94651 10.9874 2.94905 10.6746C2.95159 10.3618 3.01635 10.0527 3.13956 9.76516C3.26277 9.47766 3.44197 9.21756 3.66671 9L4.6667 8ZM11.3333 8L8 4.66666L9 3.66666C9.21756 3.44193 9.47766 3.26273 9.76516 3.13952C10.0527 3.01631 10.3618 2.95155 10.6746 2.94901C10.9874 2.94647 11.2975 3.00621 11.587 3.12473C11.8765 3.24326 12.1394 3.4182 12.3606 3.63938C12.5818 3.86056 12.7567 4.12354 12.8753 4.413C12.9938 4.70247 13.0535 5.01262 13.051 5.3254C13.0484 5.63818 12.9837 5.94733 12.8605 6.23483C12.7373 6.52234 12.5581 6.78244 12.3333 7L11.3333 8Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Icon>
);

export default PlugConnected;
