export type BarDataPoint = {
  date: string;
  candidate: number;
  interviewer: number;
};

export enum DisplayInterval {
  Hour = "HOUR",
  Day = "DAY",
  Week = "WEEK",
  Month = "MONTH",
}

export type RadialBarDataPoint = {
  name: string;
  count: number;
  fill?: string;
  label?: boolean;
};
