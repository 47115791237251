import { Box, Flex, Link, StyleProps } from "@chakra-ui/react";
import React from "react";

import { ExpandableText } from "../../../../components";
import { formatDuration } from "../../../../utils/datetime";
import MonospacedText from "../../MonospacedText";
import { Topic } from "./types";

export type ExternalRecordingTopicProps = StyleProps & {
  topic: Topic;
  bg?: Pick<StyleProps, "bg">;
  seek: (time: number) => void;
};

const ExternalRecordingTopic: React.FC<ExternalRecordingTopicProps> = ({
  topic,
  bg = "gray.50",
  seek,
}) => {
  if (!topic.description) return null;

  const textProps: StyleProps = {
    fontWeight: "medium",
    fontSize: "sm",
  };

  return (
    <>
      {/* TODO: Does clicking a time stamp (which looks like a link)
          skip to that part of the recording using ?t= query param on
          the current URL? If not, replace this with <Text> instead of <Link>
      */}
      <Flex alignItems="center" pl="5" pr="2" bg={bg} {...textProps}>
        <Link onClick={() => seek(topic.time)}>
          <MonospacedText text={formatDuration(topic.time)} />
        </Link>
      </Flex>

      <Box color="gray.900" {...textProps} py="5" pr="5" bg={bg}>
        <ExpandableText title={topic.description} />
      </Box>
    </>
  );
};

export default ExternalRecordingTopic;
