import { validate } from "uuid";

import { stringToTitleCase } from "../../utils/string";
import { BreadcrumbProps } from "./types";

/**
 * - **normal** - an unremarkable URL segment
 * - **collection** - a collection-level segment but not the current one, as
 * identified by it having a UUID immediately following it and there being
 * additional UUID's later in the path
 * - **current-collection** - the last collection-level segment in the path, as
 * identified by it having a UUID immediately following it and no further UUID's
 * found after that
 * - **resource** - a resource identifier in UUID format
 */
type BreadcrumbUrlSegmentType =
  | "normal"
  | "collection"
  | "current-collection"
  | "resource";

/**
 * Figure out the type of a given URL segment depending on what follows it
 * @param segments All the URL segments in the path
 * @param segment The segment under consideration
 * @param index The index of the given segment in the array
 */
const getSegmentType = (
  segments: string[],
  segment: string,
  index: number
): BreadcrumbUrlSegmentType => {
  const next = segments.length > index + 1 ? segments[index + 1] : undefined;
  const additionalUUID = segments.find((s, i) => validate(s) && i > index + 1);
  if (next && validate(next)) {
    if (!additionalUUID) return "current-collection";
    if (additionalUUID) return "collection";
  }
  if (validate(segment)) return "resource";
  return "normal";
};

/**
 * Process the breadcrumb title to use the appropriate case
 * @param segment URL segment
 * @param url The URL to use
 * @param order What position to place this breadcrumb in
 * @param type Type of URL segment
 */
const createBreadcrumb = (
  segment: string,
  url: string,
  order: number,
  type: BreadcrumbUrlSegmentType
): BreadcrumbProps => {
  return {
    title: type === "resource" ? segment : stringToTitleCase(segment),
    url,
    order,
  };
};

/**
 * Generate breadcrumbs from a given URL
 * @param path URL path to generate breadcrumbs from
 * @param showHome Whether to show Home (or Admin if in the admin UI) as the
 * first breadcrumb
 */
export const getBreadcrumbs = (
  path: string,
  showHome = true
): BreadcrumbProps[] => {
  const segments = path.split("/").filter((s) => s);
  const breadcrumbs: BreadcrumbProps[] = [];
  let url = "";
  segments.forEach((segment, order) => {
    const type = getSegmentType(segments, segment, order);
    const s = type === "current-collection" ? `${segment}s` : segment;
    breadcrumbs.push(createBreadcrumb(s, `${url}/${s}`, order, type));
    url += `/${segment}`;
  });
  return breadcrumbs;
};
