/* eslint-disable react/no-danger */
import { Box, Flex, Icon, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { MdGroups, MdPerson, MdWork } from "react-icons/md";

import { defaultPillProps, RouterLink, StarRating } from "../../../components";
import {
  formatDateSeconds,
  formatDuration,
  formatRelativeDate,
  MS_PER_DAY,
} from "../../../utils/datetime";
import { escapeSearchResults } from "../../../utils/escape";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import useCurrentUser from "../../hooks/useCurrentUser";
import MonospacedText from "../MonospacedText";
import { SearchResultsAnalytics } from "./SearchResultAnalytics";
import { SearchHit } from "./types";

export const SearchResultRefresh: React.FC<{ hit: SearchHit }> = ({ hit }) => {
  const currentUser = useCurrentUser();
  const sendGAEvent = useSendGAEvent();

  return (
    <Flex
      color="gray.500"
      borderBottom="1px"
      borderColor="border"
      fontSize="sm"
      borderRadius="md"
      py={6}
      flexDirection="column"
      minW="100%"
    >
      <RouterLink
        to={`/interview/${hit.callId}`}
        dangerouslySetInnerHTML={{
          __html: escapeSearchResults(hit.name || "Interview"),
        }}
        onClick={() => sendGAEvent("follow_link", "search", "call")}
        fontSize="lg"
        mt="2"
      />
      {hit.candidate && (
        <Flex alignItems="center">
          <Icon as={MdPerson} mt="-2px" mr="1" />
          <RouterLink
            to={`/candidate/${hit.candidate.id}`}
            dangerouslySetInnerHTML={{
              __html: escapeSearchResults(
                hit.candidate.name ||
                  hit.candidate.phoneNumber ||
                  hit.candidate.email ||
                  "Candidate"
              ),
            }}
            onClick={() => sendGAEvent("follow_link", "search", "candidate")}
          />
        </Flex>
      )}
      <Flex alignItems="center">
        <Icon as={MdGroups} mt="-2px" mr="1" />
        {hit.interviewers?.map((interviewer, index) => (
          <React.Fragment key={interviewer.id}>
            <span
              dangerouslySetInnerHTML={{
                __html: escapeSearchResults(interviewer.name),
              }}
            />
            {hit.interviewers && index < hit.interviewers.length - 1 && ", "}
          </React.Fragment>
        ))}
      </Flex>
      {hit.position && (
        <Flex alignItems="center">
          <Icon as={MdWork} mt="-2px" mr="1" />
          <RouterLink
            to={`/position/${hit.position.id}`}
            dangerouslySetInnerHTML={{
              __html: escapeSearchResults(hit.position.displayTitle),
            }}
            onClick={() => sendGAEvent("follow_link", "search", "position")}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            display="inline-block"
            width="90%"
          />
        </Flex>
      )}
      {hit.stage && (
        <Text as="span" fontWeight="bold">
          {` (${hit.stage})`}
        </Text>
      )}
      <Tooltip
        label={formatDateSeconds(hit.startTime)}
        aria-label={formatDateSeconds(hit.startTime)}
        placement="bottom-start"
      >
        {`${formatRelativeDate(
          new Date(hit.startTime * 1000).toISOString(),
          MS_PER_DAY * 30
        )}`}
      </Tooltip>

      {hit.rating && currentUser.organization.showStarRatings ? (
        <StarRating
          rating={hit.rating}
          readOnly
          ratingDescriptions={currentUser.organization.ratingDescriptions}
        />
      ) : null}
      <SearchResultsAnalytics hit={hit} />
      {hit.notes.length > 0 && (
        <Box mt={2} ml="86px">
          {hit.notes.map((note: any, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <Flex mt={2} key={index}>
              <Box width="40px" mr={6}>
                <RouterLink
                  to={`/interview/${hit.callId}?t=${note.time}`}
                  onClick={() => sendGAEvent("follow_link", "search", "note")}
                  whiteSpace="nowrap"
                >
                  <MonospacedText text={formatDuration(note.time)} />
                </RouterLink>
              </Box>
              <Box
                as="span"
                {...(note.type === "CUE" ? defaultPillProps : {})}
                dangerouslySetInnerHTML={{
                  __html: escapeSearchResults(note.text),
                }}
              />
            </Flex>
          ))}
        </Box>
      )}
      {hit.transcriptSegments.length > 0 && (
        <Box
          mt={2}
          ml="86px"
          borderTop={hit.notes.length > 0 ? "1px" : "0"}
          borderColor="border"
        >
          {hit.transcriptSegments.map((segment, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Flex mt={2} key={index}>
              <Box width="40px" mr={6}>
                <RouterLink
                  to={`/interview/${hit.callId}?t=${segment.startTime}`}
                  onClick={() =>
                    sendGAEvent("follow_link", "search", "transcript")
                  }
                  whiteSpace="nowrap"
                >
                  <MonospacedText text={formatDuration(segment.startTime)} />
                </RouterLink>
              </Box>
              <Box>
                <strong>[{segment.speaker}]</strong>
                <Box
                  as="span"
                  ml={2}
                  dangerouslySetInnerHTML={{
                    __html: escapeSearchResults(segment.text),
                  }}
                />
              </Box>
            </Flex>
          ))}
        </Box>
      )}
    </Flex>
  );
};
