import { Box, Button, ButtonGroup, Flex, Select } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {} from "react-icons/io";
import { MdList } from "react-icons/md";

import {
  ConfirmModal,
  LoadingIndicator,
  RouterLink,
  SortableTable,
  useToast,
} from "../../../../components";
import { EmptyStateWithIcon } from "../../../../components/EmptyStates";
import { formatRelativeDate } from "../../../../utils/datetime";
import {
  Ats,
  RedactCandidateItem,
  useOrganizationRedactionCandidatesQuery,
  useRedactScheduledCandidatesMutation,
} from "../../../graphql";

interface DataRetentionCandidatesProps {
  organization: {
    ats?: Ats | null;
    enableRetentionSinceCandidateHired: boolean;
    retentionDaysSinceCandidateHired: number;
    enableRetentionSinceCandidateRejected: boolean;
    retentionDaysSinceCandidateRejected: number;
    enableRetentionSinceLastInterview: boolean;
    retentionDaysSinceLastInterview: number;
    enableRetentionSinceInterview: boolean;
    retentionDaysSinceInterview: number;
    enableRedactionForGreenhouse: boolean;
    enableRedactionForLever: boolean;
    retentionNotificationDays?: (string | null)[] | null;
    organizationRetentionUser: {
      userId: string;
    }[];
    retentionEmailSendTime: string;
    retentionEmailSendTimezone: string;
  };
}

const DataRetentionCandidatesPage: React.FC<DataRetentionCandidatesProps> = ({
  organization,
}) => {
  const toast = useToast();
  const [countValue, setCountValue] = useState(50);
  const [isOpen, setIsOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const { data, loading, error } = useOrganizationRedactionCandidatesQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      count: countValue,
    },
  });
  const candidates = data?.candidatesToRedact?.candidates || [];
  const totalCount = data?.candidatesToRedact?.count;

  const [redactScheduledCandidates, { loading: redactScheduledLoading }] =
    useRedactScheduledCandidatesMutation({
      onCompleted: (data) => {
        toast({
          title: "Success",
          description:
            "Request is being processed, please reload the page in a few minutes.",
          status: "success",
        });
      },
      onError: (err) => {
        toast({
          title: "Error",
          description: err.message,
          status: "error",
        });
      },
    });

  const handleClick = (
    keep: boolean,
    all: boolean,
    modalText: string
  ): void => {
    setIsOpen(true);
    setModalText(modalText);
  };

  useEffect(() => {
    if (error) {
      toast({
        title: "Error loading candidates",
        description: error.message,
        status: "error",
      });
    }
  }, [error]);

  if (error) {
    return null;
  }

  if (
    organization.enableRetentionSinceCandidateHired === false &&
    organization.enableRetentionSinceCandidateRejected === false &&
    organization.enableRetentionSinceLastInterview === false
  ) {
    return (
      <EmptyStateWithIcon
        heading="Candidate section disabled"
        icon={MdList}
        Text={() => (
          <Box>
            You have not enabled candidate deletion settings. The list of
            candidates to be deleted will be shown here after enabling and
            setting candidate data retention timelines.{" "}
          </Box>
        )}
        padded={false}
      />
    );
  }

  if (!loading && candidates.length === 0) {
    return (
      <EmptyStateWithIcon
        heading="No candidates found"
        icon={MdList}
        Text={() => <Box>No candidates were found. </Box>}
        padded={false}
      />
    );
  }

  return (
    <Box my={4}>
      {loading && <LoadingIndicator />}
      {candidates && (
        <SortableTable
          width="100%"
          disableSort
          checkboxColumnWidth="10px"
          disableCheckboxes
          renderSelectedRowActions={(rows) => {
            return (
              <Flex alignItems="center" justifyContent="flex-end" w="100%">
                <ButtonGroup my="2">
                  <Select
                    size="sm"
                    name="count"
                    m="1"
                    mt={4}
                    width="220px"
                    defaultValue={countValue}
                    onChange={(option) => {
                      setCountValue(parseInt(option.target.value));
                    }}
                  >
                    <option key="10" value="10">
                      Show 10 candidates
                    </option>
                    <option key="50" value="50">
                      Show 50 candidates
                    </option>
                    <option key="100" value="100">
                      Show 100 candidates
                    </option>
                  </Select>
                  <ConfirmModal
                    modalBodyText={modalText}
                    isOpen={isOpen}
                    onCancel={() => {
                      setIsOpen(false);
                    }}
                    onConfirm={() => {
                      redactScheduledCandidates({});
                      setIsOpen(false);
                    }}
                  />
                  <Button
                    mt={4}
                    size="sm"
                    type="submit"
                    variant="danger"
                    isLoading={loading || redactScheduledLoading}
                    onClick={() =>
                      handleClick(
                        false,
                        true,
                        ` Are you sure you want to delete ${
                          totalCount || 0
                        } candidates?`
                      )
                    }
                  >
                    Delete all {totalCount || 0} candidates
                  </Button>
                </ButtonGroup>
              </Flex>
            );
          }}
          columns={[
            {
              Header: "Candidate",
              Cell: ({
                row: { original: candidate },
              }: {
                row: { original: RedactCandidateItem };
              }) => {
                if (candidate.id) {
                  return (
                    <Box>
                      <RouterLink to={`/candidate/${candidate.id}`}>
                        {candidate.fullName}
                      </RouterLink>
                    </Box>
                  );
                }
                return null;
              },
            },
            {
              Header: "Hired Date",
              Cell: ({
                row: { original: candidate },
              }: {
                row: { original: RedactCandidateItem };
              }) => <>{formatRelativeDate(candidate?.hiredAt) ?? ""}</>,
            },
            {
              Header: "Rejected Date",
              Cell: ({
                row: { original: candidate },
              }: {
                row: { original: RedactCandidateItem };
              }) => <>{formatRelativeDate(candidate?.rejectedAt) ?? ""}</>,
            },
            {
              Header: "Last Interview Date",
              Cell: ({
                row: { original: candidate },
              }: {
                row: { original: RedactCandidateItem };
              }) => <>{formatRelativeDate(candidate?.lastInterviewAt) ?? ""}</>,
            },
            {
              Header: "Interviews",
              Cell: ({
                row: { original: candidate },
              }: {
                row: { original: RedactCandidateItem };
              }) => <>{candidate?.numInterviews ?? ""}</>,
            },
          ]}
          data={candidates}
          initialSort={{ id: "id", desc: true }}
        />
      )}
    </Box>
  );
};

export default DataRetentionCandidatesPage;
