import { Button, Link } from "@chakra-ui/react";
import React from "react";
import { HiArrowTopRightOnSquare } from "react-icons/hi2";

const ViewCandidateScoresButton: React.FC<{
  candidateId: string;
  size?: "md" | "sm";
}> = ({ candidateId, size = "md" }) => {
  const small = size === "sm";

  return (
    <Button
      as={Link}
      isExternal
      href={`/candidate/${candidateId}`}
      variant="link"
      leftIcon={<HiArrowTopRightOnSquare size={small ? 16 : 20} />}
      iconSpacing={small ? "1" : "1.5"}
      fontSize={small ? "xs" : "sm"}
      fontWeight="medium"
    >
      View all candidate scores
    </Button>
  );
};
export default ViewCandidateScoresButton;
