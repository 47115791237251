import { useCallback, useState } from "react";

import { setCaretPosition } from "../utils/dom";
import useListener from "./useListener";

interface Coordinates {
  x: number;
  y: number;
}
interface TriggerState {
  isTriggered: boolean;
  node?: Node;
  caretPosition?: number;
  caretCoordinates?: Coordinates;
  start?: number;
  end?: number;
  value?: string;
}

// inspired by https://stackoverflow.com/a/6847328/7970353
function getCaretCoordinates(): Coordinates | undefined {
  const selection = document.getSelection();
  if (!selection?.rangeCount) {
    return;
  }
  const range = selection.getRangeAt(0);
  range.collapse(true);
  const rect = range.getClientRects().item(0);
  let x = rect?.left ?? 0;
  let y = ((rect?.bottom ?? 0) + (rect?.top ?? 0)) / 2;
  // Fall back to inserting a temporary element
  if (x === 0 && y === 0) {
    const span = document.createElement("span");
    if (span.getClientRects) {
      // Ensure dimensions w/ zero-width space character
      span.appendChild(document.createTextNode("\u200b"));
      range.insertNode(span);
      const rect = span.getClientRects()[0];
      x = rect.left;
      y = rect.top;
      const spanParent = span.parentNode;
      spanParent?.removeChild(span);
      spanParent?.normalize();
    }
  }
  return { x, y };
}

function getCaretContext(element: HTMLElement): [number, Node] | undefined {
  const selection = document.getSelection();
  if (!selection?.anchorNode) {
    return;
  }
  const range = selection.getRangeAt(0);
  const preCaretRange = range.cloneRange();
  preCaretRange.selectNodeContents(selection.anchorNode);
  preCaretRange.setEnd(range.endContainer, range.endOffset);
  return [preCaretRange.toString().length, selection.anchorNode];
}

const findTriggerBoundaries = (
  node: Node,
  caretPosition: number,
  triggerKey: string
): { start: number; end: number } | undefined => {
  if (!node.textContent) {
    return;
  }
  const beforeCaret = node.textContent.substring(0, caretPosition);
  let start: number | undefined;
  for (let x = beforeCaret.length; x >= 0; x -= 1) {
    if (beforeCaret[x] === triggerKey) {
      if (x === 0 || /\s/.test(beforeCaret[x - 1])) {
        start = x;
      }
    }
    if (/\s/.test(beforeCaret[x])) {
      break;
    }
  }
  if (start === undefined) {
    return;
  }

  const afterCaret = node.textContent.substring(caretPosition);
  let end = caretPosition;
  for (let x = 0; x <= afterCaret.length; x += 1) {
    end = caretPosition + x;
    const character = afterCaret[x];
    if (!character || /\s/.test(character)) {
      break;
    }
  }

  return { start, end };
};

const replaceText = (
  triggerState: TriggerState,
  replacementText: string
): void => {
  const { node, start, end, caretPosition } = triggerState;
  if (
    !node?.textContent ||
    start === undefined ||
    end === undefined ||
    caretPosition === undefined
  ) {
    return;
  }
  // remove the existing trigger text
  // eslint-disable-next-line no-param-reassign
  node.textContent =
    node.textContent.substring(0, start) + node.textContent.substring(end);

  if (!node.textContent) {
    node.textContent = " ";
  }
  // move caret position to trigger start
  setCaretPosition(node, start);
  // insertText command supports "Undo"
  document.execCommand("insertText", false, replacementText);
};

const useInputTrigger = (
  ref: React.MutableRefObject<HTMLElement | null>,
  triggerKey: string
): [TriggerState, (replacementText: string) => void] => {
  const defaultState = {
    isTriggered: false,
  };
  const [state, setState] = useState<TriggerState>(defaultState);
  const resetState = (): void => setState(defaultState);

  const handleEvent = useCallback(() => {
    if (!ref.current) {
      return;
    }
    const context = getCaretContext(ref.current);
    if (!context) {
      return;
    }
    const [caretPosition, node] = context;
    const boundaries = findTriggerBoundaries(node, caretPosition, triggerKey);
    if (boundaries && node.textContent) {
      const { start, end } = boundaries;
      const value = node.textContent.substring(start, end);
      if (state.isTriggered) {
        setState({
          ...state,
          node,
          caretPosition,
          start,
          end,
          value,
        });
      } else {
        setState({
          isTriggered: true,
          node,
          caretPosition,
          caretCoordinates: getCaretCoordinates(),
          start,
          end,
          value,
        });
      }
    } else if (state.isTriggered) {
      resetState();
    }
  }, [state]);

  useListener(ref, "input", handleEvent);
  useListener(ref, "blur", () => resetState());
  // useListener(ref, "click", handleEvent);

  const replace = (replacementText: string): void => {
    replaceText(state, replacementText);
    resetState();
  };

  return [state, replace];
};

export default useInputTrigger;
