import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import LogRocket from "logrocket";
import React from "react";
import { HiOutlineFilm, HiOutlineVideoCamera } from "react-icons/hi";
import { HiOutlineSquare3Stack3D } from "react-icons/hi2";
import { IoVideocamOffSharp } from "react-icons/io5";
import { MdExpandLess, MdExpandMore } from "react-icons/md";

import {
  GreenhouseRatingButton,
  LeverRatingButton,
  RouterLink,
} from "../../../components";
import ScheduledInterviewsLaunchButton from "../../../components/ScheduledInterviewsLaunchButton/ScheduledInterviewsLaunchButtonBeta";
import { useIsSmallScreen } from "../../../hooks/useIsSmallScreen";
import { formatRelativeDate, MS_PER_DAY } from "../../../utils/datetime";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { ScheduledInterviewListItemFragment } from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../../hooks/useCurrentUser";
import DeleteInterviewButton from "../Menu/DeleteInterviewButton";
import InterviewMenu from "../Menu/InterviewMenu";
import ScheduledInterviewMenu from "../Menu/ScheduledInterviewMenu";
import ScoringButton from "../ScoringButton/ScoringButton";
import CopyAiNotesButton from "./CopyAiNotesButton";
import InterviewChapterMobile from "./InterviewChapterMobile";
import InterviewChapters from "./InterviewChapters";
import ReactionsGroup from "./ReactionsGroup";
import Thumbnail from "./Thumbnail";
import { CandidateCall, InterviewMenuOptions } from "./types";
import { getCallName, getScheduledCallName } from "./utils";

interface BaseListItemProps {
  listPosition: number;
  currentUserNotesOnly: boolean;
  onShowHighlights?(): void;
}

interface CallListItemProps extends BaseListItemProps {
  call: CandidateCall;
  scheduledInterview?: never;
}

interface ScheduledInterviewListItemProps extends BaseListItemProps {
  scheduledInterview: ScheduledInterviewListItemFragment;
  call?: never;
  setActiveHighlight?: never;
}

type InterviewListItemProps =
  | CallListItemProps
  | ScheduledInterviewListItemProps;

const InterviewListItem: React.FC<InterviewListItemProps> = ({
  call,
  scheduledInterview,
  listPosition,
  currentUserNotesOnly,
  onShowHighlights,
}) => {
  const currentUser = useCurrentUser();
  const sendGAEvent = useSendGAEvent();
  const isSmallScreen = useIsSmallScreen();

  const participants = scheduledInterview
    ? scheduledInterview.scheduledInterviewers.map((s) => {
        if (s.user && s.user.id === currentUser.id) {
          return "You";
        }
        return s.user ? s.user.fullName : s.name || "name unavailable";
      })
    : [];
  const { allowAiNotes } = currentUser.organization;
  const showChapters = useFeatureFlag("chapters:v1:show") && allowAiNotes;
  const chaptersDisclosure = useDisclosure({
    onOpen: () => {
      sendGAEvent("view_interview_chapters", "candidate");
      LogRocket.track("view_interview_chapters");
    },
  });
  const isPlayable =
    call && (!!call.streamableAudio?.url || !!call.streamableVideo?.url);

  const onLinkClick =
    (from: string): (() => void) =>
    () =>
      call ? sendGAEvent("view_interview", "candidate", from) : undefined;

  return (
    <Box
      position="relative"
      borderRadius="xl"
      transition="0.3s background"
      px={isSmallScreen ? 0 : 4}
      mb={7}
    >
      <Flex direction={isSmallScreen ? "column" : "row"}>
        <Box width={isSmallScreen ? "100%" : "256px"} maxW="600">
          <RouterLink
            to={call ? `/interview/${call.id}` : "#"}
            onClick={onLinkClick("thumbnail")}
          >
            <Thumbnail
              size="auto"
              showUpcomingOverlay={!!scheduledInterview}
              imageUrl={
                call
                  ? call.thumbnailImageUrl
                  : "/static/images/upcoming-interview-background.png"
              }
              audioOnly={
                call &&
                !!call.streamableAudio?.url &&
                !call.streamableVideo?.url
              }
              isPlayable={isPlayable}
              time={call?.duration}
              fullVideoVariant
              timeFontSize={["lg", null, "xl"]}
            />
          </RouterLink>
        </Box>
        <Flex
          direction="column"
          align="start"
          flex="1"
          ml={isSmallScreen ? "0" : "6"}
          gap={isSmallScreen ? "0" : "2"}
        >
          <Box
            fontSize="sm"
            color="gray.600"
            fontWeight="500"
            mt={isSmallScreen ? "4" : "0"}
            justifyContent="space-between"
            width="100%"
            display="flex"
          >
            <Flex display="inline-block" fontSize="sm">
              {call
                ? formatRelativeDate(call.startTime ?? call.createdAt)
                : formatRelativeDate(
                    scheduledInterview.scheduledStart,
                    21 * MS_PER_DAY
                  )}
            </Flex>
            <Flex display="inline-block">
              {isSmallScreen && (
                <>
                  {call && isPlayable && (
                    <InterviewMenu
                      call={call}
                      positionId={call.positionId}
                      menuOptions={[
                        InterviewMenuOptions.SHARE,
                        InterviewMenuOptions.DELETE,
                      ]}
                      listPosition={listPosition}
                      buttonSize="xs"
                    />
                  )}
                  {scheduledInterview && !isSmallScreen && (
                    <ScheduledInterviewMenu
                      scheduledInterview={scheduledInterview}
                      currentUser={currentUser}
                      buttonSize="xs"
                    />
                  )}
                </>
              )}
            </Flex>
          </Box>
          <Box fontWeight="600">
            <RouterLink
              to={call ? `/interview/${call.id}` : "#"}
              onClick={onLinkClick("interview_title")}
              variant="darkGray"
              _hover={{ textDecoration: "none" }}
              fontSize="20px"
            >
              {call
                ? getCallName(call)
                : getScheduledCallName(scheduledInterview)}
            </RouterLink>
          </Box>
          <Flex direction={["column", null, "row"]} align="start">
            {call && call.interviewers.length > 0 && (
              <Box mr="3" fontSize="sm" color="gray.800">
                {call?.interviewers.map((i) => i.fullName).join(", ")}
              </Box>
            )}
            {participants.length > 0 && (
              <Text
                className="content"
                fontSize="sm"
                marginTop="1"
                noOfLines={2}
              >
                {participants.join(", ")}
              </Text>
            )}
            {call?.isInterviewer && (
              <Flex mt={{ base: "1", lg: "0" }} mb={3} gap="2">
                {call.greenhouseScorecardLinkWithId && (
                  <GreenhouseRatingButton
                    greenhouseScorecardLink={call.greenhouseScorecardLinkWithId}
                    rating={call.greenhouseUserRating}
                    variant={!isSmallScreen ? "ghost" : undefined}
                  />
                )}
                {call.greenhouseUserRating &&
                  call.leverInterviewLinkWithId &&
                  " "}
                {call.leverInterviewLinkWithId && (
                  <LeverRatingButton
                    leverInterviewLink={call.leverInterviewLinkWithId}
                    variant={!isSmallScreen ? "ghost" : undefined}
                  />
                )}
                {isSmallScreen && <ScoringButton call={call} />}
              </Flex>
            )}
          </Flex>
          {!isSmallScreen && call && <ScoringButton call={call} />}
          <Flex>
            {call && (
              <ReactionsGroup
                callId={call.id}
                notes={call.notes}
                currentUserNotesOnly={currentUserNotesOnly}
                borderColor="gray.50"
              />
            )}
            {call?.notes.length === 0 && <Box w={2} />}
            {showChapters && call && (
              <ChapterToggle
                disclosure={chaptersDisclosure}
                isSmallScreen={isSmallScreen}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex
        position={["relative", null, "absolute"]}
        flex={["1 0 100%", null]}
        alignItems="start"
        justifyContent="end"
        mt={["3", null, "0"]}
        top={[null, null, "0"]}
        right={[null, null, "6"]}
      >
        {call && !isSmallScreen && (
          <>
            {
              <RouterLink
                to={`/interview/${call.id}`}
                onClick={onLinkClick("icon_button")}
                variant="gray"
                lineHeight="0"
                _hover={{ textDecoration: "none" }}
              >
                <Tooltip label="View interview">
                  <IconButton
                    variant="icon"
                    aria-label="View interview"
                    icon={
                      <HiOutlineVideoCamera strokeWidth="1.5px" size={20} />
                    }
                  />
                </Tooltip>
              </RouterLink>
            }
            {isPlayable && (
              <Tooltip label="View highlights">
                <IconButton
                  variant="icon"
                  aria-label="View highlights"
                  disabled={call.highlights.length === 0}
                  ml={2}
                  icon={<HiOutlineFilm strokeWidth="1.5px" size={20} />}
                  onClick={onShowHighlights}
                />
              </Tooltip>
            )}
            {isPlayable && <CopyAiNotesButton call={call} />}
            {!isPlayable && <DeleteInterviewButton call={call} />}
            {isPlayable && (
              <InterviewMenu
                call={call}
                positionId={call.positionId}
                menuOptions={[
                  InterviewMenuOptions.SHARE,
                  InterviewMenuOptions.DELETE,
                ]}
                listPosition={listPosition}
              />
            )}
          </>
        )}
        {scheduledInterview && !isSmallScreen && (
          <Flex marginTop="3" alignItems="center">
            <Box flex="1 1 auto">
              {scheduledInterview.isInterviewer && (
                <ScheduledInterviewsLaunchButton
                  scheduledInterview={scheduledInterview}
                  showMarginLeft={false}
                  boxProps={{
                    mb: {},
                    justifyContent: "flex-start",
                    alignItems: "normal",
                  }}
                />
              )}
            </Box>
            <ScheduledInterviewMenu
              scheduledInterview={scheduledInterview}
              currentUser={currentUser}
            />
          </Flex>
        )}
        {scheduledInterview?.isInterviewer && isSmallScreen && (
          <Flex marginTop="3" alignItems="center" width="100%">
            <ScheduledInterviewsLaunchButton
              scheduledInterview={scheduledInterview}
              showMarginLeft={false}
              forceFullWidth
              boxProps={{
                mb: {},
                justifyContent: "flex-start",
                alignItems: "normal",
                width: "100%",
              }}
            />
          </Flex>
        )}
        {scheduledInterview?.recordingDisabled && (
          <Flex marginTop="3" alignItems="center">
            <Icon as={IoVideocamOffSharp} marginRight="2" color="orange.500" />
            <Text
              fontSize="sm"
              color="gray.600"
              data-testid="scheduled-interview-recording-disabled-text"
            >
              Interview will not record
            </Text>
          </Flex>
        )}
      </Flex>

      {showChapters && call && isSmallScreen && chaptersDisclosure.isOpen && (
        <InterviewChapterMobile
          call={call}
          isOpen={chaptersDisclosure.isOpen}
          onClose={chaptersDisclosure.onClose}
          thumbnailUrl={call.thumbnailImageUrl}
        />
      )}

      {showChapters && call && !isSmallScreen && (
        <InterviewChapters
          call={call}
          isOpen={chaptersDisclosure.isOpen}
          thumbnailUrl={call.thumbnailImageUrl}
        />
      )}
    </Box>
  );
};

type ChapterToggleProps = {
  disclosure: ReturnType<typeof useDisclosure>;
  isSmallScreen: boolean;
};

const ChapterToggle: React.FC<ChapterToggleProps> = ({
  disclosure,
  isSmallScreen,
}) => {
  return (
    <Button
      aria-label="Interview Summary"
      data-tour-id="candidate-page-interview-summary"
      size="sm"
      variant="link"
      fontWeight={500}
      onClick={disclosure.onToggle}
      textTransform="unset"
      iconSpacing={1}
      ml={6}
      rightIcon={
        <Icon
          as={
            disclosure.isOpen
              ? MdExpandLess
              : isSmallScreen
              ? HiOutlineSquare3Stack3D
              : MdExpandMore
          }
          boxSize="5"
        />
      }
    >
      Interview summary
    </Button>
  );
};

export default InterviewListItem;
