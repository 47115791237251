import { Alert, Button, Link, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { MetricName } from "../../graphql";
import AnalyticsHeroFrame from "./AnalyticsHeroFrame";
import { areFiltersOutdated, isScorecardMetric } from "./utils";

const AnalyticsHeroContent: React.FC<{
  analyticsHero: AnalyticsHeroType;
  metric: MetricName;
}> = ({ analyticsHero, metric }) => {
  const [requestInterviewStats, setRequestInterviewStats] = useState(false);
  const navigate = useNavigate();
  const sendGAEvent = useSendGAEvent();
  const filtersOutdated = areFiltersOutdated(window.location.search);
  switch (analyticsHero) {
    case "error":
      return (
        <AnalyticsHeroFrame imgSrc="/static/images/analytics_error_state.png">
          <Text>
            We’re having trouble retrieving your report. Please try again.
          </Text>
        </AnalyticsHeroFrame>
      );
    case "coming-soon":
      return (
        <AnalyticsHeroFrame imgSrc="/static/images/analytics_coming_soon.png">
          <Text>
            We’re working on something great but it’s not ready quite yet.
            <br />
            <br />
            If you’d like to see your interview stats, let us know and we’ll get
            back to you when it’s available.
            <br />
            <br />
            {!requestInterviewStats ? (
              <Button
                onClick={() => {
                  sendGAEvent("request_interview_stats", "analytics");
                  setRequestInterviewStats(true);
                }}
              >
                Request feature
              </Button>
            ) : (
              <Alert
                as="span"
                status="success"
                variant="subtle"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
              >
                Thank you for your request.
              </Alert>
            )}
          </Text>
        </AnalyticsHeroFrame>
      );
    case "no-data-because-of-no-interviews":
      return (
        <AnalyticsHeroFrame imgSrc="/static/images/analytics_no_data_because_no_interviews.svg">
          <Text>
            Insights are just a few interviews away. Those graphs will pop after
            your teams do more interviews!
          </Text>
        </AnalyticsHeroFrame>
      );
    case "no-data-because-of-no-ats-interviews":
      return (
        <AnalyticsHeroFrame imgSrc="/static/images/analytics_no_data_because_no_interviews.svg">
          <Text>
            No interviews found in your ATS. These graphs will pop once your
            team starts interviewing!
          </Text>
        </AnalyticsHeroFrame>
      );
    case "no-data-because-of-filters":
      return (
        <AnalyticsHeroFrame imgSrc="/static/images/analytics_no_data_because_filters.svg">
          <Text>
            {filtersOutdated
              ? "It looks like you have an outdated link."
              : "No data matches your request."}{" "}
            <Button
              variant="link"
              onClick={() =>
                navigate(`/insights/${metric.toLocaleLowerCase()}`)
              }
            >
              Try {filtersOutdated ? "resetting" : "different"} filters.
            </Button>{" "}
          </Text>
        </AnalyticsHeroFrame>
      );
    case "no-data-because-missing-ats-setup":
      return (
        <AnalyticsHeroFrame imgSrc="/static/images/analytics_coming_soon.png">
          <Text>
            No ATS found. <br /> <br />
            This page requires enabling your ATS in BrightHire. <br />
            <Link href="mailto:support@brighthire.ai" isExternal>
              Please contact your BrightHire Customer Success Manager to learn
              more.
            </Link>
          </Text>
        </AnalyticsHeroFrame>
      );
    default:
      throw new Error("unexpected AnalyticsHero value");
  }
};

export type AnalyticsHeroType =
  | "error"
  | "coming-soon"
  | "no-data-because-of-filters"
  | "no-data-because-of-no-interviews"
  | "no-data-because-of-no-ats-interviews"
  | "no-data-because-missing-ats-setup";

export const pickHeroType = (
  metric: MetricName,
  hasError: boolean,
  queriesLoadedAndNoData: boolean,
  visibleInteviewsCount: number | undefined,
  atsIsIntegrated: boolean | undefined
): AnalyticsHeroType | null => {
  const scorecardMetric = isScorecardMetric(metric);

  if (hasError) {
    return "error";
  }
  if (queriesLoadedAndNoData) {
    if (visibleInteviewsCount === 0) {
      return scorecardMetric
        ? "no-data-because-of-no-ats-interviews"
        : "no-data-because-of-no-interviews";
    }
    return "no-data-because-of-filters";
  }

  if (atsIsIntegrated === false && scorecardMetric) {
    return "no-data-because-missing-ats-setup";
  }
  return null;
};

export default AnalyticsHeroContent;
