import {
  Box,
  Button,
  Flex,
  Link,
  Switch,
  Text,
  useTheme,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { LoadingIndicator, SelectDateRange } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { AnalyticsDimension } from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import AnalyticsFilter from "./AnalyticsFilter";
import AnalyticsHeroContent from "./AnalyticsHeroContent";
import AnalyticsInfoAlert from "./AnalyticsInfoAlert";
import AnalyticsInfoModal from "./AnalyticsInfoModal";
import MetricChart from "./chart/MetricChart";
import { FilterIcon } from "./icons";
import LabeledChartSelect from "./LabeledChartSelect";
import { MetricConfig } from "./MetricConfig";
import MetricSummary from "./MetricSummary";
import { PivotedDataPoint } from "./types";
import { AnalyticsConfig } from "./useAnalyticsConfig";
import { AnalyticsData } from "./useAnalyticsData";
import { useAnalyticsSelectTheme } from "./useAnalyticsSelectTheme";
import { mapFiltersToAlgoliaURL, scorecardMetrics } from "./utils";

export type AnalyticsMetricsContentProps = {
  analyticsConfig: AnalyticsConfig;
  analyticsData: AnalyticsData;
  flaggedFeatures: {
    genderSegmentation: boolean;
    togglePerformanceOverlay: boolean;
    drilldowns: boolean;
  };
};

const AnalyticsMetricsContent: React.FC<AnalyticsMetricsContentProps> = ({
  analyticsConfig,
  analyticsData,
  flaggedFeatures,
}) => {
  const { colors } = useTheme();
  const sendGAEvent = useSendGAEvent();
  const [selectTheme, selectStyles] = useAnalyticsSelectTheme();
  const [showFilters, setShowFilters] = useState(true);
  const [filterHeights, setFilterHeights] = useState<{ [key: string]: number }>(
    { defaultHeight: 50 }
  );
  const { metric } = analyticsConfig;
  const metricConfig = MetricConfig[metric];
  const [performanceOverlay, setPerformanceOverlay] = useState(false);
  const release2Enabled = useFeatureFlag("analytics:release2");
  const onDrilldown = (entry: PivotedDataPoint): void => {
    const url = mapFiltersToAlgoliaURL(analyticsConfig, entry, metric);
    window.open(url, "_blank");
    sendGAEvent("metric_drilldown", "analytics", metric);
  };

  // Set showfilters to true when query param source=alert and some filters are present
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get("source");
    const departments = urlParams.get("departments");
    const positions = urlParams.get("positions");
    const interviewers = urlParams.get("interviewers");
    const stages = urlParams.get("stages");
    if (
      source === "alert" &&
      (departments || positions || interviewers || stages)
    ) {
      setShowFilters(true);
    }
  }, []);

  return (
    <>
      <Flex
        flexDir="row"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Flex flexDir="row" alignItems="baseline">
          <Text fontSize="24px" fontWeight="700" color="gray.700" pr="2">
            {metricConfig.displayName}
          </Text>
          <AnalyticsInfoModal metric={metric} />
        </Flex>

        {analyticsData.filtersAvailable && (
          <Flex>
            <FiltersButton
              open={showFilters}
              toggleFilters={() => {
                sendGAEvent(
                  `filters_${!showFilters ? "open" : "closed"}`,
                  "analytics"
                );
                setShowFilters((state) => !state);
              }}
              data-tour-id="analytics-filters"
            />
            <Flex minW="148px">
              <SelectDateRange
                state={analyticsConfig.dateRange.value}
                onSelect={analyticsConfig.dateRange.setValue}
                includeLabel={false}
                selectStyles={selectStyles}
                selectTheme={selectTheme}
                rangeStyleProps={{ wrap: "nowrap" }}
                dateInputStyleProps={{
                  fontSize: "14px",
                  color: colors.gray[900],
                  borderColor: colors.gray[100],
                }}
              />
            </Flex>
          </Flex>
        )}
      </Flex>

      {scorecardMetrics.includes(metric) &&
        analyticsConfig.dateRange.value.start < new Date("2022-03-07") && (
          <AnalyticsInfoAlert status="warning" mt="4">
            Data is unavailable for this report before March 7th, 2022.
          </AnalyticsInfoAlert>
        )}

      {/* Dimensions */}
      {/* Control filter visibility with display CSS so filter queries run on load */}
      {analyticsData.filtersAvailable && (
        <Flex
          flexDir="row"
          mt="6"
          overflow={showFilters ? "visible" : "hidden"}
          opacity={showFilters ? 1 : 0}
          height={
            showFilters ? `${Math.max(...Object.values(filterHeights))}px` : "0"
          }
          transition="height 0.25s ease-in-out, opacity 0.5s ease-in-out"
        >
          <AnalyticsFilter
            dimension={AnalyticsDimension.Department}
            secondaryDimension={analyticsConfig.secondaryDimension.value}
            placeholder="All Departments"
            queryVariables={analyticsData.commonQueryVariables}
            multiSelect={analyticsConfig.departments}
            flex={`0 0 ${release2Enabled ? "25%" : "33.3%"}`}
            maxWidth={release2Enabled ? "25%" : "33.3%"}
            filterHeights={filterHeights}
            setFilterHeights={setFilterHeights}
            pr="6"
          />
          <AnalyticsFilter
            dimension={AnalyticsDimension.Position}
            secondaryDimension={analyticsConfig.secondaryDimension.value}
            placeholder="All Positions"
            queryVariables={analyticsData.commonQueryVariables}
            multiSelect={analyticsConfig.positions}
            flex={`0 0 ${release2Enabled ? "25%" : "33.3%"}`}
            maxWidth={release2Enabled ? "25%" : "33.3%"}
            filterHeights={filterHeights}
            setFilterHeights={setFilterHeights}
            pr="6"
          />
          <AnalyticsFilter
            dimension={AnalyticsDimension.Interviewer}
            secondaryDimension={analyticsConfig.secondaryDimension.value}
            placeholder="All Interviewers"
            queryVariables={analyticsData.commonQueryVariables}
            multiSelect={analyticsConfig.interviewers}
            flex={`0 0 ${release2Enabled ? "25%" : "33.3%"}`}
            maxWidth={release2Enabled ? "25%" : "33.3%"}
            filterHeights={filterHeights}
            setFilterHeights={setFilterHeights}
            pr="6"
          />
          {release2Enabled && (
            <AnalyticsFilter
              dimension={AnalyticsDimension.JobStage}
              secondaryDimension={analyticsConfig.secondaryDimension.value}
              placeholder="All Interview Stages"
              queryVariables={analyticsData.commonQueryVariables}
              multiSelect={analyticsConfig.stages}
              flex="0 0 25%"
              maxWidth={release2Enabled ? "25%" : "33.3%"}
              filterHeights={filterHeights}
              setFilterHeights={setFilterHeights}
            />
          )}
        </Flex>
      )}

      {analyticsData.heroType ? (
        <AnalyticsHeroContent
          analyticsHero={analyticsData.heroType}
          metric={metric}
        />
      ) : (
        <>
          <Box background="gray.100" minHeight="1px" maxHeight="1px" mt="0" />
          <MetricSummary
            metric={metric}
            data={analyticsData.dataPoints}
            loading={analyticsData.loading}
            primaryDimension={analyticsConfig.primaryDimension.value}
            dateRange={analyticsConfig.dateRange.value}
          />
          <Box background="gray.100" minHeight="1px" maxHeight="1px" mb="6" />

          {/* Chart Options */}
          <Flex
            data-tour-id="analytics-dimensions"
            justifyContent="space-between"
          >
            <Flex>
              <LabeledChartSelect
                testid="analytics--group-menu"
                mr="4"
                label="Group"
                flexDir="column"
                alignItems="flex-start"
                singleSelect={analyticsConfig.primaryDimension}
              />
              {flaggedFeatures.genderSegmentation && (
                <LabeledChartSelect
                  data-testid="analytics--segment-menu"
                  minW="145px"
                  flexDir="column"
                  alignItems="stretch"
                  mr="4"
                  label="Segment"
                  singleSelect={analyticsConfig.secondaryDimension}
                />
              )}
              {flaggedFeatures.togglePerformanceOverlay && (
                <Flex
                  mr="4"
                  alignItems="flex-start"
                  height="40px"
                  flexDir="column"
                >
                  <Text fontSize="sm" color="gray.600" mr="2">
                    Performance
                  </Text>
                  <Switch
                    size="md"
                    mt="3.5"
                    defaultChecked={performanceOverlay}
                    onChange={(e) => setPerformanceOverlay(e.target.checked)}
                  />
                </Flex>
              )}
            </Flex>
            <Flex>
              <LabeledChartSelect
                data-testid="analytics--sort-menu"
                minW="110px"
                flexDir="column"
                mr="4"
                alignItems="flex-start"
                label="Sort"
                singleSelect={analyticsConfig.chartSort}
              />
              {analyticsConfig.primaryDimension.value !==
                AnalyticsDimension.Performance && (
                <LabeledChartSelect
                  data-testid="analytics--show-menu"
                  minW="150px"
                  flexDir="column"
                  alignItems="flex-start"
                  label="Show"
                  singleSelect={analyticsConfig.chartLimit}
                />
              )}
            </Flex>
          </Flex>
          {analyticsConfig.secondaryDimension.value ===
            AnalyticsDimension.Gender && (
            <Flex mt="6" mb="6">
              <AnalyticsInfoAlert status="info" flex="1">
                BrightHire predicts the gender of candidates based upon
                candidate name. Predictions are for directional guidance and
                shown in aggregate only.{" "}
                <Link
                  href="https://help.brighthire.ai/en/articles/6925822-brighthire-candidate-gender-data"
                  target="_blank"
                >
                  Learn more
                </Link>
              </AnalyticsInfoAlert>
            </Flex>
          )}

          {/* Chart */}
          <Box mt="5">
            {analyticsData.loading && <LoadingIndicator />}
            {!analyticsData.loading && (
              <MetricChart
                data={analyticsData.dataPoints || []}
                benchmarkRange={analyticsData.benchmarkRange}
                limit={analyticsConfig.chartLimit.value}
                sort={analyticsConfig.chartSort.value}
                metric={metric}
                primaryDimension={analyticsConfig.primaryDimension}
                secondaryDimension={analyticsConfig.secondaryDimension.value}
                performanceOverlay={performanceOverlay}
                drilldownEnabled={flaggedFeatures.drilldowns}
                onDrilldown={onDrilldown}
                {...analyticsConfig.filters}
              />
            )}
          </Box>
        </>
      )}
    </>
  );
};

type FiltersButtonProps = {
  open: boolean;
  toggleFilters(): void;
};
const FiltersButton: React.FC<FiltersButtonProps> = ({
  open,
  toggleFilters,
}) => {
  return (
    <Button
      data-testid="analytics--filters-button"
      bg={open ? "gray.50" : undefined}
      color={open ? "gray.400" : undefined}
      variant="ghost"
      ml="auto"
      mr="6"
      fontWeight="semibold"
      onClick={toggleFilters}
    >
      <FilterIcon mr="2" color={open ? "gray.500" : undefined} />
      <Text fontSize="sm">Filters</Text>
    </Button>
  );
};

export default AnalyticsMetricsContent;
