import { useClipTranscriptBetaQuery } from "../../../../graphql";
import { TranscriptProps } from "../../../Recording";
import useClipNotes from "./useClipNotes";

const useClipTranscript = ({
  clipId,
  callId,
}: {
  clipId: string;
  callId: string;
}): Pick<
  TranscriptProps,
  "transcript" | "notesToHighlight" | "loading" | "error"
> => {
  const { data, loading, error } = useClipTranscriptBetaQuery({
    variables: { clipId },
  });
  const { notes } = useClipNotes({ callId, clipId });

  return {
    transcript: data?.clip?.transcript ?? [],
    notesToHighlight: notes,
    loading,
    error,
  };
};

export default useClipTranscript;
