import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";

import { useToast } from "../../../components";
import useUpdateCurrentUser from "../../graphql/hooks/useUpdateCurrentUser";
import useCurrentUser from "../../hooks/useCurrentUser";

export interface FormData {
  firstName: string;
  lastName: string;
}

const SignUpForm: React.FC = () => {
  const currentUser = useCurrentUser();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      firstName: currentUser.firstName ?? "",
      lastName: currentUser.lastName ?? "",
    },
  });
  const [updateCurrentUser, { loading }] = useUpdateCurrentUser({
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
  });

  const onSubmit = (formData: FormData): void => {
    updateCurrentUser({ variables: formData });
  };

  return (
    <form
      onSubmit={handleSubmit((formData) => {
        onSubmit(formData);
      })}
      autoComplete="on"
    >
      <VStack spacing="8">
        <FormControl
          id="firstName"
          isRequired
          isInvalid={errors.firstName !== undefined}
        >
          <FormLabel>First name</FormLabel>
          <Input {...register("firstName")} autoComplete="given-name" />
          {errors.firstName !== undefined && (
            <FormErrorMessage>{errors.firstName.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl
          id="lastName"
          isRequired
          isInvalid={errors.lastName !== undefined}
        >
          <FormLabel>Last name</FormLabel>
          <Input {...register("lastName")} autoComplete="family-name" />
          {errors.lastName !== undefined && (
            <FormErrorMessage>{errors.lastName.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl id="email">
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            name="email"
            autoComplete="email"
            value={currentUser.email}
            isReadOnly
          />
        </FormControl>
        <Button type="submit" isLoading={loading}>
          Continue
        </Button>
      </VStack>
    </form>
  );
};

export default SignUpForm;
