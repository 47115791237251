import React, { forwardRef, useEffect, useState } from "react";

import { Select, useToast } from "../../../components";
import useDebounce from "../../../hooks/useDebounce";
import useSelectTheme from "../../../hooks/useSelectTheme";
import { useInterviewersLazyQuery, UserInfoFragment } from "../../graphql";

const formatOption = (
  interviewer: UserInfoFragment
): { label: string; value: UserInfoFragment } => ({
  label: `${interviewer.fullName} - ${interviewer.email}`,
  value: interviewer,
});

type InterviewerSelectProps = {
  onChange: (interviewers: UserInfoFragment[]) => void;
  defaultInterviewers?: UserInfoFragment[];
  isDisabled?: boolean;
  onBlur: any;
  autoFocus?: boolean;
};

const InterviewerSelect = forwardRef<any, InterviewerSelectProps>(
  (
    { onChange, defaultInterviewers = [], isDisabled = false, ...rest },
    ref
  ) => {
    const toast = useToast();
    const [theme, styles] = useSelectTheme();
    const [selectedInterviewers, setSelectedInterviewers] =
      useState<UserInfoFragment[]>(defaultInterviewers);
    const [interviewersQuery, { loading, data }] = useInterviewersLazyQuery({
      onError: () => {
        toast({
          title: "Error loading candidates",
          status: "error",
          isClosable: true,
          position: "top",
        });
      },
    });
    const isSelectable = selectedInterviewers?.length < 10;
    const interviewers = data?.currentUser?.organization.users;

    const [query, setQuery] = useState("");
    const debouncedQuery = useDebounce<string>(query, 200);
    useEffect(() => {
      interviewersQuery({ variables: { query: debouncedQuery } });
    }, [debouncedQuery]);

    return (
      <Select
        isDisabled={isDisabled}
        ref={ref}
        data-testid="interviewers-select"
        theme={theme}
        styles={styles}
        isClearable
        isMulti
        menuShouldBlockScroll
        menuShouldScrollIntoView
        menuPlacement="top"
        placeholder="Enter email or name"
        isLoading={loading}
        value={selectedInterviewers.map(formatOption)}
        options={isSelectable ? interviewers?.map(formatOption) : []}
        onInputChange={setQuery}
        onChange={(selectedOptions) => {
          if (!selectedOptions) {
            setSelectedInterviewers([]);
            onChange([]);
            return;
          }
          const values = selectedOptions.map((o) => o.value);
          setSelectedInterviewers(values);
          onChange(values);
        }}
        menuPortalTarget={document.getElementById("root")}
        {...rest}
      />
    );
  }
);

InterviewerSelect.displayName = "InterviewerSelect";
export default InterviewerSelect;
