import {
  Box,
  BoxProps,
  Flex,
  Icon,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { IconType } from "react-icons";

import {
  CallNoteFragment,
  CallNoteType,
  CallSpeakerFragment,
} from "../../graphql";
import { noteTypeColor, noteTypeIcon } from "../CallNotes/utils";
import SliderClipIndicator from "./SliderClipIndicator";

type ProgressBarSegmentProps = {
  left: number;
  text?: string;
  icon?: IconType;
} & BoxProps;

const ProgressBarSegment: React.FC<ProgressBarSegmentProps> = ({
  left,
  text,
  icon,
  onMouseDown,
  ...rest
}) => {
  const [opacity, setOpacity] = useState(1);

  return (
    <Tooltip label={text} placement="top">
      <Box
        position="absolute"
        left={`${Math.min(0.99, left) * 100}%`}
        top="8px"
        width="16px"
        height="16px"
        borderRadius="full"
        cursor="pointer"
        onMouseDown={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          setOpacity(0.5);
          onMouseDown?.(e);
        }}
        onMouseLeave={() => setOpacity(1)}
        onMouseUp={() => setOpacity(1)}
        opacity={opacity}
        {...rest}
      />
    </Tooltip>
  );
};

export interface VideoPlayerProgressBarProps {
  duration: number;
  value: number;
  notes?: CallNoteFragment[];
  speakers?: CallSpeakerFragment[];
  seek: (value: number) => void;
  disabled: boolean;
  isClip?: boolean;
}

const VideoPlayerProgressBar: React.FC<VideoPlayerProgressBarProps> = ({
  duration,
  value,
  notes = [],
  speakers,
  seek,
  disabled,
  isClip,
}) => {
  const sliderBackground = disabled ? "gray.100" : "whiteAlpha.500";
  const sliderFilledBackground = "white";

  const { isOpen, onOpen, onClose } = useDisclosure();

  const segments = React.useMemo(
    () =>
      notes
        .filter(
          (note) =>
            note.type !== CallNoteType.Note &&
            note.type !== CallNoteType.Comment
        )
        .map((note) => {
          // TODO: change colors to reflect new colors when we change it in sidebar as well
          const color = noteTypeColor(note.type);
          let placeholderText = "";
          let tooltipText = note.text || placeholderText;
          if (tooltipText.includes("\n")) {
            [tooltipText] = tooltipText.split("\n");
            tooltipText =
              tooltipText.length > 37 ? tooltipText.slice(0, 37) : tooltipText;
            tooltipText += "...";
          } else {
            // trim tooltip to 40 characters maximum
            tooltipText = tooltipText.replace(/(.{37})..+/, "$1...");
          }

          if (note.type === CallNoteType.Star) {
            placeholderText = "Noteworthy moment";
          } else if (note.type === CallNoteType.Flag) {
            placeholderText = "Potential red flag";
          }
          return (
            <ProgressBarSegment
              onMouseDown={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              onClick={() => seek(note.highlightStartTime ?? note.time)}
              key={note.id}
              text={tooltipText}
              left={note.time / duration}
            >
              <Icon
                as={noteTypeIcon(note.type)}
                color={color}
                boxSize="16px"
                style={{
                  stroke: "white",
                  strokeWidth: "80",
                  strokeLinejoin: "round",
                  paintOrder: "stroke",
                }}
              />
            </ProgressBarSegment>
          );
        }),
    [notes, duration, speakers]
  );
  return (
    <Flex lineHeight={1} height={8}>
      {isClip && <SliderClipIndicator isStart />}
      <Slider
        value={value}
        onChange={(value) => seek(value)}
        max={duration || undefined}
        isDisabled={disabled}
        cursor={disabled ? "not-allowed" : "pointer"}
        focusThumbOnChange={false}
      >
        <SliderTrack bg={sliderBackground} h="2">
          {!disabled && <SliderFilledTrack bg={sliderFilledBackground} h="2" />}
        </SliderTrack>
        <SliderThumb
          w={isOpen ? "6" : "3"}
          h={isOpen ? "6" : "3"}
          zIndex="auto"
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
          onFocus={(e) => e.preventDefault()}
          onFocusCapture={(e) => e.preventDefault()}
          data-testid="call-slider-thumb"
        />
        {!disabled && duration && segments}
      </Slider>
      {isClip && <SliderClipIndicator isStart={false} />}
    </Flex>
  );
};

export default VideoPlayerProgressBar;
