import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Heading,
  MenuItem,
  MenuItemProps,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { IoLockClosed } from "react-icons/io5";

import { LoadingIndicator } from "../../../components";
import { useCallSalesAiSummaryLazyQuery } from "../../graphql";

interface SalesAISummaryButtonProps extends Omit<MenuItemProps, "onClick"> {
  call: { id: string };
}

const SalesAISummaryButton: React.FC<SalesAISummaryButtonProps> = ({
  call,
  ...menuItemProps
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const cancelRef = React.useRef<HTMLButtonElement | null>(null);

  const [query, { data, loading, error }] = useCallSalesAiSummaryLazyQuery({});

  const components =
    data?.callSalesAiSummary?.text.split("\n").map((line, i) => {
      if (line.startsWith("## ")) {
        return (
          <Heading as="h3" size="sm" key={line}>
            {line.replace("## ", "")}
          </Heading>
        );
      }
      if (line.startsWith("- ")) {
        return <Text key={line}>{line.replace("- ", "• ")}</Text>;
      }
      // eslint-disable-next-line react/no-array-index-key
      return <br key={i} />;
    }) || null;

  return (
    <>
      <MenuItem
        data-testid="call-settings-menu-sales-ai-summary"
        color="blue.600"
        icon={<IoLockClosed />}
        isDisabled={loading}
        onClick={() => {
          query({
            variables: { callId: call.id, regenerate: false },
          });
          setIsModalOpen(true);
        }}
        {...menuItemProps}
      >
        View Sales AI Summary
      </MenuItem>
      <AlertDialog
        isOpen={isModalOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsModalOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent w="800px" maxW="800px">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Sales AI Summary
            </AlertDialogHeader>
            <AlertDialogBody>
              {loading ? (
                <LoadingIndicator />
              ) : (
                <>
                  {components ||
                    error?.toString() ||
                    "Could not generate AI Summary"}
                </>
              )}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                variant="outline"
                size="sm"
                ref={cancelRef}
                isDisabled={loading}
                onClick={() => setIsModalOpen(false)}
              >
                Close
              </Button>

              <Button
                variant="solid"
                size="sm"
                isLoading={loading}
                onClick={() => {
                  query({
                    variables: { callId: call.id, regenerate: true },
                  });
                }}
                ml={3}
              >
                Regenerate
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

SalesAISummaryButton.displayName = "SalesAISummaryButton";

export default SalesAISummaryButton;
