import React from "react";

import { StarRating } from "../../../components";
import { StarRatingProps } from "../../../components/StarRating/StarRating";
import { Scorecard } from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";

type OverallStarRatingProps = {
  scorecard: Pick<Scorecard, "id" | "overallNotes" | "overallScore">;
} & Omit<StarRatingProps, "ratingDescriptions">;

const OverallStarRating: React.FC<OverallStarRatingProps> = ({
  scorecard,
  onChange,
  ...rest
}) => {
  const currentUser = useCurrentUser();
  const { ratingDescriptions } = currentUser.organization;

  return (
    <StarRating
      rating={scorecard.overallScore || undefined}
      onChange={onChange}
      ratingDescriptions={ratingDescriptions}
      colors={{
        default: "blueAlpha.400",
        filled: "blue.500",
        active: "blue.300",
      }}
      {...rest}
    />
  );
};

OverallStarRating.displayName = "OverallStarRating";

export default OverallStarRating;
