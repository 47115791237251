import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

const Timestamp: React.FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <Box
      fontSize="13px"
      flex="none"
      fontWeight="bold"
      color="gray.600"
      textAlign="end"
      {...rest}
    >
      {children}
    </Box>
  );
};
export default Timestamp;
