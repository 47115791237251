import { Badge, Box, Flex, Icon, Link } from "@chakra-ui/react";
import React, { useState } from "react";
import { BsDot } from "react-icons/bs";
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi";
import { HiArrowTopRightOnSquare } from "react-icons/hi2";

import { formatRelativeDate } from "../../../utils/datetime";
import {
  CandidateAlertFeedListItemFragment,
  CandidateAlertType,
} from "../../graphql";

interface CandidateAlertProps extends CandidateAlertFeedListItemFragment {
  isLast: boolean;
  isFirst: boolean;
  handleAlertRead: (ids: string[]) => void;
}

const formatAlertType = (type: CandidateAlertType): string => {
  switch (type) {
    case CandidateAlertType.ClosingRisk: {
      return "Closing Risk";
    }
    case CandidateAlertType.FollowUp: {
      return "Follow Up";
    }
    default: {
      return type;
    }
  }
};

export const CandidateAlert: React.FC<CandidateAlertProps> = ({
  id,
  alert,
  readAt,
  isLast,
  isFirst,
  handleAlertRead,
}) => {
  const [isTextOpen, setIsTextOpen] = useState(false);
  const candidateName = alert.candidate.fullName;
  const callId = alert.call.id;
  const interviewTimestamp = alert.call.startTime;
  const positionName = alert.position?.title;
  const { text, startTime, type } = alert;
  const handleClick = (): void => {
    if (!readAt) {
      handleAlertRead([id]);
    }
  };
  return (
    <Box
      paddingBottom={isLast ? "0px" : "16px"}
      paddingTop={isFirst ? "0px" : "16px"}
      borderBottom={isLast ? "0px" : "1px"}
      borderColor="gray.100"
      onClick={handleClick}
    >
      <Box pl="2" pr="4">
        <Flex
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex alignItems="center">
            <BsDot
              viewBox="2 2 12 12"
              size={20}
              color="red"
              visibility={readAt ? "hidden" : "visible"}
            />
            <Box fontSize="sm" fontWeight="600">
              {candidateName}
            </Box>
          </Flex>
          <Flex alignItems="center">
            <Box fontSize="xs" fontWeight="400" color="gray.600" pr="2">
              {formatRelativeDate(interviewTimestamp)}
            </Box>
            <Icon
              as={isTextOpen ? HiOutlineChevronUp : HiOutlineChevronDown}
              boxSize="5"
              color="gray.400"
              onClick={() => setIsTextOpen((prevState) => !prevState)}
            />
          </Flex>
        </Flex>
        <Box pl="5" fontSize="xs" fontWeight="500" color="gray.600">
          {positionName}
        </Box>
        <Box pl="5" pt="1">
          <Badge
            color="red.600"
            backgroundColor="red.100"
            textTransform="none"
            fontSize="11"
            py="2px"
            px="6px"
            borderRadius="3px"
          >
            {formatAlertType(type)}
          </Badge>
        </Box>
        {isTextOpen && (
          <Box pl="5" pt="3">
            <Box
              pl="4"
              pr="4"
              pb="4"
              pt="3"
              border="1px"
              borderColor="gray.100"
              borderRadius="6px"
            >
              <Flex direction="column">
                <Box fontSize="sm" fontWeight="400" color="gray.700">
                  {text}
                </Box>
                <Link
                  pt="1"
                  href={`/interview/${callId}?t=${startTime}`}
                  fontWeight="500"
                  color="blue.600"
                  fontSize="xs"
                >
                  Open Interview
                  <Icon as={HiArrowTopRightOnSquare} ml="1" size={32} />
                </Link>
              </Flex>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
