import { DataKey, UserMetrics } from "./types";

export const userMetricAverage = (
  users: UserMetrics[],
  key: DataKey
): number => {
  const metricSum = users.reduce<number>((prev, current) => {
    return prev + (current.callingMetrics[key] ?? 0);
  }, 0);
  const relevantUserCount = users.filter(
    (user) => !!user.callingMetrics[key]
  ).length;
  const average = metricSum / relevantUserCount;
  return Number.isNaN(average) ? 0 : average;
};
