import { Box, BoxProps, Flex, Icon, Tooltip } from "@chakra-ui/react";
import React from "react";
import { HiOutlineInformationCircle } from "react-icons/hi2";

type ScoresTabColumnProps = {
  heading: string;
  subHeading: React.ReactNode;
  subHeadingTooltip: React.ReactNode;
} & BoxProps;

const ScoresTabColumn: React.FC<ScoresTabColumnProps> = ({
  heading,
  subHeading,
  subHeadingTooltip,
  children,
  ...props
}) => (
  <Box
    flex="1"
    minW="0"
    borderRadius="lg"
    border="1px solid"
    borderColor="gray.100"
    px="6"
    pt="5"
    pb="1"
    {...props}
  >
    <Flex
      justifyContent="space-between"
      fontWeight="semibold"
      color="gray.500"
      mb="4"
    >
      {heading}
      <Flex color="gray.600" fontWeight="normal" alignItems="center">
        {subHeading}
        {subHeadingTooltip && (
          <Tooltip variant="light" label={subHeadingTooltip}>
            <Box ml="1" boxSize="18px">
              <Icon as={HiOutlineInformationCircle} boxSize="100%" />
            </Box>
          </Tooltip>
        )}
      </Flex>
    </Flex>
    {children}
  </Box>
);

export default ScoresTabColumn;
