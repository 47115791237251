import { Box, Button, Fade } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import AddTraineesStep from "./AddTraineesStep";
import AssessmentStep from "./AsessmentStep";
import RecordingStep from "./RecordingStep";
import Stepper from "./Stepper";
import {
  StepperStep,
  TrainingListItemType,
  TrainingProgram,
  TrainingWizardStage,
} from "./types";
import { TrainingApi } from "./useTrainingApi";
import WizardFooter from "./WizardFooter";

type TrainingWizardProps = {
  trainingProgram: TrainingProgram;
  trainingApi: TrainingApi;
  setAutoSaveInProgress: (autoSaveInProgress: boolean) => void;
  trainingListItems: TrainingListItemType[];
};

type TrainingStep = {
  stage: TrainingWizardStage;
  dataAttributes?: Record<string, string>;
} & StepperStep;

const TrainingWizard: React.FC<TrainingWizardProps> = ({
  trainingProgram,
  trainingApi,
  setAutoSaveInProgress,
  trainingListItems,
}) => {
  const [stage, setStage] = useState<TrainingWizardStage>("recordings");

  // Reset stage when switching programs
  useEffect(() => setStage("recordings"), [trainingProgram.id]);

  const [viewedAssessments, setViewedAssessments] = useState(false);
  const [viewedTrainees, setViewedTrainees] = useState(false);

  useEffect(() => {
    if (stage === "assessments" && !viewedAssessments) {
      setViewedAssessments(true);
    }
  }, [stage, viewedAssessments]);

  useEffect(() => {
    if (stage === "trainees" && !viewedTrainees) {
      setViewedTrainees(true);
    }
  }, [stage, viewedTrainees]);

  const recordingsComplete =
    (trainingProgram.trainingProgramItems.length || 0) > 0;
  // No action is required by users on the assessments step, but it would look
  // weird to have future steps pre-completed. So, assessment is only seen as
  // complete once the user has viewed that step.
  const assessmentsComplete = viewedAssessments;
  // Same for trainees
  const traineesComplete = viewedTrainees;

  const stepOrder: TrainingWizardStage[] = [
    "recordings",
    "assessments",
    "trainees",
  ];
  const steps: TrainingStep[] = [
    {
      label: "Recordings and clips",
      stage: "recordings",
      width: 236,
      active: stage === "recordings",
      complete: recordingsComplete,
      available: true,
      onClick: () => setStage("recordings"),
    },
    {
      label: "Assessment questions",
      stage: "assessments",
      width: 272,
      active: stage === "assessments",
      complete: assessmentsComplete,
      available: recordingsComplete,
      onContinue: () => setStage("trainees"),
      onClick: () => setStage("assessments"),
    },
    {
      label: "Trainees",
      stage: "trainees",
      width: 172,
      active: stage === "trainees",
      complete: traineesComplete,
      available: assessmentsComplete,
      continueButtonLabel: "Launch",
      dataAttributes: {
        "data-tour-id": "training-launch-program",
      },
      onContinue: () => trainingApi.program.launch(trainingProgram.id),
      onClick: () => setStage("trainees"),
    },
  ];

  const stepIndex = stepOrder.indexOf(stage);
  const currentStep = steps[stepIndex];
  const canContinue = currentStep.complete;
  const canGoBack = stepIndex > 0;
  const nextStep = steps[stepIndex + 1];
  const prevStep = steps[stepIndex - 1];

  const renderContinueButton = (show: boolean): JSX.Element => {
    const dataAttributes = show ? currentStep.dataAttributes : undefined;
    return (
      <Button
        {...dataAttributes}
        data-testid={show ? "training-wizard-continue" : undefined}
        display={show ? undefined : "none"}
        fontSize="sm"
        fontWeight="medium"
        py="10px"
        px="3"
        disabled={!canContinue}
        onClick={() => {
          if (currentStep.onContinue) {
            currentStep.onContinue();
          } else {
            setStage(nextStep.stage);
          }
        }}
      >
        {currentStep.continueButtonLabel || "Continue"}
      </Button>
    );
  };

  const renderBackButton = (): JSX.Element => (
    <Button
      display={canGoBack ? undefined : "none"}
      variant="white"
      fontSize="sm"
      fontWeight="medium"
      mr="6"
      py="10px"
      px="5"
      onClick={() => setStage(prevStep.stage)}
    >
      Back
    </Button>
  );

  return (
    <>
      <Stepper mt="7" ml="-1px" steps={steps} />
      <Box maxWidth="1012px" width="100%" ml="auto" mr="auto">
        {stage === "recordings" && (
          <RecordingStep
            trainingProgramId={trainingProgram.id}
            trainingListItems={trainingListItems}
            trainingApi={trainingApi}
            setAutoSaveInProgress={setAutoSaveInProgress}
          />
        )}
        {stage === "assessments" && (
          <AssessmentStep
            trainingProgramId={trainingProgram.id}
            assessmentEnabled={trainingProgram.assessmentEnabled}
            trainingQuestions={trainingProgram.trainingProgramQuestions}
            trainingApi={trainingApi}
            setAutoSaveInProgress={setAutoSaveInProgress}
          />
        )}
        {stage === "trainees" && (
          <AddTraineesStep
            trainingProgramId={trainingProgram.id}
            trainees={trainingProgram.trainingProgramTrainees}
            trainingApi={trainingApi}
            setAutoSaveInProgress={setAutoSaveInProgress}
          />
        )}
      </Box>
      <WizardFooter>
        <Fade in={canGoBack}>
          {renderBackButton()}
          {renderContinueButton(canGoBack)}
        </Fade>
        <Fade in={!canGoBack}>{renderContinueButton(!canGoBack)}</Fade>
      </WizardFooter>
    </>
  );
};

export default TrainingWizard;
