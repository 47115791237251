import { Box, Text } from "@chakra-ui/react";
import LogRocket from "logrocket";
import React from "react";

import { TrackerTabState } from "./types";

export type ClearFilterProps = {
  setTrackerTabState: (trackerTabState: TrackerTabState) => void;
  trackerTabState: TrackerTabState;
};

const ClearFilter: React.FC<ClearFilterProps> = ({
  trackerTabState,
  setTrackerTabState,
}) => {
  if (trackerTabState.labeledTrackerTabGroup?.name !== "Questions") return null;

  if (!trackerTabState?.selectedTracker?.speakerTag) {
    return null;
  }
  return (
    <Box
      mb="2"
      mr="2"
      px="2"
      py="1"
      fontWeight="bold"
      borderRadius="6"
      fontSize="12"
      cursor="pointer"
      display="inline-block"
      data-testid="tracker-keyword-clear-filter"
      onClick={() => {
        LogRocket.track("tracker-clear-filter");
        setTrackerTabState({
          ...trackerTabState,
          selectedTracker: {
            group: trackerTabState.selectedTracker.group,
            speakerTag: undefined,
            keyword: undefined,
          },
        });
      }}
    >
      <Text color="blue.500">Clear Filter</Text>
    </Box>
  );
};

export default ClearFilter;
