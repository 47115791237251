import { ApolloError, MutationTuple } from "@apollo/client";

import {
  AddClientMutation,
  AddClientMutationVariables,
  OrgClientsDocument,
  OrgClientsQuery,
  useAddClientMutation,
} from "..";

export default function useAddClient({
  onCompleted,
}: {
  onCompleted?: (data: AddClientMutation) => void;
}): [
  MutationTuple<AddClientMutation, AddClientMutationVariables>[0],
  { loading: boolean; error: ApolloError | undefined }
] {
  const [addClient, { loading, error }] = useAddClientMutation({
    update(cache, { data: addClientData }) {
      const client = addClientData?.addClient?.client;
      if (client) {
        const cachedQuery = cache.readQuery<OrgClientsQuery>({
          query: OrgClientsDocument,
        });
        const cachedUser = cachedQuery?.currentUser;
        const cachedClients = cachedQuery?.currentUser?.organization.clients;
        if (cachedClients && cachedUser) {
          cache.writeQuery<OrgClientsQuery>({
            query: OrgClientsDocument,
            data: {
              currentUser: {
                ...cachedUser,
                organization: {
                  ...cachedUser.organization,
                  clients: [client, ...cachedClients],
                },
              },
            },
          });
        }
      }
    },
    onCompleted,
  });
  return [addClient, { loading, error }];
}
