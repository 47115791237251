import { Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";

const SummaryComingSoon: React.FC = () => (
  <Flex direction="column" alignItems="center" pt="8">
    <Icon viewBox="0 0 242 179" w="240px" h="auto" mt="7">
      <path d="M49.3584 97V98.9859" stroke="#E9D8FD" strokeLinecap="round" />
      <path
        d="M44.8975 98.0762L46.5202 99.7467"
        stroke="#E9D8FD"
        strokeLinecap="round"
      />
      <path d="M43 102.523H45.2945" stroke="#E9D8FD" strokeLinecap="round" />
      <path
        d="M44.7119 107.049L46.3347 105.379"
        stroke="#E9D8FD"
        strokeLinecap="round"
      />
      <path
        d="M49.3584 107.591V105.605"
        stroke="#E9D8FD"
        strokeLinecap="round"
      />
      <path
        d="M52.6677 106.267L51.3438 104.943"
        stroke="#E9D8FD"
        strokeLinecap="round"
      />
      <path
        d="M55.316 102.958H53.3301"
        stroke="#E9D8FD"
        strokeLinecap="round"
      />
      <path
        d="M53.9919 98.3242L52.668 99.6481"
        stroke="#E9D8FD"
        strokeLinecap="round"
      />
      <path
        d="M117.434 2V3.32392"
        stroke="#E9D8FD"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M113.5 6H114.824"
        stroke="#E9D8FD"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M117.434 9.94306V8.61914"
        stroke="#E9D8FD"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M120.744 5.97168H119.42"
        stroke="#E9D8FD"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M40.6465 57.6055V59.5914"
        stroke="#E9D8FD"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M34.6885 63.5625H36.6744"
        stroke="#E9D8FD"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M40.6465 68.8589V66.873"
        stroke="#E9D8FD"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M45.9419 63.5625H43.9561"
        stroke="#E9D8FD"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M183.958 85V86.9859"
        stroke="#E9D8FD"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M178 90.957H179.986"
        stroke="#E9D8FD"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M183.958 96.2535V94.2676"
        stroke="#E9D8FD"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M189.253 90.957H187.268"
        stroke="#E9D8FD"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M183.135 129.783C184.717 129.783 186 131.067 186 132.649C186 134.232 184.717 135.515 183.135 135.515C181.554 135.515 180.271 134.232 180.271 132.649"
        stroke="#E9D8FD"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6299 118.901C22.4807 118.636 23.7839 117.596 24.0857 116.477C24.3594 117.465 25.5064 118.636 26.5416 118.724C25.3766 119.137 24.2627 120.363 24.0857 121.325C23.9655 120.344 22.4245 119.044 21.6299 118.901Z"
        fill="#D6BCFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.4186 36.8168C37.4186 38.0445 36.4239 39.0389 35.1971 39.0389C33.9697 39.0389 32.9756 38.0445 32.9756 36.8168C32.9756 35.5897 33.9697 34.5947 35.1971 34.5947C36.4239 34.5947 37.4186 35.5897 37.4186 36.8168Z"
        fill="#E9D8FD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M220.89 78.9461C220.89 80.5734 219.572 81.8922 217.945 81.8922C216.318 81.8922 215 80.5734 215 78.9461C215 77.3187 216.318 76 217.945 76C219.572 76 220.89 77.3187 220.89 78.9461Z"
        fill="#D6BCFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.713 133.857C50.713 134.883 49.8818 135.715 48.8562 135.715C47.8312 135.715 47 134.883 47 133.857C47 132.831 47.8312 132 48.8562 132C49.8818 132 50.713 132.831 50.713 133.857Z"
        stroke="#E9D8FD"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.8317 22.5414C77.8317 23.7691 76.837 24.7635 75.6102 24.7635C74.3834 24.7635 73.3887 23.7691 73.3887 22.5414C73.3887 21.3143 74.3834 20.3193 75.6102 20.3193C76.837 20.3193 77.8317 21.3143 77.8317 22.5414Z"
        stroke="#E9D8FD"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M210.229 159.322C208.809 159.863 207.221 159.149 206.681 157.73C206.14 156.31 206.853 154.72 208.273 154.181C209.692 153.64 211.281 154.353 211.821 155.773C212.362 157.193 211.648 158.782 210.229 159.322Z"
        stroke="#E9D8FD"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <rect
        x="88.543"
        y="33.8994"
        width="90"
        height="106"
        rx="4"
        transform="rotate(11.2059 88.543 33.8994)"
        fill="#D6BCFA"
      />
      <rect
        x="86.3779"
        y="29.3926"
        width="90"
        height="106"
        rx="4"
        transform="rotate(11.2059 86.3779 29.3926)"
        fill="#EFE2FF"
      />
      <g opacity="0.8" clipPath="url(#clip0_3977_8733)">
        <path
          d="M121.396 124.715L120.383 126.691L120.2 124.478C120.103 123.315 119.414 122.285 118.376 121.753L116.4 120.739L118.613 120.556C119.775 120.459 120.806 119.77 121.338 118.732L122.351 116.756L122.535 118.969C122.631 120.132 123.321 121.162 124.358 121.694L126.334 122.707L124.121 122.891C122.959 122.987 121.929 123.677 121.396 124.715Z"
          fill="#9F7AEA"
        />
        <path
          d="M128.659 120.655L128.318 121.38L128.278 120.58C128.232 119.646 127.675 118.814 126.829 118.415L126.105 118.073L126.905 118.034C127.839 117.988 128.671 117.431 129.07 116.585L129.411 115.861L129.451 116.66C129.497 117.594 130.054 118.427 130.9 118.825L131.624 119.167L130.825 119.206C129.891 119.253 129.058 119.81 128.659 120.655Z"
          fill="#9F7AEA"
        />
        <path
          d="M125.927 129.179L125.465 129.992L125.347 129.064C125.28 128.537 124.969 128.072 124.507 127.809L123.694 127.347L124.622 127.229C125.149 127.162 125.614 126.851 125.877 126.389L126.339 125.576L126.457 126.504C126.524 127.031 126.835 127.496 127.297 127.759L128.11 128.221L127.182 128.339C126.655 128.406 126.19 128.717 125.927 129.179Z"
          fill="#9F7AEA"
        />
        <path
          d="M121.396 124.715L120.383 126.691L120.2 124.478C120.103 123.315 119.414 122.285 118.376 121.753L116.4 120.739L118.613 120.556C119.775 120.459 120.806 119.77 121.338 118.732L122.351 116.756L122.535 118.969C122.631 120.132 123.321 121.162 124.358 121.694L126.334 122.707L124.121 122.891C122.959 122.987 121.929 123.677 121.396 124.715Z"
          stroke="#9F7AEA"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M128.659 120.655L128.318 121.38L128.278 120.58C128.232 119.646 127.675 118.814 126.829 118.415L126.105 118.073L126.905 118.034C127.839 117.988 128.671 117.431 129.07 116.585L129.411 115.861L129.451 116.66C129.497 117.594 130.054 118.427 130.9 118.825L131.624 119.167L130.825 119.206C129.891 119.253 129.058 119.81 128.659 120.655Z"
          stroke="#9F7AEA"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M125.927 129.179L125.465 129.992L125.347 129.064C125.28 128.537 124.969 128.072 124.507 127.809L123.694 127.347L124.622 127.229C125.149 127.162 125.614 126.851 125.877 126.389L126.339 125.576L126.457 126.504C126.524 127.031 126.835 127.496 127.297 127.759L128.11 128.221L127.182 128.339C126.655 128.406 126.19 128.717 125.927 129.179Z"
          stroke="#9F7AEA"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        opacity="0.6"
        d="M92.957 55.4385L160.642 68.8476"
        stroke="#B794F4"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        opacity="0.6"
        d="M90.625 67.21L158.31 80.6191"
        stroke="#B794F4"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        opacity="0.6"
        d="M88.293 78.9805L155.977 92.3896"
        stroke="#B794F4"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        opacity="0.6"
        d="M85.9609 90.752L153.645 104.161"
        stroke="#B794F4"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        opacity="0.6"
        d="M83.6289 102.523L151.313 115.933"
        stroke="#B794F4"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        opacity="0.6"
        d="M81.2969 114.295L112.687 120.514"
        stroke="#B794F4"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M147.741 51.5701L109.283 43.951C108.914 43.8776 108.59 43.6606 108.381 43.3475L107.965 43.6251L108.381 43.3475C108.172 43.0343 108.095 42.6505 108.168 42.2802L109.994 33.0638C110.068 32.6937 110.285 32.3681 110.598 32.1584C110.91 31.9486 111.293 31.8718 111.661 31.9443L150.12 39.5635C150.489 39.637 150.813 39.8538 151.022 40.1669C151.231 40.4801 151.308 40.8641 151.235 41.2344L149.409 50.4503C149.335 50.8206 149.118 51.1462 148.805 51.3561C148.493 51.5658 148.11 51.6427 147.741 51.5701Z"
        fill="#9F7AEA"
        stroke="#9F7AEA"
      />
      <path
        d="M134.402 32.239C134.104 33.7432 132.648 34.7163 131.155 34.4205C129.662 34.1247 128.686 32.6699 128.984 31.1656C129.282 29.6614 130.739 28.6884 132.232 28.9842C133.725 29.28 134.7 30.7348 134.402 32.239Z"
        stroke="#9F7AEA"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M182.421 31.1671C183.149 31.4324 183.499 32.2065 183.203 32.8961L179.396 41.7478C179.1 42.4373 178.269 42.7812 177.54 42.5158C176.812 42.2504 176.462 41.4763 176.758 40.7868L180.565 31.935C180.861 31.2455 181.692 30.9017 182.421 31.1671Z"
        fill="#E9D8FD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M183.594 58.7432C183.916 57.9444 184.731 57.5221 185.417 57.7999L194.217 61.3665C194.902 61.6444 195.198 62.5171 194.877 63.3159C194.556 64.1147 193.74 64.537 193.054 64.2592L184.254 60.6925C183.569 60.4147 183.273 59.542 183.594 58.7432Z"
        fill="#E9D8FD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M163.807 30.7456C164.512 30.428 165.296 30.7327 165.558 31.426L168.908 40.2936C169.17 40.9869 168.81 41.8063 168.105 42.1238C167.4 42.4413 166.616 42.1367 166.354 41.4434L163.004 32.5758C162.742 31.8825 163.102 31.0631 163.807 30.7456Z"
        fill="#E9D8FD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M195.412 44.4279C195.674 45.1211 195.315 45.9406 194.61 46.2581L185.591 50.3192C184.886 50.6367 184.102 50.332 183.84 49.6388C183.578 48.9455 183.937 48.126 184.642 47.8085L193.661 43.7474C194.366 43.4299 195.15 43.7346 195.412 44.4279Z"
        fill="#E9D8FD"
      />
      <path
        d="M51.7228 80.4701L51 83L50.2772 80.4701C49.8975 79.1412 48.8588 78.1025 47.5299 77.7228L45 77L47.5299 76.2772C48.8588 75.8975 49.8975 74.8588 50.2772 73.5299L51 71L51.7228 73.5299C52.1025 74.8588 53.1412 75.8975 54.4701 76.2772L57 77L54.4701 77.7228C53.1412 78.1025 52.1025 79.1412 51.7228 80.4701Z"
        fill="#D6BCFA"
        stroke="#D6BCFA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M200.482 113.313L200 115L199.518 113.313C199.265 112.427 198.573 111.735 197.687 111.482L196 111L197.687 110.518C198.573 110.265 199.265 109.573 199.518 108.687L200 107L200.482 108.687C200.735 109.573 201.427 110.265 202.313 110.518L204 111L202.313 111.482C201.427 111.735 200.735 112.427 200.482 113.313Z"
        fill="#D6BCFA"
        stroke="#D6BCFA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M193.361 17.735L193 19L192.639 17.735C192.449 17.0706 191.929 16.5513 191.265 16.3614L190 16L191.265 15.6386C191.929 15.4487 192.449 14.9294 192.639 14.265L193 13L193.361 14.265C193.551 14.9294 194.071 15.4487 194.735 15.6386L196 16L194.735 16.3614C194.071 16.5513 193.551 17.0706 193.361 17.735Z"
        fill="#D6BCFA"
        stroke="#D6BCFA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        opacity="0.8"
        cx="117.5"
        cy="167"
        rx="50.5"
        ry="3"
        fill="#E9D8FD"
      />
      <defs>
        <clipPath id="clip0_3977_8733">
          <rect
            width="18.0044"
            height="18.0044"
            fill="white"
            transform="translate(116.493 111.581) rotate(11.2059)"
          />
        </clipPath>
      </defs>
    </Icon>
    <Text
      mt="5"
      mb="3"
      fontSize="lg"
      fontWeight="semibold"
      color="gray.700"
      lineHeight="6"
    >
      AI Summaries are coming soon!
    </Text>
    <Text fontSize="md" color="gray.600" lineHeight="5">
      Interviewing is about to get even more delightful.
    </Text>
  </Flex>
);

export default SummaryComingSoon;
