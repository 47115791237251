import { ButtonProps } from "@chakra-ui/react";
import React from "react";

import { LeverIcon, RatingButton, useTheme } from "..";

const LeverRatingButton: React.FC<{
  leverInterviewLink?: string;
  showFullWidth?: boolean;
  variant?: ButtonProps["variant"];
  size?: ButtonProps["size"];
}> = ({ size, leverInterviewLink, showFullWidth, variant }) => {
  const theme = useTheme();
  const { colors } = theme;
  if (!leverInterviewLink) return null;
  return (
    <RatingButton
      link={leverInterviewLink}
      icon={
        <LeverIcon
          fill={colors.blue[500]}
          width={size === "sm" ? "18px" : "12px"}
          height={size === "sm" ? "18px" : "12px"}
        />
      }
      showFullWidth={showFullWidth}
      size={size}
      variant={variant}
    />
  );
};

export default LeverRatingButton;
