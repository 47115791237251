import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { StarRating } from "..";
import { RatingDescriptions } from "../StarRating/StarRating";

export type StarRatingAverageProps = {
  rating?: number;
  maxRating?: number;
  size?: number;
  ratingDescriptions: RatingDescriptions;
};

const StarRatingAverage: React.FC<StarRatingAverageProps> = ({
  rating = 0,
  maxRating = 5,
  size = 5,
  ratingDescriptions,
}) => {
  const emptyStarsRef = React.useRef<HTMLDivElement>(null);
  const [filledStarsWidth, setFilledStarsWidth] = useState(0);

  useEffect(() => {
    if (emptyStarsRef.current) {
      setFilledStarsWidth(
        (emptyStarsRef.current?.offsetWidth ?? 0) * (rating / maxRating)
      );
    }
  });
  return (
    <Box position="relative">
      <StarRating
        ref={emptyStarsRef}
        rating={0}
        size={size}
        maxRating={maxRating}
        readOnly
        ratingDescriptions={ratingDescriptions}
      />
      <Box
        position="absolute"
        overflowX="hidden"
        top={0}
        left={0}
        width={`${filledStarsWidth}px`}
        display="inline-block"
      >
        <StarRating
          rating={maxRating}
          size={size}
          maxRating={maxRating}
          readOnly
          ratingDescriptions={ratingDescriptions}
        />
      </Box>
    </Box>
  );
};

StarRatingAverage.displayName = "StarRatingAverage";

export default StarRatingAverage;
