import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

export type LongPressMouseEvents = {
  onMouseDown?: () => void;
  onMouseUp?: () => void;
  onMouseLeave?: () => void;
  onTouchStart?: () => void;
  onTouchEnd?: () => void;
  [key: string]: (() => void) | undefined;
};

const useLongPress = (
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  start = () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  end = () => {},
  overrides: LongPressMouseEvents | undefined = undefined,
  ms = 300
): LongPressMouseEvents => {
  const [startLongPress, setStartLongPress] = useState(false);
  const [timerId, setTimerId] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (isMobile) {
      if (startLongPress) {
        setTimerId(window.setTimeout(start, ms));
      } else if (timerId) {
        try {
          setTimerId(undefined);
          window.clearTimeout(timerId);
          end();
          // eslint-disable-next-line no-empty
        } catch (e) {}
      }
    }

    return () => {
      if (isMobile) {
        try {
          if (timerId) {
            setTimerId(undefined);
            window.clearTimeout(timerId);
            end();
          }
          // eslint-disable-next-line no-empty
        } catch (e) {}
      }
    };
  }, [startLongPress]);

  const result: LongPressMouseEvents = {
    onMouseDown: () => setStartLongPress(true),
    onMouseUp: () => setStartLongPress(false),
    onMouseLeave: () => setStartLongPress(false),
    onTouchStart: () => setStartLongPress(true),
    onTouchEnd: () => setStartLongPress(false),
  };

  if (overrides && Object.keys(overrides).length) {
    Object.entries(overrides)?.forEach((o) => {
      const key = o[0];
      const val = o[1];
      result[key] = val;
    });
  }

  return result;
};

export default useLongPress;
