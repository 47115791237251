import React from "react";

import { MetricName } from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";
import AnalyticsMetricsContentTable from "./AnalyticsMetricsContentTable";
import useAnalyticsConfig from "./useAnalyticsConfig";
import useAnalyticsData from "./useAnalyticsData";
import { canViewAllMetrics } from "./utils";

const AnalyticsMetricsTable: React.FC = () => {
  const metric = MetricName.TotalInterviews;
  const analyticsConfig = useAnalyticsConfig(metric);
  const currentUser = useCurrentUser();
  const canViewMetrics = canViewAllMetrics(currentUser);
  const analyticsData = useAnalyticsData(analyticsConfig, {
    skipAllQueries: !canViewMetrics,
  });

  return (
    <AnalyticsMetricsContentTable
      analyticsConfig={analyticsConfig}
      analyticsData={analyticsData}
      flaggedFeatures={{
        genderSegmentation: false,
        togglePerformanceOverlay: false,
        drilldowns: true,
      }}
    />
  );
};

export default AnalyticsMetricsTable;
