export const distinct = (array: any[], field = "id"): any[] => {
  const map = new Map<string, any>();
  array.forEach((item) => {
    if (!map.has(item[field])) {
      map.set(item[field], item);
    }
  });
  return Array.from(map.values());
};

export const pluck = <T, K extends keyof T>(array: T[], key: K): T[K][] =>
  array.map(({ [key]: val }) => val);

export const asArray = <T>(val: T | T[]): T[] =>
  Array.isArray(val) ? val : [val];
