import { useTheme } from "@chakra-ui/system";
import { Theme } from "react-select";

import useSelectTheme from "../../../hooks/useSelectTheme";

export const useAnalyticsSelectTheme = (): [
  (theme: Theme) => Theme,
  Record<string, any>
] => {
  const { colors } = useTheme();
  const selectValueStyles = {
    fontSize: "14px",
    color: colors.gray[900],
  };
  const [selectTheme, selectStyles] = useSelectTheme({
    control: (provided: Record<string, any>, state: { isFocused: any }) => ({
      ...provided,
      borderColor: colors.gray[100],
    }),
    menu: (provided: Record<string, any>) => ({
      ...provided,
      fontSize: "14px",
    }),
    placeholder: (provided: Record<string, any>) => ({
      ...provided,
      color: colors.gray[900],
    }),
    singleValue: (provided: Record<string, any>) => ({
      ...provided,
      ...selectValueStyles,
    }),
    valueContainer: (provided: Record<string, any>) => ({
      ...provided,
      ...selectValueStyles,
    }),
    indicatorSeparator: () => ({ display: "none" }),
  });
  return [selectTheme, selectStyles];
};
