import {
  Box,
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
} from "@chakra-ui/react";
import React from "react";

import {
  ManageTrainingPageTableNestedRow,
  ViewedStateCell,
} from "./ManageTrainingPageTraineesRow";
import { TrainingItemStatus } from "./types";

export const buildAllTraineeNestedRecordingRows = (
  assessmentEnabled: boolean,
  itemStatuses: TrainingItemStatus[],
  traineeId: string
): React.ReactNode[] => {
  return itemStatuses.map((itemStatus, idx, arr) => {
    const hasCompletedTrainingItem = assessmentEnabled
      ? itemStatus.questionsAndAnswers.some(
          (questionAndAnswer) =>
            questionAndAnswer.answer && questionAndAnswer.answer !== ""
        )
      : itemStatus.viewed;

    const stateCell = assessmentEnabled ? (
      <AssessmentStateCell
        completed={hasCompletedTrainingItem}
        questionsAndAnswers={itemStatus.questionsAndAnswers}
        deletedQuestionsAndAnswers={itemStatus.deletedQuestionsAndAnswers}
      />
    ) : (
      <ViewedStateCell completed={hasCompletedTrainingItem} />
    );
    return (
      <ManageTrainingPageTableNestedRow
        itemName={itemStatus.name || "Recording"}
        hasCompletedTrainingItem={hasCompletedTrainingItem}
        isLast={idx === arr.length - 1}
        key={`${traineeId}_${itemStatus.itemId}`}
        itemURL={itemStatus.url}
        isRedacted={!!itemStatus.contentRedactedAt}
      >
        {stateCell}
      </ManageTrainingPageTableNestedRow>
    );
  });
};

type AssessmentStateCellProps = {
  completed: boolean;
  questionsAndAnswers: TrainingItemStatus["questionsAndAnswers"];
  deletedQuestionsAndAnswers: TrainingItemStatus["deletedQuestionsAndAnswers"];
};

const AssessmentStateCell: React.FC<AssessmentStateCellProps> = ({
  completed,
  questionsAndAnswers,
  deletedQuestionsAndAnswers,
}) => {
  if (!completed) {
    return <Text color="gray.500">No assessment yet</Text>;
  }

  const answeredDeletedQuestions = deletedQuestionsAndAnswers.filter(
    (question) => {
      return question.answer && question.answer !== "";
    }
  );

  const numAnswers = questionsAndAnswers.filter(
    (q) => q.answer && q.answer !== ""
  ).length;
  const rows = (
    <Box>
      {questionsAndAnswers.map((questionAndAnswer, idx, arr) => {
        const { questionId, question, answer } = questionAndAnswer;
        const isNew = numAnswers > 0 && !blankAnswer(answer);
        const lastIdx =
          idx === arr.length - 1 && answeredDeletedQuestions.length === 0;
        return (
          <Box
            bg="white"
            mb={!lastIdx ? "2" : undefined}
            borderTop={idx && "1px"}
            borderBottom={!lastIdx ? "1px" : undefined}
            borderColor="gray.100"
            key={questionId}
          >
            <PopoverHeader p={4}>
              <Text fontWeight="500" fontSize="sm" color="gray.900">
                {question}
              </Text>
            </PopoverHeader>
            <PopoverBody px={4} py={3}>
              <Text
                fontWeight="400"
                fontSize="sm"
                color={answer ? "gray.700" : "gray.500"}
              >
                {answer ||
                  (isNew
                    ? "Question was added after trainee already submitted their answers."
                    : "No answer yet.")}
              </Text>
            </PopoverBody>
          </Box>
        );
      })}
      {answeredDeletedQuestions.length > 0 && (
        <>
          <Box px={2} py={2} background="gray.100">
            <Text fontSize="sm" pl="2" fontWeight="500" color="gray.800">
              {`${answeredDeletedQuestions.length} Deleted Question${
                answeredDeletedQuestions.length > 1 ? "s" : ""
              }`}
            </Text>
          </Box>
          {answeredDeletedQuestions.map((questionAndAnswer, idx, arr) => {
            const { questionId, question, answer } = questionAndAnswer;
            const lastIdx = idx === arr.length - 1;
            return (
              <Box
                mb={!lastIdx ? "2" : undefined}
                borderTop={idx && "1px"}
                borderBottom={!lastIdx ? "1px" : undefined}
                borderColor="gray.100"
                key={questionId}
              >
                <PopoverHeader p={4}>
                  <Text fontWeight="500" fontSize="sm" color="gray.900">
                    {question}
                  </Text>
                </PopoverHeader>
                <PopoverBody px={4} py={3}>
                  <Text fontWeight="400" fontSize="sm" color="gray.700">
                    {answer || "No answer."}
                  </Text>
                </PopoverBody>
              </Box>
            );
          })}
        </>
      )}
    </Box>
  );
  return (
    <Popover isLazy placement="bottom-end" trigger="hover" autoFocus={false}>
      <PopoverTrigger>
        <Button color="blue.600" size="sm" variant="link" fontWeight="500">
          View assessment
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          maxHeight="450px"
          minWidth="380px"
          overflowY="auto"
          borderColor="gray.100"
          bg="gray.50"
        >
          {rows}
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

const blankAnswer = (str?: string | null): boolean => {
  return !str || str === "";
};
