import {
  diffInDays,
  MS_PER_DAY,
  MS_PER_HOUR,
  reduceToWeeks,
} from "../../utils/datetime";
import { FilteredHits } from "../OrganizationMetrics/types";
import { BarDataPoint, DisplayInterval } from "./types";

export const prepareTimeSeriesBar = (
  hits: FilteredHits,
  startDatetime: Date,
  endDatetime: Date
): BarDataPoint[] => {
  // 1. Time difference
  // 2. Select how to group the data
  // 3. Track call IDs by date
  const days = diffInDays(startDatetime, endDatetime);
  let grouping = DisplayInterval.Month;
  if (days <= 1) {
    grouping = DisplayInterval.Hour;
  } else if (days <= 93) {
    grouping = DisplayInterval.Day;
  } else if (days <= 180) {
    grouping = DisplayInterval.Week;
  }
  const dateSeries = createDateSeries(startDatetime, endDatetime, grouping);
  const dateSeriesFormat = dateSeries.map((date) =>
    dateStringFormat(date, grouping)
  );
  const candidateArray = new Array(dateSeries.length).fill(0);
  hits.candidateHits.forEach((item) => {
    const itemDate = new Date(item.startTime * 1000);
    const itemDateFormat = dateStringFormat(itemDate, grouping);
    const dateIdx = dateSeriesFormat.indexOf(itemDateFormat);
    candidateArray[dateIdx] += 1;
  });
  const interviewerArray = new Array(dateSeries.length).fill(0);
  hits.interviewerHits.forEach((item) => {
    const itemDate = new Date(item.startTime * 1000);
    const itemDateFormat = dateStringFormat(itemDate, grouping);
    const dateIdx = dateSeriesFormat.indexOf(itemDateFormat);
    interviewerArray[dateIdx] += 1;
  });

  return dateSeriesFormat.map((dateStr, idx) => {
    return {
      date: dateStr,
      candidate: candidateArray[idx],
      interviewer: interviewerArray[idx],
    };
  });
};

export const createDateSeries = (
  startDatetime: Date,
  endDatetime: Date,
  period: DisplayInterval
): Date[] => {
  const dateArray = [];
  let currentDatetime = new Date(startDatetime);
  while (currentDatetime.getTime() < endDatetime.getTime()) {
    dateArray.push(currentDatetime);
    switch (period) {
      // Assumes startDatetime is floored to 12AM
      case DisplayInterval.Hour:
        currentDatetime = new Date(currentDatetime.getTime() + MS_PER_HOUR);
        break;
      case DisplayInterval.Day:
        currentDatetime = new Date(currentDatetime.getTime() + MS_PER_DAY);
        break;
      case DisplayInterval.Week:
        currentDatetime = new Date(currentDatetime.getTime() + MS_PER_DAY * 7);
        break;
      case DisplayInterval.Month:
        currentDatetime = new Date(
          currentDatetime.getUTCFullYear(),
          currentDatetime.getMonth() + 1
        );
    }
  }
  return dateArray;
};

const dateStringFormat = (date: Date, interval: DisplayInterval): string => {
  switch (interval) {
    case DisplayInterval.Hour:
      return `${date.getHours()}:00`;
    case DisplayInterval.Day:
      return `${date.getMonth() + 1}/${date.getDate()}`;
    case DisplayInterval.Week:
      // eslint-disable-next-line no-case-declarations
      const weekDate = reduceToWeeks(date);
      return `${weekDate.getMonth() + 1}/${weekDate.getDate()}`;
    case DisplayInterval.Month:
      return `${date.getMonth() + 1}-${date.getFullYear()}`;
    default:
      return "";
  }
};
