import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Spacer,
  Textarea,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import Creatable from "react-select/creatable";

import { CallGuideCue } from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";

type Cue = Pick<CallGuideCue, "id" | "description" | "cue">;

interface FormData {
  cue: { value: string; label: string } | string;
  description: { value: string; label: string } | string;
}

interface QuestionFormProps {
  cue?: Cue;
  submitting: boolean;
  hideCancelButton?: boolean;
  onSubmit: (values: { description: string; cue: string }) => void;
  onCancel: () => void;
  onlyQuestion: boolean;
}

const StaticQuestionForm: React.FC<QuestionFormProps> = ({
  cue,
  submitting,
  onSubmit,
  onCancel,
  onlyQuestion,
}) => {
  const guidesDemoEnabled = useFeatureFlag("guidesDemoEnabled");
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      cue: guidesDemoEnabled
        ? {
            label: cue?.cue,
            value: cue?.cue,
          }
        : cue?.cue,
      description: guidesDemoEnabled
        ? {
            label: cue?.description ?? "",
            value: cue?.description ?? "",
          }
        : cue?.description ?? "",
    },
  });

  const submit = handleSubmit((form): void => {
    const cue =
      typeof form.cue === "string" ? form.cue.trim() : form.cue.value.trim();
    const description =
      typeof form.description === "string"
        ? form.description.trim()
        : form.description.value.trim();
    onSubmit({
      cue,
      description,
    });
  });

  const descriptionRegister = register("description");

  return (
    <form data-testid="cue-form" onSubmit={submit}>
      <Flex direction="column">
        <FormControl isRequired isInvalid={errors.cue !== undefined}>
          <FormLabel>Competency</FormLabel>
          <Controller
            name="cue"
            control={control}
            render={({ field: { ref, ...field } }) =>
              guidesDemoEnabled ? (
                <Creatable
                  {...field}
                  aria-label="cue"
                  isClearable
                  isSearchable
                  defaultValue={{
                    value: cue?.cue ?? "",
                    label: cue?.cue ?? "",
                  }}
                  name="cue"
                  options={[
                    { value: "Initiative", label: "Initiative" },
                    {
                      value: "Service Orientation",
                      label: "Service Orientation",
                    },
                    { value: "Business Acumen", label: "Business Acumen" },
                    {
                      value: "Attention to Detail",
                      label: "Attention to Detail",
                    },
                  ]}
                />
              ) : (
                <Input aria-label="cue" {...register("cue")} />
              )
            }
          />
        </FormControl>
        <FormControl isRequired isInvalid={errors.description !== undefined}>
          <FormLabel mt={2}>Question</FormLabel>
          <Controller
            aria-label="description"
            name="description"
            control={control}
            render={({ field: { ref, ...field } }) =>
              guidesDemoEnabled ? (
                <Creatable
                  {...field}
                  defaultValue={{
                    value: cue?.description ?? "",
                    label: cue?.description ?? "",
                  }}
                  isClearable
                  name="description"
                  ref={ref}
                  options={[
                    {
                      value: cue?.description ?? "",
                      label: cue?.description ?? "",
                    },
                    {
                      value:
                        "Tell me about a time you did more than what was asked of you in a situation.",
                      label:
                        "Tell me about a time you did more than what was asked of you in a situation.",
                    },
                    {
                      value:
                        "Imagine you’re working on something and part of the process is not as efficient or effective as it could be. How would you approach the situation?",
                      label:
                        "Imagine you’re working on something and part of the process is not as efficient or effective as it could be. How would you approach the situation?",
                    },
                    {
                      value:
                        "Tell me about the last time you raised your hand to do something.",
                      label:
                        "Tell me about the last time you raised your hand to do something.",
                    },
                    {
                      value:
                        "Think about a time when you were less busy than usual at work. What did you do with any extra time or resources that you had.",
                      label:
                        "Think about a time when you were less busy than usual at work. What did you do with any extra time or resources that you had.",
                    },
                    {
                      value:
                        "Tell me about a time when you spoke up, even if it was difficult or risky to do so.",
                      label:
                        "Tell me about a time when you spoke up, even if it was difficult or risky to do so.",
                    },
                  ]}
                />
              ) : (
                <Textarea
                  aria-label="description"
                  ref={(e) => {
                    descriptionRegister.ref(e);
                    textareaRef.current = e;
                  }}
                  name={descriptionRegister.name}
                  onBlur={descriptionRegister.onBlur}
                  onChange={(e) => {
                    descriptionRegister.onChange(e);
                    const { current } = textareaRef;
                    if (current) {
                      current.style.height = "auto";
                      current.style.height = `${current.scrollHeight}px`;
                    }
                  }}
                />
              )
            }
          />
        </FormControl>
        <Spacer />
        <ButtonGroup
          variant="outline"
          mb="auto"
          justifyContent="flex-end"
          pt={4}
        >
          {!onlyQuestion && (
            <Button size="sm" variant="outline" onClick={onCancel} mr={3}>
              Cancel
            </Button>
          )}
          <Button
            type="submit"
            size="sm"
            variant="solid"
            isLoading={submitting}
          >
            Save
          </Button>
        </ButtonGroup>
        <Spacer />
      </Flex>
    </form>
  );
};

export default StaticQuestionForm;
