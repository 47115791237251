import { ApolloError, MutationTuple } from "@apollo/client";

import {
  AddPositionMutation,
  AddPositionMutationVariables,
  OrgPositionsDocument,
  OrgPositionsQuery,
  useAddPositionMutation,
} from "..";

export default function useAddPosition({
  onCompleted,
  onError,
}: {
  onCompleted?: (data: AddPositionMutation) => void;
  onError?: (error?: Error) => void;
}): [
  MutationTuple<AddPositionMutation, AddPositionMutationVariables>[0],
  { loading: boolean; error: ApolloError | undefined }
] {
  const [addPosition, { error, loading }] = useAddPositionMutation({
    update(cache, { data: addPositionData }) {
      const position = addPositionData?.addPosition?.position;
      if (position) {
        const cachedQuery = cache.readQuery<OrgPositionsQuery>({
          query: OrgPositionsDocument,
        });
        const cachedUser = cachedQuery?.currentUser;
        const cachedPositions =
          cachedQuery?.currentUser?.organization.positions.results;
        if (cachedPositions && cachedUser) {
          cache.writeQuery<OrgPositionsQuery>({
            query: OrgPositionsDocument,
            data: {
              currentUser: {
                ...cachedUser,
                organization: {
                  ...cachedUser.organization,
                  positions: {
                    results: [position, ...cachedPositions],
                  },
                },
              },
            },
          });
        }
      }
    },
    onCompleted,
    onError,
  });
  return [addPosition, { loading, error }];
}
