import * as Sentry from "@sentry/browser";
import qs from "qs";

import config from "../config";
import { ScheduledInterviewListItemFragment } from "../main/graphql";
import { openIA } from "./extension";
import { useSendGAEvent } from "./googleAnalytics";

window.popups = {};

type WindowProps = {
  toolbar?: boolean | ((props: WindowProps, window: Window) => boolean);
  location?: boolean | ((props: WindowProps, window: Window) => boolean);
  directories?: boolean | ((props: WindowProps, window: Window) => boolean);
  status?: boolean | ((props: WindowProps, window: Window) => boolean);
  menubar?: boolean | ((props: WindowProps, window: Window) => boolean);
  scrollbars?: boolean | ((props: WindowProps, window: Window) => boolean);
  resizable?: boolean | ((props: WindowProps, window: Window) => boolean);
  width?: number | ((props: WindowProps, window: Window) => number);
  height?: number | ((props: WindowProps, window: Window) => number);
  top?: number | ((props: WindowProps, window: Window) => number);
  left?: number | ((props: WindowProps, window: Window) => number);
};

const defaultWindowProps = {
  toolbar: false,
  location: false,
  directories: false,
  status: false,
  menubar: false,
  scrollbars: false,
  resizable: true,
  width: 500,
  height: 800,
};

const useInterviewAssistant = (): {
  openInPersonIA: (
    scheduledInterview?: ScheduledInterviewListItemFragment
  ) => Promise<void>;
  openUsingZoomOrNewWindow: (url: string) => void;
  openInterviewAssistant: (args?: Record<string, any>) => Promise<void>;
  focusPopup: (target: string) => void;
  isPopupOpen: (target: string) => boolean;
  openAuthPopup: (path: string) => {
    window: Window | null;
    errorMessage: string | null;
  };
} => {
  const sendGAEvent = useSendGAEvent();
  const windowPropsToString = (windowProps: WindowProps): string => {
    const mergedProps: { [key: string]: any } = {
      ...defaultWindowProps,
      ...windowProps,
    };

    return Object.keys(mergedProps)
      .map((key) => {
        switch (typeof mergedProps[key]) {
          case "function":
            return `${key}=${mergedProps[key](mergedProps, window)}`;
          case "boolean":
            return `${key}=${mergedProps[key] ? "yes" : "no"}`;
          default:
            return `${key}=${mergedProps[key]}`;
        }
      })
      .join(",");
  };

  const getPopup = (target: string): Window | null =>
    window.popups ? window.popups[target] : null;

  const openPopupWindow = (
    path: string,
    target: string,
    windowProps: WindowProps = defaultWindowProps
  ): Window | null => {
    const popup = window.open(
      new URL(path, window.location.origin).toString(),
      target,
      windowPropsToString(windowProps)
    );
    if (window.popups !== undefined) {
      window.popups[target] = popup;
    }
    return popup;
  };

  const openAuthPopup = (
    path: string
  ): { window: Window | null; errorMessage: string | null } => {
    const popup = openPopupWindow(path, "auth");
    let errorMessage = null;
    if (!popup) {
      errorMessage =
        "This authentication method requires launching a popup. Please disable your popup blocker if it's enabled.";
    }
    return {
      window: popup,
      errorMessage,
    };
  };

  const isPopupOpen = (target: string): boolean =>
    getPopup(target)?.closed === false;

  const focusPopup = (target: string): void => {
    getPopup(target)?.focus();
  };

  const openInterviewAssistant = async ({
    path,
    callId,
    ...params
  }: Record<string, any> = {}): Promise<void> => {
    sendGAEvent("call_initiated", "calling");
    let url: string;
    if (path) {
      url = path;
    } else if (callId) {
      url = `interview-assistant/${callId}`;
    } else {
      url = "interview-assistant";
    }
    const queryString = qs.stringify(params);
    if (queryString) {
      url += `?${queryString}`;
    }
    url = new URL(url, config.urlPrefix).href;
    try {
      await openIA(url, window.screen.availHeight);
    } catch (e) {
      if (isPopupOpen("call")) {
        focusPopup("call");
      } else {
        const popup = openPopupWindow(url, "call", {
          height: window.screen.availHeight,
        });
        if (!popup) {
          Sentry.captureMessage("IA popup couldn't be opened");
        }
      }
    }
  };

  const openUsingZoomOrNewWindow = (url: string): void => {
    if (typeof zoomSdk !== "undefined") {
      zoomSdk.openUrl({ url });
    } else {
      window.open(url, "_blank");
    }
  };

  const openInPersonIA = (
    scheduledInterview?: ScheduledInterviewListItemFragment
  ): Promise<void> =>
    openInterviewAssistant({
      isInPersonInterview: true,
      scheduledInterviewId: scheduledInterview?.id,
      candidateId: scheduledInterview?.candidate?.id,
      interviewers: JSON.stringify(
        scheduledInterview?.scheduledInterviewers
          .filter(({ user }) => !!user)
          .map(({ user }) => ({
            id: user?.id,
            fullName: user?.fullName,
            email: user?.email,
          }))
      ),
      name: scheduledInterview?.name,
      positionId: scheduledInterview?.position?.id,
      callGuideId: scheduledInterview?.callGuide?.id,
    });

  return {
    openInPersonIA,
    openUsingZoomOrNewWindow,
    openInterviewAssistant,
    focusPopup,
    isPopupOpen,
    openAuthPopup,
  };
};

export default useInterviewAssistant;
