import {
  Box,
  Button,
  Checkbox,
  PopoverContent,
  Text,
  theme,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import React from "react";
import { IoClose } from "react-icons/io5";

import { CheckmarkIcon } from "../../../components/Icons/CheckmarkIcon";

type ManageTrainingSidebarFilterPopoverProps = {
  programOwners: string[];
  onSort(sort: string): void;
  onFilter(filters: string[]): void;
  activeFilters: string[];
  activeSort: string;
};

const toggleArrayItem = (arr: string[], item: string): string[] => {
  return arr.includes(item) ? arr.filter((f) => f !== item) : [...arr, item];
};

export const ManageTrainingSidebarFilterPopover: React.FC<
  ManageTrainingSidebarFilterPopoverProps
> = ({ activeFilters, activeSort, programOwners, onSort, onFilter }) => {
  const ownerFilters = programOwners.map((owner: string) => (
    <Checkbox
      spacing="14px"
      onChange={() => {
        onFilter(toggleArrayItem(activeFilters, owner));
      }}
      isChecked={activeFilters.includes(owner)}
      key={owner}
      variant="ghostOutline"
      icon={<CheckmarkIcon width="9px" height="6px" />}
    >
      <Text fontWeight="400" fontSize="sm" color="gray.900">
        Created by {owner}
      </Text>
    </Checkbox>
  ));
  return (
    <PopoverContent
      width="300px"
      maxHeight="300px"
      overflowY="auto"
      borderRadius="6px"
      py="3"
      px="4"
      _focus={{ outline: 0 }}
    >
      <Text fontSize="xs" color="gray.400" fontWeight="500" mb="2">
        Sort
      </Text>
      <VStack align="flex-start">
        <Checkbox
          spacing="12px"
          isChecked={activeSort === "A-Z"}
          onChange={() => onSort("A-Z")}
          icon={<CheckmarkIcon />}
          variant="checkOnly"
        >
          <Text fontWeight="400" fontSize="sm" color="gray.900">
            A-Z
          </Text>
        </Checkbox>
        <Checkbox
          spacing="12px"
          isChecked={activeSort === "Date Created"}
          onChange={() => onSort("Date Created")}
          icon={<CheckmarkIcon />}
          variant="checkOnly"
        >
          <Text fontWeight="400" fontSize="sm" color="gray.900">
            Date Created
          </Text>
        </Checkbox>
      </VStack>
      <Text fontSize="xs" color="gray.400" fontWeight="500" mt="4" mb="2">
        Filter
      </Text>
      <VStack align="flex-start" spacing="2">
        {ownerFilters}
      </VStack>
    </PopoverContent>
  );
};

export const ActiveFilterButtons: React.FC<{
  onClick(update: string[]): void;
  activeFilters: string[];
}> = ({ onClick, activeFilters }) => {
  if (!activeFilters.length) {
    return null;
  }
  return (
    <Box px="3" mb="3">
      <Wrap>
        {activeFilters.map((filter) => (
          <WrapItem key={filter}>
            <ActiveFilterButton
              onClick={() => onClick(toggleArrayItem(activeFilters, filter))}
              label={`by ${filter}`}
            />
          </WrapItem>
        ))}
      </Wrap>
    </Box>
  );
};

const ActiveFilterButton: React.FC<{ onClick(): void; label: string }> = ({
  onClick,
  label,
}) => {
  return (
    <Button
      onClick={onClick}
      variant="outline"
      fontWeight="400"
      fontSize="xs"
      color="gray.900"
      py="1"
      pl="3"
      pr="2"
      borderColor="gray.100"
      bg="gray.50"
      borderRadius="4px"
      size="xs"
      textTransform="none"
      rightIcon={<IoClose size="14" fill={theme.colors.gray["400"]} />}
      _hover={{
        bg: "gray.100",
      }}
    >
      {label}
    </Button>
  );
};
