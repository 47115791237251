import React from "react";

import { useToast } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { CurrentUserFragment, UserSubscriptionSettings } from "../../graphql";
import useUpdateCurrentUserSubscriptionsSettings from "../../graphql/hooks/useUpdateCurrentUserSubscriptionsSettings";
import NotificationsSettingsForm, {
  FormData,
} from "./NotificationsSettingsForm";
import SettingsPageContainer from "./shared/SettingsPageContainer";

interface NotificationsSettingsProps {
  currentUser: CurrentUserFragment;
}

const NotificationsSettings: React.FC<NotificationsSettingsProps> = ({
  currentUser,
}) => {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();
  const [updateCurrentUserSubscriptionsSettings, { error }] =
    useUpdateCurrentUserSubscriptionsSettings({
      onCompleted: (user) => {
        if (user) {
          sendGAEvent("updated", "user_notification_settings");
          toast({
            title: "Settings saved",
            status: "success",
          });
        }
      },
      onError: () => {
        toast({
          title: "Error updating user notification preferences",
          status: "error",
        });
      },
    });

  const getEnumValueFromFormData = (
    formData: FormData,
    settingKey: string
  ): UserSubscriptionSettings => {
    let emailProp: keyof FormData = "interviewRecapSubscriptionEmail";
    let slackProp: keyof FormData = "interviewRecapSubscriptionSlack";

    if (settingKey === "interviewPrepSubscription") {
      emailProp = "interviewPrepSubscriptionEmail";
      slackProp = "interviewPrepSubscriptionSlack";
    }
    if (settingKey === "weeklyRecapSubscription") {
      emailProp = "weeklyRecapSubscriptionEmail";
      slackProp = "weeklyRecapSubscriptionSlack";
    }
    if (settingKey === "monthlyAnalyticsSubscription") {
      emailProp = "monthlyAnalyticsSubscriptionEmail";
    }
    if (settingKey === "callShareSubscription") {
      emailProp = "callShareSubscriptionEmail";
      slackProp = "callShareSubscriptionSlack";
    }
    if (settingKey === "callNoteSubscription") {
      emailProp = "callNoteSubscriptionEmail";
      slackProp = "callNoteSubscriptionSlack";
    }
    if (settingKey === "morningDigestSubscription") {
      emailProp = "morningDigestSubscriptionEmail";
      slackProp = "morningDigestSubscriptionSlack";
    }

    const emailSettingValue = formData[emailProp];
    const slackSettingValue = formData[slackProp];
    if (emailSettingValue && slackSettingValue)
      return UserSubscriptionSettings.All;

    if (emailSettingValue && !slackSettingValue)
      return UserSubscriptionSettings.Email;

    if (!emailSettingValue && slackSettingValue)
      return UserSubscriptionSettings.Slack;

    return UserSubscriptionSettings.None;
  };

  const getCheckboxValueFromProperty = (
    settingValue: UserSubscriptionSettings,
    isSlack: boolean
  ): boolean => {
    if (settingValue === UserSubscriptionSettings.All) return true;
    if (settingValue === UserSubscriptionSettings.None) return false;
    if (isSlack) return settingValue === UserSubscriptionSettings.Slack;
    return settingValue === UserSubscriptionSettings.Email;
  };

  const onSubmit = (formData: FormData): void => {
    updateCurrentUserSubscriptionsSettings({
      variables: {
        interviewRecapSubscription: getEnumValueFromFormData(
          formData,
          "interviewRecapSubscription"
        ),
        interviewPrepSubscription: getEnumValueFromFormData(
          formData,
          "interviewPrepSubscription"
        ),
        weeklyRecapSubscription: getEnumValueFromFormData(
          formData,
          "weeklyRecapSubscription"
        ),
        monthlyAnalyticsSubscription: getEnumValueFromFormData(
          formData,
          "monthlyAnalyticsSubscription"
        ),
        callShareSubscription: getEnumValueFromFormData(
          formData,
          "callShareSubscription"
        ),
        callNoteSubscription: getEnumValueFromFormData(
          formData,
          "callNoteSubscription"
        ),
        morningDigestSubscription: getEnumValueFromFormData(
          formData,
          "morningDigestSubscription"
        ),
      },
    });
  };

  return (
    <SettingsPageContainer
      maxW="unset"
      heading="Notification preferences"
      subHeading="View and update which notifications you receive and where they are sent."
    >
      <NotificationsSettingsForm
        interviewRecapSubscriptionEmail={getCheckboxValueFromProperty(
          currentUser.interviewRecapSubscription,
          false
        )}
        interviewRecapSubscriptionSlack={getCheckboxValueFromProperty(
          currentUser.interviewRecapSubscription,
          true
        )}
        interviewPrepSubscriptionEmail={getCheckboxValueFromProperty(
          currentUser.interviewPrepSubscription,
          false
        )}
        interviewPrepSubscriptionSlack={getCheckboxValueFromProperty(
          currentUser.interviewPrepSubscription,
          true
        )}
        weeklyRecapSubscriptionEmail={getCheckboxValueFromProperty(
          currentUser.weeklyRecapSubscription,
          false
        )}
        weeklyRecapSubscriptionSlack={getCheckboxValueFromProperty(
          currentUser.weeklyRecapSubscription,
          true
        )}
        monthlyAnalyticsSubscriptionEmail={getCheckboxValueFromProperty(
          currentUser.monthlyAnalyticsSubscription,
          false
        )}
        callShareSubscriptionEmail={getCheckboxValueFromProperty(
          currentUser.callShareSubscription,
          false
        )}
        callShareSubscriptionSlack={getCheckboxValueFromProperty(
          currentUser.callShareSubscription,
          true
        )}
        callNoteSubscriptionEmail={getCheckboxValueFromProperty(
          currentUser.callNoteSubscription,
          false
        )}
        callNoteSubscriptionSlack={getCheckboxValueFromProperty(
          currentUser.callNoteSubscription,
          true
        )}
        morningDigestSubscriptionEmail={getCheckboxValueFromProperty(
          currentUser.morningDigestSubscription,
          false
        )}
        morningDigestSubscriptionSlack={getCheckboxValueFromProperty(
          currentUser.morningDigestSubscription,
          true
        )}
        error={error}
        onSubmit={onSubmit}
      />
    </SettingsPageContainer>
  );
};

export default NotificationsSettings;
