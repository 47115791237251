import {
  Input,
  InputElementProps,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { connectSearchBox } from "react-instantsearch-dom";

import config from "../../config";
import useDebounce from "../../hooks/useDebounce";

interface SearchBoxProps extends Omit<InputProps, "onSubmit"> {
  refine: (...args: any[]) => any;
  currentRefinement: string;
  isSearchStalled?: boolean;
  indexContextValue?: any;
  createURL?: any;
  searchOnFocus?: boolean;
  onEnter?: (value: string) => void;
  onFocus?: () => void;
  /** Optional UI to go at the left of the input (default is a search icon) */
  leftElement?: React.ReactNode;
  leftElementProps?: InputElementProps;
  /** Optional UI to go at the right of the input */
  rightElement?: React.ReactNode;
  rightElementProps?: InputElementProps;
}

const SearchBox: React.FC<SearchBoxProps> = ({
  currentRefinement,
  refine,
  onChange,
  onEnter,
  onFocus,
  isSearchStalled,
  indexContextValue,
  createURL,
  searchOnFocus,
  h,
  height,
  placeholder = "Search interviews, candidates, etc.",
  value,
  leftElement = <FiSearch />,
  leftElementProps,
  rightElement,
  rightElementProps,
  ...inputProps
}) => {
  const [search, setSearch] = useState(value ?? currentRefinement);
  const [focused, setFocused] = useState(false);
  const debouncedSearch = useDebounce(search, 350);

  useEffect(
    () => {
      if (focused) {
        refine(search);
      }
    },
    [debouncedSearch, focused] // Only call effect if debounced search term changes
  );

  const finalHeight = h ?? height ?? "8";

  return (
    <form
      noValidate
      action=""
      role="search"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <InputGroup h={finalHeight}>
        <InputLeftElement
          h="100%"
          px="0"
          color="placeholder"
          {...leftElementProps}
        >
          {leftElement}
        </InputLeftElement>
        <Input
          value={search}
          id="search-input"
          fontSize="sm"
          pr={3}
          h="100%"
          pl="9"
          boxShadow="none"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          placeholder={placeholder}
          spellCheck="false"
          maxLength={512}
          bg="white"
          type="search"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(event.currentTarget.value);
            if (onChange) {
              onChange(event);
            }
          }}
          onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
            if (onFocus) {
              onFocus();
            }
            setFocused(true);
            const text = event.currentTarget.value;
            if (searchOnFocus && !text.length) {
              setSearch(" ");
            }
          }}
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            setFocused(false);
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter" && onEnter) {
              onEnter(event.currentTarget.value);
            }
          }}
          {...inputProps}
        />
        {rightElement && (
          <InputRightElement h="100%" {...rightElementProps}>
            {rightElement}
          </InputRightElement>
        )}
      </InputGroup>
    </form>
  );
};

const SearchBoxTest: React.FC = () => null;

export default config.appEnv === "test"
  ? SearchBoxTest
  : connectSearchBox(SearchBox);

export const focusSearchBox = (): void =>
  document.getElementById("search-input")?.focus();
