import { Box, Text } from "@chakra-ui/react";
import React from "react";

import AddTraineesSelect from "./AddTraineesSelect";
import { TrainingProgram } from "./types";
import { TrainingApi } from "./useTrainingApi";

type AddTraineesStepProps = {
  trainingProgramId: string;
  trainees: TrainingProgram["trainingProgramTrainees"];
  trainingApi: TrainingApi;
  setAutoSaveInProgress: (autoSaveInProgress: boolean) => void;
};

const AddTraineesStep: React.FC<AddTraineesStepProps> = ({
  trainingProgramId,
  trainees,
  trainingApi,
  setAutoSaveInProgress,
}) => {
  return (
    <Box mt="10" mx="10" maxWidth="1012px" width="100%">
      <Text color="gray.700" mb="10" fontWeight="400">
        New trainees can be added to this training program anytime after
        it&apos;s published.
      </Text>

      <AddTraineesSelect
        trainingProgramId={trainingProgramId}
        trainees={trainees}
        onSelect={(value) => {
          trainingApi.trainee.add(trainingProgramId, [value.user.id]);
          setAutoSaveInProgress(true);
        }}
        onRemove={(value) => {
          const trainee = trainees.filter((x) => x.id === value.id)[0];
          if (!trainee) return;
          trainingApi.trainee.delete(trainee.id);
          setAutoSaveInProgress(true);
        }}
        onClear={() => {
          trainingApi.trainee.deleteAll(trainingProgramId);
          setAutoSaveInProgress(true);
        }}
        canClearAll
      />
    </Box>
  );
};

export default AddTraineesStep;
