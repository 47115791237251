import { formatDuration } from "./datetime";

export const formatSegment = (
  speakerLabels: Array<string>,
  segment: { speakerTag: number; startTime: number; words: { word: string }[] }
): string => {
  const speaker = speakerLabels[segment.speakerTag - 1];
  const timeStamp = formatDuration(segment.startTime);
  const text = segment.words.reduce<string>(
    (previous, current) => `${previous}${current.word} `,
    ""
  );
  return `${speaker}  ${timeStamp}\n${text}`;
};
