import { ApolloError } from "@apollo/client";
import {
  Checkbox,
  Divider,
  FormControl,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";

import { Alert } from "../../../components";
import { UserRoleName } from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";

export interface FormData {
  interviewRecapSubscriptionEmail: boolean;
  interviewRecapSubscriptionSlack: boolean;
  interviewPrepSubscriptionEmail: boolean;
  interviewPrepSubscriptionSlack: boolean;
  weeklyRecapSubscriptionEmail: boolean;
  weeklyRecapSubscriptionSlack: boolean;
  monthlyAnalyticsSubscriptionEmail: boolean;
  callShareSubscriptionEmail: boolean;
  callShareSubscriptionSlack: boolean;
  callNoteSubscriptionEmail: boolean;
  callNoteSubscriptionSlack: boolean;
  morningDigestSubscriptionEmail: boolean;
  morningDigestSubscriptionSlack: boolean;
}

interface NotificationsSettingsFormProps {
  interviewRecapSubscriptionEmail: boolean;
  interviewRecapSubscriptionSlack: boolean;
  interviewPrepSubscriptionEmail: boolean;
  interviewPrepSubscriptionSlack: boolean;
  weeklyRecapSubscriptionEmail: boolean;
  weeklyRecapSubscriptionSlack: boolean;
  monthlyAnalyticsSubscriptionEmail: boolean;
  callShareSubscriptionEmail: boolean;
  callShareSubscriptionSlack: boolean;
  callNoteSubscriptionEmail: boolean;
  callNoteSubscriptionSlack: boolean;
  morningDigestSubscriptionEmail: boolean;
  morningDigestSubscriptionSlack: boolean;
  error: ApolloError | undefined;
  onSubmit: (formData: FormData) => void;
}

const NotificationsSettingsForm: React.FC<NotificationsSettingsFormProps> = ({
  interviewRecapSubscriptionEmail,
  interviewRecapSubscriptionSlack,
  interviewPrepSubscriptionEmail,
  interviewPrepSubscriptionSlack,
  weeklyRecapSubscriptionEmail,
  weeklyRecapSubscriptionSlack,
  monthlyAnalyticsSubscriptionEmail,
  callShareSubscriptionEmail,
  callShareSubscriptionSlack,
  callNoteSubscriptionEmail,
  callNoteSubscriptionSlack,
  morningDigestSubscriptionEmail,
  morningDigestSubscriptionSlack,
  error,
  onSubmit,
}) => {
  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: {
      interviewRecapSubscriptionEmail,
      interviewRecapSubscriptionSlack,
      interviewPrepSubscriptionEmail,
      interviewPrepSubscriptionSlack,
      weeklyRecapSubscriptionEmail,
      weeklyRecapSubscriptionSlack,
      monthlyAnalyticsSubscriptionEmail,
      callShareSubscriptionEmail,
      callShareSubscriptionSlack,
      callNoteSubscriptionEmail,
      callNoteSubscriptionSlack,
      morningDigestSubscriptionEmail,
      morningDigestSubscriptionSlack,
    },
  });

  const currentUser = useCurrentUser();
  const handleCheckboxChange = (): void => {
    handleSubmit((formData) =>
      onSubmit({
        ...formData,
      })
    )();
  };

  const { slackAppInstalled } = currentUser.organization;

  return (
    <form>
      {error?.graphQLErrors?.map(({ message }, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Alert mb={6} key={i} status="error" description={message} />
      ))}
      <Grid
        h="auto"
        templateColumns="repeat(12, 1fr)"
        gridAutoRows="min-content"
        columnGap={14}
      >
        {/* Header */}
        <GridItem colSpan={5}>
          <Text color="gray.500" fontSize="sm" fontWeight="bold">
            Notify me when...
          </Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Text color="gray.500" fontSize="sm" fontWeight="bold">
            via Email
          </Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Text
            color="gray.500"
            fontSize="sm"
            fontWeight="bold"
            hidden={!slackAppInstalled}
          >
            via Slack
          </Text>
        </GridItem>
        {/* interview recording ready */}
        <GridItem mt="20px" colSpan={5}>
          <Text fontSize="sm" fontWeight="bold" mb={2}>
            My interview recording is ready
          </Text>
          <Text fontSize="xs">
            Includes any timestamped notes, reactions, interview questions, and
            a link to view your full recording and transcript.
          </Text>
        </GridItem>
        <GridItem mt="20px" colSpan={2}>
          <FormControl id="interviewRecapSubscriptionEmail" top="25%">
            <Checkbox
              {...register("interviewRecapSubscriptionEmail", {
                onChange: handleCheckboxChange,
              })}
              defaultChecked={interviewRecapSubscriptionEmail}
            />
          </FormControl>
        </GridItem>
        <GridItem mt="20px" colSpan={2}>
          <FormControl
            id="interviewRecapSubscriptionSlack"
            top="25%"
            hidden={!slackAppInstalled}
          >
            <Checkbox
              {...register("interviewRecapSubscriptionSlack", {
                onChange: handleCheckboxChange,
              })}
              defaultChecked={interviewRecapSubscriptionSlack}
            />
          </FormControl>
        </GridItem>
        <GridItem mt="22px" colSpan={12}>
          <Divider borderColor="gray.200" />
        </GridItem>
        {/* upcoming interviews */}
        <GridItem mt="20px" colSpan={5}>
          <Text fontSize="sm" fontWeight="bold" mb={2}>
            I have upcoming interviews
          </Text>
          <Text fontSize="xs">
            Includes links to previous recordings for your candidate, and
            opt-out links for your upcoming interview recording.
          </Text>
        </GridItem>
        <GridItem mt="20px" colSpan={2}>
          <FormControl id="interviewPrepSubscriptionEmail" top="25%">
            <Checkbox
              {...register("interviewPrepSubscriptionEmail", {
                onChange: handleCheckboxChange,
              })}
              defaultChecked={interviewPrepSubscriptionEmail}
            />
          </FormControl>
        </GridItem>
        <GridItem mt="20px" colSpan={2}>
          <FormControl
            id="interviewPrepSubscriptionSlack"
            top="25%"
            hidden={!slackAppInstalled}
          >
            <Checkbox
              {...register("interviewPrepSubscriptionSlack", {
                onChange: handleCheckboxChange,
              })}
              defaultChecked={interviewPrepSubscriptionSlack}
            />
          </FormControl>
        </GridItem>
        <GridItem mt="22px" colSpan={12}>
          <Divider borderColor="gray.200" />
        </GridItem>
        {/* weekly recap */}
        <GridItem mt="20px" colSpan={5}>
          <Text fontSize="sm" fontWeight="bold" mb={2}>
            My weekly recap is ready
          </Text>
          <Text fontSize="xs">
            Includes a summary of your total interview time that week, with
            links to all your recordings.
          </Text>
        </GridItem>
        <GridItem mt="20px" colSpan={2}>
          <FormControl id="weeklyRecapSubscriptionEmail" top="25%">
            <Checkbox
              {...register("weeklyRecapSubscriptionEmail", {
                onChange: handleCheckboxChange,
              })}
              defaultChecked={weeklyRecapSubscriptionEmail}
            />
          </FormControl>
        </GridItem>
        <GridItem mt="20px" colSpan={2}>
          <FormControl
            id="weeklyRecapSubscriptionSlack"
            top="25%"
            hidden={!slackAppInstalled}
          >
            <Checkbox
              {...register("weeklyRecapSubscriptionSlack", {
                onChange: handleCheckboxChange,
              })}
              defaultChecked={weeklyRecapSubscriptionSlack}
            />
          </FormControl>
        </GridItem>
        <GridItem mt="22px" colSpan={12}>
          <Divider borderColor="gray.200" />
        </GridItem>
        {/* monthly analytics recap */}
        {currentUser.userRole?.name === UserRoleName.SiteAdmin && (
          <>
            <GridItem mt="20px" colSpan={5}>
              <Text fontSize="sm" fontWeight="bold" mb={2}>
                My monthly insights recap is ready
              </Text>
              <Text fontSize="xs">
                Includes a summary of your total interviews for the month, with
                links to featured metrics.
              </Text>
            </GridItem>
            <GridItem mt="20px" colSpan={2}>
              <FormControl id="monthlyAnalyticsSubscriptionEmail" top="25%">
                <Checkbox
                  {...register("monthlyAnalyticsSubscriptionEmail", {
                    onChange: handleCheckboxChange,
                  })}
                  defaultChecked={monthlyAnalyticsSubscriptionEmail}
                />
              </FormControl>
            </GridItem>
            <GridItem mt="22px" colSpan={12}>
              <Divider borderColor="gray.200" />
            </GridItem>
          </>
        )}

        {/* morning digest */}
        {slackAppInstalled && (
          <>
            <GridItem mt="20px" colSpan={5}>
              <Text fontSize="sm" fontWeight="bold" mb={2}>
                My morning digest is ready
              </Text>
              <Text fontSize="xs">
                Includes a list of your scheduled interviews for that day.
              </Text>
            </GridItem>
            {/* We don't currently have a morning digest email notification, but will potentially add one down the line. */}
            <GridItem mt="20px" colSpan={2}>
              <FormControl id="morningDigestSubscriptionEmail" top="25%" hidden>
                <Checkbox
                  {...register("morningDigestSubscriptionEmail", {
                    onChange: handleCheckboxChange,
                  })}
                  isDisabled
                />
              </FormControl>
            </GridItem>
            <GridItem mt="20px" colSpan={2}>
              <FormControl id="morningDigestSubscriptionSlack" top="25%">
                <Checkbox
                  {...register("morningDigestSubscriptionSlack", {
                    onChange: handleCheckboxChange,
                  })}
                  defaultChecked={morningDigestSubscriptionSlack}
                />
              </FormControl>
            </GridItem>
            <GridItem mt="22px" colSpan={12}>
              <Divider borderColor="gray.200" />
            </GridItem>
          </>
        )}
        {/* call share */}
        <GridItem mt="20px" colSpan={5}>
          <Text fontSize="sm" fontWeight="bold" mb={2}>
            Someone shares an interview, clip or playlist with me
          </Text>
          <Text fontSize="xs">
            Includes a link to the interview, clip or playlist that was shared.
          </Text>
        </GridItem>
        <GridItem mt="20px" colSpan={2}>
          <FormControl id="callShareSubscriptionEmail" top="25%">
            <Checkbox
              {...register("callShareSubscriptionEmail", {
                onChange: handleCheckboxChange,
              })}
              defaultChecked={callShareSubscriptionEmail}
            />
          </FormControl>
        </GridItem>
        <GridItem mt="20px" colSpan={2}>
          <FormControl
            id="callShareSubscriptionSlack"
            top="25%"
            hidden={!slackAppInstalled}
          >
            <Checkbox
              {...register("callShareSubscriptionSlack", {
                onChange: handleCheckboxChange,
              })}
              defaultChecked={callShareSubscriptionSlack}
            />
          </FormControl>
        </GridItem>
        <GridItem mt="22px" colSpan={12}>
          <Divider borderColor="gray.200" />
        </GridItem>
        {/* call note */}
        <GridItem mt="20px" colSpan={5}>
          <Text fontSize="sm" fontWeight="bold" mb={2}>
            Someone leaves a comment on my interview or clip
          </Text>
          <Text fontSize="xs">Includes a link to the comment.</Text>
        </GridItem>
        <GridItem mt="20px" colSpan={2}>
          <FormControl id="callNoteSubscriptionEmail" top="25%">
            <Checkbox
              {...register("callNoteSubscriptionEmail", {
                onChange: handleCheckboxChange,
              })}
              defaultChecked={callNoteSubscriptionEmail}
            />
          </FormControl>
        </GridItem>
        <GridItem mt="20px" colSpan={2}>
          <FormControl
            id="callNoteSubscriptionSlack"
            top="25%"
            hidden={!slackAppInstalled}
          >
            <Checkbox
              {...register("callNoteSubscriptionSlack", {
                onChange: handleCheckboxChange,
              })}
              defaultChecked={callNoteSubscriptionSlack}
            />
          </FormControl>
        </GridItem>
      </Grid>
    </form>
  );
};

export default NotificationsSettingsForm;
