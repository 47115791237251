import { Box, Flex, Icon, Text, useDisclosure, VStack } from "@chakra-ui/react";
import React from "react";
import { IoLinkSharp } from "react-icons/io5";
import { useLocation } from "react-router-dom";

import { RouterLink, successToast, useToast } from "../../../components";
import useCurrentBreakpoint from "../../../hooks/useCurrentBreakpoint";
import { pluck } from "../../../utils/array";
import { copy } from "../../../utils/clipboard";
import { formatDate } from "../../../utils/datetime";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { Thumbnail, ThumbnailProps } from "../Thumbnail";
import TruncatedText from "../TruncatedText";
import { CallHighlight } from "./types";
import { getCallName } from "./utils";

export interface HighlightProps {
  highlight: CallHighlight;
  onClick?(): void;
}

const Highlight: React.FC<HighlightProps> = ({
  highlight,
  onClick,
  ...rest
}) => {
  // const [deletingHighlight, setDeletingHighlight] =
  //   useState<CallHighlightFragment | null>();

  const location = useLocation();
  const isSmallScreen = useCurrentBreakpoint() === "base";
  const toast = useToast();
  const { call } = highlight;

  const copyLink = (): void => {
    /**
     * This URL generation strategy (`useLocation()` + `window.location.origin`)
     * is needed to get the correct URL on both the main app and when running
     * in the extension
     */
    const url = new URL(window.location.origin);
    url.pathname = location.pathname;
    url.search = location.search;
    url.searchParams.set("highlight", highlight.id);
    copy(url.href);
    successToast(toast, "Link copied to clipboard");
  };

  const thumbnail: ThumbnailProps = {
    imageUrl: highlight.thumbnailImageUrl,
    audioOnly: !!call.streamableAudio?.url && !call.streamableVideo?.url,
    onClick,
  };

  const {
    isOpen: isHovering,
    onOpen: onMouseEnter,
    onClose: onMouseLeave,
  } = useDisclosure();
  const trackHover = { onMouseEnter, onMouseLeave };
  const sendGAEvent = useSendGAEvent();

  const highlightDetails = (
    <VStack
      mt={{ base: "0", sm: "2" }}
      spacing={{ base: "0.5", sm: "1" }}
      alignItems="start"
      fontSize="sm"
      color="gray.800"
    >
      <TruncatedText
        text={highlight.text}
        fontWeight="semibold"
        noOfLines={3}
        fontSize="md"
        lineHeight="5"
      />
      <RouterLink
        to={`/interview/${call.id}?t=${highlight.startTime}`}
        fontWeight="normal"
        onClick={(e) => {
          sendGAEvent("view_interview", "candidate", "highlight_title");
          e.stopPropagation();
        }}
      >
        {getCallName(call)}
      </RouterLink>

      <Flex w="100%">
        <Box>{pluck(call.interviewers, "fullName").join(", ")}</Box>

        {isSmallScreen && isHovering && (
          <Flex
            ml="auto"
            alignItems="center"
            onClick={(e) => {
              copyLink();
              e.stopPropagation();
            }}
          >
            <Flex alignItems="center" color="blue.600" cursor="pointer">
              <Icon as={IoLinkSharp} boxSize="4" mr="1" />
              <Text fontSize="xs">Copy link</Text>
            </Flex>
          </Flex>
        )}
      </Flex>
    </VStack>
  );

  if (isSmallScreen) {
    return (
      <Flex gap="4" {...rest} {...trackHover}>
        <Box flex="0" flexBasis="16">
          <Thumbnail {...thumbnail} aspectRatio={1} iconSize="24" />

          <Box
            color="gray.600"
            fontSize="xs"
            mt="2"
            lineHeight="4"
            flexShrink={0}
          >
            {formatDate(call.createdAt, {
              year: "2-digit",
              month: "short",
              day: "numeric",
            })}
          </Box>
        </Box>

        <Box flex="1" onClick={onClick}>
          {highlightDetails}
        </Box>
      </Flex>
    );
  }

  return (
    <Box {...rest}>
      <Thumbnail
        aspectRatio={16 / 9}
        {...thumbnail}
        iconSize="48"
        time={highlight.startTime}
        jumpTo
        actions={{
          primary: [
            {
              icon: IoLinkSharp,
              handler: copyLink,
              label: "Copy Link",
            },
          ],
          // more: [],
        }}
        // TODO: uncomment when we add back the delete functionality
        //     if (highlight?.canDelete) {
        //   actions.more.push({
        //     icon: IoTrashSharp,
        //     handler: () => {
        //       if (pause) pause();
        //       setDeletingHighlight(highlight);
        //     },
        //     label: "Delete",
        //   });
        // }
      />
      {/*
          TODO: uncomment when adding back the delete functionality
        {deletingHighlight && (
          <DeleteHighlightModal
            highlight={deletingHighlight}
            onClose={() => {
              setDeletingHighlight(null);
            }}
          />
        )} */}

      <VStack
        mt="2"
        alignItems="stretch"
        spacing="1"
        fontSize="sm"
        color="gray.800"
      >
        {highlightDetails}

        <Box color="gray.600" lineHeight="4">
          {formatDate(call.createdAt, {
            year: "numeric",
            month: "short",
            day: "numeric",
          })}
        </Box>
      </VStack>
    </Box>
  );
};

export default Highlight;
