import { ApolloError } from "@apollo/client";
import { Box, Text, Wrap } from "@chakra-ui/react";
import React from "react";

import { Alert, LoadingIndicator, useTheme } from "../../../../components";
import { uppercaseFirstLetterLowercaseRest } from "../../../../utils/string";
import { CallSpeakerFragment, TrackerGroupFragment } from "../../../graphql";
import { TrackerGroup } from "../TrackerGroup";
import {
  LabeledTrackerTabGroup,
  LabeledTrackerTabKeyword,
  TrackerTabKeyword,
  TrackerTabState,
} from "../types";
import { createQuestionLabel } from "../utils";

export type TrackerKeywordViewProps = {
  setTrackerTabState: (trackerTabState: TrackerTabState) => void;
  trackerTabState?: TrackerTabState;
  trackers?: TrackerGroupFragment[] | null;
  speakers?: CallSpeakerFragment[] | null;
  loading: boolean;
  error?: ApolloError | null;
};

const TrackerKeywordView: React.FC<TrackerKeywordViewProps> = ({
  setTrackerTabState,
  trackerTabState,
  speakers,
  trackers,
  loading,
  error,
}) => {
  const { colors } = useTheme();

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return (
      <Box>
        <Alert
          status="error"
          title="Error loading trackers"
          description={error.message}
          reload
        />
      </Box>
    );
  }

  if (!trackers || !speakers || trackers.length === 0) {
    return (
      <Text fontSize="14" color={colors.gray[500]}>
        No keyword trackers were found in this interview
      </Text>
    );
  }

  const transformedTrackers: LabeledTrackerTabGroup[] = trackers.map((t) => ({
    ...t,
    label: uppercaseFirstLetterLowercaseRest(t.name),
    trackerKeywords: splitKeywords(
      t.trackerKeywords || [],
      speakers.map((s) => s.label) || []
    ),
  }));

  return (
    <Wrap data-testid="tracker-header" overflow="visible" mt="5" spacing="3">
      {transformedTrackers.map((trackerGroup, index) => (
        <React.Fragment key={trackerGroup.id}>
          <TrackerGroup
            trackerGroup={trackerGroup}
            setTrackerTabState={setTrackerTabState}
            trackerTabState={trackerTabState}
            index={index}
          />
        </React.Fragment>
      ))}
    </Wrap>
  );
};

/** Splits question keyword by speaker  */
const splitKeywords = (
  trackerKeywords: TrackerTabKeyword[],
  speakerLabels: string[]
): LabeledTrackerTabKeyword[] => {
  return trackerKeywords.flatMap((kw) => {
    if (!kw?.name || !kw?.id) {
      return [];
    }
    if (kw.name !== "?") {
      // The casting is needed to avoid a typescript error where
      // {...kw} is not detected as a valid TrackerTabKeyword, despite
      // literally having exactly that type.
      return {
        ...kw,
        label: uppercaseFirstLetterLowercaseRest(kw?.name || ""),
      };
    }
    return kw.speakerCounts.map((speakerCount) => ({
      ...kw,
      label: createQuestionLabel(speakerLabels[speakerCount.speakerTag - 1]),
      count: speakerCount.count,
      speakerTag: speakerCount.speakerTag,
    }));
  });
};

export default TrackerKeywordView;
