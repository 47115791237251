import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

import { ClipFragment, useUpdateClipMutation } from "../../../graphql";
import ClipForm from "./ClipForm";

interface EditClipModalProps {
  clip: Pick<ClipFragment, "id" | "name" | "startTime" | "endTime">;
  isOpen: boolean;
  onSave: (clip: ClipFragment) => void;
  onClose: () => void;
}

const EditClipModal: React.FC<EditClipModalProps> = ({
  clip,
  isOpen,
  onSave,
  onClose,
}) => {
  const [updateClip, { loading: updateLoading, error: updateError }] =
    useUpdateClipMutation({
      update(cache, { data: updateClipResponse }) {
        const call = updateClipResponse?.updateClip?.clip?.call;
        if (call) {
          cache.modify({
            id: cache.identify(call),
            fields: {
              callClips: () => call.callClips,
            },
          });
        }
      },
      onCompleted: ({ updateClip: updateClipResponse }) => {
        if (updateClipResponse?.clip) {
          onSave(updateClipResponse.clip);
        }
      },
    });

  const handleSubmit = async (formData: { name: string }): Promise<void> => {
    if (clip) {
      await updateClip({
        variables: {
          clipId: clip.id,
          ...formData,
        },
      });
    }
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Edit Clip Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody pt="8px">
            <ClipForm
              name={clip?.name as string}
              startTime={clip?.startTime}
              endTime={clip?.endTime}
              error={updateError}
              isLoading={updateLoading}
              onSubmit={handleSubmit}
              onCancel={onClose}
            />
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default EditClipModal;
