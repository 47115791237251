import { Flex, Heading, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { HiOutlinePhone, HiOutlineVideoCamera } from "react-icons/hi2";

import { RouterLink } from "../../../../components";
import { InterviewsListInterviewers } from "../../../components/InterviewsList";
import {
  CallListItemFragment,
  ScheduledInterviewListItemFragment,
} from "../../../graphql";

type InterviewInterviewerSectionProps = {
  shouldHideInterviewType?: boolean;
  onClickInterview?(): void;
} & (
  | {
      scheduledInterview: ScheduledInterviewListItemFragment;
      call?: never;
    }
  | {
      scheduledInterview?: never;
      call: CallListItemFragment;
    }
);

const InterviewInterviewerSection: React.FC<
  InterviewInterviewerSectionProps
> = ({
  scheduledInterview,
  call,
  shouldHideInterviewType = false,
  onClickInterview,
}) => {
  let interviewers;
  if (scheduledInterview) {
    interviewers = scheduledInterview?.scheduledInterviewers?.map(
      (i) => i.user
    );
  } else {
    interviewers = call.interviewers;
  }
  let iconToDisplay;
  let interviewTypeName = "";
  const interviewType = (
    scheduledInterview?.interviewType?.toString() ||
    call?.type.toString() ||
    ""
  ).toLowerCase();
  if (interviewType.includes("zoom")) {
    interviewTypeName = "Zoom";
    iconToDisplay = HiOutlineVideoCamera;
  } else if (interviewType.includes("google")) {
    interviewTypeName = "Google Meet";
    iconToDisplay = HiOutlineVideoCamera;
  } else if (interviewType.includes("person")) {
    interviewTypeName = "In-person";
    iconToDisplay = HiOutlineLocationMarker;
  } else if (interviewType.includes("teams")) {
    interviewTypeName = "Teams";
    iconToDisplay = HiOutlineVideoCamera;
  } else if (
    interviewType.includes("phone") ||
    interviewType.includes("browser") ||
    interviewType.includes("incoming") ||
    interviewType.includes("outgoing")
  ) {
    interviewTypeName = "Phone";
    iconToDisplay = HiOutlinePhone;
  }

  return (
    <Flex direction="column" overflow="hidden" fontWeight="semibold">
      {scheduledInterview && (
        <Heading
          as="h6"
          size="xs"
          position="relative"
          wordBreak="break-word"
          color="gray.800"
          lineHeight="6"
          fontWeight="inherit"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {scheduledInterview.name || "Untitled"}
        </Heading>
      )}
      {call && (
        <RouterLink
          to={`/interview/${call.id}`}
          color="gray.800"
          fontWeight="inherit"
          _hover={{ textDecoration: "underline", color: "inherit" }}
          onClick={onClickInterview}
        >
          <Heading
            as="h6"
            size="xs"
            wordBreak="break-word"
            position="relative"
            lineHeight="6"
            textOverflow="ellipsis"
            fontWeight="inherit"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            {call.name || "Untitled"}
          </Heading>
        </RouterLink>
      )}

      <InterviewsListInterviewers
        interviewers={interviewers}
        size="xs"
        ml="0"
        marginTop="1.5"
      />

      <Flex mt="1.5" alignItems="center">
        {!shouldHideInterviewType && interviewTypeName && (
          <>
            <Icon as={iconToDisplay} color="gray.500" boxSize="4" />
            <Text
              fontSize="sm"
              color="gray.500"
              ml="1.5"
              fontWeight="500"
              lineHeight="20px"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
            >
              {interviewTypeName}
            </Text>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default InterviewInterviewerSection;
