import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

import colorVars from "../../theme/css-color-variables";

export const SettingsNavLink: React.FC<NavLinkProps> = (props) => {
  const activeStyle = {
    fontWeight: 600,
    color: colorVars.blue[500],
    borderLeftColor: colorVars.blue[500],
    borderLeftWidth: "2px",
    marginLeft: "-1px",
  };

  return (
    <NavLink
      style={({ isActive }) => ({
        padding: "1rem",
        ":hover": {
          backgroundColor: colorVars.gray[50],
        },
        ...(isActive ? activeStyle : {}),
      })}
      {...props}
    />
  );
};
