import {
  Button,
  Checkbox,
  Code,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  Input,
  Link,
  Spinner,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  IoMdCheckmarkCircleOutline,
  IoMdCloseCircleOutline,
} from "react-icons/io";

import { useToast } from "../../../components";
import {
  CurrentUserFragment,
  GreenhouseSetupDocument,
  useGenerateGreenhouseWebhookSecretMutation,
  useGreenhouseSetupQuery,
  useSetupGreenhouseMutation,
} from "../../graphql";
import SettingsPageContainer from "./shared/SettingsPageContainer";

interface GreenhouseSettingsProps {
  currentUser: CurrentUserFragment;
}

interface FormValues {
  enabled: boolean;
  apiKey: string;
  host: string;
  adminUserId: string;
  syncData: boolean;
  syncCallGuidesFromAts: boolean;
  atsOptInEnabled: boolean;
  greenhouseConsentKey: string;
}

const GreenhouseSettings: React.FC<GreenhouseSettingsProps> = ({
  currentUser,
}) => {
  const toast = useToast();
  const { data, loading } = useGreenhouseSetupQuery();
  const [setupGreenhouse, { loading: setupGreenhouseLoading }] =
    useSetupGreenhouseMutation({
      update: (cache, { data }) => {
        const settings = data?.setupGreenhouse?.setup;
        if (settings) {
          cache.writeQuery({
            query: GreenhouseSetupDocument,
            data: {
              greenhouseSetup: settings,
            },
          });
        }
      },
      onError: (err) => {
        toast({
          title: "Error",
          description: `Failed to setup Greenhouse: ${err.message}`,
          status: "error",
        });
      },
    });
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    trigger,
  } = useForm<FormValues>({});
  const watchValues = watch();

  const onSubmit = handleSubmit((formValues) => {
    let apiKey;
    if (!formValues.apiKey.includes("XXXX")) {
      apiKey = formValues.apiKey;
    }
    setupGreenhouse({
      variables: {
        enabled: formValues.enabled,
        apiKey,
        host: formValues.host,
        adminUserId: formValues.adminUserId,
        syncCallGuidesFromAts: formValues.syncCallGuidesFromAts,
        atsOptInEnabled: formValues.atsOptInEnabled,
        greenhouseConsentKey: formValues.greenhouseConsentKey,
      },
    });
  });

  useEffect(() => {
    setValue("enabled", data?.greenhouseSetup?.enabled ?? false);
    setValue("apiKey", data?.greenhouseSetup?.apiKey || "");
    setValue("host", data?.greenhouseSetup?.host || "app.greenhouse.io");
    setValue("adminUserId", data?.greenhouseSetup?.adminUserId || "");
    setValue(
      "syncCallGuidesFromAts",
      data?.greenhouseSetup?.syncCallGuidesFromAts ?? false
    );
    setValue(
      "atsOptInEnabled",
      data?.greenhouseSetup?.atsOptInEnabled ?? false
    );
    setValue(
      "greenhouseConsentKey",
      data?.greenhouseSetup?.greenhouseConsentKey ?? ""
    );
  }, [data]);

  const [generateSecret, { loading: generateSecretLoading }] =
    useGenerateGreenhouseWebhookSecretMutation({
      update(cache, { data: mutationData }) {
        const settings = data?.greenhouseSetup;
        const webhookSecretKey =
          mutationData?.generateGreenhouseWebhookSecret?.webhookSecretKey;
        if (settings && webhookSecretKey) {
          const settings = data?.greenhouseSetup;

          cache.writeQuery({
            query: GreenhouseSetupDocument,
            data: {
              greenhouseSetup: { ...settings, webhookSecretKey },
            },
          });
        }
      },
      onError: (err) => {
        toast({
          title: "Error",
          description: `Failed to generate Greenhouse Webook Secret: ${err.message}`,
          status: "error",
        });
      },
    });

  const isLoading = loading || setupGreenhouseLoading;

  return (
    <SettingsPageContainer heading="Greenhouse">
      <Flex dir="column" my={8}>
        <Text fontWeight="bold" mr={2}>
          Integration Status:{" "}
        </Text>
        {(isLoading && <Spinner />) ||
          (data?.greenhouseSetup?.valid && (
            <Icon
              mt="2px"
              as={IoMdCheckmarkCircleOutline}
              color="green.400"
              height="5"
              width="5"
            />
          )) || (
            <>
              <Icon
                mt="2px"
                as={IoMdCloseCircleOutline}
                color="red.600"
                height="5"
                width="5"
              />
              <Text> {data?.greenhouseSetup?.invalidReason}</Text>
            </>
          )}
      </Flex>
      <form onSubmit={onSubmit}>
        <VStack spacing="8" align="start">
          <FormControl id="enabled" maxW="400">
            <Checkbox
              {...register("enabled")}
              isChecked={watchValues.enabled}
              onChange={(e) => {
                setValue("enabled", e.target.checked);
              }}
            >
              Enabled
            </Checkbox>
          </FormControl>
          <FormControl id="syncCallGuidesFromAts" maxW="400">
            <Checkbox
              {...register("syncCallGuidesFromAts")}
              isChecked={watchValues.syncCallGuidesFromAts}
              onChange={(e) => {
                setValue("syncCallGuidesFromAts", e.target.checked);
              }}
            >
              Sync Guides
            </Checkbox>
          </FormControl>
          <FormControl id="apiKey" isRequired maxW="400">
            <FormLabel>API Key</FormLabel>
            <Input {...register("apiKey")} type="password" />
          </FormControl>
          <FormControl id="host" isInvalid={!!errors?.host} maxW="400">
            <FormLabel>Host</FormLabel>
            <Input
              placeholder="app.greenhouse.io"
              {...register("host", {
                onBlur: () => trigger("host"),
                validate: {
                  http: (value) => value.match(/^http/) === null,
                  slash: (value) => value.match(/\//g) === null,
                  other: (value) => value.match(/^[0-9a-z-.]+$/g) !== null,
                },
              })}
            />
            <FormErrorMessage>
              {errors.host?.type === "http" && (
                <>
                  Remove the&nbsp;<Code>http</Code>&nbsp;
                </>
              )}
              {errors.host?.type === "slash" && (
                <>
                  Remove the&nbsp;<Code>/</Code>
                </>
              )}
              {errors.host?.type === "other" && (
                <>Only include letters, numbers, and hyphens</>
              )}
            </FormErrorMessage>
          </FormControl>
          <FormControl id="adminUserId" isRequired maxW="400">
            <FormLabel>Admin User ID</FormLabel>
            <Input {...register("adminUserId")} />
          </FormControl>
          <FormControl
            id="atsOptInEnabled"
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            mt={2}
          >
            <FormLabel my="3">ATS Opt In Enabled</FormLabel>
            <Checkbox
              {...register("atsOptInEnabled")}
              isChecked={watchValues.atsOptInEnabled}
              onChange={(e) => {
                setValue("atsOptInEnabled", e.target.checked);
              }}
            />
            <FormHelperText>
              By turning this on any ATS-controlled candidate will be required
              to grant explicit consent during the application process to be
              recorded. They will provide recording consent during application
              and will not receive an opt-out email because their recording
              settings will now be controlled by the ATS. See
              <Link
                href="http://help.brighthire.ai/en/articles/8470773-ats-opt-in-recording-consent"
                target="_blank"
              >
                {" this help doc "}
              </Link>
              for more information.
            </FormHelperText>
          </FormControl>
          <FormControl
            id="greenhouseConsentKey"
            mt={2}
            isDisabled={watchValues.atsOptInEnabled === false}
            maxW="400"
          >
            <FormLabel>Greenhouse Consent Key</FormLabel>
            <Input {...register("greenhouseConsentKey")} />
          </FormControl>
          <Button type="submit" disabled={isLoading}>
            Save
          </Button>
        </VStack>
      </form>
      {data?.greenhouseSetup?.valid && (
        <>
          <Stack mt={4} spacing={2}>
            <Text fontWeight="bold">Webhook Secret</Text>
            <Text>
              The webhook secret is generated by BrightHire to use with
              Greenhouse.
            </Text>
            <HStack spacing={2}>
              <Input
                readOnly
                placeholder="No secret generated."
                value={data?.greenhouseSetup?.webhookSecretKey || ""}
                width={450}
              />
              <Button
                onClick={() => {
                  generateSecret({
                    variables: {},
                  });
                }}
                disabled={generateSecretLoading}
              >
                Generate
              </Button>
            </HStack>
          </Stack>
          <Stack mt={4} spacing={2}>
            <Text fontWeight="bold">Webhook Url</Text>
            <Text>
              Please use this url when setting up webhooks in Greenhouse.
            </Text>
            <Input
              readOnly
              value={`https://app.brighthire.ai/callback/greenhouse/webhook/${currentUser.organization.id}`}
            />
          </Stack>
        </>
      )}
    </SettingsPageContainer>
  );
};

export default GreenhouseSettings;
