import { Box } from "@chakra-ui/react";
import React from "react";

const AiNotesMessage: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Box
    data-tour-id="ai-notes-message-container"
    alignItems="center"
    display="flex"
    flexDir="column"
    h="100%"
    pt="124px"
    px="6"
    width="100%"
  >
    {children}
  </Box>
);

export default AiNotesMessage;
