import { Box } from "@chakra-ui/react";
import React from "react";

import { StarRating } from "../../../components";
import { StarRatingProps } from "../../../components/StarRating/StarRating";
import {
  Call,
  RatingDescriptions,
  useUpdateCallRatingMutation,
} from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";

type CallStarRatingProps = {
  call: Pick<Call, "id" | "rating" | "status" | "interviewerIds">;
  ratingDescriptions?: RatingDescriptions;
  showNoRating?: boolean;
} & Omit<StarRatingProps, "ratingDescriptions">;

const CallStarRating: React.FC<CallStarRatingProps> = ({
  call,
  onChange,
  showNoRating = false,
  ratingDescriptions,
  ...rest
}) => {
  const currentUser = useCurrentUser();
  const [updateCallRating] = useUpdateCallRatingMutation();
  const readOnly = !call.interviewerIds.includes(currentUser.id);
  if (
    (readOnly && (!call.rating || call.status !== "COMPLETED")) ||
    (!readOnly && call.status !== "COMPLETED")
  ) {
    return showNoRating ? (
      <Box as="span" color="placeholder" fontSize="xs">
        (no rating)
      </Box>
    ) : null;
  }
  return (
    <StarRating
      readOnly={readOnly}
      rating={call?.rating ?? undefined}
      onChange={(rating) => {
        updateCallRating({
          variables: { id: call?.id, rating },
          optimisticResponse: {
            updateCall: {
              __typename: "UpdateCall",
              call: {
                __typename: "Call",
                id: call.id,
                rating,
              },
            },
          },
        });
        onChange?.(rating);
      }}
      ratingDescriptions={
        ratingDescriptions ?? currentUser.organization.ratingDescriptions
      }
      {...rest}
    />
  );
};

CallStarRating.displayName = "CallStarRating";

export default CallStarRating;
