import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

export const Reload: React.FC<IconProps> = (props) => (
  <Icon fill="none" color="white" viewBox="0 0 24 25" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.30628 10.0933C4.59276 5.29208 9.52779 2.44284 14.329 3.72931C15.9114 4.15333 17.2837 4.9749 18.3649 6.05803C18.3648 6.05796 18.3649 6.05809 18.3649 6.05803L20.2654 7.95852V4.77835C20.2654 4.36414 20.6011 4.02835 21.0154 4.02835C21.4296 4.02835 21.7654 4.36414 21.7654 4.77835V9.76918C21.7654 10.1844 21.4288 10.521 21.0136 10.521H16.0228C15.6085 10.521 15.2728 10.1852 15.2728 9.77096C15.2728 9.35674 15.6085 9.02096 16.0228 9.02096H19.2065L17.3039 7.11838C16.404 6.21684 15.2617 5.53216 13.9408 5.1782C9.93976 4.10614 5.82723 6.4805 4.75517 10.4815C4.64796 10.8816 4.23671 11.119 3.83661 11.0118C3.43651 10.9046 3.19907 10.4934 3.30628 10.0933ZM12 12.1725C11.8619 12.1725 11.75 12.2845 11.75 12.4225C11.75 12.5606 11.8619 12.6725 12 12.6725C12.1381 12.6725 12.25 12.5606 12.25 12.4225C12.25 12.2845 12.1381 12.1725 12 12.1725ZM10.25 12.4225C10.25 11.4561 11.0335 10.6725 12 10.6725C12.9665 10.6725 13.75 11.4561 13.75 12.4225C13.75 13.389 12.9665 14.1725 12 14.1725C11.0335 14.1725 10.25 13.389 10.25 12.4225ZM20.1626 13.8335C20.5627 13.9407 20.8001 14.3519 20.6929 14.752C19.4065 19.5532 14.4714 22.4025 9.67024 21.116C8.08787 20.692 6.71567 19.8705 5.63452 18.7874L3.73413 16.8858V20.0669C3.73413 20.4811 3.39834 20.8169 2.98413 20.8169C2.56992 20.8169 2.23413 20.4811 2.23413 20.0669V14.3243L7.97677 14.3243C8.39098 14.3243 8.72677 14.6601 8.72677 15.0743C8.72677 15.4885 8.39099 15.8243 7.97677 15.8243L4.79396 15.8243L6.69553 17.7271C7.59538 18.6286 8.73749 19.3131 10.0585 19.6671C14.0595 20.7391 18.172 18.3648 19.2441 14.3638C19.3513 13.9637 19.7625 13.7262 20.1626 13.8335Z"
      fill="currentColor"
    />
  </Icon>
);
