import { Box, Text } from "@chakra-ui/react";
import React from "react";

import { useTheme } from "../../../../components";
import { AnalyticsDimension, MetricName } from "../../../graphql";
import { MetricConfig } from "../MetricConfig";
import { PivotedDataPoint } from "../types";
import { formatValue } from "../utils";
import {
  segmentLabels,
  SegmentName,
  themedSegmentColorsFocused,
} from "./segmentPresentation";

type TooltipContentProps = {
  metric: MetricName;
  secondaryDimension: AnalyticsDimension;
  label: string;
  payload: {
    payload?: PivotedDataPoint; // recharts has payload[n].payload in their api
    dataKey?: string | number;
    value?: string | number | (string | number)[];
  }[];
};

const TooltipContent: React.FC<TooltipContentProps> = ({
  metric,
  secondaryDimension,
  label,
  payload,
}) => {
  const { colors } = useTheme();
  const { displayName, valueFormat } = MetricConfig[metric];
  const segmentColors = themedSegmentColorsFocused(colors);

  return (
    <Box
      background="white"
      p="2"
      borderRadius="lg"
      borderWidth="1px"
      borderColor="gray.200"
      fontSize="xs"
    >
      <Text fontWeight="bold" color="gray.700">
        {label}
      </Text>
      {payload.map((p) => {
        const pivotedDataPoint = p.payload;
        if (!pivotedDataPoint) {
          return null;
        }
        const segment = (p.dataKey as string).split(".")[1] as SegmentName;
        const segmentDataPoint = pivotedDataPoint.data[segment];
        return (
          <Box key={`${p.payload?.dataId || ""}-${segment}`}>
            {segment !== "all" && (
              <Text mt="2" color={segmentColors[segment]} fontWeight="bold">
                {segmentLabels[segment]}
              </Text>
            )}
            <Text color="gray.900">
              {displayName}: {formatValue(segmentDataPoint.value, valueFormat)}
            </Text>
            {metric !== MetricName.TotalInterviews && (
              <Text color="gray.900">
                Interview Count: {segmentDataPoint.countDataPoints}
              </Text>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default TooltipContent;
