import { Flex, Icon, StyleProps, Tooltip } from "@chakra-ui/react";
import React from "react";
import { IconType } from "react-icons";
import { BsEyeSlashFill } from "react-icons/bs";
import { CgOrganisation } from "react-icons/cg";
import { IoMdPeople } from "react-icons/io";
import { IoPeopleCircleSharp } from "react-icons/io5";

import {
  CallVisibility,
  useVisibilityLevelDescriptionsQuery,
} from "../../../graphql";

type ClipVisibilityProps = StyleProps & {
  clipVisibility: CallVisibility;
};

const ClipVisibility: React.FC<ClipVisibilityProps> = ({
  clipVisibility: visibility,
  ...styles
}) => {
  const { data } = useVisibilityLevelDescriptionsQuery();
  const visibilityText =
    data?.visibilityLevelDescriptions.find(
      ({ visibilityLevel }) => visibilityLevel === visibility
    )?.description ?? getDefaultVisibilityText(visibility);

  return (
    <Flex {...styles}>
      <Tooltip label={visibilityText}>
        <span>
          <Icon
            as={visibilityIcon(visibility)}
            boxSize={6}
            p={1}
            color="gray.500"
          />
        </span>
      </Tooltip>
    </Flex>
  );
};

export default ClipVisibility;

const getDefaultVisibilityText = (visibility: CallVisibility): string => {
  switch (visibility) {
    case CallVisibility.Organization:
      return "Visible to your organization";
    case CallVisibility.Private:
      return "Visible to users in the interview and admins + anyone invited";
    case CallVisibility.Public:
      return "Visible to the hiring team and admins + anyone invited";
    case CallVisibility.Restricted:
      return "Visible to specific interviewers and admins + anyone invited";
    default:
      return "";
  }
};

function visibilityIcon(visibility: CallVisibility): IconType {
  switch (visibility) {
    case CallVisibility.Organization:
      return CgOrganisation;
    case CallVisibility.Private:
      return BsEyeSlashFill;
    case CallVisibility.Public:
      return IoMdPeople;
    case CallVisibility.Restricted:
      return IoPeopleCircleSharp;
    default:
      throw new Error("Invalid CallVisibility enum value.");
  }
}
