import { StyleConfig } from "@chakra-ui/theme-tools";

const config: StyleConfig = {
  baseStyle: {
    label: { _disabled: { opacity: 0.7 } },
  },

  sizes: {
    mdLg: {
      control: { boxSize: "5" },
      label: { fontSize: "sm", ml: "2.5", lineHeight: "5" },
      container: { padding: "10px" },
    },
  },

  variants: {
    outline: {
      control: {
        border: "1.5px solid",
        borderColor: "blue.500",
        borderRadius: "4px",
        p: "1px",
        height: "1.2em",
        width: "1.2em",

        _hover: {
          backgroundColor: "blue.500",
        },
      },
    },
    ghostOutline: {
      control: {
        border: "2px solid",
        borderColor: "blue.600",
        borderRadius: "4px",
        p: "4px",
        height: "1em",
        width: "1em",

        _hover: {
          backgroundColor: "transparent",
        },

        _checked: {
          bg: "transparent",
          color: "blue.600",

          _hover: {
            backgroundColor: "transparent",
          },
        },
      },
    },
    checkOnly: {
      control: {
        border: "0",
        borderColor: "transparent",
        height: "1.2em",
        width: "1.2em",

        _checked: {
          bg: "transparent",
          color: "blue.600",

          _hover: {
            backgroundColor: "transparent",
          },
        },
      },
    },
    "blue.600": {
      control: {
        _checked: {
          background: "blue.600",
          borderColor: "blue.600",
        },
      },
    },
    gray: {
      label: {
        ml: "2.5",
        fontSize: "sm",
        color: "gray.900",
        _disabled: {
          color: "gray.200",
        },
      },
      control: {
        boxSize: "5",
        _disabled: {
          borderColor: "gray.200",
          background: "transparent",
        },
      },
    },
  },
};

export default config;
