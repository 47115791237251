import React, { useEffect, useState } from "react";

import { useToast } from "../../../components";
import { UserRole, useSetUserRolesMutation } from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";
import RoleSelect from "../RoleSelect";

interface UserRoleSelectProps {
  userId: string;
  defaultValue: string;
  showDescription?: boolean;
}

const UserRoleSelect: React.FC<UserRoleSelectProps> = ({
  userId,
  defaultValue,
  showDescription = false,
}) => {
  const currentUser = useCurrentUser();
  const toast = useToast();
  const [userRole, setUserRole] =
    useState<
      Pick<UserRole, "id" | "name" | "permissions" | "formattedPermissions">
    >();

  const [setUserRoles] = useSetUserRolesMutation({
    onCompleted: (data) => {
      if (data.setUserRoles) {
        toast({
          title: "Success",
          description: "Updated user role",
          status: "success",
        });
      }
    },
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
  });

  const { userRoles } = currentUser.organization;

  const canModify =
    userId !== currentUser.id && currentUser.userRole?.canManageUserRoles;

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setUserRole(userRoles?.find((u) => u.id === e.target.value));
    setUserRoles({
      variables: {
        users: [{ userId, userRoleId: e.target.value }],
      },
    });
  };

  useEffect(() => {
    if (!userRole) {
      setUserRole(userRoles?.find((r) => r.id === defaultValue));
    }
  }, [userRoles]);

  return (
    <RoleSelect
      userRole={userRole}
      formId={`user-role-select-${userId}`}
      showDescription={showDescription}
      onChange={onChange}
      defaultValue={defaultValue}
      userRoles={userRoles}
      canModify={canModify}
      selectProps={{ "data-testid": "user-role-select" }}
    />
  );
};

export default UserRoleSelect;
