import { Box } from "@chakra-ui/react";
import React from "react";
import { SortingRule } from "react-table";

import { RouterLink, SortableTable } from "../../../components";
import { formatRelativeDate } from "../../../utils/datetime";
import { PageOptions } from "../../../utils/types";
import { Position, PositionListItemFragment } from "../../graphql";
import useLink from "../../hooks/useLink";

type ListHiringTeam = { __typename?: "Position" } & {
  membershipsCount?: number | null;
} & PositionListItemFragment;

interface HiringTeamListProps {
  positions: ListHiringTeam[];
  pageOptions?: PageOptions;
  loading?: boolean;
  sortBy: SortingRule<string>;
}

const HiringTeamList: React.FC<HiringTeamListProps> = ({
  positions,
  sortBy,
  pageOptions,
  loading,
}) => {
  return (
    <Box mb="8">
      <SortableTable<Position>
        columns={[
          {
            Header: "Position Name",
            accessor: "title",
            Cell: ({
              row: { original: position },
            }: {
              row: { original: ListHiringTeam };
            }) => {
              const hiringTeamLink = useLink({
                type: "hiringTeam",
                positionId: position.id,
              });
              return (
                <RouterLink to={hiringTeamLink}>{position.title}</RouterLink>
              );
            },
          },
          {
            Header: "Position Group",
            accessor: (position) => position.client.name,
            id: "client.name",
            Cell: ({
              row: { original: position },
            }: {
              row: { original: ListHiringTeam };
            }) => <>{position.client.name}</>,
          },
          {
            Header: "Members",
            accessor: "membershipsCount",
            Cell: ({
              row: { original: position },
            }: {
              row: { original: ListHiringTeam };
            }) => <>{position.membershipsCount}</>,
          },
          {
            Header: "Date Added",
            accessor: "createdAt",
            Cell: ({
              row: { original: position },
            }: {
              row: { original: ListHiringTeam };
            }) => (
              <Box whiteSpace="nowrap">
                {formatRelativeDate(position.createdAt)}
              </Box>
            ),
          },
        ]}
        data={positions as Position[]}
        initialSort={sortBy}
        manualPagination
        pageOptions={pageOptions}
        loading={loading}
      />
    </Box>
  );
};

export default HiringTeamList;
