import { Box, Center } from "@chakra-ui/react";
import React from "react";
import { SortingRule } from "react-table";

import {
  RouterLink,
  SortableTable,
  SortableTableColumn,
} from "../../../components";
import useCurrentBreakpoint from "../../../hooks/useCurrentBreakpoint";
import { formatRelativeDate } from "../../../utils/datetime";
import { PageOptions } from "../../../utils/types";
import { PositionPageListItemFragment } from "../../graphql";

const largeScreenBreakpoints = ["lg", "xl", "2xl"];
const smallScreenBreakpoints = ["base", "sm"];
interface PositionListProps {
  positions: Array<PositionPageListItemFragment>;
  emptyState?: string;
  pageOptions?: PageOptions;
  loading?: boolean;
  sortBy: SortingRule<string>;
}

const PositionList: React.FC<PositionListProps> = ({
  positions,
  emptyState,
  pageOptions,
  loading,
  sortBy,
}) => {
  const currentBreakpoint = useCurrentBreakpoint();
  const shouldShowCompactView =
    smallScreenBreakpoints.includes(currentBreakpoint);

  const columns: SortableTableColumn<PositionPageListItemFragment>[] = [
    {
      Header: "Position Name",
      accessor: "title",
      Cell: ({ row: { original: position } }) => (
        <RouterLink to={`/position/${position.id}`}>
          {position.title}
        </RouterLink>
      ),
    },
    {
      Header: "Position Group",
      id: "client.name",
      accessor: (position) => position.client.name,
    },
    {
      Header: "Candidates",
      accessor: "candidatesCount",
      show: !shouldShowCompactView,
      Cell: ({ row: { original: position } }) => {
        return <>{position.candidatesCount || 0}</>;
      },
    },
    {
      Header: "Last Interview",
      accessor: "lastCallAt",
      id: "lastCallAt",
      Cell: ({ row: { original: position } }) => (
        <>
          {(shouldShowCompactView ? "Last Interview " : "") +
            formatRelativeDate(position.lastCallAt)}
        </>
      ),
    },
  ];

  if (positions.length < 1 && !loading) {
    return (
      <Box bgColor="gray.50" mt={4} borderRadius={4}>
        <Center fontSize="14px" color="gray.600" p={10}>
          There are no positions to show here.
        </Center>
      </Box>
    );
  }

  return (
    <SortableTable
      columns={columns}
      data={positions}
      manualPagination
      pageOptions={pageOptions}
      width="100%"
      initialSort={sortBy}
      loading={false}
      includeSpacerColumn={largeScreenBreakpoints.includes(currentBreakpoint)}
      shouldShowCompactView={smallScreenBreakpoints.includes(currentBreakpoint)}
    />
  );
};

PositionList.displayName = "PositionList";
export default PositionList;
