import {
  Avatar as ChakraAvatar,
  AvatarProps as ChakraAvatarProps,
} from "@chakra-ui/react";
import React from "react";

import { Tooltip } from "../Tooltip";

type AvatarProps = {
  user: {
    profilePicUrl?: string | null;
    firstName?: string | null;
    lastName?: string | null;
  };
  showTooltip?: boolean;
  isInterviewer?: boolean;
} & ChakraAvatarProps;

const Avatar = React.forwardRef<HTMLSpanElement, AvatarProps>(
  (
    { user, showTooltip = false, isInterviewer = false, size = "xs", ...rest },
    ref
  ) => {
    const fullName = `${user.firstName ?? ""} ${user.lastName ?? ""}`;
    // eslint-disable-next-line no-undef-init
    let label: string | undefined = undefined;
    if (showTooltip) {
      label = fullName;
      if (isInterviewer) {
        label += " (Interviewer)";
      }
    }

    const avatar = (
      <ChakraAvatar
        ref={ref}
        size={size}
        cursor="default"
        name={fullName}
        src={user.profilePicUrl ?? undefined}
        {...rest}
      />
    );
    return (
      <Tooltip
        id="chakra-interviewer-avatar"
        dismissBehavior="auto"
        trigger={avatar}
      >
        {label}
      </Tooltip>
    );
  }
);

export default Avatar;
