import {
  ButtonGroup,
  Icon,
  IconButton,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import LogRocket from "logrocket";
import React from "react";
import { FaPeopleArrows, FaPlus } from "react-icons/fa";
import { HiPhone } from "react-icons/hi2";
import { IoCalendarSharp } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";

import { useIsSmallScreen } from "../../../../hooks/useIsSmallScreen";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import useInterviewAssistant from "../../../../utils/popup";
import { AddScheduledInterviewModal } from "../../../components/ScheduledInterviewModal";
import useCurrentUser from "../../../hooks/useCurrentUser";

const InterviewButtonGroup: React.FC = () => {
  const isSmallScreen = useIsSmallScreen();
  const { openInPersonIA, openInterviewAssistant } = useInterviewAssistant();
  const currentUser = useCurrentUser();
  const sendGAEvent = useSendGAEvent();
  const navigate = useNavigate();
  const location = useLocation();
  const inPersonInterviewEnabled =
    currentUser.organization.allowInPersonMeetings;
  const manualScheduledInterviewsEnabled =
    currentUser.organization.allowManualScheduledInterviews;

  const schedulePath = "/schedule";
  const isSchedulePath = location.pathname === schedulePath;

  const manualScheduleModal = useDisclosure({
    isOpen:
      isSchedulePath && currentUser.organization.allowManualScheduledInterviews,
    onOpen: () => {
      navigate(schedulePath);
      sendGAEvent(
        "call_button",
        "home_page",
        "Manual Schedule",
        isSmallScreen ? "mobile" : ""
      );
    },
    onClose() {
      navigate("/", { replace: true });
    },
  });

  const onOpenInterviewAssistant = (): void => {
    openInterviewAssistant();
    sendGAEvent(
      "call_button",
      "home_page",
      "Call Candidate",
      isSmallScreen ? "mobile" : ""
    );
  };

  return (
    <>
      <AddScheduledInterviewModal {...manualScheduleModal} />
      <ButtonGroup
        colorScheme="blue"
        variant="ghost"
        gap="2"
        /**
         * Note: for whatever reason, this `size` does not work:
         * `size={{ base: "md", lg: " sm" }}`
         */
        size={isSmallScreen ? "md" : "sm"}
        data-tour-id="call-buttons"
      >
        {inPersonInterviewEnabled && (
          <Tooltip label="Start in-person interview">
            <IconButton
              data-tour-id="in-person-manual"
              data-testid="homepage-in-person-interview-button"
              aria-label="Start in-person interview"
              icon={<Icon as={FaPeopleArrows} boxSize="5" />}
              border="1px solid"
              borderColor="gray.100"
              _hover={{ borderColor: "blue.600" }}
              onClick={() => {
                openInPersonIA();
                LogRocket.track("in-person-manual-start");
                sendGAEvent("launch", "in_person", "Manual");
                sendGAEvent(
                  "call_button",
                  "home_page",
                  "In-person",
                  isSmallScreen ? "mobile" : ""
                );
              }}
              onFocus={(e) => e.preventDefault()}
            />
          </Tooltip>
        )}
        {manualScheduledInterviewsEnabled && (
          <Tooltip label="Schedule Interview">
            <span style={{ position: "relative" }}>
              <IconButton
                data-testid="homepage-schedule-interview-button"
                aria-label="Schedule an interview"
                icon={<Icon as={IoCalendarSharp} boxSize="5" />}
                onClick={manualScheduleModal.onOpen}
                border="1px solid"
                borderColor="gray.100"
                _hover={{ borderColor: "blue.600" }}
                // don't show the tooltip on focus - https://github.com/chakra-ui/chakra-ui/issues/5304
                onFocus={(e) => e.preventDefault()}
              />
              {/* The "+" badge on the calendar icon" */}
              <Icon
                as={FaPlus}
                color="blue.600"
                boxSize="10px"
                position="absolute"
                backgroundColor="white"
                borderRadius="50%"
                border="1px solid"
                borderColor="white"
                right="1"
                bottom="1"
                pointerEvents="none"
                zIndex="1"
              />
            </span>
          </Tooltip>
        )}
        <Tooltip label="Call Candidate">
          <IconButton
            onClick={onOpenInterviewAssistant}
            data-testid="homepage-start-call"
            aria-label="Start a Call"
            icon={<Icon as={HiPhone} boxSize="5" />}
            border="1px solid"
            borderColor="gray.100"
            _hover={{ borderColor: "blue.600" }}
          />
        </Tooltip>
      </ButtonGroup>
    </>
  );
};

export default InterviewButtonGroup;
