// DO NOT USE THIS FILE DIRECTLY
// Please use the useTheme hook or css-color-variables file instead

import { dataVizColorRanges } from "../main/pages/analytics/chart/segmentPresentation";

const colorRanges = {
  gray: {
    50: "#F5F6F7",
    100: "#E5E7Ea",
    200: "#D3D8De",
    300: "#B8BEC5",
    400: "#9CA5AE",
    500: "#808B97",
    600: "#67717E",
    700: "#505863",
    800: "#393f47",
    900: "#060E14",
  },
  blue: {
    50: "#EAF9FF",
    100: "#DDF5FF",
    200: "#AEDEFF",
    300: "#7FC6FE",
    400: "#4FAFFD",
    500: "#2398FB",
    600: "#0772D0",
    700: "#0263B0",
    800: "#00467F",
    900: "#002A4F",
  },
  green: {
    50: "#EFFAF6",
    100: "#E2F6EF",
    200: "#BBE9D9",
    300: "#9EDFC8",
    400: "#60CCA5",
    500: "#2DB182",
    600: "#259B71",
    700: "#177856",
    800: "#155C43",
    900: "#093E2B",
  },
  yellow: {
    50: "#FFFCEE",
    100: "#FFF8DB",
    200: "#FFEAAF",
    300: "#FFDC7F",
    400: "#FFCE4D",
    500: "#FFBF1E",
    600: "#E6A606",
    700: "#B38100",
    800: "#805C00",
    900: "#4E3700",
  },
  orange: {
    50: "#FFF5ED",
    100: "#FFEBDB",
    200: "#FFCBAE",
    300: "#FFAE7E",
    400: "#FF954C",
    500: "#FF7E1A",
    600: "#E65600",
    700: "#B43600",
    800: "#811D00",
    900: "#4F0C00",
  },
  red: {
    50: "#FFF5F5",
    100: "#FFE5E6",
    200: "#FBBABB",
    300: "#F28F8E",
    400: "#EB6861",
    500: "#E44435",
    600: "#CA1E1B",
    700: "#9E141B",
    800: "#710D19",
    900: "#460512",
  },
  pink: {
    50: "#FFF5F7",
  },
  purple: {
    100: "#E9D8FD",
    200: "#D6BCFA",
    300: "#B794F4",
    400: "#9F7AEA",
    450: "#805AD5",
    500: "#a485db",
    550: "#8067aa", // only used on the hovered speaker bar
    600: "#6B46C1",
    700: "#553C9A",
    800: "#44337A",
    900: "#322659",
  },
  black: "#000000",
  white: "#ffffff",
  blackAlpha: {
    700: "rgba(0, 0, 0, 0.7)",
  },
  blueAlpha: {
    50: "rgba(7, 114, 208, 0.04)",
    100: "rgba(7, 114, 208, 0.06)",
    200: "rgba(7, 114, 208, 0.08)",
    300: "rgba(7, 114, 208, 0.16)",
    400: "rgba(7, 114, 208, 0.24)",
  },
};

const colors = {
  ...colorRanges,
  pageBackground: colorRanges.gray[50],
  searchBackground: "#FFFD54",
  navBackground: `linear-gradient(to bottom, rgba(0, 0, 0, 0.00) 48.44%, rgba(0, 0, 0, 0.12) 100%), ${colorRanges.blue[700]}`,
  navBackgroundImpersonated: `linear-gradient(to bottom, ${colorRanges.red[600]} 47.86%, ${colorRanges.red[700]})`,
  currentSearchBackground: "#ED8936",
  searchBackgroundBeta: "#FFEE65",
  currentSearchBackgroundBeta: colorRanges.orange[500],
  border: colorRanges.gray[200],
  primary: colorRanges.gray[900],
  placeholder: colorRanges.gray[600],
  secondary: colorRanges.gray[400],
  error: colorRanges.red[400],
  link: {
    disabled: colorRanges.gray[400],
    default: colorRanges.blue[700],
    hover: colorRanges.blue[800],
    active: colorRanges.blue[900],
  },
  alert: {
    info: {
      background: colorRanges.blue[50],
      icon: colorRanges.blue[500],
    },
    success: {
      background: colorRanges.green[50],
      icon: colorRanges.green[500],
    },
    warning: {
      background: "#FFFAF0",
      backgroundHover: "#FFF2D7",
      backgroundPressed: "#FFE9BD",
      icon: "#F2994A",
      iconHover: "#F08C32",
      iconPressed: "#EF7E1A",
    },
    error: {
      background: colorRanges.red[50],
      icon: colorRanges.red[500],
    },
  },
  tabs: {
    bright: colorRanges.blue[700],
    hoverBorder: "#CBD5E0",
    hoverBackground: "#EEF0F2",
    activeBorder: "#BFCBD9",
    activeBackground: "#E3E6EA",
    unselectedColor: colorRanges.gray[600],
  },
  icons: {
    bright: "#126BB6",
    comment: colorRanges.blue[600],
    cue: colorRanges.blue[500],
    flag: colorRanges.red[500],
    highlightV2: colorRanges.blue[500],
    note: colorRanges.gray[400],
    star: colorRanges.yellow[500],
    thumbsDown: colorRanges.red[400],
    thumbsUp: colorRanges.green[500],
    add: colorRanges.green[500],
    remove: colorRanges.red[500],
  },
  stars: {
    active: "#E9C242",
  },
  dataViz: {
    ...dataVizColorRanges,
  },
};

export default colors;
