import { Box } from "@chakra-ui/react";
import React from "react";

import { Alert } from "../../../components";
import { CallGuideCue, useUpdateCallGuideCueMutation } from "../../graphql";
import QuestionForm from "./QuestionForm";

type Cue = Pick<CallGuideCue, "id" | "description" | "cue">;

interface EditQuestionProps {
  cue: Cue;
  onCompleted: () => void;
  onCancel: () => void;
}

const EditQuestion: React.FC<EditQuestionProps> = ({
  cue,
  onCompleted,
  onCancel,
}) => {
  const [updateCue, { error, loading }] = useUpdateCallGuideCueMutation({
    onCompleted,
  });

  const onSubmit = ({
    description,
    cue: competency,
  }: {
    description: string;
    cue: string;
  }): void => {
    updateCue({ variables: { id: cue.id, description, cue: competency } });
  };

  return (
    <Box pt={4} pb={5}>
      {error && <Alert status="error" description="Error saving question" />}
      <QuestionForm
        onlyQuestion={false}
        cue={cue}
        submitting={loading}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </Box>
  );
};

export default EditQuestion;
