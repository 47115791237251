import {
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { HiOutlineXCircle } from "react-icons/hi";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";

import { Avatar, LoadingIndicator, RouterLink } from "../../../components";
import {
  TableNewer,
  TableNewerColumn,
  TableNewerRow,
} from "../../../components/TableNew/TableNewer";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { formatDate } from "../../../utils/datetime";
import {
  CallGuide,
  useAllUsersWithAssignedCallGuidesQuery,
  useRemoveCallGuideAssignedUserMutation,
} from "../../graphql";

type NestedRowProps = {
  isLast: boolean;
  name?: string | null;
  callGuideId: string;
  dateAssigned: string;
  onUnassign(): void;
};

const NestedRow: React.FC<NestedRowProps> = ({
  callGuideId,
  name,
  isLast,
  dateAssigned,
  onUnassign,
}) => {
  const borderProps = { borderBottom: "1px", borderColor: "gray.100" };
  const fullBorder = isLast ? borderProps : undefined;
  return (
    <Box
      as="tr"
      fontWeight="500"
      fontSize="sm"
      backgroundColor="gray.50"
      lineHeight="21px"
      key={callGuideId}
    >
      <Box as="td" {...fullBorder} />
      <Box as="td" {...fullBorder} />
      <Box
        as="td"
        ml={8}
        pb={0}
        pl={4}
        py={2}
        opacity="0.8"
        color="gray.700"
        {...borderProps}
      >
        <RouterLink to={`/guide/${callGuideId}`}>{name}</RouterLink>
        <Box as="span" display="block" fontWeight="normal" color="gray.500">
          {formatDate(dateAssigned, {
            dateStyle: "medium",
          })}
        </Box>
      </Box>
      <Flex justifyContent="flex-end" as="td" pr={4} py={2} {...borderProps}>
        <Tooltip label="Unassign from guide">
          <IconButton
            variant="icon"
            aria-label="Unassign from guide"
            color="red.400"
            icon={<HiOutlineXCircle size={20} />}
            bg="transparent"
            onClick={onUnassign}
          />
        </Tooltip>
      </Flex>
    </Box>
  );
};

type GuideAssignment = {
  id: string;
  dateAssigned: string;
  callGuide: Pick<CallGuide, "id" | "name">;
};

type AssignedGuidesRowData = {
  id: string;
  firstRowForUser: boolean;
  email: string;
  profilePicUrl?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  fullName: string | null;
  callGuideAssignments?: GuideAssignment[];
};

type AssignedGuidesRow = {
  key: string;
  data: AssignedGuidesRowData;
  children?: React.ReactNode[];
};

type AssignedGuidesTableProps = {
  query: string;
};

const AssignedGuidesTable: React.FC<AssignedGuidesTableProps> = ({ query }) => {
  const { percentHeight } = useWindowDimensions();
  const [openRowIDs, setOpenRowIDs] = useState<string[]>([]);

  const toggleRow = (row: TableNewerRow): void => {
    const rowData = row.data as AssignedGuidesRowData;
    if (openRowIDs.includes(rowData.id)) {
      setOpenRowIDs(openRowIDs.filter((id) => id !== rowData.id));
    } else {
      setOpenRowIDs([...openRowIDs, rowData.id]);
    }
  };

  const { data, loading, refetch } = useAllUsersWithAssignedCallGuidesQuery({
    variables: {
      query,
    },
  });
  const allUsers =
    data?.currentUser?.organization.usersWithGuideAssignments || [];

  const [unassignInterviewer] = useRemoveCallGuideAssignedUserMutation({
    onCompleted() {
      refetch();
    },
  });

  const columns: Array<TableNewerColumn> = [
    {
      header: "Name",
      id: "name",
      cell: (row: TableNewerRow) => {
        const rowData = row.data as AssignedGuidesRowData;
        if (!rowData.firstRowForUser) {
          return "";
        }
        return (
          <Flex direction="row" alignItems="center">
            <Avatar
              name={rowData.fullName || ""}
              user={rowData}
              borderRadius="100%"
              mr="3"
            />
            <Text color="gray.900" fontWeight="500">
              {rowData.fullName}
            </Text>
          </Flex>
        );
      },
    },
    {
      header: "Email",
      id: "email",
      cell: (row: TableNewerRow) => {
        const rowData = row.data as AssignedGuidesRowData;
        return (
          <Text color="gray.800" fontWeight="500">
            {rowData.email}
          </Text>
        );
      },
    },
    {
      header: "Assigned Guides",
      id: "numberAssigned",
      cell: (row: TableNewerRow) => {
        const rowData = row.data as AssignedGuidesRowData;
        return (
          <Text color="gray.800" fontWeight="500" whiteSpace="nowrap">
            {rowData.callGuideAssignments?.length}
          </Text>
        );
      },
    },
    {
      header: " ",
      id: "caret",
      cell: (row: TableNewerRow) => {
        return (
          <Flex flexDir="row" justifyContent="flex-end">
            <Button
              mr="2"
              p="0"
              height="auto"
              minW="auto"
              variant="ghost"
              color="gray.500"
              _hover={{
                bg: "gray.50",
              }}
              onClick={() => toggleRow(row)}
            >
              {row.children ? <IoChevronUp /> : <IoChevronDown />}
            </Button>
          </Flex>
        );
      },
    },
  ];

  const tableData: AssignedGuidesRow[] = useMemo(() => {
    if (allUsers.length === 0) {
      return [];
    }

    const sortedUsers = [...allUsers].sort((a, b) =>
      (a.lastName || "").localeCompare(b.lastName || "")
    );
    const seenUsers = new Set<string>();
    const mappedUsers = sortedUsers.map((user) => {
      const callGuideAssignments = user.callGuideAssignments || [];
      const result: AssignedGuidesRow = {
        key: user.id,
        data: {
          ...user,
          callGuideAssignments,
          firstRowForUser: !seenUsers.has(user.id),
        },
        children: openRowIDs.includes(user.id)
          ? user.callGuideAssignments?.map((guideAssignment, idx) => (
              <NestedRow
                key={`${guideAssignment.callGuide.id}_${user.id}`}
                name={guideAssignment.callGuide.name}
                callGuideId={guideAssignment.callGuide.id}
                dateAssigned={guideAssignment.dateAssigned}
                isLast={idx === user.callGuideAssignments.length - 1}
                onUnassign={() => {
                  unassignInterviewer({
                    variables: {
                      callGuideId: guideAssignment.callGuide.id,
                      userId: user.id,
                    },
                  });
                }}
              />
            ))
          : undefined,
      };

      seenUsers.add(user.id);
      return result;
    });

    return mappedUsers;
  }, [allUsers, openRowIDs, query]);

  if (loading) {
    return (
      <Box mt="4">
        <LoadingIndicator />
      </Box>
    );
  }

  return (
    <Box pb={8} mt="4">
      <Box borderRadius="1">
        {tableData.length ? (
          <TableNewer
            columns={columns}
            data={tableData}
            columnWidths={["30%", "30%", "25%", "15%"]}
            onClickRow={(row) => toggleRow(row)}
          />
        ) : (
          <Flex
            height={percentHeight(50)}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Image
              width="25%"
              src="/static/images/empty-list-items-generic.svg"
            />
            <Text fontSize="sm" fontWeight="400" color="gray.700" mt="12">
              No interviewers have been assigned to any interview guides
            </Text>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default AssignedGuidesTable;
