import { Flex, FlexProps, Icon } from "@chakra-ui/react";
import React from "react";

import { BhLogo } from "../../../components";

const InterviewAssistantHeader: React.FC<FlexProps> = ({
  children,
  ...rest
}) => {
  return (
    <Flex
      height="12"
      alignItems="center"
      justifyContent="flex-start"
      px="3"
      bg="blue.800"
      {...rest}
    >
      <Icon
        as={BhLogo}
        color="white"
        h="32px"
        pl="1"
        pr={{ base: "2", sm: "4" }}
        width="auto"
      />
      {children}
    </Flex>
  );
};

export default InterviewAssistantHeader;
