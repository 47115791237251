import { Box, useDisclosure } from "@chakra-ui/react";
import React, { useEffect } from "react";

import { AsyncModal } from "../../../components";
import { ShareModal } from "../ShareModal";
import { useSharePlaylistModal } from "./useSharePlaylistModal";

export interface SharePlaylistModalProps {
  trigger: JSX.Element;
  playlistId?: string;
  callId: string;
  clipIds?: string[];
  onShare?(): void;
}

const SharePlaylistModal: React.FC<SharePlaylistModalProps> = ({
  trigger,
  playlistId,
  clipIds,
  callId,
  onShare,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { hasSelection, getShareData, loading, ...shareModalProps } =
    useSharePlaylistModal({ playlistId, callId, clipIds, onClose, onShare });

  useEffect(() => {
    if (isOpen) {
      getShareData();
    }
  }, [isOpen]);

  return (
    <>
      <Box as="span" onClick={onOpen} display="contents">
        {trigger}
      </Box>

      <AsyncModal
        isOpen={isOpen}
        onClose={onClose}
        loading={loading}
        scrollBehavior={hasSelection ? "outside" : "inside"}
      >
        <ShareModal onClose={onClose} {...shareModalProps} />
      </AsyncModal>
    </>
  );
};

export default SharePlaylistModal;
