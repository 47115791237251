import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { IconType } from "react-icons";
import { IoIosChatbubbles, IoIosInformationCircle } from "react-icons/io";

import { getErrorContent } from "../../main/pages/integrations/getErrorContent";

const TEXT_COLOR = "gray.600";

type RichTooltipDefaultProps = {
  top: JSX.Element;
  bottom?: JSX.Element;
  color: string;
  icon: IconType;
};

export const RichTooltipDefaults: { [key: string]: RichTooltipDefaultProps } = {
  // Quick Stats
  interactivity: {
    top: (
      <span>
        Best practice recommends a high rate of interactivity, or back-and-forth
        conversation throughout
      </span>
    ),
    bottom: (
      <span>
        For accurate statistics, make sure that you are labeling ‘speakers’ in
        your completed interviews
      </span>
    ),
    color: "green.500",
    icon: IoIosChatbubbles,
  },
  "talk time": {
    top: (
      <span>
        It’s recommended that the candidate speak for 50-70% of the
        conversation, though every interview is different
      </span>
    ),
    color: "black",
    icon: IoIosChatbubbles,
  },

  // Zoom errors
  "brighthire excluded": {
    top: <span>BrightHire is not available for this meeting</span>,
    bottom: (
      <span>
        This meeting has been excluded from BrightHire. Please contact your
        administrator for details.
      </span>
    ),
    color: "black",
    icon: IoIosInformationCircle,
  },

  // Zoom notetaker and Google meet admin search info
  "zoom notetaker and google meet admin search": {
    top: (
      <span>
        You can use relative dates and also combine search terms. Try
        &quot;BrightHire next week&quot;, Sarah Smith 3 days ago&quot;, or
        &quot;BrightHire in_progress today&quot;.
      </span>
    ),
    bottom: (
      <span>
        Dates default to the created_at column, but you can specify what field
        to query dates on by using the column&apos;s postgres name. For example,
        &quot;scheduled_start_time:january 3&quot; in the Meetings tab would
        give you meetings scheduled to start on January 3rd of the current
        calendar year.
        <br />
        <br />
        Dates have ranges proportional to their specificity. For example,
        &quot;10 minutes from now&quot; will show results starting 10 minutes
        from now and ending 1 minute after that; &quot;next week&quot; will show
        results for the 7 days starting Monday of next week.
        <br />
        <br />
        Dates use your home timezone.
      </span>
    ),
    color: "black",
    icon: IoIosInformationCircle,
  },

  // Invalid meeting type
  "invalid meeting type": {
    top: <span>BrightHire is not available for this meeting</span>,
    bottom: (
      <span>
        This meeting cannot have BrightHire added due to its type. Please check
        with your administrator to confirm the types of meetings you can use.
      </span>
    ),
    color: "black",
    icon: IoIosInformationCircle,
  },

  // Unknown error
  "not found": {
    top: <span>BrightHire is not available for this meeting</span>,
    bottom: (
      <span>
        We could not find a suitable meeting to add BrightHire to. Please reach
        out for assistance.
      </span>
    ),
    color: "black",
    icon: IoIosInformationCircle,
  },
};

const getValueAsString = (
  el: string | JSX.Element,
  key?: string | null
): string => {
  const text = key || "Oops, we're missing some content here.";
  try {
    if (el && typeof el === "string") {
      return el;
    }
    if (el && typeof el !== "string") {
      return el.props.children.toString();
    }
  } catch {
    return text;
  }
  return text;
};

/**
 * Get rich tooltip content, first checking for any available content from the
 * error map in zoomIntegration.ts and overriding with defaults above
 * @param key Key for finding tooltip contents
 * @param forceSpan Use span style tooltips instead of rich tooltips
 */
export const getRichTooltipContentFromDefaults = (
  key?: string | null,
  forceSpan?: boolean
): JSX.Element => {
  const k = key?.toLowerCase() || "nonsense";
  const ec = getErrorContent(k, true);
  const result = RichTooltipDefaults[k]
    ? RichTooltipDefaults[k]
    : {
        top: ec.primary,
        bottom: ec.secondary,
        icon: undefined,
      };
  const icon = result?.icon || IoIosInformationCircle;

  if (forceSpan) {
    const { bottom } = result;
    if (bottom) {
      const text = getValueAsString(bottom, key);
      return <span>{text}</span>;
    }
    return <span />;
  }

  return key && (result.top || result.bottom) ? (
    <>
      <Flex
        flexDirection="row"
        backgroundColor="white"
        p="5"
        width="100%"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
      >
        <Box fontSize="sm" color="placeholder" p="0 10px 0 0">
          <Icon as={icon} w="36px" h="36px" color="gray.400" />
        </Box>
        {result.top && (
          <Box fontSize="sm" color="placeholder" p="0 0 0 12px">
            <Text color={TEXT_COLOR} textAlign="left">
              {result.top}
            </Text>
          </Box>
        )}
      </Flex>
      {result.bottom && (
        <Box fontSize="sm" color="placeholder" p="5" backgroundColor="gray.100">
          <Text color={TEXT_COLOR}>{result.bottom}</Text>
        </Box>
      )}
    </>
  ) : (
    <span />
  );
};
