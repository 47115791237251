import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  Icon,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";
import { HiOutlineSearch } from "react-icons/hi";
import { IoIosMenu } from "react-icons/io";
import { MdClose } from "react-icons/md";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import { RouterLinkBox } from "../RouterLink";
import { SpotlightSearchState } from "../SpotlightSearch";
import SidebarNavItem, { NavItem } from "./SidebarNavItem";

type MobileHeaderProps = {
  app: "main" | "plan";
  /** Primary nav items, shown first in the drawer */
  navItems: NavItem[];
  /** Nav items separated from `navItems` by a divider  */
  bottomNavItems: NavItem[];
  /** Nav items that remain sticky at the bottom  */
  footerNavItems: NavItem[];
  /** Called when search icon is clicked (drawer closed)  */
  onShowSearch?(): void;
  searchState?: SpotlightSearchState;
  /** Called when drawer is opened  */
  onOpen?(): void;
  /** Called when drawer is closed  */
  onClose?(): void;
  isImpersonated?: boolean;
};

/**
 * Page header that exposes access to instant search and navigation via a drawer
 */
const MobileHeader: React.FC<PropsWithChildren<MobileHeaderProps>> = ({
  app,
  navItems,
  bottomNavItems,
  footerNavItems,
  isImpersonated = false,
  searchState,
  onShowSearch,
  onOpen,
  onClose,
  children,
}) => {
  const { layoutHeight, headerHeight } = useWindowDimensions();
  const drawer = useDisclosure({ onOpen, onClose });

  return (
    <>
      <Flex
        as="header"
        bg="white"
        position="sticky"
        top="0"
        width="100vw"
        zIndex={drawer.isOpen ? 1500 : 5}
        px="5"
        h="12"
        alignItems="center"
        borderColor="gray.100"
        borderBottomWidth="1px"
      >
        <RouterLinkBox to="/" isExternal={app !== "main"} mr="auto">
          <Image src="/static/images/logo_wordmark_blue.svg" width="130" />
        </RouterLinkBox>

        {isImpersonated && (
          <Text
            position="absolute"
            top="3"
            left="50%"
            transform="translateX(-50%)"
            color="red.500"
          >
            ❗️ Impersonating ❗️
          </Text>
        )}

        {drawer.isOpen ? (
          <Icon
            as={MdClose}
            onClick={drawer.onClose}
            boxSize="6"
            color="gray.500"
          />
        ) : searchState?.isOpen ? (
          <Icon
            as={MdClose}
            onClick={searchState.onClose}
            boxSize="6"
            color="gray.500"
          />
        ) : (
          <>
            {onShowSearch && (
              <Icon
                as={HiOutlineSearch}
                onClick={onShowSearch}
                boxSize="6"
                p="1px"
                color="gray.500"
                mr="5"
              />
            )}
            <Icon
              as={IoIosMenu}
              onClick={drawer.onOpen}
              boxSize="6"
              color="gray.500"
            />
          </>
        )}
      </Flex>

      {children}

      <Drawer {...drawer} autoFocus={false}>
        <DrawerOverlay h={layoutHeight} top={headerHeight} />

        <DrawerContent
          mt={headerHeight}
          bg={isImpersonated ? "navBackgroundImpersonated" : "navBackground"}
          color="white"
          py="4"
          px="3"
          maxW="336px"
        >
          <DrawerBody p="0">
            {navItems.map((navItem) => (
              <SidebarNavItem
                sidebarIsOpen
                key={navItem.label}
                navItem={navItem}
                onClick={drawer.onClose}
              />
            ))}

            <Divider my="4" borderColor="whiteAlpha.400" />

            {bottomNavItems.map((navItem) => (
              <SidebarNavItem
                sidebarIsOpen
                key={navItem.label}
                navItem={navItem}
              />
            ))}
          </DrawerBody>

          <DrawerFooter
            px="0"
            pb="0"
            pt="4"
            display="block"
            borderColor="whiteAlpha.400"
            borderTopWidth="1px"
          >
            {footerNavItems.map((navItem) => (
              <SidebarNavItem
                sidebarIsOpen
                key={navItem.label}
                navItem={navItem}
              />
            ))}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default MobileHeader;
