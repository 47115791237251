import invariant from "invariant";
import { useCallback, useState } from "react";

export enum AskChatRole {
  Assistant = "assistant",
  User = "user",
}

export type AskChatMessage = {
  role: AskChatRole;
  content: string; // text to send to AI
  userContent: string; // nice version of text shown to user, e.g. "write an email" vs. underlying prompt
};

export type AskChat = {
  messages: AskChatMessage[];
};

/**
 * Internal state for the latest message sent to the AI.
 */
type Completion = {
  text?: string;
  loading: boolean;
  error?: string;
};

const useAskChat = (
  callId?: string,
  candidateId?: string
): {
  chat: AskChat;
  addUserMessage: (content: string, userContent: string) => Promise<void>;
  busy: boolean;
  reset: () => void;
} => {
  invariant(callId || candidateId, "No call or candidate to use ask");
  let requestPath = `/interview/${callId || ""}/ask`;
  if (candidateId) {
    requestPath = `/candidate/${candidateId}/ask`;
  }

  const [chat, setChat] = useState<AskChat>({ messages: [] });
  const [latestMessage, setLatestMessage] = useState<Completion>({
    loading: false,
  });

  const updateLatestMessage = (completion: Completion): void => {
    setChat((prevChat) => {
      const newChat = { ...prevChat, messages: [...prevChat.messages] };
      const newChatMessages = newChat.messages;
      newChatMessages[newChatMessages.length - 1] = {
        role: AskChatRole.Assistant,
        content: completion.text || "",
        userContent: completion.text || "",
      };
      return newChat;
    });
    setLatestMessage(completion);
  };

  const handleError = (error: string): void => {
    updateLatestMessage({
      loading: false,
      error,
      text: error,
    });
  };

  const addUserMessage = useCallback(
    async (content: string, userContent: string): Promise<void> => {
      try {
        const newChat: AskChat = {
          ...chat,
          messages: [
            ...chat.messages,
            { role: AskChatRole.User, content, userContent },
            { role: AskChatRole.Assistant, content: "", userContent: "" },
          ],
        };
        setChat(newChat);
        setLatestMessage({ loading: true });

        const response = await fetch(requestPath, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            messages: newChat.messages.slice(0, -1),
          }),
        });
        if (!response.ok || !response.body) {
          if (response.status === 402) {
            handleError(
              "You have reached your daily limit of AI requests, please try again later."
            );
          } else {
            handleError("Something went wrong, please try again later.");
          }
          return;
        }
        const reader = response.body.getReader();
        let cumulativeText = "";
        // eslint-disable-next-line no-constant-condition
        while (true) {
          // eslint-disable-next-line no-await-in-loop
          const { value, done } = await reader.read();
          if (done) break;
          const text = new TextDecoder().decode(value);
          cumulativeText += text;
          updateLatestMessage({
            text: cumulativeText,
            loading: true,
          });
        }
        updateLatestMessage({
          text: cumulativeText,
          loading: false,
        });
      } catch (e) {
        handleError("Something went wrong, please try again later.");
      }
    },
    [callId, chat]
  );
  return {
    chat,
    addUserMessage,
    busy: latestMessage.loading,
    reset: () => setChat({ messages: [] }),
  };
};

export default useAskChat;
