import { MenuItem, useDisclosure } from "@chakra-ui/react";
import * as Sentry from "@sentry/browser";
import React from "react";
import { HiOutlineTrash } from "react-icons/hi";

import { errorToast, successToast, useToast } from "../../../components";
import BasicAlertDialog from "../../components/BasicAlertDialog";
import { useDeletePlaylistMutation } from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";

type DeletePlaylistProps = {
  playlistId: string;
  goToPlaylistManagementHome(): void;
};

const DeletePlaylist: React.FC<DeletePlaylistProps> = ({
  playlistId,
  goToPlaylistManagementHome,
}) => {
  const currentUser = useCurrentUser();
  const confirmation = useDisclosure();
  const toast = useToast();

  const [deletePlaylist, { loading }] = useDeletePlaylistMutation({
    variables: { playlistId },
    update(cache, { data }) {
      const playlistId = data?.deletePlaylist?.playlistId;
      if (playlistId) {
        cache.modify({
          id: cache.identify({ __typename: "User", id: currentUser.id }),
          fields: {
            playlists: ({ results, ...existing }, { readField }) => {
              return {
                ...existing,
                results:
                  results?.filter(
                    (p: any) => readField("id", p) !== playlistId
                  ) ?? [],
              };
            },
          },
        });
      }
    },
    onCompleted(data) {
      if (data.deletePlaylist?.playlistId) {
        successToast(toast, "Playlist deleted");
        confirmation.onClose();
        goToPlaylistManagementHome();
      }
    },
    onError(err) {
      Sentry.captureException(err);
      errorToast(toast, "There was a problem deleting this Playlist");
      confirmation.onClose();
    },
  });

  return (
    <>
      <MenuItem
        color="red.600"
        _hover={{
          bg: "red.100",
          color: "red.500",
        }}
        _focus={{
          color: "red.700",
          bg: "red.200",
        }}
        _active={{ bg: "red.50", color: "red.400" }}
        icon={<HiOutlineTrash size="18px" />}
        iconSpacing="2"
        onClick={confirmation.onOpen}
        fontSize="sm"
        fontWeight="500"
      >
        Delete
      </MenuItem>

      {confirmation.isOpen && (
        <BasicAlertDialog
          title="Are you sure?"
          body={
            <>
              Deleting a playlist cannot be undone. Any users this playlist is
              shared with will no longer have access.
            </>
          }
          confirmLabel="Yes, Delete"
          onCancel={confirmation.onClose}
          onConfirm={deletePlaylist}
          isDisabled={loading}
          variant="wide"
        />
      )}
    </>
  );
};

export default DeletePlaylist;
