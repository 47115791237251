import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  BoxProps,
  useTheme,
} from "@chakra-ui/react";
import React from "react";

type AnalyticsInfoAlertProps = {
  status: AlertProps["status"];
  children: React.ReactNode;
} & BoxProps;

const AnalyticsInfoAlert: React.FC<AnalyticsInfoAlertProps> = ({
  status,
  children,
  ...boxProps
}) => {
  const { colors } = useTheme();
  return (
    <Alert
      status={status}
      variant="subtle"
      borderRadius="4"
      bgColor={status === "info" ? colors.gray[50] : undefined}
      mt="4"
      {...boxProps}
    >
      <AlertIcon color={status === "info" ? colors.gray[600] : undefined} />
      <AlertDescription fontSize="sm">{children}</AlertDescription>
    </Alert>
  );
};

export default AnalyticsInfoAlert;
