import React, { SVGAttributes } from "react";

type IconProps = Pick<
  SVGAttributes<SVGSVGElement>,
  "width" | "height" | "fill"
>;

export const PlayIcon: React.FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg
      width={width || "24"}
      height={width || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
          d="M6.23445 20.6251C5.94665 20.6245 5.66394 20.5491 5.41414 20.4062C4.85164 20.0874 4.50195 19.4687 4.50195 18.797V5.20321C4.50195 4.52961 4.85164 3.91274 5.41414 3.59399C5.66989 3.44701 5.96043 3.37142 6.25539 3.37513C6.55034 3.37884 6.83889 3.46171 7.09086 3.61508L18.7088 10.5695C18.951 10.7213 19.1506 10.9321 19.2889 11.1822C19.4273 11.4322 19.4999 11.7134 19.4999 11.9991C19.4999 12.2849 19.4273 12.566 19.2889 12.8161C19.1506 13.0662 18.951 13.277 18.7088 13.4288L7.08898 20.3851C6.83112 20.541 6.53577 20.6239 6.23445 20.6251Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="-1"
          y="-1"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default PlayIcon;
