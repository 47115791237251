import React from "react";

import { successToast, useToast } from "../../../../../components";
import {
  CallNoteType,
  ClipBetaFragment,
  useClipShareQuery,
} from "../../../../graphql";
import VideoBeta, { VideoBetaProps } from "../../VideoBeta";
import useClipNotes from "./useClipNotes";
import useClipTranscript from "./useClipTranscript";

type ClipVideoProps = Omit<
  VideoBetaProps,
  | "speakers"
  | "duration"
  | "onAddNote"
  | "notes"
  | "segments"
  | "shareableUsers"
  | "shareableUsersLoading"
> & {
  clip: ClipBetaFragment;
  callId: string;
};

const ClipVideo: React.FC<ClipVideoProps> = ({
  clip,
  callId,
  ...videoProps
}) => {
  const toast = useToast();

  const { onAddNote, notes } = useClipNotes({
    callId,
    clipId: clip.id,
    onAddCallNoteCompleted(data) {
      const note = data.addNewCallNote?.callNote;
      if (note) {
        successToast(
          toast,
          `${note.type === CallNoteType.Note ? "Note" : "Reaction"} added`,
          { position: "top" }
        );
      }
    },
  });

  const { transcript } = useClipTranscript({ clipId: clip.id, callId });

  const shareQuery = useClipShareQuery({ variables: { clipId: clip.id } });

  return (
    <VideoBeta
      {...videoProps}
      speakers={clip.speakers}
      duration={clip.duration}
      onAddNote={onAddNote}
      notes={notes ?? []}
      segments={transcript}
      shareableUsers={shareQuery.data?.clip?.shareableUsers}
      shareableUsersLoading={shareQuery.loading}
    />
  );
};

export default ClipVideo;
