import { Text } from "@chakra-ui/react";
import React from "react";

import { LoadingIndicator } from "../../../../../../../components";
import { EmptyAIIcon } from "../../../../../../../components/Images";
import { CallBetaFragment } from "../../../../../../graphql";
import AiNotesMessage from "./AiNotesMessage";
import AiNotesQuestionBlocks from "./AiNotesQuestionBlocks";
import { AiNotesReturn } from "./useAiNotes";

type AiNotesProps = AiNotesReturn & {
  call: Pick<
    CallBetaFragment,
    | "id"
    | "canEdit"
    | "candidate"
    | "position"
    | "startTime"
    | "speakers"
    | "transcriptionStatus"
    | "aiNotesProcessingStatus"
  >;
  onClickTimestamp(t: number): void;
};

const AiNotes: React.FC<AiNotesProps> = ({
  call,
  data,
  regenerate,
  copyAiNotes,
  updateAiNote,
  updateAiNoteQuestion,
  onClickTimestamp,
  createAiNoteTags,
}) => {
  const { aiNotes: questionsAndAnswers, loading, error, status } = data;
  return (
    <>
      {loading && (
        <AiNotesMessage>
          <LoadingIndicator />
        </AiNotesMessage>
      )}
      {!loading && error && (
        <AiNotesMessage>
          <EmptyAIIcon marginBottom="64px" />
          <Text align="center" maxW="360px">
            Oops! There was an error fetching AI notes. Please reload the page
            or try again later.
          </Text>
        </AiNotesMessage>
      )}
      {!loading && !error && status && questionsAndAnswers && (
        <AiNotesQuestionBlocks
          status={status}
          questionsAndAnswers={questionsAndAnswers}
          regenerate={regenerate}
          copyNotes={copyAiNotes}
          callId={call.id}
          canEdit={call.canEdit}
          updateAiNote={updateAiNote}
          updateAiNoteQuestion={updateAiNoteQuestion}
          onClickTimestamp={onClickTimestamp}
          createAiNoteTags={createAiNoteTags}
        />
      )}
    </>
  );
};

export default AiNotes;
