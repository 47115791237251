import { checkCookieExistence } from "../../../utils/cookie";
import { useCurrentUserQuery } from "..";

const WHITE_LIST = [] as string[];
const useFeatureFlag = (feature: string): boolean => {
  const { data } = useCurrentUserQuery({ fetchPolicy: "cache-only" });
  const query = new URLSearchParams(document.location.search);
  const urlEnabledFeatures = (
    query.get("enabledFeatures")?.split(",") || []
  ).filter((feature) => WHITE_LIST.includes(feature));

  const enabledFeatures = data?.currentUser?.enabledFeatures || [];
  return !!(
    enabledFeatures.includes(feature) || urlEnabledFeatures?.includes(feature)
  );
};

export const useFeatureFlagForCurrentOrImpersonatedUser = (
  feature: string
): boolean => {
  const { data } = useCurrentUserQuery({ fetchPolicy: "cache-only" });
  const enabledFeatures = data?.currentUser?.enabledFeatures || [];
  const impersonatedFeatures = data?.currentUser?.impersonatedFeatures || [];
  const isImpersonating = checkCookieExistence("is_impersonating");
  return !!(
    enabledFeatures.includes(feature) ||
    (isImpersonating && impersonatedFeatures?.includes(feature))
  );
};

export default useFeatureFlag;
