export default {
  baseStyle: {
    control: {
      _checked: {
        bg: "blue.600",
        borderColor: "blue.600",
        _hover: {
          bg: "blue.400",
          borderColor: "blue.400",
        },
      },
      _hover: {
        bg: "blue.400",
        borderColor: "blue.400",
      },
    },
  },
};
