import { Box, Flex, IconButton, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { MdVolumeMute, MdVolumeUp } from "react-icons/md";

import { useIsSmallScreen } from "../../../hooks/useIsSmallScreen";
import { VolumeControl } from "../VolumeControl";

interface AudioControlsProps {
  volume: number;
  disabled?: boolean;
  muted: boolean;
  setVolume: (volume: number) => void;
  mute: (muted: boolean) => void;
  isTop?: boolean;
}

export const AudioControls: React.FC<AudioControlsProps> = ({
  setVolume,
  mute,
  volume,
  disabled = false,
  muted,
  isTop = false,
}) => {
  const isSmallScreen = useIsSmallScreen();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  /**
   * For mobile viewports, add a click listener to close
   * this component when it is open and there is a click outside
   */
  useEffect(() => {
    const closeOnClickOutside = (e: MouseEvent): void => {
      const didClickOutside = !containerRef.current?.contains(
        e.target as HTMLElement
      );

      if (didClickOutside) {
        onClose();
      }
    };

    if (isSmallScreen && isOpen) {
      window.addEventListener("click", closeOnClickOutside);
      return () => window.removeEventListener("click", closeOnClickOutside);
    }
  }, [isSmallScreen, isOpen]);

  return (
    <Box position="relative" w={12} h={12}>
      <Flex
        ref={containerRef}
        position="absolute"
        bottom={isTop ? undefined : 0}
        top={isTop ? 0 : undefined}
        left={0}
        right={0}
        direction="column"
        alignItems="center"
        justifyContent="flex-end"
        pointerEvents="all"
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        bg={isOpen ? "gray.800" : undefined}
        p={3}
        pt={isOpen ? 5 : 3}
        pb={isOpen ? 5 : 3}
        borderRadius="full"
        transition="all 0.2s"
      >
        {isOpen && (
          <VolumeControl
            volume={volume}
            setVolume={setVolume}
            disabled={disabled}
            orientation="vertical"
            mb={2}
            h="16"
          />
        )}
        <IconButton
          aria-label="Mute"
          variant="unstyled"
          size="xs"
          icon={muted ? <MdVolumeMute size="24" /> : <MdVolumeUp size="24" />}
          isRound
          color="white"
          isDisabled={disabled}
          _disabled={{ bg: "transparent", opacity: 0.3 }}
          onClick={() => {
            if (isSmallScreen && !isOpen) {
              onOpen();
            } else {
              mute(!muted);
            }
          }}
        />
      </Flex>
    </Box>
  );
};
