import { ApolloError } from "@apollo/client";
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";

import { Alert } from "../../../components";

export interface FormData {
  playCallDisclaimer: boolean;
  allowSelfSignUp: boolean;
  allowPhoneSignIn: boolean;
  emailDomain: string;
  inviteScheduledInterviewers: boolean;
  requireActiveUserToImport: boolean;
  allowCandidateOptOut: boolean;
  allowInterviewerOptOut: boolean;
  allowAiCustomTemplates: boolean;
}

interface OrganizationSettingsFormProps {
  allowSelfSignUp: boolean;
  allowPhoneSignIn: boolean;
  emailDomain?: string | null;
  inviteScheduledInterviewers: boolean;
  requireActiveUserToImport: boolean;
  error: ApolloError | undefined;
  isLoading: boolean;
  onSubmit: (formData: FormData) => void;
}

const OrganizationSettingsForm: React.FC<OrganizationSettingsFormProps> = ({
  allowSelfSignUp,
  allowPhoneSignIn,
  emailDomain,
  inviteScheduledInterviewers,
  requireActiveUserToImport,
  error,
  isLoading,
  onSubmit,
}) => {
  const { register, handleSubmit, watch } = useForm<FormData>({
    defaultValues: {
      allowSelfSignUp,
      allowPhoneSignIn,
      inviteScheduledInterviewers,
      requireActiveUserToImport,
      emailDomain: emailDomain || "",
    },
  });

  return (
    <form
      autoComplete="on"
      onSubmit={handleSubmit((formData) => {
        onSubmit(formData);
      })}
    >
      {error?.graphQLErrors?.map(({ message }, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Alert key={i} status="error" description={message} />
      ))}
      <VStack spacing="8" align="start">
        <FormControl id="allowSelfSignUp">
          <Checkbox
            {...register("allowSelfSignUp")}
            defaultChecked={allowSelfSignUp}
          >
            Allow Self Sign-Up
          </Checkbox>
          <FormHelperText>
            This allows anybody from your organization&apos;s email domain to
            sign-up using their work email, without an invitation. This is
            recommended.
          </FormHelperText>
        </FormControl>
        <FormControl id="allowPhoneSignIn">
          <Checkbox
            {...register("allowPhoneSignIn")}
            defaultChecked={allowPhoneSignIn}
          >
            Allow Phone Sign-In
          </Checkbox>
          <FormHelperText>
            This allows users to log in with their cell phones via SMS. Turning
            this off will force them to sign-in with an OAuth provider (Google
            or Microsoft).
          </FormHelperText>
        </FormControl>
        <FormControl id="inviteScheduledInterviewers">
          <Checkbox
            {...register("inviteScheduledInterviewers")}
            defaultChecked={inviteScheduledInterviewers}
          >
            Auto-Invite Scheduled Interviewers
          </Checkbox>
          <FormHelperText>
            This invites scheduled interviewers to sign up with BrightHire
            automatically (applies only to interviews with “Auto-Join by
            Position” enabled). Note that this only applies to new or updated
            scheduled interviews.
          </FormHelperText>
        </FormControl>
        <FormControl id="requireActiveUserToImport">
          <Checkbox
            {...register("requireActiveUserToImport")}
            defaultChecked={requireActiveUserToImport}
            isDisabled={
              inviteScheduledInterviewers === false &&
              watch("inviteScheduledInterviewers") === false
            }
          >
            Require Sign Up to Capture Interviews
          </Checkbox>
          <FormHelperText>
            This requires users to have completed sign up with BrightHire in
            order to capture their interviews. Auto-Invite Scheduled
            Interviewers must be enabled, to disable require sign up
          </FormHelperText>
        </FormControl>
        <FormControl id="emailDomain" isRequired maxW="400">
          <FormLabel>Email domain</FormLabel>
          <FormHelperText mb={2}>
            Emails from this domain can sign up without an invitation when
            &quot;Allow Self Sign-Up&quot; is enabled
          </FormHelperText>
          <Input
            {...register("emailDomain")}
            maxLength={100}
            placeholder="mycompany.com"
          />
        </FormControl>
        <Button type="submit" isLoading={isLoading} data-testid="save">
          Save
        </Button>
      </VStack>
    </form>
  );
};

export default OrganizationSettingsForm;
