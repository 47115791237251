import { Link, LinkBox, LinkBoxProps, LinkOverlay } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";
import { To } from "react-router-dom";

import { RouterLink } from "./RouterLink";

export type RouterLinkBoxProps = {
  to: To;
  target?: React.HTMLAttributeAnchorTarget;
  isExternal?: boolean;
} & LinkBoxProps;

/**
 * Makes `children` clickable like a link.
 *
 * Chakra [docs](https://chakra-ui.com/docs/components/link-overlay)
 * come with some nice examples + caveats
 */
export const RouterLinkBox: React.FC<PropsWithChildren<RouterLinkBoxProps>> = ({
  to,
  target,
  isExternal,
  children,
  ...props
}) => {
  return (
    <LinkBox {...props}>
      {isExternal && (
        <LinkOverlay as={Link} href={to as string} target={target} />
      )}
      {!isExternal && <LinkOverlay as={RouterLink} to={to} target={target} />}
      {children}
    </LinkBox>
  );
};
