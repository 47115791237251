import {
  Box,
  Collapse,
  Flex,
  Heading,
  Icon,
  Link,
  ListItem,
  OrderedList,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import {
  MdCheckCircle,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from "react-icons/md";
import { useSearchParams } from "react-router-dom";

import { Alert, LoadingIndicator, useToast } from "../../../components";
import { BullhornActionSelect } from "../../components";
import {
  CurrentUserFragment,
  CurrentUserFragmentDoc,
  useAddBullhornCredentialsMutation,
  useBullhornAuthQuery,
  useRemoveBullhornCredentialsMutation,
} from "../../graphql";
import BullhornCredentialsForm from "./BullhornCredentialsForm";
import RemoveIntegrationButton from "./RemoveIntegrationButton";
import SettingsPageContainer from "./shared/SettingsPageContainer";

interface BullhornSettingsProps {
  currentUser: CurrentUserFragment;
}

const BullhornSettings: React.FC<BullhornSettingsProps> = ({ currentUser }) => {
  const toast = useToast();
  const { isOpen, onToggle } = useDisclosure();
  const [
    BullhornCredentials,
    {
      loading: BullhornCredentialsLoading,
      error: BullhornCredentialsError,
      data,
    },
  ] = useAddBullhornCredentialsMutation({
    onCompleted: ({ addBullhornCredentials }) => {
      if (addBullhornCredentials) {
        toast({
          status: "success",
          title: "Bullhorn Credentials",
          description: "Success!",
        });
      }
    },
    onError: () => {
      toast({
        status: "error",
        title: "Bullhorn Credentials",
        description: "There was a problem - please try again",
      });
    },
    update(cache, { data }) {
      const organization =
        data?.addBullhornCredentials?.currentUser.organization;
      if (organization) {
        cache.writeFragment({
          id: `User:${currentUser.id}`,
          fragment: CurrentUserFragmentDoc,
          fragmentName: "CurrentUser",
          data: {
            ...currentUser,
            organization: {
              ...currentUser.organization,
              ...organization,
            },
          },
        });
      }
    },
  });

  const {
    data: bullhornAuthData,
    loading: bullhornAuthLoading,
    error: bullhornAuthError,
  } = useBullhornAuthQuery();
  const [removeBullhorn, { loading: revokeLoading }] =
    useRemoveBullhornCredentialsMutation({
      onCompleted: ({ removeBullhornCredentials }) => {
        if (removeBullhornCredentials?.currentUser) {
          toast({
            status: "success",
            title: "Success",
            description: "Bullhorn was removed",
          });
        }
      },
      onError: () => {
        toast({
          status: "error",
          title: "Error",
          description: "There was a problem removing Bullhorn",
        });
      },
    });

  const authUrl =
    data?.addBullhornCredentials?.authUrl ??
    bullhornAuthData?.bullhornAuth?.authUrl;
  const isBullhornAuthorized = currentUser?.organization.isBullhornAuthorized;

  const [searchParams, setSearchParams] = useSearchParams();
  const bullhornAuth = searchParams.get("bullhornAuth");
  useEffect(() => {
    if (bullhornAuth) {
      if (bullhornAuth === "success") {
        toast({
          status: "success",
          title: "Success",
          description: "Your Bullhorn settings will appear momentarily",
        });
      } else if (bullhornAuth === "failure") {
        toast({
          status: "error",
          title: "Integrations",
          description:
            "There was a problem - please check your credentials and try again",
        });
      }
      searchParams.delete("bullhornAuth");
      setSearchParams(searchParams, { replace: true });
    }
  });

  return (
    <SettingsPageContainer heading="Bullhorn">
      <Flex alignItems="center">
        {(isBullhornAuthorized || authUrl) && (
          <>
            <Icon ml={2} mr={4} as={MdCheckCircle} color="green.400" />
            <RemoveIntegrationButton
              remove={() => removeBullhorn()}
              isLoading={revokeLoading}
              name="Bullhorn"
            />
          </>
        )}
      </Flex>
      {bullhornAuthError && (
        <Alert status="error" description={bullhornAuthError.message} />
      )}
      {bullhornAuthLoading ? (
        <LoadingIndicator />
      ) : isBullhornAuthorized ? (
        <Box>
          <Heading as="h2" size="sm" mb="2">
            Default Bullhorn Action
          </Heading>
          <BullhornActionSelect onSelect={() => null} />
          <Box bg="gray.100" p={3} borderRadius="base" mt={4}>
            <Flex
              fontWeight="semibold"
              cursor="pointer"
              onClick={() => onToggle()}
              mb="2"
              alignItems="center"
            >
              Add BrightHire Custom Tab to BullHorn{" "}
              <Icon
                as={isOpen ? MdKeyboardArrowUp : MdKeyboardArrowDown}
                h="6"
                w="6"
                ml="auto"
              />
            </Flex>
            <Collapse startingHeight={75} in={isOpen}>
              <OrderedList spacing={2} p="1">
                <ListItem>
                  Login to BullHorn as user with admin permissions
                </ListItem>
                <ListItem>Menu &gt; Admin &gt; View Layout</ListItem>
                <ListItem>
                  Under <strong>Field Map Entity</strong>, select{" "}
                  <strong>Candidate</strong>
                </ListItem>
                <ListItem>
                  Select <strong>Custom Tabs</strong>
                </ListItem>
                <ListItem>
                  Click <strong>Add new</strong>
                </ListItem>
                <ListItem>Fill out the form</ListItem>
                <UnorderedList pl={4}>
                  <ListItem>
                    Name: <em>BrightHire</em>
                  </ListItem>
                  <ListItem>
                    Enabled: <em>Yes (checked)</em>
                  </ListItem>
                  <ListItem>
                    Url:{" "}
                    <em>
                      {`${window.location.protocol}//${window.location.host}/interview-assistant/integrations/bullhorn`}
                    </em>
                  </ListItem>
                  <ListItem>
                    User Types:{" "}
                    <em>
                      All User Types who should see the BrightHire Custom Tab
                    </em>
                  </ListItem>
                </UnorderedList>
                <ListItem>Click Save</ListItem>
              </OrderedList>
            </Collapse>
          </Box>
        </Box>
      ) : authUrl ? (
        <Link mt={4} href={authUrl}>
          Click here to authorize Bullhorn
        </Link>
      ) : (
        <BullhornCredentialsForm
          isLoading={BullhornCredentialsLoading}
          error={BullhornCredentialsError}
          onSubmit={(formData) => BullhornCredentials({ variables: formData })}
        />
      )}
    </SettingsPageContainer>
  );
};

export default BullhornSettings;
