import { CandidatePositionChapter, Match } from "./types";

const matchThreshold = 0.87;

export const cosineSimilarity = (
  embedding1: number[] | undefined | null,
  embedding2: number[] | undefined | null
): number => {
  if (!embedding1 || !embedding2) return 0;
  // Calculate the dot product of the two embeddings
  let dotProduct = 0;
  for (let i = 0; i < embedding1.length; i++) {
    dotProduct += embedding1[i] * embedding2[i];
  }

  // Calculate the magnitudes of the embeddings
  const magnitude1 = Math.sqrt(
    embedding1.reduce((sum, val) => sum + val * val, 0)
  );
  const magnitude2 = Math.sqrt(
    embedding2.reduce((sum, val) => sum + val * val, 0)
  );

  // Calculate the cosine similarity
  const cosineSimilarity = dotProduct / (magnitude1 * magnitude2);
  return cosineSimilarity;
};

export const getMatchesOverThreshold = (
  matches: Match[],
  threshold: number = matchThreshold
): Match[] => {
  return matches.filter((match) => match.similarity > threshold);
};

export const getMatchingChapters = (
  chapters1: CandidatePositionChapter[],
  chapters2: CandidatePositionChapter[],
  /**
   * feature-flagged behavior to ensure `getMatchingChapters(a, b)` gives
   * the same result as `getMatchingChapters(b, a)`
   */
  useCommutative?: boolean,
  useV2Matching?: boolean
): Match[] => {
  const matches: Match[] = [];

  let [primary, secondary] = [chapters1, chapters2];
  if (useCommutative && chapters2.length > chapters1.length) {
    [primary, secondary] = [chapters2, chapters1];
  }

  primary.forEach((c) => {
    let bestMatch: CandidatePositionChapter | undefined;
    let bestSimilarity = -1;
    secondary.forEach((c2) => {
      let similarity = cosineSimilarity(c.embedding, c2.embedding);
      if (useV2Matching) {
        const questionSimilarity = cosineSimilarity(
          c.questionEmbedding,
          c2.questionEmbedding
        );
        const answerSimilarity = cosineSimilarity(
          c.answerEmbedding,
          c2.answerEmbedding
        );
        const chapterTitleSimilarity = cosineSimilarity(
          c.chapterTitleEmbedding,
          c2.chapterTitleEmbedding
        );
        similarity =
          questionSimilarity * 0.57 + // (4/7)
          answerSimilarity * 0.29 + // (2/7)
          chapterTitleSimilarity * 0.14; // (1/7)
      }
      if (similarity > bestSimilarity) {
        bestMatch = c2;
        bestSimilarity = similarity;
      }
    });
    if (bestMatch) {
      matches.push({
        chapter1: c,
        chapter2: bestMatch,
        similarity: bestSimilarity,
        id: `${c.id}-${bestMatch.id}`,
      });
    }
  });
  matches.sort((a, b) => b.similarity - a.similarity);
  return matches;
};
