import { NetworkStatus } from "@apollo/client";
import React, { useEffect } from "react";

import { useToast } from "../../../components";
import {
  CandidateAlertFeedFilter,
  useCandidateAlertFeedLazyQuery,
  useMarkCandidateAlertFeedReadMutation,
} from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";
import { CandidateAlertList } from "./CandidateAlertList";
import { useCandidateAlertEnabled } from "./useCandidateAlertEnabled";

const PAGE_LIMIT = 10;

const CandidateAlertFeed: React.FC<{
  setHasUnreadAlerts: (uread: boolean) => void;
  alertFilter?: CandidateAlertFeedFilter;
  shouldMarkAllRead?: boolean;
}> = ({ setHasUnreadAlerts, alertFilter, shouldMarkAllRead }) => {
  const toast = useToast();
  const currentUser = useCurrentUser();

  const candidateAlertsEnabled = useCandidateAlertEnabled(
    currentUser.organization.id
  );

  const [getAlertFeed, { data, loading, fetchMore, networkStatus }] =
    useCandidateAlertFeedLazyQuery({
      notifyOnNetworkStatusChange: true,
      onError: () => {
        toast({
          title: "Error",
          description: "Unable to load candidate alerts",
          status: "error",
        });
      },
    });
  const results = data?.candidateAlertFeed.results || [];

  const [markRead] = useMarkCandidateAlertFeedReadMutation({
    update(cache, { data }) {
      const ids = data?.markCandidateAlertFeedRead?.markedIds;
      const readAt = data?.markCandidateAlertFeedRead?.readAt;
      if (ids && readAt) {
        ids.forEach((id) => {
          cache.modify({
            id: cache.identify({ __typename: "CandidateAlertFeed", id }),
            fields: {
              readAt() {
                return readAt;
              },
            },
          });
        });
      }
    },
  });

  const handleAlertRead = (ids: string[]): void => {
    markRead({
      variables: {
        candidateAlertFeedIds: ids,
      },
    });
  };

  useEffect(() => {
    if (shouldMarkAllRead === true) {
      const unreadAlerts: string[] = results
        .filter((item) => !item.readAt)
        .map((item) => item.id);
      if (unreadAlerts.length > 0) {
        handleAlertRead(unreadAlerts);
      }
    }
  }, [shouldMarkAllRead]);

  /**
   * Executes at the beginning
   */
  useEffect(() => {
    if (candidateAlertsEnabled) {
      getAlertFeed({
        fetchPolicy: "network-only",
        variables: {
          pagination: {
            limit: PAGE_LIMIT,
          },
          alertFilter,
        },
      });
    }
  }, [alertFilter]);

  /**
   * Executed when the user loads a new page of results
   */
  const onLoadMore = (): void => {
    fetchMore({
      variables: {
        pagination: {
          limit: Number(results.length) + PAGE_LIMIT,
        },
      },
    });
  };

  // Need to rerun when user clicks on alert to update read dot on menu
  useEffect(() => {
    if (candidateAlertsEnabled && results) {
      const unreadFound = results.find((item) => !item.readAt);
      setHasUnreadAlerts(!!unreadFound);
    }
  }, [results]);

  return (
    <CandidateAlertList
      alerts={results}
      onLoadMore={onLoadMore}
      loading={
        loading &&
        networkStatus !== NetworkStatus.refetch &&
        networkStatus !== NetworkStatus.ready
      }
      showLoadMore={data?.candidateAlertFeed.pageInfo?.hasNextPage || false}
      handleAlertRead={handleAlertRead}
    />
  );
};

export default CandidateAlertFeed;
