import { As, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import React from "react";

import { Avatar } from "../../../../components";
import { CallSpeakerOption } from "../../../graphql";

export type SpeakerMenuOption = Pick<
  CallSpeakerOption,
  "id" | "label" | "profilePicUrl"
>;

type SpeakerMenuProps<TProps> = {
  defaultIsOpen?: boolean;
  /**
   * If disabled, only `trigger` will be shown, with no menu functionality
   */
  disabled?: boolean;
  /**
   * The component that will be shown and will trigger the menu upon click.
   *
   * Any props the trigger requires can be passed in `triggerProps`
   *
   * Note that Chakra [requires](https://chakra-ui.com/docs/components/menu#customizing-the-button)
   * this component use `forwardRef` for menu placement to work
   */
  trigger: As<TProps>;
  /**
   * Props to pass to the `trigger`
   */
  triggerProps?: TProps;
  speakerOptions: SpeakerMenuOption[] | undefined;
  /**
   * Whether to poll AI notes status after changing speakers
   */
  onSelect(so: SpeakerMenuOption): void;
};

const SpeakerMenu = <T,>({
  defaultIsOpen,
  disabled,
  trigger,
  speakerOptions,
  onSelect,
  triggerProps,
}: SpeakerMenuProps<T>): React.ReactElement => {
  const hideMenu = disabled || !speakerOptions?.length;

  return (
    <Menu
      placement="bottom-start"
      defaultIsOpen={defaultIsOpen}
      isOpen={hideMenu ? false : undefined}
    >
      <MenuButton as={trigger as As} {...triggerProps} />

      <MenuList
        zIndex={3}
        py={2}
        px={0}
        background="blue.900"
        borderWidth="1px"
        borderColor="whiteAlpha.300"
        boxShadow="0px 10px 20px rgba(0, 0, 0, 0.04)"
        borderRadius="4px"
      >
        {speakerOptions?.map((option) => (
          <MenuItem
            key={option.id}
            onClick={() => onSelect(option)}
            color="white"
            fontWeight="400"
            fontSize="sm"
            py={2.5}
            pl={4}
            _hover={{
              background: "blue.500",
            }}
            _focus={{
              background: "blue.900",
            }}
          >
            <Avatar
              mr="2.5"
              user={{
                profilePicUrl: option.profilePicUrl,
                firstName: option.label,
              }}
            />
            {option.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default SpeakerMenu;
