import { AnimatePresence, motion, MotionStyle } from "framer-motion";
import React from "react";

import { exitOnCollapse, exitOnExpand } from "./animations";

type AnimateExpansionProps = {
  /** Whether the sidebar is expanded or collapsed */
  isOpen: boolean;
  /** Styles to apply to the enclosing `<motion.div />` */
  style?: MotionStyle;
  /** UI to show when sidebar is expanded */
  expanded: React.ReactNode;
  /** Styles to apply to the enclosing `<motion.div />` when sidebar is expanded */
  expandedStyle?: MotionStyle;
  /** UI to show when sidebar is collapsed */
  collapsed: React.ReactNode;
  /** Styles to apply to the enclosing `<motion.div />` when sidebar is collapsed */
  collapsedStyle?: MotionStyle;
  skipAnimationDelay?: boolean;
};

/**
 * Handles switching between `expanded` and `collapsed` UI in step
 * with the sidebar expand / collapse animation.
 */
const AnimateExpansion: React.FC<AnimateExpansionProps> = ({
  isOpen,
  style,
  expanded,
  expandedStyle,
  collapsed,
  collapsedStyle,
  skipAnimationDelay,
}) => {
  return (
    <AnimatePresence exitBeforeEnter custom={{ skipAnimationDelay }}>
      {isOpen && (
        <motion.div
          key="open"
          variants={exitOnCollapse}
          exit="exit"
          style={{ ...style, ...expandedStyle }}
        >
          {expanded}
        </motion.div>
      )}
      {!isOpen && (
        <motion.div
          key="closed"
          variants={exitOnExpand}
          exit="exit"
          style={{ ...style, ...collapsedStyle }}
        >
          {collapsed}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AnimateExpansion;
