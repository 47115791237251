import { Box } from "@chakra-ui/react";
import React from "react";

import { Alert } from "../../../components";
import {
  CallGuideDocument,
  CallGuideQuery,
  useAddCallGuideCueMutation,
} from "../../graphql";
import QuestionForm from "./QuestionForm";

type CallGuide = NonNullable<CallGuideQuery["callGuide"]>;

interface AddQuestionProps {
  callGuide: CallGuide;
  onCompleted: () => void;
  onCancel: () => void;
  onlyQuestion: boolean;
}

const AddQuestion: React.FC<AddQuestionProps> = ({
  callGuide,
  onCompleted,
  onCancel,
  onlyQuestion,
}) => {
  const [addCue, { error, loading }] = useAddCallGuideCueMutation({
    update(cache, { data }) {
      const callGuideCue = data?.addCallGuideCue?.callGuideCue;
      if (callGuideCue) {
        cache.writeQuery<CallGuideQuery>({
          query: CallGuideDocument,
          data: {
            callGuide: {
              ...callGuide,
              cues: [...callGuide.cues, callGuideCue],
            },
          },
        });
      }
    },
    onCompleted,
  });

  const onSubmit = ({
    description,
    cue,
  }: {
    description: string;
    cue: string;
  }): void => {
    addCue({
      variables: {
        callGuideId: callGuide.id,
        cue,
        description,
      },
    });
  };

  return (
    <Box flex={1} py={4}>
      {error && <Alert status="error" description="Error saving question" />}
      <QuestionForm
        onlyQuestion={onlyQuestion}
        submitting={loading}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </Box>
  );
};

export default AddQuestion;
