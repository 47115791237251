import { ApolloError } from "@apollo/client";
import React from "react";
import { SortingRule } from "react-table";

import { Alert } from "../../../components";
import { PageOptions } from "../../../utils/types";
import { CallGuideListItemFragment } from "../../graphql";
import CallGuideList from "./CallGuideList";

interface PositionCallGuidesProps {
  callGuides: Array<CallGuideListItemFragment>;
  pageOptions: PageOptions;
  loading?: boolean;
  sortBy: SortingRule<string>;
  error?: ApolloError;
}

const PositionCallGuides: React.FC<PositionCallGuidesProps> = ({
  callGuides,
  pageOptions,
  loading,
  sortBy,
  error,
}) => {
  if (error)
    return (
      <Alert
        status="error"
        title="Error loading call guides"
        description={error.message}
      />
    );

  return (
    <CallGuideList
      callGuides={callGuides}
      pageOptions={pageOptions}
      loading={loading}
      sortBy={sortBy}
      emptyState="There are no call guides to show here"
      showOwner
    />
  );
};

export default PositionCallGuides;
