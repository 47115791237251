import clipboardCopy from "clipboard-copy";

export type CopyData = { plain: string; html: string };

/**
 * Copy plain text or plain text + HTML to the clipboard, with
 * a fallback to plain text copy on non-supported browsers.
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/write#browser_compatibility
 */
export const copy = async (text: string | CopyData): Promise<void> => {
  if (isCopyData(text)) {
    await richCopy(text);
  } else {
    await clipboardCopy(text);
  }
};

const richCopy = async ({ plain, html }: CopyData): Promise<void> => {
  try {
    await navigator.clipboard.write([
      new ClipboardItem({
        "text/html": new Blob([html], { type: "text/html" }),
        "text/plain": new Blob([plain], { type: "text/plain" }),
      }),
    ]);
  } catch {
    await clipboardCopy(plain);
  }
};

const isCopyData = (s: string | CopyData): s is CopyData =>
  typeof s === "object";
