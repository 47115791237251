import { Box, BoxProps } from "@chakra-ui/react";
import styled from "@emotion/styled";
import invariant from "invariant";
import React, { useEffect, useRef } from "react";

import colorVars from "../../theme/css-color-variables";
import { focusEnd } from "../../utils/dom";

const StyledBox = styled(Box)`
  &:empty::before {
    content: "${(props: any) => props.placeholder}";
    color: ${(props: any) =>
      props.placeholdercolor?.default || colorVars.gray[500]};
  }
  &:empty:hover::before {
    color: ${(props: any) =>
      props.placeholdercolor?.hover || colorVars.gray[500]};
  }
  &:empty:focus:hover::before {
    color: ${(props: any) =>
      props.placeholdercolor?.focus || colorVars.gray[500]};
  }
`;

export type ContentEditableProps = {
  defaultValue?: string;
  autoFocus?: boolean;
  isDisabled?: boolean;
  clearOnSubmit?: boolean;
  showOutlineOnFocus?: boolean;
  onChange?: (text?: string | null) => void;
  onSubmit?: (text?: string | null) => void;
  onBlur?: (text?: string | null) => void;
  placeholderColor?: { default?: string; hover?: string; focus?: string };
} & Omit<BoxProps, "onChange" | "onBlur" | "onSubmit">;

export const ContentEditable = React.forwardRef<
  HTMLDivElement,
  ContentEditableProps
>(
  (
    {
      defaultValue,
      isDisabled = false,
      autoFocus = false,
      clearOnSubmit = false,
      showOutlineOnFocus = false,
      onChange,
      onSubmit,
      onBlur,
      placeholderColor,
      ...rest
    },
    ref
  ) => {
    invariant(typeof ref !== "function", "ref cannot be function");
    const localRef = useRef<HTMLDivElement>(null);
    const boxRef = ref ?? localRef;
    useEffect(() => {
      if (boxRef?.current) {
        boxRef.current.textContent = defaultValue ?? null;
        if (autoFocus) {
          focusEnd(boxRef.current);
        }
      }
    }, []);
    return (
      <StyledBox
        ref={boxRef}
        data-testid="textbox"
        role="textbox"
        whiteSpace="pre-wrap"
        overflowX="hidden"
        outline={showOutlineOnFocus ? undefined : "none"}
        outlineColor={showOutlineOnFocus ? "blue.500" : undefined}
        cursor="text"
        p={showOutlineOnFocus ? 1 : 0}
        contentEditable={!isDisabled}
        placeholdercolor={placeholderColor}
        onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            const text = boxRef.current?.textContent;
            onSubmit?.(text);
            boxRef.current?.blur();
            if (clearOnSubmit && boxRef.current) {
              boxRef.current.textContent = null;
            }
          }
        }}
        onInput={() => {
          const text = boxRef.current?.textContent;
          onChange?.(text);
        }}
        onBlur={(e: React.FocusEvent<HTMLDivElement>): void => {
          onBlur?.(e.currentTarget.textContent);
        }}
        onPaste={(e: React.ClipboardEvent<HTMLDivElement>): void => {
          e.preventDefault();
          const text = e.clipboardData.getData("text/plain");
          document.execCommand("insertHTML", false, text);
        }}
        {...rest}
      />
    );
  }
);
