import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ModalCloseButton,
} from "@chakra-ui/react";
import React, { useRef } from "react";

interface BasicAlertDialogProps {
  title: string;
  body: React.ReactNode;
  confirmLabel: string;
  onCancel(): void;
  onConfirm(): void;
  isDisabled?: boolean;
  isSubmitting?: boolean;
  variant?: "wide";
}

const BasicAlertDialog: React.FC<BasicAlertDialogProps> = ({
  title,
  body,
  confirmLabel,
  onCancel,
  onConfirm,
  isDisabled,
  isSubmitting = false,
  variant,
}) => {
  const cancelRef = useRef<HTMLButtonElement>(null);

  const buttonProps = {
    fontSize: "sm",
    size: variant === "wide" ? "md" : "sm",
  };

  return (
    <AlertDialog isOpen onClose={onCancel} leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay>
        <AlertDialogContent my="auto" maxWidth="500px">
          <ModalCloseButton top="6" />
          <AlertDialogHeader
            fontSize="xl"
            fontWeight="500"
            borderColor="gray.100"
          >
            {title}
          </AlertDialogHeader>
          <AlertDialogBody pt="4" pb="6" fontSize="sm" color="gray.700">
            {body}
          </AlertDialogBody>
          <AlertDialogFooter
            borderColor="gray.100 !important"
            borderTop="1px solid"
          >
            <Button
              variant="ghost"
              onClick={onCancel}
              ref={cancelRef}
              {...buttonProps}
            >
              Cancel
            </Button>
            <Button
              data-testid="confirm-delete"
              variant="danger"
              onClick={onConfirm}
              ml={variant === "wide" ? "auto" : 3}
              isDisabled={isDisabled}
              isLoading={isSubmitting}
              minW={variant === "wide" ? 120 : undefined}
              {...buttonProps}
            >
              {confirmLabel}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default BasicAlertDialog;
