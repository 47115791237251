import { Flex } from "@chakra-ui/react";
import React from "react";

import {
  CallSpeakerFragment,
  TranscriptSegmentFragment,
} from "../../../graphql";
import SpeakerBarSegment from "./SpeakerBarSegment";

type SpeakerBarProps = {
  callDuration: number;
  candidateOnly?: boolean;
  speaker: CallSpeakerFragment;
  segments: TranscriptSegmentFragment[];
  onClickSegment: (startTime: number) => void;
};
const SpeakerBar: React.FC<SpeakerBarProps> = ({
  callDuration,
  candidateOnly = false,
  speaker,
  segments,
  onClickSegment,
}) => {
  return (
    <Flex
      position="relative"
      alignItems="center"
      bg="whiteAlpha.300"
      borderRadius="4px"
      h="16px"
      borderLeft="6px"
      borderRight="6px"
      borderColor="whiteAlpha.300"
      mt={2}
      w="100%"
    >
      {segments.map((segment) => (
        <SpeakerBarSegment
          candidateOnly={candidateOnly}
          key={segment.id}
          speaker={speaker}
          segment={segment}
          callDuration={callDuration}
          onClickSegment={onClickSegment}
        />
      ))}
    </Flex>
  );
};

export default SpeakerBar;
