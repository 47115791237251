import { Box, Flex, forwardRef, Text } from "@chakra-ui/react";
import LogRocket from "logrocket";
import React from "react";

import { useToast } from "../../../../components";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import {
  CallSpeakerFragment,
  useUpdateCallSpeakerMutation,
} from "../../../graphql";
import useAiNotesStatus from "../SidebarTabs/Ai/Notes/NotesQA/useAiNotesStatus";
import SpeakerMenu, { SpeakerMenuOption } from "../SpeakerMenu";

const SpeakerTagBase: React.FC<SpeakerTagProps> = forwardRef(
  (
    {
      speaker,
      canChangeSpeaker = true,
      candidateOnly = false,
      speakerOptions = [],
      ...rest
    },
    ref
  ) => {
    const background = speaker.isCandidate ? "purple.300" : "gray.100";
    const hoveredBackground = speaker.isCandidate ? "purple.500" : "gray.300";
    const backgroundStyles = {
      background,
      _hover: {
        background: canChangeSpeaker ? hoveredBackground : background,
        cursor: canChangeSpeaker ? "pointer" : "default",
      },
    };
    return (
      <Flex
        ref={ref}
        direction="row"
        borderRadius="12px"
        fontWeight="500"
        fontSize="xs"
        color="black"
        overflow="hidden"
        height="22px"
        maxW="100%"
        lineHeight="22px"
        opacity={candidateOnly && !speaker.isCandidate ? 0.2 : 1}
        transition="opacity 0.2s ease"
        {...backgroundStyles}
        {...rest}
      >
        <Text
          borderRadius="12px"
          px="2"
          zIndex="2"
          bg="inherit"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {speaker.label}
        </Text>
        <Text
          bg="gray.900"
          borderRadius="50%"
          marginLeft="-23px"
          marginTop="-2px"
          width="26px"
          height="26px"
          flexShrink="0"
        />
        <Box as="span" px="1">
          {speaker.talkTimePercentage}%
        </Box>
      </Flex>
    );
  }
);

type SpeakerTagProps = {
  callId?: string;
  speaker: CallSpeakerFragment;
  canChangeSpeaker?: boolean;
  speakerOptions?: SpeakerMenuOption[];
  candidateOnly?: boolean;
  defaultIsOpen?: boolean;
};
const SpeakerTag: React.FC<SpeakerTagProps> = ({
  callId,
  candidateOnly,
  speaker,
  canChangeSpeaker = false,
  speakerOptions = [],
  defaultIsOpen,
}) => {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();

  const [updateCallSpeakerMutation] = useUpdateCallSpeakerMutation({
    onError: () => {
      toast({ status: "error", title: "Error changing speaker" });
    },
  });

  const { checkAiNotesStatus } = useAiNotesStatus();

  const updateCallSpeaker = (speakerOptionId: string): void => {
    if (callId) {
      sendGAEvent("update_speaker", "call_review");
      LogRocket.track("speaker-label-updated");
      updateCallSpeakerMutation({
        variables: {
          callId,
          speakerTag: speaker.speakerTag,
          speakerOptionId,
        },
        onCompleted: () => {
          checkAiNotesStatus(callId);
        },
      });
    }
  };

  return (
    <SpeakerMenu
      trigger={SpeakerTagBase}
      defaultIsOpen={defaultIsOpen}
      disabled={!canChangeSpeaker}
      triggerProps={{
        canChangeSpeaker,
        candidateOnly,
        speaker,
      }}
      speakerOptions={speakerOptions}
      onSelect={(option) => updateCallSpeaker(option.id)}
    />
  );
};

export default React.memo(SpeakerTag);
