import {
  Box,
  Collapse,
  ListItem,
  OrderedList,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { LoadingIndicator } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import {
  CandidateInterviewFragment,
  Chapter,
  useCallChaptersQuery,
} from "../../graphql";
import InterviewChapter from "./InterviewChapter";

type InterviewChaptersProps = {
  call: CandidateInterviewFragment;
  isOpen: boolean;
  thumbnailUrl?: any; // remove this when we have highlight thumbnails backfilled
};

const CHAPTER_INSET = 12;

const InterviewChapters: React.FC<InterviewChaptersProps> = ({
  call,
  isOpen,
  thumbnailUrl,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const sendGAEvent = useSendGAEvent();

  const handleTabsChange = (index: number): void => {
    setTabIndex(index);
    sendGAEvent("view_interview_chapter", "candidate");
  };

  const { loading, error, data } = useCallChaptersQuery({
    variables: { callId: call.id },
  });

  return (
    <Collapse in={isOpen} animateOpacity>
      <Box>
        {loading && (
          <Box bg="gray.50" rounded="md" w="40%" p="4" mt="4">
            <LoadingIndicator />
          </Box>
        )}
        {error && (
          <Box bg="gray.50" rounded="md" w="40%" p="4" mt="4">
            <Text>
              Oops! There was an error fetching topics for this interview.
            </Text>
          </Box>
        )}
        {!loading && !error && data && (
          <Tabs
            orientation="vertical"
            variant="unstyled"
            mt={4}
            index={tabIndex}
            onChange={handleTabsChange}
            isLazy
          >
            <Box bg="gray.50" rounded="md" minW="40%">
              <Text
                fontSize="lg"
                fontWeight="semibold"
                verticalAlign="center"
                pl="8"
                pt="5"
                pb="3"
              >
                Interview Summary
              </Text>
              <TabList
                as={OrderedList}
                maxHeight="500px"
                overflow="auto"
                ml="0"
                pl="0"
                pb="4"
              >
                {data?.callChapters?.chapters?.length === 0 && (
                  <Text ml="8" color="gray.900">
                    No topics detected.
                  </Text>
                )}
                {data?.callChapters?.chapters.map(
                  (chapter: Chapter, idx: number) => (
                    <Tab
                      key={chapter.id}
                      as={ListItem}
                      display="list-item"
                      position="relative"
                      fontWeight="normal"
                      color="gray.900"
                      _selected={{
                        fontWeight: "semibold",
                        color: "blue.600",
                      }}
                      _active={{
                        bg: "inherit",
                      }}
                      _hover={{ bg: "inherit", color: "blue.600" }}
                      cursor="pointer"
                      ml={CHAPTER_INSET}
                      pl={0}
                      pt={0}
                      pb={2}
                      data-tour-id={`candidate-page-interview-summary-chapter-${idx}`}
                    >
                      <ChapterListItem
                        key={chapter.id}
                        chapter={chapter}
                        isActive={idx === tabIndex}
                      />
                    </Tab>
                  )
                )}
              </TabList>
            </Box>
            <TabPanels>
              {data?.callChapters?.chapters.map(
                (chapter: Chapter, index: number) => (
                  <InterviewChapter
                    key={chapter.id}
                    chapter={chapter}
                    call={call}
                    index={index}
                    thumbnailUrl={thumbnailUrl}
                  />
                )
              )}
            </TabPanels>
          </Tabs>
        )}
      </Box>
    </Collapse>
  );
};

type ChapterListItemProps = {
  chapter: Chapter;
  isActive?: boolean;
};

const ChapterListItem: React.FC<ChapterListItemProps> = ({
  chapter,
  isActive,
}) => {
  return (
    <Box as="span" fontSize="sm">
      <span style={{ paddingRight: "12px" }}>{chapter.text}</span>
      {isActive && (
        <Box
          w="4px"
          bg="blue.600"
          position="absolute"
          left={CHAPTER_INSET * -1}
          top="0"
          bottom="0"
        />
      )}
    </Box>
  );
};

export default InterviewChapters;
