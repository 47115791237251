import { Box } from "@chakra-ui/react";
import React from "react";

import useFeatureFlag from "../../../graphql/hooks/useFeatureFlag";

type DragHandleProps = {
  onDragStart: () => void;
  offsetLeft: number;
  offsetTop: number;
  activeBrowserSelection?: boolean;
};

export const SelectionDragHandleStart: React.FC<DragHandleProps> = ({
  onDragStart,
  offsetLeft,
  offsetTop,
  activeBrowserSelection,
}) => {
  const dragToSelectEnabled = useFeatureFlag("clips:drag-to-select");
  if (!dragToSelectEnabled) {
    return null;
  }
  // Disable bounds draggers while text selection is active, otherwise there are
  // edge cases where the text selection hovers over a bounds icon and either anchors
  // the selection to a bounds icon and not a word, or the bounds div blocks the
  // selection from working on this word and it default to the previous word.
  if (activeBrowserSelection) {
    return null;
  }
  return (
    <Box
      left={`${offsetLeft - 11}px`}
      top={`${offsetTop - 2}px`}
      position="absolute"
      height="28px"
      width="20px"
      onMouseDown={(e) => {
        e.preventDefault();
        onDragStart();
      }}
      cursor="col-resize"
      zIndex="1"
      userSelect="none"
    >
      <Box
        position="absolute"
        top="-3px"
        left="6px"
        w="8px"
        h="8px"
        bg="yellow.500"
        borderRadius="7px"
      />
      <Box
        position="absolute"
        top="0px"
        left="9px"
        w="2px"
        h="24.5px"
        bg="yellow.500"
      />
    </Box>
  );
};

export const SelectionDragHandleEnd: React.FC<DragHandleProps> = ({
  onDragStart,
  offsetLeft,
  offsetTop,
  activeBrowserSelection,
}) => {
  const dragToSelectEnabled = useFeatureFlag("clips:drag-to-select");
  if (!dragToSelectEnabled) {
    return null;
  }
  // Disable bounds draggers while text selection is active, otherwise there are
  // edge cases where the text selection hovers over a bounds icon and either anchors
  // the selection to a bounds icon and not a word, or the bounds div blocks the
  // selection from working on this word and it default to the previous word.
  if (activeBrowserSelection) {
    return null;
  }
  return (
    <Box
      position="absolute"
      left={`${offsetLeft - 11}px`}
      top={`${offsetTop - 2}px`}
      height="28px"
      width="20px"
      onMouseDown={(e) => {
        e.preventDefault();
        onDragStart();
      }}
      cursor="col-resize"
      zIndex="1"
      userSelect="none"
    >
      <Box
        position="absolute"
        bottom="0px"
        left="6px"
        w="8px"
        h="8px"
        bg="yellow.500"
        borderRadius="7px"
      />
      <Box
        position="absolute"
        top="2px"
        left="9px"
        w="2px"
        h="20px"
        bg="yellow.500"
      />
    </Box>
  );
};
