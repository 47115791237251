import {
  HiOutlineCalendar,
  HiOutlineSparkles,
  HiOutlineUserGroup,
  HiOutlineUsers,
} from "react-icons/hi";
import {
  IoChatbubblesOutline,
  IoPeopleOutline,
  IoStarHalfOutline,
} from "react-icons/io5";
import { IconType } from "react-icons/lib";
import { MdOutlineTopic } from "react-icons/md";

import { AnalyticsDimension, MetricName } from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../../hooks/useCurrentUser";
import { canViewAllMetrics } from "./utils";

export type NavMetricGroup = {
  title: string;
  metrics: MetricName[];
  icon: IconType;
  enabled: boolean;
  badge?: string;
  segment?: AnalyticsDimension;
};

const useSidebarConfig = (): any => {
  const currentUser = useCurrentUser();
  const ratingMetricsEnabled = useFeatureFlag("analytics:rating-metrics");
  const hcaMetricsEnabled = useFeatureFlag("analytics:hca-metrics");
  const scorecardMetricsEnabled =
    useFeatureFlag("analytics:scorecard") && !!currentUser.organization?.ats;
  const dashboardEnabled = useFeatureFlag("analytics:dashboard");
  const experimentalEnabled = useFeatureFlag("analytics:experimental");

  const metricGroups: NavMetricGroup[] = [];

  // DASHBOARD NAV
  if (dashboardEnabled) {
    const candidateExperienceMetrics = [
      MetricName.OnTimeInterviews,
      MetricName.LongestMonologue,
      MetricName.SpeakingRate,
      MetricName.Interactivity,
    ];
    const deiMetrics = [
      MetricName.QuestionsAsked,
      MetricName.CandidateTalkRatio,
      MetricName.OnTimeInterviews,
      MetricName.Interactivity,
    ];
    if (scorecardMetricsEnabled) {
      deiMetrics.splice(2, 0, MetricName.PassRate);
    }
    const operationalExcellenceMetrics = [
      MetricName.ScorecardCompletionTime,
      MetricName.ScorecardCompletionRate,
    ];
    const interviewerLoadMetrics = [
      MetricName.TotalInterviews,
      MetricName.TotalInterviewTime,
    ];
    const experimentalMetrics = [
      MetricName.TopicTrends,
      MetricName.QuestionTrends,
    ];

    if (canViewAllMetrics(currentUser)) {
      metricGroups.unshift(
        {
          title: "Candidate Experience",
          metrics: candidateExperienceMetrics,
          icon: HiOutlineUsers,
          enabled: true,
        },
        {
          title: "DEI",
          metrics: deiMetrics,
          icon: HiOutlineUserGroup,
          enabled: true,
          segment: AnalyticsDimension.Gender,
        },
        {
          title: "Operational Excellence",
          metrics: operationalExcellenceMetrics,
          icon: HiOutlineSparkles,
          enabled: scorecardMetricsEnabled,
        },
        {
          title: "Interviewer Load",
          metrics: interviewerLoadMetrics,
          icon: HiOutlineCalendar,
          enabled: true,
        },
        {
          title: "Experimental",
          metrics: experimentalMetrics,
          icon: HiOutlineSparkles,
          enabled: experimentalEnabled,
        }
      );
    }
    // LEGACY NAV
  } else {
    const interviewerInsightsMetrics = [
      MetricName.TotalInterviews,
      MetricName.WeeklyInterviews,
      MetricName.TotalInterviewTime,
      MetricName.WeeklyInterviewTime,
    ];
    const conversationDynamicsMetrics = [
      MetricName.CandidateTalkRatio,
      MetricName.LongestMonologue,
      MetricName.SpeakingRate,
      MetricName.Interactivity,
      MetricName.QuestionsAsked,
    ];
    if (hcaMetricsEnabled) {
      conversationDynamicsMetrics.push(MetricName.Sentiment);
    }

    const topicCoverageMetrics = [
      MetricName.WorkLocationCoverage,
      MetricName.CompensationCoverage,
      MetricName.CareLikeFamilyCoverage,
    ];
    const ratingMetrics = [MetricName.Rating, MetricName.StrongRatingPercent];
    const calibrationInsightsMetrics = [MetricName.PassRate];
    const operationalInsightsMetrics = [
      MetricName.ScorecardCompletionRate,
      MetricName.ScorecardCompletionTime,
    ];
    // Put OnTimeInterviews in the right group
    if (scorecardMetricsEnabled) {
      operationalInsightsMetrics.push(MetricName.OnTimeInterviews);
    } else {
      conversationDynamicsMetrics.push(MetricName.OnTimeInterviews);
    }

    if (canViewAllMetrics(currentUser)) {
      metricGroups.unshift(
        {
          title: "Interviewer Insights",
          metrics: interviewerInsightsMetrics,
          icon: IoPeopleOutline,
          enabled: true,
        },
        {
          title: "Conversation Dynamics",
          metrics: conversationDynamicsMetrics,
          icon: IoChatbubblesOutline,
          enabled: true,
        },
        {
          title: "Topic Coverage",
          metrics: topicCoverageMetrics,
          icon: MdOutlineTopic,
          enabled: hcaMetricsEnabled,
        },
        {
          title: "Ratings Metrics",
          metrics: ratingMetrics,
          icon: IoStarHalfOutline,
          enabled: ratingMetricsEnabled,
        },
        {
          title: "Calibration Insights",
          metrics: calibrationInsightsMetrics,
          icon: IoPeopleOutline,
          enabled: scorecardMetricsEnabled,
        },
        {
          title: "Operational Excellence",
          metrics: operationalInsightsMetrics,
          icon: IoPeopleOutline,
          enabled: scorecardMetricsEnabled,
        }
      );
    }
  }

  const enabledNavMetricGroups = metricGroups.filter((g) => g.enabled);

  return enabledNavMetricGroups;
};

export default useSidebarConfig;
