import { Flex, Heading } from "@chakra-ui/react";
import React from "react";

export const SettingsTitle: React.FC<{
  title: string;
  icon?: React.ReactNode;
}> = ({ title, icon }) => (
  <Flex align="center" px="6" py="4" position="relative" color="gray.400">
    {icon && (
      <Flex mr="1" align="center">
        {icon}
      </Flex>
    )}
    <Heading
      as="h2"
      fontSize="xs"
      m={0}
      fontWeight="600"
      textTransform="uppercase"
    >
      {title}
    </Heading>
  </Flex>
);
