import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { LoadingIndicator } from "../../../components";
import { useCurrentUserPlaylistsQuery } from "../../graphql";
import EmptyState from "./EmptyState";
import ManagePlaylist from "./ManagePlaylist";
import MyPlaylistsPageLayout from "./MyPlaylistsPageLayout";
import { useManagePlaylistsNavigation } from "./useManagePlaylistsNavigation";

const MyPlaylists: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultSort = searchParams.get("sort") || "createdAt desc";
  const [activeSort, setActiveSort] = useState<string>(defaultSort);
  const [playlistErrorMsg, setPlaylistErrorMsg] = useState("");

  const { loading, error, data, previousData } = useCurrentUserPlaylistsQuery({
    variables: {
      pagination: {
        orderBy: activeSort,
        page: 1,
      },
    },
  });
  const playlists =
    data?.currentUser?.playlists?.results ||
    previousData?.currentUser?.playlists?.results ||
    [];

  const { activePlaylistId, goToPlaylist, goToPlaylistManagementHome } =
    useManagePlaylistsNavigation(playlists);

  useEffect(() => {
    setSearchParams({ sort: activeSort }, { replace: true });
  }, [activeSort]);

  return (
    <MyPlaylistsPageLayout
      playlistData={playlists}
      activeSort={activeSort}
      setActiveSort={setActiveSort}
      activePlaylistId={activePlaylistId}
      goToPlaylist={goToPlaylist}
      data-tour-id="my-playlists-page"
      error={error?.message || playlistErrorMsg}
    >
      {activePlaylistId ? (
        <ManagePlaylist
          playlistId={activePlaylistId}
          goToPlaylistManagementHome={goToPlaylistManagementHome}
          onPlaylistError={setPlaylistErrorMsg}
        />
      ) : loading ? (
        <LoadingIndicator />
      ) : (
        <EmptyState />
      )}
    </MyPlaylistsPageLayout>
  );
};

export default MyPlaylists;
