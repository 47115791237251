import { CallSpeakerFragment } from "../../../graphql";

export type CallSpeaker = CallSpeakerFragment & { condensed?: boolean };

export const sortSpeakerList = (speakers: CallSpeaker[]): CallSpeaker[] => {
  const sortedSpeakers = [];
  const candidate = speakers.find((speaker) => speaker.isCandidate);
  const interviewers = speakers.filter((speaker) => !speaker.isCandidate);
  if (candidate) {
    sortedSpeakers.push(candidate);
  }
  if (interviewers.length) {
    const sortedInterviewers = interviewers.sort(
      (a, b) => b.talkTimePercentage - a.talkTimePercentage
    );
    sortedSpeakers.push(...sortedInterviewers);
  }
  return sortedSpeakers;
};

export const condenseSpeakerList = (speakers: CallSpeaker[]): CallSpeaker[] => {
  // We always show the first 3 speakers: a candidate and two interviewers, or just 3 interviewers.
  const speakersCopy = [...speakers];
  const condensedSpeakers = speakersCopy.splice(0, 3);
  // If there are any remaining interviewers/speakers, we group them together.
  if (speakersCopy.length) {
    const label = `+${speakersCopy.length} more`;
    const talkTimePercentage = Math.min(
      speakersCopy.reduce(
        (sum, speaker) => sum + speaker.talkTimePercentage,
        0
      ),
      100
    );
    condensedSpeakers.push({
      id: "condensed_speakers",
      speakerTag: -1,
      label,
      isCandidate: false,
      isInterviewer: false,
      identified: true,
      talkTimePercentage,
      condensed: true,
    });
  }
  return condensedSpeakers;
};
