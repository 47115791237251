import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";
import { matchPath, NavLink, useLocation } from "react-router-dom";

type NavLinkBaseProps = BoxProps & {
  isActive: boolean;
};

/** Provides the base UI for a settings nav link, except it is not itself a link */
export const SettingsNavLinkBase: React.FC<NavLinkBaseProps> = ({
  isActive,
  ...props
}) => (
  <Box
    fontWeight="medium"
    fontSize="sm"
    py="3"
    pl="6"
    pr="5"
    color={isActive ? "blue.600" : "gray.700"}
    backgroundColor={isActive ? "blue.50" : "inherit"}
    _hover={{ backgroundColor: "blue.100", color: "blue.600" }}
    {...props}
  />
);

type NavLinkProps = BoxProps & {
  to: string;
  /** See https://reactrouter.com/en/main/components/nav-link#end for details */
  end?: boolean;
  /** Additional URLs that should mark this item as active when any of them are active */
  matchPaths?: string[];
};

/** Link with styling for the active state  */
export const SettingsNavLink: React.FC<NavLinkProps> = ({
  to,
  end,
  matchPaths = [],
  ...props
}) => {
  const { pathname } = useLocation();
  const matchPathsActive = matchPaths.some((path) => matchPath(path, pathname));

  return (
    <NavLink to={to} end={end}>
      {({ isActive }) => (
        <SettingsNavLinkBase
          isActive={isActive || matchPathsActive}
          {...props}
        />
      )}
    </NavLink>
  );
};
