import { Box, Grid, GridItem } from "@chakra-ui/react";
import React, { useRef } from "react";

import { useIsSmallScreen } from "../../../hooks/useIsSmallScreen";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import useElementHeight from "../../hooks/useElementHeight";

type RecordingLayoutProps = {
  header: React.ReactNode;
  video: React.ReactNode;
  transcript: React.ReactNode;
  sidebar: React.ReactNode;
  /**
   * `children` is used for content like modals that needs to exist
   * in the DOM but does not need its root node to occupy space or be visible
   */
  children?: React.ReactNode;
};

const RecordingLayout: React.FC<RecordingLayoutProps> = ({
  header,
  video,
  transcript,
  sidebar,
  children,
}) => {
  const { layoutHeight, absoluteLayoutHeight } = useWindowDimensions();
  const isSmallScreen = useIsSmallScreen();

  const videoRef = useRef<HTMLDivElement | null>(null);
  const videoHeight = useElementHeight(videoRef);

  const headerRef = useRef<HTMLDivElement | null>(null);
  const headerHeight = useElementHeight(headerRef);

  const contentHeight =
    absoluteLayoutHeight + (isSmallScreen ? videoHeight : headerHeight);

  return (
    <Box height={layoutHeight} overflowY="auto">
      <Grid
        height={{ base: "auto", md: `${contentHeight}px` }}
        minH={`${contentHeight}px`}
        background="white"
        borderColor="gray.100"
        templateColumns={{
          base: "1fr",
          lg: "minmax(200px, 60%) minmax(420px, 1fr)",
        }}
        templateRows="repeat(2, min-content) 1fr 0"
        templateAreas={{
          base: `
            "video"
            "header"
            "sidebar"
            "hidden"`,
          lg: `
            "header      header"
            "video       sidebar"
            "transcript  sidebar"
            "hidden      hidden"`,
        }}
      >
        <GridItem
          area="header"
          borderBottomWidth={{ base: "0", lg: "1px" }}
          ref={headerRef}
        >
          {header}
        </GridItem>

        <GridItem area="video" ref={videoRef}>
          {video}
        </GridItem>

        {!isSmallScreen && (
          <GridItem
            bg="white"
            gridArea="transcript"
            overflowY="auto"
            borderRightWidth="1px"
          >
            {transcript}
          </GridItem>
        )}

        <GridItem area="sidebar" bg="white" overflowY="auto">
          {sidebar}
        </GridItem>

        <GridItem area="hidden">{children}</GridItem>
      </Grid>
    </Box>
  );
};

export default RecordingLayout;
