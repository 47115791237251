import { Flex, Heading } from "@chakra-ui/react";
import React from "react";

import { CenteredCardLayout } from "../../../components";
import useCurrentUser from "../../hooks/useCurrentUser";
import useSignOut from "../../hooks/useSignOut";
import SignUpForm from "./SignUpForm";

const SelectPersonaPage: React.FC = () => {
  const currentUser = useCurrentUser();
  const onSignOut = useSignOut({ currentUser });

  return (
    <CenteredCardLayout
      containerProps={{ alignItems: "center" }}
      onSignOut={onSignOut}
    >
      <Flex
        pt={8}
        flexDirection="column"
        alignItems="center"
        width={["100%", "400px"]}
      >
        <Heading as="h1" fontSize="28px" fontWeight="normal" mb={4}>
          Tell us about yourself
        </Heading>
        <SignUpForm />
      </Flex>
    </CenteredCardLayout>
  );
};

export default SelectPersonaPage;
