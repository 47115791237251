import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";

import { useToast } from "../../../components";
import {
  CurrentUserFragment,
  useUpdateOrganizationAiSettingsMutation,
} from "../../graphql";
import SettingsPageContainer from "./shared/SettingsPageContainer";

interface AiSettingsProps {
  currentUser: CurrentUserFragment;
}

interface FormData {
  allowAiCustomTemplates: boolean;
  pasteAiNotesInScorecard: boolean;
}

interface AiSettingsFormProps {
  allowAiCustomTemplates: boolean;
  pasteAiNotesInScorecard: boolean;
  isLoading: boolean;
  onSubmit: (formData: FormData) => void;
}

const AiSettingsForm: React.FC<AiSettingsFormProps> = ({
  allowAiCustomTemplates,
  pasteAiNotesInScorecard,
  isLoading,
  onSubmit,
}) => {
  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: {
      allowAiCustomTemplates,
    },
  });

  return (
    <form
      autoComplete="on"
      onSubmit={handleSubmit((formData) => {
        onSubmit(formData);
      })}
    >
      <VStack spacing="8" align="start">
        <FormControl id="pasteAiNotesInScorecard">
          <Checkbox
            {...register("pasteAiNotesInScorecard")}
            defaultChecked={pasteAiNotesInScorecard}
          >
            Include AI notes when pasting interview notes to scorecard
          </Checkbox>
          <FormHelperText>
            This sends matched AI notes to complete scorecard questions for
            filling out Greenhouse Scorecards. Defaults to disabled.
          </FormHelperText>
        </FormControl>
        <FormControl id="allowAiCustomTemplates">
          <Checkbox
            {...register("allowAiCustomTemplates")}
            defaultChecked={allowAiCustomTemplates}
          >
            Allow custom AI note templates for recordings
          </Checkbox>
          <FormHelperText>
            Checking this will allow users to build custom templates with free
            form text boxes that extract notes from the transcript using AI.
          </FormHelperText>
        </FormControl>
        <Button type="submit" isLoading={isLoading} data-testid="save">
          Save
        </Button>
      </VStack>
    </form>
  );
};

const AiSettings: React.FC<AiSettingsProps> = ({ currentUser }) => {
  const toast = useToast();

  const [updateOrganizationSettings, { loading: orgSettingsLoading }] =
    useUpdateOrganizationAiSettingsMutation({
      onCompleted: (data) => {
        if (data.updateOrganizationAiSettings?.organization) {
          toast({
            status: "success",
            title: "Organization Settings",
            description: "Success!",
          });
        }
      },
      onError: () => {
        toast({
          status: "error",
          title: "Organization Settings",
          description: "There was a problem - please try again",
        });
      },
    });

  return (
    <SettingsPageContainer
      heading="AI"
      subHeading="Configure AI based features in BrightHire."
    >
      <AiSettingsForm
        allowAiCustomTemplates={currentUser.organization.allowAiCustomTemplates}
        pasteAiNotesInScorecard={
          currentUser.organization.pasteAiNotesInScorecard
        }
        isLoading={orgSettingsLoading}
        onSubmit={(formData) =>
          updateOrganizationSettings({ variables: formData })
        }
      />
    </SettingsPageContainer>
  );
};

export default AiSettings;
