const colorVars = {
  gray: {
    50: "var(--chakra-colors-gray-50)",
    100: "var(--chakra-colors-gray-100)",
    200: "var(--chakra-colors-gray-200)",
    300: "var(--chakra-colors-gray-300)",
    400: "var(--chakra-colors-gray-400)",
    500: "var(--chakra-colors-gray-500)",
    600: "var(--chakra-colors-gray-600)",
    700: "var(--chakra-colors-gray-700)",
    800: "var(--chakra-colors-gray-800)",
    900: "var(--chakra-colors-gray-900)",
  },
  orange: {
    50: "var(--chakra-colors-orange-50)",
    100: "var(--chakra-colors-orange-100)",
    200: "var(--chakra-colors-orange-200)",
    300: "var(--chakra-colors-orange-300)",
    400: "var(--chakra-colors-orange-400)",
    500: "var(--chakra-colors-orange-500)",
    600: "var(--chakra-colors-orange-600)",
    700: "var(--chakra-colors-orange-700)",
    800: "var(--chakra-colors-orange-800)",
    900: "var(--chakra-colors-orange-900)",
  },
  yellow: {
    50: "var(--chakra-colors-yellow-50)",
    100: "var(--chakra-colors-yellow-100)",
    200: "var(--chakra-colors-yellow-200)",
    300: "var(--chakra-colors-yellow-300)",
    400: "var(--chakra-colors-yellow-400)",
    500: "var(--chakra-colors-yellow-500)",
    600: "var(--chakra-colors-yellow-600)",
    700: "var(--chakra-colors-yellow-700)",
    800: "var(--chakra-colors-yellow-800)",
    900: "var(--chakra-colors-yellow-900)",
  },
  green: {
    50: "var(--chakra-colors-green-50)",
    100: "var(--chakra-colors-green-100)",
    200: "var(--chakra-colors-green-200)",
    300: "var(--chakra-colors-green-300)",
    400: "var(--chakra-colors-green-400)",
    500: "var(--chakra-colors-green-500)",
    600: "var(--chakra-colors-green-600)",
    700: "var(--chakra-colors-green-700)",
    800: "var(--chakra-colors-green-800)",
    900: "var(--chakra-colors-green-900)",
  },
  blue: {
    50: "var(--chakra-colors-blue-50)",
    100: "var(--chakra-colors-blue-100)",
    200: "var(--chakra-colors-blue-200)",
    300: "var(--chakra-colors-blue-300)",
    400: "var(--chakra-colors-blue-400)",
    500: "var(--chakra-colors-blue-500)",
    600: "var(--chakra-colors-blue-600)",
    700: "var(--chakra-colors-blue-700)",
    800: "var(--chakra-colors-blue-800)",
    900: "var(--chakra-colors-blue-900)",
  },
  red: {
    50: "var(--chakra-colors-red-50)",
    100: "var(--chakra-colors-red-100)",
    200: "var(--chakra-colors-red-200)",
    300: "var(--chakra-colors-red-300)",
    400: "var(--chakra-colors-red-400)",
    500: "var(--chakra-colors-red-500)",
    600: "var(--chakra-colors-red-600)",
    700: "var(--chakra-colors-red-700)",
    800: "var(--chakra-colors-red-800)",
    900: "var(--chakra-colors-red-900)",
  },
  cyan: {
    50: "var(--chakra-colors-cyan-50)",
    100: "var(--chakra-colors-cyan-100)",
    200: "var(--chakra-colors-cyan-200)",
    300: "var(--chakra-colors-cyan-300)",
    400: "var(--chakra-colors-cyan-400)",
    500: "var(--chakra-colors-cyan-500)",
    600: "var(--chakra-colors-cyan-600)",
    700: "var(--chakra-colors-cyan-700)",
    800: "var(--chakra-colors-cyan-800)",
    900: "var(--chakra-colors-cyan-900)",
  },
  black: "var(--chakra-colors-black)",
  white: "var(--chakra-colors-white)",
  secondary: "var(--chakra-colors-secondary)",
  searchBackground: "var(--chakra-colors-searchBackground)",
  currentSearchBackground: "var(--chakra-colors-currentSearchBackground)",
  icons: {
    bright: "var(--chakra-colors-icons-bright)",
    comment: "var(--chakra-colors-icons-comment)",
    cue: "var(--chakra-colors-icons-cue)",
    flag: "var(--chakra-colors-icons-flag)",
    highlightV2: "var(--chakra-colors-icons-highlightV2)",
    note: "var(--chakra-colors-icons-note)",
    star: "var(--chakra-colors-icons-star)",
    thumbsDown: "var(--chakra-colors-icons-thumbsDown)",
    thumbsUp: "var(--chakra-colors-icons-thumbsUp)",
  },
};

export default colorVars;
