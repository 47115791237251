import { Box, Flex, IconButton, Text, Textarea } from "@chakra-ui/react";
import React, { FC, useState } from "react";
import { HiOutlineTrash } from "react-icons/hi";

import { CommonGuideItemProps } from "../types";
import GuideItemLabel from "./GuideItemLabel";

const GuideItemQuestion: FC<CommonGuideItemProps> = ({
  itemId,
  text,
  isEditing,
  canDelete,
  onDelete,
  onEnter,
  onUpdate,
}) => {
  const [storedValue, setStoredValue] = useState<string>(text);

  const tooltipContent = (
    <Text>
      <Text as="span" fontWeight="600">
        Questions
      </Text>{" "}
      can be used to provide guidance to interviewers on how to assess
      candidates.
    </Text>
  );

  if (isEditing) {
    return (
      <Box width="100%">
        <GuideItemLabel text="Question" tooltipContent={tooltipContent} />
        <Flex direction="row" alignItems="center">
          <Textarea
            autoFocus={isEditing}
            value={storedValue}
            onChange={(e) => {
              setStoredValue(e.target.value);
              onUpdate(itemId, { description: e.target.value });
            }}
            whiteSpace="pre-wrap"
            onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement>) => {
              if (event.key === "Enter" && !event.shiftKey && onEnter) {
                event.preventDefault();
                onEnter();
              }
            }}
          />
          <IconButton
            variant="icon"
            aria-label="Delete Question"
            color="red.400"
            backgroundColor="transparent"
            icon={<HiOutlineTrash size={20} />}
            ml={6}
            onClick={onDelete}
            disabled={!canDelete}
          />
        </Flex>
      </Box>
    );
  }

  if (!text) {
    return (
      <Text color="gray.600" as="i" fontSize="md">
        Add question
      </Text>
    );
  }

  return (
    <Flex direction="row" alignItems="flex-start">
      <Text color="gray.400" fontWeight="600" mr={1}>
        Q:
      </Text>
      <Text color="gray.800" whiteSpace="pre-wrap">
        {text}
      </Text>
    </Flex>
  );
};

export default GuideItemQuestion;
