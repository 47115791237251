import { Box, Button, Flex, Text, useTheme } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { SortingRule } from "react-table";

import {
  LoadingIndicator,
  SelectDateRange,
  SortableTable,
} from "../../../components";
import { PageOptions } from "../../../hooks/useOffsetPagination";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { AnalyticsDimension, MetricName } from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import AnalyticsFilter from "./AnalyticsFilter";
import AnalyticsHeroContent from "./AnalyticsHeroContent";
import AnalyticsInfoAlert from "./AnalyticsInfoAlert";
import { FilterIcon } from "./icons";
import LabeledChartSelect from "./LabeledChartSelect";
import { AnalyticsConfig } from "./useAnalyticsConfig";
import { AnalyticsData } from "./useAnalyticsData";
import { useAnalyticsSelectTheme } from "./useAnalyticsSelectTheme";
import { scorecardMetrics } from "./utils";

export type AnalyticsMetricsContentTableProps = {
  analyticsConfig: AnalyticsConfig;
  analyticsData: AnalyticsData;
  flaggedFeatures: {
    genderSegmentation: boolean;
    togglePerformanceOverlay: boolean;
    drilldowns: boolean;
  };
};

const AnalyticsMetricsContentTable: React.FC<
  AnalyticsMetricsContentTableProps
> = ({ analyticsConfig, analyticsData }) => {
  const { colors } = useTheme();
  const sendGAEvent = useSendGAEvent();
  const [selectTheme, selectStyles] = useAnalyticsSelectTheme();
  const [showFilters, setShowFilters] = useState(true);
  const [filterHeights, setFilterHeights] = useState<{ [key: string]: number }>(
    { defaultHeight: 50 }
  );
  const metric = MetricName.TotalInterviews;
  const release2Enabled = useFeatureFlag("analytics:release2");

  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState({ id: "countDataPoints", desc: true });

  // Set showfilters to true when query param source=alert and some filters are present
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get("source");
    const departments = urlParams.get("departments");
    const positions = urlParams.get("positions");
    const interviewers = urlParams.get("interviewers");
    if (source === "alert" && (departments || positions || interviewers)) {
      setShowFilters(true);
    }
  }, []);

  const randomizedData = useMemo(() => {
    return (
      analyticsData?.dataPoints?.map((pt) => {
        const newPt = {
          ...pt,
          ontimestarts: generateRandomNumber(),
          talkratio: generateRandomNumber(),
          speakingrate: generateRandomNumber(),
          questionopportunity: generateRandomNumber(),
          agendaset: generateRandomNumber(),
          illegalquestions: generateRandomNumber(),
          score: 0,
        };

        newPt.score = Math.floor(
          (newPt.ontimestarts +
            newPt.talkratio +
            newPt.speakingrate +
            newPt.questionopportunity +
            newPt.agendaset +
            newPt.illegalquestions) /
            6
        );

        return newPt;
      }) ?? []
    );
  }, [analyticsData.dataPoints]);

  const [pagedData, pageInfo] = useMemo(() => {
    const limit = analyticsConfig.chartLimit.value;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    randomizedData.sort((a, b) => a[sortBy.id] - b[sortBy.id]);
    if (sortBy.desc) {
      randomizedData.reverse();
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const d = randomizedData.slice(
      (currentPage - 1) * limit,
      currentPage * limit
    );
    return [
      d,
      {
        currentPage,
        totalPages: Math.ceil(randomizedData.length / limit),
        handlePageNumberChange: (i: number) => {
          setCurrentPage(i);
        },
        onChangeSort: (sb: any) => {
          setSortBy(sb[0]);
        },
        sortBy,
      },
    ];
  }, [currentPage, randomizedData, analyticsConfig.chartLimit, sortBy]);

  return (
    <>
      <Flex
        flexDir="row"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Flex flexDir="row" alignItems="baseline">
          <Text fontSize="24px" fontWeight="700" color="gray.700" pr="2">
            Interview Quality
          </Text>
          {/* <AnalyticsInfoModal metric={metric} /> */}
        </Flex>

        {analyticsData.filtersAvailable && (
          <Flex>
            <FiltersButton
              open={showFilters}
              toggleFilters={() => {
                sendGAEvent(
                  `filters_${!showFilters ? "open" : "closed"}`,
                  "analytics"
                );
                setShowFilters((state) => !state);
              }}
              data-tour-id="analytics-filters"
            />
            <Flex minW="148px">
              <SelectDateRange
                state={analyticsConfig.dateRange.value}
                onSelect={analyticsConfig.dateRange.setValue}
                includeLabel={false}
                selectStyles={selectStyles}
                selectTheme={selectTheme}
                rangeStyleProps={{ wrap: "nowrap" }}
                dateInputStyleProps={{
                  fontSize: "14px",
                  color: colors.gray[900],
                  borderColor: colors.gray[100],
                }}
              />
            </Flex>
          </Flex>
        )}
      </Flex>

      {scorecardMetrics.includes(metric) &&
        analyticsConfig.dateRange.value.start < new Date("2022-03-07") && (
          <AnalyticsInfoAlert status="warning" mt="4">
            Data is unavailable for this report before March 7th, 2022.
          </AnalyticsInfoAlert>
        )}

      {/* Dimensions */}
      {/* Control filter visibility with display CSS so filter queries run on load */}
      {analyticsData.filtersAvailable && (
        <Flex
          flexDir="row"
          mt="6"
          overflow={showFilters ? "visible" : "hidden"}
          opacity={showFilters ? 1 : 0}
          height={
            showFilters ? `${Math.max(...Object.values(filterHeights))}px` : "0"
          }
          transition="height 0.25s ease-in-out, opacity 0.5s ease-in-out"
          borderBottom="1px solid"
          borderColor="gray.200"
          pb={4}
        >
          <AnalyticsFilter
            dimension={AnalyticsDimension.Department}
            secondaryDimension={analyticsConfig.secondaryDimension.value}
            placeholder="All Departments"
            queryVariables={analyticsData.commonQueryVariables}
            multiSelect={analyticsConfig.departments}
            flex={`0 0 ${release2Enabled ? "25%" : "33.3%"}`}
            maxWidth={release2Enabled ? "25%" : "33.3%"}
            filterHeights={filterHeights}
            setFilterHeights={setFilterHeights}
            pr="6"
          />
          <AnalyticsFilter
            dimension={AnalyticsDimension.Position}
            secondaryDimension={analyticsConfig.secondaryDimension.value}
            placeholder="All Positions"
            queryVariables={analyticsData.commonQueryVariables}
            multiSelect={analyticsConfig.positions}
            flex={`0 0 ${release2Enabled ? "25%" : "33.3%"}`}
            maxWidth={release2Enabled ? "25%" : "33.3%"}
            filterHeights={filterHeights}
            setFilterHeights={setFilterHeights}
            pr="6"
          />
          <AnalyticsFilter
            dimension={AnalyticsDimension.Interviewer}
            secondaryDimension={analyticsConfig.secondaryDimension.value}
            placeholder="All Interviewers"
            queryVariables={analyticsData.commonQueryVariables}
            multiSelect={analyticsConfig.interviewers}
            flex={`0 0 ${release2Enabled ? "25%" : "33.3%"}`}
            maxWidth={release2Enabled ? "25%" : "33.3%"}
            filterHeights={filterHeights}
            setFilterHeights={setFilterHeights}
            pr="6"
          />
        </Flex>
      )}

      {analyticsData.heroType ? (
        <AnalyticsHeroContent
          analyticsHero={analyticsData.heroType}
          metric={metric}
        />
      ) : (
        <>
          {/* Chart Options */}
          <Flex
            data-tour-id="analytics-dimensions"
            justifyContent="space-between"
            pt={4}
          >
            <Flex>
              <LabeledChartSelect
                testid="analytics--group-menu"
                mr="4"
                label="Group"
                flexDir="column"
                alignItems="flex-start"
                singleSelect={analyticsConfig.primaryDimension}
              />
            </Flex>
            <Flex>
              {analyticsConfig.primaryDimension.value !==
                AnalyticsDimension.Performance && (
                <LabeledChartSelect
                  data-testid="analytics--show-menu"
                  minW="150px"
                  flexDir="column"
                  alignItems="flex-start"
                  label="Show"
                  singleSelect={analyticsConfig.chartLimit}
                />
              )}
            </Flex>
          </Flex>

          {/* Chart */}
          <Box mt="5" color="gray.800">
            {analyticsData.loading && <LoadingIndicator />}
            {!analyticsData.loading && (
              <MetricTable
                rows={pagedData || []}
                pageOptions={pageInfo}
                sortBy={sortBy}
              />
            )}
          </Box>
        </>
      )}
    </>
  );
};

type FiltersButtonProps = {
  open: boolean;
  toggleFilters(): void;
};
const FiltersButton: React.FC<FiltersButtonProps> = ({
  open,
  toggleFilters,
}) => {
  return (
    <Button
      data-testid="analytics--filters-button"
      bg={open ? "gray.50" : undefined}
      color={open ? "gray.400" : undefined}
      variant="ghost"
      ml="auto"
      mr="6"
      fontWeight="600"
      onClick={toggleFilters}
    >
      <FilterIcon mr="2" color={open ? "gray.500" : undefined} />
      <Text fontSize="sm">Filters</Text>
    </Button>
  );
};

interface MetricTableProps {
  rows: Array<any>;
  pageOptions?: PageOptions;
  sortBy: SortingRule<string>;
}

function generateRandomNumber(): number {
  return Math.floor(Math.random() * 71) + 30;
}

const MetricTable: React.FC<MetricTableProps> = ({
  rows,
  pageOptions,
  sortBy,
}) => {
  return (
    <SortableTable<any>
      columns={[
        {
          Header: "Name",
          accessor: "label",
          id: "label",
          Cell: (data: any) => {
            const { label } = data.row.values;
            return (
              <Text pl={4} pr={3} minW={170}>
                {label}
              </Text>
            );
          },
        },
        {
          Header: "Interviews",
          accessor: "countDataPoints",
          id: "countDataPoints",
        },
        {
          Header: "Quality Score",
          accessor: "score",
          id: "score",
          Cell: (data: any) => {
            const { score } = data.row.values;
            let bgColor = "green.50";
            let fontColor = "green.800";
            if (score < 70) {
              bgColor = "yellow.50";
              fontColor = "yellow.800";
            }
            if (score < 50) {
              bgColor = "red.50";
              fontColor = "red.800";
            }
            return (
              <Text
                bgColor={bgColor}
                fontWeight="500"
                color={fontColor}
                px={4}
                py={3}
              >
                {score}
              </Text>
            );
          },
        },
        {
          Header: "On-Time starts",
          accessor: "ontimestarts",
          id: "ontimestarts",
          headerHelperText:
            "Interviewer joins meeting within a specified time from the interview start time.",
        },
        {
          Header: "Talk ratio",
          accessor: "talkratio",
          id: "talkratio",
          headerHelperText:
            "Interviewer provides candidate a specified percentage of total time to speak.",
        },
        {
          Header: "Speaking rate",
          accessor: "speakingrate",
          id: "speakingrate",
          headerHelperText:
            "Interviewer’s pace of speaking falls within a specified range.",
        },
        {
          Header: "Question opportunity",
          accessor: "questionopportunity",
          id: "questionopportunity",
          headerHelperText:
            "Interviewer provides the candidate an opportunity to ask questions.",
        },
        {
          Header: "Agenda set",
          accessor: "agendaset",
          id: "agendaset",
          headerHelperText:
            "Interviewer provides the candidate an introduction and agenda for the interview.",
        },
        {
          Header: "Problematic questions",
          accessor: "illegalquestions",
          id: "illegalquestions",
          Cell: (data: any) => {
            const { illegalquestions } = data.row.values;
            return <Text pl={4}>{illegalquestions}</Text>;
          },
          headerHelperText:
            "Interviewer asks a question during the interview that may be problematic.",
        },
      ]}
      disablePaddingOnColumns={["score", "label", "illegalquestions"]}
      data={rows}
      autoResetSortBy={false}
      initialSort={sortBy}
      pageOptions={pageOptions}
      manualPagination
    />
  );
};

export default AnalyticsMetricsContentTable;
