import React from "react";
import { useNavigate } from "react-router-dom";

import NoAccess from "../../../components/NoAccess";
import { usePageTracker } from "../../../utils/googleAnalytics";
import { DefaultPageLayout } from "../../layouts";

const NoAccessPage: React.FC = () => {
  usePageTracker("no-access");
  const navigate = useNavigate();

  return (
    <DefaultPageLayout bg="white">
      <NoAccess
        buttonConfig={{
          text: "Go to home",
          onClick: () => {
            navigate("/");
          },
        }}
      />
    </DefaultPageLayout>
  );
};

export default NoAccessPage;
