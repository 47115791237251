import { QueryResult } from "@apollo/client";
import { Box, Divider, Flex, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import {
  CallSpeakerFragment,
  TrackerGroupFragment,
  TranscriptSegmentFragment,
} from "../../../graphql";
import TrackerKeywordView from "../../Trackers/beta/TrackerKeywordView";
import TrackerSegmentView from "../../Trackers/beta/TrackerSegmentView";
import { TrackerTabState } from "../../Trackers/types";

type TrackerSegmentData = {
  keyword?: string | null;
  segments: TranscriptSegmentFragment[];
};

export type TrackersTabProps = {
  onTrackerGroupChange?(groupId?: string): void;
  onTrackerKeywordChange?(keywordId?: string): void;
  onSeek?(t: number): void;
  trackers?: TrackerGroupFragment[] | null;
  trackerSegments?: TrackerSegmentData[] | null;
  transcriptLanguageCode?: string | null;
  speakers?: CallSpeakerFragment[] | null;
  keywordsFetchData: Pick<QueryResult, "loading" | "error">;
  segmentsFetchData: Pick<QueryResult, "loading" | "error">;
  speakerMenuDisabled: boolean;
  doesCallHaveTranscript: boolean;
};

const TrackersTab: React.FC<TrackersTabProps> = ({
  onTrackerGroupChange,
  onTrackerKeywordChange,
  onSeek,
  trackers,
  trackerSegments,
  transcriptLanguageCode,
  speakers,
  keywordsFetchData,
  segmentsFetchData,
  speakerMenuDisabled,
  doesCallHaveTranscript,
}) => {
  const [trackerTabState, setTrackerTabState] = useState<TrackerTabState>();
  const trackerGroup = trackerTabState?.selectedTracker?.group;
  const trackerKeyword = trackerTabState?.selectedTracker?.keyword;

  useEffect(() => {
    onTrackerGroupChange?.(trackerGroup?.id);
  }, [trackerGroup?.id]);

  useEffect(() => {
    onTrackerKeywordChange?.(trackerKeyword?.id);
  }, [trackerKeyword?.id]);

  return transcriptLanguageCode &&
    doesCallHaveTranscript &&
    transcriptLanguageCode !== "en" ? (
    <Flex direction="column" alignItems="center" pt="180px">
      <img src="/static/images/clipboards.svg" width="241" />
      <Text maxWidth="300px" fontSize="sm" textAlign="center">
        We do not yet support POIs for interviews recorded in this language.
      </Text>
    </Flex>
  ) : (
    <VStack
      align="top"
      mt="6"
      mx="2"
      data-testid="tracker-tab-container"
      spacing="0"
    >
      <Box mx="4">
        <Text fontWeight="bold" fontSize="sm">
          POIs Found in Your Transcript
        </Text>
      </Box>
      <Box px="4" mt="6">
        <TrackerKeywordView
          setTrackerTabState={setTrackerTabState}
          trackerTabState={trackerTabState}
          trackers={trackers}
          speakers={speakers}
          {...keywordsFetchData}
        />
      </Box>
      <Box height="1px" background="" px="4" pt="6">
        <Divider color="gray.100" />
      </Box>

      {trackerTabState?.selectedTracker && (
        <Box pt="5">
          <TrackerSegmentView
            trackerSegments={trackerSegments}
            speakerMenuDisabled={speakerMenuDisabled}
            speakers={speakers}
            trackerTabState={trackerTabState}
            setTrackerTabState={setTrackerTabState}
            seek={(t) => onSeek?.(t)}
            {...segmentsFetchData}
          />
        </Box>
      )}
    </VStack>
  );
};

export default TrackersTab;
