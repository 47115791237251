import {
  Box,
  BoxProps,
  Heading,
  HeadingProps,
  ThemingProps,
  useStyleConfig,
} from "@chakra-ui/react";
import React from "react";

export type CardTitleProps = HeadingProps;

export const CardTitle = React.forwardRef<HTMLHeadingElement, CardTitleProps>(
  (props, ref) => (
    <Heading
      ref={ref}
      as="h2"
      fontSize="sm"
      lineHeight="44px"
      height="45px"
      m={0}
      px="6"
      py="0"
      borderBottomWidth="1px"
      {...props}
    />
  )
);

export interface CardProps extends BoxProps, ThemingProps<"Card"> {}

export const Card = React.forwardRef<
  HTMLDivElement,
  CardProps & {
    children?: React.ReactNode;
  }
>((props, ref) => {
  const { variant, ...rest } = props;
  const styles = useStyleConfig("Card", { variant });
  return <Box ref={ref} sx={styles} {...rest} />;
});
