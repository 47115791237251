import { useEffect } from "react";

import { uuid as uuidRegex } from "../../../utils/regex";

export const manageTrainingRoute = (id: string): string => {
  return `/training/manage/${id}`;
};

export const viewTrainingRoute = (id: string): string => {
  return `/training/view/${id}`;
};

export const EmptyName = "Untitled Program";

/**
 * Parse URLs of the form "<domain>/interview/<callId>", ignoring
 * subpaths and query strings. Also, "<domain/interview/<callId>/clip/<clipId"
 */
export const parseCallAndClipFromUrl = (
  urlStr: string
):
  | {
      callId: string;
      clipId?: string;
    }
  | undefined => {
  // Catch exceptions and treat as an inability to parse
  try {
    const pathParts = new URL(urlStr).pathname.split("/");

    const callIdIndex = pathParts.findIndex((x) => x === "interview") + 1;
    const clipIdIndex = pathParts.findIndex((x) => x === "clip") + 1;

    const callId: string | undefined = pathParts[callIdIndex];
    const clipId: string | undefined = pathParts[clipIdIndex];

    if (!callId || !callId.match(uuidRegex)) {
      return undefined;
    }

    if (clipId) {
      if (!clipId.match(uuidRegex)) {
        return undefined;
      }
      return { callId, clipId };
    }

    return { callId };
  } catch {
    return undefined;
  }
};

/**
 * Parse URL type from a string. Paths are assumed to be of the form
 * "/guides", "/guides/shared", "/guides/new", "/guide/:callGuideId"
 * "/positions", "/positions/mine", "/positions/new", "/position/:positionId"
 * "/candidate/:candidateId"
 */
export const parseInternalUrlType = (url: string): string => {
  try {
    const pathParts = new URL(url).pathname.split("/");
    const firstPart = pathParts[1];
    switch (firstPart) {
      case "guides":
      case "guide":
        return "guide";
      case "positions":
      case "position":
        return "position";
      case "candidate":
      case "candidates":
        return "candidate";
      case "":
      case undefined:
        return "home";
      default:
        return "unknown";
    }
  } catch {
    return "invalid";
  }
};

export const useOnVisibilityChange = (callback: () => void): void => {
  useEffect(() => {
    document.addEventListener("visibilitychange", callback);
    return () => {
      document.removeEventListener("visibilitychange", callback);
    };
  });
};

export const reorder = (arr: any[], from: number, to: number): any[] => {
  const newArr = [...arr];
  newArr.splice(to, 0, newArr.splice(from, 1)[0]);
  return newArr;
};
