import { ApolloError, MutationTuple } from "@apollo/client";

import {
  CallGuideVisibility,
  UpdateCallGuideMutation,
  UpdateCallGuideMutationVariables,
  useUpdateCallGuideMutation,
} from "../..";
import {
  removeFromCurrentUserSharedCallGuidesQuery,
  removeFromPositionQuery,
  removeFromSharedCallGuidesQuery,
  updateCurrentUserSharedCallGuidesQuery,
  updatePositionQuery,
  updateSharedCallGuidesQuery,
} from "./utils";

export default function useUpdateCallGuide({
  onCompleted,
  onError,
  positionId,
}: {
  onCompleted?: () => void;
  onError?: (error: ApolloError) => void;
  positionId?: string;
}): [
  MutationTuple<UpdateCallGuideMutation, UpdateCallGuideMutationVariables>[0],
  { loading: boolean; error: ApolloError | undefined }
] {
  const [updateCallGuide, { error, loading }] = useUpdateCallGuideMutation({
    onCompleted,
    onError,
    update(cache, { data: updateCallGuideData }) {
      const updatedCallGuide = updateCallGuideData?.updateCallGuide?.callGuide;
      if (updatedCallGuide) {
        if (updatedCallGuide.visibility === CallGuideVisibility.Public) {
          updateSharedCallGuidesQuery(cache, updatedCallGuide);
          updateCurrentUserSharedCallGuidesQuery(cache, updatedCallGuide);
        } else {
          removeFromSharedCallGuidesQuery(cache, updatedCallGuide.id);
          removeFromCurrentUserSharedCallGuidesQuery(
            cache,
            updatedCallGuide.id
          );
        }
        // if the related position changed, update the cache
        if (positionId !== updatedCallGuide.position?.id) {
          if (updatedCallGuide.position?.id) {
            updatePositionQuery(
              cache,
              updatedCallGuide,
              updatedCallGuide.position.id
            );
          }
          if (positionId) {
            removeFromPositionQuery(cache, updatedCallGuide.id, positionId);
          }
        }
      }
    },
  });
  return [updateCallGuide, { loading, error }];
}
