import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

import { FormData, VideoForm } from "./VideoForm";

interface AddVideoModalProps {
  isOpen: boolean;
  onSave: (video: FormData) => void;
  onClose: () => void;
}

const AddVideoModal: React.FC<AddVideoModalProps> = ({
  isOpen,
  onSave,
  onClose,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Add Video</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VideoForm
              url=""
              isLoading={false}
              onSubmit={(formData) => onSave(formData)}
              onCancel={onClose}
            />
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default AddVideoModal;
