import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { CustomDateTimeInput, DatePicker } from "../../../components";
import { formatDurationLabel, roundedDate } from "../../../utils/datetime";
import {
  meetURL,
  phoneNumber as phoneNumberRegex,
  teamsUrl,
  zoomURL,
} from "../../../utils/regex";
import { formatUSPhoneNumber } from "../../../utils/string";
import { Candidate, InterviewType, UserInfoFragment } from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";
import { CallGuideSelect, PositionSelect, TimezoneSelect } from "..";
import CandidateSelect from "../CandidateSelect";
import InterviewerSelect from "./InterviewersSelect";

const durationMinutesOptions = [
  15, 30, 45, 60, 75, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360, 390,
];

export interface FormValues {
  scheduledStartTime: string;
  timezoneName: string;
  durationMinutes: number;
  zoomJoinUrl?: string;
  googleMeetMeetingUrl?: string;
  teamsMeetingUrl?: string;
  phoneNumber?: string;
  candidateId?: string | null;
  candidateFirstName?: string;
  candidateLastName?: string;
  candidateEmail?: string;
  interviewerIds: string[];
  name?: string;
  positionId?: string;
  callGuideId?: string | null;
}

export interface ScheduledInterviewFormProps {
  mode?: "create" | "update" | "join";
  editRestriction?: boolean;
  defaultScheduledStartTime?: string;
  defaultTimezoneName?: string;
  defaultDurationMinutes?: number;
  defaultInterviewType?: InterviewType;
  defaultPhoneNumber?: string;
  defaultGoogleMeetMeetingUrl?: string;
  defaultTeamsMeetingUrl?: string;
  defaultZoomJoinUrl?: string;
  defaultCandidate?: Pick<
    Candidate,
    "id" | "firstName" | "lastName" | "defaultEmail" | "defaultPhoneNumber"
  >;
  defaultInterviewers?: UserInfoFragment[];
  defaultName?: string;
  defaultCallGuideId?: string;
  defaultPositionId?: string;
  isLoading: boolean;
  submitButtonText?: string;
  onSubmit: (values: FormValues) => void;
}

export const ScheduledInterviewForm: React.FC<ScheduledInterviewFormProps> = ({
  mode = "create",
  editRestriction = false,
  submitButtonText = "Schedule",
  defaultScheduledStartTime = roundedDate().toISOString(),
  defaultTimezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone,
  defaultDurationMinutes = 30,
  defaultInterviewType = InterviewType.Phone,
  defaultPhoneNumber,
  defaultGoogleMeetMeetingUrl,
  defaultTeamsMeetingUrl,
  defaultZoomJoinUrl,
  defaultCandidate,
  defaultInterviewers = [],
  defaultCallGuideId,
  defaultPositionId,
  defaultName,
  isLoading,
  onSubmit,
}) => {
  const currentUser = useCurrentUser();
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
  } = useForm<FormValues>({
    defaultValues: {
      scheduledStartTime: defaultScheduledStartTime,
      durationMinutes: defaultDurationMinutes,
      phoneNumber: defaultPhoneNumber
        ? formatUSPhoneNumber(defaultPhoneNumber)
        : "",
      googleMeetMeetingUrl: defaultGoogleMeetMeetingUrl,
      teamsMeetingUrl: defaultTeamsMeetingUrl,
      zoomJoinUrl: defaultZoomJoinUrl,
      candidateId: defaultCandidate?.id,
      interviewerIds: defaultInterviewers.map((i) => i.id),
      callGuideId: defaultCallGuideId,
      positionId: defaultPositionId,
      name: defaultName,
      timezoneName: defaultTimezoneName,
    },
  });
  const [interviewType, setInterviewType] = useState(defaultInterviewType);
  const [positionId, setPositionId] = useState(defaultPositionId);
  const [showCandidateSelect, setShowCandidateSelect] = useState(
    ["create", "join"].includes(mode)
  );
  const [showCandidateFields, setShowCandidateFields] = useState(false);
  const [showSubmit, setShowSubmit] = useState(
    ["update", "join"].includes(mode)
  );
  const [createCandidateValue, setCreateCandidateValue] = useState<string>();

  const onSubmitForm = handleSubmit((formValues): void => {
    const { scheduledStartTime, ...rest } = formValues;
    const scheduledStart = new Date(scheduledStartTime);
    scheduledStart.setMinutes(
      scheduledStart.getMinutes() - scheduledStart.getTimezoneOffset()
    );
    onSubmit({
      scheduledStartTime: scheduledStart.toISOString(),
      ...rest,
    });
  });

  useEffect(() => {
    if (!createCandidateValue) return;
    if (createCandidateValue.includes("@")) {
      setValue("candidateEmail", createCandidateValue);
    } else if (/^[\d +\-()]+$/.test(createCandidateValue)) {
      setValue("phoneNumber", createCandidateValue);
    } else {
      const values = createCandidateValue.split(" ");
      setValue("candidateFirstName", values[0]);
      if (values.length > 1) {
        setValue("candidateLastName", values[1]);
      }
    }
  }, [createCandidateValue]);

  const showInterviewTypesSelect =
    mode !== "join" && currentUser.organization.interviewTypes.length > 1;
  const isPhoneInterview = interviewType === InterviewType.Phone;

  return (
    <form onSubmit={onSubmitForm}>
      <VStack spacing="8" align="stretch">
        <Box hidden={mode === "join"}>
          <VStack spacing="8" align="stretch">
            <Flex wrap="wrap" columnGap="4" rowGap="6">
              {editRestriction ? (
                ""
              ) : (
                <FormControl id="scheduledStartTime" flex="2">
                  <FormLabel>Interview Date</FormLabel>
                  <Controller
                    control={control}
                    name="scheduledStartTime"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <DatePicker
                        minDate={new Date()}
                        selected={new Date(value)}
                        required
                        showTimeSelect
                        timeIntervals={15}
                        dateFormat="MM/dd/yyyy   h:mm aa"
                        onChange={onChange}
                        onBlur={onBlur}
                        customInput={<CustomDateTimeInput minWidth="215px" />}
                        portalId="datepicker-portal"
                      />
                    )}
                  />
                </FormControl>
              )}
              {editRestriction ? (
                ""
              ) : (
                <FormControl id="durationMinutes" minW="fit-content" flex="1">
                  <FormLabel>Duration</FormLabel>
                  <Select
                    data-testid="interview-duration-select"
                    {...register("durationMinutes", { valueAsNumber: true })}
                    isRequired
                    placeholder="Duration"
                  >
                    {durationMinutesOptions.map((value) => (
                      <option key={value} value={value}>
                        {formatDurationLabel(value)}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Flex>
            {editRestriction ? (
              ""
            ) : (
              <FormControl id="timezoneName" isInvalid={!!errors.timezoneName}>
                <FormLabel>Timezone</FormLabel>
                <Controller
                  control={control}
                  name="timezoneName"
                  rules={{
                    required: "required",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TimezoneSelect
                      timezone={value}
                      onChange={onChange}
                      data-testid="interview-timezone-select"
                    />
                  )}
                />
              </FormControl>
            )}
          </VStack>
        </Box>
        {!editRestriction && showCandidateSelect && (
          <FormControl
            id="candidateId"
            isRequired
            isInvalid={!!errors.candidateId}
          >
            <FormLabel>Candidate</FormLabel>
            <Controller
              name="candidateId"
              control={control}
              rules={{
                required: "Please select a candidate",
              }}
              render={({ field: { onChange, onBlur, ref } }) => (
                <CandidateSelect
                  autoFocus
                  ref={ref}
                  name="candidateId"
                  onSelect={(candidate) => {
                    onChange(candidate?.id);
                    if (isPhoneInterview && !getValues("phoneNumber")) {
                      setValue(
                        "phoneNumber",
                        candidate?.defaultPhoneNumber
                          ? formatUSPhoneNumber(candidate.defaultPhoneNumber)
                          : undefined
                      );
                    }
                  }}
                  onCreate={(value) => {
                    setCreateCandidateValue(value);
                    setShowCandidateFields(true);
                    setShowCandidateSelect(false);
                    setValue("candidateId", undefined);
                    unregister("candidateId");
                  }}
                  onBlur={onBlur}
                />
              )}
            />
            {errors.candidateId && (
              <FormErrorMessage>{errors.candidateId.message}</FormErrorMessage>
            )}
          </FormControl>
        )}
        {showCandidateFields && (
          <VStack
            align="stretch"
            spacing="6"
            p="5"
            backgroundColor="gray.50"
            borderRadius="md"
          >
            <HStack justify="space-between">
              <Heading size="xs" as="h2">
                Create a New Candidate
              </Heading>
              <Button
                variant="link"
                size="sm"
                onClick={() => {
                  setShowCandidateFields(false);
                  setShowCandidateSelect(true);
                  setValue("candidateFirstName", undefined);
                  setValue("candidateLastName", undefined);
                  setValue("candidateEmail", undefined);
                  setValue("phoneNumber", undefined);
                }}
              >
                Cancel
              </Button>
            </HStack>
            <HStack>
              <FormControl id="candidateFirstName" isRequired>
                <FormLabel>Candidate First Name</FormLabel>
                <Input
                  {...register("candidateFirstName")}
                  data-testid="candidateFirstName"
                />
                {errors.candidateFirstName && (
                  <FormErrorMessage>
                    {errors.candidateFirstName.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl id="candidateLastName" isRequired>
                <FormLabel>Candidate Last Name</FormLabel>
                <Input
                  {...register("candidateLastName")}
                  data-testid="candidateLastName"
                />
                {errors.candidateLastName && (
                  <FormErrorMessage>
                    {errors.candidateLastName.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </HStack>
            <HStack>
              <FormControl
                id="candidateEmail"
                isRequired={[
                  InterviewType.GoogleMeet,
                  InterviewType.Zoom,
                  InterviewType.Teams,
                ].includes(interviewType)}
              >
                <FormLabel>Candidate Email</FormLabel>
                <Input
                  type="email"
                  {...register("candidateEmail")}
                  data-testid="candidateEmail"
                />
                {errors.candidateEmail && (
                  <FormErrorMessage>
                    {errors.candidateEmail.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              {/* candidate phone number is collected in a different field for phone interviews */}
              {!isPhoneInterview && (
                <FormControl id="phoneNumber">
                  <FormLabel>Candidate Phone</FormLabel>
                  <Input
                    {...register("phoneNumber")}
                    data-testid="candidatePhoneNumber"
                  />
                  {errors.phoneNumber && (
                    <FormErrorMessage>
                      {errors.phoneNumber.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              )}
            </HStack>
          </VStack>
        )}
        <Flex columnGap="4">
          {!editRestriction && showInterviewTypesSelect && (
            <FormControl id="type" isRequired flex="0 0 105px">
              <FormLabel>Type</FormLabel>
              <Select
                data-testid="type-select"
                name="type"
                textTransform="capitalize"
                value={interviewType}
                onChange={(e) => {
                  // workaround for react-hook-form issues when un-registering fields on unmount
                  // manually unregister before setting a new interviewType
                  const selectedType = e.target.value as InterviewType;
                  if (selectedType !== InterviewType.Phone) {
                    unregister("phoneNumber");
                  }
                  if (selectedType !== InterviewType.Zoom) {
                    unregister("zoomJoinUrl");
                  }
                  if (selectedType !== InterviewType.GoogleMeet) {
                    unregister("googleMeetMeetingUrl");
                  }
                  if (selectedType !== InterviewType.Teams) {
                    unregister("teamsMeetingUrl");
                  }
                  setInterviewType(selectedType);
                }}
              >
                {currentUser.organization.interviewTypes.map((type) => (
                  <option key={type} value={type}>
                    {type.replace("_", " ").toLowerCase()}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
          {editRestriction ? (
            ""
          ) : isPhoneInterview ? (
            <FormControl
              id="phoneNumber"
              isRequired
              isInvalid={!!errors.phoneNumber}
            >
              <FormLabel>Phone Number</FormLabel>
              <Input
                autoComplete="on"
                type="tel"
                data-testid="scheduled-interview-phoneNumber"
                {...register("phoneNumber", {
                  pattern: {
                    value: phoneNumberRegex,
                    message: "Please enter a valid phone number",
                  },
                  required: "Phone number is required",
                })}
                placeholder="(xxx) xxx-xxxx"
              />
              {errors.phoneNumber && (
                <FormErrorMessage>
                  {errors.phoneNumber.message}
                </FormErrorMessage>
              )}
            </FormControl>
          ) : interviewType === InterviewType.Zoom ? (
            <FormControl
              id="zoomJoinUrl"
              isRequired
              isInvalid={!!errors.zoomJoinUrl}
            >
              <FormLabel>Zoom Meeting URL</FormLabel>
              <Input
                {...register("zoomJoinUrl", {
                  pattern: {
                    value: zoomURL,
                    message: "Please enter a valid Zoom URL",
                  },
                  required: "Zoom URL is required",
                })}
                data-testid="zoomJoinUrl"
                placeholder="https://zoom.us/j/1234567890?pwd=dm5qwerty"
              />
              {errors.zoomJoinUrl && (
                <FormErrorMessage>
                  {errors.zoomJoinUrl.message}
                </FormErrorMessage>
              )}
            </FormControl>
          ) : interviewType === InterviewType.GoogleMeet ? (
            <FormControl
              id="googleMeetMeetingUrl"
              isRequired
              isInvalid={!!errors.googleMeetMeetingUrl}
            >
              <FormLabel>Meet URL</FormLabel>
              <Input
                {...register("googleMeetMeetingUrl", {
                  pattern: {
                    value: meetURL,
                    message: "Please enter a valid Google Meet URL",
                  },
                  required: "Meet URL is required",
                })}
                data-testid="googleMeetMeetingUrl"
                placeholder="https://meet.google.com/abc-defg-hij"
              />
              {errors.googleMeetMeetingUrl && (
                <FormErrorMessage>
                  {errors.googleMeetMeetingUrl?.message}
                </FormErrorMessage>
              )}
            </FormControl>
          ) : interviewType === InterviewType.Teams ? (
            <FormControl
              id="teamsMeetingUrl"
              isRequired
              isInvalid={!!errors.teamsMeetingUrl}
            >
              <FormLabel>Microsoft Teams URL</FormLabel>
              <Input
                {...register("teamsMeetingUrl", {
                  pattern: {
                    value: teamsUrl,
                    message: "Please enter a valid Microsoft Teams URL",
                  },
                  required: "Microsoft Teams URL is required",
                })}
                data-testid="teamsMeetingUrl"
                placeholder="https://teams.microsoft.com/l/meetup-join/19:d00b61fe60f04a6697606d90dacac90b@thread.tacv2/1669923176555?context=%7B%22Tid%22:%228292a654-7ff5-4cf6-8ab7-1537784c3eaf%22,%22Oid%22:%2208c7628a-42bb-4822-8c89-ab9fa6705eda%22%7D"
              />
              {errors.teamsMeetingUrl && (
                <FormErrorMessage>
                  {errors.teamsMeetingUrl?.message}
                </FormErrorMessage>
              )}
            </FormControl>
          ) : null}
        </Flex>
        {editRestriction ? (
          ""
        ) : (
          <FormControl
            id="interviewerIds"
            isRequired
            isInvalid={!!errors.interviewerIds}
          >
            <FormLabel>Interviewers</FormLabel>
            <Controller
              name="interviewerIds"
              control={control}
              rules={{
                validate: (value) =>
                  (value && value.length > 0) ||
                  "Please select at least one interviewer",
              }}
              render={({ field: { onChange, onBlur, ref } }) => (
                <InterviewerSelect
                  ref={ref}
                  defaultInterviewers={defaultInterviewers}
                  onChange={(interviewers) => {
                    onChange(interviewers.map((i) => i.id));
                    if (!showSubmit) setShowSubmit(true);
                  }}
                  onBlur={onBlur}
                />
              )}
            />
            {errors.interviewerIds && (
              <FormErrorMessage>
                {
                  (errors.interviewerIds as unknown as { message: string })
                    .message
                }
              </FormErrorMessage>
            )}
          </FormControl>
        )}
        {showSubmit && (
          <>
            <VStack
              backgroundColor="gray.50"
              p="5"
              spacing={8}
              borderRadius="md"
            >
              <FormControl id="callGuideId">
                <FormLabel>Interview Guide (optional)</FormLabel>
                <Controller
                  name="callGuideId"
                  control={control}
                  render={({ field }) => (
                    <CallGuideSelect
                      {...field}
                      placeholder="Type to filter guides"
                      positionId={positionId}
                      defaultCallGuideId={defaultCallGuideId}
                      autoFocus={mode === "create"}
                      onSelect={(callGuide) => {
                        const values = getValues(["positionId", "name"]);
                        setValue("callGuideId", callGuide?.id ?? "");
                        if (
                          callGuide?.position?.id &&
                          !values[0] &&
                          callGuide.id !== defaultCallGuideId
                        ) {
                          setValue("positionId", callGuide.position.id);
                          setPositionId(callGuide.position.id);
                        }
                        if (callGuide?.name && !values[1]) {
                          setValue("name", callGuide?.name);
                        }
                      }}
                    />
                  )}
                />
                {errors.callGuideId && (
                  <FormErrorMessage>
                    {errors.callGuideId.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl id="positionId">
                <FormLabel>Position (optional)</FormLabel>
                <Controller
                  name="positionId"
                  control={control}
                  render={({ field }) => (
                    <PositionSelect
                      {...field}
                      positionId={positionId}
                      onSelect={(position) => {
                        setValue("positionId", position?.id ?? "");
                        setPositionId(position?.id ?? "");
                      }}
                    />
                  )}
                />
                {errors.positionId && (
                  <FormErrorMessage>
                    {errors.positionId.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              {editRestriction ? (
                ""
              ) : (
                <FormControl id="name">
                  <FormLabel>Interview Name (optional)</FormLabel>
                  <Input data-testid="interview-name" {...register("name")} />
                  {errors.name && (
                    <FormErrorMessage>{errors.name.message}</FormErrorMessage>
                  )}
                </FormControl>
              )}
            </VStack>
            <Button
              data-testid="scheduled-interview-submit-button"
              type="submit"
              mt={8}
              isLoading={isLoading}
            >
              {submitButtonText}
            </Button>
          </>
        )}
      </VStack>
    </form>
  );
};

export default ScheduledInterviewForm;
