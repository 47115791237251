import { Flex, Icon, IconProps, Link, Text } from "@chakra-ui/react";
import React from "react";
import { MdPeople } from "react-icons/md";

import {
  CallVisibility,
  useVisibilityLevelDescriptionsQuery,
} from "../../graphql";
import useLink from "../../hooks/useLink";
import { OrganizationIcon } from "./VisibilitySelect";

const SharedWithStatic: React.FC<{
  visibility: CallVisibility;
  trainingProgramCount: number;
  numNotShown: number;
  positionId?: string | null;
}> = ({ visibility, trainingProgramCount, numNotShown, positionId }) => {
  const { data } = useVisibilityLevelDescriptionsQuery();
  const text =
    data?.visibilityLevelDescriptions.find(
      ({ visibilityLevel }) => visibilityLevel === visibility
    )?.userRoleDescription ?? getDefaultVisibilityText(visibility);

  const iconProps = { boxSize: 6, color: "gray.700", mr: 3 };
  const containerProps = { alignItems: "center", py: 4, px: 3, fontSize: "sm" };

  const renderIcon = (): JSX.Element =>
    visibility === CallVisibility.Organization ? (
      <OrganizationIcon {...iconProps} />
    ) : (
      <Icon as={MdPeople} {...iconProps} />
    );

  const hiringTeamLink = useLink({
    type: "hiringTeam",
    positionId: positionId ?? "",
  });

  return (
    <>
      {positionId && numNotShown > 0 && (
        <Flex {...containerProps}>
          <Icon as={MdPeople} {...iconProps} />
          <Text>
            {numNotShown} other user{numNotShown !== 1 ? "s" : ""} on the{" "}
            <Link
              target="_blank"
              href={hiringTeamLink}
              _focus={{ boxShadow: "none" }}
            >
              hiring team
            </Link>
          </Text>
        </Flex>
      )}

      {text && (
        <Flex {...containerProps}>
          {renderIcon()}
          <Text>{text}</Text>
        </Flex>
      )}

      {trainingProgramCount > 0 && (
        <Flex {...containerProps}>
          <TraineesIcon {...iconProps} />
          <Text>
            All trainees in {trainingProgramCount} training program
            {trainingProgramCount !== 1 ? "s" : ""}
          </Text>
        </Flex>
      )}
    </>
  );
};

export default SharedWithStatic;

const getDefaultVisibilityText = (visibility: CallVisibility): string => {
  switch (visibility) {
    case CallVisibility.Organization:
      return "Any user at the company";
    case CallVisibility.Public:
    case CallVisibility.Restricted:
      return "All recruiters and site admins";
    case CallVisibility.Private:
      return "All site admins";
    default:
      return "";
  }
};

function TraineesIcon(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 20 20" fill="currentcolor" {...props}>
      <path d="M5.9375 10C7.4908 10 8.75 8.7408 8.75 7.1875C8.75 5.6342 7.4908 4.375 5.9375 4.375C4.3842 4.375 3.125 5.6342 3.125 7.1875C3.125 8.7408 4.3842 10 5.9375 10Z" />
      <path d="M9.14062 11.5625C8.04063 11.0039 6.82656 10.7812 5.9375 10.7812C4.19609 10.7812 0.625 11.8492 0.625 13.9844V15.625H6.48438V14.9973C6.48438 14.2551 6.79688 13.5109 7.34375 12.8906C7.78008 12.3953 8.39102 11.9355 9.14062 11.5625Z" />
      <path d="M13.2812 11.25C11.2473 11.25 7.1875 12.5063 7.1875 15V16.875H19.375V15C19.375 12.5063 15.3152 11.25 13.2812 11.25Z" />
      <path d="M13.2812 10C15.1797 10 16.7188 8.46098 16.7188 6.5625C16.7188 4.66402 15.1797 3.125 13.2812 3.125C11.3828 3.125 9.84375 4.66402 9.84375 6.5625C9.84375 8.46098 11.3828 10 13.2812 10Z" />
      <rect x="10.75" y="2" width="5" height="1" />
      <rect x="9.75" y="1" width="7" height="1" />
      <rect x="16.75" y="1" width="0.5" height="8" />
      <rect x="3.64661" y="3.39648" width="4.48276" height="0.896552" />
      <rect x="2.75" y="2.5" width="6.27586" height="0.896552" />
      <rect x="8.80176" y="2.5" width="0.448276" height="7.17241" />
    </Icon>
  );
}
