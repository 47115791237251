import { Box, Button, Flex, Select } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { HiOutlineChevronDown } from "react-icons/hi";

import { Alert, LoadingIndicator } from "../../../../components";
import useIntersectionObserver from "../../../../hooks/useIntersectionObserver";
import { useIsSmallScreen } from "../../../../hooks/useIsSmallScreen";
import { formatRelativeDate } from "../../../../utils/datetime";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { InterviewMenuOptions } from "../../../components/InterviewsList";
import {
  useCurrentUserSharedWithMeCountsQuery,
  useCurrentUserSharedWithMeQuery,
  UserRoleName,
} from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { ClipListItem } from "../../clip/ClipListItem";
import { PlaylistListItem } from "../../playlists/PlaylistListItem";
import CompletedInterviewsListItem from "../Interviews/CompletedInterviewsListItem";
import { SharedWithMeEmptyState } from "./SharedWithMeEmptyState";
import TabsLayout, { TabContent } from "./TabsLayout";

enum Tabs {
  INTERVIEWS = "interviews",
  PLAYLISTS = "playlists",
  CLIPS = "clips",
}

type SortOrder = "asc" | "desc";

const SharedWithMe: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(Tabs.INTERVIEWS);
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");
  const sentinelRef = useRef<HTMLDivElement>(null);
  const sentinelInView = useIntersectionObserver(sentinelRef, {
    rootMargin: "20%",
  });
  const isSmallScreen = useIsSmallScreen();
  const sendGAEvent = useSendGAEvent();
  const onClickLink = (itemType: string): void =>
    sendGAEvent(
      "Link to",
      "home_page",
      itemType,
      `Shared with me${isSmallScreen ? " (mobile)" : ""}`
    );

  const orderBy = sortOrder === "asc" ? "created_at asc" : "";
  const pageSize = 10;
  const pagination = {
    limit: pageSize,
    page: 0,
    orderBy,
  };

  const { data, loading, error, fetchMore } = useCurrentUserSharedWithMeQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      calls: currentTab === Tabs.INTERVIEWS,
      clips: currentTab === Tabs.CLIPS,
      playlists: currentTab === Tabs.PLAYLISTS,
      pagination,
    },
  });

  const {
    data: countsData,
    loading: countsLoading,
    error: countsError,
  } = useCurrentUserSharedWithMeCountsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      pagination,
    },
  });
  const isLoading = loading || countsLoading;
  const isError = error || countsError;

  const { callsSharedWithMe, clipsSharedWithMe, playlistsSharedWithMe } =
    data?.currentUser ?? {};

  const calls = callsSharedWithMe?.results ?? [];
  const numCalls =
    countsData?.currentUser?.callsSharedWithMe?.pageInfo?.totalRows ?? 0;

  const clips = clipsSharedWithMe?.results ?? [];
  const numClips =
    countsData?.currentUser?.clipsSharedWithMe?.pageInfo?.totalRows ?? 0;

  const playlists = playlistsSharedWithMe?.results ?? [];
  const numPlaylists =
    countsData?.currentUser?.playlistsSharedWithMe?.pageInfo?.totalRows ?? 0;

  const currentUser = useCurrentUser();
  const isBasicUser = currentUser.userRole?.name === UserRoleName.Basic;

  useEffect(() => {
    if (numCalls === 0) {
      if (numClips > 0) {
        setCurrentTab(Tabs.CLIPS);
      } else if (numPlaylists > 0) {
        setCurrentTab(Tabs.PLAYLISTS);
      }
    }
  }, [numCalls]);

  const loadMore = (): void => {
    const count: number =
      currentTab === Tabs.INTERVIEWS
        ? calls.length
        : currentTab === Tabs.CLIPS
        ? clips.length
        : playlists.length;

    fetchMore({
      variables: {
        pagination: {
          ...pagination,
          limit: count + pageSize,
        },
      },
    });
  };
  let hasMoreItemsToLoad = false;
  if (currentTab === Tabs.INTERVIEWS) {
    hasMoreItemsToLoad = calls.length < numCalls;
  } else if (currentTab === Tabs.CLIPS) {
    hasMoreItemsToLoad = clips.length < numClips;
  } else {
    hasMoreItemsToLoad = playlists.length < numPlaylists;
  }

  useEffect(() => {
    if (sentinelInView && hasMoreItemsToLoad) {
      loadMore();
    }
  }, [sentinelInView]);

  if (isError) {
    return (
      <Alert
        status="error"
        description={error?.message || countsError?.message}
      />
    );
  }

  return (
    <>
      <TabsLayout
        currentTab={currentTab}
        onTabChange={(newTab) => {
          setCurrentTab(newTab);
        }}
        tabRightChildren={
          <Select
            width={isSmallScreen ? "100%" : "196px"}
            defaultValue={sortOrder}
            icon={<HiOutlineChevronDown />}
            size="sm"
            height="10"
            fontWeight="400"
            color="gray.900"
            onChange={(e: React.SyntheticEvent<HTMLSelectElement, Event>) =>
              setSortOrder(e.currentTarget.value as SortOrder)
            }
          >
            <option value="desc">Newest first</option>
            <option value="asc">Oldest first</option>
          </Select>
        }
      >
        <TabContent
          id={Tabs.INTERVIEWS}
          displayName={`Interviews${numCalls > 0 ? ` (${numCalls})` : ""}`}
        >
          <Box order={1} data-testid="upcoming-interviews" mb="5">
            {calls.length === 0 && !isLoading && (
              <SharedWithMeEmptyState heading="No interviews have been shared with you yet" />
            )}
            {calls.map((sharedCall, i: number) => (
              <CompletedInterviewsListItem
                call={sharedCall.call}
                key={sharedCall.id}
                listPosition={i + 1}
                menuOptions={isBasicUser ? [] : [InterviewMenuOptions.SHARE]}
                shouldHideInterviewType
                displayDate={sharedCall.createdAt}
                onClickCandidate={() => onClickLink("Candidate")}
                onClickPosition={() => onClickLink("Position")}
                onClickInterview={() => onClickLink("Interview")}
              />
            ))}
          </Box>
        </TabContent>
        <TabContent
          id={Tabs.CLIPS}
          displayName={`Clips${numClips > 0 ? ` (${numClips})` : ""}`}
        >
          <Box order={1} data-testid="upcoming-interviews" mb="5">
            {clips.length === 0 && !isLoading && (
              <SharedWithMeEmptyState heading="No clips have been shared with you yet" />
            )}
            {clips.map((sharedClip) => (
              <ClipListItem
                key={sharedClip.clip.id}
                clip={sharedClip.clip}
                displayDate={sharedClip.createdAt}
                size="sm"
                onClipLinkClick={() => onClickLink("Clip")}
                readonly
              />
            ))}
          </Box>
        </TabContent>
        <TabContent
          id={Tabs.PLAYLISTS}
          displayName={`Playlists${
            numPlaylists > 0 ? ` (${numPlaylists})` : ""
          }`}
        >
          <Box order={1} data-testid="upcoming-playlists" mb="5">
            {playlists.length === 0 && !isLoading && (
              <SharedWithMeEmptyState heading="No playlists have been shared with you yet" />
            )}
            {playlists.map((share, i: number) => (
              <PlaylistListItem
                share={share}
                key={share.id}
                displayDate={formatRelativeDate(share.createdAt)}
                size="sm"
                onPlaylistLinkClick={() => onClickLink("Playlist")}
              />
            ))}
          </Box>
        </TabContent>
      </TabsLayout>
      {!isSmallScreen && !isLoading && !error && hasMoreItemsToLoad && (
        <Button
          variant="chalk"
          fontSize="sm"
          fontWeight={500}
          height="32px"
          onClick={loadMore}
          mb="4"
        >
          Load more
        </Button>
      )}
      {isSmallScreen && <Box width="100%" mb="2" ref={sentinelRef} />}
      {isSmallScreen && !loading && !error && !hasMoreItemsToLoad && (
        <Box pt="2" pb="4" color="gray.500">
          No more items to show
        </Box>
      )}
      {isLoading && (
        <Flex width="100%" height="100%">
          <LoadingIndicator py={6} />
        </Flex>
      )}
    </>
  );
};

export default SharedWithMe;
