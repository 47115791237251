import {
  Box,
  Flex,
  FlexProps,
  FormControl,
  FormLabel,
  InputProps,
} from "@chakra-ui/react";
import React from "react";
import Select from "react-select";

import { diffInDays, MS_PER_DAY } from "../../utils/datetime";
import { useSendGAEvent } from "../../utils/googleAnalytics";
import { CustomDateInput, DatePicker } from "..";

export enum Interval {
  Day = "DAY",
  Week = "WEEK",
  Month = "MONTH",
  Year = "YEAR",
}

export type SelectDateRangeState = {
  type: "last_6m" | "last_90" | "last_30" | "last_7" | "custom";
  start: Date;
  end: Date;
  interval: Interval;
  selected: string;
};

const getInterval = (start: Date, end: Date): Interval => {
  const diff = diffInDays(start, end);
  return diff > 7 ? Interval.Week : Interval.Day;
};

const today = new Date();

interface SelectDateRangeProps {
  disabled?: boolean;
  state: SelectDateRangeState;
  onSelect: (state: SelectDateRangeState) => void;
  maxDate?: Date;
  includeLabel?: boolean;
  selectStyles?: any;
  selectTheme?: any;
  rangeStyleProps?: FlexProps;
  dateInputStyleProps?: InputProps;
}

const SelectDateRange: React.FC<SelectDateRangeProps> = ({
  disabled,
  state,
  onSelect,
  maxDate = today,
  includeLabel = true,
  selectStyles = {},
  selectTheme = {},
  rangeStyleProps = {},
  dateInputStyleProps = {},
}) => {
  const { start, end, selected } = state;
  const sendGAEvent = useSendGAEvent();

  const handleStartDateChange = (startDate: Date): void => {
    if (startDate) {
      onSelect({
        type: "custom",
        start: startDate,
        end,
        interval: getInterval(start, end),
        selected: "5",
      });
      sendGAEvent(
        "date_range_selected",
        "analytics",
        `${start.toISOString()}-${end.toISOString()}`
      );
    }
  };

  const handleEndDateChange = (endDate: Date): void => {
    if (endDate) {
      onSelect({
        type: "custom",
        start,
        end: endDate,
        interval: getInterval(start, endDate),
        selected: "5",
      });
      sendGAEvent(
        "date_range_selected",
        "analytics",
        `${start.toISOString()}-${end.toISOString()}`
      );
    }
  };

  const options = [
    { value: "1", label: "Last 6 months" },
    { value: "2", label: "Last 90 days" },
    { value: "3", label: "Last 30 days" },
    { value: "4", label: "Last 7 days" },
    { value: "5", label: "Custom" },
  ];

  const { container: containerStyles = {}, ...selectStylesRest } = selectStyles;

  return (
    <FormControl
      id="date-range"
      data-testid="date-range"
      display="flex"
      flex="1"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
    >
      {includeLabel && (
        <FormLabel
          mb="0"
          minW="100px"
          color="secondary"
          fontSize="sm"
          fontWeight="bold"
        >
          TIME PERIOD
        </FormLabel>
      )}
      <Select
        isDisabled={disabled}
        isSearchable={false}
        value={options.find((v) => v.value === selected)}
        onChange={(option) => {
          if (option) {
            switch (option.value) {
              case "1":
                onSelect(dateRange6MonthsAgo());
                sendGAEvent("date_range_selected", "analytics", "6 months ago");
                break;
              case "2":
                onSelect(dateRange90DaysAgo());
                sendGAEvent("date_range_selected", "analytics", "90 days ago");
                break;
              case "3":
                onSelect(dateRange30DaysAgo());
                sendGAEvent("date_range_selected", "analytics", "30 days ago");
                break;
              case "4":
                onSelect(dateRange7DaysAgo());
                sendGAEvent("date_range_selected", "analytics", "7 days ago");
                break;
              case "5":
                onSelect(dateRangeCustom(start, end));
                break;
            }
          }
        }}
        options={options}
        styles={{
          container: (provided: Record<string, any>) => ({
            ...provided,
            flex: 1,
            minWidth: 120,
            ...containerStyles,
          }),
          ...selectStylesRest,
        }}
        theme={selectTheme}
      />
      {selected === "5" && (
        <Flex wrap="wrap" ml={4} alignItems="center" {...rangeStyleProps}>
          <DatePicker
            selected={start}
            maxDate={end}
            onChange={handleStartDateChange}
            disabled={disabled}
            customInput={<CustomDateInput {...dateInputStyleProps} />}
          />
          <Box mx="2" textAlign="center" fontSize="sm">
            to
          </Box>
          <DatePicker
            selected={end}
            minDate={start}
            maxDate={maxDate}
            onChange={handleEndDateChange}
            disabled={disabled}
            customInput={<CustomDateInput {...dateInputStyleProps} />}
          />
        </Flex>
      )}
    </FormControl>
  );
};

export const dateRange6MonthsAgo = (): SelectDateRangeState => ({
  type: "last_6m",
  start: monthsAgo(6),
  end: today,
  interval: Interval.Week,
  selected: "1",
});

export const dateRange90DaysAgo = (): SelectDateRangeState => ({
  type: "last_90",
  start: daysAgo(90),
  end: today,
  interval: Interval.Week,
  selected: "2",
});

export const dateRange30DaysAgo = (): SelectDateRangeState => ({
  type: "last_30",
  start: daysAgo(30),
  end: today,
  interval: Interval.Week,
  selected: "3",
});

export const dateRange7DaysAgo = (): SelectDateRangeState => ({
  type: "last_7",
  start: daysAgo(7),
  end: today,
  interval: Interval.Day,
  selected: "4",
});

export const dateRangeCustom = (
  start: Date,
  end: Date
): SelectDateRangeState => ({
  type: "custom",
  start,
  end,
  interval: getInterval(start, end),
  selected: "5",
});

const daysAgo = (days: number): Date => {
  return new Date(Date.now() - MS_PER_DAY * days);
};

export const daysBefore = (date: Date, days: number): Date => {
  return new Date(date.valueOf() - MS_PER_DAY * days);
};

const monthsAgo = (months: number): Date => {
  const d = new Date();
  d.setMonth(d.getMonth() - (months - 1), 1);
  return d;
};

export default SelectDateRange;
