import invariant from "invariant";
import { useCallback } from "react";

import { useToast } from "../../components";
import useExtensionFeatureEnabled from "../../hooks/useBrowserExtensionFeatureEnabled";
import { openFeedbackForm, openScorecard } from "../../utils/extension";
import { CopyNotesButtonsProps } from "../components/CallNotes/beta/CopyNotesButtons";
import { Ats, Call } from "../graphql";

type Autofillable = Pick<
  Call,
  "greenhouseScorecardLinkWithId" | "leverInterviewLinkWithId"
>;

export const getAts = (recording: Autofillable): Ats | undefined =>
  recording.greenhouseScorecardLinkWithId
    ? Ats.Greenhouse
    : recording.leverInterviewLinkWithId
    ? Ats.Lever
    : undefined;

const getAtsLink = ({
  greenhouseScorecardLinkWithId,
  leverInterviewLinkWithId,
}: Autofillable): string | null =>
  greenhouseScorecardLinkWithId ?? leverInterviewLinkWithId ?? null;

const useAutofill = (
  recording: Autofillable
): Pick<CopyNotesButtonsProps, "onAutofill" | "autofillEnabled"> => {
  const toast = useToast();

  const pasteScorecardEnabled = useExtensionFeatureEnabled("paste_scorecard", {
    checkFeatureFlag: false,
  });

  const ats = getAts(recording);
  const url = getAtsLink(recording);

  const autofillEnabled = pasteScorecardEnabled && !!url;

  const onAutofill = useCallback(() => {
    invariant(url, "useAutofill missing ATS link");

    const onError = (): void => {
      toast({
        title: "There was a problem",
        description: "Please try again later",
        status: "error",
      });
    };

    if (typeof zoomSdk !== "undefined") {
      zoomSdk.openUrl({ url });
    } else if (ats === Ats.Greenhouse) {
      openScorecard(url, true).catch(onError);
    } else if (ats === Ats.Lever) {
      openFeedbackForm(url, true).catch(onError);
    }
  }, [ats, url]);

  return { onAutofill, autofillEnabled };
};

export default useAutofill;
