import {
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
} from "@chakra-ui/react";
import React from "react";
import { FiSearch } from "react-icons/fi";

type UncontrolledSearchInputProps = {
  onSearch: (query: string) => void;
  query?: string;
} & InputProps;

const UncontrolledSearchInput: React.FC<UncontrolledSearchInputProps> = ({
  onSearch,
  query,
  placeholder = "Search...",
  ...rest
}) => {
  let timeout: any = 0;

  /**
   * Submit on press enter or wait for user to stop typing, keeps from getting
   * in the way of the user trying to add more to the field
   */
  const handleKeyUp = (e: any): void => {
    const value = e.target?.value ?? "";
    if (timeout) clearTimeout(timeout);
    if (e.key === "Enter") {
      onSearch(value);
      return;
    }
    timeout = setTimeout(() => {
      onSearch(value);
      clearTimeout(timeout);
    }, 600);
  };

  /**
   * Handle when the search box is cleared
   */
  const handleChange = (e: any): void => {
    const value = e.target?.value ?? "";
    if (value) return;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      onSearch("");
      clearTimeout(timeout);
    }, 600);
  };

  return (
    <InputGroup h="8">
      <InputLeftElement h="8" p="0" color="placeholder">
        <FiSearch />
      </InputLeftElement>
      <Input
        size="sm"
        maxWidth="250px"
        onKeyUp={handleKeyUp}
        onChange={handleChange}
        type="search"
        value={query}
        placeholder={placeholder}
        {...rest}
      />
    </InputGroup>
  );
};

export default UncontrolledSearchInput;
