import { ApolloError, MutationTuple } from "@apollo/client";

import {
  CurrentUserDocument,
  CurrentUserFragment,
  CurrentUserQuery,
  UpdateCurrentUserSubscriptionsSettingsMutation,
  UpdateCurrentUserSubscriptionsSettingsMutationVariables,
  useUpdateCurrentUserSubscriptionsSettingsMutation,
} from "..";

export default function useUpdateCurrentUserSubscriptionsSettings({
  onCompleted,
  onError,
}: {
  onCompleted?: (currentUser: CurrentUserFragment) => void;
  onError?: (error: ApolloError) => void;
} = {}): [
  MutationTuple<
    UpdateCurrentUserSubscriptionsSettingsMutation,
    UpdateCurrentUserSubscriptionsSettingsMutationVariables
  >[0],
  { loading: boolean; error: ApolloError | undefined }
] {
  const [updateCurrentUserSubscriptionsSettings, { loading, error }] =
    useUpdateCurrentUserSubscriptionsSettingsMutation({
      update(cache, { data: currentUserData }) {
        const currentUser =
          currentUserData?.updateCurrentUserSubscriptionsSettings?.currentUser;
        if (currentUser) {
          cache.writeQuery<CurrentUserQuery>({
            query: CurrentUserDocument,
            data: { currentUser },
          });
        }
      },
      onCompleted: ({ updateCurrentUserSubscriptionsSettings }) => {
        const currentUser = updateCurrentUserSubscriptionsSettings?.currentUser;
        if (currentUser && onCompleted) {
          onCompleted(currentUser);
        }
      },
      onError,
    });
  return [updateCurrentUserSubscriptionsSettings, { loading, error }];
}
