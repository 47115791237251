import LogRocket from "logrocket";
import React, { useEffect } from "react";

import { LoadingIndicator } from "../../../components";
import {
  useTrainingProgramManagementListItemsQuery,
  useTrainingProgramTraineeStatusQuery,
} from "../../graphql";
import DefaultLayout from "../../layouts/DefaultLayout";
import AllTraineesPageView from "./AllTraineesPageView";
import ErrorPage from "./ErrorPage";
import ManageTrainingLayout from "./ManageTrainingLayout";
import { useTrainingApi } from "./useTrainingApi";
import { useOnVisibilityChange } from "./utils";

/**
 * Page for managing all trainees across all programs.
 */
const AllTraineesPage: React.FC = () => {
  useEffect(() => LogRocket.track("training-manage-all-trainees-page"), []);

  const {
    data,
    error,
    refetch: refetchTraineesStatus,
  } = useTrainingProgramTraineeStatusQuery({
    fetchPolicy: "cache-and-network",
  });

  const {
    data: trainingProgramsData,
    loading: trainingProgramsLoading,
    error: trainingProgramsError,
    refetch: refetchTrainingPrograms,
  } = useTrainingProgramManagementListItemsQuery();

  const trainingApi = useTrainingApi(refetchTrainingPrograms);

  useOnVisibilityChange(refetchTraineesStatus);

  if (error || trainingProgramsError) {
    return (
      <ErrorPage
        title="Error loading training program"
        error={error ?? trainingProgramsError}
      />
    );
  }

  if (trainingProgramsLoading || !trainingProgramsData) {
    return (
      <DefaultLayout>
        <LoadingIndicator />
      </DefaultLayout>
    );
  }

  const trainees = data?.trainingProgramTrainees;
  const { traineeCount, trainingProgramManagementListItems: trainingPrograms } =
    trainingProgramsData;

  if (!trainees) {
    return (
      <ManageTrainingLayout
        trainingApi={trainingApi}
        trainingPrograms={trainingPrograms}
        allTrainees
        traineeCount={traineeCount}
      >
        <LoadingIndicator />
      </ManageTrainingLayout>
    );
  }

  return (
    <AllTraineesPageView
      trainingPrograms={trainingPrograms}
      trainingApi={trainingApi}
      trainees={trainees}
      traineeCount={traineeCount}
    />
  );
};

export default AllTraineesPage;
