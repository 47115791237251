import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";

export const useCandidateAlertEnabled = (organizationId: string): boolean => {
  const userEnabled = useFeatureFlag("candidate:alert");
  let orgEnabled = useFeatureFlag("candidate:alert:for_orgs");
  // Prevents org wide enablement for HCA
  if (organizationId === "0d81c738-a02f-475a-8ebe-cc36acd218be") {
    orgEnabled = false;
  }
  return userEnabled || orgEnabled;
};
