import { ApolloError } from "@apollo/client";
import { useEffect, useState } from "react";

import {
  ShareUserFragment,
  useCallShareLazyQuery,
  useClipShareLazyQuery,
} from "..";

type ShareData = {
  id: string;
  sharedTo: ShareUserFragment;
};

type ViewableShareData = {
  id: string;
  shareableUsers: ShareUserFragment[];
  callShares: ShareData[];
};

export default function useLoadViewableShares({
  callId,
  clipId,
}: {
  callId: string;
  clipId?: string | null;
}): { loading: boolean; callOrClip?: ViewableShareData | null } {
  const [loading, setLoading] = useState(true);
  const [callOrClip, setCallOrClip] = useState<ViewableShareData | null>(null);
  const [fetchCallShares] = useCallShareLazyQuery();
  const [fetchClipShares] = useClipShareLazyQuery();

  const onError = (error: ApolloError): void => {
    setLoading(false);
  };

  useEffect(() => {
    if (clipId) {
      fetchClipShares({
        variables: { clipId },
        onError,
        onCompleted: (data) => {
          setCallOrClip(
            data.clip
              ? {
                  callShares: data.clip.clipShares,
                  ...data.clip,
                }
              : null
          );
          setLoading(false);
        },
      });
    } else {
      fetchCallShares({
        variables: {
          id: callId,
        },
        onError,
        onCompleted: (data) => {
          setCallOrClip(data.call ? (data.call as ViewableShareData) : null);
          setLoading(false);
        },
      });
    }
  }, []);

  return { loading, callOrClip };
}
