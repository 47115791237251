import { CandidateFragment } from "../main/graphql";

export const getSmartrecruitersLink = (
  candidate: Pick<CandidateFragment, "applications" | "smartrecruitersLink">,
  positionId?: string
): string | null | undefined => {
  /** Anyone on the hiring team has access to the application/candidate profile
   * in SmartRecruiters. Interviewers must be on the hiring team to be scheduled
   * for an interview. SmartRecruiters has a general review section per
   * application per user (not per interview like other ATS).
   */
  const foundApplication = candidate.applications.find(
    (application) => application.positionId === positionId
  );
  const link =
    foundApplication?.smartrecruitersLink || candidate.smartrecruitersLink;
  return link;
};
