import { Box, Center, Flex } from "@chakra-ui/react";
import React from "react";

interface SliderClipIndicatorProps {
  isStart: boolean;
  extraSpacerOnEnd?: boolean;
}

const SliderClipIndicator: React.FC<SliderClipIndicatorProps> = ({
  isStart,
  extraSpacerOnEnd,
}) => {
  if (isStart)
    return (
      <Flex mr="3px" pb="6px" data-testid="slider-clip-left">
        <Center>
          <Box bgColor="gray.300" h="6px" w="8px" mb="2px" />
        </Center>
        <Box
          borderRightWidth={1}
          borderRightStyle="dashed"
          borderRightColor="gray.700"
          h="12px"
          ml="3px"
        />
      </Flex>
    );
  return (
    <Flex
      ml="3px"
      pl={extraSpacerOnEnd ? "3px" : "0"}
      pb="6px"
      data-testid="slider-clip-right"
    >
      <Box
        borderRightWidth={1}
        borderRightStyle="dashed"
        borderRightColor="gray.700"
        h="12px"
        mr="3px"
      />
      <Center>
        <Box bgColor="gray.300" h="6px" w="8px" mb="2px" />
      </Center>
    </Flex>
  );
};

export default SliderClipIndicator;
