import { Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React, { useState } from "react";

import { useToast } from "../../../components";
import UpgradeRequestImage from "../../../components/Images/UpgradeRequestImage";
import { usePageTracker } from "../../../utils/googleAnalytics";
import { useRequestInterviewUpgradeMutation } from "../../graphql";
import { DefaultPageLayout } from "../../layouts";

const UpgradePage: React.FC = () => {
  usePageTracker("upgrade-interview");
  const toast = useToast();
  const showErrorToast = (): void => {
    toast({
      status: "error",
      title:
        "There was an error with submitting the request, please try again in some time",
    });
  };
  const [successfullySubmittedRequest, setSuccessfullySubmittedRequest] =
    useState(false);

  const [requestInterviewUpgrade] = useRequestInterviewUpgradeMutation({
    onCompleted: (data) => {
      if (data?.requestInterviewUpgrade?.success) {
        setSuccessfullySubmittedRequest(true);
        toast({
          status: "success",
          title:
            "Request submitted successfully. Our support team will be in touch with you with next steps shortly",
        });
      } else {
        showErrorToast();
      }
    },
    onError: () => {
      showErrorToast();
    },
  });

  const onContactSupport = (): void => {
    requestInterviewUpgrade();
  };

  return (
    <DefaultPageLayout bg="linear-gradient(0deg, #E9F5FF 0%, #E9F5FF 100%), linear-gradient(180deg, rgba(113, 165, 214, 0.60) 100.29%, rgba(173, 223, 244, 0.60) 200.29%)">
      <Flex
        h="100%"
        alignItems="center"
        color="gray.800"
        justifyContent="space-around"
      >
        {successfullySubmittedRequest ? (
          <Box textAlign="center">
            <UpgradeRequestImage />
            <Heading
              as="h3"
              fontSize="3xl"
              size="3xl"
              fontWeight="600"
              mb={3}
              mt={8}
              lineHeight="40px"
            >
              Thank you for your interest.
            </Heading>
            <Text>We&apos;ll be in touch with you shortly.</Text>
          </Box>
        ) : (
          <Flex w="820px" flexWrap="wrap-reverse" p="10px">
            <Box mr={6} flex="1" w="330px">
              <Heading as="h3" fontSize="3xl" size="3xl" fontWeight="600">
                Dramatically more efficient hiring
              </Heading>
              <Text mt={2}>
                BrightHire Interview streamlines your process with real-time
                interviewer guidance, AI-powered notes and highlights,
                actionable insights, and more.
              </Text>
              <Button onClick={onContactSupport} w="100%" marginTop={6}>
                Upgrade to Interview today
              </Button>
            </Box>
            <Box position="relative" marginTop={-8} maxW="440px">
              <Image src="/static/images/interview-upgrade.png" />
            </Box>
          </Flex>
        )}
      </Flex>
    </DefaultPageLayout>
  );
};

export default UpgradePage;
