import React from "react";

import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { usePageTracker } from "../../../utils/googleAnalytics";
import InterviewViewBeta from "../../components/Interview/InterviewViewBeta";
import { DefaultPageLayout } from "../../layouts";

const CallPage: React.FC = () => {
  usePageTracker("call_details");
  const { layoutHeight } = useWindowDimensions();

  return (
    <DefaultPageLayout height={{ base: "auto", lg: layoutHeight }}>
      <InterviewViewBeta />
    </DefaultPageLayout>
  );
};

export default CallPage;
