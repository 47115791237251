import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { BsPlus } from "react-icons/bs";

import { useTheme } from "../../../components";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { formatDuration } from "../../../utils/datetime";
import RecordingsModal from "./RecordingsModal";
import TabHeader, { TabHeaderText } from "./TabHeader";
import TrainingListItem from "./TrainingListItem";
import { TrainingListItemType, TrainingProgram } from "./types";
import { TrainingApi } from "./useTrainingApi";

type ManageTrainingPageRecordingsProps = {
  trainingProgram: TrainingProgram;
  trainingListItems: TrainingListItemType[];
  trainingApi: TrainingApi;
  setAutoSaveInProgress: (autoSaveInProgress: boolean) => void;
};

const ManageTrainingPageRecordings: React.FC<
  ManageTrainingPageRecordingsProps
> = ({
  trainingProgram,
  trainingListItems,
  trainingApi,
  setAutoSaveInProgress,
}) => {
  const { colors } = useTheme();
  const {
    isOpen: isAddRecordingOpen,
    onOpen: onAddRecordingOpen,
    onClose: onAddRecordingClose,
  } = useDisclosure();
  const { percentHeight } = useWindowDimensions();

  return (
    <Box minHeight="0" overflowY="auto" overflowX="hidden" mb="-1px">
      <TabHeader>
        <TabHeaderText>
          {trainingListItems.length} Video
          {trainingListItems.length === 1 ? "" : "s"}
          {!!trainingProgram.totalDuration &&
            ` - Total Length: ${formatDuration(trainingProgram.totalDuration)}`}
        </TabHeaderText>
        <Button
          data-testid="training-management-add-recording"
          variant="outline"
          onClick={onAddRecordingOpen}
          leftIcon={<BsPlus size={20} />}
          fontSize="sm"
          fontWeight="500"
          mr="4"
          mt="3"
          mb="4"
          height="2.375rem"
          borderColor={colors.blue[600]}
          color={colors.blue[600]}
          backgroundColor={`${colors.blue[500]}1A`}
        >
          Add recording
        </Button>
        <RecordingsModal
          isOpen={isAddRecordingOpen}
          onClose={onAddRecordingClose}
          trainingProgramId={trainingProgram.id}
          trainingApi={trainingApi}
        />
      </TabHeader>
      {trainingListItems.length > 0 ? (
        <Box>
          {trainingListItems.map((trainingItem, i) => (
            <TrainingListItem
              key={trainingItem.id}
              trainingItem={trainingItem}
              listLength={trainingListItems.length}
              listPosition={i}
              trainingApi={trainingApi}
              launched
              trainingProgramId={trainingProgram.id}
              setAutoSaveInProgress={setAutoSaveInProgress}
              trainingListItems={trainingListItems}
            />
          ))}
        </Box>
      ) : (
        <Flex
          height={percentHeight(60)}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Text fontSize="sm" fontWeight="400" color="gray.700">
            There are no recordings currently in this program
          </Text>
        </Flex>
      )}
    </Box>
  );
};

export default ManageTrainingPageRecordings;
