export const buildLeverLink = (
  postingId?: string,
  opportunityId?: string
): string => {
  let leverLink = "https://hire.lever.co";
  if (postingId && postingId !== "") {
    leverLink = leverLink.concat(`/jobs/postings/edit/${postingId}`);
  }
  if (opportunityId && opportunityId !== "") {
    leverLink = leverLink.concat(`/candidates/${opportunityId}`);
  }
  return leverLink;
};
