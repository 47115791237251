import React, { PropsWithChildren } from "react";

import { InstantSearch } from "../components/Search";
import { SidebarNav } from "../components/SidebarNav";
import { SpotlightSearch } from "../components/SpotlightSearch";
import useCurrentUser from "../hooks/useCurrentUser";

interface BaseLayoutProps {
  allowEmptyQuery?: boolean;
  updateUrlWithSearchParams?: boolean;
}

/**
 * Prepares page navigation components and places `children` in
 * the appropriate spot to clear the navigation UI
 */
const BaseLayout: React.FC<PropsWithChildren<BaseLayoutProps>> = ({
  allowEmptyQuery = false,
  updateUrlWithSearchParams = false,
  children,
}) => {
  const currentUser = useCurrentUser();

  if (!currentUser) {
    return <SidebarNav>{children}</SidebarNav>;
  }

  return (
    <InstantSearch
      allowEmptyQuery={allowEmptyQuery}
      updateUrl={updateUrlWithSearchParams}
    >
      <SpotlightSearch>
        {(searchState) => (
          <SidebarNav currentUser={currentUser} searchState={searchState}>
            {children}
          </SidebarNav>
        )}
      </SpotlightSearch>
    </InstantSearch>
  );
};

export default BaseLayout;
