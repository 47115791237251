import { Flex, Icon, IconButton } from "@chakra-ui/react";
import React from "react";
import {
  IoFlagOutline,
  IoFlagSharp,
  IoStarOutline,
  IoStarSharp,
} from "react-icons/io5";

import { Avatar } from "../../../components";
import { CallNoteType, UserAvatarFragment } from "../../graphql";
import { noteTypeColorBeta, reactionTooltipLabel } from "./utils";

const buttonStyles = {
  variant: "nostyle",
  w: "7",
  minW: "7",
  h: "7",
  borderRadius: "full",
  color: "gray.200", // inactive color
};

type ReactionsBetaProps = {
  noteType?: CallNoteType;
  updateNoteReaction: (
    newNoteType: CallNoteType,
    previousNoteType?: CallNoteType
  ) => void;
  isOverall?: boolean;
  onReactionHover?: (type?: CallNoteType) => void;
  /**
   * Optional props for display of submitted reactions
   */
  showAvatar?: boolean;
  user?: { id?: string } & Pick<
    UserAvatarFragment,
    "firstName" | "profilePicUrl"
  >;
  isInterviewerOwned?: boolean;
  readOnly?: boolean;
};

const Reactions: React.FC<ReactionsBetaProps> = ({
  updateNoteReaction,
  noteType,
  onReactionHover,
  showAvatar,
  user,
  isInterviewerOwned,
  readOnly,
}) => {
  const isStar = noteType === CallNoteType.Star;
  const isFlag = noteType === CallNoteType.Flag;

  const isReaction = isStar || isFlag;

  const handleReactionHover = (type?: CallNoteType): void => {
    onReactionHover?.(type);
  };

  if (readOnly) {
    const shouldShowAvatar = user && showAvatar;
    const justify = isReaction && !shouldShowAvatar ? "end" : "start";
    return (
      <Flex align="center" justify={justify} w="56px" h="7">
        {shouldShowAvatar && (
          <Avatar
            user={user}
            showTooltip
            isInterviewer={isInterviewerOwned}
            mr="1"
          />
        )}
        {isReaction && (
          <IconButton
            aria-label={reactionTooltipLabel(noteType)}
            icon={
              <Icon
                boxSize="16px"
                color={noteTypeColorBeta(noteType)}
                as={isFlag ? IoFlagSharp : IoStarSharp}
              />
            }
            isDisabled={readOnly}
            _disabled={{ bg: "none" }}
            _hover={{
              bg: "none",
            }}
            cursor="default"
            {...buttonStyles}
          />
        )}
      </Flex>
    );
  }

  return (
    <Flex align="center" justify="start" w="56px">
      <IconButton
        icon={
          <Icon
            boxSize="16px"
            color={isStar ? noteTypeColorBeta(CallNoteType.Star) : "inherit"}
            as={isStar ? IoStarSharp : IoStarOutline}
          />
        }
        aria-label={reactionTooltipLabel(CallNoteType.Star)}
        onClick={() => updateNoteReaction(CallNoteType.Star, noteType)}
        isDisabled={readOnly}
        _hover={{
          bg: noteTypeColorBeta(CallNoteType.Star, true),
          color: noteTypeColorBeta(CallNoteType.Star),
        }}
        _focus={{
          bg: noteTypeColorBeta(CallNoteType.Star, true),
          color: noteTypeColorBeta(CallNoteType.Star),
        }}
        onMouseEnter={() => handleReactionHover(CallNoteType.Star)}
        onMouseLeave={() => handleReactionHover(undefined)}
        {...buttonStyles}
      />
      <IconButton
        icon={
          <Icon
            boxSize="16px"
            color={isFlag ? noteTypeColorBeta(CallNoteType.Flag) : "inherit"}
            as={isFlag ? IoFlagSharp : IoFlagOutline}
          />
        }
        aria-label={reactionTooltipLabel(CallNoteType.Flag)}
        onClick={() => updateNoteReaction(CallNoteType.Flag, noteType)}
        isDisabled={readOnly}
        _hover={{
          bg: noteTypeColorBeta(CallNoteType.Flag, true),
          color: noteTypeColorBeta(CallNoteType.Flag),
        }}
        _focus={{
          bg: noteTypeColorBeta(CallNoteType.Flag, true),
          color: noteTypeColorBeta(CallNoteType.Flag),
        }}
        onMouseEnter={() => handleReactionHover(CallNoteType.Flag)}
        onMouseLeave={() => handleReactionHover(undefined)}
        {...buttonStyles}
      />
    </Flex>
  );
};

export default Reactions;
