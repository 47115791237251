import { Box, Flex, Icon } from "@chakra-ui/react";
import React from "react";
import { IoTrashBin } from "react-icons/io5";

import useDeleteClip from "./useDeleteClip";

interface DeleteClipButtonProps {
  clipId: string;
  canDelete: boolean;
  onSuccess?: () => void;
  onError: (errorMsg: string) => void;
}

const DeleteClipButton: React.FC<DeleteClipButtonProps> = ({
  clipId,
  canDelete,
  onSuccess,
  onError,
}) => {
  const { openConfirmationDialog, confirmDeleteClipDialog } = useDeleteClip({
    clipId,
    onSuccess,
    onError,
  });

  return (
    <>
      <Flex
        alignItems="center"
        px="3"
        onClick={canDelete ? openConfirmationDialog : undefined}
        width="full"
        color={canDelete ? "red.600" : "gray.700"}
      >
        <Icon as={IoTrashBin} boxSize="4" />
        <Box ml="3" fontWeight="500">
          Delete Clip
          {!canDelete && (
            <Box fontWeight="400" fontSize="12px" width="100%">
              Please contact clip creator to request deletion of this clip.
            </Box>
          )}
        </Box>
      </Flex>

      {confirmDeleteClipDialog}
    </>
  );
};

export default DeleteClipButton;
