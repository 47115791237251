import { ApolloError } from "@apollo/client";

type CallErrorView = "error" | "forbidden" | "none" | "deleted" | "";

export function useCallErrorView({
  error,
  call,
  clip,
  auditLogItem,
}: {
  error?: ApolloError;
  call?: any;
  clip?: any;
  auditLogItem: any;
}): CallErrorView {
  const recording = call ?? clip;
  let view: CallErrorView = "";

  if (error || !recording) {
    view = "error";

    if (error?.graphQLErrors.some((e) => e.extensions?.code === "FORBIDDEN")) {
      view = "forbidden";
    }

    if (
      error?.graphQLErrors.some((e) => e.extensions?.code === "NOT_FOUND") ||
      (!error && !recording)
    ) {
      view = "none";
    }

    if (auditLogItem) {
      view = "deleted";
    }
  }

  return view;
}
