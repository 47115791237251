import { isVideo } from "../../../../utils/call";
import {
  useCallTrackerSegmentLazyQuery,
  useCallTrackersQuery,
} from "../../../graphql";
import { TrackersTabProps } from "../../Recording/SidebarTabs/TrackersTab";

type CallTrackersParams = {
  callId: string;
};

type CallTrackersReturn = Omit<
  TrackersTabProps,
  "onSeek" | "doesCallHaveTranscript"
>;

function useCallTrackers({ callId }: CallTrackersParams): CallTrackersReturn {
  const trackersQuery = useCallTrackersQuery({
    variables: { callId },
  });

  const [getTrackerSegments, trackerSegmentsQuery] =
    useCallTrackerSegmentLazyQuery();

  return {
    onTrackerGroupChange(trackerGroupId) {
      if (trackerGroupId) {
        getTrackerSegments({
          variables: { id: callId, trackerGroupId },
        });
      }
    },
    onTrackerKeywordChange(trackerKeywordId) {
      if (trackerKeywordId) {
        getTrackerSegments({
          variables: { id: callId, trackerKeywordId },
        });
      }
    },
    trackers: trackersQuery.data?.call?.trackers,
    trackerSegments: trackerSegmentsQuery.data?.call?.trackerSegments,
    speakers: trackersQuery.data?.call?.speakers,
    keywordsFetchData: trackersQuery,
    segmentsFetchData: trackerSegmentsQuery,
    speakerMenuDisabled:
      !trackerSegmentsQuery.data?.call ||
      !isVideo(trackerSegmentsQuery.data.call.type) ||
      !trackerSegmentsQuery.data.call.canEdit,
  };
}

export default useCallTrackers;
