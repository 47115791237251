import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  Heading,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useFormContext } from "react-hook-form";

import { CallType, NotetakerRecorderDisclaimerType } from "../../main/graphql";

export type DisclaimerFormData = {
  disclaimer: string;
  disclaimerType: NotetakerRecorderDisclaimerType;
  defaultDisclaimer: string;
};

interface DisclaimerInputsProps {
  callType: CallType;
}

const DisclaimerInputs: React.FC<DisclaimerInputsProps> = ({ callType }) => {
  const { register, watch } = useFormContext<DisclaimerFormData>();

  const callText = (callType: CallType): string => {
    switch (callType) {
      case CallType.GoogleMeet:
        return "Google Meet";
      case CallType.ZoomNotetaker:
        return "Zoom";
      case CallType.TeamsNotetaker:
        return "Teams";
      default:
        return "the meeting";
    }
  };

  const notificationType = (t: NotetakerRecorderDisclaimerType): string => {
    if (t === NotetakerRecorderDisclaimerType.Organization) {
      return "a chat message";
    }
    if (t === NotetakerRecorderDisclaimerType.Default) {
      return "a chat message";
    }
    if (t === NotetakerRecorderDisclaimerType.Native) {
      return "the Zoom recording disclaimer dialog box";
    }

    return "nothing";
  };

  const [
    notetakerRecorderDisclaimer,
    notetakerRecorderDisclaimerType,
    defaultDisclaimer,
  ] = watch(["disclaimer", "disclaimerType", "defaultDisclaimer"]);

  return (
    <div>
      <Divider mb={6} />
      <Heading as="h4" fontSize="md" mb={6}>
        Disclaimer
      </Heading>
      <FormControl id="notetakerRecorderDisclaimerType" mb={8}>
        <Select
          size="sm"
          width="auto"
          data-testid="notetaker-recorder-disclaimer-type"
          {...register("disclaimerType")}
        >
          {callType === CallType.ZoomNotetaker && (
            <option key="NATIVE" value={NotetakerRecorderDisclaimerType.Native}>
              Use the Zoom recording disclaimer dialog box
            </option>
          )}
          <option key="DEFAULT" value={NotetakerRecorderDisclaimerType.Default}>
            Use BrightHire&apos;s default disclaimer
          </option>
          <option
            key="ORGANIZATION"
            value={NotetakerRecorderDisclaimerType.Organization}
          >
            Create custom disclaimer text
          </option>
          <option key="NONE" value={NotetakerRecorderDisclaimerType.None}>
            Don&apos;t show any disclaimer
          </option>
        </Select>
        <FormHelperText>
          Select the disclaimer text all participants should see while
          BrightHire is recording. This will appear as a chat notification in{" "}
          {callText(callType)} when the meeting starts aand each time a user
          joins the meeting.
          {callType === CallType.ZoomNotetaker && (
            <Text mt={2}>
              If &apos;use the Zoom recording disclaimer dialog box&apos; is
              selected then the recording disclaimer will be shown natively in
              Zoom and not sent as a chat message.
            </Text>
          )}
        </FormHelperText>
      </FormControl>
      <FormControl
        id="googleMeetRecordingDisclaimer"
        mb={8}
        hidden={
          notetakerRecorderDisclaimerType !==
          NotetakerRecorderDisclaimerType.Organization
        }
      >
        <Input
          {...register("disclaimer")}
          placeholder="Add a custom BrightHire disclaimer"
          data-testid="notetaker-recorder-disclaimer"
        />
        <FormHelperText>
          Please note that only the first 100 characters will be shown in the
          notification, but the full text will be available in the chat panel.
        </FormHelperText>
      </FormControl>

      <Box p={6} mb={6} bg="gray.100">
        <Text fontSize="xs">
          Your interviewers and candidates will see:{" "}
          <Text fontWeight={600} as="span">
            {notificationType(notetakerRecorderDisclaimerType)}
          </Text>
        </Text>
        {(notetakerRecorderDisclaimerType ===
          NotetakerRecorderDisclaimerType.Default ||
          notetakerRecorderDisclaimerType ===
            NotetakerRecorderDisclaimerType.Organization) && (
          <Text fontSize="xs">
            With the text:{" "}
            <Text fontWeight={600} as="span">
              {notetakerRecorderDisclaimerType ===
              NotetakerRecorderDisclaimerType.Default
                ? defaultDisclaimer
                : notetakerRecorderDisclaimer}
            </Text>
          </Text>
        )}
      </Box>
    </div>
  );
};

export default DisclaimerInputs;
