import React, { useMemo } from "react";

import { errorToast, useToast } from "../../../../components";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import {
  AddNewCallNoteMutation,
  CallBetaFragment,
  CallNotesV3Query,
  CallNoteType,
  CallNoteVisibility,
  ScorecardItemNotesFragment as ScorecardItem,
  useCallNotesV3LazyQuery,
} from "../../../graphql";
import useAddCallNote from "../../../graphql/hooks/useAddCallNote";
import useAutofill, { getAts } from "../../../hooks/useAutofill";
import { useCopyNotes } from "../../../hooks/useCopyNotesBeta";
import useCurrentUser from "../../../hooks/useCurrentUser";
import CopyNotesV3Buttons from "../../CallNotes/beta/CopyNotesV3Buttons";
import { NotesV3TabProps } from "../../Recording/SidebarTabs/NotesV3Tab";

type CallNotesParams = {
  call: Pick<
    CallBetaFragment,
    | "id"
    | "greenhouseScorecardLinkWithId"
    | "leverInterviewLinkWithId"
    | "candidate"
    | "position"
    | "interviewerIds"
  >;
  onCallNotesV3QueryCompleted?: (data: CallNotesV3Query) => void;
  onAddCallNoteCompleted?: (data: AddNewCallNoteMutation) => void;
  disableAutofill?: boolean;
  notesForCurrentUserOnly: boolean;
};

type CallNotesReturn = Pick<
  NotesV3TabProps,
  "scorecardItems" | "generalNotes" | "onAddNote" | "headerSlot" | "error"
> & { getCallNotesV3(): void; loading: boolean };

const useCallNotes = ({
  call,
  onCallNotesV3QueryCompleted,
  onAddCallNoteCompleted,
  disableAutofill,
  notesForCurrentUserOnly,
}: CallNotesParams): CallNotesReturn => {
  const currentUser = useCurrentUser();
  const sendGAEvent = useSendGAEvent();
  const toast = useToast();
  const callId = call.id;
  const [
    getCallNotesV3,
    { data: notesData, loading: notesLoading, error: notesError },
  ] = useCallNotesV3LazyQuery({
    variables: {
      id: callId,
      notesForCurrentUserOnly,
    },
    onCompleted: onCallNotesV3QueryCompleted,
  });

  const [addCallNote] = useAddCallNote({
    call: { id: callId, __typename: "Call" },
    onCompleted: onAddCallNoteCompleted,
    onError() {
      errorToast(toast, "There was a problem adding your note");
    },
  });

  const mergedScoreCardItems = useMemo(() => {
    const scorecardItems: ScorecardItem[] = JSON.parse(
      JSON.stringify(
        notesData?.call?.notesScorecards?.flatMap(
          (scorecard) => scorecard.items
        ) ?? []
      )
    );

    const mergeScoreCardItems = (items: ScorecardItem[]): ScorecardItem[] => {
      return items.reduce((acc, current) => {
        const existingItem = acc.find(
          (item) => item.itemText === current.itemText
        );

        if (existingItem) {
          const existingItemIndex = acc.indexOf(existingItem);
          acc[existingItemIndex] = {
            ...existingItem,
            marked: existingItem.marked || current.marked,
            markedTime:
              (existingItem.markedTime ?? Infinity) <
              (current.markedTime ?? Infinity)
                ? existingItem.markedTime
                : current.markedTime,
            questionNotes: [
              ...existingItem.questionNotes,
              ...current.questionNotes,
            ],
            childItems: mergeScoreCardItems([
              ...(existingItem.childItems as ScorecardItem[]),
              ...((current.childItems as ScorecardItem[]) || []),
            ]),
          };
        } else {
          acc.push({
            ...current,
            childItems: mergeScoreCardItems(
              (current.childItems as ScorecardItem[]) || []
            ),
          });
        }

        return acc;
      }, [] as ScorecardItem[]);
    };

    return mergeScoreCardItems(scorecardItems);
  }, [notesData?.call?.notesScorecards]);

  const generalNotes = notesData?.call?.generalNotes ?? [];
  const ats = getAts(call);

  const { autofillEnabled, onAutofill } = useAutofill(call);
  const handleCopyNotes = useCopyNotes({
    call,
    generalNotes,
    scorecardItemNotes: mergedScoreCardItems,
  });

  const isInterviewer = call.interviewerIds.includes(currentUser.id);

  return {
    getCallNotesV3,
    generalNotes,
    scorecardItems: mergedScoreCardItems,
    loading: notesData === undefined,
    error: notesError,

    headerSlot: notesLoading ? null : (
      <CopyNotesV3Buttons
        ats={ats}
        onCopy={handleCopyNotes}
        onAutofill={onAutofill}
        autofillEnabled={autofillEnabled && !disableAutofill && isInterviewer}
      />
    ),
    onAddNote: async ({ text, type, time, scorecardQuestionId }) => {
      if (
        text.trim() === "" &&
        [CallNoteType.Note, CallNoteType.Comment].includes(type)
      ) {
        return;
      }

      sendGAEvent("call_note_added", "call_review");

      const callId = call.id;

      await addCallNote({
        variables: {
          callId,
          text,
          type,
          time,
          scorecardQuestionId,
          visibility: CallNoteVisibility.Public,
        },
      });
    },
  };
};

export default useCallNotes;
