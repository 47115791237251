import { Box, Flex, Heading, Icon, useTheme } from "@chakra-ui/react";
import React from "react";
import { IconType } from "react-icons";

const TEXT_COLOR = "gray.600";
const ICON_COLOR = "gray.400";

// Please use "gray.600" for any text content and gray.400 for any icons
export const EmptyStateWithIcon: React.FC<{
  heading: string;
  icon: IconType;
  Text: React.FC<any>;
  transparent?: boolean;
  padded?: boolean;
  maxWidth?: string;
}> = ({
  heading,
  icon,
  Text,
  padded = true,
  transparent = false,
  maxWidth = "pageMinWidth",
}) => {
  const theme = useTheme();
  const { pagePadding } = theme.sizes;
  const padding = padded ? pagePadding : "none";
  return (
    <Box p={padding} width="100%" overflow="visible" maxWidth={maxWidth}>
      <Flex
        flexDirection="row"
        width="100%"
        backgroundColor={transparent ? "transparent" : "gray.100"}
        p="10"
        justifyContent="flex-start"
        alignContent="center"
        alignItems="center"
      >
        <Box fontSize="sm" color="placeholder">
          <Icon as={icon} w="48px" h="48px" color={ICON_COLOR} />
        </Box>
        <Box fontSize="sm" color="placeholder" pl={{ sm: 4, md: 8, lg: 10 }}>
          <Heading as="h1" fontSize="sm" mb={2} colorScheme={TEXT_COLOR}>
            {heading}
          </Heading>
          <Text colorScheme={TEXT_COLOR} />
        </Box>
      </Flex>
    </Box>
  );
};
