import {
  Tag,
  TagCloseButton,
  TagLabel,
  TagLabelProps,
  TagProps,
} from "@chakra-ui/react";
import React from "react";

interface KeywordPillProps {
  text: string;
  tagProps: TagProps;
  tagLabelProps: TagLabelProps;
  closeButton?: boolean;
  onClose(text: string): void;
}

export const defaultKeywordTagProps = {
  borderWidth: "1px",
  padding: 1,
  size: "md" as const,
};

export const defaultKeywordTagLabelProps = {
  paddingRight: 2,
  fontSize: "12px",
  fontWeight: "medium" as const,
};

const KeywordPill: React.FC<KeywordPillProps> = ({
  text,
  tagProps,
  tagLabelProps,
  closeButton,
  onClose,
}) => (
  <Tag key={text} {...tagProps}>
    <TagLabel {...tagLabelProps}>{text}</TagLabel>
    {closeButton ? <TagCloseButton onClick={() => onClose(text)} /> : ""}
  </Tag>
);

KeywordPill.displayName = "KeywordPill";

export default KeywordPill;
