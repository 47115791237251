import React from "react";

import { usePageTracker } from "../../../utils/googleAnalytics";
import { CandidateView } from "../../components/Candidate";
import DefaultLayout from "../../layouts/DefaultLayout";

const CandidatePage: React.FC = () => {
  usePageTracker("candidate_page_redesign");

  return (
    <DefaultLayout>
      <CandidateView />
    </DefaultLayout>
  );
};

export default CandidatePage;
