import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

const EMPTY_INTERVIEW_ICON = (
  <svg
    width="100%"
    height="167"
    viewBox="0 0 190 167"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M162.955 49.1361C166.689 50.1367 168.905 53.975 167.904 57.7093L141.767 155.257C140.654 159.41 136.365 161.869 132.186 160.749L8.46862 127.599C4.28981 126.479 1.80484 122.206 2.91768 118.052L29.0555 20.5048C30.056 16.7706 33.8944 14.5545 37.6287 15.5551L162.955 49.1361Z"
      fill="#DDF5FF"
    />
    <path
      d="M38.4486 15.711L162.166 48.861C166.345 49.9807 168.823 54.2797 167.702 58.4641L166.07 64.5546L27.2209 27.3501L28.8529 21.2596C29.9741 17.0752 34.2697 14.5913 38.4486 15.711Z"
      fill="#AEDEFF"
    />
    <path
      d="M39.0423 25.9073C40.3454 26.2564 41.6851 25.4802 42.0342 24.1772C42.3838 22.8724 41.6113 21.5322 40.3082 21.183C39.0069 20.8344 37.6659 21.6082 37.3163 22.9131C36.9672 24.216 37.7411 25.5586 39.0423 25.9073Z"
      fill="#EAF9FF"
    />
    <path
      d="M46.7064 27.9612C48.0076 28.3099 49.3491 27.5342 49.6983 26.2312C50.0479 24.9263 49.2735 23.5857 47.9722 23.237C46.6691 22.8878 45.33 23.6622 44.9804 24.967C44.6312 26.27 45.4033 27.612 46.7064 27.9612Z"
      fill="#EAF9FF"
    />
    <path
      d="M54.3744 30.0156C55.6774 30.3648 57.0171 29.5886 57.3662 28.2856C57.7159 26.9808 56.9433 25.6406 55.6402 25.2914C54.3371 24.9423 52.998 25.7166 52.6483 27.0215C52.2992 28.3244 53.0713 29.6665 54.3744 30.0156Z"
      fill="#EAF9FF"
    />
    <path
      d="M81.0234 34.5676L115.887 43.9093"
      stroke="#EAF9FF"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.8664 61.614L82.7128 69.0754C84.4824 69.5496 85.5379 71.3806 85.0626 73.1545C84.5878 74.9265 82.7582 75.9844 80.9886 75.5102L53.1422 68.0488C51.3706 67.5742 50.3152 65.7432 50.79 63.9712C51.2653 62.1972 53.0948 61.1393 54.8664 61.614Z"
      fill="#7FC6FE"
    />
    <path
      d="M51.3039 74.9136L67.5823 79.2754C69.3538 79.75 70.4093 81.581 69.9345 83.353C69.4592 85.127 67.6296 86.1849 65.8581 85.7102L49.5797 81.3484C47.8081 80.8737 46.7527 79.0428 47.228 77.2689C47.7028 75.4968 49.5323 74.4389 51.3039 74.9136Z"
      fill="#AEDEFF"
    />
    <path
      d="M78.2922 82.1454L83.432 83.5227C85.2036 83.9973 86.259 85.8283 85.7842 87.6004C85.3089 89.3743 83.4794 90.4322 81.7078 89.9575L76.568 88.5803C74.7964 88.1056 73.741 86.2746 74.2163 84.5007C74.6911 82.7287 76.5206 81.6708 78.2922 82.1454Z"
      fill="#7FC6FE"
    />
    <path
      d="M47.7375 88.2117L51.736 89.2831C53.5057 89.7572 54.5611 91.5882 54.0863 93.3603C53.611 95.1342 51.7814 96.1921 50.0118 95.7179L46.0133 94.6465C44.2417 94.1718 43.1863 92.3409 43.6616 90.567C44.1364 88.7949 45.9659 87.737 47.7375 88.2117Z"
      fill="#AEDEFF"
    />
    <path
      d="M62.4466 92.1516L86.4376 98.58C88.2091 99.0546 89.2646 100.886 88.7898 102.658C88.3144 104.432 86.4849 105.489 84.7134 105.015L60.7223 98.5864C58.9527 98.1122 57.8972 96.2813 58.3725 94.5074C58.8473 92.7353 60.6769 91.6774 62.4466 92.1516Z"
      fill="#7FC6FE"
    />
    <path
      d="M97.1516 101.451L117.715 106.961C119.484 107.435 120.54 109.266 120.065 111.038C119.59 112.812 117.76 113.87 115.991 113.396L95.4273 107.886C93.6558 107.411 92.6003 105.58 93.0756 103.806C93.5505 102.034 95.38 100.976 97.1516 101.451Z"
      fill="#EAF9FF"
    />
    <path
      d="M44.175 101.51L57.0274 104.953C58.7971 105.428 59.8526 107.258 59.3773 109.032C58.9025 110.804 57.0729 111.862 55.3032 111.388L42.4508 107.944C40.6792 107.47 39.6238 105.639 40.0986 103.867C40.5739 102.093 42.4034 101.035 44.175 101.51Z"
      fill="#7FC6FE"
    />
    <path
      d="M67.7395 107.822L70.5967 108.588C72.3663 109.062 73.4218 110.893 72.9465 112.667C72.4717 114.439 70.6421 115.497 68.8724 115.023L66.0153 114.257C64.2456 113.783 63.1902 111.952 63.665 110.18C64.1403 108.406 65.9698 107.348 67.7395 107.822Z"
      fill="#AEDEFF"
    />
    <path
      d="M81.3059 111.458L98.3 116.011C100.07 116.485 101.125 118.316 100.65 120.09C100.175 121.862 98.3455 122.92 96.5758 122.446L79.5817 117.892C77.8121 117.418 76.7566 115.587 77.2314 113.815C77.7067 112.041 79.5363 110.983 81.3059 111.458Z"
      fill="#7FC6FE"
    />
    <g opacity="0.8">
      <path
        d="M153.477 79.6188C164.165 84.0229 176.402 78.9166 180.808 68.2128C185.214 57.509 180.122 45.2616 169.433 40.8575C158.745 36.4534 146.508 41.5597 142.102 52.2635C137.696 62.9673 142.788 75.2146 153.477 79.6188Z"
        fill="#7FC6FE"
      />
    </g>
    <path
      d="M155.477 75.6207C166.165 80.0248 178.402 74.9185 182.808 64.2147C187.214 53.5109 182.122 41.2636 171.433 36.8594C160.745 32.4553 148.508 37.5617 144.102 48.2654C139.696 58.9692 144.788 71.2166 155.477 75.6207Z"
      fill="#AEDEFF"
    />
    <path
      d="M167.441 45.5316L167.411 45.5175C165.329 44.7204 160.864 44.4817 158.877 49.3118C158.501 50.2226 158.932 51.2618 159.843 51.6376C160.753 52.013 161.792 51.5791 162.167 50.6683C163.251 48.036 165.451 48.6014 166.116 48.8395C168.212 49.7164 169.051 51.3816 168.296 53.2163C167.74 54.5636 166.982 54.8212 165.124 55.1593C163.464 55.4568 160.948 55.9238 159.846 58.8624L159.65 59.3406C159.275 60.2514 159.706 61.2906 160.617 61.6665C161.518 62.0375 162.56 61.6186 162.958 60.7156L163.197 60.1353C163.562 59.1579 164.121 58.9764 165.77 58.6741L165.796 58.6732C167.563 58.3466 170.242 57.8374 171.587 54.5728C173.105 50.8839 171.404 47.1642 167.441 45.5316Z"
      fill="#EAF9FF"
    />
    <path
      d="M159.987 63.4905C158.984 63.078 157.829 63.5586 157.414 64.5646C157.002 65.5693 157.483 66.7256 158.485 67.14C159.504 67.5466 160.655 67.0748 161.059 66.0658C161.472 65.0593 160.992 63.9053 159.987 63.4905Z"
      fill="#EAF9FF"
    />
  </svg>
);

const EMPTY_HIGHLIGHT_ICON = (
  <svg
    width="192"
    height="186"
    viewBox="0 0 192 186"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M54.741 20.5924L160.408 48.9058C168.285 51.0164 172.952 59.0995 170.841 66.9763L145.1 163.042C142.99 170.919 134.907 175.586 127.03 173.475L21.3628 145.162C13.4859 143.051 8.81918 134.968 10.9298 127.091L36.6705 31.0254C38.8061 23.1553 46.8892 18.4885 54.741 20.5924Z"
      fill="#FFEAAF"
    />
    <path
      d="M60.9044 123.809L129.332 142.144C134.23 143.456 137.131 148.481 135.819 153.379C134.506 158.277 129.481 161.179 124.583 159.866L47.2941 139.157L49.6686 130.296C50.981 125.398 56.0064 122.496 60.9044 123.809Z"
      fill="#FFF8DB"
    />
    <path
      d="M35.0607 116.884C39.9587 118.196 42.8601 123.222 41.5477 128.12C40.2353 133.018 35.2098 135.919 30.3118 134.607C25.4138 133.294 22.5124 128.269 23.8248 123.371C25.1372 118.473 30.1627 115.572 35.0607 116.884Z"
      fill="#FFCE4D"
    />
    <path
      d="M57.4835 33.1979L151.755 58.4579C156.653 59.7704 159.555 64.7958 158.242 69.6938L143.073 126.306C141.761 131.204 136.735 134.105 131.837 132.793L37.5655 107.533C32.6675 106.22 29.7661 101.195 31.0785 96.2969L46.2477 39.6849C47.5601 34.7869 52.5855 31.8855 57.4835 33.1979Z"
      fill="#FFCE4D"
    />
    <path
      d="M44.5547 96.2144L130.213 119.166"
      stroke="#E6A606"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61.0154 106.164C63.8023 106.91 66.666 105.257 67.4127 102.47C68.1595 99.6832 66.5062 96.8196 63.7192 96.0729C60.9323 95.3261 58.0687 96.9794 57.3219 99.7664C56.5752 102.553 58.2285 105.417 61.0154 106.164Z"
      fill="#FFEAAF"
    />
    <path
      d="M104.921 76.8014L94.2073 64.6954C93.3778 63.7636 91.951 63.6705 91.0157 64.502C90.6698 64.8028 90.4349 65.19 90.3231 65.6072L85.7129 82.8128C85.3894 84.0199 86.1102 85.2683 87.3173 85.5918C87.7345 85.7035 88.2096 85.6997 88.6208 85.5445L103.951 80.42C105.139 80.0257 105.753 78.7118 105.352 77.549C105.246 77.2581 105.101 77.0077 104.921 76.8014Z"
      fill="#805C00"
    />
    <g style={{ mixBlendMode: "multiply" }} opacity="0.8">
      <path
        d="M155.506 78.7815C166.194 83.1857 178.431 78.0793 182.837 67.3756C187.243 56.6718 182.151 44.4244 171.463 40.0203C160.774 35.6161 148.538 40.7225 144.131 51.4263C139.725 62.13 144.818 74.3774 155.506 78.7815Z"
        fill="#FFCE4D"
      />
    </g>
    <path
      d="M157.507 74.7835C168.195 79.1876 180.432 74.0813 184.838 63.3775C189.244 52.6737 184.152 40.4263 173.464 36.0222C162.775 31.6181 150.539 36.7244 146.132 47.4282C141.726 58.132 146.818 70.3794 157.507 74.7835Z"
      fill="#FFDC7F"
    />
    <path
      d="M169.47 44.6944L169.44 44.6803C167.358 43.8832 162.894 43.6445 160.906 48.4746C160.53 49.3854 160.961 50.4245 161.873 50.8004C162.782 51.1758 163.821 50.7419 164.197 49.8311C165.28 47.1988 167.48 47.7642 168.145 48.0023C170.241 48.8792 171.08 50.5444 170.326 52.3791C169.769 53.7263 169.011 53.984 167.153 54.3221C165.494 54.6195 162.978 55.0866 161.875 58.0252L161.68 58.5034C161.304 59.4142 161.735 60.4534 162.646 60.8292C163.547 61.2003 164.59 60.7814 164.987 59.8784L165.226 59.2981C165.592 58.3207 166.15 58.1392 167.799 57.8369L167.826 57.8359C169.593 57.5094 172.271 57.0002 173.616 53.7356C175.135 50.0467 173.434 46.327 169.47 44.6944Z"
      fill="#FFFCEE"
    />
    <path
      d="M162.016 62.6533C161.012 62.2408 159.858 62.7214 159.443 63.7274C159.03 64.7321 159.511 65.8884 160.514 66.3027C161.533 66.7094 162.683 66.2376 163.087 65.2286C163.5 64.2221 163.02 63.0681 162.016 62.6533Z"
      fill="#FFFCEE"
    />
  </svg>
);

export const EmptyInterviewState: React.FC = () => {
  return (
    <Flex
      alignItems="center"
      direction="column"
      justifyContent="center"
      alignSelf="center"
      maxW="500"
      mt="80px"
    >
      <Box width="100%">{EMPTY_INTERVIEW_ICON}</Box>
      <Box
        color="gray.900"
        width="100%"
        alignItems="center"
        justifyContent="center"
        mt="40px"
      >
        <Text as="p" mb="3" fontSize="2xl" fontWeight="600">
          No interviews to display
        </Text>
        <Text
          as="p"
          fontSize="sm"
          fontWeight="400"
          color="gray.600"
          lineHeight="20px"
          px={[3, 0]}
        >
          There are no recorded interviews to display for this candidate. If you
          believe is this is incorrect, verify that you have access to view
          them.
        </Text>
      </Box>
    </Flex>
  );
};

export const EmptyHighlightFilteredState: React.FC = () => (
  <Flex
    py="8"
    textAlign="center"
    bg="yellow.50"
    alignItems="center"
    justifyContent="center"
    flexDir="column"
    minHeight="520px"
  >
    {EMPTY_HIGHLIGHT_ICON}
    <Text
      as="p"
      fontSize="2xl"
      color="gray.900"
      fontWeight="600"
      lineHeight="32px"
      mt="10"
    >
      No highlights match your filter selections.
    </Text>
    <Text as="p" fontSize="sm" lineHeight="20px" color="gray.600" mt="3">
      Try changing or removing them.
    </Text>
  </Flex>
);

export const EmptyHighlightUnfilteredState: React.FC = () => (
  <Flex
    py="8"
    textAlign="center"
    bg="yellow.50"
    alignItems="center"
    justifyContent="center"
    flexDir="column"
    minHeight="520px"
  >
    {EMPTY_HIGHLIGHT_ICON}
    <Text
      as="p"
      fontSize="2xl"
      color="gray.900"
      fontWeight="600"
      lineHeight="32px"
      mt="10"
    >
      No highlights to display.
    </Text>
    <Text as="p" fontSize="sm" lineHeight="20px" color="gray.600" mt="3">
      There are no highlights to display for this candidate. If you believe this
      is incorrect, verify that you have access to view them.
    </Text>
  </Flex>
);
