import { Flex } from "@chakra-ui/react";
import LogRocket from "logrocket";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { LoadingIndicator } from "../../../components";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { useTrainingProgramTraineeListItemsQuery } from "../../graphql";
import DefaultLayout from "../../layouts/DefaultLayout";
import { TraineeEmptyState } from "./EmptyState";
import ErrorPage from "./ErrorPage";
import { viewTrainingRoute } from "./utils";
import ViewTrainingSidebar, {
  ViewTrainingProgramSidebarItem,
} from "./ViewTrainingSidebar";

/**
 * Landing page for the Training > View header navigation. Redirects
 * to a training program if one exists.
 */
const ViewTrainingRedirectPage: React.FC = () => {
  useEffect(() => LogRocket.track("training-trainee-page"), []);
  const navigate = useNavigate();
  const { data, loading, error } = useTrainingProgramTraineeListItemsQuery({
    pollInterval: 10000,
  });
  const { layoutHeight } = useWindowDimensions();
  const trainingPrograms: ViewTrainingProgramSidebarItem[] =
    data?.trainingProgramTraineeListItems || [];

  const trainingsInProgress = trainingPrograms.filter((p) => !p.completed);
  const completedTrainings = trainingPrograms.filter((p) => p.completed);
  useNavigateToFirstProgram(trainingsInProgress, completedTrainings);

  if (loading) {
    return (
      <DefaultLayout>
        <LoadingIndicator />
      </DefaultLayout>
    );
  }

  if (error) {
    return <ErrorPage title="Error loading training programs" error={error} />;
  }

  return (
    <DefaultLayout>
      <Flex height={layoutHeight} overflowX="auto">
        <ViewTrainingSidebar
          inProgress={trainingsInProgress}
          completed={completedTrainings}
          onClickProgram={(id: string) => navigate(viewTrainingRoute(id))}
        />
        <TraineeEmptyState />
      </Flex>
    </DefaultLayout>
  );
};

// This page routes to a specific training program if possible
const useNavigateToFirstProgram = (
  trainingPrograms: { id: string }[],
  completedTrainings: { id: string }[]
): void => {
  const navigate = useNavigate();
  useEffect(() => {
    if (trainingPrograms.length > 0) {
      navigate(viewTrainingRoute(trainingPrograms[0].id), {
        replace: true,
      });
    } else if (completedTrainings.length > 0) {
      navigate(viewTrainingRoute(completedTrainings[0].id), {
        replace: true,
      });
    }
  }, [trainingPrograms, completedTrainings]);
};

export default ViewTrainingRedirectPage;
