import {
  Box,
  Button,
  Checkbox,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";

import { CheckmarkIcon } from "../../../components/Icons/CheckmarkIcon";
import Sidebar, { SidebarItem } from "../../components/Sidebar";
import { Playlist } from "../../graphql";
import { FilterArrowDownIcon } from "../training/icons";

type MyPlaylistsPageSidebarProps = {
  playlistData: Pick<Playlist, "id" | "title">[];
  activeSort: string;
  setActiveSort: React.Dispatch<React.SetStateAction<string>>;
  goToPlaylist(playlistId: string): void;
  activePlaylistId?: string;
};

const MyPlaylistsPageSidebar: React.FC<MyPlaylistsPageSidebarProps> = ({
  playlistData,
  activeSort,
  setActiveSort,
  goToPlaylist,
  activePlaylistId,
}) => {
  const SORT_OPTIONS = [
    { key: "createdAt desc", title: "Newest" },
    { key: "createdAt asc", title: "Oldest" },
    { key: "title asc", title: "A-Z" },
    { key: "title desc", title: "Z-A" },
  ];

  return (
    <Sidebar data-tour-id="my-playlists-sidebar" minW="220px" flexShrink="1">
      <Flex
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        px="8"
        mt="7"
        mb="2"
      >
        <Text fontSize="xs" fontWeight="700" color="gray.400" py="1">
          My Playlists
        </Text>
        <Popover placement="bottom-end">
          <PopoverTrigger>
            <Button
              variant="ghost"
              fontSize="xs"
              color="blue.600"
              fontWeight="500"
              py="1"
              px="2"
              _hover={{ bg: "gray.50" }}
              height="28px"
              disabled={playlistData.length === 0}
              _disabled={{ bg: "white", color: "gray.200" }}
            >
              <FilterArrowDownIcon width="24px" height="24px" />
            </Button>
          </PopoverTrigger>
          <PopoverContent
            _focus={{ outline: 0 }}
            width="200px"
            py="3"
            px="4"
            borderRadius="6px"
          >
            <VStack align="flex-start">
              <Text fontSize="xs" color="gray.400" fontWeight="500">
                Sort
              </Text>
              {SORT_OPTIONS.map((sortOption) => {
                return (
                  <Checkbox
                    key={sortOption.key}
                    spacing="12px"
                    isChecked={activeSort === sortOption.key}
                    onChange={() => setActiveSort(sortOption.key)}
                    icon={<CheckmarkIcon />}
                    variant="checkOnly"
                    width="100%"
                    pr={2}
                  >
                    <Text fontWeight="400" fontSize="sm" color="gray.900">
                      {sortOption.title}
                    </Text>
                  </Checkbox>
                );
              })}
            </VStack>
          </PopoverContent>
        </Popover>
      </Flex>
      <Box px="4" width="100%">
        {playlistData.length === 0 ? (
          <Flex color="gray.600" m="4" fontWeight="400" fontSize="14px">
            No playlists
          </Flex>
        ) : null}
        {playlistData.map((playlist) => (
          <SidebarItem
            key={playlist.id}
            text={playlist.title || "Playlist that must not be named"}
            active={playlist.id === activePlaylistId}
            onClick={() => goToPlaylist(playlist.id)}
            maxW="436px"
          />
        ))}
      </Box>
    </Sidebar>
  );
};

export default MyPlaylistsPageSidebar;
