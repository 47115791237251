import {
  IconButton,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import { MdPeople, MdVisibility, MdVisibilityOff } from "react-icons/md";

import { CallNoteVisibility } from "../../graphql";

interface VisibilityMenuProps {
  visibility: CallNoteVisibility;
  onChange: (visibility: CallNoteVisibility) => void;
}

const VisibilityMenu = React.forwardRef<HTMLDivElement, VisibilityMenuProps>(
  ({ visibility, onChange }, ref) => {
    return (
      <Menu placement="left-start" computePositionOnMount strategy="fixed">
        <Tooltip label="Visibility">
          <MenuButton
            as={IconButton}
            aria-label="visibility-menu"
            icon={
              visibility === CallNoteVisibility.Public ? (
                <MdVisibility />
              ) : visibility === CallNoteVisibility.CallOwner ? (
                <MdPeople />
              ) : (
                <MdVisibilityOff />
              )
            }
            data-testid="call-note-visibility"
          />
        </Tooltip>
        <MenuList>
          <MenuOptionGroup
            value={visibility}
            title="Visibility"
            type="radio"
            onChange={(value) => onChange(value as CallNoteVisibility)}
          >
            <MenuItemOption
              value={CallNoteVisibility.Public}
              data-testid="visibility-public"
            >
              Hiring team
            </MenuItemOption>
            <MenuItemOption
              value={CallNoteVisibility.CallOwner}
              data-testid="visibility-callowner"
            >
              Interviewers
            </MenuItemOption>
            <MenuItemOption
              value={CallNoteVisibility.Private}
              data-testid="visibility-private"
            >
              Only you
            </MenuItemOption>
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    );
  }
);

export default VisibilityMenu;
