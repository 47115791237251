import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Spacer,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { GrDrag } from "react-icons/gr";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";
import { MdAddCircleOutline } from "react-icons/md";

import { Alert } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import {
  CallGuideDocument,
  CallGuideQuery,
  useUpdateCallGuideCueMutation,
} from "../../graphql";
import AddQuestion from "./AddQuestion";
import DeleteCueDialog from "./DeleteCueDialog";
import EditQuestion from "./EditQuestion";

type CallGuide = NonNullable<CallGuideQuery["callGuide"]>;
type Cue = CallGuide["cues"][number];

interface QuestionListProps {
  callGuide: CallGuide;
  readOnly: boolean;
}

const QuestionList: React.FC<QuestionListProps> = ({ callGuide, readOnly }) => {
  const [editingCue, setEditingCue] = useState<Cue | null>(null);
  const [cueToDelete, setCueToDelete] = useState<Cue | null>(null);
  const [cueList, setCueList] = useState<Cue[]>([]);
  const [showAddQuestionForm, setShowAddQuestionForm] = useState(false);
  const sendGAEvent = useSendGAEvent();

  useMemo(() => {
    setCueList(callGuide.cues);
  }, [callGuide.cues]);

  const [updateCue, { error: updateCueError, client }] =
    useUpdateCallGuideCueMutation();

  const handleMove = useCallback(
    (fromIndex: number, toIdx: number): void => {
      if (!callGuide) return;
      const cue = callGuide.cues[fromIndex];
      if (!cue) return;
      if (toIdx >= callGuide.cues.length) return;

      let toIndex = toIdx;
      const movingDown = toIndex >= fromIndex;
      if (movingDown) toIndex += 1;
      sendGAEvent("cue", "call_guides", "move");

      updateCue({
        variables: {
          id: cue.id,
          index: toIndex,
        },
      });

      const updatedCallGuide = {
        ...callGuide,
      };
      const cues = [] as Array<Cue>;

      for (let i = 0; i < callGuide.cues.length; i += 1) {
        // eslint-disable-next-line
        if (i === fromIndex) continue;
        if (i === toIndex) {
          cues.push(callGuide.cues[fromIndex]);
        }
        cues.push(callGuide.cues[i]);
      }
      if (toIndex === callGuide.cues.length) {
        cues.push(callGuide.cues[fromIndex]);
      }
      updatedCallGuide.cues = cues;
      setCueList(cues);

      client?.cache.writeQuery<CallGuideQuery>({
        query: CallGuideDocument,
        variables: { id: callGuide.id },
        data: {
          callGuide: updatedCallGuide,
        },
      });
    },
    [callGuide, client?.cache, updateCue]
  );

  return (
    <>
      {cueToDelete && (
        <DeleteCueDialog
          cue={cueToDelete}
          callGuideId={callGuide.id}
          onClose={() => {
            setCueToDelete(null);
          }}
        />
      )}
      {updateCueError && (
        <Alert status="error" description="Error reordering questions" reload />
      )}

      <DragDropContext
        onDragEnd={(result) => {
          if (!result.destination) {
            return;
          }
          handleMove(result.source.index, result.destination.index);
        }}
      >
        <Droppable droppableId="droppable">
          {(provided) => (
            // eslint-disable-next-line @typescript-eslint/unbound-method
            <Box
              maxWidth="930px"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {cueList.map((cue, index) => (
                <Draggable
                  key={cue.id}
                  draggableId={cue.id}
                  index={index}
                  isDragDisabled={readOnly || cueList.length < 2}
                  data-testid={`cue-item-${index}`}
                >
                  {(provided) => (
                    <Flex
                      data-testid="cue-item"
                      alignItems="flex-start"
                      borderRadius="4px"
                      mb={2}
                      pr={4}
                      backgroundColor="white"
                      // eslint-disable-next-line @typescript-eslint/unbound-method
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <Flex
                        {...provided.dragHandleProps}
                        mr="0.5rem"
                        mt="2"
                        height={8}
                        width={8}
                        alignItems="center"
                        justifyContent="center"
                        data-testid={`drag-handle-${index}`}
                        visibility={
                          readOnly || cueList.length < 2 ? "hidden" : "visible"
                        }
                      >
                        <GrDrag />
                      </Flex>
                      <Box flex={1} mr="auto">
                        {editingCue === cue ? (
                          <EditQuestion
                            cue={cue}
                            onCompleted={() => {
                              setEditingCue(null);
                            }}
                            onCancel={() => {
                              setEditingCue(null);
                            }}
                          />
                        ) : (
                          <>
                            <Text
                              flex={1}
                              my={3}
                              fontSize="14px"
                              fontWeight="600"
                              data-testid={`cue-item-${index}-cue`}
                            >
                              {cue?.cue}
                            </Text>
                            <Text
                              whiteSpace="pre-wrap"
                              flex={1}
                              my={3}
                              fontSize="14px"
                            >
                              {cue.description}
                            </Text>
                          </>
                        )}
                      </Box>
                      {!readOnly && editingCue !== cue && (
                        <ButtonGroup variant="outline" mb="auto">
                          <Tooltip label="Edit question">
                            <IconButton
                              data-testid={`edit-question-${index}`}
                              aria-label="edit question"
                              variant="nostyle"
                              icon={<HiOutlinePencil />}
                              onClick={() => {
                                setEditingCue(cue);
                                setShowAddQuestionForm(false);
                              }}
                              _hover={{
                                bgColor: "gray.50",
                                borderRadius: "2px",
                              }}
                            />
                          </Tooltip>
                          <Tooltip label="Delete question">
                            <IconButton
                              data-testid={`delete-question-${index}`}
                              aria-label="delete question"
                              variant="nostyle"
                              icon={<HiOutlineTrash />}
                              onClick={() => {
                                setCueToDelete(cue);
                              }}
                              _hover={{
                                bgColor: "gray.50",
                                borderRadius: "2px",
                              }}
                            />
                          </Tooltip>
                        </ButtonGroup>
                      )}
                    </Flex>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              {(showAddQuestionForm || callGuide.cues.length === 0) && (
                <Flex
                  alignItems="flex-start"
                  borderRadius="4px"
                  pr={4}
                  backgroundColor="white"
                >
                  <Box ml={9} flex={1} mr="auto">
                    <AddQuestion
                      onlyQuestion={!cueList.length}
                      callGuide={callGuide}
                      onCompleted={() => {
                        setShowAddQuestionForm(false);
                      }}
                      onCancel={() => {
                        setShowAddQuestionForm(false);
                      }}
                    />
                  </Box>
                </Flex>
              )}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      {!showAddQuestionForm && !readOnly && callGuide.cues.length > 0 && (
        <Button
          data-testid="Add Question"
          size="sm"
          mt={4}
          width="100%"
          height="52px"
          backgroundColor="blue.100"
          justifyContent="flex-start"
          borderStyle="dashed"
          variant="outline"
          leftIcon={<MdAddCircleOutline />}
          onClick={() => {
            setEditingCue(null);
            setShowAddQuestionForm(true);
          }}
        >
          Add question
        </Button>
      )}
      <Spacer pb={8} />
    </>
  );
};

export default QuestionList;
