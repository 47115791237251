import { Box, Flex, Heading, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";

import { RouterLink } from "../../../../components";
import { formatDuration } from "../../../../utils/datetime";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { Clip } from "../../../graphql";
import AddToTrainingProgramButton from "../../AddToTrainingProgramButton/AddToTrainingProgramButton";
import { clipLengthLabel } from "../../Interview/Clip/utils";
import {
  InterviewsListRight,
  InterviewsListRow,
  InterviewsListTitle,
  LIST_ITEM_FLEX_FULL,
  THUMBNAIL_MR,
} from "../../InterviewsList";
import { Thumbnail } from "../../Thumbnail";
import ClipListItemMoreMenu from "./ClipListItemMoreMenu";
import ClipVisibility from "./ClipVisibility";

interface ClipListItemProps {
  clip: Pick<
    Clip,
    | "id"
    | "name"
    | "callId"
    | "startTime"
    | "endTime"
    | "thumbnailImageUrl"
    | "canEdit"
    | "canDelete"
    | "visibility"
    | "visibleVisibilityLevels"
  >;
  callId: string;
  positionId?: string;
  listLength: number;
  listPosition: number;
  isLastItem?: boolean;
  readOnly: boolean;
  isShareModeEnabled?: boolean;
  updateClipId?: (clipId: string) => void;
  clipShareOrder?: number;
}

const ClipListItem: React.FC<ClipListItemProps> = ({
  clip,
  callId,
  positionId,
  listLength,
  listPosition,
  isLastItem,
  readOnly,
  isShareModeEnabled = false,
  updateClipId,
  clipShareOrder,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const sendGAEvent = useSendGAEvent();
  const linkTo = `/interview/${clip.callId}/clip/${clip.id}`;
  const trackNav = (): void =>
    sendGAEvent("clip_nav_from_sidebar", "call_review");

  const handleParentClick = (): void => {
    if (isShareModeEnabled && updateClipId) {
      updateClipId(clip.id);
    }
  };

  let background = "white";
  if (isShareModeEnabled) {
    if (isHovering) {
      background = "blue.50";
    } else if (clipShareOrder) {
      background = "gray.50";
    }
  }
  return (
    <VStack
      align="flex-start"
      alignItems="flex-start"
      borderBottom={!isLastItem ? "gray.100" : "0"}
      px="6"
      onClick={handleParentClick}
      cursor={isShareModeEnabled ? "pointer" : "default"}
      background={background}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      data-testid={`clip-list-item-${listPosition}`}
    >
      <InterviewsListRow
        listLength={listLength}
        listPosition={listPosition}
        showDivider={false}
        showPadding={false}
        alignItems={isShareModeEnabled ? "center" : "flex-start"}
        fullWidth
      >
        <Flex
          flexFlow="row nowrap"
          flex={LIST_ITEM_FLEX_FULL}
          position="relative"
          minHeight={{ base: "80px", lg: "auto" }}
        >
          {
            // the wrapper <Box /> is here so that layout doesn't break
            // see <InterviewsListTitle /> left margin styles
          }
          <Box position={{ base: "absolute", lg: "relative" }}>
            <Thumbnail
              size="sm"
              mr={THUMBNAIL_MR}
              imageUrl={clip.thumbnailImageUrl}
              audioOnly={!clip.thumbnailImageUrl}
              isDisabled={isShareModeEnabled}
              isClip
              onClick={() => {
                if (!isShareModeEnabled) {
                  window.open(linkTo, "_blank");
                  trackNav();
                }
              }}
              time={clip.endTime - clip.startTime}
            />
          </Box>
          <InterviewsListTitle parent="completed">
            <RouterLink
              to={linkTo}
              target="_blank"
              onClick={trackNav}
              isDisabled={isShareModeEnabled}
              _disabled={{ color: "gray.900" }}
            >
              <Heading
                as="h6"
                size="xs"
                mt="1"
                top="-0.28em"
                wordBreak="break-word"
                position="relative"
                lineHeight="150%"
                color="gray.900"
                fontWeight="500"
              >
                {clip.name
                  ? clip.name
                  : `${clipLengthLabel(
                      clip.startTime,
                      clip.endTime,
                      false
                    )} clip`}
              </Heading>
            </RouterLink>
            <Text fontSize="sm" lineHeight="150%" color="gray.700">
              {formatDuration(clip.startTime)} - {formatDuration(clip.endTime)}
            </Text>
          </InterviewsListTitle>
        </Flex>
        {isShareModeEnabled ? (
          <Box
            borderRadius="50%"
            border="3px solid"
            borderColor={clipShareOrder ? "blue.300" : "gray.200"}
            color={clipShareOrder ? "blue.700" : "gray.600"}
            backgroundColor={clipShareOrder ? "blue.100" : ""}
            fontSize="md"
            height="30px"
            fontWeight="400"
            flex="0 0 30px"
            textAlign="center"
          >
            {clipShareOrder || ""}
          </Box>
        ) : null}
        {!isShareModeEnabled && (
          <InterviewsListRight marginLeft="140px">
            <Flex mr="2">
              <AddToTrainingProgramButton
                callId={callId}
                clipIds={[clip?.id]}
                size="sm"
              />
            </Flex>
            <ClipVisibility clipVisibility={clip.visibility} mr="1" />
            <ClipListItemMoreMenu
              clip={clip}
              listPosition={listPosition}
              positionId={positionId}
              readOnly={readOnly}
            />
          </InterviewsListRight>
        )}
      </InterviewsListRow>
    </VStack>
  );
};

export default ClipListItem;
