import { ApolloCache } from "@apollo/client";
import { Link, Text } from "@chakra-ui/react";
import * as Sentry from "@sentry/browser";
import invariant from "invariant";
import React, { useState } from "react";

import { errorToast, successToast, useToast } from "../../../components";
import {
  ExternalPlaylistShare,
  ExternalPlaylistShareFragmentDoc,
  Playlist,
  PlaylistShare,
  PlaylistShareFragmentDoc,
  PlaylistSidebarItemFragment,
  PlaylistSidebarItemFragmentDoc,
  useCallShareLazyQuery,
  useCreateAndSharePlaylistExternallyMutation,
  useCreateAndSharePlaylistMutation,
  useCreateExternalPlaylistShareMutation,
  useCreatePlaylistShareMutation,
  usePlaylistSharesLazyQuery,
  useRemoveExternalPlaylistShareMutation,
  useRemovePlaylistShareMutation,
  useRenewExternalPlaylistShareMutation,
} from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";
import { Share, ShareModalProps } from "../ShareModal/types";

export interface UseSharePlaylistModalParams {
  playlistId?: string;
  callId: string;
  clipIds?: string[];
  onClose(): void;
  onShare?(): void;
}

export type UseSharePlaylistModal = Omit<ShareModalProps, "onClose"> & {
  getShareData(): Promise<any>;
  hasSelection: boolean;
  loading: boolean;
};

/**
 * This hook takes either a playlistId for an existing playlist,
 * or a callId + clipIds used to create + share a playlist.
 *
 * Like useCallShareModal() amd useClipShareModal(), this hook sets up
 * all of the queries and mutations required to run the share modal for a
 * playlist and returns them as props that can be passed to the share modal
 */
export function useSharePlaylistModal({
  playlistId,
  callId,
  clipIds,
  onClose,
  onShare,
}: UseSharePlaylistModalParams): UseSharePlaylistModal {
  invariant(
    playlistId || (callId && !!clipIds),
    "SharePlaylistModal requires either playlistId or (callId and non-empty clipIds)"
  );

  const currentUser = useCurrentUser();
  const [hasSelection, setHasSelection] = useState(false);
  const toast = useWrappedToast();

  const [getCallShareData, callShareQuery] = useCallShareLazyQuery({
    variables: { id: callId, forPlaylist: true },
    onError: (err) => {
      Sentry.captureException(err);
      onClose();
      toast.error(`There was a problem sharing this playlist`);
    },
  });
  const [getPlaylistShareData, playlistShareQuery] = usePlaylistSharesLazyQuery(
    {
      onError(err) {
        Sentry.captureException(err);
        onClose();
        toast.error(`There was a problem sharing this playlist`);
      },
    }
  );
  const getShareData = (): Promise<any> => {
    const fetches: Promise<any>[] = [getCallShareData()];
    if (playlistId) {
      fetches.push(getPlaylistShareData({ variables: { playlistId } }));
    }
    return Promise.all(fetches);
  };

  const {
    canShare,
    canShareExternal,
    name: callName,
    candidate,
    shareableUsers,
  } = callShareQuery.data?.call ?? { canShare: false };

  const playlistShareData = playlistShareQuery.data?.playlist;
  const internalShares: Share[] = playlistShareData?.shares || [];
  const externalShares: Share[] = playlistShareData?.externalShares || [];
  const sharedWithUnique = externalShares
    .concat(internalShares)
    .filter(
      (share, index, shares) =>
        shares.findIndex((s) => s.sharedTo?.id === share.sharedTo?.id) === index
    );

  const cacheKey = { __typename: "Playlist", id: playlistId };

  const addNewSharesToCache = (opts: {
    cache: ApolloCache<any>;
    shares: any[];
    external?: boolean;
  }): void => {
    const { cache, shares, external } = opts;

    const field: keyof Playlist = external ? "externalShares" : "shares";
    const fragmentName = external ? "ExternalPlaylistShare" : "PlaylistShare";
    const fragment = external
      ? ExternalPlaylistShareFragmentDoc
      : PlaylistShareFragmentDoc;

    cache.modify({
      id: cache.identify(cacheKey),
      fields: {
        [field]: (val) => {
          const newShares = shares.map((share) =>
            cache.writeFragment({
              fragment,
              fragmentName,
              data: share,
            })
          );

          return [...val, ...newShares];
        },
      },
    });
  };

  const removeShareFromCache = (opts: {
    cache: ApolloCache<any>;
    shareId: string;
    external?: boolean;
  }): void => {
    const { cache, shareId, external } = opts;
    const field: keyof Playlist = external ? "externalShares" : "shares";

    cache.modify({
      id: cache.identify(cacheKey),
      fields: {
        [field]: (
          existing: (ExternalPlaylistShare | PlaylistShare)[],
          { readField }
        ) => existing.filter((share) => shareId !== readField("id", share)),
      },
    });
  };

  const addPlaylistToSidebarCache = (opts: {
    cache: ApolloCache<any>;
    playlist: PlaylistSidebarItemFragment;
  }): void => {
    const { cache, playlist } = opts;
    cache.modify({
      id: cache.identify({ __typename: "User", id: currentUser.id }),
      fields: {
        playlists: ({ results, ...existing }) => ({
          ...existing,
          results: [
            cache.writeFragment({
              data: playlist,
              fragment: PlaylistSidebarItemFragmentDoc,
            }),
            ...results,
          ],
        }),
      },
    });
  };

  const [createAndSharePlaylist, createAndShareMutation] =
    useCreateAndSharePlaylistMutation({
      update(cache, { data }) {
        const playlist = data?.createAndSharePlaylist?.playlist;
        if (playlist) {
          addPlaylistToSidebarCache({ cache, playlist });
        }
      },
      onCompleted(data) {
        if (data.createAndSharePlaylist) {
          toast.success(
            "Playlist created & shared.",
            data.createAndSharePlaylist.playlist.id
          );
          onClose();
          onShare?.();
        }
      },
      onError(err) {
        Sentry.captureException(err);
        toast.error("There was a problem sharing this playlist");
      },
    });

  const [sharePlaylist, shareMutation] = useCreatePlaylistShareMutation({
    onCompleted(data) {
      if (data.sharePlaylist?.playlistShares) {
        toast.success("Playlist shared.");
        onClose();
        onShare?.();
      }
    },
    onError(err) {
      Sentry.captureException(err);
      toast.error("There was a problem sharing this playlist");
    },
    update(cache, { data }) {
      if (data?.sharePlaylist?.playlistShares) {
        const shares = data.sharePlaylist.playlistShares;
        addNewSharesToCache({ cache, shares });
      }
    },
  });

  const [removeShare, { loading: removeShareLoading }] =
    useRemovePlaylistShareMutation({
      update(cache, { data }) {
        const shareId = data?.removePlaylistShare?.playlistShare.id;
        if (shareId) {
          removeShareFromCache({ cache, shareId });
        }
      },
      onError(err) {
        Sentry.captureException(err);
        toast.error("There was a problem removing this share");
      },
      onCompleted(data) {
        if (data.removePlaylistShare) {
          toast.success("Removed share");
        }
      },
    });

  const [createAndSharePlaylistExternally, createAndShareExternallyMutation] =
    useCreateAndSharePlaylistExternallyMutation({
      update(cache, { data }) {
        const playlist = data?.createAndSharePlaylistExternally?.playlist;
        if (playlist) {
          addPlaylistToSidebarCache({ cache, playlist });
        }
      },
      onCompleted(data) {
        if (data.createAndSharePlaylistExternally) {
          toast.success(
            "Playlist created & shared externally.",
            data.createAndSharePlaylistExternally.playlist.id
          );
          onClose();
          onShare?.();
        }
      },
      onError(err) {
        Sentry.captureException(err);
        toast.error("There was a problem sharing this playlist externally");
      },
    });

  const [sharePlaylistExternally, shareExternallyMutation] =
    useCreateExternalPlaylistShareMutation({
      onCompleted(data) {
        if (data.sharePlaylistExternally?.externalPlaylistShares) {
          toast.success("Playlist shared externally.");
          onClose();
          onShare?.();
        }
      },
      onError(err) {
        Sentry.captureException(err);
        toast.error("There was a problem sharing this playlist externally");
      },
      update(cache, { data }) {
        if (data?.sharePlaylistExternally?.externalPlaylistShares) {
          const shares = data.sharePlaylistExternally.externalPlaylistShares;
          addNewSharesToCache({ cache, shares, external: true });
        }
      },
    });

  const [renewExternalShare, { loading: renewExternalShareLoading }] =
    useRenewExternalPlaylistShareMutation({
      onError(err) {
        Sentry.captureException(err);
        toast.error("There was a problem renewing this share");
      },
      onCompleted(data) {
        if (data.renewExternalPlaylistShare) {
          toast.success("Successfully renewed external share");
        }
      },
    });

  const [removeExternalShare, { loading: removeExternalShareLoading }] =
    useRemoveExternalPlaylistShareMutation({
      update(cache, { data }) {
        const shareId = data?.removeExternalPlaylistShare?.playlistShare.id;
        if (shareId) {
          removeShareFromCache({ cache, shareId, external: true });
        }
      },
      onError(err) {
        Sentry.captureException(err);
        toast.error("There was a problem removing this external share");
      },
      onCompleted(data) {
        if (data.removeExternalPlaylistShare) {
          toast.success("Removed external share");
        }
      },
    });

  const getDefaultPlaylistTitle = (): string => {
    if (callName) {
      return `${callName} Playlist`;
    }
    if (candidate?.fullName) {
      return `Interview with ${candidate?.fullName} Playlist`;
    }
    if (clipIds) {
      return `${clipIds.length} Clip Playlist`;
    }
    return "Playlist";
  };

  return {
    includeTitleInput: !playlistId,
    defaultTitle: getDefaultPlaylistTitle(),
    modalTitle: "Share Playlist",
    optionsLoading: callShareQuery.loading,
    shareableUsers,
    onSelection: (selection) => setHasSelection(!!selection.length),
    canShare,
    onShare(shareToUserIds, shareMessage, title) {
      if (clipIds) {
        createAndSharePlaylist({
          variables: {
            clipIds,
            shareMessage,
            shareToUserIds,
            title,
            description: "",
          },
        });
      } else if (playlistId) {
        sharePlaylist({
          variables: { playlistId, shareMessage, shareToUserIds },
        });
      }
    },
    removeShare(share) {
      if (!removeShareLoading) {
        removeShare({ variables: { shareId: share.id } });
      }
    },

    canShareExternal,
    onShareExternal(shareToEmails, shareMessage, title) {
      if (clipIds) {
        createAndSharePlaylistExternally({
          variables: {
            clipIds,
            shareMessage,
            shareToEmails,
            title,
            description: "",
          },
        });
      } else if (playlistId) {
        sharePlaylistExternally({
          variables: { playlistId, shareMessage, shareToEmails },
        });
      }
    },
    renewExternalShare({ id }) {
      if (!renewExternalShareLoading) {
        renewExternalShare({ variables: { id } });
      }
    },
    async removeExternalShare(share) {
      if (!removeExternalShareLoading) {
        await removeExternalShare({ variables: { shareId: share.id } });
      }
    },

    shareLoading:
      shareMutation.loading ||
      shareExternallyMutation.loading ||
      createAndShareMutation.loading ||
      createAndShareExternallyMutation.loading,

    getShareData,
    hasSelection,
    loading: callShareQuery.loading || playlistShareQuery.loading,

    sharedWith: sharedWithUnique,
    externalShareDuration:
      currentUser.organization.externalShareDefaultDurationDays,
  };
}

function useWrappedToast(): {
  error(msg?: string): void;
  success(text: string, playlistId?: string): void;
} {
  const toast = useToast();

  return {
    error(msg) {
      errorToast(toast, msg);
    },

    success(text, playlistId) {
      successToast(
        toast,
        <Text>
          {`${text} `}
          {playlistId && (
            <Link
              variant="white"
              fontWeight="semibold"
              href={`/playlist/${playlistId}`}
              target="_blank"
            >
              View playlist
            </Link>
          )}
        </Text>,
        { duration: 7_000, isClosable: true }
      );
    },
  };
}
