import { Button, useDisclosure } from "@chakra-ui/react";
import React from "react";

import { ConfirmModal, useToast } from "../../../components";
import { useSetMembershipsMutation } from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";

interface Membership {
  role: {
    id: string;
  };
  user: {
    id: string;
  };
  position: {
    id: string;
  };
}

interface RemoveUserFromHiringTeamButtonProps {
  membership: Membership;
}

const RemoveUserFromHiringTeamButton: React.FC<
  RemoveUserFromHiringTeamButtonProps
> = ({ membership }) => {
  const currentUser = useCurrentUser();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [setMemberships] = useSetMembershipsMutation({
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
    onCompleted: (data) => {
      if (data.setMemberships) {
        toast({
          title: "Success",
          description: "Removed from Hiring Team",
          status: "success",
        });
      }
    },
  });

  const onSubmit = (): void => {
    setMemberships({
      variables: {
        users: [
          {
            userId: membership.user.id,
            positionId: membership.position.id,
            positionRoleId: null,
          },
        ],
      },
    });
    onClose();
  };

  const currentUserMembership = currentUser.memberships.find((m) => {
    return m.position.id === membership.position.id;
  });
  const enabled =
    currentUser.userRole?.canManageHiringTeam ||
    (membership.user.id !== currentUser.id &&
      currentUserMembership?.role?.canManageHiringTeam);

  return (
    <>
      <Button
        size="xs"
        variant="dangerOutline"
        onClick={onOpen}
        disabled={!enabled}
      >
        Remove
      </Button>
      <ConfirmModal
        modalBodyText="Are you sure you want to remove this user from the hiring team?"
        isOpen={isOpen}
        onCancel={onClose}
        onConfirm={onSubmit}
      />
    </>
  );
};

export default RemoveUserFromHiringTeamButton;
