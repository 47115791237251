import React, { useEffect, useState } from "react";

import { useToast } from "../../../components";
import { PlanUserRole, useSetPlanUserRolesMutation } from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";
import RoleSelect from "../RoleSelect";

interface PlanUserRoleSelectProps {
  userId: string;
  defaultValue: string;
  showDescription?: boolean;
}

const PlanUserRoleSelect: React.FC<PlanUserRoleSelectProps> = ({
  userId,
  defaultValue,
  showDescription = false,
}) => {
  const currentUser = useCurrentUser();
  const toast = useToast();
  const [planUserRole, setPlanUserRole] =
    useState<
      Pick<PlanUserRole, "id" | "name" | "permissions" | "formattedPermissions">
    >();

  const [setUserRoles] = useSetPlanUserRolesMutation({
    onCompleted: (data) => {
      if (data.setPlanUserRoles) {
        toast({
          title: "Success",
          description: "Updated Plan user role",
          status: "success",
        });
      }
    },
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
  });

  const { planUserRoles } = currentUser.organization;
  const roleOptions = [
    {
      id: "",
      name: "NO_ACCESS",
      formattedPermissions: "No access to BrightHire Plan",
      formattedName: "No access",
    },
    ...planUserRoles,
  ];

  const canModify =
    userId !== currentUser.id && currentUser.planUserRole?.canManageUserRoles;

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setPlanUserRole(planUserRoles?.find((u) => u.id === e.target.value));
    setUserRoles({
      variables: {
        users: [
          {
            userId,
            planUserRoleId: e.target.value === "" ? null : e.target.value,
          },
        ],
      },
    });
  };

  useEffect(() => {
    if (!planUserRole) {
      setPlanUserRole(planUserRoles?.find((r) => r.id === defaultValue));
    }
  }, [planUserRoles]);

  return (
    <RoleSelect
      userRole={planUserRole}
      formId={`plan-user-role-select-${userId}`}
      showDescription={showDescription}
      onChange={onChange}
      defaultValue={defaultValue}
      userRoles={roleOptions}
      canModify={canModify}
      selectProps={{ "data-testid": "plan-user-role-select" }}
    />
  );
};

export default PlanUserRoleSelect;
