import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

export const AudioImage: React.FC<IconProps> = (props) => {
  return (
    <Icon
      boxSize="100%"
      viewBox="0 0 256 144"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="white"
      strokeOpacity="0.36"
      strokeWidth="8"
      strokeLinecap="round"
      {...props}
    >
      <path d="M28 64V80" />
      <path d="M48 60V84" />
      <path d="M68 48V96" />
      <path d="M88 20V124" />
      <path d="M108 40V104" />
      <path d="M128 56V88" />
      <path d="M148 48V96" />
      <path d="M168 32V112" />
      <path d="M188 56V88" />
      <path d="M208 44V100" />
      <path d="M228 64V80" />
    </Icon>
  );
};
