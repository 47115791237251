import { Flex, Image } from "@chakra-ui/react";
import React from "react";

const EmptyState: React.FC<{ text?: string }> = ({ text }) => {
  return (
    <Flex m="auto" flexDirection="column" alignItems="center" color="gray.600">
      <Flex>
        <Image src="/static/images/cactus.png" />
      </Flex>
      <Flex>It&apos;s looking a bit empty here.</Flex>
      <Flex>
        {text ||
          "Create a playlist by going to an interview page and sharing a few clips."}
      </Flex>
    </Flex>
  );
};

export default EmptyState;
