import { useEffect } from "react";
import { isMobile } from "react-device-detect";

import config from "../config";
import { CurrentUserFragment as CurrentInterviewUserFragmentMain } from "../main/graphql";
import useUpdateCurrentUserMain from "../main/graphql/hooks/useUpdateCurrentUser";
import { CurrentPlanUserFragment } from "../plan/graphql";
import useUpdateCurrentUserPlan from "../plan/graphql/hooks/useUpdateCurrentUser";
import { checkCookieExistence } from "../utils/cookie";
import { getVersion } from "../utils/extension";
import useCacheState from "./useCacheState";

function useBrowserExtensionTracking(
  updateCurrentUserHook:
    | typeof useUpdateCurrentUserMain
    | typeof useUpdateCurrentUserPlan,
  currentUser?:
    | CurrentInterviewUserFragmentMain
    | CurrentPlanUserFragment
    | null
): string | null {
  const [browserExtensionVersion, setBrowserExtensionVersion] = useCacheState<
    string | null
  >("browserExtensionVersion", currentUser?.browserExtensionVersion ?? null);

  const [updateCurrentUser] = updateCurrentUserHook({
    onCompleted: (currentUser) => {
      if (currentUser.browserExtensionVersion) {
        setBrowserExtensionVersion(currentUser.browserExtensionVersion);
      }
    },
  });

  const checkVersion = (): void => {
    getVersion().then((browserExtensionVersion) => {
      if (currentUser?.isImpersonated || isMobile) return;
      // eslint-disable-next-line eqeqeq
      if (browserExtensionVersion != currentUser?.browserExtensionVersion) {
        updateCurrentUser({
          variables: { browserExtensionVersion: browserExtensionVersion ?? "" },
        });
      }
    });
  };

  const isImpersonating = checkCookieExistence("is_impersonating");
  useEffect(() => {
    if (!currentUser || config.appEnv === "test" || isImpersonating) {
      return;
    }
    checkVersion();
    const id = setInterval(checkVersion, 30000);
    return () => clearInterval(id);
  }, [currentUser, isImpersonating]);

  return browserExtensionVersion;
}

export default useBrowserExtensionTracking;
