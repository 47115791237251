import { Box, Flex, Icon, Tooltip } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { HiOutlineStar, HiStar } from "react-icons/hi2";

export interface RatingDescriptions {
  oneStar: string;
  twoStar: string;
  threeStar: string;
  fourStar: string;
  fiveStar: string;
}

type ColorPalette = {
  /** Shown when no rating + not hovering */
  default?: string;
  /** Shown when hovering + editable */
  active?: string;
  /** Shown when filled or readonly */
  filled?: string;
};

const formatLabel = (
  rating: number,
  ratingDescriptions: RatingDescriptions
): string => {
  switch (rating) {
    case 1:
      return ratingDescriptions.oneStar;
    case 2:
      return ratingDescriptions.twoStar;
    case 3:
      return ratingDescriptions.threeStar;
    case 4:
      return ratingDescriptions.fourStar;
    case 5:
      return ratingDescriptions.fiveStar;
    default:
      return `${rating} Stars`;
  }
};

export type StarRatingProps = {
  rating?: number;
  maxRating?: number;
  size?: number;
  readOnly?: boolean;
  onChange?: (rating: number) => void;
  ratingDescriptions: RatingDescriptions;
  iconMargin?: string;
  colors?: ColorPalette;
};

const StarRating = React.forwardRef<HTMLDivElement, StarRatingProps>(
  (
    {
      rating = 0,
      maxRating = 5,
      size = 5,
      readOnly = false,
      onChange,
      ratingDescriptions,
      iconMargin = "0",
      ...props
    },
    ref
  ) => {
    const defaultFilledIndex = rating - 1;
    const [filledIndex, setFilledIndex] = useState(defaultFilledIndex);
    const [active, setActive] = useState(false);
    useEffect(() => {
      if (!active && filledIndex !== defaultFilledIndex) {
        setFilledIndex(defaultFilledIndex);
      }
    });

    const colors = props.colors ?? {
      default: "gray.500",
      active: "stars.active",
      filled: "yellow.400",
    };

    return (
      <Box
        role="star-rating"
        ref={ref}
        display="inline-block"
        onMouseEnter={() => (!readOnly ? setActive(true) : undefined)}
        onMouseLeave={() => {
          if (!readOnly) {
            setActive(false);
            setFilledIndex(defaultFilledIndex);
          }
        }}
      >
        <Flex ml="-2px">
          {[...Array(maxRating)].map((_, index) => {
            let color = colors.default;
            if (active) {
              color = colors.active;
            } else if (rating !== 0) {
              color = colors.filled;
            } else if (rating === 0 && readOnly) {
              color = colors.filled;
            }
            return (
              <Tooltip
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                placement="top"
                label={
                  !readOnly && maxRating === 5
                    ? formatLabel(index + 1, ratingDescriptions)
                    : undefined
                }
                openDelay={300}
              >
                <Flex
                  cursor={!readOnly ? "pointer" : undefined}
                  onClick={() => {
                    if (!readOnly) {
                      const selectedRating = index + 1;
                      if (selectedRating === rating) {
                        onChange?.(0);
                        setFilledIndex(-1);
                      } else {
                        onChange?.(index + 1);
                        setActive(false);
                      }
                    }
                  }}
                  onMouseEnter={() => {
                    if (!readOnly) {
                      setActive(true);
                      setFilledIndex(index);
                    }
                  }}
                >
                  <Icon
                    w={size}
                    h={size}
                    mx={iconMargin}
                    fill={index <= filledIndex ? color : colors.default}
                    color={index <= filledIndex ? color : colors.default}
                    as={index <= filledIndex ? HiStar : HiOutlineStar}
                    pointerEvents="none"
                  />
                </Flex>
              </Tooltip>
            );
          })}
        </Flex>
      </Box>
    );
  }
);

StarRating.displayName = "StarRating";

export default StarRating;
