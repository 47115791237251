import { useParams } from "react-router-dom";

import { useNumberSearchParam } from "../../../hooks/useSearchParam";
import { ExternalShareType } from "./types";

interface UseExternalShareParams {
  shareId: string;
  shareType: ExternalShareType;
  index: number;
}

export function useExternalShareParams(): UseExternalShareParams {
  const { shareType, shareId } = useParams() as {
    shareType: ExternalShareType;
    shareId: string;
  };
  const [index] = useNumberSearchParam("index");
  return {
    shareId,
    shareType,
    index: index ?? 0,
  };
}
