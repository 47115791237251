import { Box } from "@chakra-ui/react";
import React from "react";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useTheme } from "..";
/**
 * Page content with max width
 */

interface PageContentProps {
  children: React.ReactNode;
}
const PageContent: React.FC<PageContentProps> = ({ children }) => {
  const theme = useTheme();
  const { pagePadding } = theme.sizes;
  const { layoutHeight } = useWindowDimensions();

  // White bg and min height hack to simulate white background
  // Will be unnecessary once the rest of the pages have white backgrounds
  return (
    <Box padding={pagePadding} bg="white" minH={layoutHeight}>
      <Box maxW="1400px" marginX="auto">
        {children}
      </Box>
    </Box>
  );
};

export default PageContent;
