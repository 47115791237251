import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Input,
  Link,
  Switch,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import {
  HiArrowLeft,
  HiOutlineDuplicate,
  HiOutlineInformationCircle,
  HiOutlinePencil,
  HiOutlineTrash,
} from "react-icons/hi";
import { MdLock } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import {
  GreenhouseIcon,
  LeverIcon,
  TooltipIcon,
  useTheme,
} from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { PositionSelect } from "../../components";
import { CallGuideVisibility } from "../../graphql";
import useDeleteCallGuide from "../../graphql/hooks/CallGuide/useDeleteCallGuide";
import useDuplicateCallGuideBeta from "../../graphql/hooks/CallGuide/useDuplicateCallGuideBeta";
import useIsOverflowing from "../../hooks/useIsOverflowing";
import { CallGuide } from "./types";

interface GuideHeaderProps {
  callGuide: CallGuide;
  setGuide: React.Dispatch<React.SetStateAction<CallGuide | null>>;
  readOnly: boolean;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: () => void;
  onCancel: () => void;
  shouldHideEditingButton: boolean;
  haveChangesBeenMade: boolean;
  setHaveChangesBeenMade: React.Dispatch<React.SetStateAction<boolean>>;
}

const GuideHeader: React.FC<GuideHeaderProps> = ({
  callGuide,
  setGuide,
  readOnly,
  isEditing,
  setIsEditing,
  onSave,
  onCancel,
  shouldHideEditingButton,
  haveChangesBeenMade,
  setHaveChangesBeenMade,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

  const cancelRef = React.useRef<HTMLButtonElement | null>(null);
  const sendGAEvent = useSendGAEvent();
  const navigate = useNavigate();
  const toast = useToast();
  const theme = useTheme();
  const { colors } = theme;
  const [duplicateCallGuide] = useDuplicateCallGuideBeta({
    onError: () => toast({ status: "error", title: "Error copying guide" }),
    onCompleted: ({ duplicateCallGuideBeta }) => {
      const callGuide = duplicateCallGuideBeta?.callGuide;
      if (callGuide) {
        navigate(`/guide/${callGuide.id}`);
        setIsEditing(true);
      }
    },
  });

  const { ref: guideNameRef, isOverflowing: guideNameIsOverflowing } =
    useIsOverflowing<HTMLDivElement>({ width: true });
  const { ref: positionRef, isOverflowing: positionIsOverflowing } =
    useIsOverflowing<HTMLAnchorElement>({ width: true });

  const [deleteCallGuide, { loading: deleteCallGuideLoading }] =
    useDeleteCallGuide({
      onError: () => toast({ status: "error", title: "Error deleting guide" }),
      onCompleted: () => {
        setIsDeleteModalOpen(false);
        navigate("/guides", { replace: true });
      },
    });

  const customSelectStyles = {
    control: (provided: Record<string, any>) => ({
      ...provided,
      borderRadius: "6px",
      width: "100%",
      borderColor: "gray.200",
      maxWidth: "480px",
    }),
    container: (provided: Record<string, any>) => ({
      ...provided,
      width: "100%",
      maxWidth: "480px",
    }),
  };

  const canEdit = !readOnly && !callGuide.atsId;
  return (
    <>
      <Flex
        bg="white"
        py="2"
        px="8"
        width="100%"
        justifyContent="space-between"
        height="64px"
      >
        <Flex gap="6" alignItems="center">
          <Tooltip
            label={
              isEditing
                ? "Save changes to go back to your Interview Guides"
                : "Back to interview guides"
            }
            shouldWrapChildren
          >
            <IconButton
              aria-label="Back to interview guides"
              icon={<HiArrowLeft size="24" />}
              variant="icon"
              disabled={isEditing}
              onClick={() => navigate(`/guides`)}
            />
          </Tooltip>
          <Flex
            direction="row"
            alignItems="center"
            w={["100%", "100%", "100%", 180, 240, 360]}
            overflow={!isEditing ? "hidden" : undefined}
            data-testid="guide-name"
          >
            {!isEditing && (
              <Flex direction="column" minW="0">
                <Text
                  fontSize="xs"
                  color="gray.500"
                  fontWeight="400"
                  alignItems="center"
                >
                  Guide title
                </Text>
                <Flex fontSize="sm" color="gray.900" fontWeight="400" mt="1">
                  <Tooltip
                    label={guideNameIsOverflowing ? callGuide.name : undefined}
                    openDelay={300}
                  >
                    <Box
                      textOverflow="ellipsis"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      ref={guideNameRef}
                    >
                      {callGuide.name}
                    </Box>
                  </Tooltip>
                  {callGuide.atsId && (
                    <Tooltip
                      label="This Interview Guide is managed by your ATS."
                      placement="top"
                      hasArrow
                    >
                      <Flex alignItems="center" ml={2}>
                        {callGuide.greenhouseId && (
                          <GreenhouseIcon fill={colors.gray[400]} />
                        )}
                        {callGuide.leverFeedbackTemplateId && (
                          <LeverIcon fill={colors.gray[400]} />
                        )}
                        {callGuide.customAtsId && (
                          <Icon as={MdLock} color="gray.400" />
                        )}
                        {callGuide.ashbyFeedbackFormId && (
                          <Icon as={MdLock} color="gray.400" />
                        )}
                      </Flex>
                    </Tooltip>
                  )}
                </Flex>
              </Flex>
            )}
            {isEditing && (
              <Input
                value={callGuide.name || ""}
                fontSize="sm"
                borderRadius="6"
                onChange={(e) => {
                  setHaveChangesBeenMade(true);
                  setGuide((guide) => {
                    return {
                      ...guide,
                      name: e.target.value,
                    } as CallGuide;
                  });
                }}
              />
            )}
          </Flex>
          <Flex
            direction="row"
            w={["100%", "100%", "100%", 180, 240, 360]}
            alignItems="center"
            overflow={!isEditing ? "hidden" : undefined}
          >
            {!isEditing && (
              <Flex direction="column" minW="0">
                <Text
                  fontSize="xs"
                  color="gray.500"
                  fontWeight="400"
                  alignItems="center"
                >
                  Position (optional)
                </Text>
                <Flex fontSize="sm" color="gray.900" fontWeight="400" mt="1">
                  {callGuide.position ? (
                    <Tooltip
                      label={
                        positionIsOverflowing
                          ? callGuide.position?.displayTitle
                          : undefined
                      }
                      openDelay={300}
                    >
                      <Link
                        color="blue.600"
                        fontWeight="400"
                        href={`/position/${callGuide.position?.id}`}
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        ref={positionRef}
                      >
                        {callGuide.position?.displayTitle}
                      </Link>
                    </Tooltip>
                  ) : (
                    <Text>{"\u00A0"}</Text>
                  )}
                </Flex>
              </Flex>
            )}
            {isEditing && (
              <Flex width="100%">
                <PositionSelect
                  positionId={callGuide.position?.id}
                  onSelect={(position) => {
                    if (position?.id !== callGuide.position?.id) {
                      setGuide((guide) => {
                        setHaveChangesBeenMade(true);
                        return {
                          ...guide,
                          position: { ...guide?.position, id: position?.id },
                        } as CallGuide;
                      });
                    }
                  }}
                  customStyles={customSelectStyles}
                />
              </Flex>
            )}
          </Flex>
          <Flex direction="column" px="3">
            <Flex
              as="label"
              htmlFor="public-switch"
              fontSize="xs"
              color="gray.500"
              fontWeight="400"
              alignItems="center"
            >
              Public
              <TooltipIcon
                hasArrow
                placement="top"
                color="gray.400"
                icon={HiOutlineInformationCircle}
                label="Public guides can be used by everyone in the Position's Hiring Team."
                ml={1}
              />
            </Flex>
            <Flex fontSize="sm" color="gray.900" fontWeight="400">
              {isEditing ? (
                <Box>
                  <Switch
                    id="public-switch"
                    alignSelf="center"
                    size="sm"
                    isDisabled={readOnly}
                    isChecked={
                      callGuide.visibility === CallGuideVisibility.Public
                    }
                    onChange={() => {
                      setHaveChangesBeenMade(true);
                      setGuide((guide) => {
                        return {
                          ...guide,
                          visibility:
                            guide?.visibility === CallGuideVisibility.Public
                              ? CallGuideVisibility.Private
                              : CallGuideVisibility.Public,
                        } as CallGuide;
                      });
                    }}
                  />
                </Box>
              ) : callGuide.visibility === CallGuideVisibility.Public ? (
                "Yes"
              ) : (
                "No"
              )}
            </Flex>
          </Flex>
          <Flex direction="column" px="3">
            <Flex
              fontSize="xs"
              color="gray.500"
              fontWeight="400"
              alignItems="center"
            >
              Template
              <TooltipIcon
                hasArrow
                placement="top"
                color="gray.400"
                icon={HiOutlineInformationCircle}
                label="Template guides can be used by everyone in your organization."
                ml={1}
              />
            </Flex>
            <Flex fontSize="sm" color="gray.900" fontWeight="400">
              {isEditing ? (
                <Box>
                  <Switch
                    id="template-switch"
                    alignSelf="center"
                    size="sm"
                    isDisabled={readOnly}
                    isChecked={callGuide.isTemplate}
                    onChange={() => {
                      setHaveChangesBeenMade(true);
                      setGuide((guide) => {
                        return {
                          ...guide,
                          isTemplate: !guide?.isTemplate,
                        } as CallGuide;
                      });
                    }}
                  />
                </Box>
              ) : callGuide.isTemplate ? (
                "Yes"
              ) : (
                "No"
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex width="240px" alignItems="center" justifyContent="flex-end">
          <Flex direction="row" alignItems="center">
            <Box mr={2}>
              <Tooltip
                label={
                  callGuide.cues.length === 0
                    ? "Add at least one block of content before duplicating this guide"
                    : isEditing
                    ? "Save your changes before duplicating this guide"
                    : "Duplicate"
                }
                shouldWrapChildren
              >
                <IconButton
                  aria-label="Duplicate"
                  icon={<HiOutlineDuplicate strokeWidth="1.5px" size="24" />}
                  size="md"
                  variant="icon"
                  disabled={callGuide.cues.length === 0 || isEditing}
                  onClick={() => {
                    sendGAEvent("copy", "call_guides");
                    duplicateCallGuide({ variables: { id: callGuide.id } });
                  }}
                />
              </Tooltip>
            </Box>
            <Box>
              <Tooltip label="Delete" shouldWrapChildren>
                <IconButton
                  aria-label="Delete"
                  icon={<HiOutlineTrash strokeWidth="1.5px" size="24" />}
                  size="md"
                  variant="iconDanger"
                  color="red"
                  onClick={() => {
                    setIsDeleteModalOpen(true);
                  }}
                />
              </Tooltip>
            </Box>
          </Flex>
          <Flex direction="row" justifyContent="center" alignItems="center">
            <Link
              variant="primary"
              fontSize="md"
              fontWeight="500"
              onClick={() => {
                onCancel();
              }}
              hidden={!isEditing}
              ml={5}
            >
              Cancel
            </Link>
            <Box hidden={!isEditing}>
              <Tooltip
                label={
                  callGuide.cues.length === 0
                    ? "Add at least one block of content before saving your changes"
                    : ""
                }
              >
                <Button
                  size="sm"
                  variant="solid"
                  fontWeight="500"
                  onClick={() => {
                    onSave();
                  }}
                  ml={5}
                >
                  Save
                </Button>
              </Tooltip>
            </Box>
            <Tooltip
              shouldWrapChildren
              label={
                callGuide.atsId
                  ? "This Interview Guide is managed by your ATS."
                  : ""
              }
            >
              <Button
                size="sm"
                variant="solid"
                fontWeight="500"
                leftIcon={<HiOutlinePencil />}
                disabled={!canEdit}
                onClick={() => setIsEditing(true)}
                hidden={isEditing}
                ml={5}
              >
                Edit
              </Button>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
      <AlertDialog
        isOpen={isDeleteModalOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Guide
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete <strong>{callGuide.name}</strong>?
              This cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                variant="outline"
                size="sm"
                ref={cancelRef}
                isDisabled={deleteCallGuideLoading}
                onClick={() => {
                  setHaveChangesBeenMade(false);
                  setIsDeleteModalOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                size="sm"
                data-testid="delete-guide-button"
                isLoading={deleteCallGuideLoading}
                onClick={() => {
                  sendGAEvent("delete", "call_guides");
                  deleteCallGuide({ variables: { id: callGuide.id } });
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default GuideHeader;
