import React from "react";

import {
  JobDescriptionVisibleTo,
  PaginatedJobDescriptionVisibleTo,
} from "../../../plan/graphql";
import {
  CallVisibility,
  PaginatedRecordingVisibleTo,
  RecordingVisibleTo,
  UserFragment,
} from "../../graphql";

export type VisibleToUser =
  | (
      | Omit<RecordingVisibleTo, "user">
      | Omit<JobDescriptionVisibleTo, "user">
    ) & {
      user: Pick<
        UserFragment,
        "id" | "firstName" | "lastName" | "fullName" | "profilePicUrl" | "email"
      >;
    };

export type VisibleTo =
  | (Pick<PaginatedRecordingVisibleTo, "resultCount"> & {
      results: VisibleToUser[];
    })
  | (Pick<PaginatedJobDescriptionVisibleTo, "resultCount"> & {
      results: VisibleToUser[];
    });

export type InternalUser = Pick<
  UserFragment,
  "id" | "email" | "firstName" | "lastName" | "fullName" | "profilePicUrl"
>;

export type ExternalUser = {
  id: string;
  email: string;
};

export type ShareUser = InternalUser | ExternalUser;

type BaseShare = {
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type InternalShare = BaseShare & {
  sharedTo: InternalUser;
};

export type ExternalShare = BaseShare & {
  sharedTo: ExternalUser;
  isExpired: boolean;
  daysRemaining: number;
};

export type Share = Omit<InternalShare | ExternalShare, "sharedTo"> & {
  sharedTo: ShareUser;
};

export function isExternalUser(user: ShareUser): user is ExternalUser {
  return !isInternalUser(user);
}

export function isInternalUser(user: ShareUser): user is InternalUser {
  const { firstName, lastName, fullName } = user as InternalUser;
  return [firstName, lastName, fullName].some((val) => val !== undefined);
}

export function isExternalShare(share: Share): share is ExternalShare {
  const { isExpired, daysRemaining } = share as ExternalShare;
  return [isExpired, daysRemaining].some((val) => val !== undefined);
}

export function isInternalShare(share: Share): share is InternalShare {
  return isInternalUser(share.sharedTo);
}

export type ShareModalProps = {
  onChangeVisibility?: (v: CallVisibility) => void;
  trainingProgramCount?: number;
  removeExternalShare?: (s: ExternalShare) => Promise<void>;
  renewExternalShare?: (s: ExternalShare) => void;
  defaultTitle?: string;
  includeTitleInput?: boolean;
  canShareExternal?: boolean;
  visibilityLevels?: CallVisibility[];
  onShareExternal?: (emails: string[], message: string, title: string) => void;
  shareableUsers?: InternalUser[];
  optionsLoading: boolean;
  maxSelection?: number;
  shareLoading: boolean;
  onSelection?: (s: InternalUser[]) => void;
  removeShare?: (s: InternalShare) => void;
  sharedWith?: Share[];
  visibility?: CallVisibility;
  visibleTo?: VisibleTo;
  positionId?: string | null;
  callId?: string | null;
  clipId?: string | null;
  canShare: boolean;
  onShare: (shareToIds: string[], message: string, title: string) => void;
  onClose: () => void;
  modalTitle?: string;
  externalShareDuration: number;
  sharedWithStatic?: React.ReactNode;
  /** Custom UI for the "Copy Link" button */
  copyLink?: React.ReactNode;
  infoText?: string;
};
