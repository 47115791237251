import { useToast } from "@chakra-ui/react";
import React, { useMemo } from "react";
import ReactSelect from "react-select";

import useSelectTheme from "../../../hooks/useSelectTheme";
import { useTimezonesQuery } from "../../graphql";

interface Timezone {
  name: string;
  abbrev: string;
  utcOffset: string;
}

const TimezoneSelect: React.FC<{
  timezone: string;
  isDisabled?: boolean;
  onChange: (tz: string) => void;
}> = ({ timezone, isDisabled, onChange }) => {
  const toast = useToast();
  const { data, loading } = useTimezonesQuery({
    onError: () => {
      toast({
        title: "Error loading timezones",
        status: "error",
      });
    },
  });
  const timezoneLabel = (tz: Timezone): string => {
    const splits = tz.name.split("/");
    if (splits.length !== 2) return "Unknown";
    let label = splits[1].replaceAll("_", " ");
    if (label === "Kiev") label = "Kyiv";
    return `(GMT${tz.utcOffset}) ${label}`;
  };
  const timezoneOptions = useMemo(() => {
    if (!data?.timezones) return [];
    const tzs = [...data.timezones]
      .filter((tz) => {
        const splits = tz.name.split("/");
        if (splits.length !== 2) return false;
        if (
          [
            "America",
            "Europe",
            "Asia",
            "Africa",
            "Antarctica",
            "Atlantic",
            "Pacific",
            "Australia",
          ].includes(splits[0])
        ) {
          return true;
        }
        return false;
      })
      .map((tz) => {
        return {
          value: tz.name,
          label: timezoneLabel(tz),
        };
      });
    tzs.sort((tza, tzb) => {
      if (tza.label < tzb.label) return -1;
      if (tza.label > tzb.label) return 1;
      return 0;
    });
    return tzs;
  }, [data?.timezones]);

  const selectedTimezoneOption = timezoneOptions.find((v) => {
    return v.value === timezone;
  });
  const [theme, styles] = useSelectTheme();
  return (
    <ReactSelect
      theme={theme}
      styles={styles}
      isLoading={loading}
      isDisabled={isDisabled}
      name="select-timezone"
      placeholder="Select a timezone"
      value={selectedTimezoneOption}
      options={timezoneOptions}
      onChange={(v) => {
        if (v) onChange(v.value);
      }}
    />
  );
};

export default TimezoneSelect;
