import React from "react";

export const RefreshIcon: React.FC = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4103_49076)">
        <path
          d="M1.5 8.50024C1.49974 7.09417 1.95542 5.72593 2.79867 4.60079C3.64193 3.47565 4.82729 2.65429 6.17692 2.25992C7.52655 1.86556 8.96766 1.91946 10.2841 2.41354C11.6005 2.90763 12.7211 3.81525 13.478 5.00024"
          stroke="#808B97"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5 1V5H9.5"
          stroke="#808B97"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.4995 8.5C14.4997 9.90606 14.0441 11.2743 13.2008 12.3994C12.3576 13.5246 11.1722 14.3459 9.82256 14.7403C8.47294 15.1347 7.03182 15.0808 5.71542 14.5867C4.39903 14.0926 3.27833 13.185 2.52148 12"
          stroke="#808B97"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.5 16V12H6.5"
          stroke="#808B97"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4103_49076">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
