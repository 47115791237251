import React from "react";

import { ScoringSettingsBase, useToast } from "../../../components";
import { useUpdateOrganizationScoringMutation } from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";

const ScoringSettings: React.FC = () => {
  const currentUser = useCurrentUser();
  const toast = useToast();

  const {
    scoringEnabled,
    guideOverallScoringEnabledByDefault,
    guideCompetencyScoringEnabledByDefault,
    competencyRatingDescriptions,
    ratingDescriptions,
  } = currentUser.organization;

  const [updateOrganizationScoring, { loading, error }] =
    useUpdateOrganizationScoringMutation({
      onCompleted: (data) => {
        if (data?.updateOrganizationScoring?.organization) {
          toast({
            status: "success",
            title: "Scoring Settings",
            description: "Saved!",
          });
        }
      },
      onError: () => {
        toast({
          status: "error",
          title: "Scoring Settings",
          description:
            error?.message ?? "There was a problem - please try again",
        });
      },
    });

  return (
    <ScoringSettingsBase
      defaultScoringEnabled={scoringEnabled}
      defaultGuideOverallScoringEnabled={guideOverallScoringEnabledByDefault}
      defaultCompetencyScoringEnabled={guideCompetencyScoringEnabledByDefault}
      disabled={loading}
      competencyRatingDescriptions={competencyRatingDescriptions}
      ratingDescriptions={ratingDescriptions}
      onSave={(values) => {
        const {
          scoringEnabled,
          guideOverallScoringEnabledByDefault,
          guideCompetencyScoringEnabledByDefault,
          interviewRatingDescriptions,
          competencyStarRatingDescriptions,
        } = values;

        updateOrganizationScoring({
          variables: {
            isEnabled: scoringEnabled,
            guideOverallScoringEnabledByDefault,
            guideCompetencyScoringEnabledByDefault,
            oneStarRating: interviewRatingDescriptions.oneStar,
            twoStarRating: interviewRatingDescriptions.twoStar,
            threeStarRating: interviewRatingDescriptions.threeStar,
            fourStarRating: interviewRatingDescriptions.fourStar,
            fiveStarRating: interviewRatingDescriptions.fiveStar,
            oneStarCompetencyRating: competencyStarRatingDescriptions.oneStar,
            twoStarCompetencyRating: competencyStarRatingDescriptions.twoStar,
            threeStarCompetencyRating:
              competencyStarRatingDescriptions.threeStar,
            fourStarCompetencyRating: competencyStarRatingDescriptions.fourStar,
            fiveStarCompetencyRating: competencyStarRatingDescriptions.fiveStar,
          },
        });
      }}
    />
  );
};

export default ScoringSettings;
