type MotionStyle = {
  display?: string;
  justifyContent?: string;
  alignItems?: string;
  opacity?: string | number;
  height?: string | number;
};

/**
 * Styles for list container animations
 */
export const listAnimation = {
  visible: {
    opacity: 1,
    transition: {
      duration: 0.1,
      when: "beforeChildren",
      staggerChildren: 0.003,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
};

/**
 * Styles for list item animations
 */
export const itemAnimation = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: -20 },
};

/**
 * Get styles for an element such as a loading icon that's appended to a list
 * @param showBorderTop Whether to show a borderTop or not
 */
export const loadingStyle: MotionStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

/**
 * Get styles for animating an element such as a loading icon that's
 * appended to a list
 * @param height The expected "visible" height for the element
 */
export const loadingVariants = (
  height: number | string
): { visible: MotionStyle; hidden: MotionStyle } => {
  const visible: MotionStyle = { opacity: 1, height };
  const hidden: MotionStyle = { opacity: 0, height: 0 };
  return {
    visible,
    hidden,
  };
};
