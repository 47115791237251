import { Box, Flex, Icon, Text, Tooltip } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { PropsWithChildren } from "react";
import {
  HiOutlineChatBubbleBottomCenterText,
  HiOutlineQuestionMarkCircle,
  HiOutlineVideoCamera,
} from "react-icons/hi2";

import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { Maybe, MetricName } from "../../../graphql";

interface QuickStatsItemProps {
  /**
   * The name of the statistic, to be displayed in the UI
   */
  metric:
    | MetricName.TotalInterviews
    | MetricName.CandidateTalkRatio
    | MetricName.QuestionsAsked;
  /**
   * The value of the statistic, to be displayed in the UI
   */
  value: Maybe<number>;
  /**
   * Optional method for formatting the value
   */
  formatter?(v: number): string;
  /**
   * Lower and upper bounds of the benchmark range, where available
   */
  benchmark?: Maybe<[number, number]>;
}

const QuickStatsItem: React.FC<QuickStatsItemProps> = ({
  metric,
  value,
  formatter = (v) => `${v}`,
  benchmark,
}) => {
  const sendGAEvent = useSendGAEvent();

  let icon = HiOutlineVideoCamera;
  let metricLabel = "Interviews";
  let benchmarkSuffix = "";

  if (metric === MetricName.CandidateTalkRatio) {
    icon = HiOutlineChatBubbleBottomCenterText;
    metricLabel = "Candidate talk ratio";
  }
  if (metric === MetricName.QuestionsAsked) {
    icon = HiOutlineQuestionMarkCircle;
    metricLabel = "Questions asked";
    benchmarkSuffix = "questions ";
  }

  const hasValue = value !== null;
  const displayValue = hasValue ? formatter(value) : null;

  const withinBenchmark =
    benchmark && hasValue && benchmark[0] <= value && value <= benchmark[1];
  const belowBenchmark = benchmark && hasValue && value < benchmark[0];
  const aboveBenchmark = benchmark && hasValue && value > benchmark[1];

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={{
        visible: { opacity: 1, x: 0 },
        hidden: { opacity: 0, x: 7 },
      }}
    >
      <Flex
        alignItems="center"
        data-tooltip-key={metricLabel}
        color="blue.50"
        p="2"
      >
        <Flex boxSize="10" bg="currentColor" borderRadius="md" mr="3">
          <Icon as={icon} m="auto" color="blue.500" boxSize="5" />
        </Flex>
        <Box>
          <Text
            color="gray.600"
            fontWeight="medium"
            fontSize="sm"
            lineHeight="4"
            mb="1"
          >
            {metricLabel}
          </Text>
          <Flex alignItems="center">
            <Box color="gray.900" fontSize="md" fontWeight="semibold" mr="2">
              {displayValue || (
                <Box
                  color="gray.400"
                  fontSize="sm"
                  fontWeight="normal"
                  lineHeight="5"
                  mt="1"
                >
                  No data yet
                </Box>
              )}
            </Box>
            <Tooltip
              shouldWrapChildren
              textAlign="center"
              fontSize="sm"
              fontWeight="normal"
              lineHeight="4"
              maxW="230px"
              py="1.5"
              label={
                benchmark &&
                `50% of interviews fall within a benchmark of ${formatter(
                  benchmark[0]
                )} - ${formatter(
                  benchmark[1]
                )} ${benchmarkSuffix}based on data from our customer base.`
              }
              onOpen={() =>
                sendGAEvent("Quick stat tooltip", "home_page", metric)
              }
            >
              {belowBenchmark && (
                <BenchmarkTag variant="info">Below benchmark</BenchmarkTag>
              )}
              {withinBenchmark && (
                <BenchmarkTag variant="primary">Within benchmark</BenchmarkTag>
              )}
              {aboveBenchmark && (
                <BenchmarkTag variant="info">Above benchmark</BenchmarkTag>
              )}
            </Tooltip>
          </Flex>
        </Box>
      </Flex>
    </motion.div>
  );
};

export default QuickStatsItem;

const BenchmarkTag: React.FC<
  PropsWithChildren<{ variant: "primary" | "info" }>
> = ({ variant, children }) => (
  <Box
    fontSize="xs"
    fontWeight="medium"
    borderRadius="sm"
    lineHeight="4"
    color={variant === "primary" ? "green.700" : "yellow.700"}
    bg={variant === "primary" ? "green.50" : "yellow.100"}
    px="1"
    py="0.5"
  >
    {children}
  </Box>
);
