import { useRedactCallMutation } from "..";

/** Wrapper around the redact call mutation to ensure proper cleanup */
const useRedactCall: typeof useRedactCallMutation = (options) => {
  return useRedactCallMutation({
    ...options,
    refetchQueries: ["CandidateScores"],
    update: (cache, { data }, { variables }) => {
      const success = data?.redactCall?.success;
      if (success) {
        const callCacheId = cache.identify({
          __typename: "Call",
          id: variables?.id,
        });
        if (callCacheId) {
          cache.evict({ id: callCacheId });
          cache.gc();
        }
      }
    },
  });
};

export default useRedactCall;
