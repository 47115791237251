import { Box, Flex, FlexProps, Icon, Spinner } from "@chakra-ui/react";
import React from "react";
import { IoVideocamOffSharp } from "react-icons/io5";

import { VideoCallbackRef } from "../Video/useVideoState";
import VideoPlayerBeta from "../Video/VideoPlayerBeta";
import { VideoDisplayMode } from "./types";
import { CallbackRef, MediaListeners } from "./useMediaPlayer";

export type VideoModuleBetaProps = {
  clipInProgress: boolean;
  clipTimedOut: boolean;
  videoDisplayMode: VideoDisplayMode;
  mediaSrc?: string;
  listeners: MediaListeners;
  mediaPlayerRef: CallbackRef;
  videoRef: VideoCallbackRef;
  height: string | number;
  isVideoVisible: boolean;
  onClick?: FlexProps["onClick"];
};

const VideoModuleBeta: React.FC<VideoModuleBetaProps> = ({
  clipInProgress,
  clipTimedOut,
  videoDisplayMode,
  mediaSrc,
  listeners,
  mediaPlayerRef,
  videoRef,
  height,
  isVideoVisible,
  onClick,
}) => {
  if (clipTimedOut) {
    return (
      <MessageFrame
        message="Clip creation timed out. Please try again."
        icon={
          <Icon as={IoVideocamOffSharp} color="whiteAlpha.600" boxSize={12} />
        }
      />
    );
  }
  if (clipInProgress) {
    return (
      <MessageFrame
        message="Processing clip..."
        icon={<Spinner width="48px" height="48px" color="whiteAlpha.600" />}
      />
    );
  }
  if (videoDisplayMode === VideoDisplayMode.VIDEO) {
    return (
      <VideoPlayerBeta
        mediaPlayerRef={mediaPlayerRef}
        videoRef={videoRef}
        src={mediaSrc}
        height={height}
        isVideoVisible={isVideoVisible}
        onClick={onClick}
        {...listeners}
      />
    );
  }
  if (videoDisplayMode === VideoDisplayMode.AUDIO) {
    return <audio ref={mediaPlayerRef} src={mediaSrc} {...listeners} />;
  }
  if (videoDisplayMode === VideoDisplayMode.PROCESSING) {
    return (
      <MessageFrame
        message="Processing recording..."
        icon={<Spinner width="48px" height="48px" color="whiteAlpha.600" />}
      />
    );
  }
  if (videoDisplayMode === VideoDisplayMode.IN_PROGRESS) {
    return (
      <MessageFrame
        message="Interview is in progress..."
        icon={<Spinner width="48px" height="48px" color="whiteAlpha.600" />}
      />
    );
  }
  if (videoDisplayMode === VideoDisplayMode.NO_RECORDING) {
    return (
      <MessageFrame
        message="No recording available."
        icon={
          <Icon as={IoVideocamOffSharp} color="whiteAlpha.600" boxSize={12} />
        }
      />
    );
  }
  return null;
};

type MessageFrameProps = {
  message: string;
  icon: React.ReactNode;
};

const MessageFrame: React.FC<MessageFrameProps> = ({ message, icon }) => {
  return (
    <Flex
      align="center"
      flexDirection="column"
      h="220px"
      justify="center"
      py={{ base: 4, lg: 8 }}
      m="auto"
    >
      {icon}
      <Box color="whiteAlpha.600" fontWeight="bold" fontSize="md" mt="1">
        {message}
      </Box>
    </Flex>
  );
};

export default VideoModuleBeta;
