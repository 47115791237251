import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const VideoCameraDisabled = (props: IconProps): JSX.Element => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.96967 3.96967C1.26256 3.67678 1.73744 3.67678 2.03033 3.96967L2.97614 4.91548C3.42272 4.65172 3.94396 4.5 4.5 4.5H13.5C15.1569 4.5 16.5 5.84315 16.5 7.5V8.68934L19.9393 5.25C20.8843 4.30505 22.5 4.97431 22.5 6.31066V17.6893C22.5 19.0257 20.8843 19.6949 19.9393 18.75L16.5 15.3107V16.5C16.5 17.056 16.3483 17.5773 16.0845 18.0239L17.0303 18.9697C17.3232 19.2626 17.3232 19.7374 17.0303 20.0303C16.7374 20.3232 16.2626 20.3232 15.9697 20.0303L0.96967 5.03033C0.676777 4.73744 0.676777 4.26256 0.96967 3.96967ZM14.9493 16.8887C14.9824 16.7648 15 16.6345 15 16.5V7.5C15 6.67157 14.3284 6 13.5 6H4.5C4.36549 6 4.23524 6.0176 4.11135 6.05069L14.9493 16.8887ZM16.5 13.1893L21 17.6893V6.31066L16.5 10.8107V13.1893ZM2.25 8.25C2.66421 8.25 3 8.58579 3 9V16.5C3 17.3284 3.67157 18 4.5 18H12C12.4142 18 12.75 18.3358 12.75 18.75C12.75 19.1642 12.4142 19.5 12 19.5H4.5C2.84315 19.5 1.5 18.1569 1.5 16.5V9C1.5 8.58579 1.83579 8.25 2.25 8.25Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
);

export default VideoCameraDisabled;
