import {
  Button,
  Flex,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
} from "@chakra-ui/react";
import { transparentize } from "@chakra-ui/theme-tools";
import React, { useState } from "react";
import { IoMdInformationCircle } from "react-icons/io";

import { LoadingIndicator, RouterLink } from "../../../components";
import useDebounce from "../../../hooks/useDebounce";
import { useTrainingProgramItemPreviewQuery } from "../../graphql";
import TrainingListItem from "./TrainingListItem";
import { TrainingListItemType } from "./types";
import { TrainingApi } from "./useTrainingApi";
import { parseCallAndClipFromUrl } from "./utils";

interface TrainingListItemPreviewProps {
  trainingApi: TrainingApi;
  callId: string;
  clipId?: string;
}

const TrainingListItemPreview: React.FC<TrainingListItemPreviewProps> = ({
  trainingApi,
  callId,
  clipId,
}) => {
  const { data, error, loading } = useTrainingProgramItemPreviewQuery({
    variables: { callId, clipId },
  });

  if (loading) return <LoadingIndicator />;
  if (error || !data?.trainingProgramItemPreview)
    return (
      <Flex bgColor="orange.50" alignItems="center" borderRadius="lg" p="5">
        <Icon as={IoMdInformationCircle} color="orange.400" w="5" h="5" />

        <Text color="gray.900" fontSize="sm" ml="5">
          Hmm, we can’t seem to find a BrightHire recording with that URL.
          Please try copying & pasting the link again.
        </Text>
      </Flex>
    );

  const trainingItem: TrainingListItemType = data.trainingProgramItemPreview;

  return (
    <TrainingListItem
      trainingApi={trainingApi}
      trainingItem={trainingItem}
      layout="compact"
      borderWidth="1px"
      borderColor="gray.100"
      borderRadius="xl"
      p="1"
      setAutoSaveInProgress={() => false}
    />
  );
};

type RecordingsModalProps = Omit<ModalProps, "children"> & {
  trainingProgramId: string;
  trainingApi: TrainingApi;
};

const RecordingsModal: React.FC<RecordingsModalProps> = ({
  isOpen,
  onClose: onCloseProp,
  trainingProgramId,
  trainingApi,
}) => {
  const [recordingUrl, setRecordingUrl] = useState("");
  const debouncedUrl = useDebounce(recordingUrl, 500);
  const callAndClipId = parseCallAndClipFromUrl(debouncedUrl);

  const onClose = (): void => {
    onCloseProp();
    setRecordingUrl("");
  };

  const onSubmit = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    if (!callAndClipId) return;

    trainingApi.recording.add(
      trainingProgramId,
      callAndClipId.callId,
      callAndClipId.clipId
    );

    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="lg">
        <ModalHeader fontWeight="500" borderBottomWidth={1}>
          Add a Recording
        </ModalHeader>
        <ModalCloseButton top="6" />

        <ModalBody p="8">
          <form onSubmit={onSubmit}>
            <Text color="gray.700" fontSize="sm">
              Note: Adding new recordings to this program will instantly add
              them for all trainees in this program (in progress and marked
              complete). Trainees already marked complete will remain marked
              complete.
            </Text>

            <Input
              fontSize="sm"
              borderRadius="4px"
              mt="6"
              autoFocus
              placeholder="Paste URL to a BrightHire recording or clip"
              onChange={(e) => setRecordingUrl(e.target.value)}
              value={recordingUrl}
            />

            <RouterLink
              to="/search"
              fontSize="md"
              color="blue.600"
              fontWeight={500}
              px={4}
              py={3}
              mt={5}
              mb={6}
              display="inline-block"
              borderRadius="4px"
              target="_blank"
              _hover={{
                background: transparentize(
                  "blue.600",
                  0.1
                ) as unknown as string,
                textDecoration: "none",
              }}
            >
              Search Recordings
            </RouterLink>

            {callAndClipId && (
              <Flex direction="column">
                <TrainingListItemPreview
                  trainingApi={trainingApi}
                  callId={callAndClipId.callId}
                  clipId={callAndClipId.clipId}
                />

                <Button
                  type="submit"
                  mt="10"
                  fontWeight="500"
                  fontSize="sm"
                  alignSelf="flex-end"
                >
                  Add
                </Button>
              </Flex>
            )}
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RecordingsModal;
