import { StyleConfig } from "@chakra-ui/theme-tools";

const config: StyleConfig = {
  sizes: {
    sm: {
      dialog: { maxW: "min(398px, calc(100vw - 32px))" },
    },
    full: {
      dialog: { width: "100%" },
    },
  },
};

export default config;
