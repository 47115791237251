import { Box, Flex, Image, Link, Text } from "@chakra-ui/react";
import React from "react";

import { BhLogo, RouterLink } from "../../../components";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const ExtensionLandingPage: React.FC = () => {
  const { windowHeight } = useWindowDimensions();
  return (
    <Flex
      flexDirection="column"
      minH={windowHeight}
      w="100%"
      bg="linear-gradient(180deg, #EBF9FF 100%, #EBFAFE 100%);"
      pt="10"
      pb="16"
    >
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        flex="1"
      >
        <RouterLink to="/" target="_blank">
          <BhLogo color="blue.600" width="64px" height="64px" mb="3" />
        </RouterLink>
        <Text fontWeight="600" fontSize="4xl" color="black">
          BrightHire Chrome extension is installed!
        </Text>
        <Text mt="8" fontWeight="500" fontSize="md" color="gray.800">
          Complete your ATS scorecards in less than a minute, with BrightHire’s
          AI notes.
        </Text>
        <Text fontWeight="500" color="gray.800" mb="7" mt="4">
          Visit{" "}
          <RouterLink
            color="blue.600"
            fontWeight="400"
            textDecoration="underline"
            to="/"
            target="_blank"
          >
            BrightHire
          </RouterLink>{" "}
          to login or our{" "}
          <Link
            color="blue.600"
            fontWeight="400"
            textDecoration="underline"
            href="https://help.brighthire.ai/en/"
            target="_blank"
          >
            help center
          </Link>{" "}
          for more information.
        </Text>
        <Box position="relative" maxW="620px">
          <RouterLink to="/" target="_blank">
            <Image
              src="/static/images/extension-landing-page.gif"
              borderRadius="16px"
            />
          </RouterLink>
        </Box>
      </Flex>
    </Flex>
  );
};

export default ExtensionLandingPage;
