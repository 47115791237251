import React from "react";
import { IconBaseProps, IconType } from "react-icons";

const BhAtIcon: IconType = (props?: IconBaseProps) => {
  const size: number =
    typeof props?.size === "number"
      ? props?.size
      : parseInt(props?.size ?? "20", 10);
  const title = props?.title ?? "'At' symbol";
  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ pointerEvents: "none" }}
    >
      <title>{title}</title>
      <path
        d="M12.5 9.93261C12.3243 11.9248 10.9329 13.0576 9.35356 13.0576C7.77426 13.0576 6.72309 11.6584 6.87504 9.93261C7.027 8.20683 8.32504 6.80762 9.90434 6.80762C11.4836 6.80762 12.6516 8.21386 12.5 9.93261V9.93261Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.491 16.2412C11.3754 16.71 10.6442 16.8076 9.39415 16.8076C5.59728 16.8076 2.79259 13.7295 3.12501 9.93262C3.45744 6.13574 6.809 3.05762 10.6059 3.05762C14.8828 3.05762 17.234 5.85957 16.9035 9.60957C16.657 12.4092 14.868 13.2158 13.9313 13.0498C13.05 12.8936 12.3227 12.0959 12.457 10.5693L12.7883 6.80957"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BhAtIcon;
