import {
  Box,
  IconButton,
  Tag,
  Text,
  Tooltip as ChakraTooltip,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { HiOutlineInformationCircle } from "react-icons/hi";

import colorVars from "../../../theme/css-color-variables";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import { SearchHit, SearchHitAnalytics } from "./types";
import { getSearchHitAnalytics } from "./utils";

export const SearchResultsAnalytics: React.FC<{ hit: SearchHit }> = ({
  hit,
}) => {
  const analyticsSearchUIEnabled = useFeatureFlag(
    "analytics:index-analytics-ui"
  );
  // get metric query params
  const queryParams = new URLSearchParams(window.location.search);
  const metricParam = queryParams.get("metric") || "None";
  const hitAnalytics = getSearchHitAnalytics(hit, metricParam);

  if (!hitAnalytics || !analyticsSearchUIEnabled) {
    return null;
  }

  return <MetricTag hitAnalytics={hitAnalytics} />;
};

const MetricTag: React.FC<{ hitAnalytics: SearchHitAnalytics }> = ({
  hitAnalytics,
}) => {
  const tooltipContent = hitAnalytics.tooltipLines?.join("\r\n");

  const styles = {
    positive: {
      bg: "#E2F6EF",
      textColor: "#177856",
    },
    negative: {
      bg: "#FFF8DB",
      textColor: "#B38100",
    },
    neutral: {
      bg: colorVars.blue[50],
      textColor: colorVars.blue[600],
    },
    none: {
      bg: colorVars.gray[50],
      textColor: colorVars.gray[500],
    },
  };

  const valenceStyles = styles[hitAnalytics.valence || "none"];

  return (
    <Box mt={2}>
      <Tag {...valenceStyles}>
        <Text display="inline" whiteSpace="pre-line">
          {hitAnalytics.text}
        </Text>
        <SearchResultTooltip label={tooltipContent}>
          {tooltipContent && (
            <IconButton
              aria-label="Interview Metric tooltip"
              variant="unstyled"
              size="xxs"
              ml="1"
              color="gray.800"
              mb="1px"
              icon={
                <HiOutlineInformationCircle color={valenceStyles.textColor} />
              }
            />
          )}
        </SearchResultTooltip>
      </Tag>
    </Box>
  );
};

const SearchResultTooltip: React.FC<{
  label?: string;
  children: ReactNode;
}> = ({ label = "", children }) => {
  return (
    <ChakraTooltip
      bg="white"
      p="2"
      borderRadius="md"
      border="1px solid"
      borderColor="gray.200"
      color="gray.800"
      boxShadow="none"
      fontSize="sm"
      shouldWrapChildren
      fontWeight="normal"
      label={label}
      placement="right-end"
      isDisabled={label.length === 0}
    >
      {children}
    </ChakraTooltip>
  );
};
