import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import {
  GreenhouseRatingButton,
  LeverRatingButton,
} from "../../../../components";
import useCurrentBreakpoint from "../../../../hooks/useCurrentBreakpoint";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { Thumbnail, ThumbnailStates } from "../../../components";
import CallStarRating from "../../../components/CallStarRating";
import CopyAiNotesButton from "../../../components/Candidate/CopyAiNotesButton";
import { InterviewMenuOptions } from "../../../components/InterviewsList";
import ScoringButton from "../../../components/ScoringButton/ScoringButton";
import { CallListItemFragment, NoRecordingReason } from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";
import BaseListItem from "./BaseListItem";
import CompletedInterviewsMobileListItem from "./CompletedInterviewsMobileListItem";
import InterviewCandidatePositionSection from "./InterviewCandidatePositionSection";
import InterviewInterviewerSection from "./InterviewInterviewerSection";
import InterviewMenu from "./InterviewMenu";

interface CompletedInterviewsListItemProps {
  call: CallListItemFragment;
  listPosition: number;
  menuOptions?: InterviewMenuOptions[];
  shouldHideInterviewType?: boolean;
  displayDate?: string;
  onClickCandidate?(): void;
  onClickPosition?(): void;
  onClickInterview?(): void;
}

const CompletedInterviewsListItem: React.FC<
  CompletedInterviewsListItemProps
> = ({
  call,
  listPosition = 1,
  menuOptions = [],
  shouldHideInterviewType,
  displayDate,
  onClickCandidate,
  onClickPosition,
  onClickInterview,
}) => {
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const sendGAEvent = useSendGAEvent();
  const isSmallDesktop = useCurrentBreakpoint() === "lg";

  const showStarRating =
    !call.candidate?.readOnly && currentUser.organization.showStarRatings;

  let thumbnailPlaceholder: ThumbnailStates = "processing";
  if (call.noRecordingReason === NoRecordingReason.CallNotCompleted) {
    thumbnailPlaceholder = "inProgress";
  } else if (call.noRecordingReason) {
    thumbnailPlaceholder = "missing";
  }

  return (
    <BaseListItem
      data-testid={`completed-interviews-item-${listPosition}`}
      data-title={call.name ?? "Untitled"}
      pb={{ base: "5", lg: "4" }}
      px={{ base: "5", lg: "4" }}
      mb="4"
      showMobile={isSmallDesktop}
      leftCol={
        <Flex flex="1" overflow="hidden">
          <Thumbnail
            size="sm"
            mr={5}
            isPlayable={call.recordings.length > 0}
            imageUrl={call.thumbnailImageUrl}
            audioOnly={!call.recordings.find((r) => r.format === "MP4")}
            placeholder={thumbnailPlaceholder}
            time={call.duration}
            onClick={() => {
              navigate(`/interview/${call.id}`);
              onClickInterview?.();
            }}
            timeFontSize="sm"
          />
          <InterviewCandidatePositionSection
            call={call}
            displayDate={displayDate}
            onClickCandidate={onClickCandidate}
            onClickPosition={onClickPosition}
          />
        </Flex>
      }
      centerCol={
        <InterviewInterviewerSection
          call={call}
          shouldHideInterviewType={shouldHideInterviewType}
          onClickInterview={onClickInterview}
        />
      }
      rightCol={
        <Flex>
          <Flex
            direction="column"
            gap="2"
            ml="auto"
            py="1"
            lineHeight="5"
            alignItems="flex-end"
          >
            {showStarRating && (
              <CallStarRating
                size={5}
                call={call}
                ratingDescriptions={currentUser.organization.ratingDescriptions}
              />
            )}
            {call.isInterviewer &&
              (call.greenhouseScorecardLinkWithId ||
                call.leverInterviewLinkWithId) && (
                <Box>
                  {call.greenhouseScorecardLinkWithId && (
                    <GreenhouseRatingButton
                      greenhouseScorecardLink={
                        call.greenhouseScorecardLinkWithId
                      }
                      rating={call.greenhouseUserRating}
                      showFullWidth
                    />
                  )}
                  {call.greenhouseUserRating &&
                    call.leverInterviewLinkWithId &&
                    " "}
                  {call.leverInterviewLinkWithId && (
                    <LeverRatingButton
                      leverInterviewLink={call.leverInterviewLinkWithId}
                      showFullWidth
                    />
                  )}
                </Box>
              )}
            {call && (
              <ScoringButton call={call} shouldShowAverageScore={false} />
            )}
            {call && (
              <CopyAiNotesButton
                call={call}
                variant="full"
                onCopy={() => sendGAEvent("ai_notes", "home_page", "Copy")}
                onOpen={() => sendGAEvent("ai_notes", "home_page", "Open")}
              />
            )}
          </Flex>
          <InterviewMenu
            call={call}
            positionId={call.position?.id}
            listPosition={listPosition}
            menuOptions={menuOptions}
            ml="5"
          />
        </Flex>
      }
      mobile={
        <CompletedInterviewsMobileListItem
          itemName={call.name}
          candidate={call.candidate}
          position={call.position}
          callId={call.id}
          displayDate={displayDate ?? call.startTime ?? call.createdAt}
          onClickCandidate={onClickCandidate}
          onClickPosition={onClickPosition}
          onClickInterview={onClickInterview}
          menu={
            <InterviewMenu
              call={call}
              positionId={call.position?.id}
              listPosition={listPosition}
              menuOptions={menuOptions}
            />
          }
          footer={
            <>
              {call && (call.needsCurrentUserScore || call.myScore) && (
                <Flex flex="1">
                  <ScoringButton call={call} shouldShowAverageScore={false} />
                </Flex>
              )}
              <CopyAiNotesButton
                call={call}
                onCopy={() => sendGAEvent("ai_notes", "home_page", "Copy")}
                onOpen={() => sendGAEvent("ai_notes", "home_page", "Open")}
                variant="full"
                flex="1"
                size="sm"
                iconSpacing="1.5"
              />
              {call.isInterviewer &&
                (call.greenhouseScorecardLinkWithId ||
                  call.leverInterviewLinkWithId) && (
                  <Box flex="1" lineHeight="5">
                    {call.greenhouseScorecardLinkWithId && (
                      <GreenhouseRatingButton
                        greenhouseScorecardLink={
                          call.greenhouseScorecardLinkWithId
                        }
                        rating={call.greenhouseUserRating}
                        showFullWidth
                        size="sm"
                      />
                    )}
                    {call.leverInterviewLinkWithId && (
                      <LeverRatingButton
                        leverInterviewLink={call.leverInterviewLinkWithId}
                        showFullWidth
                        size="sm"
                      />
                    )}
                  </Box>
                )}
            </>
          }
        />
      }
    />
  );
};

export default CompletedInterviewsListItem;
