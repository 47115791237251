import { Maybe } from "graphql/jsutils/Maybe";

import { NO_VALUE_SELECTED, PillSelectOptions } from "../../../../components";
import { CandidateFragment } from "../../../graphql";

export const useCandidatePositionOptions = (
  candidate: Pick<
    CandidateFragment,
    "positions" | "calls" | "scheduledInterviews"
  >
): PillSelectOptions => {
  const options = candidate.positions.map((p) => ({
    key: p.id,
    displayTitle: p.displayTitle,
    count: 0,
  }));

  const mapCallsToOptions = (
    array: Array<{ positionId?: Maybe<string> | undefined }>
  ): void => {
    array.forEach((position, i) => {
      const { positionId } = position;
      const positionKey = positionId || NO_VALUE_SELECTED;
      const positionOption = options.find((p) => p.key === positionKey);
      if (positionOption) {
        positionOption.count += 1;
      } else {
        const isNoPositionCall = positionKey === NO_VALUE_SELECTED;
        options.push({
          key: positionKey,
          displayTitle: isNoPositionCall
            ? "Interviews with no position"
            : "Position not found",
          count: 1,
        });
      }
    });
  };
  mapCallsToOptions(candidate.calls);
  mapCallsToOptions(candidate.scheduledInterviews);
  return options;
};
