import excludedPhrases from "./excludedQuestionKeywords";

export type SegmentWord = { word: string; startTime: number };

export const canonicalize = (str: string): string => {
  if (typeof str === "string") {
    return str.toLocaleLowerCase().replace(/[!?,.]/gi, "");
  }
  return str;
};

interface MatchPosition {
  start: number;
  end: number;
}

export const findMatchPosition = (
  words: SegmentWord[],
  keyword: string
): MatchPosition => {
  const keywordParts = keyword.split(" ");
  const numKeywordParts = keywordParts.length;

  if (keyword === "?") {
    const matchPosition = findQuestionMatchPosition(words);
    return matchPosition;
  }
  for (let i = 0; i <= words.length - numKeywordParts; i++) {
    // Match each keywordPart in the (potentially multi-word) keyword
    for (let j = 0; j < numKeywordParts; j++) {
      const isFinalKeywordPart = j === numKeywordParts - 1;
      const curWord = words[i + j].word;
      const keywordPart = keywordParts[j];
      if (keywordPart === undefined || curWord === undefined) {
        break;
      }
      const wordMatch = canonicalize(keywordPart) === canonicalize(curWord);
      if (!wordMatch) {
        break;
      } else if (isFinalKeywordPart) {
        return { start: i, end: i + numKeywordParts - 1 };
      }
      if (curWord === keywordParts[j] && isFinalKeywordPart) {
        return { start: i, end: i + numKeywordParts - 1 };
      }
    }
  }

  return { start: -1, end: -1 };
};

export const findQuestionMatchPosition = (
  words: SegmentWord[]
): MatchPosition => {
  let currentSentence = "";
  for (let i = 0; i < words.length; i++) {
    const currentWord = words[i].word.toLowerCase().trim();
    if (
      currentWord.endsWith(".") ||
      currentWord.endsWith(",") ||
      currentWord.endsWith("!") ||
      currentWord.endsWith("?")
    ) {
      const canonicalizedWord = currentWord.replace(/[!,.]/gi, "");
      if (canonicalizedWord.endsWith("?")) {
        currentSentence += currentWord;
        if (!excludedPhrases.has(currentSentence)) {
          return { start: i, end: i };
        }
      }
      currentSentence = "";
    } else {
      currentSentence += `${words[i].word.toLowerCase()} `;
    }
  }

  return { start: -1, end: -1 };
};

export const findTrimmedPosition = (
  words: SegmentWord[],
  matchPosition: MatchPosition
): MatchPosition => {
  if (matchPosition.start === -1) return matchPosition;

  let sentenceStart = 0;
  let sentenceEnd = words.length - 1;

  // Trim start to first terminating word.
  for (let i = matchPosition.start - 1; i >= 0; i--) {
    if (isTerminalWord(words[i].word)) {
      sentenceStart = i + 1;
      break;
    }
  }

  // Trim end to first terminating word.
  for (let i = matchPosition.end; i <= words.length - 1; i++) {
    if (isTerminalWord(words[i].word)) {
      sentenceEnd = i;
      break;
    }
  }

  return { start: sentenceStart, end: sentenceEnd };
};

const isTerminalWord = (word: string): boolean => {
  const lastCharacter = word[word.length - 1];
  return "!?.".includes(lastCharacter);
};
