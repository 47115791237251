import { Box, Flex } from "@chakra-ui/react";
import React from "react";

import { Tab } from "../../utils/types";
import { Card, CardTitle } from "../Card";
import { SettingsNavLink } from "../SettingsNavLink";

const MasterListView: React.FC<{
  tabs: Tab<any>[];
  urlPrefix: string;
  title?: string;
}> = ({ tabs, urlPrefix, title }) => (
  <Box>
    <Card>
      {title && <CardTitle position="relative">{title}</CardTitle>}
      <Flex flexDirection="column">
        {tabs.map((tab, i) => (
          <SettingsNavLink
            key={`list-${tab.path}`}
            to={`${urlPrefix}/${tab.startingPath || tab.path}`}
            data-testid="master-detail--link"
          >
            {tab.name}
          </SettingsNavLink>
        ))}
      </Flex>
    </Card>
  </Box>
);

export default MasterListView;
