import { Heading, TextProps } from "@chakra-ui/react";
import React, { forwardRef } from "react";

type SpeakerProps = {
  speakerLabel: string;
} & TextProps;

export const Speaker = forwardRef<HTMLHeadingElement, SpeakerProps>(
  ({ speakerLabel, ...rest }, ref) => (
    <Heading as="h3" fontSize="sm" {...rest} ref={ref}>
      {speakerLabel}
    </Heading>
  )
);
