import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

export const GradCapIcon = (props: IconProps): JSX.Element => (
  <Icon width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
    <path
      d="M26 10.5L14.0552 5.77185C14.0197 5.75781 13.9803 5.75781 13.9448 5.77185L2.3505 10.3613C2.22436 10.4112 2.22404 10.5896 2.35 10.64L7 12.5M26 10.5L21 12.5M26 10.5V17.5M7 12.5L13.9452 15.2285C13.9804 15.2423 14.0196 15.2423 14.0548 15.2285L21 12.5M7 12.5V19C7 19 7.5 22.15 14 22.15C20.5 22.15 21 19 21 19V12.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
