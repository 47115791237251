import { ApolloError } from "@apollo/client";
import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";

import { Alert } from "../../../../components";
import { clipLengthLabel } from "../../Interview/Clip/utils";

export interface FormData {
  name: string;
}

interface ClipFormProps {
  name?: string;
  startTime?: number;
  endTime?: number;
  error: ApolloError | undefined;
  isLoading: boolean;
  onSubmit: (formData: FormData) => void;
  onCancel: () => void;
}

const ClipForm: React.FC<ClipFormProps> = ({
  name = "",
  startTime = 0,
  endTime = 0,
  error,
  isLoading,
  onSubmit,
  onCancel,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name,
    },
  });
  const submitClip = handleSubmit((formData): void => onSubmit(formData));

  return (
    <form onSubmit={submitClip}>
      {error?.graphQLErrors?.map(({ message }, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Alert mb={6} key={i} status="error" description={message} />
      ))}
      <VStack spacing="8">
        <FormControl id="name" isInvalid={errors.name !== undefined}>
          <FormLabel>Clip title (optional)</FormLabel>
          <Input
            {...register("name")}
            placeholder={`${clipLengthLabel(startTime, endTime, false)} clip`}
            data-testid="edit-clip-title-input"
            autoFocus
          />
          {errors.name !== undefined && (
            <FormErrorMessage>{errors.name.message}</FormErrorMessage>
          )}
        </FormControl>
        <ButtonGroup spacing={2} alignSelf="end">
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" isLoading={isLoading}>
            Save
          </Button>
        </ButtonGroup>
      </VStack>
    </form>
  );
};

export default ClipForm;
