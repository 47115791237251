import { ApolloError } from "@apollo/client";
import LogRocket from "logrocket";
import { useCallback, useEffect, useState } from "react";

import { useToast } from "../../../../../../../components";
import { formatDetails } from "../../../../../../../utils/call";
import { copy } from "../../../../../../../utils/clipboard";
import { formatDuration } from "../../../../../../../utils/datetime";
import { useSendGAEvent } from "../../../../../../../utils/googleAnalytics";
import {
  AiNotesProcessingStatus,
  QuestionAndAnswer,
  useCallAiNotesQuery,
  useCreateAiNotesMutation,
  useCreateAiNoteTagsMutation,
  useUpdateAiAnswerMutation,
  useUpdateAiQuestionMutation,
} from "../../../../../../graphql";
import { noteTimestampedLink } from "../../../../../CallNotes/utils";

type AiNotesParams = {
  call: {
    id: string;
    aiNotesProcessingStatus?: AiNotesProcessingStatus | null;
  };
  shouldCreateNotes?: boolean;
  shouldPoll?: boolean;
};

type AiNotesData = {
  aiNotes: QuestionAndAnswer[];
  loading: boolean;
  error?: ApolloError;
  status?: AiNotesProcessingStatus | null;
};

export type AiNotesReturn = {
  data: AiNotesData;
  stopPolling?(): void;
  regenerate(): void;
  copyAiNotes(): void;
  updateAiNote(itemId: string, newValue: string): void;
  updateAiNoteQuestion(noteId: string, newValue: string): void;
  createAiNoteTags(): void;
};

const useAiNotes = ({
  call,
  shouldCreateNotes = true,
  shouldPoll = true,
}: AiNotesParams): AiNotesReturn => {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();

  const [aiNotesStatus, setAiNotesStatus] = useState(
    call.aiNotesProcessingStatus
  );

  const {
    loading,
    error,
    data: aiNotesData,
    startPolling,
    stopPolling,
  } = useCallAiNotesQuery({
    variables: { callId: call.id },
    pollInterval: shouldPoll ? 1000 : undefined,
    onCompleted: (data) => {
      // create notes if not tried yet
      const status = data.callAiNotes?.aiNotesProcessingStatus;
      if (status === null && shouldCreateNotes) {
        setAiNotesStatus(AiNotesProcessingStatus.InProgress);
        createAiNotes({ variables: { callId: call.id } });
      } else {
        setAiNotesStatus(status);
      }
    },
  });

  const [createAiNotes] = useCreateAiNotesMutation({
    onError: (err) => {
      toast({
        title: "AI notes error",
        description: err.message,
        status: "error",
      });
    },
  });

  // stop polling if task succeeds or fails
  useEffect(() => {
    if (
      aiNotesStatus &&
      [
        AiNotesProcessingStatus.Failed,
        AiNotesProcessingStatus.Completed,
      ].includes(aiNotesStatus)
    ) {
      stopPolling();
    }
  }, [stopPolling, aiNotesStatus]);

  // restart polling if manual regenerate
  const regenerate = (): void => {
    createAiNotes({
      variables: { callId: call.id },
    });
    setAiNotesStatus(AiNotesProcessingStatus.InProgress);
    startPolling(1000);
  };

  const questionsAndAnswers = aiNotesData?.callAiNotes?.questionsAndAnswers;
  // copies all notes to clipboard if no filterId is provided
  const copyAiNotes = useCallback(
    (filterId?: string) => {
      const questionsArray = questionsAndAnswers || [];
      const questionsToCopy = filterId
        ? questionsArray.filter((q) => q.id === filterId && q.error === null)
        : questionsArray.filter((q) => q.error === null);

      if (questionsToCopy.length > 0) {
        let plain = filterId ? "" : `${formatDetails(call)}\n\n`;
        let html = filterId
          ? ""
          : `${formatDetails(call, { html: true })}<br><br>`;

        questionsToCopy.forEach((questionAndAnswer) => {
          plain = plain.concat(questionAndAnswer.question).concat("\n\n");
          html = html.concat(
            `<b>${questionAndAnswer.question} (<a href="${noteTimestampedLink({
              callId: call.id,
              time: questionAndAnswer.startTime,
            })}">${formatDuration(
              questionAndAnswer.startTime
            )}</a>)</b><br><ul>`
          );
          if (questionAndAnswer.answerItems.length > 0) {
            const answers = questionAndAnswer.answerItems.map(
              (item) => item.text
            );
            plain = plain
              .concat(answers.map((answer) => `- ${answer}`).join("\n"))
              .concat("\n\n");
            html = html.concat(
              answers.map((answer) => `<li>${answer}</li>`).join("")
            );
          }
          html = html.concat("</ul><br>");
        });
        copy({ plain, html });
        toast({
          title: "Notes copied to clipboard",
          status: "success",
        });
        LogRocket.track(filterId ? "ai-notes-copy-block" : "ai-notes-copy-all");
        sendGAEvent(
          filterId ? "ai_notes_copy_block" : "ai_notes_copy_all",
          "call_review"
        );
      } else {
        toast({
          title: "No notes to copy",
          status: "warning",
        });
      }
    },
    [questionsAndAnswers, call, toast]
  );

  const [updateAiNoteAnswerMutation] = useUpdateAiAnswerMutation();
  const updateAiNote = (itemId: string, newValue: string): void => {
    updateAiNoteAnswerMutation({
      variables: {
        answerItemId: itemId,
        text: newValue,
      },
      onError: () => {
        toast({
          title: "Error updating note",
          status: "error",
        });
      },
    });
  };

  const [updateAiNoteQuestionMutation] = useUpdateAiQuestionMutation();
  const updateAiNoteQuestion = (noteId: string, newValue: string): void => {
    updateAiNoteQuestionMutation({
      variables: {
        callAiNoteId: noteId,
        text: newValue,
      },
      onError: () => {
        toast({
          title: "Error updating question",
          status: "error",
        });
      },
    });
  };

  const [createAiNoteTagsMutation] = useCreateAiNoteTagsMutation();
  const createAiNoteTags = (): void => {
    createAiNoteTagsMutation({
      variables: { callId: call.id },
    });
  };

  return {
    data: {
      aiNotes: aiNotesData?.callAiNotes?.questionsAndAnswers ?? [],
      loading,
      error,
      status: aiNotesStatus,
    },
    regenerate,
    copyAiNotes,
    stopPolling,
    updateAiNote,
    updateAiNoteQuestion,
    createAiNoteTags,
  };
};

export default useAiNotes;
