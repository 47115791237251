import React, { PropsWithChildren, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  SidebarNavBase,
  SpotlightSearchState,
  useNavItems,
} from "../../../components";
import { focusSearchBox } from "../../../components/Search/SearchBox";
import { getEnabledProducts } from "../../../utils/enabledProducts";
import { CurrentUserFragment } from "../../graphql";
import { useFeatureFlagForCurrentOrImpersonatedUser } from "../../graphql/hooks/useFeatureFlag";
import useSignOut from "../../hooks/useSignOut";
import CallInProgressBanner from "../CallInProgressBanner";

type SidebarNavProps = {
  currentUser?: CurrentUserFragment;
  searchState?: SpotlightSearchState;
};

export const SidebarNav: React.FC<PropsWithChildren<SidebarNavProps>> = ({
  currentUser,
  searchState,
  children,
}) => {
  const planPOCEnabled = useFeatureFlagForCurrentOrImpersonatedUser("plan-poc");
  const location = useLocation();
  const isSearchPage = location.pathname === "/search";

  const onSignOut = useSignOut({ currentUser });

  const [skipAnimationDelay, setSkipAnimationDelay] = useState(false);
  const onSearchClick = (): void => {
    if (!isSearchPage) {
      setSkipAnimationDelay(true);
      searchState?.onOpen();
    } else {
      focusSearchBox();
    }
  };

  useEffect(() => {
    setSkipAnimationDelay(true);
    if (isSearchPage) {
      searchState?.onClose();
    }
  }, [location.pathname]);

  const [navItems, footerNavItems] = useNavItems({
    app: "main",
    currentUser,
    enabledProducts: getEnabledProducts(currentUser),
    availableProducts: planPOCEnabled ? ["interview", "plan"] : ["interview"],
    onSignOut,
    onClick: (navItem) => {
      if (navItem.label === "Search") {
        onSearchClick();
      }
    },
  });

  useEffect(() => {
    const showSearchOnSlash = (e: KeyboardEvent): void => {
      const target = e.target as HTMLElement | null;
      const shouldForwardToTarget =
        target &&
        (["INPUT", "TEXTAREA", "SELECT"].includes(target.tagName) ||
          target.isContentEditable);

      if (e.key === "/" && !shouldForwardToTarget) {
        e.preventDefault();
        onSearchClick();
      }
    };

    window.addEventListener("keydown", showSearchOnSlash, true);
    return () => window.removeEventListener("keydown", showSearchOnSlash, true);
  }, []);

  return (
    <SidebarNavBase
      app="main"
      navItems={navItems}
      onShowSearch={onSearchClick}
      searchState={searchState}
      footerNavItems={footerNavItems}
      skipAnimationDelay={skipAnimationDelay}
      onAnimationComplete={() => setSkipAnimationDelay(false)}
      isImpersonated={currentUser?.isImpersonated}
    >
      {currentUser && <CallInProgressBanner />}
      {children}
    </SidebarNavBase>
  );
};
