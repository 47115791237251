import { ChakraProvider, useTheme as useThemeChakra } from "@chakra-ui/react";
import React from "react";

import useFeatureFlag from "../../main/graphql/hooks/useFeatureFlag";
import usePlanFeatureFlag from "../../plan/graphql/hooks/useFeatureFlag";
import theme, { interTheme, Theme } from "../../theme";

interface ThemedChakraProviderProps {
  children: React.ReactNode;
}

export const ThemedChakraProvider: React.FC<ThemedChakraProviderProps> = ({
  children,
}) => {
  const isInterInterview = useFeatureFlag("inter");
  const isInterPlan = usePlanFeatureFlag("inter");
  const isInter = isInterInterview || isInterPlan;
  return (
    <ChakraProvider theme={isInter ? interTheme : theme}>
      {children}
    </ChakraProvider>
  );
};

export const StorybookThemedChakraProvider: React.FC<
  ThemedChakraProviderProps
> = ({ children }) => {
  const isInterInterview = useFeatureFlag("inter");
  const isInterPlan = usePlanFeatureFlag("inter");
  const isInter = isInterInterview || isInterPlan;
  return (
    <ChakraProvider theme={isInter ? interTheme : theme}>
      {children}
    </ChakraProvider>
  );
};

export const useTheme = (): Theme => {
  return useThemeChakra<Theme>();
};
