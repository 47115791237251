import React from "react";
import { useParams } from "react-router-dom";

import { MetricName } from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../../hooks/useCurrentUser";
import AnalyticsMetricsContent from "./AnalyticsMetricsContent";
import useAnalyticsConfig from "./useAnalyticsConfig";
import useAnalyticsData from "./useAnalyticsData";
import { canViewAllMetrics } from "./utils";

const AnalyticsMetrics: React.FC = () => {
  const { metric: metricParam } = useParams<{ metric: string }>();
  const metric = metricParam?.toUpperCase() as MetricName;
  const analyticsConfig = useAnalyticsConfig(metric);
  const hcaMetricsEnabled = useFeatureFlag("analytics:hca-metrics");
  const departmentFilterEnabled = useFeatureFlag("department_filter");
  const stageFilterEnabled = useFeatureFlag("stage_filter");
  const release2Enabled = useFeatureFlag("analytics:release2");
  const currentUser = useCurrentUser();
  const canViewMetrics = canViewAllMetrics(currentUser);
  const analyticsData = useAnalyticsData(analyticsConfig, {
    skipAllQueries: !canViewMetrics,
  });
  const genderSegmentationEnabled = useFeatureFlag(
    "analytics:gender-segmentation"
  );

  return (
    <AnalyticsMetricsContent
      analyticsConfig={analyticsConfig}
      analyticsData={analyticsData}
      flaggedFeatures={{
        genderSegmentation: genderSegmentationEnabled,
        togglePerformanceOverlay: hcaMetricsEnabled,
        drilldowns:
          departmentFilterEnabled && stageFilterEnabled && release2Enabled,
      }}
    />
  );
};

export default AnalyticsMetrics;
