import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

export const CopyIcon = (props: IconProps): JSX.Element => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.25 1.5C16.4331 1.5 17.4549 2.18436 17.9435 3.17789C18.4411 3.22029 18.9365 3.27017 19.4298 3.32741C20.9272 3.50119 22 4.78722 22 6.25699V19.5C22 21.1569 20.6569 22.5 19 22.5H8.5C6.84315 22.5 5.5 21.1569 5.5 19.5V16.75C5.5 16.3358 5.83579 16 6.25 16C6.66421 16 7 16.3358 7 16.75V19.5C7 20.3284 7.67157 21 8.5 21H19C19.8284 21 20.5 20.3284 20.5 19.5V6.25699C20.5 5.51078 19.9607 4.89909 19.2569 4.81741C18.9175 4.77802 18.577 4.74223 18.2354 4.71007C18.1332 5.43905 17.5071 6 16.75 6H10.75C9.99287 6 9.36676 5.43905 9.26459 4.71007C8.92303 4.74223 8.58253 4.77802 8.24314 4.81741C7.53933 4.89909 7 5.51078 7 6.25699V10.25C7 10.6642 6.66421 11 6.25 11C5.83579 11 5.5 10.6642 5.5 10.25V6.25699C5.5 4.78722 6.57283 3.50119 8.07022 3.32741C8.56347 3.27017 9.05894 3.22029 9.55654 3.17789C10.0451 2.18436 11.0669 1.5 12.25 1.5H15.25ZM10.8061 4.09149C10.9839 3.46099 11.5641 3 12.25 3H15.25C15.9359 3 16.5161 3.46099 16.6939 4.09149C16.7303 4.2205 16.75 4.35733 16.75 4.5H10.75C10.75 4.35733 10.7697 4.2205 10.8061 4.09149Z"
      fill="currentColor"
    />
    <path
      d="M11.4694 9.71967C11.1765 10.0126 11.1765 10.4874 11.4694 10.7803L13.3712 12.6821L2.74969 12.6821C2.33548 12.6821 1.99969 13.0179 1.99969 13.4321C1.99969 13.8463 2.33548 14.1821 2.74969 14.1821L13.3709 14.1821L11.4694 16.0836C11.1765 16.3765 11.1765 16.8514 11.4694 17.1443C11.7623 17.4372 12.2371 17.4372 12.53 17.1443L15.5352 14.1391C15.9258 13.7486 15.9258 13.1154 15.5352 12.7249L12.53 9.71967C12.2371 9.42678 11.7623 9.42678 11.4694 9.71967Z"
      fill="currentColor"
    />
  </Icon>
);
