import { Box, Button, ButtonGroup, Flex, useTheme } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { FiExternalLink } from "react-icons/fi";
import { HiOutlineBell, HiOutlineBellSlash } from "react-icons/hi2";
import { IoPeopleSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import {
  GreenhouseIcon,
  LeverIcon,
  PageTitle,
  SmartrecruitersIcon,
  useToast,
} from "../../../components";
import { buildLeverLink } from "../../../utils/lever";
import { useCandidateAlertEnabled } from "../../components/CandidateAlert/useCandidateAlertEnabled";
import {
  CandidateAlertUserSubscriptionsQuery,
  Client,
  Position,
  useAddCandidateAlertUserSubscriptionsMutation,
  useCandidateAlertUserSubscriptionsQuery,
  useMuteCandidateAlertUserSubscriptionsMutation,
} from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";
import useLink from "../../hooks/useLink";

interface PositionHeaderProps {
  position: Pick<
    Position,
    | "id"
    | "title"
    | "greenhouseLink"
    | "leverPostingId"
    | "customAtsUrl"
    | "smartrecruitersLink"
  > & { client: Pick<Client, "name"> };
  canEdit: boolean;
  loading: boolean;
  handleSubmitTitle: (value: string) => void;
}

type PositionSubscription =
  CandidateAlertUserSubscriptionsQuery["candidateAlertUserSubscriptions"]["positionSubscriptions"][0];

const PositionHeader: React.FC<PositionHeaderProps> = ({
  position,
  canEdit,
  loading,
  handleSubmitTitle,
}) => {
  const navigate = useNavigate();
  const hiringTeamLink = useLink({
    type: "hiringTeam",
    positionId: position.id,
  });
  const theme = useTheme();
  const toast = useToast();
  const currentUser = useCurrentUser();
  const { colors } = theme;
  const candidateAlertsEnabled = useCandidateAlertEnabled(
    currentUser.organization.id
  );

  const [currentAlertSubscription, setCurrentAlertSubscription] = useState<
    PositionSubscription | undefined
  >();
  const { data: candidateAlertSubscriptions, refetch } =
    useCandidateAlertUserSubscriptionsQuery();
  useMemo(() => {
    setCurrentAlertSubscription(
      candidateAlertSubscriptions?.candidateAlertUserSubscriptions.positionSubscriptions.find(
        (item) => {
          if (item.positionId === position.id) return true;
          return false;
        }
      )
    );
  }, [candidateAlertSubscriptions]);
  const [addSubscription, { loading: addLoading }] =
    useAddCandidateAlertUserSubscriptionsMutation({
      onCompleted: (data) => {
        if (data.addCandidateAlertUserSubscriptions) {
          toast({
            title: "Success",
            description:
              "You have subscribed to all future candidate alerts for this position",
            status: "success",
          });
          refetch();
        }
      },
    });
  const [muteSubscription, { loading: muteLoading }] =
    useMuteCandidateAlertUserSubscriptionsMutation({
      onCompleted: (data) => {
        if (data.muteCandidateAlertUserSubscriptions) {
          toast({
            title: "Success",
            description:
              "You have muted all future candidate alerts for this position",
            status: "success",
          });
          refetch();
        }
      },
    });
  const subscriptionLoading = addLoading || muteLoading;

  return (
    <Flex
      direction={["column", "column", "row"]}
      align="start"
      justify="space-between"
    >
      <Box flex="1">
        <PageTitle
          titleText={position.title}
          secondaryText={position.client.name}
          editable={canEdit && !loading}
          onTitleSubmit={handleSubmitTitle}
          mb={[4, 4, 10]}
        />
      </Box>
      <ButtonGroup spacing="2" size="sm" variant="ghost" mb={[10, 10, 0]}>
        {!!position.greenhouseLink && (
          <Button
            as="a"
            href={position.greenhouseLink}
            target="_blank"
            leftIcon={
              <GreenhouseIcon marginRight="0" fill={colors.blue[600]} />
            }
            colorScheme="blue"
            aria-label="Greenhouse"
            borderRadius="base"
          >
            View in Greenhouse
          </Button>
        )}
        {candidateAlertsEnabled &&
          (currentAlertSubscription?.mutedAt ||
            currentAlertSubscription === undefined) && (
            <Button
              colorScheme="blue"
              aria-label="Subscribe to alerts"
              borderRadius="base"
              leftIcon={<HiOutlineBell height="12px" />}
              isLoading={subscriptionLoading}
              onClick={() => {
                addSubscription({
                  variables: {
                    positionIds: [position.id],
                    candidateIds: [],
                  },
                });
              }}
            >
              Subscribe to alerts
            </Button>
          )}
        {candidateAlertsEnabled &&
          currentAlertSubscription &&
          !currentAlertSubscription.mutedAt && (
            <Button
              colorScheme="blue"
              aria-label="Subscribe to alerts"
              borderRadius="base"
              leftIcon={<HiOutlineBellSlash height="12px" />}
              isLoading={subscriptionLoading}
              onClick={() => {
                muteSubscription({
                  variables: {
                    positionIds: [position.id],
                    candidateIds: [],
                  },
                });
              }}
            >
              Mute alerts
            </Button>
          )}
        {!!position.leverPostingId && (
          <Button
            as="a"
            href={buildLeverLink(position.leverPostingId)}
            target="_blank"
            leftIcon={
              <LeverIcon
                height="12px"
                marginRight="0"
                fill={colors.blue[600]}
              />
            }
            colorScheme="blue"
            aria-label="Lever"
            borderRadius="base"
          >
            View in Lever
          </Button>
        )}
        {!!position.customAtsUrl && (
          <Button
            as="a"
            href={position.customAtsUrl}
            target="_blank"
            leftIcon={<FiExternalLink height="12px" />}
            colorScheme="blue"
            aria-label="View in your ATS"
            borderRadius="base"
          >
            ATS
          </Button>
        )}
        {!!position.smartrecruitersLink && (
          <Button
            as="a"
            href={position.smartrecruitersLink}
            target="_blank"
            leftIcon={
              <SmartrecruitersIcon
                height="14px"
                width="11.45px"
                marginRight="0"
                fill={colors.blue[600]}
              />
            }
            colorScheme="blue"
            aria-label="Smartrecruiters"
            borderRadius="base"
          >
            View in SmartRecruiters
          </Button>
        )}
        <Button
          onClick={() => navigate(hiringTeamLink)}
          leftIcon={<IoPeopleSharp height="12px" />}
          colorScheme="blue"
          aria-label="Lever"
          borderRadius="base"
        >
          View hiring team
        </Button>
      </ButtonGroup>
    </Flex>
  );
};

export default PositionHeader;
