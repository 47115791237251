import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const BhLogo = (props: IconProps): JSX.Element => (
  <Icon {...props} viewBox="0 0 17 18">
    <svg
      width="17"
      height="18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1213 11.1213L16 16L11.1213 11.1213ZM9 9L16 2L9 9ZM9 9L6.12132 11.8787L9 9ZM9 9L6.12132 6.12132L9 9ZM6.12132 11.8787C5.57843 11.3358 4.82843 11 4 11C2.34315 11 1 12.3431 1 14C1 15.6569 2.34315 17 4 17C5.65685 17 7 15.6569 7 14C7 13.1716 6.66421 12.4216 6.12132 11.8787ZM6.12132 6.12132C6.66421 5.57843 7 4.82843 7 4C7 2.34315 5.65685 1 4 1C2.34315 1 1 2.34315 1 4C1 5.65685 2.34315 7 4 7C4.82843 7 5.57843 6.66421 6.12132 6.12132Z"
        fill="white"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Icon>
);

export default BhLogo;
