import { MutableRefObject, useLayoutEffect, useState } from "react";

const useElementHeight = <T extends HTMLElement>(
  ref: MutableRefObject<T | null>
): number => {
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setHeight(ref.current?.offsetHeight ?? 0);
  }, [ref.current?.offsetHeight]);

  return height;
};

export default useElementHeight;
