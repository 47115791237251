import {
  CurrentUserFragment as CurrentInterviewUserFragment,
  UserRoleName,
} from "../main/graphql";
import { CurrentPlanUserFragment } from "../plan/graphql";

type CurrentUserFragment =
  | CurrentInterviewUserFragment
  | CurrentPlanUserFragment;

export const canManageTraining = (
  user: CurrentInterviewUserFragment | CurrentPlanUserFragment
): boolean => {
  if (user.userRole?.canCreateTrainings) return true;

  const membershipWithPermission = user.memberships.find((m) => {
    return m.role.canCreateTrainings;
  });

  return membershipWithPermission !== undefined;
};

export const canViewAllMetrics = (user: CurrentUserFragment): boolean => {
  const isSiteAdmin = user.userRole?.name === UserRoleName.SiteAdmin;
  const isHiringAdmin = !!user.memberships.find(
    (m) => m.role.canManageHiringTeam
  );

  return isSiteAdmin || isHiringAdmin;
};
