import { Box, Icon, IconProps, Tooltip, TooltipProps } from "@chakra-ui/react";
import React from "react";
import { IconType } from "react-icons";
import { IoIosHelpCircle } from "react-icons/io";

type TooltipIconProps = {
  label: string;
  placement?: TooltipProps["placement"];
  icon?: IconType;
  openDelay?: number;
  hasArrow?: boolean;
} & Omit<IconProps, "icon">;

export const TooltipIcon: React.FC<TooltipIconProps> = ({
  label,
  placement,
  icon = IoIosHelpCircle,
  openDelay,
  hasArrow = false,
  ...rest
}) => (
  <Tooltip
    hasArrow={hasArrow}
    label={label}
    placement={placement}
    openDelay={openDelay}
    cursor="pointer"
  >
    <Box display="flex">
      <Icon as={icon} {...rest} />
    </Box>
  </Tooltip>
);
