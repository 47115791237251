import React from "react";

const AILoading: React.FC = () => {
  return (
    <img
      src="/static/images/AILoading.gif"
      style={{ width: 210, height: 210 }}
    />
  );
};

export default AILoading;
