import { Box } from "@chakra-ui/react";
import React from "react";

type DashboardCardInfoContentProps = {
  children: JSX.Element;
};

const DashboardCardInfoContent: React.FC<DashboardCardInfoContentProps> = ({
  children,
}) => (
  <Box fontSize="sm" color="gray.800">
    {children}
  </Box>
);

export default DashboardCardInfoContent;
