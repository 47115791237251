import {
  AspectRatio,
  Box,
  Flex,
  Icon,
  Image,
  StyleProps,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  IoCall,
  IoCut,
  IoHourglassOutline,
  IoVideocamOffSharp,
} from "react-icons/io5";

import { AudioImage, PlayIcon, useTheme } from "../../../components";
import { formatDuration } from "../../../utils/datetime";
import { Maybe } from "../../graphql";
import { MediaActions, MediaActionsType } from "../MediaActions";
import MonospacedText from "../MonospacedText";
import { ThumbnailSizes } from "./utils";

const ASPECT_RATIO = 253 / 168;

export type ThumbnailStates =
  | "processing"
  | "inProgress"
  | "missing"
  | undefined;

export interface ThumbnailProps {
  size?: string;
  width?: number;
  mr?: number;
  imageUrl?: Maybe<string>;
  isPlayable?: boolean;
  audioOnly?: boolean;
  iconSize?: string | number;
  placeholder?: ThumbnailStates;
  time?: Maybe<number>;
  jumpTo?: boolean;
  onClick?: () => void;
  actions?: MediaActionsType;
  fullVideoVariant?: boolean;
  timeFontSize?: string | Array<string | null>;
  isClip?: boolean;
  isDisabled?: boolean;
  noPlayIcon?: boolean;
  aspectRatio?: number;
  borderRadius?: StyleProps["borderRadius"];
  imageScale?: number;
}

const Thumbnail: React.FC<ThumbnailProps> = ({
  size = "auto",
  width,
  mr,
  imageUrl,
  isPlayable = "true",
  audioOnly,
  iconSize,
  placeholder = "missing",
  time,
  jumpTo,
  onClick,
  actions,
  fullVideoVariant,
  timeFontSize = "xl",
  isClip = false,
  isDisabled = false,
  noPlayIcon,
  borderRadius,
  aspectRatio = ASPECT_RATIO,
  imageScale,
}) => {
  const { colors, overlays } = useTheme();
  const [thumbnailSize, setThumbnailSize] = useState(ThumbnailSizes[size]);
  useEffect(() => {
    if (width) setThumbnailSize({ ...thumbnailSize, width: `${width}px` });
  }, [width]);

  const {
    isOpen: isHovering,
    onOpen: onMouseEnter,
    onClose: onMouseLeave,
  } = useDisclosure();

  const placeholders = {
    processing: {
      icon: IoHourglassOutline,
      text: "Processing",
    },
    missing: {
      icon: IoVideocamOffSharp,
      text: "No Recording",
    },
    inProgress: {
      icon: IoCall,
      text: "In Progress",
    },
  };

  const placeholderContent = (
    <Flex
      w="100%"
      h="100%"
      direction="column"
      alignItems="center"
      justifyContent="center"
      color="gray.400"
      bg="gray.100"
      data-testid="thumbnail-placeholder"
    >
      <Icon as={placeholders[placeholder].icon} boxSize="6" mt="1" mb="2" />
      <Text as="span" fontSize="xs" lineHeight="150%" fontWeight="bold">
        {placeholders[placeholder].text}
      </Text>
    </Flex>
  );

  const mediaContent = (
    <Box
      height="100%"
      position="relative"
      bg="gray.800"
      data-testid="thumbnail-media"
    >
      {audioOnly && (
        <AudioImage
          width={thumbnailSize.width}
          backgroundImage="/static/images/rectangle.png"
          backgroundSize="cover"
        />
      )}
      {!audioOnly && imageUrl && (
        <Image
          src={imageUrl}
          height="100%"
          fit="cover"
          transform={imageScale ? `scale(${imageScale})` : undefined}
        />
      )}
      {!isDisabled && (
        <Flex
          position="absolute"
          w="100%"
          h="100%"
          top="0"
          left="0"
          justifyContent="center"
          alignItems="center"
          bg={isHovering ? overlays.lightHover : overlays.light}
          transition="background .2s ease-out;"
        >
          {fullVideoVariant ? (
            <Flex
              direction="row"
              alignItems="center"
              justifyContent="center"
              px="4"
              py="1"
              borderRadius="full"
              bg={colors.blackAlpha[500]}
              color="white"
              fontSize={timeFontSize}
              fontWeight="bold"
            >
              {!noPlayIcon && <PlayIcon width="18px" height="18px" />}
              {!!time && (
                <Box ml="2">
                  <MonospacedText text={formatDuration(time, false)} />
                </Box>
              )}
            </Flex>
          ) : (
            !noPlayIcon && (
              <PlayIcon
                width={iconSize ?? thumbnailSize.iconSize}
                height={iconSize ?? thumbnailSize.iconSize}
              />
            )
          )}
        </Flex>
      )}
      {!!time && !fullVideoVariant && !isDisabled && (
        <Flex
          position="absolute"
          bottom={thumbnailSize.durationInset}
          right={thumbnailSize.durationInset}
          h={thumbnailSize.durationHeight}
          px={thumbnailSize.durationPadding}
          alignItems="center"
          justifyContent="center"
          bg={overlays.medium}
          color="white"
          fontSize={thumbnailSize.durationFontSize}
          fontWeight="medium"
          borderRadius={thumbnailSize.durationBorderRadius}
          lineHeight="0"
        >
          {jumpTo && <>Skip to&nbsp;</>}
          <MonospacedText text={formatDuration(time, false)} />
        </Flex>
      )}
      {isClip && !fullVideoVariant && (
        <Flex
          alignItems="center"
          bg="gray.50"
          borderRadius="28px"
          color="gray.600"
          fontSize={thumbnailSize.durationFontSize}
          fontWeight="bold"
          h={thumbnailSize.durationHeight}
          justifyContent="center"
          lineHeight="0"
          ml="auto"
          position="absolute"
          px={thumbnailSize.durationPadding}
          py="0.5"
          right={thumbnailSize.durationInset}
          top={thumbnailSize.durationInset}
        >
          {size !== "sm" && <IoCut size="12" />}
          <Text
            fontSize={size === "sm" ? "xs" : "sm"}
            ml={size === "sm" ? 0 : 1}
          >
            Clip
          </Text>
        </Flex>
      )}
    </Box>
  );

  return (
    <Box
      position="relative"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-testid="thumbnail"
    >
      {isHovering && actions && <MediaActions actions={actions} />}
      <AspectRatio
        flex="0 0 auto"
        mr={mr}
        ratio={aspectRatio}
        width={thumbnailSize.width}
        maxW={thumbnailSize.width}
        borderRadius={borderRadius ?? thumbnailSize.borderRadius}
        overflow="hidden"
        position="relative"
        onClick={onClick}
        cursor="pointer"
      >
        {isPlayable ? mediaContent : placeholderContent}
      </AspectRatio>
    </Box>
  );
};

export default Thumbnail;
