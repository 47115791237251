import React, { forwardRef } from "react";
import Select from "react-select";

import { Alert } from "../../../components";
import useSelectTheme from "../../../hooks/useSelectTheme";
import { useBullhornCommentActionsQuery } from "../../graphql";

interface BullhornActionSelectProps {
  name?: string;
  placeholder?: string;
  onSelect: (action?: string) => void;
  isDisabled?: boolean;
  defaultAction?: string | null;
}

const BullhornActionSelect = forwardRef<any, BullhornActionSelectProps>(
  ({ name, placeholder, onSelect, isDisabled = false, defaultAction }, ref) => {
    const [theme, styles] = useSelectTheme();
    const { data, loading, error } = useBullhornCommentActionsQuery();
    const actions = data?.bullhornCommentActions ?? [];

    if (error) {
      return (
        <Alert status="error" description="Error loading bullhorn actions" />
      );
    }

    return (
      <Select
        ref={ref}
        name={name}
        theme={theme}
        styles={styles}
        isClearable
        placeholder={placeholder}
        isLoading={loading}
        defaultValue={
          defaultAction
            ? {
                label: defaultAction,
                value: defaultAction,
              }
            : undefined
        }
        options={actions?.map((action) => ({
          label: action,
          value: action,
        }))}
        onChange={(option: any) => {
          onSelect(option?.value ?? undefined);
        }}
        isDisabled={isDisabled}
      />
    );
  }
);

BullhornActionSelect.displayName = "BullhornActionSelect";
export default BullhornActionSelect;
