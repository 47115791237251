import { AvatarGroup, AvatarGroupProps, Tooltip } from "@chakra-ui/react";
import React from "react";

import { Avatar } from "../../../components";
import { ExternalUser, UserFragment } from "../../graphql";
import SharedWithAvatar from "../ShareModal/SharedWithAvatar";

type InternalViewer = {
  isInterviewer?: boolean;
} & Pick<
  UserFragment,
  "id" | "fullName" | "firstName" | "lastName" | "profilePicUrl" | "email"
>;

export type ExternalViewers = {
  externalViewers: Pick<ExternalUser, "id" | "email">[];
};

type CallViewersProps = {
  internalViewers: InternalViewer[];
} & ExternalViewers &
  Omit<AvatarGroupProps, "children">;

const CallViewers = React.forwardRef<HTMLDivElement, CallViewersProps>(
  (
    { internalViewers, externalViewers, size = "sm", spacing = "-2", ...rest },
    ref
  ) => {
    let label = internalViewers.reduce(
      (prev, viewer) =>
        `${prev} ${viewer.fullName}${
          viewer.isInterviewer ? " (Interviewer)\n" : "\n"
        }`,
      ""
    );
    if (externalViewers.length) {
      label += externalViewers.reduce(
        (prev, viewer) => `${prev} ${viewer.email}`,
        ""
      );
    }
    return (
      <Tooltip whiteSpace="pre-wrap" label={label}>
        <AvatarGroup
          ref={ref}
          max={5}
          size={size}
          spacing={spacing}
          {...rest}
          data-testid="viewers"
        >
          {internalViewers.map((viewer) => (
            <Avatar
              key={viewer.id}
              user={viewer}
              isInterviewer={viewer.isInterviewer}
            />
          ))}
          {externalViewers.map((viewer) => (
            <SharedWithAvatar
              key={viewer.id}
              user={viewer}
              width="7"
              height="7"
            />
          ))}
        </AvatarGroup>
      </Tooltip>
    );
  }
);

export default CallViewers;
