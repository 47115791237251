import { Button, ButtonProps, Flex, IconButton } from "@chakra-ui/react";
import React from "react";

import {
  GreenhouseIcon,
  LeverIcon,
  Tooltip,
  TooltipProps,
  useTheme,
} from "../../../../components";
import CopyOutlineIcon from "../../../../components/Icons/CopyOutlineIcon";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { Ats } from "../../../graphql";

export interface CopyNotesV3ButtonsProps extends ButtonProps {
  onCopy(): void;
  onAutofill?(): void;
  autofillEnabled: boolean;
  ats?: Ats;
}

/**
 * This component will render two buttons in the case that autofill is available,
 * and one button otherwise
 *   - When autofill is available the primary button is for
 *     autofill and there is a smaller icon button for copy notes.
 *   - When autofill is not available, copy notes is the only button
 */
const CopyNotesV3Buttons: React.FC<CopyNotesV3ButtonsProps> = ({
  onCopy,
  onAutofill: onAutofillProp,
  autofillEnabled,
  ats,
  ...rest
}) => {
  const { colors } = useTheme();
  const sendGAEvent = useSendGAEvent();

  const isGreenhouse = autofillEnabled && ats === Ats.Greenhouse;
  const isLever = autofillEnabled && ats === Ats.Lever;

  const onAutofill = autofillEnabled
    ? (): void => {
        sendGAEvent("autofill_scorecard", "call_review", "call_details");
        onAutofillProp?.();
      }
    : undefined;

  const primaryColor = colors.blue[600];
  const secondaryColor = colors.blue[50];
  const tertiaryColor = colors.blue[100];

  const tooltipStyles: Partial<TooltipProps> = {
    padding: "4px 12px",
    bgColor: "gray.700",
    fontSize: "14px",
  };

  return (
    <Flex alignItems="center">
      <Button
        size="xs"
        fontSize="sm"
        fontWeight="medium"
        variant="ghost"
        colorScheme="blue"
        data-tour-id="one-click-complete-scorecard"
        ml="3"
        mr="2"
        height="8"
        leftIcon={
          isGreenhouse ? (
            <GreenhouseIcon fill="currentColor" height="24px" />
          ) : isLever ? (
            <LeverIcon fill="currentColor" height="16px" />
          ) : (
            <CopyOutlineIcon width="16px" height="16px" />
          )
        }
        onClick={onAutofill ?? onCopy}
        {...rest}
      >
        {isGreenhouse
          ? "Complete scorecard"
          : isLever
          ? "Complete feedback form"
          : "Copy all notes"}
      </Button>

      {/* Secondary "copy notes" icon button, only shown if autofill is available */}
      {(isGreenhouse || isLever) && (
        <Tooltip
          id="copyAllNotes"
          placement="top"
          {...tooltipStyles}
          trigger={
            <IconButton
              icon={<CopyOutlineIcon width="16px" height="16px" />}
              {...rest}
              aria-label="Copy all notes"
              size="xs"
              ml="2"
              p="2"
              variant="ghost"
              height="8"
              onClick={onCopy}
              color={primaryColor}
              bg="transparent"
              _hover={{ bg: secondaryColor }}
              _active={{
                bg: tertiaryColor,
              }}
            />
          }
        >
          Copy All Notes
        </Tooltip>
      )}
    </Flex>
  );
};

export default CopyNotesV3Buttons;
