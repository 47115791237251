import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";

import { RouterLink } from "../../../../components";
import {
  formatRelativeDate,
  formatTime,
  MS_PER_DAY,
} from "../../../../utils/datetime";
import { sentenceCase } from "../../../../utils/string";
import {
  CallListItemFragment,
  ScheduledInterviewListItemFragment,
} from "../../../graphql";

/**
 * Format the date line under the title
 * @param scheduledStart Start time
 * @param scheduledEnd End time
 */
export const formatDateline = (
  scheduledStart: string,
  scheduledEnd: string
): string => {
  const limit = MS_PER_DAY * 7;
  let dateline = `${formatRelativeDate(scheduledStart, limit)}`;
  if (scheduledStart && scheduledEnd) {
    dateline += ` (${formatTime(scheduledStart)} - ${formatTime(
      scheduledEnd
    )})`;
  } else if (scheduledStart) {
    dateline += ` (${formatTime(scheduledStart)})`;
  }
  return dateline;
};

type InterviewCandidatePositionSectionProps = {
  onClickCandidate?(): void;
  onClickPosition?(): void;
  displayDate?: string;
} & (
  | {
      scheduledInterview: ScheduledInterviewListItemFragment;
      call?: never;
    }
  | {
      scheduledInterview?: never;
      call: CallListItemFragment;
    }
);

const InterviewCandidatePositionSection: React.FC<
  InterviewCandidatePositionSectionProps
> = ({
  scheduledInterview,
  call,
  displayDate,
  onClickCandidate,
  onClickPosition,
}) => {
  const candidate = scheduledInterview?.candidate || call?.candidate;
  const position = scheduledInterview?.position || call?.position;

  let timeToDisplay = "";
  if (scheduledInterview) {
    timeToDisplay = formatDateline(
      scheduledInterview.scheduledStart,
      scheduledInterview.scheduledEnd
    );
  }
  if (call) {
    timeToDisplay = formatRelativeDate(
      displayDate ?? call.startTime ?? call.createdAt
    );
  }

  return (
    <Flex direction="column" minW={0} overflow="hidden">
      <Heading
        as="h6"
        size="sm"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
        lineHeight="6"
        color="gray.500"
        fontWeight="semibold"
      >
        {candidate && (
          <RouterLink
            to={`/candidate/${candidate.id}`}
            color="gray.800"
            _hover={{ textDecoration: "underline", color: "inherit" }}
            minW={0}
            fontWeight="inherit"
            onClick={onClickCandidate}
          >
            {candidate.fullName || "Candidate"}
          </RouterLink>
        )}
        {!candidate && "No candidate selected"}
      </Heading>
      <Box
        fontSize="sm"
        fontWeight="medium"
        lineHeight="5"
        mt="2"
        color="gray.500"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
      >
        {position && (
          <RouterLink
            to={`/position/${position.id}`}
            fontWeight="inherit"
            color="gray.600"
            _hover={{ textDecoration: "underline", color: "inherit" }}
            onClick={onClickPosition}
          >
            {position.displayTitle}
          </RouterLink>
        )}
        {!position && "No position selected"}
      </Box>
      <Text
        fontSize="sm"
        fontWeight="500"
        color="gray.500"
        mt="2"
        lineHeight="5"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
      >
        {sentenceCase(timeToDisplay)}
      </Text>
    </Flex>
  );
};

export default InterviewCandidatePositionSection;
