import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

function useCacheState<TData = any>(
  key: string,
  defaultState: TData
): [TData, (state: TData) => void] {
  const query = gql`
    {
      ${key} @client
    }
  `;
  const { data, client } = useQuery(query);

  const setState = (state: TData): void => {
    client.cache.writeQuery({ query, data: { [key]: state } });
  };

  if (!data) {
    setState(defaultState);
    return [defaultState, setState];
  }
  return [data[key], setState];
}

export default useCacheState;
