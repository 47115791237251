import invariant from "invariant";
import React from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Hit } from "react-instantsearch-core";
import { connectRefinementList } from "react-instantsearch-dom";
import Select from "react-select";

import useSelectTheme from "../../hooks/useSelectTheme";
import { useSendGAEvent } from "../../utils/googleAnalytics";

interface RefinementListProps {
  attribute: string;
  items: Hit<{
    count: number;
    isRefined: boolean;
    label: string;
    value: string[];
  }>[];
  refine: (value: string[]) => any;
  searchForItems: (value: string) => any;
  currentRefinement: string[];
  canRefine: boolean;
}

const RefinementListBase: React.FC<RefinementListProps> = ({
  attribute,
  items,
  refine,
  searchForItems,
  currentRefinement,
  canRefine,
}) => {
  const [theme, styles] = useSelectTheme();
  const sendGAEvent = useSendGAEvent();
  const currentValues = currentRefinement.map((label) => ({
    label,
    value: label,
  }));

  return (
    <Select
      id={attribute}
      theme={theme}
      styles={styles}
      value={currentValues}
      isMulti
      options={items.map(({ label }) => ({
        label,
        value: label,
      }))}
      onChange={(selectedOption) => {
        if (!canRefine) return;
        if (!selectedOption) {
          refine([]);
          return;
        }
        invariant(Array.isArray(selectedOption), "Invalid selectedOption");
        const selectedValues = selectedOption.map((o) => o.value);
        refine(selectedValues);
        sendGAEvent("filter", "search", attribute);
      }}
      onInputChange={searchForItems}
    />
  );
};

const ConnectedRefinementsList = connectRefinementList(RefinementListBase);

const RefinementList: React.FC<any> = (props) => (
  <ConnectedRefinementsList searchable limit={100} {...props} />
);

export default RefinementList;
