import {
  Heading,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  TextProps,
} from "@chakra-ui/react";
import LogRocket from "logrocket";
import React, { useEffect, useState } from "react";
import { MdWarning } from "react-icons/md";

import { useToast } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import {
  CallSpeakerFragment,
  useCallSpeakerOptionsLazyQuery,
  useClipSpeakerOptionsLazyQuery,
  useUpdateCallSpeakerMutation,
} from "../../graphql";

type SpeakerProps = {
  speakerLabel: string;
  showIcon: boolean;
} & TextProps;

type SpeakerOption = {
  id: string;
  label: string;
};

export const Speaker: React.FC<SpeakerProps> = ({
  showIcon,
  speakerLabel,
  ...rest
}) => (
  <Heading as="h3" fontSize="sm" {...rest}>
    {showIcon && <Icon as={MdWarning} color="yellow.400" mb="2px" mr="1" />}
    {speakerLabel}
  </Heading>
);

const sortSpeakerOptions = (
  speakerOptions: SpeakerOption[]
): SpeakerOption[] => {
  speakerOptions.slice().sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });
  return speakerOptions;
};

interface SpeakerMenuProps {
  callId?: string;
  speaker: Pick<CallSpeakerFragment, "label" | "identified" | "speakerTag">;
  isDisabled: boolean;
  clipId?: string;
}

const SpeakerMenu: React.FC<SpeakerMenuProps> = ({
  callId,
  speaker,
  isDisabled,
  clipId,
}) => {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();
  const [speakerOptions, setSpeakerOptions] = useState<SpeakerOption[] | null>(
    null
  );

  const [fetchCallSpeakerOptions] = useCallSpeakerOptionsLazyQuery();
  const [fetchClipSpeakerOptions] = useClipSpeakerOptionsLazyQuery();

  useEffect(() => {
    if (isDisabled || !callId) {
      return;
    }
    if (clipId) {
      fetchClipSpeakerOptions({
        variables: { clipId },
        onError: (err) => {
          toast({ status: "error", title: "Error fetching speaker labels" });
        },
        onCompleted: (data) => {
          if (data.clip) {
            setSpeakerOptions(sortSpeakerOptions(data.clip.callSpeakerOptions));
          }
        },
      });
    } else {
      fetchCallSpeakerOptions({
        variables: { id: callId },
        onError: (err) => {
          toast({ status: "error", title: "Error fetching speaker labels" });
        },
        onCompleted: (data) => {
          if (data.call)
            setSpeakerOptions(sortSpeakerOptions(data.call.speakerOptions));
        },
      });
    }
  }, [isDisabled]);

  const [updateCallSpeakerMutation, { loading: updatingSpeaker }] =
    useUpdateCallSpeakerMutation({
      onError: () => {
        toast({ status: "error", title: "Error updating speaker" });
      },
    });

  const updateCallSpeaker = (speakerOptionId: string): void => {
    if (callId) {
      sendGAEvent("update_speaker", "call_review");
      LogRocket.track("speaker-label-updated");
      updateCallSpeakerMutation({
        variables: {
          callId,
          speakerTag: speaker.speakerTag,
          speakerOptionId,
        },
      });
    }
  };

  if (isDisabled || updatingSpeaker || !speakerOptions) {
    return (
      <Speaker
        speakerLabel={speaker.label}
        showIcon={!speaker.identified && !isDisabled}
      />
    );
  }
  return (
    <Menu placement="right-start">
      <MenuButton
        _hover={{
          color: "link.hover",
        }}
      >
        <Speaker
          speakerLabel={speaker.label}
          showIcon={!speaker.identified && !isDisabled}
        />
      </MenuButton>
      {!clipId && (
        <MenuList>
          {speakerOptions.map((option) => (
            <MenuItem
              key={option.id}
              onClick={() => updateCallSpeaker(option.id)}
            >
              {option.label}
            </MenuItem>
          ))}
        </MenuList>
      )}
    </Menu>
  );
};
SpeakerMenu.displayName = "SpeakerMenu";

export default React.memo(SpeakerMenu);
