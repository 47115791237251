import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

export const NoCandidatesToCompare: React.FC<IconProps> = (props) => (
  <Icon width="240" height="148" viewBox="0 0 240 148" fill="none" {...props}>
    <path
      d="M41.5472 142.481L41.5371 81.4574L63.9641 81.4453L63.9762 142.481H41.5472Z"
      fill="#D3D8DE"
    />
    <path
      d="M52.7714 135.204C55.1182 135.204 57.0193 133.301 57.0193 130.954C57.0193 128.607 55.1182 126.704 52.7714 126.704C50.4227 126.704 48.5215 128.607 48.5215 130.954C48.5215 133.301 50.4227 135.204 52.7714 135.204Z"
      fill="#B8BEC5"
    />
    <path
      d="M19.3207 142.481L19.3086 81.4574L41.7377 81.4453L41.7498 142.481H19.3207Z"
      fill="#E5E7EA"
    />
    <path
      d="M30.5527 135.204C32.8994 135.204 34.8026 133.301 34.8026 130.954C34.8026 128.607 32.8994 126.704 30.5527 126.704C28.2059 126.704 26.3027 128.607 26.3027 130.954C26.3027 133.301 28.2059 135.204 30.5527 135.204Z"
      fill="#B8BEC5"
    />
    <path
      d="M41.543 81.8652V142.489"
      stroke="#67717E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M80.4571 142.864L80.6043 142.088C81.2514 138.553 84.4268 135.872 88.0013 135.872H120.555L119.303 142.904H80.4551L80.4571 142.864Z"
      fill="#E5E7EA"
    />
    <path
      d="M92.8926 142.833L99.5356 105.929H138.384L133.44 133.356C132.448 138.755 127.622 142.833 122.144 142.833H92.8926Z"
      fill="#E5E7EA"
    />
    <path
      d="M65.7753 121.473C59.8722 121.473 54.719 119.183 51.2735 115.026C47.7897 110.792 46.4611 105.197 47.5176 99.1753L58.6948 37.2912C60.8964 25.0152 72.7187 14.9912 84.9948 14.9912H185.727C191.632 14.9912 196.785 17.2835 200.231 21.4407C203.713 25.6745 205.043 31.2692 203.987 37.2912L192.807 99.1753C190.608 111.451 178.786 121.473 166.509 121.473H65.7753Z"
      fill="#E5E7EA"
    />
    <path
      d="M58.2715 93.5314L67.8096 40.621C69.4023 31.8793 77.8054 24.7705 86.5472 24.7705H180.713C184.91 24.7705 188.574 26.4015 191.058 29.3551C193.541 32.345 194.507 36.3469 193.715 40.621L184.138 93.5314H58.2715Z"
      fill="white"
    />
    <path
      d="M117.708 112.908C116.311 112.908 115.087 112.404 114.27 111.433C112.45 109.374 112.938 105.916 115.395 103.741C116.603 102.652 118.184 101.993 119.7 101.993C121.097 101.993 122.321 102.497 123.137 103.469C124.958 105.527 124.47 108.985 122.012 111.16C120.764 112.287 119.224 112.908 117.708 112.908Z"
      fill="#9CA5AE"
    />
    <path
      d="M96.5098 122.278H135.358"
      stroke="#67717E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M123.198 78.123C134.577 78.123 144.728 68.8974 145.871 57.5186C147.012 46.1397 138.712 36.9141 127.333 36.9141C115.954 36.9141 105.803 46.1397 104.662 57.5186C103.519 68.8974 111.819 78.123 123.198 78.123Z"
      fill="#E5E7EA"
    />
    <path
      d="M131.954 69.8694L132.149 67.9097L120.555 67.8916L120.339 70.0488H116.472C113.543 70.0488 111.015 71.7766 109.859 72.7101C117.486 80.3874 130.563 79.7946 139.081 71.3976C139.202 71.2726 139.341 71.1456 139.462 71.0206C138.419 70.2827 137.147 69.8875 135.802 69.8512L131.954 69.8694Z"
      fill="#B8BEC5"
    />
    <path
      d="M115.528 58.5733C116.151 56.4886 116.391 53.9161 116.982 51.5976C117.393 50.015 117.954 48.5593 118.923 47.4807C121.31 44.8376 127.113 43.8114 131.345 47.1561C135.576 50.5008 134.869 55.2104 134.476 57.3334L132.484 64.4704L132.147 68.0126H120.55L120.724 66.0893C120.724 66.0893 118.135 66.6276 116.746 65.6034C114.871 64.2184 114.474 62.0793 115.528 58.5733Z"
      fill="#F5F6F7"
    />
    <path
      d="M118.922 47.4559C121.309 44.8128 127.517 43.1576 131.748 46.5023C135.978 49.847 134.867 55.1856 134.474 57.3086L132.482 64.4456C132.482 64.4456 129.156 63.367 127.974 60.6351C127.974 60.6351 133.178 58.4054 131.234 55.2038C129.367 52.1292 125.192 55.9235 125.192 55.9235C123.089 55.2038 121.224 52.1111 121.224 52.1111C117.952 52.2926 116.982 51.5547 116.982 51.5547C117.392 49.9902 117.952 48.5346 118.922 47.4559Z"
      fill="#9CA5AE"
    />
    <path
      d="M197.286 42.838C208.286 42.838 217.205 33.9209 217.205 22.919C217.205 11.9192 208.286 3 197.286 3C186.284 3 177.367 11.9192 177.367 22.919C177.367 33.9209 186.284 42.838 197.286 42.838Z"
      fill="#9CA5AE"
    />
    <path
      d="M197.286 39.838C208.286 39.838 217.205 30.9209 217.205 19.919C217.205 8.91921 208.286 0 197.286 0C186.284 0 177.367 8.91921 177.367 19.919C177.367 30.9209 186.284 39.838 197.286 39.838Z"
      fill="#D3D8DE"
    />
    <path
      d="M196.915 9.08398H196.883C194.764 9.13842 190.748 10.5436 190.748 15.5073C190.748 16.4447 191.504 17.2008 192.442 17.2008C193.379 17.2008 194.135 16.4447 194.135 15.5073C194.135 12.8017 196.276 12.5033 196.948 12.471C199.109 12.4831 200.452 13.6444 200.452 15.5294C200.452 16.9145 199.877 17.4145 198.365 18.3842C197.012 19.2471 194.966 20.5677 195.062 23.5495V24.0394C195.062 24.9769 195.819 25.7329 196.756 25.7329C197.681 25.7329 198.45 24.9869 198.47 24.0515V23.4547C198.439 22.4628 198.865 22.1019 200.208 21.239L200.228 21.2269C201.665 20.3015 203.839 18.8842 203.839 15.5294C203.839 11.7372 200.996 9.08398 196.915 9.08398Z"
      fill="#F5F6F7"
    />
    <path
      d="M196.865 27.584C195.831 27.584 194.99 28.4247 194.99 29.459C194.99 30.4912 195.831 31.3339 196.865 31.3339C197.908 31.3238 198.75 30.4912 198.74 29.459C198.74 28.4247 197.897 27.584 196.865 27.584Z"
      fill="#F5F6F7"
    />
    <path
      d="M182.137 104.326H173.091C171.179 104.326 169.627 105.878 169.627 107.789C169.627 109.043 170.314 110.209 171.419 110.836L179.57 115.253V142.424H188.258L190.288 132.781C190.826 130.182 193.094 128.331 195.752 128.331H204.888C207.187 128.331 209.247 129.735 210.084 131.884L214.175 142.394H222.505L217.997 109.939C217.818 108.535 216.622 107.521 215.189 107.521C213.965 107.521 212.89 108.297 212.531 109.433L211.576 112.267H192.707L186.586 99.4287C186.288 98.7734 185.512 98.5033 184.855 98.8017C184.556 98.9508 184.346 99.1908 184.227 99.4589L182.137 104.326Z"
      fill="#B8BEC5"
    />
    <path
      d="M2.78625 142.383C1.25804 142.383 0 143.641 0 145.169C0 146.748 1.23385 147.957 2.78625 147.957H237.214C238.742 147.957 240 146.699 240 145.169C240 143.641 238.742 142.383 237.214 142.383H2.78625Z"
      fill="white"
    />
  </Icon>
);

export const NoChaptersToCompare: React.FC<IconProps> = (props) => (
  <Icon width="190" height="167" viewBox="0 0 190 167" fill="none" {...props}>
    <path
      d="M162.955 49.1359C166.689 50.1365 168.905 53.9748 167.904 57.7091L141.767 155.257C140.654 159.41 136.365 161.868 132.186 160.749L8.46862 127.599C4.28981 126.479 1.80484 122.205 2.91768 118.052L29.0555 20.5047C30.056 16.7704 33.8944 14.5543 37.6287 15.5549L162.955 49.1359Z"
      fill="#FFF8DB"
    />
    <path
      d="M38.4486 15.7108L162.166 48.8608C166.345 49.9805 168.823 54.2795 167.702 58.4639L166.07 64.5544L27.2209 27.3499L28.8529 21.2595C29.9741 17.0751 34.2697 14.5911 38.4486 15.7108Z"
      fill="#FFEAAF"
    />
    <path
      d="M39.0404 25.9071C40.3435 26.2562 41.6831 25.48 42.0322 24.177C42.3819 22.8722 41.6093 21.532 40.3062 21.1829C39.005 20.8342 37.664 21.608 37.3143 22.9129C36.9652 24.2159 37.7391 25.5584 39.0404 25.9071Z"
      fill="#FFFCEE"
    />
    <path
      d="M46.7083 27.9608C48.0096 28.3094 49.3511 27.5337 49.7002 26.2307C50.0498 24.9259 49.2754 23.5852 47.9742 23.2366C46.6711 22.8874 45.3319 23.6618 44.9823 24.9666C44.6332 26.2696 45.4052 27.6116 46.7083 27.9608Z"
      fill="#FFFCEE"
    />
    <path
      d="M54.3744 30.0155C55.6774 30.3646 57.0171 29.5884 57.3662 28.2854C57.7159 26.9806 56.9433 25.6404 55.6402 25.2913C54.3371 24.9421 52.998 25.7164 52.6483 27.0213C52.2992 28.3243 53.0713 29.6663 54.3744 30.0155Z"
      fill="#FFFCEE"
    />
    <path
      d="M81.0215 34.5674L115.885 43.9091L81.0215 34.5674Z"
      fill="#F5F6F7"
    />
    <path
      d="M81.0215 34.5674L115.885 43.9091"
      stroke="#FFFCEE"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="49.5508"
      y="59.9248"
      width="36"
      height="24"
      rx="4"
      transform="rotate(15 49.5508 59.9248)"
      fill="#FFDC7F"
    />
    <rect
      x="41.2695"
      y="90.834"
      width="36"
      height="4"
      rx="2"
      transform="rotate(15 41.2695 90.834)"
      fill="#FFEAAF"
    />
    <rect
      x="39.1973"
      y="98.5615"
      width="36"
      height="4"
      rx="2"
      transform="rotate(15 39.1973 98.5615)"
      fill="#FFEAAF"
    />
    <rect
      x="37.127"
      y="106.289"
      width="36"
      height="4"
      rx="2"
      transform="rotate(15 37.127 106.289)"
      fill="#FFEAAF"
    />
    <rect
      x="95.916"
      y="72.3477"
      width="36"
      height="24"
      rx="4"
      transform="rotate(15 95.916 72.3477)"
      fill="#FFDC7F"
    />
    <rect
      x="87.6348"
      y="103.257"
      width="36"
      height="4"
      rx="2"
      transform="rotate(15 87.6348 103.257)"
      fill="#FFEAAF"
    />
    <rect
      x="85.5625"
      y="110.984"
      width="36"
      height="4"
      rx="2"
      transform="rotate(15 85.5625 110.984)"
      fill="#FFEAAF"
    />
    <rect
      x="83.4922"
      y="118.712"
      width="36"
      height="4"
      rx="2"
      transform="rotate(15 83.4922 118.712)"
      fill="#FFEAAF"
    />
    <g style={{ mixBlendMode: "multiply" }} opacity="0.8">
      <path
        d="M153.473 79.6189C164.161 84.0231 176.398 78.9167 180.804 68.213C185.21 57.5092 180.118 45.2618 169.429 40.8577C158.741 36.4535 146.504 41.5599 142.098 52.2637C137.692 62.9675 142.784 75.2148 153.473 79.6189Z"
        fill="#FFDC7F"
      />
    </g>
    <path
      d="M155.473 75.6209C166.161 80.025 178.398 74.9187 182.804 64.2149C187.21 53.5111 182.118 41.2638 171.429 36.8596C160.741 32.4555 148.504 37.5618 144.098 48.2656C139.692 58.9694 144.784 71.2168 155.473 75.6209Z"
      fill="#FFEAAF"
    />
    <path
      d="M167.437 45.5318L167.407 45.5177C165.325 44.7206 160.86 44.4819 158.873 49.312C158.497 50.2228 158.928 51.2619 159.84 51.6378C160.749 52.0132 161.788 51.5793 162.163 50.6685C163.247 48.0362 165.447 48.6016 166.112 48.8397C168.208 49.7166 169.047 51.3818 168.293 53.2165C167.736 54.5637 166.978 54.8214 165.12 55.1595C163.46 55.4569 160.944 55.924 159.842 58.8626L159.646 59.3408C159.271 60.2516 159.702 61.2908 160.613 61.6666C161.514 62.0377 162.556 61.6188 162.954 60.7158L163.193 60.1355C163.559 59.1581 164.117 58.9766 165.766 58.6743L165.792 58.6733C167.559 58.3468 170.238 57.8376 171.583 54.573C173.101 50.8841 171.4 47.1644 167.437 45.5318Z"
      fill="#FFFCEE"
    />
    <path
      d="M159.982 63.4907C158.978 63.0782 157.823 63.5588 157.408 64.5648C156.996 65.5695 157.477 66.7258 158.48 67.1401C159.498 67.5468 160.649 67.075 161.053 66.066C161.466 65.0595 160.986 63.9055 159.982 63.4907Z"
      fill="#FFFCEE"
    />
  </Icon>
);
