import { Box, Flex, FormControl, FormLabel, Switch } from "@chakra-ui/react";
import React from "react";
import { connectRefinementList } from "react-instantsearch-dom";

import { useSendGAEvent } from "../../utils/googleAnalytics";
import { StarRating } from "..";
import { RatingDescriptions } from "../StarRating/StarRating";

const formatRefinement = (
  min: number,
  max: number,
  includeUnrated: boolean
): string[] => {
  const range = max - min + 1;
  const refinement = [...Array(range)].map((_, index) => String(min + index));
  if (includeUnrated) {
    refinement.push("0");
  }
  return refinement;
};

interface RatingProps {
  maxRating?: number;
  attribute: string;
  currentRefinement: string[];
  refine: (value: string[]) => any;
  ratingDescriptions: RatingDescriptions;
}

const Rating: React.FC<RatingProps> = ({
  maxRating = 5,
  attribute,
  currentRefinement,
  refine,
  ratingDescriptions,
}) => {
  const includeUnrated =
    !currentRefinement.length || currentRefinement.includes("0");

  let rating = 1;
  const sendGAEvent = useSendGAEvent();
  if (currentRefinement.length) {
    rating = currentRefinement.reduce<number>((previous, current) => {
      if (current === "0") {
        return previous;
      }
      return Math.min(previous, Number(current));
    }, maxRating);
  }

  const handleChange = (min: number, includeUnrated: boolean): void => {
    if (min === 0) {
      return;
    }
    sendGAEvent("filter", "search", attribute);
    if (min === 1 && includeUnrated) {
      refine([]);
      return;
    }
    const refinement = formatRefinement(min, maxRating, includeUnrated);
    refine(refinement);
  };

  return (
    <Flex alignItems="center">
      <StarRating
        maxRating={maxRating}
        rating={rating}
        onChange={(newRating) => handleChange(newRating, includeUnrated)}
        ratingDescriptions={ratingDescriptions}
      />
      <Box fontSize="xs" ml={1} mr="5" whiteSpace="nowrap">
        &amp; Up
      </Box>
      <FormControl id={attribute} display="flex" alignItems="center">
        <Switch
          id={attribute}
          size="sm"
          isChecked={includeUnrated}
          onChange={() => handleChange(rating, !includeUnrated)}
        />
        <FormLabel
          htmlFor={attribute}
          fontSize="xs"
          fontWeight="normal"
          m={0}
          ml={1}
        >
          Include unrated
        </FormLabel>
      </FormControl>
    </Flex>
  );
};

export default connectRefinementList(Rating);
