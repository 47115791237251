import { ApolloError } from "@apollo/client";
import React from "react";
import { SortingRule } from "react-table";

import { Alert } from "../../../components";
import { PageOptions } from "../../../utils/types";
import { CandidateListItemFragment } from "../../graphql";
import CandidateList from "./CandidateList";

interface PositionCandidatesProps {
  candidates: Array<CandidateListItemFragment>;
  pageOptions: PageOptions;
  loading?: boolean;
  sortBy: SortingRule<string>;
  error?: ApolloError;
  positionId?: string;
}

const PositionCandidates: React.FC<PositionCandidatesProps> = ({
  candidates,
  pageOptions,
  loading,
  sortBy,
  error,
  positionId,
}) => {
  if (error)
    return (
      <Alert
        status="error"
        title="Error loading positions"
        description={error.message}
      />
    );

  return (
    <CandidateList
      candidates={candidates}
      pageOptions={pageOptions}
      loading={loading}
      sortBy={sortBy}
      emptyState="There are no candidates to show here"
      positionId={positionId}
    />
  );
};

export default PositionCandidates;
