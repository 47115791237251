import { Text } from "@chakra-ui/react";
import React from "react";
import { HiOutlineUsers } from "react-icons/hi";

import { MetricName } from "../../graphql";
import DashboardCard from "./DashboardCard";
import { DashboardCardWrapperProps } from "./types";
import useDashboardStat from "./useDashboardStat";
import { getDashboardCardValuesForMetric } from "./utils";

const DashboardCardExperience: React.FC<DashboardCardWrapperProps> = ({
  alerts,
  dashboardConfig,
}) => {
  // ON TIME STARTS STAT
  const onTimeStartsStat = useDashboardStat(
    MetricName.OnTimeInterviews,
    dashboardConfig.queryOptions
  );
  const {
    metricAverageValue: onTimeAverageValue,
    numXValues: onTimeNumXValues,
    delta: onTimeDelta,
  } = getDashboardCardValuesForMetric({
    dataPoints: onTimeStartsStat.dataPoints,
    deltaDataPoints: onTimeStartsStat.deltaDataPoints,
    metric: MetricName.OnTimeInterviews,
  });

  // TALK RATIO STAT
  const talkRatioStat = useDashboardStat(
    MetricName.CandidateTalkRatio,
    dashboardConfig.queryOptions
  );
  const {
    metricAverageValue: talkRatioAverageValue,
    numXValues: talkRatioNumXValues,
    delta: talkRatioDelta,
  } = getDashboardCardValuesForMetric({
    dataPoints: talkRatioStat.dataPoints,
    deltaDataPoints: talkRatioStat.deltaDataPoints,
    metric: MetricName.CandidateTalkRatio,
  });

  const loading = onTimeStartsStat.loading || talkRatioStat.loading;

  return (
    <DashboardCard
      title="Candidate Experience"
      category="candidate_experience"
      icon={HiOutlineUsers}
      stats={[
        {
          title: "On-Time Starts",
          values: [
            {
              value: onTimeNumXValues > 0 ? onTimeAverageValue : undefined,
              format: "percent",
            },
          ],
          delta: onTimeDelta,
          loading,
          metric: MetricName.OnTimeInterviews,
          dashboardConfig,
        },
        {
          title: "Candidate Talk Ratio",
          values: [
            {
              value:
                talkRatioNumXValues > 0 ? talkRatioAverageValue : undefined,
              format: "percent",
            },
          ],
          benchmark: talkRatioStat.benchmark,
          delta: talkRatioDelta,
          loading,
          metric: MetricName.CandidateTalkRatio,
          dashboardConfig,
        },
      ]}
      alerts={alerts}
      dashboardConfig={dashboardConfig}
      infoContent={
        <>
          <Text mb="3">
            <Text as="b">On-Time Starts</Text> is calculated as how often the
            interviewer started speaking within five minutes of the scheduled
            interview start, averaged across interviews over the selected date
            range. Interviews without a scheduled start time are excluded from
            this report.
          </Text>
          <Text mb="3">
            <Text as="b">Candidate Talk Ratio</Text> is calculated using the
            total time the candidate is speaking as a share of the total
            duration of the interview, averaged over the selected date range.
          </Text>
          <Text mb="3">
            Benchmark: 50% of interviews fall within this benchmark range of 39%
            - 60% based on data from our customer base.
          </Text>
        </>
      }
    />
  );
};

export default DashboardCardExperience;
