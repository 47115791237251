export default {
  variants: {
    popover: {
      td: {
        color: "gray.800",
        fontFamily: "inherit",
        fontSize: "sm",
        fontWeight: 400,
        textTransform: "unset",
        letterSpacing: "unset",
        py: 3,
      },
      th: {
        color: "gray.600",
        fontFamily: "inherit",
        fontSize: "xs",
        fontWeight: 500,
        textTransform: "unset",
        letterSpacing: "unset",
      },
      thead: {
        backgroundColor: "gray.50",
      },
    },
  },
};
