import {
  Editable,
  EditablePreview,
  EditablePreviewProps,
  EditableProps,
  EditableTextarea,
  EditableTextareaProps,
  Flex,
} from "@chakra-ui/react";
import React from "react";
import ResizeTextarea from "react-textarea-autosize";

export type EditableTextProps = {
  defaultValue?: string | null | undefined;
  placeholder?: string;
  isDisabled?: boolean;
  borderlessPreview?: boolean;
  onSubmit: (newValue: string) => void;
  onEdit?: () => void;
  onCancel?: () => void;
  textColor?: string;
  disabledTextColor?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  textareaProps?: EditableTextareaProps;
  previewProps?: EditablePreviewProps;
} & Omit<EditableProps, "onSubmit" | "children" | "defaultValue">;

export const EditableArea = React.forwardRef<HTMLDivElement, EditableTextProps>(
  (
    {
      defaultValue,
      placeholder = "",
      isDisabled,
      onSubmit,
      onEdit,
      onCancel,
      value,
      borderlessPreview,
      textColor,
      disabledTextColor,
      onKeyDown,
      textareaProps,
      previewProps,
      ...rest
    },
    ref
  ) => {
    return (
      <Editable
        selectAllOnFocus={false}
        defaultValue={value ?? defaultValue ?? ""}
        value={value}
        placeholder={placeholder}
        isDisabled={isDisabled}
        onSubmit={(newValue: any) => {
          if (typeof newValue === "string") {
            onSubmit(newValue);
          }
        }}
        onEdit={onEdit}
        onCancel={onCancel}
        {...rest}
      >
        {({
          isEditing,
          onEdit,
        }: {
          isEditing: boolean;
          onEdit: () => void;
        }) => {
          const valueIsNotEmpty =
            defaultValue !== placeholder && defaultValue !== "";
          return (
            <Flex ref={ref} h="100%" flexDirection="row" alignItems="center">
              <EditablePreview
                flex="1"
                fontSize="sm"
                overflowX="hidden"
                textOverflow="ellipsis"
                px={3}
                py={2}
                border="gray.200"
                borderColor={borderlessPreview ? "transparent" : undefined}
                borderRadius={borderlessPreview ? "0" : "4px"}
                color={textColor || (valueIsNotEmpty ? "gray.900" : "gray.400")}
                _disabled={{
                  color: disabledTextColor,
                }}
                whiteSpace="pre-wrap"
                {...previewProps}
              />
              <EditableTextarea
                as={ResizeTextarea}
                fontSize="sm"
                flex="1"
                my="1px"
                ml="1px"
                px={3}
                py={2}
                resize="none"
                color="gray.900"
                overflow="hidden"
                onKeyDown={onKeyDown}
                {...textareaProps}
              />
            </Flex>
          );
        }}
      </Editable>
    );
  }
);
