import LogRocket from "logrocket";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { LoadingIndicator } from "../../../components";
import {
  useTrainingProgramManagementListItemsQuery,
  useTrainingProgramQuery,
  useTrainingProgramTraineesQuery,
} from "../../graphql";
import DefaultLayout from "../../layouts/DefaultLayout";
import ErrorPage from "./ErrorPage";
import ManageTrainingLayout from "./ManageTrainingLayout";
import ManageTrainingPageView from "./ManageTrainingPageView";
import { useTrainingApi } from "./useTrainingApi";
import { useOnVisibilityChange } from "./utils";

/**
 * Page for managing a specific training program.
 */
const ManageTrainingPage: React.FC = () => {
  useEffect(() => LogRocket.track("training-manage-page"), []);
  const { trainingProgramId } = useParams() as { trainingProgramId: string };

  const { data, error } = useTrainingProgramQuery({
    variables: {
      id: trainingProgramId,
    },
  });

  const { data: traineeData, refetch: refetchTrainees } =
    useTrainingProgramTraineesQuery({
      variables: {
        id: trainingProgramId,
      },
    });
  const trainees = traineeData?.trainingProgram?.trainingProgramTrainees || [];

  const {
    data: trainingProgramsData,
    loading: trainingProgramsLoading,
    error: trainingProgramsError,
    refetch: refetchTrainingPrograms,
  } = useTrainingProgramManagementListItemsQuery();

  const trainingApi = useTrainingApi(refetchTrainingPrograms);

  useOnVisibilityChange(refetchTrainees);

  if (error || trainingProgramsError) {
    return (
      <ErrorPage
        title="We've been notified of the error and are working to fix it. Please reload the page to view this training program."
        error={error ?? trainingProgramsError}
      />
    );
  }

  if (trainingProgramsLoading || !trainingProgramsData) {
    return (
      <DefaultLayout>
        <LoadingIndicator />
      </DefaultLayout>
    );
  }

  const trainingPrograms =
    trainingProgramsData.trainingProgramManagementListItems || [];
  const trainingProgram = data?.trainingProgram;

  if (!trainingProgram) {
    return (
      <ManageTrainingLayout
        trainingApi={trainingApi}
        trainingPrograms={trainingPrograms}
        activeProgramId={trainingProgramId}
        traineeCount={trainingProgramsData.traineeCount}
      >
        <LoadingIndicator />
      </ManageTrainingLayout>
    );
  }

  return (
    <ManageTrainingPageView
      key={trainingProgram?.id}
      trainingProgram={trainingProgram}
      trainingPrograms={trainingPrograms}
      trainingApi={trainingApi}
      trainees={trainees}
      traineeCount={trainingProgramsData.traineeCount}
    />
  );
};

export default ManageTrainingPage;
