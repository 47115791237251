export default new Set([
  "is this still a good time for us to talk?",
  "is this still a good time to talk?",
  "so do you have any questions for me?",
  "is this still a good time for you?",
  "so what questions do you have for me?",
  "is this still an okay time to chat?",
  "do you have any questions for me?",
  "do you have any questions so far?",
  "do you have any questions for us?",
  "what else can i answer for you?",
  "anything else i can answer for you?",
  "how's your day going so far?",
  "do you know what i mean?",
  "is this still a good time?",
  "are you able to hear me?",
  "how's your week going so far?",
  "like what does that look like?",
  "how does that sound to you?",
  "any questions on any of that?",
  "does that sound good to you?",
  "do you have a hard stop?",
  "how are you doing this morning?",
  "so how are you doing today?",
  "can you hear me all right?",
  "you know what i mean?",
  "how are you doing today?",
  "can you hear me now?",
  "does that answer your question?",
  "do you have any questions?",
  "can you hear me okay?",
  "does that work for you?",
  "can you see my screen?",
  "you know what i'm saying?",
  "is that okay with you?",
  "hi, can you hear me?",
  "hey, can you hear me?",
  "did that answer your question?",
  "how can i help you?",
  "can you still hear me?",
  "hi, how are you doing?",
  "can you say that again?",
  "hey, how are you doing?",
  "how are you this morning?",
  "is that what you said?",
  "can you repeat the question?",
  "did i answer your question?",
  "i'm well, how are you?",
  "how is your day going?",
  "is that what you're asking?",
  "can you hear me?",
  "how are you doing?",
  "does that make sense?",
  "how are you today?",
  "hi, how are you?",
  "does that sound good?",
  "hey, how's it going?",
  "how's your day going?",
  "how does that sound?",
  "how was your weekend?",
  "what do you think?",
  "hey, how are you?",
  "what do you mean?",
  "does that sound okay?",
  "how you doing today?",
  "any questions on that?",
  "can you see me?",
  "how's your week going?",
  "any questions for me?",
  "hi, how's it going?",
  "well, how are you?",
  "how's your friday going?",
  "hello, how are you?",
  "does it make sense?",
  "how's your week been?",
  "any questions so far?",
  "could you repeat that?",
  "oh, you know what?",
  "hey, how you doing?",
  "sorry, what was that?",
  "how's it going today?",
  "can you repeat that?",
  "is that all right?",
  "can you see it?",
  "hey, how are ya?",
  "how's your day been?",
  "how's your morning going?",
  "how's it going, man?",
  "how are you?",
  "how's it going?",
  "is that right?",
  "is that correct?",
  "how about you?",
  "how about yourself?",
  "any other questions?",
  "is that okay?",
  "what about you?",
  "how you doing?",
  "how are ya?",
  "what's going on?",
  "how about yours?",
  "are you okay?",
  "how about now?",
  "you hear me?",
  "does that help?",
  "is that better?",
  "what'd you say?",
  "is that true?",
  "are you good?",
  "is that it?",
  "how's that sound?",
  "any questions there?",
  "how's everything going?",
  "you know?",
  "what else?",
  "anything else?",
  "doing well?",
  "oh really?",
  "right now?",
  "sound good?",
  "any questions?",
  "oh, really?",
  "i guess?",
  "pardon me?",
  "who knows?",
  "all good?",
  "you understand?",
  "what's up?",
  "right?",
  "hello?",
  "correct?",
  "huh?",
  "really?",
  "okay?",
  "good?",
  "you?",
  "ready?",
  "this?",
  "pardon?",
  "please?",
  "awesome?",
]);
