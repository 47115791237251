import { Box, Heading } from "@chakra-ui/react";
import React from "react";

import { StatusWithText } from "../StatusWithText";

interface ZoomSettingsProps {
  showLicense?: boolean;
  zoomUserType?: number | null;
  settingsJson: string;
}

const ZoomSettings: React.FC<ZoomSettingsProps> = ({
  showLicense = true,
  zoomUserType,
  settingsJson,
}) => {
  const settings = JSON.parse(settingsJson);

  return (
    <>
      <Box mb={8}>
        <Heading as="h3" size="xs" mb={4}>
          Recording Settings
        </Heading>
        {showLicense && (
          <StatusWithText passed={zoomUserType === 2} label="Licensed User" />
        )}
        <StatusWithText
          passed={settings.recording.cloud_recording}
          label='Enable "Cloud recording"'
        />
        <StatusWithText
          passed={settings.recording.record_speaker_view}
          label='Enable "Record active speaker with shared screen"'
        />
        <StatusWithText
          warn
          passed={settings.recording.recording_disclaimer}
          label="Auto delete cloud recordings (optional)"
        />
        <StatusWithText
          warn
          passed={settings.recording.record_audio_file}
          label='Enable "Record an audio only file" (optional)'
        />
        <StatusWithText
          warn
          passed={settings.recording.ask_participants_to_consent_disclaimer}
          label='Enable "Recording disclaimer" (optional)'
        />
      </Box>
      <Box>
        <Heading as="h3" size="xs" mb={4}>
          Meeting Settings
        </Heading>
        <StatusWithText
          warn
          passed={settings.in_meeting.waiting_room}
          label='Enable "Waiting Room" (optional)'
        />
        <StatusWithText
          passed={!settings.schedule_meeting.use_pmi_for_scheduled_meetings}
          label='Disable "Use Personal Meeting ID (PMI) when scheduling a meeting"'
        />
      </Box>
    </>
  );
};

export default ZoomSettings;
