import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select as ChakraSelect,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import Select from "react-select";

import { useToast } from "../../../components";
import useSelectTheme from "../../../hooks/useSelectTheme";
import { useOrgPositionsQuery, useSetMembershipsMutation } from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";

interface AddMembershipButtonProps {
  userIds: string[];
  filterPositionIds?: string[];
}

const AddMembershipButton: React.FC<AddMembershipButtonProps> = ({
  userIds,
  filterPositionIds,
}) => {
  const currentUser = useCurrentUser();
  const toast = useToast();
  const [theme, styles] = useSelectTheme({
    container: (provided: Record<string, any>) => {
      return { ...provided, flex: 1 };
    },
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [roleId, setRoleId] = useState<string>(
    currentUser.organization.positionRoles[0]?.id
  );
  const [positionId, setPositionId] = useState<string>();
  const { data, loading } = useOrgPositionsQuery({
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
  });
  const position = data?.currentUser?.organization.positions.results?.find(
    (p) => p.id === positionId
  );
  const [setMemberships] = useSetMembershipsMutation({
    onCompleted: (data) => {
      if (data.setMemberships) {
        onClose();
        toast({
          title: "Success",
          description: "Added to Hiring Team",
          status: "success",
        });
      }
    },
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
  });

  const onSubmit = (): void => {
    if (!roleId) return;
    if (!positionId) return;
    const memberships = userIds.map((userId) => {
      return {
        userId,
        positionId,
        positionRoleId: roleId,
      };
    });
    setMemberships({ variables: { users: memberships } });
  };

  const enabled =
    currentUser.userRole?.canManageHiringTeam ||
    currentUser.memberships.some((m) => m.role.canManageHiringTeam);

  const allPositions = currentUser.userRole?.canManageHiringTeam
    ? data?.currentUser?.organization.positions.results || []
    : currentUser.memberships
        .filter((m) => {
          return m.role.canManageHiringTeam;
        })
        .map((m) => m.position);

  const positions = allPositions.filter(
    (p) => !filterPositionIds?.includes(p.id)
  );

  return (
    <>
      <Button
        size="xs"
        variant="outline"
        leftIcon={<MdAddCircleOutline />}
        isDisabled={userIds.length < 1 || !enabled}
        onClick={onOpen}
      >
        Add to hiring team
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>Add to hiring team</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing="8">
                <FormControl
                  id="membership-position-select"
                  data-testid="position-select"
                >
                  <FormLabel>Position</FormLabel>
                  <Select
                    theme={theme}
                    styles={styles}
                    isClearable
                    placeholder="Select a Position"
                    isLoading={loading}
                    value={{
                      label: position?.displayTitle,
                      value: positionId,
                    }}
                    options={positions.map((p) => ({
                      label: p.displayTitle,
                      value: p.id,
                    }))}
                    onChange={(option) =>
                      setPositionId(
                        (option as { value: string } | undefined)?.value
                      )
                    }
                  />
                </FormControl>
                <FormControl id="membership-role-select" mt="2">
                  <FormLabel>Role</FormLabel>
                  <ChakraSelect
                    data-testid="membership-role-select"
                    value={roleId}
                    isDisabled={!positionId}
                    onChange={(e) => setRoleId(e.target.value)}
                  >
                    {currentUser.organization.positionRoles.map((r) => (
                      <option key={r.id} value={r.id}>
                        {r.formattedName}
                      </option>
                    ))}
                  </ChakraSelect>
                  <FormHelperText>
                    {
                      currentUser.organization.positionRoles.find(
                        (r) => r.id === roleId
                      )?.formattedPermissions
                    }
                  </FormHelperText>
                </FormControl>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button onClick={onSubmit} isDisabled={!roleId || !positionId}>
                Submit
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};

export default AddMembershipButton;
