import React from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { StateResultsProvided } from "react-instantsearch-core";
import { connectStateResults } from "react-instantsearch-dom";

import config from "../../config";

interface SearchStateProps extends StateResultsProvided {
  children?: React.ReactNode;
  render: (
    stateResults: StateResultsProvided
  ) => React.ReactElement<any> | null;
}

const SearchState: React.FC<SearchStateProps> = ({
  children,
  render,
  ...rest
}) => render(rest);

const SearchStateTest: React.FC<Pick<SearchStateProps, "render">> = ({
  render,
}) =>
  render({
    searchState: {},
    searchResults: {} as any,
    allSearchResults: {} as any,
    searching: false,
    isSearchStalled: undefined,
    searchingForFacetValues: undefined,
  });

export default config.appEnv === "test"
  ? SearchStateTest
  : connectStateResults(SearchState);
