import { createIcon, Icon } from "@chakra-ui/react";
import React from "react";

export const FilterArrowDownIcon = createIcon({
  displayName: "ToggleFilterIcon",
  viewBox: "0 0 24 24",
  path: [
    <path
      d="M13.5 13.625L17.1 17.225L20.7 13.625M17.1 16.725V8"
      stroke="currentColor"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />,
    <line
      x1="3.875"
      y1="7.72501"
      x2="11.725"
      y2="7.72501"
      stroke="currentColor"
      strokeWidth="1.75"
      strokeLinecap="round"
    />,
    <line
      x1="3.875"
      y1="11.325"
      x2="9.325"
      y2="11.325"
      stroke="currentColor"
      strokeWidth="1.75"
      strokeLinecap="round"
    />,
    <line
      x1="3.875"
      y1="14.925"
      x2="6.925"
      y2="14.925"
      stroke="currentColor"
      strokeWidth="1.75"
      strokeLinecap="round"
    />,
  ],
});

export const TrashIcon = createIcon({
  displayName: "TrashIcon",
  viewBox: "0 0 24 24",

  path: [
    <path
      d="M6.22498 9.375V17.775C6.22498 18.332 6.44622 18.8661 6.84005 19.2599C7.23388 19.6537 7.76802 19.875 8.32498 19.875H15.675C16.2319 19.875 16.7661 19.6537 17.1599 19.2599C17.5537 18.8661 17.775 18.332 17.775 17.775V9.375"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />,
    <path
      d="M5.17493 7.27499H18.8249"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />,
    <path
      d="M9.37488 7.275V4.125H14.6249V7.275"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />,
  ],
});

export const GreenCheckmarkIcon: React.FC = () => (
  <Icon viewBox="0 0 10 9" boxSize="3" fill="transparent">
    <path
      d="M9 1.59998L3.4 7.99998L1 5.59998"
      stroke="#50CCAD"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export const BriefcaseIcon: React.FC = () => (
  <Icon
    stroke="currentColor"
    fill="transparent"
    strokeWidth="2"
    viewBox="0 0 24 24"
    boxSize="20px"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <rect x={3} y={7} width={18} height={13} rx={2} />
    <path d="M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2" />
    <line x1={12} y1={12} x2={12} y2={12.01} />
    <path d="M3 13a20 20 0 0 0 18 0" />
  </Icon>
);
