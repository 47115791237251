import { Box, SystemProps, Text } from "@chakra-ui/react";
import React from "react";

import {
  CallNoteType,
  GuideItemType,
  ScorecardItemNotesFragment,
} from "../../../../graphql";
import ScorecardNoteQuestion from "./ScorecardNoteQuestion";
import { NotesV3CommonProps } from "./types";

type ScorecardNoteBlockProps = {
  item: ScorecardItemNotesFragment;
  containerProps?: SystemProps;
  onAddNote(note: {
    text: string;
    type: CallNoteType;
    time: number;
    scorecardQuestionId?: string;
  }): Promise<void>;
  onMarkQuestion?: (markedTime: number, scorecardQuestionId: string) => void;
} & NotesV3CommonProps;

const ScorecardNoteBlock: React.FC<ScorecardNoteBlockProps> = ({
  item,
  containerProps,
  onMarkQuestion,
  onAddNote,
  ...commonProps
}) => {
  const containerStyles: SystemProps = {
    display: "flex",
    flexDirection: "column",
    gap: "1",
    borderRadius: "lg",
    borderWidth: "1px",
    borderColor: "gray.200",
    overflow: "hidden",
    ...containerProps,
  };

  // A semi-disabled state: text blocks or empty competencies cannot have notes.
  if (
    item.type === GuideItemType.Text ||
    (item.type === GuideItemType.Competency && item.childItems.length === 0)
  ) {
    return (
      <Box
        {...containerStyles}
        p="4"
        backgroundColor="gray.50"
        color="gray.800"
        fontWeight={item.type === GuideItemType.Competency ? "600" : "normal"}
      >
        {item.itemText}
      </Box>
    );
  }

  if (item.type === GuideItemType.Competency) {
    return (
      <Box {...containerStyles}>
        <Text p="4" fontWeight="600" color="gray.900">
          {item.itemText}
        </Text>
        {item.childItems.map((childItem) => (
          <ScorecardNoteQuestion
            key={childItem.id}
            item={childItem}
            // We're reusing complicated CallNote components because duplicating them for scorecard usage would be a *lot* of work.
            // Far more work than just asking Typescript to overlook something for once in its life.
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onAddNote={(params) => {
              onAddNote?.({
                ...params,
                scorecardQuestionId: childItem.id,
              });
            }}
            onMarkQuestion={onMarkQuestion}
            {...commonProps}
          />
        ))}
      </Box>
    );
  }

  if (item.type === GuideItemType.Question) {
    return (
      <ScorecardNoteQuestion
        item={item}
        // We're reusing complicated CallNote components because duplicating them for scorecard usage would be a *lot* of work.
        // Far more work than just asking Typescript to overlook something for once in its life.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onAddNote={(params) => {
          onAddNote?.({
            ...params,
            scorecardQuestionId: item.id,
          });
        }}
        onMarkQuestion={onMarkQuestion}
        containerProps={containerStyles}
        {...commonProps}
      />
    );
  }

  return <Box {...containerStyles}>{item.itemText}</Box>;
};

export default ScorecardNoteBlock;
