import React from "react";

import useLoadViewableShares from "../../graphql/hooks/useLoadViewableShares";
import MentionMenuBeta from "./MentionMenuBeta";

interface MentionMenuProps {
  inputRef: React.MutableRefObject<HTMLElement | null>;
  callId: string;
  clipId?: string | null;
}

const MentionMenu: React.FC<MentionMenuProps> = ({
  inputRef,
  callId,
  clipId,
}) => {
  const { callOrClip, loading } = useLoadViewableShares({ callId, clipId });

  return (
    <MentionMenuBeta
      inputRef={inputRef}
      loading={loading}
      shareableUsers={callOrClip?.shareableUsers}
    />
  );
};

export default MentionMenu;
