import {
  Box,
  BoxProps,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { RouterLink } from "../RouterLink";
import { getBreadcrumbs } from "./source";
import { BreadcrumbProps } from "./types";

type BreadcrumbsProps = {
  /**
   * Optional breadcrumbs to override the default for the page
   */
  breadcrumbs?: BreadcrumbProps[];
  showHome?: boolean;
} & BoxProps;

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  breadcrumbs = [],
  showHome = true,
  ...boxProps
}) => {
  const location = useLocation();
  const isAdmin = location.pathname.startsWith("/admin");
  const finalBreadcrumbs = useMemo(() => {
    let nextBreadcrumbs = breadcrumbs;

    if (!nextBreadcrumbs?.length) {
      // TODO: The current state here is a quick fallback, it'd be nice to have
      // reusable code to replace UUID's with human-readable resource names
      // everywhere breadcrumbs are used, see scheduled-interview & organization
      // breadcrumbs for a start on this
      nextBreadcrumbs = breadcrumbs.concat(getBreadcrumbs(location.pathname));
    }

    const hasAdmin = nextBreadcrumbs.find(
      (b) => b.order === 0 && b.title === "Admin"
    );

    if (showHome && isAdmin && !hasAdmin) {
      nextBreadcrumbs.push({
        order: -1,
        title: isAdmin ? "Admin" : "Home",
        url: isAdmin ? "/admin" : "/",
      });
    }
    return nextBreadcrumbs;
  }, [breadcrumbs, showHome]);

  return (
    <Box {...boxProps} pb={4}>
      <Breadcrumb>
        {finalBreadcrumbs
          .sort((a, b) => (a.order > b.order ? 1 : -1))
          .map((breadcrumb, i) => {
            if (i === finalBreadcrumbs.length - 1) {
              return (
                <BreadcrumbItem key={`${breadcrumb.url}-${i + 1}`}>
                  <BreadcrumbLink isCurrentPage>
                    {breadcrumb.title}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              );
            }
            return (
              <BreadcrumbItem key={`${breadcrumb.url}-end-${i + 1}`}>
                <BreadcrumbLink as={RouterLink} to={breadcrumb.url}>
                  {breadcrumb.title}
                </BreadcrumbLink>
              </BreadcrumbItem>
            );
          })}
      </Breadcrumb>
    </Box>
  );
};

export default Breadcrumbs;
