import { Image } from "@chakra-ui/react";
import React from "react";

import { RouterLinkBox } from "../RouterLink";
import AnimateExpansion from "./AnimateExpansion";

type AnimatedLogoProps = {
  app: "main" | "plan";
  isOpen: boolean;
  skipAnimationDelay?: boolean;
};

/**
 * Handles swapping the logo image with the word-marked logo image in a way
 * that plays nicely with the timing of the sidebar's expand / collapse
 */
const AnimatedLogo: React.FC<AnimatedLogoProps> = ({
  app,
  isOpen,
  skipAnimationDelay,
}) => {
  return (
    <RouterLinkBox to="/" isExternal={app !== "main"}>
      <AnimateExpansion
        isOpen={isOpen}
        skipAnimationDelay={skipAnimationDelay}
        style={{ marginLeft: "8px" }}
        expandedStyle={{ marginRight: "auto" }}
        expanded={
          <Image
            src="/static/images/logo_wordmark_white.svg"
            maxW="unset"
            h="10"
            p="5px"
          />
        }
        collapsed={
          <Image
            src="/static/images/logo_white.svg"
            boxSize="10"
            maxW="unset"
            transform="scale(1.02) translate(0.4px, 0.2px)"
          />
        }
      />
    </RouterLinkBox>
  );
};

export default AnimatedLogo;
