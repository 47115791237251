import React, { forwardRef } from "react";
import Select from "react-select";

import useSelectTheme from "../../../hooks/useSelectTheme";

interface CalendarItem {
  id: string;
  resourceId: string;
  name: string;
  videoMeetingImportStrategy: string;
  canSetVideoMeetingImportStrategy: boolean;
}

interface CalendarSelectProps {
  name?: string;
  placeholder?: string;
  calendars: Array<CalendarItem>;
  onSelect?: (calendar: CalendarItem | undefined) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const CalendarSelect = forwardRef<any, CalendarSelectProps>(
  (
    { name, placeholder, calendars, onSelect, isDisabled = false, isLoading },
    ref
  ) => {
    const [theme, styles] = useSelectTheme();

    return (
      <Select
        ref={ref}
        name={name}
        theme={theme}
        styles={styles}
        isClearable
        isLoading={isLoading}
        placeholder={placeholder}
        value={null}
        options={calendars?.map((calendar) => ({
          label: calendar.name,
          value: calendar,
        }))}
        closeMenuOnSelect
        onChange={(option) => {
          if (onSelect) onSelect((option as { value: CalendarItem })?.value);
        }}
        isDisabled={isDisabled}
      />
    );
  }
);

// const CustomOption: React.FC<OptionProps<{
//   label: string;
//   value: CallGuideListItemFragment;
// }>> = (props) => {
//   const v = props.data as {
//     label: string;
//     value: CallGuideListItemFragment;
//   } | null;
//   return (
//     <components.Option {...props}>
//       <Flex>
//         <Box>{v?.label}</Box>
//         {v?.value?.isTemplate && (
//           <Box flex="0 0 70px" marginRight={2} marginLeft="auto">
//             <Tag
//               size="sm"
//               rounded="full"
//               variant="solid"
//               backgroundColor="blue.500"
//               color="white"
//               fontSize="xs"
//             >
//               <TagLabel>Template</TagLabel>
//             </Tag>
//           </Box>
//         )}
//       </Flex>
//     </components.Option>
//   );
// };

CalendarSelect.displayName = "CalendarSelect";
export default CalendarSelect;
