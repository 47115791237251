import React from "react";
import { useParams } from "react-router-dom";

import { uuid as uuidRegex } from "../../utils/regex";

export interface RequireUUIDParamsProps {
  paramNames: string[];
  element: JSX.Element;
  children: JSX.Element;
}

export const RequireUUIDParams: React.FC<RequireUUIDParamsProps> = ({
  paramNames,
  element,
  children,
}) => {
  const params = useParams();
  for (let i = 0; i < paramNames.length; i++) {
    const paramName = paramNames[i];
    const paramValue = params[paramName];
    if (paramValue && !paramValue.match(uuidRegex)) {
      return element;
    }
  }
  return children;
};
