import { useCallback, useEffect, useRef, useState } from "react";
import { isDesktop } from "react-device-detect";

import { useFullscreen } from "../../../hooks/useFullscreen";
import usePictureInPicture, {
  ExtendedHTMLVideoElement,
} from "../../../hooks/usePictureInPictureBeta";
import { VideoDisplayMode } from "../Interview";
// import { VideoDisplayMode } from "../Interview/types";

export type VideoCallbackRef = (
  element: ExtendedHTMLVideoElement | null
) => void;

export type VideoStateControl = {
  isActive: boolean;
  isSupported: boolean;
  toggle?: (() => Promise<void> | undefined) | (() => void);
  enable?(): void;
  disable?(): void;
};

type VideoStateReturn = {
  isVideoVisible: boolean;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  videoCallbackRef: VideoCallbackRef;
  fullScreenControls: VideoStateControl;
  pipControls: VideoStateControl;
  focusVideo(): void;
  hideVideo(enablePIP?: boolean): void;
};

const useVideoState = (
  videoDisplayMode: VideoDisplayMode
): VideoStateReturn => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const videoRef = useRef<ExtendedHTMLVideoElement | null>(null);

  const videoCallbackRef: VideoCallbackRef = useCallback((element) => {
    videoRef.current = element;
  }, []);

  const { isFullscreenSupported, toggleFullscreen, isFullscreen } =
    useFullscreen(containerRef);

  const {
    isPictureInPictureAvailable,
    isPictureInPictureActive,
    togglePictureInPicture,
  } = usePictureInPicture(videoRef, {
    onLeavePictureInPicture: () => {
      setIsVideoVisible(true);
      togglePictureInPicture(false);
    },
  });

  const [isVideoVisible, setIsVideoVisible] = useState(
    videoDisplayMode === VideoDisplayMode.VIDEO
  );

  useEffect(() => {
    setIsVideoVisible(videoDisplayMode === VideoDisplayMode.VIDEO);
  }, [videoDisplayMode]);

  const focusVideo = useCallback(() => {
    if (!isVideoVisible) {
      setIsVideoVisible(true);
    }
    if (isPictureInPictureActive) {
      togglePictureInPicture(false);
    }
  }, [isVideoVisible, isPictureInPictureActive]);

  const hideVideo = useCallback(
    (enablePIP = true) => {
      if (isVideoVisible) {
        setIsVideoVisible(false);
        if (enablePIP) {
          togglePictureInPicture(true);
        }
      }
    },
    [isVideoVisible]
  );

  return {
    isVideoVisible,
    containerRef,
    videoCallbackRef,
    fullScreenControls: {
      isActive: isFullscreen,
      isSupported: isFullscreenSupported,
      toggle: toggleFullscreen,
    },
    pipControls: {
      isActive: isPictureInPictureActive,
      isSupported: isPictureInPictureAvailable,
      toggle: useCallback(() => {
        if (!isPictureInPictureActive && isDesktop) {
          setIsVideoVisible(false);
        }
        togglePictureInPicture(!isPictureInPictureActive);
      }, [isPictureInPictureActive, togglePictureInPicture]),
      enable: () => {
        setIsVideoVisible(false);
        togglePictureInPicture(true);
      },
      disable: () => {
        togglePictureInPicture(true);
      },
    },
    focusVideo,
    hideVideo,
  };
};

export default useVideoState;
