import { Center, CenterProps, Tooltip } from "@chakra-ui/react";
import React from "react";
import { GoDotFill } from "react-icons/go";

import colorVars from "../../../theme/css-color-variables";

const Indicator: React.FC<{ tooltipText: string } & CenterProps> = ({
  tooltipText,
  ...rest
}) => {
  return (
    <Tooltip label={tooltipText}>
      <Center
        w="5"
        h="5"
        borderRadius="base"
        _hover={{ bg: "red.50" }}
        {...rest}
      >
        <GoDotFill color={colorVars.red[600]} />
      </Center>
    </Tooltip>
  );
};

export default Indicator;
