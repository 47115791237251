import { Link } from "@chakra-ui/react";
import * as React from "react";

import { isNumeric } from "../../utils/number";
import {
  domain as domainRegex,
  email as emailRegex,
  punctuationPostfix,
  punctuationPrefix,
} from "../../utils/regex";

type Props = {
  className?: string;
  noopener?: boolean;
  noreferrer?: boolean;
  target?: string;
  text: string;
};

type PunctuationReturnType = {
  prefix: string;
  textWithoutBoundaryPunctuations: string;
  postfix: string;
};

const Parse: React.FC<Props> = ({
  className,
  noopener,
  noreferrer,
  target,
  text,
}) => {
  const rel = `${noopener ? "noopener" : ""}${noreferrer ? " noreferrer" : ""}`;
  const lines = text.split("\n");
  const contents: Array<string | Array<string | JSX.Element>> = [];
  lines.forEach((text) => {
    const textList = text.split(" ");

    const extractPunctuations = (inputText: string): PunctuationReturnType => {
      let startingIndex = 0;
      let endingIndex = inputText.length;
      while (
        punctuationPrefix.exec(
          inputText.substring(startingIndex, inputText.length)
        )
      ) {
        startingIndex += 1;
      }
      while (punctuationPostfix.exec(inputText.substring(0, endingIndex))) {
        endingIndex -= 1;
      }
      const prefix = inputText.substring(0, startingIndex);
      const postfix = inputText.substring(endingIndex, inputText.length);
      const textWithoutBoundaryPunctuations = inputText.substring(
        startingIndex,
        endingIndex
      );
      return { prefix, textWithoutBoundaryPunctuations, postfix };
    };

    const content = textList.reduce(
      (list: Array<string | JSX.Element>, part: string, index) => {
        const { prefix, textWithoutBoundaryPunctuations, postfix } =
          extractPunctuations(part);

        const numeric = isNumeric(textWithoutBoundaryPunctuations);
        const domainPart = domainRegex.exec(textWithoutBoundaryPunctuations);
        const emailPart = emailRegex.exec(
          textWithoutBoundaryPunctuations.replace(/^@/, "")
        );
        let item: string | JSX.Element = part;
        if (part.length === 0) {
          item = " ";
        } else if (domainPart && !numeric) {
          const withoutHTTP = !(
            part.startsWith("https://") || part?.startsWith("http://")
          );
          const safeURL = withoutHTTP ? `http://${part}` : part;
          item = (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              {prefix ? <span>{prefix}</span> : null}
              <Link
                href={safeURL}
                className={className}
                rel={rel}
                target={target}
              >
                {textWithoutBoundaryPunctuations}
              </Link>
              {postfix ? <span>{postfix}</span> : null}
            </React.Fragment>
          );
        } else if (emailPart) {
          item = (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key="index">
              {prefix ? <span>{prefix}</span> : null}
              <Link
                href={`mailto:${textWithoutBoundaryPunctuations.replace(
                  /^@/,
                  ""
                )}`}
                className={className}
                rel={rel}
                target={target}
                data-testid="email"
              >
                {textWithoutBoundaryPunctuations}
              </Link>
              {postfix ? <span>{postfix}</span> : null}
            </React.Fragment>
          );
        }

        const lastChild = list[list.length - 1];
        if (list.length === 0) {
          list.push(item);
        } else if (typeof lastChild === "string") {
          if (typeof item === "string") {
            // eslint-disable-next-line no-param-reassign
            list[list.length - 1] = `${lastChild} ${item}`;
          } else {
            // eslint-disable-next-line no-param-reassign
            list[list.length - 1] = `${lastChild} `;
            list.push(item);
          }
        } else if (typeof item === "string") {
          list.push(` ${item}`);
        } else {
          list.push(" ");
          list.push(item);
        }

        return list;
      },
      []
    );
    contents.push(content);
    contents.push("\n");
  });

  return <>{contents}</>;
};

export default React.memo(Parse);
