import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import LogRocket from "logrocket";
import React, { useEffect } from "react";

import useEffectAfterFirstRender from "../../../hooks/useEffectAfterFirstRender";
import { useIsSmallScreen } from "../../../hooks/useIsSmallScreen";
import { usePageTracker, useSendGAEvent } from "../../../utils/googleAnalytics";
import FeedSidebar from "../../components/FeedSidebar/FeedSidebar";
import { DefaultPageLayout } from "../../layouts";
import CompletedInterviews from "./Interviews/CompletedInterviews";
import CompletedInterviewsMobile from "./Interviews/CompletedInterviewsMobile";
import UpcomingInterviews from "./Interviews/UpcomingInterviews";
import UpcomingInterviewsMobile from "./Interviews/UpcomingInterviewsMobile";
import QuickStats from "./QuickStats/QuickStats";
import SharedWithMe from "./SharedWithMe";
import useTabUrlState from "./useTabUrlState";

const HomePage: React.FC = () => {
  usePageTracker("home:v2");
  const sendGAEvent = useSendGAEvent();
  const isSmallScreen = useIsSmallScreen();

  useEffect(() => {
    LogRocket.track("home-page-beta");
  }, []);

  const tabs = isSmallScreen
    ? [
        {
          displayName: "Upcoming",
          content: <UpcomingInterviewsMobile />,
        },
        {
          displayName: "Completed",
          content: <CompletedInterviewsMobile />,
        },
        {
          displayName: "Shared with me",
          content: <SharedWithMe />,
          tourId: "shared-with-me",
        },
        {
          displayName: "Activity",
          content: (
            <Box pb="6">
              <QuickStats mb="4" />
              <FeedSidebar />
            </Box>
          ),
        },
      ]
    : [
        {
          displayName: "My interviews",
          content: (
            <Flex gap="8">
              <Box flex="3" maxW="1200px">
                <UpcomingInterviews />
                <CompletedInterviews />
              </Box>
              <Box mt="12" flex="1" minW="316px" maxW="440px">
                <QuickStats mb="4" />
                <FeedSidebar />
              </Box>
            </Flex>
          ),
        },
        {
          displayName: "Shared with me",
          content: <SharedWithMe />,
          tourId: "shared-with-me",
        },
      ];

  const [tabIndex, setTabIndex] = useTabUrlState({
    tabNames: tabs.map((t) => t.displayName),
    onTabChange(idx) {
      const tab = tabs[idx];
      sendGAEvent(
        "tab_change",
        "home_page",
        tab.displayName,
        isSmallScreen ? "mobile" : ""
      );
    },
  });

  /**
   * Whenever the layout changes, switch to the first tab
   */
  useEffectAfterFirstRender(() => {
    setTabIndex(0);
  }, [isSmallScreen]);

  return (
    <DefaultPageLayout
      // FYI——Comes from above max-widths (1200 + 440)
      // plus padding (64) + column gap (32)
      maxW="1736px"
    >
      <Tabs
        variant="home"
        isLazy
        index={tabIndex}
        onChange={setTabIndex}
        pt={{ base: "0", lg: "2" }}
      >
        <TabList>
          {tabs.map(({ displayName, tourId }) => (
            <Tab
              key={displayName}
              fontSize={{ base: "sm", lg: "md" }}
              fontWeight="600"
              data-tour-id={tourId}
            >
              {displayName}
            </Tab>
          ))}
        </TabList>

        <TabPanels>
          {tabs.map(({ displayName, content }) => (
            <TabPanel
              key={displayName}
              pt={{ base: "6", lg: "8" }}
              pl={{ base: "4", lg: "8" }}
              pr={{ base: "4", lg: "8" }}
            >
              {content}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </DefaultPageLayout>
  );
};

export default HomePage;
