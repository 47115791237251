import { Input, InputGroup, InputProps } from "@chakra-ui/react";
import React, { useState } from "react";

interface KeywordBoxProps extends Omit<InputProps, "onSubmit"> {
  indexContextValue?: any;
  onEnter?: (value: string) => void;
}

const KeywordBox: React.FC<KeywordBoxProps> = ({
  onChange,
  onEnter,
  indexContextValue,
  ...inputProps
}) => {
  const [keyword, setKeyword] = useState("");

  return (
    <form
      noValidate
      action=""
      role="keywordsearch"
      id="keywordform"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <InputGroup>
        <Input
          value={keyword}
          minWidth={300}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          autoFocus
          fontSize="sm"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setKeyword(event.currentTarget.value);
            if (onChange) {
              onChange(event);
            }
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter" && onEnter) {
              onEnter(event.currentTarget.value);
              setKeyword("");
            }
          }}
          {...inputProps}
        />
      </InputGroup>
    </form>
  );
};

export default KeywordBox;
