import React, { ReactElement } from "react";
import { RadialBar, RadialBarChart as RCRadialBarChart } from "recharts";

import colorVars from "../../theme/css-color-variables";

interface customLabelProps {
  value: number;
  index: number;
  name: string;
}

const RadialBarChart: React.FC<
  React.ComponentProps<typeof RCRadialBarChart>
> = ({ ...radialBarChartProps }) => {
  const renderCustomizedLabel = (
    props: customLabelProps
  ): ReactElement<SVGElement> => {
    const { value, index } = props;
    if (index === 1) {
      return (
        <text
          x="50%"
          y="52%"
          fill={colorVars.gray[600]}
          fontSize="34px"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {value}%
        </text>
      );
    }
    return <text />;
  };
  return (
    <RCRadialBarChart
      width={200}
      height={170}
      innerRadius={0}
      outerRadius={130}
      barSize={15}
      {...radialBarChartProps}
    >
      <RadialBar
        isAnimationActive={false}
        label={renderCustomizedLabel}
        background
        dataKey="count"
      />
    </RCRadialBarChart>
  );
};

export default RadialBarChart;
