import { Modal, ModalOverlay } from "@chakra-ui/react";
import React from "react";

import {
  CallGuideFragment,
  CallGuideListItemFragment,
  CallGuideQuery,
} from "../../graphql";
import useUpdateCallGuide from "../../graphql/hooks/CallGuide/useUpdateCallGuide";
import GuideForm from "./GuideForm";

type CallGuide = NonNullable<CallGuideQuery["callGuide"]>;

export interface FormData {
  name: string;
  positionId?: string;
  stageIds?: string[];
  assignedUserIds?: string[];
}

interface EditCallGuideModalProps {
  callGuide: CallGuide | CallGuideFragment | CallGuideListItemFragment;
  isOpen: boolean;
  onClose: () => void;
}

const EditCallGuideModal: React.FC<EditCallGuideModalProps> = ({
  callGuide,
  isOpen,
  onClose,
}) => {
  const [updateCallGuide, { error, loading }] = useUpdateCallGuide({});

  const onSubmit = ({
    name,
    positionId,
    assignedUserIds,
  }: {
    name: string;
    positionId?: string;
    assignedUserIds?: string[];
  }): void => {
    updateCallGuide({
      variables: {
        id: callGuide.id,
        name,
        positionId,
        assignedUserIds,
      },
    });
  };

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay>
        <GuideForm
          title="Edit Guide"
          name={callGuide.name ?? ""}
          positionId={callGuide.position?.id}
          assignedUserIds={callGuide.assignedUsers?.map((user) => user?.userId)}
          error={error}
          isLoading={loading}
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      </ModalOverlay>
    </Modal>
  );
};

export default EditCallGuideModal;
