import {
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  IoArrowDownOutline,
  IoArrowUpCircleSharp,
  IoArrowUpOutline,
  IoTrashOutline,
} from "react-icons/io5";

import {
  menuIconProps,
  TrainingMenuItem as MenuItem,
} from "./TrainingMenuItem";
import { TrainingQuestion } from "./types";
import { TrainingApi } from "./useTrainingApi";
import { reorder } from "./utils";

interface QuestionMenuProps {
  trainingProgramId: string;
  trainingApi: TrainingApi;
  question: TrainingQuestion;
  trainingQuestions: TrainingQuestion[];
  setAutoSaveInProgress: (autoSaveInProgress: boolean) => void;
  onDelete: (e: any) => void;
  listPosition: number;
  menuDisabled?: boolean;
}

const QuestionMenu: React.FC<QuestionMenuProps> = ({
  trainingProgramId,
  trainingApi,
  question,
  trainingQuestions,
  setAutoSaveInProgress,
  onDelete,
  listPosition,
  menuDisabled,
}) => {
  return (
    <Menu>
      <Tooltip
        label="You can't remove or reorder the only question in an assessment"
        isDisabled={!menuDisabled}
        shouldWrapChildren
      >
        <MenuButton
          data-testid={`question-menu-button-${listPosition}`}
          as={IconButton}
          aria-label="Options"
          icon={<BsThreeDotsVertical />}
          variant="ghost"
          size="sm"
          ml="3"
          colorScheme="gray"
          isDisabled={menuDisabled}
        />
      </Tooltip>
      <MenuList zIndex="3">
        <MenuItem
          data-testid={`question-move-to-top-${listPosition}`}
          icon={<IoArrowUpCircleSharp {...menuIconProps} />}
          isDisabled={listPosition === 0}
          onClick={() => {
            trainingApi.questions.reorder(
              trainingProgramId,
              reorder(
                trainingQuestions.map((q) => q.id),
                listPosition,
                0
              )
            );
          }}
        >
          Move to top
        </MenuItem>
        <MenuItem
          data-testid={`question-move-up-${listPosition}`}
          icon={<IoArrowUpOutline {...menuIconProps} />}
          isDisabled={listPosition === 0}
          onClick={() => {
            trainingApi.questions.reorder(
              trainingProgramId,
              reorder(
                trainingQuestions.map((q) => q.id),
                listPosition,
                listPosition - 1
              )
            );
          }}
        >
          Move up
        </MenuItem>
        <MenuItem
          data-testid={`question-move-down-${listPosition}`}
          icon={<IoArrowDownOutline {...menuIconProps} />}
          isDisabled={listPosition === trainingQuestions.length - 1}
          onClick={() => {
            trainingApi.questions.reorder(
              trainingProgramId,
              reorder(
                trainingQuestions.map((q) => q.id),
                listPosition,
                listPosition + 1
              )
            );
          }}
        >
          Move down
        </MenuItem>
        <MenuDivider />
        <MenuItem
          data-testid={`question-delete-${listPosition}`}
          icon={<IoTrashOutline {...menuIconProps} />}
          onClick={onDelete}
        >
          Remove
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default QuestionMenu;
