import { useOutletContext } from "react-router-dom";

type ContextType = {
  selectedOrgId: string;
  setSelectedOrgId: (id: string) => void;
  showBHDemoDashboard: boolean;
  setShowBHDemoDashboard: (show: boolean) => void;
};

const useAnalyticsContext = (): ContextType => {
  return useOutletContext<ContextType>();
};

export default useAnalyticsContext;
