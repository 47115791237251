import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  useControllableState,
} from "@chakra-ui/react";
import React from "react";

/** This can be extended to support button-level `isDisabled` (or similar) */
type ButtonData = {
  label: string;
};

export type SegmentedButtonProps = {
  /** Config for buttons */
  buttons?: ButtonData[];
  /** Label of the active button. Defaults to the first button */
  activeButton?: string;
  onChange?(label: string): void;
  groupProps?: ButtonGroupProps;
  buttonProps?: ButtonProps;
  activeButtonProps?: ButtonProps;
};

/**
 * A connected button group that displays one button as "active"
 */
export const SegmentedButton: React.FC<SegmentedButtonProps> = ({
  buttons,
  onChange,
  groupProps,
  buttonProps,
  activeButtonProps,
  ...props
}) => {
  const [activeButton, setActiveButton] = useControllableState({
    value: props.activeButton,
    defaultValue: buttons?.[0]?.label,
    onChange,
  });

  if (!buttons?.length) return null;

  return (
    <ButtonGroup
      bg="blackAlpha.100"
      p="1"
      borderRadius="lg"
      size="sm"
      variant="chalk"
      {...groupProps}
    >
      {buttons.map(({ label, ...button }) => (
        <Button
          key={label}
          lineHeight="5"
          fontWeight="regular"
          h="9"
          flex="1 0 0"
          borderRadius="md"
          color="gray.900"
          isActive={label === activeButton}
          {...button}
          {...buttonProps}
          _active={{
            bg: "white",
            fontWeight: "semibold",
            ...activeButtonProps,
          }}
          onClick={() => {
            onChange?.(label);
            setActiveButton(label);
          }}
        >
          {label}
        </Button>
      ))}
    </ButtonGroup>
  );
};
