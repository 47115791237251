import { useEffect, useState } from "react";

import { CallSpeakerFragment, TranscriptSegmentFragment } from "../../graphql";

const getNextSegment = (
  transcript: TranscriptSegmentFragment[],
  candidateSpeakerTag: number,
  index: number
): number => {
  let curr = index + 1;
  let currentSegment = transcript[curr];
  while (
    currentSegment?.words.length < 2 ||
    (curr < transcript.length &&
      currentSegment.speakerTag !== candidateSpeakerTag)
  ) {
    curr += 1;
    currentSegment = transcript[curr];
  }

  return curr;
};

type UseCandidateOnlyPlayback = (
  speakers: CallSpeakerFragment[],
  time: number,
  seek: (time: number) => void,
  transcript?: TranscriptSegmentFragment[]
) => {
  candidateIdentified: boolean;
  candidateOnly: boolean;
  setCandidateOnly: (candidateOnly: boolean) => void;
};

const useCandidateOnlyPlayback: UseCandidateOnlyPlayback = (
  speakers,
  time,
  seek,
  transcript
) => {
  const [candidateSpeakerTag, setCandidateSpeakerTag] = useState(-1);
  const [candidateOnly, setCandidateOnly] = useState(false);

  const seekToNextSection = (currentSegmentIndex: number): void => {
    if (!transcript) return;

    if (currentSegmentIndex === transcript.length) {
      return;
    }

    // You've reached the end of this segment
    const nextSegmentIndex = getNextSegment(
      transcript,
      candidateSpeakerTag,
      currentSegmentIndex
    );

    if (nextSegmentIndex === transcript.length) return;

    if (transcript[nextSegmentIndex].startTime > time + 0.2) {
      seek(transcript[nextSegmentIndex].startTime - 0.2);
    }
  };

  useEffect(() => {
    const candidate = speakers?.find((speaker) => speaker.isCandidate);
    if (!candidate) {
      setCandidateSpeakerTag(-1);
      return;
    }

    setCandidateSpeakerTag(candidate.speakerTag);
  }, [speakers]);

  useEffect(() => {
    if (!transcript) return;
    if (!candidateOnly) return;

    const candidateOnlySections = transcript.filter(
      (segment) => segment.speakerTag === candidateSpeakerTag
    );

    const currentSegmentIndex = candidateOnlySections.findIndex(
      (segment) => time <= segment.endTime + 0.2
    );

    if (currentSegmentIndex === -1) return;

    const currentSegment = candidateOnlySections[currentSegmentIndex];
    const lastWordInSegment =
      currentSegment.words[currentSegment.words.length - 1];

    if (time > lastWordInSegment.endTime) {
      const currentSegmentTrueIndex = transcript.findIndex(
        (segment) => segment.id === currentSegment.id
      );
      seekToNextSection(currentSegmentTrueIndex);
    }
  }, [time]);

  useEffect(() => {
    if (!candidateOnly || !transcript) return;

    const currentSegmentIndex = transcript.findIndex(
      (segment) => time <= segment.endTime
    );

    if (currentSegmentIndex === -1) return;
    if (transcript[currentSegmentIndex].speakerTag !== candidateSpeakerTag) {
      seekToNextSection(currentSegmentIndex);
    }
  }, [candidateOnly]);

  return {
    candidateIdentified: candidateSpeakerTag !== -1,
    candidateOnly,
    setCandidateOnly,
  };
};

export default useCandidateOnlyPlayback;
