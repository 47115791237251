import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const CopyOutlineIcon = (props: IconProps): JSX.Element => (
  <Icon {...props} viewBox="0 0 16 16">
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0867 3.89711L12.1027 3.12788C12.1014 2.65227 11.9118 2.19652 11.5755 1.86021C11.2392 1.5239 10.7835 1.33436 10.3079 1.33301H3.38478C2.84124 1.33461 2.32042 1.55125 1.93608 1.93559C1.55174 2.31993 1.3351 2.84075 1.3335 3.38429V10.3074C1.33485 10.783 1.52439 11.2387 1.8607 11.575C2.19701 11.9113 2.65275 12.1009 3.12837 12.1022H3.8976M5.7239 3.8973H12.8393C13.8483 3.8973 14.6662 4.71524 14.6662 5.72422V12.8396C14.6662 13.8486 13.8483 14.6665 12.8393 14.6665H5.7239C4.71491 14.6665 3.89697 13.8486 3.89697 12.8396V5.72422C3.89697 4.71524 4.71491 3.8973 5.7239 3.8973Z"
        stroke="#0772D0"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  </Icon>
);

export default CopyOutlineIcon;
