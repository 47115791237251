import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverTrigger,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { HiPlus } from "react-icons/hi";
import { IoPeople } from "react-icons/io5";
import { useNavigate, useSearchParams } from "react-router-dom";

import Sidebar, { SidebarItem } from "../../components/Sidebar";
import { FilterArrowDownIcon } from "./icons";
import {
  ActiveFilterButtons,
  ManageTrainingSidebarFilterPopover,
} from "./ManageTrainingSidebarFilter";
import TrainingNameModal from "./TrainingNameModal";
import { EmptyName, manageTrainingRoute } from "./utils";

type FilterSortOptions = "A-Z" | "Date Created";

export type ManageTrainingProgramSidebarItem = {
  id: string;
  createdAt: string;
  name: string;
  owner: string;
  draft: boolean;
  trainees: number;
};

type ManageTrainingSidebarProps = {
  trainingPrograms: ManageTrainingProgramSidebarItem[];
  onCreateProgram: (name: string, description: string) => void;
  activeProgramId?: string;
  allTrainees?: boolean;
  traineeCount: number;
};

const ManageTrainingSidebar: React.FC<ManageTrainingSidebarProps> = ({
  trainingPrograms,
  onCreateProgram,
  activeProgramId,
  allTrainees,
  traineeCount,
}) => {
  const navigate = useNavigate();
  const nameModal = useDisclosure({});
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultFilters =
    searchParams.get("filters")?.split(",").filter(Boolean) || [];
  const defaultSort = searchParams.get("sort") || "A-Z";
  const [activeFilters, setActiveFilters] = useState<string[]>(defaultFilters);
  const [activeSort, setActiveSort] = useState<FilterSortOptions>(
    defaultSort as FilterSortOptions
  );

  useEffect(() => {
    setSearchParams({
      filters: activeFilters.join(","),
      sort: activeSort,
    });
  }, [activeFilters, activeSort]);

  const programOwners = trainingPrograms.reduce((list, program) => {
    list.add(program.owner);
    return list;
  }, new Set<string>());
  const sortedProgramOwners = [...programOwners].sort((a, b) =>
    a.localeCompare(b)
  );

  const filteredPrograms = trainingPrograms.filter((program) => {
    return activeFilters.length ? activeFilters.includes(program.owner) : true;
  });
  const sortedPrograms = filteredPrograms.sort((a, b) => {
    if (activeSort === "A-Z") {
      return a.name.localeCompare(b.name);
    }
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  return (
    <Sidebar>
      <Box px="2">
        <Text mt="5" fontSize="xs" color="gray.400" fontWeight="500" pl="4">
          Trainees
        </Text>
        <SidebarItem
          data-tour-id="training-all-trainees-sidebar-item"
          data-testid="training-all-trainees-sidebar-item"
          text="All Trainees"
          active={allTrainees}
          onClick={() =>
            navigate({
              pathname: "/training/manage/all-trainees",
              search: window.location.search,
            })
          }
          badgeIcon={IoPeople}
          badge={`${traineeCount}`}
        />
      </Box>
      <Box
        h="7px"
        background="gray.50"
        borderColor="gray.100"
        borderTopWidth="1px"
        borderBottomWidth="1px"
      />
      <Flex
        justifyContent="space-between"
        alignItems="center"
        pl="6"
        pr="14px"
        pt="3.5"
        mt="1.5"
        mb="2"
      >
        <Text fontSize="xs" color="gray.400" fontWeight="500">
          Programs
        </Text>
        <Box>
          {Boolean(activeFilters.length) && (
            <Tooltip label="Clear all filters" placement="top">
              <Button
                variant="ghost"
                fontSize="xs"
                color="blue.600"
                fontWeight="500"
                py="1"
                px="2"
                _hover={{ bg: "blue.50" }}
                height="28px"
                onClick={() => setActiveFilters([])}
              >
                Clear
              </Button>
            </Tooltip>
          )}
          <Popover placement="bottom-end">
            <PopoverTrigger>
              <Button
                variant="ghost"
                fontSize="xs"
                color="gray.400"
                fontWeight="500"
                py="1"
                px="2"
                _hover={{ bg: "gray.50" }}
                height="28px"
              >
                Filter
                <FilterArrowDownIcon ml="3" width="24px" height="24px" />
              </Button>
            </PopoverTrigger>
            <ManageTrainingSidebarFilterPopover
              activeFilters={activeFilters}
              activeSort={activeSort}
              programOwners={sortedProgramOwners}
              onSort={(update) => setActiveSort(update as FilterSortOptions)}
              onFilter={(update: string[]) => setActiveFilters(update)}
            />
          </Popover>
        </Box>
      </Flex>
      <ActiveFilterButtons
        onClick={setActiveFilters}
        activeFilters={activeFilters}
      />
      <Box px="2">
        <SidebarItem
          text="New Training Program"
          icon={HiPlus}
          color="blue.600"
          onClick={nameModal.onOpen}
          data-tour-id="training-create-program"
          data-testid="training-create-program"
        />
        {sortedPrograms.map((program) => (
          <SidebarItem
            key={program.id}
            text={program.name === "" ? EmptyName : program.name}
            active={program.id === activeProgramId}
            onClick={() =>
              navigate({
                pathname: manageTrainingRoute(program.id),
                search: window.location.search,
              })
            }
            badgeIcon={program.draft ? undefined : IoPeople}
            badge={program.draft ? "Draft" : program.trainees.toString()}
          />
        ))}
      </Box>
      {nameModal.isOpen && (
        <TrainingNameModal
          onClose={nameModal.onClose}
          onSave={onCreateProgram}
          mode="create"
        />
      )}
    </Sidebar>
  );
};

export default ManageTrainingSidebar;
