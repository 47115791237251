import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Checkbox,
  MenuItem,
  MenuItemProps,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import { IoTrashBin } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import { useToast } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { CallFragment } from "../../graphql";
import useRedactCall from "../../graphql/hooks/useRedactCall";

interface DeleteCallButtonProps extends Omit<MenuItemProps, "onClick"> {
  call: Pick<CallFragment, "id" | "name" | "canDelete" | "skipRedaction">;
}

const DeleteCallButton: React.FC<DeleteCallButtonProps> = ({
  call,
  ...menuItemProps
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [warningAcknowledged, setWarningAcknowledged] = React.useState(false);

  const cancelRef = React.useRef<HTMLButtonElement | null>(null);
  const navigate = useNavigate();
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();

  const [deleteCall, { loading }] = useRedactCall({
    onCompleted: (data) => {
      navigate("/", { replace: true });
    },
    onError: (e) => {
      toast({
        title: "Error",
        description:
          "This interview could not be deleted. Please try again later.",
        status: "error",
      });
    },
  });

  const isDeleteDisabled = !call.canDelete || call.skipRedaction;

  return (
    <>
      <Tooltip
        openDelay={500}
        label={
          isDeleteDisabled
            ? "Please contact your site administrator to request deletion of this interview."
            : undefined
        }
      >
        <MenuItem
          data-testid="call-settings-menu-delete"
          color={isDeleteDisabled ? "default" : "red.600"}
          icon={<IoTrashBin />}
          isDisabled={loading || isDeleteDisabled}
          onClick={() => {
            if (!isDeleteDisabled) {
              setIsModalOpen(true);
            }
          }}
          {...menuItemProps}
        >
          Delete
        </MenuItem>
      </Tooltip>
      <AlertDialog
        isOpen={isModalOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => {
          setWarningAcknowledged(false);
          setIsModalOpen(false);
        }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Are you sure you want to delete this interview?
            </AlertDialogHeader>
            <AlertDialogBody>
              <Text>
                Deleting
                <strong>{call.name ? ` ${call.name} ` : " Untitled "}</strong>
                will also permanently delete the recording and associated
                interview data from BrightHire. This will affect trainings that
                use this recording. This cannot be undone.
              </Text>
              <Checkbox
                mt={4}
                isChecked={warningAcknowledged}
                onChange={(e) => setWarningAcknowledged(e.target.checked)}
                data-testid="call-delete-confirm-checkbox"
              >
                I understand and would like to delete this interview.
              </Checkbox>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                variant="outline"
                size="sm"
                ref={cancelRef}
                isDisabled={loading}
                onClick={() => {
                  setWarningAcknowledged(false);
                  setIsModalOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                size="sm"
                data-testid="call-delete-confirm-button"
                isLoading={loading}
                onClick={() => {
                  sendGAEvent("delete", "call_review");
                  deleteCall({
                    variables: { id: call.id },
                  });
                }}
                isDisabled={!warningAcknowledged}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

DeleteCallButton.displayName = "DeleteCallButton";

export default DeleteCallButton;
