import { Box, Flex, Heading, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { HiOutlineDocumentSearch } from "react-icons/hi";

import { LoadingIndicator, SearchInput, useToast } from "../../../components";
import { EmptyStateWithIcon } from "../../../components/EmptyStates";
import useOffsetPagination from "../../../hooks/useOffsetPagination";
import { useSearchParam } from "../../../hooks/useSearchParam";
import { AutoJoinPositionGroupPopover } from "../../components";
import {
  PositionJbpListItemFragment,
  PositionPageListItemFragment,
  useOrgPositionsJbpLazyQuery,
  useOrgPositionsJbpQuery,
  useUpdatePositionsAutoJoinMutation,
} from "../../graphql";
import AutoJoinByPositionList from "./AutoJoinByPositionList";

const AutoJoinByPositionSettings: React.FC = () => {
  const toast = useToast();
  const [query, setQuery] = useSearchParam("q");
  const onSearch: (searchString: string) => void = (searchString) => {
    setQuery(searchString);
    setSearchTerm(searchString);
  };

  const jbpAllPositionData = useOrgPositionsJbpQuery({
    variables: {
      atsOnly: true,
    },
  });

  const [fetchJBP, jbpData] = useOrgPositionsJbpLazyQuery({
    variables: {
      atsOnly: true,
    },
    onError: (err) => {
      toast({
        title: "Error",
        description: `Failed to load positions: ${err.message}`,
        status: "error",
      });
    },
    fetchPolicy: "network-only",
    onCompleted: ({ currentUser }) => {
      if (!currentUser) return;
      const list = currentUser.organization.positions || [];
      setNumberOfPages(
        currentUser.organization.positions.pageInfo?.totalPages || 1
      );
      setListItems(list.results ?? []);
    },
  });

  const {
    listItems: positions,
    setListItems,
    setNumberOfPages,
    pageOptions,
    setSearchTerm,
    didFetchComplete,
  } = useOffsetPagination<PositionPageListItemFragment>(
    (opts) =>
      fetchJBP({
        ...opts,
        variables: {
          atsOnly: true,
          ...opts?.variables,
        },
      }),
    {
      initialSortBy: { id: "client.name", desc: true },
      pageLimit: 100,
      scrollToTop: false,
    }
  );

  const [updatePositionsAutoJoin] = useUpdatePositionsAutoJoinMutation({
    onError: (err) => {
      toast({
        status: "error",
        title: "Error",
        description: `Failed to turn on Auto Join Video Interview: ${err.message}`,
      });
    },
    onCompleted: (data) => {
      if (
        data?.updatePositionsAutoJoin &&
        data?.updatePositionsAutoJoin.positions.length > 0
      ) {
        toast({
          title: "Success",
          description: `BrightHire will ${
            data?.updatePositionsAutoJoin.positions[0].autoJoinInterview
              ? "automatically join and record existing and future scheduled interviews."
              : "NOT automatically join and record existing and future scheduled interviews."
          }`,
          status: "success",
        });
      }
    },
  });

  const handleUpdatePositionsAutoJoin = (
    positions: Array<PositionJbpListItemFragment>,
    autoJoinInterview: boolean
  ): Promise<any> => {
    const positionIds = positions.map((position) => position.id);
    return updatePositionsAutoJoin({
      variables: { positionIds, autoJoinInterview },
    });
  };

  return (
    <Box w="fit-content">
      <Heading as="h1" size="md" mb="4">
        Auto Join By Position
      </Heading>
      <Text mb="8">
        BrightHire will automatically join and record all video interviews for
        the positions selected below, except for candidates that have opted out
        and users that are excluded from recording.
      </Text>
      <Heading as="h2" size="sm" mb="4">
        Positions
      </Heading>
      <HStack mb="24px">
        {jbpAllPositionData.loading ? (
          <LoadingIndicator />
        ) : (
          <AutoJoinPositionGroupPopover
            positions={
              jbpAllPositionData.data?.currentUser?.organization.positions
                .results || []
            }
            orgAutoJoinAllNewPositions={
              jbpAllPositionData.data?.currentUser?.organization
                .autoJoinAllNewPositions || false
            }
          />
        )}
      </HStack>
      <Flex alignItems="center" mb={5} id="search-filter--search">
        <SearchInput
          placeholder="Search positions by name, requisition id, or department"
          defaultValue={query}
          onSearch={onSearch}
          width="500px"
          maxWidth="500px"
        />
      </Flex>
      {jbpData.loading ? (
        <LoadingIndicator />
      ) : didFetchComplete && positions.length > 0 ? (
        <AutoJoinByPositionList
          positions={positions}
          updatePositionsAutoJoin={handleUpdatePositionsAutoJoin}
          pageOptions={pageOptions}
          sortBy={pageOptions.sortBy}
        />
      ) : (
        <EmptyStateWithIcon
          heading="No positions found"
          icon={HiOutlineDocumentSearch}
          Text={() => (
            <Box>
              No positions were found{" "}
              {query
                ? " that match your query. Please try something else."
                : " for this organization."}
            </Box>
          )}
          padded={false}
        />
      )}
    </Box>
  );
};

export default AutoJoinByPositionSettings;
