import { Box, BoxProps, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { IoCut } from "react-icons/io5";

type ClipPillProps = {
  text?: string;
  size: "xs" | "sm" | "md";
  highContrast?: boolean;
};

const ClipPill: React.FC<ClipPillProps & BoxProps> = ({
  text = "Clip",
  size,
  highContrast,
  ...rest
}) => {
  const isSm = size === "sm";
  const isMd = size === "md";
  return (
    <Box {...rest}>
      <Flex
        display="inline-flex"
        alignItems="center"
        flexDir="row"
        background={highContrast ? "yellow.300" : "gray.100"}
        color="gray.900"
        borderRadius="16px"
        height={isMd ? "32px" : isSm ? "24px" : "22px"}
        px={isMd || isSm ? "4" : "3"}
      >
        <IoCut size={isMd ? 20 : 14} />
        <Text
          ml={isMd ? 2 : 1}
          fontWeight={700}
          display="inline"
          fontSize={isMd ? "sm" : isSm ? "xs" : "2xs"}
        >
          {text}
        </Text>
      </Flex>
    </Box>
  );
};

export default ClipPill;
