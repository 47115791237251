import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

export const InsightsIcon = (props: IconProps): JSX.Element => (
  <Icon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M4.1001 20.2729L10.4934 13.9337L14.6161 18.0564L22.1001 9.70312"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.29648 9.57642C3.38773 9.27661 3.81218 9.27661 3.90343 9.57642L4.29091 10.8495C4.3217 10.9506 4.40086 11.0298 4.50202 11.0606L5.7751 11.4481C6.0749 11.5393 6.0749 11.9638 5.7751 12.055L4.50202 12.4425C4.40086 12.4733 4.3217 12.5525 4.29091 12.6536L3.90343 13.9267C3.81218 14.2265 3.38773 14.2265 3.29648 13.9267L2.90899 12.6536C2.8782 12.5525 2.79904 12.4733 2.69788 12.4425L1.4248 12.055C1.125 11.9638 1.125 11.5393 1.4248 11.4481L2.69788 11.0606C2.79904 11.0298 2.8782 10.9506 2.90899 10.8495L3.29648 9.57642Z"
      fill="currentColor"
    />
    <path
      d="M14.6266 7.52173C14.7178 7.22192 15.1423 7.22192 15.2335 7.52173L15.621 8.79481C15.6518 8.89596 15.7309 8.97513 15.8321 9.00591L17.1052 9.3934C17.405 9.48465 17.405 9.9091 17.1052 10.0004L15.8321 10.3878C15.7309 10.4186 15.6518 10.4978 15.621 10.5989L15.2335 11.872C15.1423 12.1718 14.7178 12.1718 14.6266 11.872L14.2391 10.5989C14.2083 10.4978 14.1291 10.4186 14.028 10.3878L12.7549 10.0004C12.4551 9.9091 12.4551 9.48465 12.7549 9.3934L14.028 9.00591C14.1291 8.97513 14.2083 8.89596 14.2391 8.79481L14.6266 7.52173Z"
      fill="currentColor"
    />
    <path
      d="M7.9332 3.2717C8.04346 2.90943 8.55634 2.90943 8.6666 3.2717L9.13481 4.81C9.17202 4.93223 9.26767 5.02789 9.3899 5.06509L10.9282 5.5333C11.2905 5.64356 11.2905 6.15644 10.9282 6.2667L9.3899 6.73491C9.26767 6.77211 9.17202 6.86777 9.13481 6.99L8.6666 8.5283C8.55634 8.89057 8.04346 8.89057 7.9332 8.5283L7.46499 6.99C7.42779 6.86777 7.33213 6.77211 7.2099 6.73491L5.6716 6.2667C5.30934 6.15644 5.30934 5.64356 5.6716 5.5333L7.2099 5.06509C7.33213 5.02789 7.42779 4.93223 7.46499 4.81L7.9332 3.2717Z"
      fill="currentColor"
    />
  </Icon>
);
