import { FormControl, FormHelperText, Select, Tooltip } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { useToast } from "../../../components";
import { PositionRole, useSetMembershipsMutation } from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";

interface Membership {
  user: {
    id: string;
  };
  role: {
    id: string;
    name: string;
  };
  position: {
    id: string;
  };
}

interface PositionRoleSelectProps {
  membership: Membership;
  showDescription?: boolean;
}

const PositionRoleSelect: React.FC<PositionRoleSelectProps> = ({
  membership,
  showDescription = false,
}) => {
  const currentUser = useCurrentUser();
  const toast = useToast();
  const [positionRole, setPositionRole] =
    useState<
      Pick<PositionRole, "id" | "name" | "permissions" | "formattedPermissions">
    >();
  const { positionRoles } = currentUser.organization;

  const [setMemberships] = useSetMembershipsMutation({
    onCompleted: (data) => {
      if (data.setMemberships) {
        toast({
          title: "Success",
          description: "Updated role",
          status: "success",
        });
      }
    },
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
  });

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setPositionRole(positionRoles.find((r) => r.id === e.target.value));
    setMemberships({
      variables: {
        users: [
          {
            userId: membership.user.id,
            positionRoleId: e.target.value,
            positionId: membership.position.id,
          },
        ],
      },
    });
  };

  useEffect(() => {
    if (!positionRole) {
      setPositionRole(positionRoles?.find((r) => r.id === membership.role.id));
    }
  }, [positionRole]);

  const currentUserMembership = currentUser.memberships.find((m) => {
    return m.position.id === membership.position.id;
  });
  const enabled =
    currentUser.userRole?.canManageHiringTeam ||
    (membership.user.id !== currentUser.id &&
      currentUserMembership?.role?.canManageHiringTeam);

  return (
    <FormControl id="position-role-select">
      <Tooltip
        label={
          !showDescription
            ? positionRole?.formattedPermissions ?? undefined
            : undefined
        }
        openDelay={250}
      >
        <Select
          size="sm"
          w="max-content"
          onChange={onChange}
          value={positionRole?.id}
          defaultValue={membership.role.id}
          disabled={!enabled}
          data-testid="position-role-select"
        >
          {positionRoles?.map((r) => (
            <option key={r.id} value={r.id}>
              {r.formattedName}
            </option>
          ))}
        </Select>
      </Tooltip>
      {showDescription && (
        <FormHelperText>{positionRole?.formattedPermissions}</FormHelperText>
      )}
    </FormControl>
  );
};

export default PositionRoleSelect;
