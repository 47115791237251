import {
  Box,
  Flex,
  Grid,
  Heading,
  IconButton,
  Stat,
  StatHelpText,
  StatLabel,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import {
  Alert,
  LoadingIndicator,
  RouterLink,
  SortableTable,
} from "../../../components";
import { formatRelativeDate } from "../../../utils/datetime";
import {
  AddUserToHiringTeamButton,
  PositionRoleSelect,
  RemoveUserFromHiringTeamButton,
} from "../../components";
import {
  Position,
  PositionRole,
  useOrgHiringTeamQuery,
  User,
} from "../../graphql";
import useLink from "../../hooks/useLink";
import SettingsPageContainer from "../settings/shared/SettingsPageContainer";
import { BriefcaseIcon } from "../training/icons";

interface Membership {
  __typename?: "Membership";
  user: { __typename?: "User" } & Pick<User, "id" | "email" | "fullName">;
  role: { __typename?: "PositionRole" } & Pick<PositionRole, "id" | "name">;
  position: { __typename?: "Position" } & Pick<Position, "id" | "title">;
  createdAt: string;
}

const TeamInfoPage: React.FC = () => {
  const params = useParams() as { id: string };
  const navigate = useNavigate();
  const hiringTeamId = params.id;
  const { data, loading, error } = useOrgHiringTeamQuery({
    variables: {
      id: hiringTeamId,
    },
  });

  if (loading) {
    return <LoadingIndicator mt="25%" />;
  }

  if (error) {
    if (error.graphQLErrors.some((e) => e.extensions?.code === "FORBIDDEN")) {
      return <Navigate to="/forbidden" replace />;
    }
    // eslint-disable-next-line no-console
    console.error(error);
    return (
      <Alert status="error" description="Error loading candidate" reload />
    );
  }

  const position = data?.position;
  if (!position) return <Navigate to="/not-found" replace />;
  return (
    <SettingsPageContainer
      maxW="100%"
      heading={
        <>
          {position.title}
          <Tooltip label="View Position">
            <IconButton
              variant="ghost"
              colorScheme="blue"
              p="0"
              aria-label="View Position"
              icon={<BriefcaseIcon />}
              onClick={() => {
                navigate(`/position/${position.id}`);
              }}
              ml={2}
            />
          </Tooltip>
        </>
      }
    >
      <Box>
        <Flex>
          <Heading size="sm" pb={4}>
            Details
          </Heading>
        </Flex>
        <Grid
          maxW="700px"
          gridGap={1}
          gridTemplateColumns="1fr 1fr"
          gridTemplateRows="1fr"
          mb={8}
        >
          <TeamStat label="Position Group" value={position.client.name} />
          <TeamStat
            label="Date Added"
            value={formatRelativeDate(position.createdAt)}
          />
        </Grid>
        <Flex alignItems="baseline" mb="3">
          <Heading as="h2" size="sm" mr="4">
            Members
          </Heading>
          <AddUserToHiringTeamButton
            position={position}
            filterUserIds={position.memberships.map((m) => m.user.id)}
          />
        </Flex>
        {position.memberships.length < 1 ? (
          <Text size="sm">No members on this Hiring Team</Text>
        ) : (
          <SortableTable<Membership>
            columns={[
              {
                Header: "Name",
                accessor: (membership) => membership.user.fullName,
                id: "user.fullName",
              },
              {
                Header: "Email",
                accessor: (membership) => membership.user.email,
                id: "user.email",
                Cell: ({
                  row: { original: membership },
                }: {
                  row: { original: Membership };
                }) => {
                  const link = useLink({
                    type: "userInfo",
                    userId: membership.user.id,
                  });
                  return (
                    <RouterLink to={link}>{membership.user.email}</RouterLink>
                  );
                },
              },
              {
                Header: "Role",
                accessor: "role",
                Cell: ({
                  row: { original: membership },
                }: {
                  row: { original: Membership };
                }) => {
                  return <PositionRoleSelect membership={membership} />;
                },
              },
              {
                Header: "Added",
                accessor: "createdAt",
                Cell: ({
                  row: { original: membership },
                }: {
                  row: { original: Membership };
                }) => <>{formatRelativeDate(membership.createdAt)}</>,
              },
              {
                Header: "Remove",
                Cell: ({
                  row: { original: membership },
                }: {
                  row: { original: Membership };
                }) => {
                  return (
                    <RemoveUserFromHiringTeamButton membership={membership} />
                  );
                },
              },
            ]}
            data={position.memberships}
            initialSort={{ id: "title", desc: false }}
          />
        )}
      </Box>
    </SettingsPageContainer>
  );
};

const TeamStat: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => {
  return (
    <Stat>
      <StatLabel>{label}</StatLabel>
      <StatHelpText>{value}</StatHelpText>
    </Stat>
  );
};

export default TeamInfoPage;
