import {
  Box,
  BoxProps,
  Flex,
  Icon,
  StyleProps,
  SystemStyleObject,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { IconType } from "react-icons";
import { NavLink } from "react-router-dom";

import TruncatedText from "../TruncatedText";

type SidebarProps = StyleProps & {
  children: React.ReactNode;
};

const Sidebar: React.FC<SidebarProps> = ({ children, ...rest }) => {
  return (
    <Box
      width="340px"
      minWidth="340px"
      bg="white"
      borderRightColor="gray.100"
      borderRightWidth="1px"
      marginRight="6px"
      overflow="auto"
      {...rest}
    >
      {children}
    </Box>
  );
};

type SidebarItemProps = {
  text: string;
  color?: string;
  active?: boolean;
  icon?: IconType;
  iconBefore?: boolean;
  badgeIcon?: IconType;
  badge?: string;
  count?: number;
  to?: string;
  onClick?: () => void;
} & BoxProps;

export const SidebarItem: React.FC<SidebarItemProps> = ({
  text,
  color,
  icon,
  iconBefore,
  badgeIcon,
  active,
  onClick,
  badge,
  count,
  ...rest
}) => {
  const usedColor = color || (active ? "gray.900" : "gray.800");

  const activeStyle = {
    bg: "gray.100",
    borderColor: "gray.100",
    color: color ? undefined : "gray.900",
  };

  const backgroundStyle = active
    ? activeStyle
    : {
        borderColor: "transparent",
      };

  const hoverStyles: SystemStyleObject | undefined = active
    ? undefined
    : {
        bg: "blue.100",
        borderColor: "blue.100",
      };

  const clickedStyles: SystemStyleObject | undefined = active
    ? undefined
    : {
        bg: "gray.100",
        borderColor: "gray.100",
      };

  return (
    <Flex
      px="4"
      m="0"
      cursor="pointer"
      alignItems="center"
      borderWidth="1px"
      borderRadius="4px"
      height="10"
      onClick={onClick}
      color={usedColor}
      fontSize="sm"
      fontWeight="500"
      role="group"
      {...backgroundStyle}
      _hover={hoverStyles}
      _active={clickedStyles}
      {...rest}
    >
      {iconBefore ? (
        <>
          {icon && (
            <Icon
              as={icon}
              fill={active ? "blue.500" : "currentColor"}
              boxSize="4"
              mr="3"
            />
          )}
          <TruncatedText pr="2" noOfLines={1} text={text} />
        </>
      ) : (
        <>
          <TruncatedText pr="2" noOfLines={1} text={text} />
          {icon && (
            <Icon
              as={icon}
              fill={active ? "blue.500" : "currentColor"}
              boxSize="4"
              ml="auto"
            />
          )}
        </>
      )}
      {(badge || badgeIcon) && (
        <Flex
          ml="auto"
          alignItems="center"
          color="white"
          bg={active ? "blue.500" : "gray.300"}
          borderRadius="28px"
          px="2"
          py="0.5"
        >
          {badgeIcon && (
            <Icon as={badgeIcon} boxSize="4" mr={badge ? 1 : undefined} />
          )}
          {badge && <Text>{badge}</Text>}
        </Flex>
      )}
      {count && !badge && (
        <Text
          ml="auto"
          color="white"
          bg={active ? "blue.600" : "gray.300"}
          borderRadius="28px"
          px="2"
          py="0.5"
          _groupHover={{ bg: "blue.600" }}
        >
          {count}
        </Text>
      )}
    </Flex>
  );
};

type SidebarNavLinkProps = {
  to: string;
} & SidebarItemProps;

export const SidebarNavLink: React.FC<SidebarNavLinkProps> = ({
  to,
  ...rest
}) => (
  <NavLink to={to}>
    {({ isActive }) => <SidebarItem {...rest} active={isActive} />}
  </NavLink>
);

export const SidebarSectionHeader: React.FC<{ text: string }> = ({ text }) => {
  return (
    <Text
      fontSize="xs"
      color="gray.400"
      px="4"
      pt="3.5"
      mt="4"
      fontWeight="500"
      mb="1"
    >
      {text}
    </Text>
  );
};

export default Sidebar;
