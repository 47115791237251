import LogRocket from "logrocket";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { errorToast, successToast, useToast } from "../../../../../components";
import { useIsSmallScreen } from "../../../../../hooks/useIsSmallScreen";
import { isVideo } from "../../../../../utils/call";
import {
  ClipBetaFragment,
  useAnswerTrainingProgramQuestionsMutation,
  useClipTrackerSegmentLazyQuery,
  useClipTrackersQuery,
  useCurrentUserCallTrainingLazyQuery,
} from "../../../../graphql";
import { useCopyNotes } from "../../../../hooks/useCopyNotesBeta";
import CopyNotesButtons from "../../../CallNotes/beta/CopyNotesButtons";
import {
  NotesTab,
  SidebarTab,
  SidebarTabs,
  TrackersTab,
  TrainingTab,
  Transcript,
} from "../../../Recording";
import { MediaPlayerInterface } from "../../useMediaPlayer";
import useClipNotes from "./useClipNotes";
import useClipTranscript from "./useClipTranscript";

export enum ClipTab {
  TRANSCRIPT = "transcript",
  NOTES = "notes",
  POIS = "trackers",
  TRAINING = "training",
}

type ClipSidebarProps = {
  clip: Pick<
    ClipBetaFragment,
    "id" | "speakers" | "duration" | "interviewerIds" | "canEdit"
  >;
  callId: string;
  currentTab: ClipTab;
  player: MediaPlayerInterface;
  autoScrollEnabled: boolean;
  setAutoScrollEnabled(enabled: boolean): void;
  onTabChange?(t: ClipTab): void;
  onSeek(t: number): void;
  onUserChangeTab?(): void;
  isVideoVisible?: boolean;
  hideVideo(): void;
  focusVideo(): void;
  isVideoCall?: boolean;
};

const ClipSidebar: React.FC<ClipSidebarProps> = ({
  clip,
  callId,
  currentTab,
  player,
  autoScrollEnabled,
  isVideoCall,
  isVideoVisible,
  hideVideo,
  focusVideo,
  setAutoScrollEnabled,
  onTabChange,
  onUserChangeTab,
  onSeek,
}) => {
  const isSmallScreen = useIsSmallScreen();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const toast = useToast();

  const clipId = clip.id;

  const transcript = useClipTranscript({ clipId, callId });

  // Prepare clip notes
  const {
    questions,
    generalNotes,
    onAddNote,
    error: notesError,
  } = useClipNotes({
    callId,
    clipId,
    onCallNotesQueryCompleted: (data) => {
      if (!data.clip?.generalNotes.length && !data.clip?.questions.length) {
        onTabChange?.(ClipTab.POIS);
      }
    },
  });
  const handleCopyNotes = useCopyNotes({ call: clip, generalNotes, questions });

  // Prepare clip POIs
  const trackersQuery = useClipTrackersQuery({ variables: { clipId } });
  const [getTrackerSegments, trackerSegmentsQuery] =
    useClipTrackerSegmentLazyQuery();

  // Prepare clip training assessments
  const trainingProgramId = searchParams.get("trainingProgram");

  const [answerTraining] = useAnswerTrainingProgramQuestionsMutation({
    onCompleted: () => {
      navigate(`/training/view/${trainingProgramId as string}`);
      successToast(toast, "Your answers have been submitted.");
      LogRocket.track("training-submit-assessment");
    },
    onError: () =>
      errorToast(
        toast,
        "There was a problem submitting your assessment. Please try again later."
      ),
  });

  const [getTraining, trainingQueryResult] =
    useCurrentUserCallTrainingLazyQuery({
      fetchPolicy: "cache-and-network",
    });

  useEffect(() => {
    if (trainingProgramId) {
      getTraining({ variables: { callId, clipId, trainingProgramId } });
    }
  }, [trainingProgramId, callId, clipId]);

  const callTraining = trainingQueryResult.data?.currentUser?.callTraining;

  // Tab change logic
  useEffect(() => {
    if (isSmallScreen) {
      onTabChange?.(ClipTab.TRANSCRIPT);
    } else if (currentTab === ClipTab.TRANSCRIPT) {
      onTabChange?.(ClipTab.NOTES);
    }
  }, [isSmallScreen]);

  useEffect(() => {
    if (callTraining) {
      onTabChange?.(ClipTab.TRAINING);
    }
  }, [callTraining]);

  return (
    <SidebarTabs
      currentTab={currentTab}
      onTabChange={(newTab) => {
        if (newTab !== currentTab) onUserChangeTab?.();
        onTabChange?.(newTab);
      }}
    >
      {isSmallScreen && (
        <SidebarTab id={ClipTab.TRANSCRIPT} displayName="Transcript">
          <Transcript
            {...transcript}
            speakers={clip.speakers}
            player={player}
            autoScrollEnabled={autoScrollEnabled}
            setAutoScrollEnabled={setAutoScrollEnabled}
            isVideoCall={isVideoCall}
            isVideoVisible={isVideoVisible}
            hideVideo={hideVideo}
            focusVideo={focusVideo}
          />
        </SidebarTab>
      )}

      <SidebarTab id={ClipTab.NOTES} displayName="Notes">
        <NotesTab
          callId={callId}
          clipId={clipId}
          generalNotes={generalNotes}
          questions={questions}
          callDuration={clip.duration}
          interviewerIds={clip.interviewerIds}
          notesReadOnly={!clip.canEdit}
          onClickTimestamp={onSeek}
          playerTime={player.time}
          error={notesError}
          overflow="hidden"
          headerSlot={
            <CopyNotesButtons
              autofillEnabled={false}
              onCopy={handleCopyNotes}
            />
          }
          onAddNote={onAddNote}
        />
      </SidebarTab>

      <SidebarTab id={ClipTab.POIS} displayName="POIs">
        <TrackersTab
          onSeek={onSeek}
          onTrackerGroupChange={(trackerGroupId) => {
            if (trackerGroupId) {
              getTrackerSegments({
                variables: { clipId, trackerGroupId },
              });
            }
          }}
          onTrackerKeywordChange={(trackerKeywordId) => {
            if (trackerKeywordId) {
              getTrackerSegments({
                variables: { clipId, trackerKeywordId },
              });
            }
          }}
          trackers={trackersQuery?.data?.clip?.trackers}
          trackerSegments={trackerSegmentsQuery.data?.clip?.trackerSegments}
          speakers={trackersQuery.data?.clip?.callSpeakers}
          keywordsFetchData={trackersQuery}
          segmentsFetchData={trackerSegmentsQuery}
          speakerMenuDisabled={
            !clip.canEdit ||
            !trackerSegmentsQuery.data?.clip ||
            !isVideo(trackerSegmentsQuery.data.clip.callType)
          }
          doesCallHaveTranscript={transcript.transcript.length > 0}
        />
      </SidebarTab>

      {callTraining && !isSmallScreen && (
        <SidebarTab id={ClipTab.TRAINING} displayName="Training">
          <TrainingTab
            callTraining={callTraining}
            onAnswer={(answers) => answerTraining({ variables: { answers } })}
            pt="6"
            h="100%"
          />
        </SidebarTab>
      )}
    </SidebarTabs>
  );
};

export default ClipSidebar;
