import { Flex } from "@chakra-ui/react";
import React from "react";

import useWindowDimensions from "../../../hooks/useWindowDimensions";
import DefaultLayout from "../../layouts/DefaultLayout";
import MainContent from "./MainContent";
import ManageTrainingSidebar, {
  ManageTrainingProgramSidebarItem,
} from "./ManageTrainingSidebar";
import { TrainingApi } from "./useTrainingApi";

type ManageTrainingLayoutProps = {
  activeProgramId?: string;
  allTrainees?: boolean;
  children: React.ReactNode;
  traineeCount: number;
  trainingApi: TrainingApi;
  trainingPrograms: ManageTrainingProgramSidebarItem[];
};

const ManageTrainingLayout: React.FC<ManageTrainingLayoutProps> = ({
  trainingPrograms,
  trainingApi,
  activeProgramId,
  allTrainees,
  traineeCount,
  children,
}) => {
  const { layoutHeight } = useWindowDimensions();
  return (
    <DefaultLayout>
      <Flex height={layoutHeight} overflowX="auto">
        <ManageTrainingSidebar
          trainingPrograms={trainingPrograms}
          onCreateProgram={trainingApi.program.create}
          activeProgramId={activeProgramId}
          allTrainees={allTrainees}
          traineeCount={traineeCount}
        />
        <MainContent minW="650px">{children}</MainContent>
      </Flex>
    </DefaultLayout>
  );
};

export default ManageTrainingLayout;
