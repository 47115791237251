import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

import { useToast } from "../../../components";
import { PositionListItemFragment } from "../../graphql";
import useAddPosition from "../../graphql/hooks/useAddPosition";
import PositionForm from "../PositionForm/PositionForm";

interface AddPositionModalProps {
  isOpen: boolean;
  onSave: (position: PositionListItemFragment) => void;
  onClose: () => void;
  title?: string;
}

export const AddPositionModal: React.FC<AddPositionModalProps> = ({
  isOpen,
  onSave,
  onClose,
  title,
}) => {
  const toast = useToast();

  const [addPosition, { loading: addLoading }] = useAddPosition({
    onCompleted: (data) => {
      if (data?.addPosition) {
        onSave(data?.addPosition.position);
      }
    },
    onError: (error?: Error) => {
      toast({
        title: "Error",
        status: "error",
        description: error?.message || "Error adding position",
      });
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Add Position</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PositionForm
              isLoading={addLoading}
              onSubmit={(formData) => addPosition({ variables: formData })}
              onCancel={onClose}
              title={title}
            />
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
