import { MutableRefObject, useEffect, useRef } from "react";

/**
 * Execute a callback at a given interval and update the interval duration
 * if it changes
 * @param callback Callback to execute on each function run
 * @param delay Delay to use between executions
 */
const useInterval = (
  callback: () => void,
  /**
   * Delay between, defaults to 5 minutes
   */
  delay: number = 1000 * 60 * 5
): void => {
  const savedCallback:
    | MutableRefObject<undefined>
    | MutableRefObject<() => void> = useRef();

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval and update the delay when it changes
  useEffect(() => {
    const executeCallback = (): void => {
      if (savedCallback?.current) {
        savedCallback.current();
      }
    };
    const id = setInterval(executeCallback, delay);
    return () => clearInterval(id);
  }, [delay]);
};

export default useInterval;
