import LogRocket from "logrocket";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { LoadingIndicator } from "../../../components";
import {
  useTrainingProgramQuery,
  useTrainingProgramTraineeListItemsQuery,
} from "../../graphql";
import DefaultLayout from "../../layouts/DefaultLayout";
import ErrorPage from "./ErrorPage";
import { useOnVisibilityChange, viewTrainingRoute } from "./utils";
import ViewTrainingPageView from "./ViewTrainingPageView";
import { ViewTrainingProgramSidebarItem } from "./ViewTrainingSidebar";

/**
 * Page for viewing a specific training program as a trainee.
 */
const ViewTrainingPage: React.FC = () => {
  useEffect(() => LogRocket.track("training-trainee-page"), []);
  const { trainingProgramId } = useParams() as { trainingProgramId: string };
  const navigate = useNavigate();
  const { data, loading, error } = useTrainingProgramTraineeListItemsQuery({
    pollInterval: 10000,
  });
  const trainingPrograms: ViewTrainingProgramSidebarItem[] =
    data?.trainingProgramTraineeListItems || [];

  // TODO: replace with a query with much more limited data for trainees
  const {
    data: trainingProgramData,
    error: trainingProgramError,
    refetch,
  } = useTrainingProgramQuery({
    variables: { id: trainingProgramId },
    fetchPolicy: "cache-and-network",
  });

  useOnVisibilityChange(refetch);

  if (loading) {
    return (
      <DefaultLayout>
        <LoadingIndicator />
      </DefaultLayout>
    );
  }

  if (error || trainingProgramError) {
    return (
      <ErrorPage
        title="We've been notified of the error and are working to fix it. Please reload the page to view this training program."
        error={error || trainingProgramError}
      />
    );
  }

  const currentTraining = trainingProgramData?.trainingProgram;
  const trainingsInProgress = trainingPrograms.filter((p) => !p.completed);
  const completedTrainings = trainingPrograms.filter((p) => p.completed);

  return (
    <ViewTrainingPageView
      key={currentTraining?.id}
      inProgress={trainingsInProgress}
      completed={completedTrainings}
      currentTrainingProgram={currentTraining}
      onClickProgram={(id: string) => navigate(viewTrainingRoute(id))}
    />
  );
};

export default ViewTrainingPage;
