import { useSearchParams } from "react-router-dom";

const useStartTime = (duration: number | undefined): number => {
  const [searchParams] = useSearchParams();
  const t = Number(searchParams.get("t"));

  if (Number.isNaN(t) || t < 0 || !duration || t > duration) {
    return 0;
  }

  return t;
};

export default useStartTime;
