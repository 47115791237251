import { Box, Flex, FlexProps, StyleProps } from "@chakra-ui/react";
import React from "react";
import { FiFastForward, FiRewind } from "react-icons/fi";
import { MdPause, MdPlayArrow } from "react-icons/md";

import { PlaybackRateMenu } from "../../../components";
import { AudioControls } from "../Video/AudioControls";
import { VideoStateControl } from "../Video/useVideoState";
import VideoFullscreenControls from "../Video/VideoFullscreenControls";
import { MediaPlayerInterface } from "./useMediaPlayer";
import VideoPlayerProgressBar, {
  VideoPlayerProgressBarProps,
} from "./VideoPlayerProgressBar";
import VideoTime from "./VideoTime";

type VideoControlsMobileProps = {
  player: MediaPlayerInterface;
  fullScreenControls?: VideoStateControl;
  pipControls?: VideoStateControl;
  progressBarProps: VideoPlayerProgressBarProps;
  onClick?: FlexProps["onClick"];
  variant?: "compact";
} & StyleProps;

const VideoControlsMobile: React.FC<VideoControlsMobileProps> = ({
  player,
  pipControls,
  fullScreenControls,
  progressBarProps,
  onClick,
  variant,
  ...styles
}) => {
  const {
    time,
    duration: playerDuration,
    setVolume,
    mute,
    playing,
    volume,
    muted,
    playbackRate,
    setPlaybackRate,
  } = player;

  const compact = variant === "compact";

  return (
    <Flex
      direction="column"
      position="absolute"
      top="0"
      width="100%"
      height="100%"
      pt="2"
      px="4"
      pb={compact ? "0" : "4"}
      bg="blackAlpha.600"
      fontSize="sm"
      fontWeight="medium"
      onClick={onClick}
      {...styles}
    >
      <Flex>
        <Flex mt="-2" alignItems="center">
          <AudioControls
            setVolume={setVolume}
            mute={mute}
            volume={volume}
            muted={muted}
            isTop
          />

          <PlaybackRateMenu
            playbackRate={playbackRate}
            setPlaybackRate={setPlaybackRate}
            menuProps={{ variant: "blackAlpha" }}
            menuButtonProps={{
              borderRadius: "full",
              background: "transparent",
            }}
          />
        </Flex>

        <VideoFullscreenControls
          ml="auto"
          pipControls={pipControls}
          fullScreenControls={fullScreenControls}
        />
      </Flex>

      <Flex
        flexGrow="1"
        alignItems="center"
        justifyContent="space-around"
        color="white"
      >
        <Flex
          direction="column"
          alignItems="center"
          pos="relative"
          ml="3"
          onClick={(e) => {
            e.stopPropagation();
            player.skipBack(15);
          }}
        >
          <FiRewind size="30" />
          <Box pos="absolute" top="100%">
            -15s
          </Box>
        </Flex>

        {playing ? (
          <MdPause
            size="48"
            onClick={(e) => {
              e.stopPropagation();
              player.pause();
            }}
            color="white"
          />
        ) : (
          <MdPlayArrow
            size="48"
            onClick={(e) => {
              e.stopPropagation();
              player.play();
            }}
            color="white"
          />
        )}

        <Flex
          direction="column"
          alignItems="center"
          pos="relative"
          mr="3"
          onClick={(e) => {
            e.stopPropagation();
            player.skipForward(15);
          }}
        >
          <FiFastForward size="30" />
          <Box pos="absolute" top="100%">
            +15s
          </Box>
        </Flex>
      </Flex>

      <Box mt={compact ? "3" : "auto"}>
        <VideoTime
          time={time}
          duration={playerDuration}
          mb={compact ? "-1" : "1"}
        />
        <VideoPlayerProgressBar {...progressBarProps} />
      </Box>
    </Flex>
  );
};

export default VideoControlsMobile;
