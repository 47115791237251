import { Button } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaGoogle } from "react-icons/fa";

import config from "../../../config";
import useInterviewAssistant from "../../../utils/popup";
import { CurrentUserFragment, useCurrentUserLazyQuery } from "../../graphql";

interface GoogleAuthButtonProps {
  label: string;
  onAuth?: (currentUser: CurrentUserFragment) => void;
  onError: (msg: string) => void;
  inviteCode?: string;
}

const GoogleAuthButton: React.FC<GoogleAuthButtonProps> = ({
  label,
  onAuth,
  onError,
  inviteCode,
}) => {
  const { focusPopup, isPopupOpen, openAuthPopup } = useInterviewAssistant();
  const [wasMessageSet, setWasMessageSet] = useState<boolean>(false);
  const [testAuthCode, setTestAuthCode] = useState<string>("");

  const [getCurrentUser, { loading }] = useCurrentUserLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: ({ currentUser }) => {
      if (currentUser && onAuth) {
        onAuth(currentUser);
      }
    },
    onError: () => {
      setWasMessageSet(true);
      onError("An error occured while attempting to log you in.");
    },
  });

  useEffect(() => {
    // We will no longer be using the Google SDK so we're just getting the
    // mocked auth code for the Cypress test user
    const f = async (): Promise<void> => {
      if (config.appEnv === "test") {
        await new Promise((resolve, reject) => {
          (function waitForAuthCode(retries = 0) {
            if (window.localStorage.getItem("testAuthCode")) {
              resolve(null);
            }
            // retry for 10s
            if (retries > 100) {
              reject(new Error("Timed out while waiting for auth code"));
            }
            setTimeout(waitForAuthCode, 100, retries + 1);
          })();
        });
        setTestAuthCode(window.localStorage.getItem("testAuthCode") ?? "");
      }
    };
    f();
  }, []);

  /**
   * Handle messages posted from the oauth child window
   * @param e The post message event
   */
  const postMessageHandler = (e: any): void => {
    if (
      e.data?.type === "google_oauth_postmessage" &&
      e.data?.result === "success"
    ) {
      getCurrentUser();
      window.removeEventListener("message", postMessageHandler);
    } else if (e.data?.result === "error" && !wasMessageSet) {
      setWasMessageSet(true);
      onError(e.data?.message);
    }
  };

  /**
   * Action when the user clicks the "Continue with Google" button
   */
  const onClick = (): void => {
    setWasMessageSet(false);
    if (isPopupOpen("auth")) {
      focusPopup("auth");
    } else {
      // Opens a popup window to run the Google server-side oauth flow.
      // See /routes/auth.py
      let url = "/auth/google/init";
      if (inviteCode) {
        url += `?invite_code=${inviteCode}`;
      }
      if (config.appEnv === "test") {
        url += `${url.indexOf("?") >= 0 ? "&" : "?"}auth_code=${testAuthCode}`;
      }
      const { errorMessage } = openAuthPopup(url);
      if (errorMessage) {
        onError(errorMessage);
      } else {
        window.addEventListener("message", postMessageHandler);
      }
    }
  };

  const isLoading = loading || (!testAuthCode && config.appEnv === "test");
  return (
    <Button
      leftIcon={<FaGoogle />}
      width="100%"
      onClick={onClick}
      isLoading={isLoading}
      data-testid={isLoading ? null : "google-auth-button"}
    >
      {label}
    </Button>
  );
};

export default GoogleAuthButton;
