import { checkCookieExistence } from "./cookie";

export const track = (
  event: string,
  properties?: Record<string, unknown>,
  options?: SegmentAnalytics.SegmentOpts,
  callback?: () => void
): void => {
  const isImpersonating = checkCookieExistence("is_impersonating");
  if (!window.analytics || isImpersonating) return;

  analytics.track(event, properties, options, callback);
};

// Note: url, title, referrer and path are collected automatically
export const page = (
  name?: string,
  properties?: Record<string, unknown>,
  options?: SegmentAnalytics.SegmentOpts,
  callback?: () => void
): void => {
  const isImpersonating = checkCookieExistence("is_impersonating");
  if (!window.analytics || isImpersonating) return;

  analytics.page(name, properties, options, callback);
};

export const identify = (
  userId: string,
  traits?: Record<string, unknown>,
  options?: SegmentAnalytics.SegmentOpts,
  callback?: () => void
): void => {
  const isImpersonating = checkCookieExistence("is_impersonating");
  if (!window.analytics || isImpersonating) return;

  analytics.identify(userId, traits, options, callback);
};
