import config from "../../config";

export type SetPageTitleProps = {
  base?: string;
  title?: string;
  prefix?: string;
  suffix?: string;
};

/**
 * Hook to set page title (the text shown on the browser tab).
 *
 * - If you specify a `title`, that will be used
 * - Otherwise you can control the `prefix`, `suffix`, and `base` (default is "BrightHire") strings
 */
function useSetPageTitle({
  base = "BrightHire",
  title = "",
  prefix = "",
  suffix = "",
}: SetPageTitleProps = {}): void {
  if (title) {
    document.title = title;
    return;
  }

  const { appEnv } = config;
  const envPrefix =
    appEnv === "development"
      ? "[dev] "
      : appEnv === "staging"
      ? "[staging] "
      : "";

  document.title = `${envPrefix}${prefix}${base}${suffix}`;
}

export default useSetPageTitle;
