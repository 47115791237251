import { ApolloError } from "@apollo/client";
import invariant from "invariant";

import { isInPerson as callIsInPerson } from "../../../utils/call";
import {
  Call,
  EndCallMutation,
  EndInPersonCallMutation,
  useEndCallMutation,
  useEndInPersonCallMutation,
} from "..";

type MutationResult =
  | EndCallMutation["endCall"]
  | EndInPersonCallMutation["endInPersonCall"];

export type EndCallParams = {
  call: Pick<Call, "id" | "type"> | null | undefined;
  onError?(error: ApolloError): void;
  onCompleted?(data: MutationResult): void;
};

export type EndCall = {
  endCall(): Promise<void>;
  loading: boolean;
};

export const useEndCall = ({
  call,
  onError,
  onCompleted,
}: EndCallParams): EndCall => {
  const isInPerson = call && callIsInPerson(call.type);

  const [endCall, endCallMutation] = useEndCallMutation({
    onError,
    onCompleted(data) {
      onCompleted?.(data.endCall);
    },
  });
  const [endInPersonCall, endInPersonCallMutation] = useEndInPersonCallMutation(
    {
      onError,
      onCompleted(data) {
        onCompleted?.(data.endInPersonCall);
      },
    }
  );

  return {
    async endCall() {
      invariant(call, "missing call");
      const callId = call.id;

      if (isInPerson) {
        await endInPersonCall({ variables: { callId } });
        return;
      }

      await endCall({ variables: { callId } });
    },
    loading: isInPerson
      ? endInPersonCallMutation.loading
      : endCallMutation.loading,
  };
};
