import { Call, CandidatePositionChaptersQuery } from "../../../graphql";

export type CandidatePositionChapter =
  CandidatePositionChaptersQuery["candidatePositionChapters"]["chapters"][number];

export const isChapter = (c: any): c is CandidatePositionChapter =>
  // eslint-disable-next-line no-underscore-dangle
  c.__typename === "Chapter";

export type Match = {
  chapter1: CandidatePositionChapter;
  chapter2: CandidatePositionChapter;
  similarity: number;
  id: string;
};

export type CandidateCompareListItemFragment = {
  id: string;
  fullName: string;
  validCallsCount: number;
  lastCall: Call;
};
