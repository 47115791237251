import { Flex, Icon, Tooltip as ChakraTooltip } from "@chakra-ui/react";
import React from "react";
import { HiOutlineInformationCircle } from "react-icons/hi";

import { BetaTag } from "../../../../components";

const AskBetaTag = (): JSX.Element => (
  <Flex>
    <ChakraTooltip
      bg="white"
      p="2"
      borderRadius="md"
      border="1px solid"
      borderColor="gray.200"
      color="gray.800"
      boxShadow="none"
      fontSize="sm"
      shouldWrapChildren
      fontWeight="normal"
      placement="top"
      label={
        <>
          Quick Actions are in Beta. As they are generated using modern AI
          tools, they may occasionally include inaccuracies or errors. Be sure
          to check the interview recording in important or ambiguous cases.
        </>
      }
    >
      <BetaTag bg="purple.300" _hover={{ bg: "purple.300" }} />
      <Icon
        boxSize={5}
        ml="10px"
        color="gray.800"
        as={HiOutlineInformationCircle}
      />
    </ChakraTooltip>
  </Flex>
);

export default AskBetaTag;
