/**
 * Text options depending on what action is associated with the interview
 */
export enum ButtonText {
  ADD = "Add BrightHire",
  ERROR = "Not available",
  EXCLUDED = "Not available",
  GOOGLE_MEET = "Launch Meet",
  TEAMS = "Launch Teams",
  PHONE = "Start call",
  ZOOM = "Launch Zoom",
  UPDATING = "Updating",
  UPDATE_FAILED = "Update failed",
  OPT_IN = "Launch meeting",
}

/**
 * An action associated with a scheduled interview
 */
export enum ButtonAction {
  /**
   * The meeting is eligible to have BrightHire added to it
   */
  ADD = "add",
  /**
   * An error has occurred
   */
  ERROR = "error",
  /**
   * The meeting is excluded from BrightHire, either directly or at the user
   * level due to an opt-out
   */
  EXCLUDED = "excluded",
  /**
   * The interview should launch a Google Meet meeting
   */
  GOOGLE_MEET = "googleMeet",
  /**
   * A loading operation is in progress, most likely an http request
   */
  LOADING = "loading",
  /**
   * The meeting import associated with the interview is being updated
   */
  UPDATING = "updating",
  /**
   * The meeting import associated with the interview is being updated
   */
  UPDATE_FAILED = "updateFailed",
  /**
   * The interview should launch a phone call
   */
  PHONE = "phone",
  /**
   * The interview should launch a Zoom call
   */
  ZOOM = "zoom",
  /**
   * The interview should launch a Teams call
   */
  TEAMS = "teams",

  // Opt in page
  OPT_IN = "optIn",
  /**
   * No action expected at this time
   */
  NONE = "none",
}

/**
 * Color variant to use, depending on the list position and action of the button
 */
export enum ButtonVariant {
  ADD_SOLID = "warning",
  ADD_OUTLINE = "warning",
  ERROR_SOLID = "disabled",
  ERROR_OUTLINE = "disabled",
  EXCLUDED_SOLID = "disabled",
  EXCLUDED_OUTLINE = "disabled",
  GOOGLE_MEET_SOLID = "solid",
  GOOGLE_MEET_OUTLINE = "outline",
  TEAMS_SOLID = "solid",
  TEAMS_OUTLINE = "outline",
  UPDATING_SOLID = "disabled",
  UPDATING_OUTLINE = "disabled",
  UPDATE_FAILED_SOLID = "disabled",
  UPDATE_FAILED_OUTLINE = "disabled",
  PHONE_SOLID = "solid",
  PHONE_OUTLINE = "outline",
  ZOOM_SOLID = "solid",
  ZOOM_OUTLINE = "outline",
}

/**
 * The potential reasons why BrightHire is not be eligible to be added to an
 * interview
 */
export enum AddBrightHireReason {
  ZOOM_DISABLED = "Zoom disabled",
  INVALID_INTERVIEWER = "Invalid interviewer",
  NOT_FOUND = "Meeting not found",
  EXCLUDED = "BrightHire excluded",
  INVALID_MEETING_TYPE_INSTANT = "Invalid meeting type: INSTANT",
  INVALID_MEETING_TYPE_RECURRING = "Invalid meeting type: RECURRING",
  INVALID_MEETING_TYPE_PMI = "Invalid meeting type: PMI",
  INVALID_MEETING_TYPE = "Invalid meeting type",
  UNKNOWN = "Unknown",
}

/**
 * Button properties for the Launch Interview button
 */
export type LaunchInterviewButton = {
  /**
   * The action type that the user will take by clicking
   */
  action: ButtonAction;
  /**
   * The variant to display
   */
  variant?: ButtonVariant | "warning" | "disabled" | "solid" | "outline";
  /**
   * Button text
   */
  text?: ButtonText | string;
  /**
   * Tooltip to appear on hover
   */
  tooltip?: JSX.Element | string;
  /**
   * Function to execute on button click
   */
  execute?: () => void;
  leftIcon?: React.ReactElement;
};
