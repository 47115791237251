import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoCreateSharp } from "react-icons/io5";

import { Clip, Maybe, UserRoleName } from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";
import DeleteClipButton from "./DeleteClipButton";
import EditClipModal from "./EditClipModal";
import ShareClipButton from "./ShareClipButton";

interface ClipListItemMoreMenuProps {
  clip: Pick<
    Clip,
    | "id"
    | "name"
    | "callId"
    | "startTime"
    | "endTime"
    | "thumbnailImageUrl"
    | "canEdit"
    | "canDelete"
    | "visibility"
    | "visibleVisibilityLevels"
  >;
  listPosition: number;
  positionId?: Maybe<string> | undefined;
  readOnly: boolean;
}

const ClipListItemMoreMenu: React.FC<ClipListItemMoreMenuProps> = ({
  clip,
  listPosition = 1,
  positionId,
  readOnly = false,
}) => {
  const currentUser = useCurrentUser();
  const toast = useToast();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const isBasicUser = currentUser.userRole?.name === UserRoleName.Basic;
  if (isBasicUser) return null;

  return (
    <Menu placement="right-start" autoSelect={false}>
      <EditClipModal
        clip={clip}
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
        }}
        onSave={(clip) => {
          setIsEditModalOpen(false);
        }}
      />
      <MenuButton
        p="1"
        minW="auto"
        color="gray.500"
        borderRadius="4px"
        _hover={{
          bg: "gray.50",
        }}
        data-testid={`clips-list-item-${listPosition}-menu-button`}
      >
        <BsThreeDotsVertical size="16px" />
      </MenuButton>
      <MenuList fontSize="sm" color="gray.900" py="1">
        <MenuItem px={0} data-testid={`clips-list-item-${listPosition}-share`}>
          <ShareClipButton
            clip={clip}
            callId={clip.callId}
            buttonStyle="menuItem"
            isModalOpen={isShareModalOpen}
            onCloseModal={() => setIsShareModalOpen(false)}
            onOpenModal={() => setIsShareModalOpen(true)}
            positionId={positionId}
          />
        </MenuItem>

        {!readOnly && (
          <>
            <MenuDivider />

            <MenuItem
              px={0}
              data-testid={`clips-list-item-${listPosition}-edit`}
              disabled={!clip.canEdit}
            >
              <Flex
                flexFlow="row nowrap"
                alignItems="center"
                onClick={() => setIsEditModalOpen(clip.canEdit)}
                px="3"
                width="full"
                color={clip.canEdit ? "gray.900" : "gray.700"}
              >
                <IoCreateSharp size="16px" />
                <Flex flexFlow="column nowrap" ml="3" fontWeight="500">
                  Edit Title
                  {!clip.canEdit && (
                    <Box fontWeight="400" fontSize="xs" width="100%">
                      Please contact clip creator to request title change.
                    </Box>
                  )}
                </Flex>
              </Flex>
            </MenuItem>

            <MenuItem
              px={0}
              data-testid={`clips-list-item-${listPosition}-delete`}
            >
              <DeleteClipButton
                clipId={clip.id}
                canDelete={clip.canDelete}
                onSuccess={() =>
                  toast({
                    title: "Clip deleted",
                    status: "success",
                  })
                }
                onError={(errorMsg) => {
                  toast({
                    title: errorMsg,
                    status: "error",
                  });
                }}
              />
            </MenuItem>
          </>
        )}
      </MenuList>
    </Menu>
  );
};

export default ClipListItemMoreMenu;
