import LogRocket from "logrocket";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { errorToast, useToast } from "../../../../components";
import {
  CallTrainingFragment,
  useAnswerTrainingProgramQuestionsMutation,
  useCurrentUserCallTrainingLazyQuery,
} from "../../../graphql";

type CallTrainingParams = {
  callId: string;
};

type CallTrainingReturn = {
  callTraining: CallTrainingFragment | null;
  onAnswer(
    answers: { questionId: string; answer: string; itemId: string }[]
  ): void;
};

function useCallTraining({ callId }: CallTrainingParams): CallTrainingReturn {
  const toast = useToast();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const trainingProgramId = searchParams.get("trainingProgram");

  const [answerTrainingProgramQuestions] =
    useAnswerTrainingProgramQuestionsMutation({
      onError: (err) => {
        errorToast(toast, `Error submitting answers: ${err.message}`);
      },
    });

  const [getCallTraining, callTrainingResults] =
    useCurrentUserCallTrainingLazyQuery({
      fetchPolicy: "cache-and-network",
    });

  useEffect(() => {
    if (trainingProgramId) {
      getCallTraining({ variables: { callId, trainingProgramId } });
    }
  }, [trainingProgramId, callId]);

  const callTraining =
    callTrainingResults.data?.currentUser?.callTraining ?? null;

  return {
    callTraining,
    onAnswer(answers) {
      if (callTraining) {
        answerTrainingProgramQuestions({
          variables: { answers },
          onCompleted: () => {
            navigate(`/training/view/${trainingProgramId as string}`);
            toast({
              title: "Success!",
              description: "Your answers have been submitted.",
              status: "success",
            });
            LogRocket.track("training-submit-assessment");
          },
        });
      }
    },
  };
}

export default useCallTraining;
