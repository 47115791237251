import { AccordionProps } from "@chakra-ui/react";
import { useEffect, useRef } from "react";

/**
 * Chakra accordion will render an item as collapsed
 * on the first render, even if the `defaultIndex` prop is used
 * to indicate that the item should be expanded on first render.
 *
 * This can cause layout flashes + jumps on first render.
 *
 * The fix is to use the `reduceMotion` prop, but only for the first render
 */
const useChakraAccordionBugfix = (
  expandedByDefault?: boolean
): AccordionProps => {
  const isFirstRenderRef = useRef(true);
  const isFirstRender = isFirstRenderRef.current;

  useEffect(() => {
    isFirstRenderRef.current = false;
  }, []);

  const bugfixActive = expandedByDefault && isFirstRender;

  if (bugfixActive) {
    return { reduceMotion: true };
  }

  return {};
};

export default useChakraAccordionBugfix;
