import { Box, Button, Flex, HStack } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { HiOutlinePlus } from "react-icons/hi";
import { v4 as uuidv4 } from "uuid";

import DragHandleDots from "../../../components/Icons/DragHandleDots";
import {
  CallGuideItemChild,
  CallGuideQuery,
  GuideItemType,
} from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";
import GuideItemCompetency from "./GuideItems/GuideItemCompetency";
import GuideItemQuestion from "./GuideItems/GuideItemQuestion";
import GuideItemText from "./GuideItems/GuideItemText";
import { CommonGuideItemProps, GuideItem, GuideItemUpdate } from "./types";
import { stripTypenameFromCues } from "./util";

type CallGuide = NonNullable<CallGuideQuery["callGuide"]>;

interface GuideItemListProps {
  callGuide: CallGuide;
  readOnly: boolean;
  isEditing: boolean;
  setGuide(callGuide: CallGuide): void;
  setHaveChangesBeenMade: React.Dispatch<React.SetStateAction<boolean>>;
  idsWithErrors: string[];
}

const GuideItemList: React.FC<GuideItemListProps> = ({
  callGuide,
  readOnly,
  isEditing,
  setGuide,
  setHaveChangesBeenMade,
  idsWithErrors,
}) => {
  const currentUser = useCurrentUser();
  const [guideItems, setGuideItems] = useState<GuideItem[]>(
    stripTypenameFromCues(callGuide.cues)
  );

  const [activeItemID, setActiveItemID] = useState<string>();

  const updateGuideItems = (newItems: GuideItem[]): void => {
    setGuideItems(newItems);
    setHaveChangesBeenMade(true);
    setGuide({
      ...callGuide,
      cues: newItems,
    });
  };

  useEffect(() => {
    if (!isEditing) {
      setActiveItemID("");
      setGuideItems(stripTypenameFromCues(callGuide.cues));
    } else if (!guideItems.length) {
      addItemOfType(GuideItemType.Competency);
    }
  }, [callGuide, isEditing]);

  const addItemOfType = (type: GuideItemType): void => {
    // We use a fake UUID here just for React's rendering
    const newItem: GuideItem = {
      id: uuidv4(),
      type,
      cue: "",
      description: "",
      position: guideItems.length,
      scoringEnabled: false,
      competencyId: null,
      childItems: [],
    };
    if (type === GuideItemType.Competency) {
      newItem.scoringEnabled =
        currentUser.organization.scoringEnabled &&
        currentUser.organization.guideCompetencyScoringEnabledByDefault;
    }
    updateGuideItems([...guideItems, newItem]);
    setActiveItemID(newItem.id);
  };

  const addQuestionToCompetency = (competencyId: string): void => {
    const competencyItem = guideItems.find((item) => item.id === competencyId);
    if (competencyItem) {
      const childItems = [...competencyItem.childItems] || [];
      const newQuestion = {
        id: uuidv4(),
        description: "",
        position: childItems.length,
      };
      childItems.push(newQuestion);
      updateParentItemWithId(competencyId, { childItems });
    }
  };

  const updateParentItemWithId = (
    id: string,
    update: GuideItemUpdate
  ): void => {
    const itemIdx = guideItems.findIndex((item) => item.id === id);
    if (itemIdx !== -1) {
      guideItems[itemIdx] = { ...guideItems[itemIdx], ...update };
      updateGuideItems([...guideItems]);
    }
  };

  const updateChildItemWithId = (
    parentId: string,
    childId: string,
    update: GuideItemUpdate
  ): void => {
    const parent = guideItems.find((item) => item.id === parentId);
    if (parent?.childItems?.length) {
      const childIdx = parent.childItems?.findIndex(
        (item) => item.id === childId
      );
      if (childIdx !== -1) {
        parent.childItems[childIdx] = {
          ...parent.childItems[childIdx],
          ...update,
        } as CallGuideItemChild;
        updateGuideItems([...guideItems]);
      }
    }
  };

  const deleteParentItemWithId = (id: string): void => {
    if (guideItems.length > 1) {
      updateGuideItems(guideItems.filter((item) => item.id !== id));
    }
  };

  const deleteChildItemWithId = (parentId: string, childId: string): void => {
    const parent = guideItems.find((item) => item.id === parentId);
    if (parent) {
      parent.childItems = parent.childItems?.filter(
        (item) => item.id !== childId
      );
      updateGuideItems([...guideItems]);
    }
  };

  const handleMove = useCallback(
    (fromIndex: number, toIdx: number): void => {
      if (!callGuide) return;
      const item = guideItems[fromIndex];
      if (!item) return;
      if (toIdx >= guideItems.length) return;

      let toIndex = toIdx;
      const movingDown = toIndex >= fromIndex;
      if (movingDown) toIndex += 1;
      // sendGAEvent("cue", "call_guides", "move");

      const newList = [] as Array<GuideItem>;
      for (let i = 0; i < guideItems.length; i += 1) {
        // eslint-disable-next-line
        if (i === fromIndex) continue;
        if (i === toIndex) {
          newList.push(guideItems[fromIndex]);
        }
        newList.push(guideItems[i]);
      }
      if (toIndex === guideItems.length) {
        newList.push(guideItems[fromIndex]);
      }
      updateGuideItems(newList);
    },
    [callGuide, guideItems]
  );

  const itemProps = (item: GuideItem): CommonGuideItemProps => ({
    itemId: item.id,
    text: item.description,
    isEditing: isEditing && activeItemID === item.id,
    canDelete: guideItems.length > 1,
    onDelete: () => deleteParentItemWithId(item.id),
    onEnter: () => setActiveItemID(""),
    onUpdate: (itemId, updates) => updateParentItemWithId(itemId, updates),
  });

  return (
    <>
      <DragDropContext
        onDragEnd={(result) => {
          if (!result.destination) {
            return;
          }
          handleMove(result.source.index, result.destination.index);
        }}
      >
        <Droppable droppableId="droppable">
          {(provided) => (
            // eslint-disable-next-line @typescript-eslint/unbound-method
            <Box
              width="80%"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {guideItems.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                  isDragDisabled={readOnly}
                >
                  {(provided) => (
                    <Flex
                      data-testid={`guide-item-${index}`}
                      alignItems="flex-start"
                      borderRadius="12px"
                      mt={5}
                      pr={4}
                      pl={readOnly || !isEditing ? 5 : undefined}
                      backgroundColor="white"
                      // eslint-disable-next-line @typescript-eslint/unbound-method
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...(activeItemID === item.id
                        ? {
                            border: "1px solid",
                            borderColor:
                              idsWithErrors.includes(item.id) &&
                              !item.description
                                ? "red"
                                : "blue.600",
                            boxShadow:
                              idsWithErrors.includes(item.id) &&
                              !item.description
                                ? "0px 0px 0px 4px #F28F8E;"
                                : "0px 0px 0px 4px #4FAFFD;",
                          }
                        : {
                            border: "1px solid",
                            borderColor:
                              idsWithErrors.includes(item.id) &&
                              !item.description
                                ? "red"
                                : "gray.200",
                            boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                          })}
                    >
                      <Flex
                        {...provided.dragHandleProps}
                        height={8}
                        width={8}
                        mr={1}
                        ml={2}
                        mt={4}
                        alignItems="center"
                        justifyContent="center"
                        data-testid={`drag-handle-${index}`}
                        hidden={readOnly || !isEditing}
                      >
                        <DragHandleDots width={5} height={5} />
                      </Flex>
                      <Box
                        py={5}
                        flex={1}
                        onClick={() => {
                          if (isEditing) {
                            setActiveItemID(item.id);
                          }
                        }}
                      >
                        {item.type === GuideItemType.Competency && (
                          <GuideItemCompetency
                            {...itemProps(item)}
                            childItems={item.childItems}
                            callGuideId={callGuide.id}
                            competencyId={item.competencyId}
                            itemScoringEnabled={!!item.scoringEnabled}
                            orgScoringEnabled={
                              currentUser.organization.scoringEnabled
                            }
                            onAddQuestion={() =>
                              addQuestionToCompetency(item.id)
                            }
                            onDeleteChild={deleteChildItemWithId}
                            onUpdateChild={updateChildItemWithId}
                            idsWithErrors={idsWithErrors}
                          />
                        )}
                        {item.type === GuideItemType.Question && (
                          <GuideItemQuestion {...itemProps(item)} />
                        )}
                        {item.type === GuideItemType.Text && (
                          <GuideItemText {...itemProps(item)} />
                        )}
                      </Box>
                    </Flex>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      {/* Todo: remove this manual width */}
      <Box width="80%" mt={4} hidden={readOnly || !isEditing}>
        <HStack spacing="4">
          <Button
            data-testid="Add competency"
            size="sm"
            justifyContent="flex-start"
            variant="ghost"
            fontSize="sm"
            fontWeight="500"
            leftIcon={<HiOutlinePlus />}
            onClick={() => addItemOfType(GuideItemType.Competency)}
          >
            Add competency
          </Button>
          <Button
            data-testid="add-question"
            size="sm"
            justifyContent="flex-start"
            variant="ghost"
            fontSize="sm"
            fontWeight="500"
            leftIcon={<HiOutlinePlus />}
            onClick={() => addItemOfType(GuideItemType.Question)}
          >
            Add question
          </Button>
          <Button
            data-testid="Add text"
            size="sm"
            fontSize="sm"
            fontWeight="500"
            justifyContent="flex-start"
            variant="ghost"
            leftIcon={<HiOutlinePlus />}
            onClick={() => addItemOfType(GuideItemType.Text)}
          >
            Add text
          </Button>
        </HStack>
      </Box>
    </>
  );
};

export default GuideItemList;
