import { MenuItem as ChakraMenuItem, MenuItemProps } from "@chakra-ui/react";
import React from "react";

import colorVars from "../../../theme/css-color-variables";

export const TrainingMenuItem: React.FC<MenuItemProps> = ({
  children,
  ...rest
}) => {
  return (
    <ChakraMenuItem
      iconSpacing="2"
      color="gray.900"
      fontWeight="500"
      _hover={{
        bg: "gray.100",
      }}
      {...rest}
    >
      {children}
    </ChakraMenuItem>
  );
};

export const menuIconProps = {
  color: colorVars.gray[900],
  size: "20px",
};
