import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const BhLinkedIn = (props: IconProps): JSX.Element => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        d="M21.1623 21.1667L21.1668 21.1659V14.4422C21.1668 11.1529 20.4587 8.61903 16.6133 8.61903C14.7647 8.61903 13.5241 9.63347 13.0177 10.5952H12.9642V8.92611H9.31814V21.1659H13.1147V15.1052C13.1147 13.5095 13.4172 11.9664 15.3934 11.9664C17.3405 11.9664 17.3695 13.7875 17.3695 15.2076V21.1667H21.1623Z"
        fill="currentColor"
      />
      <path
        d="M3.13599 8.92688H6.9371V21.1667H3.13599V8.92688Z"
        fill="currentColor"
      />
      <path
        d="M5.03502 2.83334C3.81968 2.83334 2.8335 3.81952 2.8335 5.03486C2.8335 6.25021 3.81968 7.25702 5.03502 7.25702C6.25037 7.25702 7.23655 6.25021 7.23655 5.03486C7.23579 3.81952 6.24961 2.83334 5.03502 2.83334Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
);

export default BhLinkedIn;
