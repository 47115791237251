import { Box, Tooltip } from "@chakra-ui/react";
import { Range, RangeProps, SliderProps } from "rc-slider";
import React, { useMemo } from "react";

import colorVars from "../../../../theme/css-color-variables";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { secondsToHHMMSS } from "../../../../utils/media";
import { ClipPlayRange } from "./types";

type ClipSliderProps = {
  videoDuration?: number;
  playRange: ClipPlayRange;
  onDragStartStop: React.Dispatch<React.SetStateAction<boolean>>;
  onChange: (values: number[]) => void;
};

const ClipSlider: React.FC<ClipSliderProps> = ({
  playRange,
  videoDuration,
  onDragStartStop,
  onChange,
}) => {
  const sendGAEvent = useSendGAEvent();
  if (videoDuration === undefined) {
    return <Box h="10px" marginTop="-4px" marginBottom="16px" />;
  }
  return (
    <Range
      style={{
        zIndex: 1,
        height: "4px",
      }}
      allowCross
      max={Math.floor(videoDuration)}
      value={[playRange.start, playRange.end]}
      trackStyle={trackStyle}
      railStyle={{
        height: "4px",
        backgroundColor: colorVars.gray[100],
        borderRadius: "base",
      }}
      pushable
      handle={(props) => (
        <Handle key={`handle-${props.index}`} {...props} ref={undefined} />
      )}
      handleStyle={handleStyle}
      onBeforeChange={() => onDragStartStop(true)}
      onAfterChange={() => {
        onDragStartStop(false);
        sendGAEvent("clip_slider_dragged", "call_review");
      }}
      onChange={onChange}
    />
  );
};

type HandleProps = Parameters<NonNullable<SliderProps["handle"]>>[0];

const Handle: React.FC<HandleProps> = (props) => {
  const max = props.max || 1;
  const offsetPercent = (props.offset || 0) / 100.0;
  const seconds = Math.round(offsetPercent * max);
  const formattedSeconds = useMemo(() => secondsToHHMMSS(seconds), [seconds]);
  return (
    <Tooltip label={formattedSeconds} isOpen={props.dragging}>
      <Box
        _hover={{
          bg: "yellow.200",
        }}
        style={{
          ...props.style,
          // -6px shifts handle to line up at its center
          left: `calc(${(props.value / max) * 100}% - 6px)`,
        }}
        className={props.className}
      />
    </Tooltip>
  );
};

const trackStyle: RangeProps["trackStyle"] = [
  {
    background: colorVars.yellow[300],
    height: "4px",
    borderRadius: "0px",
  },
];

const handleStyle: RangeProps["handleStyle"] = [
  {
    backgroundColor: colorVars.yellow[400],
    boxSizing: "border-box",
    width: "8px",
    height: "8px",
    border: "1px solid white",
    borderRadius: "8px",
    zIndex: 2,
    marginTop: "-2px",
    marginLeft: "-2px",
  },
  {
    backgroundColor: colorVars.yellow[400],
    boxSizing: "border-box",
    width: "8px",
    height: "8px",
    border: "1px solid white",
    borderRadius: "8px",
    zIndex: 3,
    marginTop: "-2px",
    marginLeft: "6px",
  },
];

export default ClipSlider;
