import { Box, Flex, FlexProps, Text, useTheme } from "@chakra-ui/react";
import React from "react";

import { RouterLinkBox, RouterLinkBoxProps } from "../../../components";

type SupportItemProps = Partial<RouterLinkBoxProps> & {
  icon: React.ReactNode;
  heading: React.ReactNode;
  subHeading: React.ReactNode;
  onClick?(): void;
};

const SupportItem: React.FC<SupportItemProps> = ({
  to,
  target,
  isExternal,
  icon,
  heading,
  subHeading,
  onClick,
}) => {
  const { colors } = useTheme();

  const linkProps: FlexProps & Partial<RouterLinkBoxProps> = to
    ? { as: RouterLinkBox, to, target, isExternal }
    : {};

  return (
    <Flex
      {...linkProps}
      display="flex"
      flexDirection="column"
      borderRadius="2xl"
      overflow="hidden"
      color="gray.800"
      outline={`1px solid ${colors.blue[200]}`}
      _hover={{ outline: `4px solid ${colors.blue[500]}`, color: "blue.600" }}
      cursor="pointer"
      boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)"
      onClick={onClick}
    >
      <Flex
        p="6"
        bg="linear-gradient(0deg, #E9F5FF 0%, #E9F5FF 100%), linear-gradient(180deg, rgba(113, 165, 214, 0.60) 100.29%, rgba(173, 223, 244, 0.60) 200.29%)"
        justifyContent="center"
      >
        {icon}
      </Flex>
      <Box bg="white" p="6" textAlign="center" flex="1">
        <Text
          fontSize="2xl"
          fontWeight="semibold"
          lineHeight="8"
          mb="2"
          color="inherit"
        >
          {heading}
        </Text>
        <Text fontSize="md" lineHeight="5" color="gray.600">
          {subHeading}
        </Text>
      </Box>
    </Flex>
  );
};

export default SupportItem;
