import { SearchOptions } from "@algolia/client-search";
import { RequestOptions } from "@algolia/transporter";
import { useEffect, useState } from "react";

import { groupHits } from "../components/OrganizationMetrics/groupHits";
import { KeywordHit } from "../components/OrganizationMetrics/types";
import config from "../config";
import { useAlgolia } from "./useAlgolia";

const useAlgoliaAllCalls = (
  appId?: string,
  searchKey?: string,
  indexName?: string,
  searchOptions?: SearchOptions
): {
  groupedHits: KeywordHit[];
  loadingCalls: boolean;
  requestSearch(request: RequestOptions & SearchOptions): void;
} => {
  // Initialize
  // Initialize useAlgolia
  // TODO: Add Hit type interface to searchState
  const [searchState, requestDispatch, getMore, setAlgoliaConfig] = useAlgolia(
    "",
    "",
    "",
    {
      ...searchOptions,
    }
  );
  const { hits, loading, hasMore } = searchState;

  const [isAlgoliaConfigInit, setIsAlgoliaConfigInit] =
    useState<boolean>(false);
  const [loadingCalls, setLoadingCalls] = useState<boolean>(false);
  const [groupedHits, setGroupedHits] = useState<KeywordHit[]>([]);
  const requestSearch = (
    request: RequestOptions & SearchOptions = {}
  ): void => {
    setLoadingCalls(true);
    // Search request
    requestDispatch(request);
    // Prevents first search occuring before it has a query value
    if (!isAlgoliaConfigInit) {
      setAlgoliaConfig({
        appId: appId || config.algoliaApplicationId,
        searchKey,
        indexName: indexName || config.algoliaIndex,
      });
      setIsAlgoliaConfigInit(true);
    }
  };
  useEffect(() => {
    if (hasMore) {
      getMore();
    }
  }, [hits]);
  useEffect(() => {
    if (!loading && !hasMore && isAlgoliaConfigInit) {
      setGroupedHits(groupHits(hits));
      setLoadingCalls(false);
    }
  }, [loading, hasMore]);

  return { groupedHits, loadingCalls, requestSearch };
};

export { useAlgoliaAllCalls };
