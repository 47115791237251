import { Box, Flex, Icon, Text, Textarea } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { HiStar } from "react-icons/hi2";
import ResizeTextarea from "react-textarea-autosize";

import { RefreshIcon } from "../../../../../components/Icons/Refresh";
import {
  formatRelativeDate,
  getMostRecentDateString,
} from "../../../../../utils/datetime";
import { useSendGAEvent } from "../../../../../utils/googleAnalytics";
import {
  CallBetaFragment,
  Candidate,
  Scorecard,
  ScorecardItem,
  useUpdateScorecardMutation,
} from "../../../../graphql";
import OverallStarRating from "../../../OverallStarRating";
import ScorecardCompetencyListItem from "../../../Scorecard/ScorecardCompetencyListItem";
import CopyScoresButton from "./CopyScoresButton";
import ViewCandidateScoresButton from "./ViewCandidateScoresButton";

type CurrentUserScorecard = Pick<
  Scorecard,
  "id" | "overallScoringEnabled" | "overallNotes" | "overallScore" | "updatedAt"
> & {
  competencies:
    | Pick<
        ScorecardItem,
        "id" | "itemText" | "score" | "scoringEnabled" | "notes" | "updatedAt"
      >[];
};

type CurrentUserScoresProps = {
  scorecard: CurrentUserScorecard;
  showViewCandidate?: boolean;
  call: Pick<CallBetaFragment, "id" | "candidate" | "position" | "startTime">;
  candidate?: Pick<Candidate, "id">;
};

const CurrentUserScores: React.FC<CurrentUserScoresProps> = ({
  scorecard,
  showViewCandidate,
  call,
  candidate,
}) => {
  const sendGAEvent = useSendGAEvent();
  useEffect(() => {
    sendGAEvent("review_scores", "scoring", "Self Review");
  }, []);

  const { competencies } = scorecard;
  const [updateScorecard, { loading: updating }] = useUpdateScorecardMutation();

  const lastUpdatedAt = getMostRecentDateString(
    [scorecard.updatedAt].concat(competencies.map((c) => c.updatedAt))
  );

  const saveScorecardUpdates = ({
    overallScore = scorecard.overallScore ?? 0,
    overallNotes = scorecard.overallNotes ?? undefined,
  }: {
    overallScore?: number;
    overallNotes?: string;
  }): void => {
    updateScorecard({
      variables: {
        scorecardId: scorecard.id,
        overallScore,
        overallNotes,
      },
      optimisticResponse: {
        updateScorecard: {
          __typename: "UpdateScorecard",
          scorecard: {
            __typename: "Scorecard",
            id: scorecard.id,
            overallScore,
            overallNotes,
            updatedAt: new Date().toISOString(),
          },
        },
      },
    });
  };

  return (
    <Flex direction="column">
      <Flex alignItems="center" mt="1" gap="2">
        <Flex color="gray.500" flex="1">
          {updating && (
            <Flex mr="1.5">
              <Icon as={RefreshIcon} boxSize="6" />
            </Flex>
          )}
          <Text fontWeight="400" fontSize="sm">
            {lastUpdatedAt &&
              !updating &&
              `Last saved ${formatRelativeDate(lastUpdatedAt)}`}
            {updating && `Autosaving`}
          </Text>
        </Flex>
        {showViewCandidate && candidate && (
          <ViewCandidateScoresButton candidateId={candidate.id} />
        )}
        <CopyScoresButton call={call} scorecard={scorecard} />
      </Flex>
      {scorecard.overallScoringEnabled && (
        <Flex mt="2">
          <Box
            px="4"
            py="3"
            bg="blueAlpha.50"
            alignItems="center"
            borderRadius="8"
            width="100%"
            border="1px solid"
            borderColor="blueAlpha.300"
          >
            <Flex
              color="gray.700"
              fontWeight="600"
              fontSize="sm"
              alignItems="center"
            >
              <Flex flex="1" mr="2" alignItems="center" gap="2">
                <Icon
                  as={HiStar}
                  color="blue.600"
                  boxSize="7"
                  p="1.5"
                  bg="blueAlpha.300"
                  borderRadius="full"
                />
                Interview score
              </Flex>
              <OverallStarRating
                scorecard={scorecard}
                onChange={(overallScore) =>
                  saveScorecardUpdates({ overallScore })
                }
              />
            </Flex>
            <Flex direction="column" width="100%" alignItems="center">
              {(scorecard.overallScore || 0) > 0 && (
                <Flex width="100%" mt="2">
                  <Textarea
                    borderRadius="6"
                    px="3"
                    fontSize="sm"
                    defaultValue={scorecard.overallNotes || ""}
                    placeholder="Explain your score"
                    as={ResizeTextarea}
                    maxH="112px"
                    onBlur={(e) =>
                      saveScorecardUpdates({ overallNotes: e.target.value })
                    }
                  />
                </Flex>
              )}
            </Flex>
          </Box>
        </Flex>
      )}
      <Flex
        direction="column"
        width="100%"
        mt="3"
        justifyContent="space-between"
        gap="3"
      >
        {competencies.map(
          (competency) =>
            competency.scoringEnabled && (
              <ScorecardCompetencyListItem
                key={competency.id}
                competency={competency}
              />
            )
        )}
      </Flex>
    </Flex>
  );
};

export default CurrentUserScores;
