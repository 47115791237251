import React from "react";

import { CurrentUserFragment, usePlanCustomTemplateQuery } from "../../graphql";
import PlanSettingsForm from "./PlanSettingsForm";
import SettingsPageContainer from "./shared/SettingsPageContainer";

interface PlanSettingsProps {
  currentUser: CurrentUserFragment;
}

const PlanSettings: React.FC<PlanSettingsProps> = ({ currentUser }) => {
  const { data, loading } = usePlanCustomTemplateQuery();
  if (loading || !data?.planCustomTemplate) return null;
  const { id: templateId, sections } = data?.planCustomTemplate || {};
  return (
    <SettingsPageContainer heading="Job descriptions" maxW="776px">
      <PlanSettingsForm
        currentUser={currentUser}
        templateId={templateId}
        sections={sections}
      />
    </SettingsPageContainer>
  );
};

export default PlanSettings;
