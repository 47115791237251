import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useState,
} from "react";

/**
 * Browser API support. Picture in Picture API is not yet supported in TypeScript.
 * First Public Working Draft: https://www.w3.org/TR/picture-in-picture/
 */
/**
 * Reference:
 * https://www.w3.org/TR/picture-in-picture/#interface-picture-in-picture-window
 */
interface PictureInPictureWindow extends EventTarget {
  readonly width: number;
  readonly height: number;
  onresize: ((this: HTMLVideoElement, ev: Event) => any) | null;
}
/**
 * Reference:
 * https://www.w3.org/TR/picture-in-picture/#htmlvideoelement-extensions
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export interface ExtendedHTMLVideoElement extends HTMLVideoElement {
  autoPictureInPicture: boolean;
  disablePictureInPicture: boolean;
  requestPictureInPicture(): Promise<PictureInPictureWindow>;
  onenterpictureinpicture: ((this: HTMLVideoElement, ev: Event) => any) | null;
  onleavepictureinpicture: ((this: HTMLVideoElement, ev: Event) => any) | null;
  addEventListener<K extends keyof HTMLVideoElementEventMap>(
    type: K,
    listener: (this: HTMLVideoElement, ev: HTMLVideoElementEventMap[K]) => any,
    options?: boolean | AddEventListenerOptions
  ): void;
  addEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions
  ): void;
  removeEventListener<K extends keyof HTMLVideoElementEventMap>(
    type: K,
    listener: (this: HTMLVideoElement, ev: HTMLVideoElementEventMap[K]) => any,
    options?: boolean | EventListenerOptions
  ): void;
  removeEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | EventListenerOptions
  ): void;
}
interface HTMLVideoElementEventMap extends HTMLElementEventMap {
  enterpictureinpicture: EnterPictureInPictureEvent;
  leavepictureinpicture: Event;
}
/**
 * Reference:
 * https://www.w3.org/TR/picture-in-picture/#document-extensions
 */
interface ExtendedDocument extends Document, ExtendedDocumentOrShadowRoot {
  readonly pictureInPictureEnabled: boolean;
  exitPictureInPicture(): Promise<void>;
}
/**
 * Reference:
 * https://www.w3.org/TR/picture-in-picture/#documentorshadowroot-extension
 */
interface ExtendedDocumentOrShadowRoot extends DocumentOrShadowRoot {
  readonly pictureInPictureElement: Element | null;
}
interface EnterPictureInPictureEvent extends Event {
  readonly pictureInPictureWindow: PictureInPictureWindow;
}
/**
 * react-use-pip API
 */
declare type VideoRefType = MutableRefObject<ExtendedHTMLVideoElement | null>;
interface usePictureInPictureOptions {
  onEnterPictureInPicture?: ExtendedHTMLVideoElement["onenterpictureinpicture"];
  onLeavePictureInPicture?: ExtendedHTMLVideoElement["onleavepictureinpicture"];
  onRequestPictureInPictureError?: (error: any) => void;
  onExitPictureInPictureError?: (error: any) => void;
}
interface usePictureInPictureReturnType {
  isPictureInPictureActive: boolean;
  isPictureInPictureAvailable: boolean;
  togglePictureInPicture: Dispatch<SetStateAction<boolean>>;
}

function isWebkitPictureInPictureSupported(
  video: ExtendedHTMLVideoElement
): boolean {
  return (
    (video as any).webkitSupportsPresentationMode &&
    typeof (video as any).webkitSetPresentationMode === "function"
  );
}
function isPictureInPictureSupported(): boolean {
  return (document as ExtendedDocument).pictureInPictureEnabled;
}

function isPictureInPictureDisabled(video: ExtendedHTMLVideoElement): boolean {
  return video.disablePictureInPicture;
}

export default function usePictureInPicture(
  videoRef: VideoRefType,
  options?: usePictureInPictureOptions
): usePictureInPictureReturnType {
  const {
    onEnterPictureInPicture,
    onLeavePictureInPicture,
    onRequestPictureInPictureError,
    onExitPictureInPictureError,
  } = options || {};

  const [isPictureInPictureActive, togglePictureInPicture] =
    useState<boolean>(false);

  const [isPictureInPictureAvailable, setIsPictureInPictureAvailable] =
    useState<boolean>(false);

  useEffect(() => {
    handlePictureInPicture(
      videoRef,
      isPictureInPictureActive,
      onRequestPictureInPictureError,
      onExitPictureInPictureError
    );
  }, [
    videoRef,
    isPictureInPictureActive,
    onRequestPictureInPictureError,
    onExitPictureInPictureError,
  ]);

  useEffect(() => {
    if (videoRef.current === null) {
      return;
    }
    setIsPictureInPictureAvailable(
      (isWebkitPictureInPictureSupported(videoRef.current) ||
        isPictureInPictureSupported()) &&
        !isPictureInPictureDisabled(videoRef.current)
    );

    if (
      onEnterPictureInPicture &&
      typeof onEnterPictureInPicture === "function"
    ) {
      videoRef.current.addEventListener(
        "enterpictureinpicture",
        onEnterPictureInPicture
      );
    }
    if (
      onLeavePictureInPicture &&
      typeof onLeavePictureInPicture === "function"
    ) {
      videoRef.current.addEventListener(
        "leavepictureinpicture",
        onLeavePictureInPicture
      );
    }

    return () => {
      if (videoRef.current === null) {
        return;
      }
      if (
        onEnterPictureInPicture &&
        typeof onEnterPictureInPicture === "function"
      ) {
        videoRef.current.removeEventListener(
          "enterpictureinpicture",
          onEnterPictureInPicture
        );
      }
      if (
        onLeavePictureInPicture &&
        typeof onLeavePictureInPicture === "function"
      ) {
        videoRef.current.removeEventListener(
          "leavepictureinpicture",
          onLeavePictureInPicture
        );
      }
    };
  }, [videoRef.current]);

  return {
    isPictureInPictureActive,
    isPictureInPictureAvailable,
    togglePictureInPicture,
  };
}

async function handlePictureInPicture(
  video: VideoRefType,
  isActive: boolean,
  onRequestPictureInPictureError: usePictureInPictureOptions["onRequestPictureInPictureError"],
  onExitPictureInPictureError: usePictureInPictureOptions["onExitPictureInPictureError"]
): Promise<void> {
  if (video.current === null) {
    return;
  }
  if (isActive) {
    try {
      if (isWebkitPictureInPictureSupported(video.current)) {
        // Safari^9.0 support
        (video.current as any).webkitSetPresentationMode("picture-in-picture");
      } else {
        await video.current.requestPictureInPicture();
      }
    } catch (error) {
      if (
        onRequestPictureInPictureError &&
        typeof onRequestPictureInPictureError === "function"
      ) {
        onRequestPictureInPictureError(error);
      }
    }
  }
  if (!isActive && (document as ExtendedDocument).pictureInPictureElement) {
    try {
      if (isWebkitPictureInPictureSupported(video.current)) {
        // Safari^9.0 support
        (video.current as any).webkitSetPresentationMode("inline");
      } else {
        await (document as ExtendedDocument).exitPictureInPicture();
      }
    } catch (error) {
      if (
        onExitPictureInPictureError &&
        typeof onExitPictureInPictureError === "function"
      ) {
        onExitPictureInPictureError(error);
      }
    }
  }
}
