import { createContext, useContext } from "react";

export type AppEnvironment = "app" | "extension";

export const AppEnvironmentContext = createContext<AppEnvironment>("app");

/**
 * Return what context the app is running in. Primarily used to determine
 * if rendering for the browser extension, but this could be extended as
 * needed for other use cases
 */
const useAppEnvironmentContext = (): AppEnvironment => {
  return useContext(AppEnvironmentContext);
};

/**
 * Returns `true` if rendering in the context of the browser extension
 */
export const useIsExtension = (): boolean =>
  useAppEnvironmentContext() === "extension";

export default useAppEnvironmentContext;
