import { Box, Flex, Grid, Icon, StyleProps, Text } from "@chakra-ui/react";
import React from "react";

import ExternalRecordingTopic from "./ExternalRecordingTopic";
import { Topic } from "./types";

export type ExternalRecordingTopicsTabProps = {
  topics: Topic[];
  seek: (time: number) => void;
};

const ExternalRecordingTopicsTab: React.FC<ExternalRecordingTopicsTabProps> = ({
  topics,
  seek,
}) => {
  return (
    <Box position="relative" px="3" overflowY="auto">
      <Flex bg="green.50" p="5" pr="7" mt="2" mb="4" alignItems="center">
        <SearchIconWithBackgroundFill mr="4" />

        <Text as="span" color="gray.800" fontSize="sm">
          Looking for a topic you don’t see below? Check out the{" "}
          <Text as="span" fontWeight="medium">
            “Points of Interest”
          </Text>{" "}
          tab to scan highlights captured by BrightHire’s AI engine
        </Text>
      </Flex>

      <Grid templateColumns="min-content 1fr" rowGap="2" mb="4">
        {topics.map((topic) => (
          <ExternalRecordingTopic topic={topic} key={topic.id} seek={seek} />
        ))}
      </Grid>
    </Box>
  );
};

export default ExternalRecordingTopicsTab;

function SearchIconWithBackgroundFill({
  fill = "green.600",
  ...props
}: StyleProps = {}): JSX.Element {
  return (
    <Icon width="30px" height="30px" viewBox="0 0 30 30" fill={fill} {...props}>
      <path d="M15 3.75C8.79668 3.75 3.75 8.79668 3.75 15C3.75 21.2033 8.79668 26.25 15 26.25C21.2033 26.25 26.25 21.2033 26.25 15C26.25 8.79668 21.2033 3.75 15 3.75ZM19.6875 21.0135L16.5146 17.8406C15.4542 18.5703 14.1593 18.8774 12.8841 18.7016C11.6089 18.5258 10.4454 17.8797 9.62203 16.8903C8.79862 15.9008 8.3747 14.6393 8.43348 13.3534C8.49225 12.0675 9.02946 10.8499 9.9397 9.9397C10.8499 9.02946 12.0675 8.49225 13.3534 8.43348C14.6393 8.3747 15.9008 8.79862 16.8903 9.62203C17.8797 10.4454 18.5258 11.6089 18.7016 12.8841C18.8774 14.1593 18.5703 15.4542 17.8406 16.5146L21.0135 19.6875L19.6875 21.0135Z" />
      <path d="M13.5938 16.875C15.4059 16.875 16.875 15.4059 16.875 13.5938C16.875 11.7816 15.4059 10.3125 13.5938 10.3125C11.7816 10.3125 10.3125 11.7816 10.3125 13.5938C10.3125 15.4059 11.7816 16.875 13.5938 16.875Z" />
    </Icon>
  );
}
