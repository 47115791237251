import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

interface DrawerOverlayProps extends BoxProps {
  onClick: () => void;
}

const DrawerOverlay = React.forwardRef<HTMLDivElement, DrawerOverlayProps>(
  ({ onClick, ...props }, ref) => {
    return (
      <Box
        pos="fixed"
        bg="rgba(0,0,0,0.4)"
        left="0"
        top="12"
        right="0"
        bottom="0"
        ref={ref}
        zIndex={"overlay" as any}
        onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          if (onClick) {
            onClick();
          }
          event.stopPropagation();
        }}
        {...props}
      />
    );
  }
);

DrawerOverlay.displayName = "DrawerOverlay";

export default DrawerOverlay;
