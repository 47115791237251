import {
  Button,
  Flex,
  HStack,
  IconButton,
  IconButtonProps,
  Link,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";

import { successToast, useToast } from "../../../../components";
import PopoverBeta from "../../../../components/Popover/Popover";
import { copy } from "../../../../utils/clipboard";
import useInterviewAssistant from "../../../../utils/popup";
import { CandidateLink, CandidatePhoneNumber } from "../../../graphql";

export type LinkInputType = CandidatePhoneNumber | CandidateLink | string;
export type LinkType = "email" | "phone_number" | "linked_in";

type CandidateHeaderLinksProps = {
  label: string;
  icon: IconButtonProps["icon"];
  links: LinkInputType[];
  linkType: LinkType;
  dataTestId?: string;
};

export const CandidatePhoneNumberListItem: React.FC<{
  link: CandidatePhoneNumber;
}> = ({ link }) => {
  const { openInterviewAssistant } = useInterviewAssistant();
  return (
    <Flex alignItems="center" px="4" py="3">
      <Text color="gray.600" fontWeight="400" fontSize="sm" mr="4">
        {link.type}
      </Text>
      <Text color="gray.800" fontWeight="400" fontSize="sm" mr="6">
        {link.phoneNumber}
      </Text>
      <Button
        size="xs"
        variant="outline"
        flexShrink="0"
        onClick={() =>
          openInterviewAssistant({
            phoneNumber: link.phoneNumber,
            candidateFirstName: link.candidate?.firstName,
            candidateLastName: link.candidate?.lastName,
          })
        }
      >
        Call with BrightHire
      </Button>
    </Flex>
  );
};

export const CandidateEmailListItem: React.FC<{
  link: string;
}> = ({ link }) => {
  const toast = useToast();
  const copyEmail = (): void => {
    copy(link);
    successToast(toast, "Email address copied to clipboard");
  };
  return (
    <HStack spacing="6" alignItems="center" px="4" py="3">
      <Text color="gray.800" fontWeight="400" fontSize="sm" minW="0">
        {link}
      </Text>
      <Button size="xs" variant="outline" onClick={copyEmail} flexShrink="0">
        Copy
      </Button>
      <Link isExternal variant="outlineButton" href={`mailto:${link}`}>
        Email
      </Link>
    </HStack>
  );
};

export const CandidateLinkedInListItem: React.FC<{
  link: CandidateLink;
}> = ({ link }) => (
  <Flex alignItems="center" px="4" py="3" justifyContent="space-between">
    <Text color="gray.800" fontWeight="400" fontSize="sm" pr="4">
      From {link.source}
    </Text>
    <Link isExternal variant="outlineButton" href={link.link}>
      View
    </Link>
  </Flex>
);

const CandidateHeaderLinks: React.FC<CandidateHeaderLinksProps> = ({
  label,
  icon,
  links,
  linkType,
  dataTestId,
}) => {
  let ListItemComponent: any = null;
  switch (linkType) {
    case "email":
      ListItemComponent = CandidateEmailListItem;
      break;
    case "linked_in":
      ListItemComponent = CandidateLinkedInListItem;
      break;
    case "phone_number":
      ListItemComponent = CandidatePhoneNumberListItem;
      break;
  }
  if (!ListItemComponent) {
    return null;
  }
  return (
    <Tooltip label={label}>
      <PopoverBeta
        button={
          <IconButton
            variant="icon"
            aria-label={label}
            icon={icon}
            data-testid={dataTestId}
          />
        }
      >
        {links.map((link, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <ListItemComponent key={idx} link={link} />
        ))}
      </PopoverBeta>
    </Tooltip>
  );
};

export default CandidateHeaderLinks;
