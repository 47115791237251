import * as React from "react";

import Parse from "./Parse";

export type LinkifyProps = {
  children: React.ReactNode;
  className?: string;
  email?: boolean;
  noopener?: boolean;
  noreferrer?: boolean;
  target?: string;
};

// heavily inspired by https://github.com/SlashArash/react-linky/
const Linkify: React.FC<LinkifyProps> = ({
  children,
  className,
  noopener = true,
  noreferrer = true,
  target = "_blank",
}) => {
  if (typeof children === "string") {
    return (
      <Parse
        className={className}
        noopener={noopener}
        noreferrer={noreferrer}
        target={target}
        text={children}
      />
    );
  }
  if (
    React.isValidElement(children) &&
    children.type !== "a" &&
    children.type !== "button"
  ) {
    return React.cloneElement(
      children,
      undefined,
      <Linkify
        className={className}
        noopener={noopener}
        noreferrer={noreferrer}
        target={target}
      >
        {children.props.children}
      </Linkify>
    );
  }
  if (Array.isArray(children)) {
    return (
      <>
        {children.map((child, index) => (
          <Linkify
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={className}
            noopener={noopener}
            noreferrer={noreferrer}
            target={target}
          >
            {child}
          </Linkify>
        ))}
      </>
    );
  }
  return <>{children}</>;
};

export default Linkify;
