import { Flex, Heading, SystemProps, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";

import { LoadingIndicator } from "../../../../components";
import { formatRatio } from "../../../../utils/number";
import { Maybe, MetricName } from "../../../graphql";
import QuickStatsItem from "./QuickStatsItem";

interface QuickStatsBaseProps extends SystemProps {
  numInterviews: Maybe<number>;
  talkRatio: Maybe<number>;
  talkRatioBenchmark: Maybe<[number, number]>;
  questionsAsked: Maybe<number>;
  questionsAskedBenchmark: Maybe<[number, number]>;
  loading?: boolean;
}

/**
 * UI for quick stats with no GraphQL queries
 *
 * Useful for testing + storybooks
 */
const QuickStatsBase: React.FC<QuickStatsBaseProps> = ({
  numInterviews,
  talkRatio,
  talkRatioBenchmark,
  questionsAsked,
  questionsAskedBenchmark,
  loading,
  ...styles
}) => {
  return (
    <Flex
      direction="column"
      py="4"
      px="2"
      borderRadius="lg"
      border="1px solid"
      borderColor="gray.100"
      {...styles}
    >
      <Flex
        data-testid="homepage-module-title-stats"
        mb="4"
        alignItems="baseline"
        px="2"
      >
        <Heading
          as="h3"
          color="gray.900"
          fontSize="md"
          fontWeight="semibold"
          lineHeight="5"
          data-tour-id="your-stats"
        >
          My stats
        </Heading>

        <Text ml="auto" fontSize="sm" fontWeight="semibold" color="gray.500">
          Last 30 days
        </Text>
      </Flex>
      {loading && (
        <motion.div
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0, height: 0 },
            visible: { opacity: 1, height: 100 },
          }}
        >
          <LoadingIndicator py={6} />
        </motion.div>
      )}
      {!loading && (
        <>
          <QuickStatsItem
            value={numInterviews ?? 0}
            metric={MetricName.TotalInterviews}
          />
          <QuickStatsItem
            value={talkRatio}
            formatter={formatRatio}
            metric={MetricName.CandidateTalkRatio}
            benchmark={talkRatioBenchmark}
          />
          <QuickStatsItem
            value={questionsAsked}
            formatter={(v) => v.toFixed(2)}
            metric={MetricName.QuestionsAsked}
            benchmark={questionsAskedBenchmark}
          />
        </>
      )}
    </Flex>
  );
};

export default QuickStatsBase;
