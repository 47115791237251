import { ApolloError, MutationTuple } from "@apollo/client";

import {
  CallGuideVisibility,
  DuplicateCallGuideMutation,
  DuplicateCallGuideMutationVariables,
  useDuplicateCallGuideMutation,
} from "../..";
import {
  removeFromCurrentUserSharedCallGuidesQuery,
  removeFromSharedCallGuidesQuery,
  updateCurrentUserQuery,
  updateCurrentUserSharedCallGuidesQuery,
  updatePositionQuery,
  updateSharedCallGuidesQuery,
} from "./utils";

export default function useDuplicateCallGuide({
  onCompleted,
  onError,
}: {
  onCompleted?: (data: DuplicateCallGuideMutation) => void;
  onError?: (error: ApolloError) => void;
}): [
  MutationTuple<
    DuplicateCallGuideMutation,
    DuplicateCallGuideMutationVariables
  >[0],
  { loading: boolean; error: ApolloError | undefined }
] {
  const [duplicateCallGuide, { error, loading }] =
    useDuplicateCallGuideMutation({
      onCompleted,
      onError,
      update(cache, { data: duplicateCallGuideData }) {
        const duplicateCallGuide =
          duplicateCallGuideData?.duplicateCallGuide?.callGuide;
        if (duplicateCallGuide) {
          updateCurrentUserQuery(cache, duplicateCallGuide);
          if (duplicateCallGuide.visibility === CallGuideVisibility.Public) {
            updateSharedCallGuidesQuery(cache, duplicateCallGuide);
            updateCurrentUserSharedCallGuidesQuery(cache, duplicateCallGuide);
          } else {
            removeFromSharedCallGuidesQuery(cache, duplicateCallGuide.id);
            removeFromCurrentUserSharedCallGuidesQuery(
              cache,
              duplicateCallGuide.id
            );
          }
          if (duplicateCallGuide.position?.id) {
            updatePositionQuery(
              cache,
              duplicateCallGuide,
              duplicateCallGuide.position.id
            );
          }
        }
      },
    });

  return [duplicateCallGuide, { loading, error }];
}
