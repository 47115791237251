import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Box,
  Flex,
  Grid,
  Icon,
  Tag,
  Tooltip,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import { HiOutlineInformationCircle } from "react-icons/hi";

import { CaretIcon } from "../../../../components";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { CallSpeakerOption, TranscriptSegmentFragment } from "../../../graphql";
import useCandidateOnlyPlayback from "../../Interview/useCandidateOnlyPlayback";
import { MediaPlayerInterface } from "../../Interview/useMediaPlayer";
import CandidateOnlyToggle from "./CandidateOnlyToggle";
import SpeakerBar from "./SpeakerBar";
import SpeakerBarSegment from "./SpeakerBarSegment";
import SpeakerTag from "./SpeakerTag";
import { CallSpeaker, condenseSpeakerList, sortSpeakerList } from "./utils";

interface CommonSpeakerBarProps {
  callId?: string;
  callDuration: number;
  speakers: CallSpeaker[];
  speakerOptions?: Pick<CallSpeakerOption, "id" | "label">[];
  canChangeSpeaker?: boolean;
  segments: TranscriptSegmentFragment[];
  onClickSegment: (startTime: number) => void;
}

interface SubSpeakerBarProps extends CommonSpeakerBarProps {
  candidateIdentified: boolean;
  candidateOnly: boolean;
  setCandidateOnly(candidateOnly: boolean): void;
}

const SpeakerBarsClosed: React.FC<SubSpeakerBarProps> = ({
  callId,
  callDuration,
  candidateIdentified,
  candidateOnly,
  setCandidateOnly,
  speakers,
  speakerOptions,
  canChangeSpeaker,
  segments,
  onClickSegment,
}) => {
  const speakerBarSegments = useMemo(() => {
    return segments.map((segment) => {
      const speaker = speakers.find((s) => s.speakerTag === segment.speakerTag);
      if (!speaker) {
        return null;
      }
      return (
        <SpeakerBarSegment
          candidateOnly={candidateOnly}
          key={segment.id}
          speaker={speaker}
          segment={segment}
          callDuration={callDuration}
          onClickSegment={onClickSegment}
        />
      );
    });
  }, [segments, speakers, candidateOnly, callDuration, onClickSegment]);
  const finalSpeakers =
    speakers.length > 4 ? condenseSpeakerList(speakers) : speakers;
  const firstUnidentifiedSpeaker = speakers.find((s) => !s.identified);
  const { isOpen: ctaClicked, onOpen: hideCta } = useDisclosure();
  const showCta =
    canChangeSpeaker &&
    segments.length > 0 &&
    !ctaClicked &&
    !!firstUnidentifiedSpeaker;

  return (
    <>
      <Grid
        alignItems="center"
        templateColumns="minmax(0, 1fr) auto auto"
        width="100%"
      >
        <Flex minWidth="0" gap="2" mr="2">
          {showCta ? (
            <Tag
              fontSize="xs"
              borderRadius="xl"
              variant="info"
              cursor="pointer"
              onClick={hideCta}
              lineHeight="4"
              fontWeight="medium"
            >
              <Icon
                as={HiOutlineInformationCircle}
                mr="1"
                color="gray.900"
                boxSize="4"
              />
              Complete this interview by identifying each speaker in the
              conversation
            </Tag>
          ) : (
            finalSpeakers.map((speaker) => (
              <SpeakerTag
                key={speaker.id}
                callId={callId}
                candidateOnly={candidateOnly}
                speaker={speaker}
                canChangeSpeaker={canChangeSpeaker && !speaker.condensed}
                speakerOptions={speakerOptions}
                defaultIsOpen={
                  ctaClicked && speaker.id === firstUnidentifiedSpeaker?.id
                }
              />
            ))
          )}
        </Flex>

        {candidateIdentified ? (
          <CandidateOnlyToggle
            candidateOnly={candidateOnly}
            setCandidateOnly={setCandidateOnly}
            mr="30px"
          />
        ) : (
          <Box />
        )}
        {finalSpeakers.length > 1 && (
          <AccordionButton
            p={0}
            width="auto"
            justifyContent="flex-end"
            aria-label="Show all speaker bars"
          >
            <Tooltip label="Show all speaker bars" shouldWrapChildren>
              <Icon
                as={CaretIcon}
                data-tour-id="ip2-speaker-bar-toggle"
                color="gray.100"
                transition="all 0.5s ease"
                style={{ rotate: "0deg" }}
              />
            </Tooltip>
          </AccordionButton>
        )}
      </Grid>
      <Flex
        position="relative"
        alignItems="center"
        bg="whiteAlpha.300"
        borderRadius="4px"
        h="16px"
        p="6px"
        mt={2}
        w="100%"
      >
        {speakerBarSegments}
      </Flex>
    </>
  );
};

const SpeakerBarsOpen: React.FC<SubSpeakerBarProps> = ({
  callId,
  callDuration,
  candidateIdentified,
  candidateOnly,
  setCandidateOnly,
  speakers,
  speakerOptions,
  canChangeSpeaker,
  segments,
  onClickSegment,
}) => {
  const sendGAEvent = useSendGAEvent();
  useEffect(() => {
    sendGAEvent("speaker_bar_open", "call_review");
  }, []);

  const segmentsBySpeakerTag = speakers.reduce((sum, speaker) => {
    return {
      ...sum,
      [speaker.speakerTag]: segments.filter(
        (segment) => segment.speakerTag === speaker.speakerTag
      ),
    };
  }, {} as Record<number, TranscriptSegmentFragment[]>);

  return (
    <>
      <Grid
        alignItems="center"
        templateColumns="minmax(0, 1fr) auto auto"
        width="100%"
      >
        <Flex minWidth="0" mr="2">
          <SpeakerTag
            callId={callId}
            candidateOnly={candidateOnly}
            speaker={speakers[0]}
            canChangeSpeaker={canChangeSpeaker}
            speakerOptions={speakerOptions}
          />
        </Flex>

        {candidateIdentified ? (
          <CandidateOnlyToggle
            candidateOnly={candidateOnly}
            setCandidateOnly={setCandidateOnly}
            mr="30px"
          />
        ) : (
          <Box />
        )}
        <AccordionButton
          flexGrow="0.95"
          width="auto"
          justifyContent="flex-end"
          p={0}
        >
          <Tooltip label="Hide all speaker bars" shouldWrapChildren>
            <Icon
              as={CaretIcon}
              color="gray.100"
              transition="all 0.5s ease"
              style={{ rotate: "180deg" }}
            />
          </Tooltip>
        </AccordionButton>
      </Grid>
      <SpeakerBar
        callDuration={callDuration}
        candidateOnly={candidateOnly}
        speaker={speakers[0]}
        segments={segmentsBySpeakerTag[speakers[0].speakerTag]}
        onClickSegment={onClickSegment}
      />
      {speakers.slice(1).map((speaker) => (
        <VStack key={speaker.id} align="flex-start" mt="2">
          <SpeakerTag
            callId={callId}
            candidateOnly={candidateOnly}
            speaker={speaker}
            canChangeSpeaker={canChangeSpeaker}
            speakerOptions={speakerOptions}
          />
          <SpeakerBar
            callDuration={callDuration}
            candidateOnly={candidateOnly}
            speaker={speaker}
            segments={segmentsBySpeakerTag[speaker.speakerTag]}
            onClickSegment={onClickSegment}
          />
        </VStack>
      ))}
    </>
  );
};

export interface SpeakerBarProps extends CommonSpeakerBarProps {
  player: Pick<MediaPlayerInterface, "seek" | "time">;
}

const SpeakerBars: React.FC<SpeakerBarProps> = ({
  player,
  segments,
  speakers,
  ...rest
}) => {
  const sortedSpeakers = useMemo(() => sortSpeakerList(speakers), [speakers]);
  const { seek, time } = player;
  const { candidateOnly, setCandidateOnly, candidateIdentified } =
    useCandidateOnlyPlayback(speakers, time, seek, segments);

  return (
    <Accordion allowToggle>
      <AccordionItem border="0">
        {({ isExpanded }) =>
          isExpanded ? (
            <SpeakerBarsOpen
              candidateIdentified={candidateIdentified}
              candidateOnly={candidateOnly}
              segments={segments}
              setCandidateOnly={setCandidateOnly}
              speakers={sortedSpeakers}
              {...rest}
            />
          ) : (
            <SpeakerBarsClosed
              candidateIdentified={candidateIdentified}
              candidateOnly={candidateOnly}
              segments={segments}
              setCandidateOnly={setCandidateOnly}
              speakers={sortedSpeakers}
              {...rest}
            />
          )
        }
      </AccordionItem>
    </Accordion>
  );
};

export default SpeakerBars;
