import { Box, Circle, Flex, Icon, Tag, Text } from "@chakra-ui/react";
import React from "react";
import { HiArrowRight } from "react-icons/hi";

import { RouterLink } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { Alert, useAddAlertViewMutation } from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";
import { DashboardConfig } from "./types";
import { drildownToMetricURL } from "./utils";

export type DashboardCardAlertProps = {
  alert: Alert;
  dashboardConfig: DashboardConfig;
  title: string;
  category: string;
};

const DashboardCardAlert: React.FC<DashboardCardAlertProps> = ({
  alert,
  dashboardConfig,
  title,
  category,
}) => {
  const currentUser = useCurrentUser();
  const sendGAEvent = useSendGAEvent();
  const isCrossOrgAlert = currentUser.organization.id !== alert.organizationId;

  const [addAlertView] = useAddAlertViewMutation({
    update(cache) {
      cache.modify({
        id: cache.identify({ __typename: "Alert", id: alert.id }),
        fields: {
          isNew: () => false,
        },
      });
    },
  });
  const handleAlertClick: () => void = () => {
    // Prevent BH admin user from logging alert views for other orgs
    if (isCrossOrgAlert) return;

    sendGAEvent("alert_click", "analytics", title, undefined, alert);
    addAlertView({
      variables: {
        organizationId: alert.organizationId,
        type: alert.alertType,
        aggregation: alert.aggregation,
        aggregationIds: alert.aggregationIds,
        valence: alert.alertValence,
        category,
        segment: alert.segment,
      },
    });
  };

  return (
    <RouterLink
      to={drildownToMetricURL(alert, dashboardConfig.urlDateParam)}
      onClick={handleAlertClick}
      _hover={{ textDecor: "none" }}
    >
      <Flex
        align="start"
        px="4"
        py="6"
        borderRadius="lg"
        _hover={{ bg: "blue.50" }}
      >
        <Tag
          as={Box}
          position="relative"
          size="sm"
          textTransform="uppercase"
          bg={alert.alertValence === "positive" ? "#E4F0CC" : "gray.100"}
          color={alert.alertValence === "positive" ? "#05636B" : "#505863"}
          mr="2"
          px="1"
          py="2px"
          minHeight="default"
          fontSize="9px"
          borderRadius="2px"
          mt="1"
        >
          {alert.alertValence === "positive" ? "Win" : "Tip"}
          {alert.isNew && <TagIndicator />}
        </Tag>
        <Box flex="1" mr="2">
          <Text color="gray.800" fontSize="sm" fontWeight="normal">
            {alert.message}
          </Text>
        </Box>
        <Icon as={HiArrowRight} />
      </Flex>
    </RouterLink>
  );
};

const TagIndicator: React.FC = () => {
  return (
    <Circle
      position="absolute"
      top="-4px"
      left="-4px"
      size="8px"
      bg="red.600"
    />
  );
};

export default DashboardCardAlert;
