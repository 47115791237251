import { Button } from "@chakra-ui/react";
import React from "react";
import { MdEmail } from "react-icons/md";

import { useToast } from "../../../components";
import { useResendInviteMutation } from "../../graphql";

interface ResendInvitationButtonProps {
  user: {
    id: string;
  };
}

const ResendInvitationButton: React.FC<ResendInvitationButtonProps> = ({
  user,
}) => {
  const toast = useToast();
  const [resendInvite, { loading: resendInviteLoading }] =
    useResendInviteMutation({
      onError: (err) => {
        toast({
          title: "Error",
          description: `Failed to send invitation: ${err.message}`,
          status: "error",
        });
      },
      onCompleted: (data) => {
        if (data.resendInvite) {
          toast({
            title: "Success",
            description: "Sent invitation",
            status: "success",
          });
        }
      },
    });
  return (
    <Button
      variant="outline"
      size="xs"
      leftIcon={<MdEmail />}
      isLoading={resendInviteLoading}
      aria-label="Resend Invite"
      onClick={() => {
        resendInvite({ variables: { id: user.id } });
      }}
    >
      Resend
    </Button>
  );
};

export default ResendInvitationButton;
