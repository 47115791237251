import {
  Box,
  Flex,
  ListItem,
  TabPanel,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";

import { RouterLink } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { CandidateInterviewFragment, Chapter } from "../../graphql";
import VideoWithThumbnail from "../Video/VideoWithThumbnail";
import { getCallName } from "./utils";

type InterviewChapterProps = {
  chapter: Chapter;
  call: CandidateInterviewFragment;
  thumbnailUrl?: any; // remove this when we have highlight thumbnails backfilled
  index: number;
};

const InterviewChapter: React.FC<InterviewChapterProps> = ({
  chapter,
  call,
  thumbnailUrl,
  index,
}) => {
  const sendGAEvent = useSendGAEvent();
  return (
    <TabPanel
      key={chapter.id}
      pl={12}
      pb={3}
      fontSize="sm"
      color="gray.800"
      maxW="600px"
    >
      <Text fontWeight="semibold" mb={2} fontSize="lg" mt="4">
        {index + 1}. {chapter.text}
      </Text>
      <Box width="70%">
        <VideoWithThumbnail
          streamableVideo={call.streamableVideo}
          streamableAudio={call.streamableAudio}
          startTime={chapter.startTime}
          thumbnailUrl={chapter.thumbnailUrl || thumbnailUrl}
          audioOnly={!chapter.thumbnailUrl && !thumbnailUrl}
        />
      </Box>

      <Text fontWeight="semibold" mt={6} mb={2}>
        {`${
          chapter.questionAndAnswer.question.includes("Candidate discussed:")
            ? ""
            : "Q: "
        }${chapter.questionAndAnswer.question}`}
      </Text>
      <UnorderedList pl={2}>
        {chapter.questionAndAnswer.answerItems.map((answer) => (
          <ListItem key={answer.id} mb={1}>
            {answer.text}
          </ListItem>
        ))}
      </UnorderedList>
      <Flex direction="column" align="start" mt={4}>
        <RouterLink
          to={`/interview/${call.id}?t=${chapter.startTime}&tab=ai_notes`}
          onClick={() =>
            sendGAEvent("view_interview", "candidate", "chapter_button")
          }
        >
          {getCallName(call)}
        </RouterLink>
      </Flex>
    </TabPanel>
  );
};
export default InterviewChapter;
