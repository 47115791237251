import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const FormatLengthLong = (props: IconProps): JSX.Element => (
  <Icon {...props} viewBox="0 0 16 16">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M10 10H2V11.3333H10V10ZM10 4.66667H2V6H10V4.66667ZM2 8.66667H14V7.33333H2V8.66667ZM2 14H14V12.6667H2V14ZM2 2V3.33333H14V2H2Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
);

export default FormatLengthLong;
