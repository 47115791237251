export const getEnabledProducts = (currentUser?: {
  organization: { planEnabled: boolean; interviewEnabled: boolean };
  userRole?: any | null;
}): Array<"interview" | "plan"> => {
  const planEnabled = currentUser?.organization.planEnabled;
  const interviewEnabled = currentUser?.organization.interviewEnabled;

  if (planEnabled && interviewEnabled) return ["interview", "plan"];
  if (interviewEnabled) return ["interview"];
  if (planEnabled) return ["plan"];
  return [];
};
