import { formatISODate } from "../../../utils/datetime";
import {
  AnalyticsDimension,
  DataPoint,
  MetricName,
  useAnalyticsBenchmarksQuery,
  useAnalyticsDataQuery,
  useCurrentUserQuery,
} from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import { AnalyticsHeroType, pickHeroType } from "./AnalyticsHeroContent";
import { CommonQueryVariables } from "./types";
import { AnalyticsConfig } from "./useAnalyticsConfig";
import useAnalyticsContext from "./useAnalyticsContext";
import { isScorecardMetric } from "./utils";

export type MetricBenchmarkRange = {
  metric: any;
  upperBound: number;
  lowerBound: number;
};

export type AnalyticsData = {
  dataPoints: DataPoint[] | undefined;
  heroType: AnalyticsHeroType | null;
  filtersAvailable: boolean;
  loading: boolean;
  commonQueryVariables: CommonQueryVariables;
  benchmarkRange?: MetricBenchmarkRange;
};

const disabledMetrics: string | MetricName[] = [];

type Options = {
  skipAllQueries?: boolean;
};

/**
 * Query results and any error states related to analytics
 */
const useAnalyticsData = (
  analyticsConfig: AnalyticsConfig,
  options: Options
): AnalyticsData => {
  const { skipAllQueries } = options;
  const { metric } = analyticsConfig;
  const { selectedOrgId } = useAnalyticsContext();

  const commonQueryVariables: CommonQueryVariables = {
    metric,
    dateRangeStart: formatISODate(analyticsConfig.dateRange.value.start),
    dateRangeEnd: formatISODate(analyticsConfig.dateRange.value.end),
    organizationId: selectedOrgId,
    ...analyticsConfig.filters,
  };

  const analyticsDataQuery = useAnalyticsDataQuery({
    variables: {
      ...commonQueryVariables,
      primaryDimension: analyticsConfig.primaryDimension.value,
      secondaryDimension: analyticsConfig.secondaryDimension.value,
    },
    skip: disabledMetrics?.includes(metric) || skipAllQueries,
  });
  const dataPoints = analyticsDataQuery.data?.analyticsData?.data;

  // Get Organization data
  const { data } = useCurrentUserQuery();

  // Lifetime interview count
  const lifetimeInterviewsMetric = isScorecardMetric(metric)
    ? MetricName.AtsTotalInterviews
    : MetricName.TotalInterviews;
  const visibleInteviewCountQuery = useAnalyticsDataQuery({
    variables: {
      metric: lifetimeInterviewsMetric,
      primaryDimension: AnalyticsDimension.Interviewer,
      secondaryDimension: AnalyticsDimension.None,
      departments: [],
      positions: [],
      interviewers: [],
      stages: [],
      dateRangeStart: `1901-01-01`,
      dateRangeEnd: formatISODate(new Date()),
      organizationId: selectedOrgId,
    },
    skip: skipAllQueries,
  });
  const visibleInterviewCount =
    visibleInteviewCountQuery.data?.analyticsData?.data?.reduce(
      (prev, cur) => prev + (cur.value || 0),
      0
    );

  // benchmarks
  const analyticsBenchmarksQuery = useAnalyticsBenchmarksQuery({
    skip: !useFeatureFlag("analytics:release2"),
  });
  const benchmarks = analyticsBenchmarksQuery.data?.benchmarks?.data;
  const benchmarkRange = benchmarks?.find((b) => b.metric === metric);

  const queriesLoadedAndNoData =
    !analyticsDataQuery.loading &&
    !visibleInteviewCountQuery.loading &&
    dataPoints?.length === 0;

  /** Whether the user sees no data, but could if they changed filters */
  const noDataBecauseOfFilters =
    queriesLoadedAndNoData && visibleInterviewCount !== 0;

  const heroType = pickHeroType(
    metric,
    Boolean(analyticsDataQuery.error) ||
      Boolean(visibleInteviewCountQuery.error),
    queriesLoadedAndNoData,
    visibleInterviewCount,
    !!data?.currentUser?.organization?.ats
  );

  return {
    dataPoints,
    heroType,
    // Any hero display other than the one specifically about overly specific filters
    // means there is definitely no data that could be filtered.
    filtersAvailable: !heroType || noDataBecauseOfFilters,
    // If datapoints is undefined or null the queriy is obviously loading. If data points are
    // defined, but empty, it could be either because of filters or the lack of intervies.
    // Show loading until we are sure.
    loading:
      dataPoints === undefined ||
      dataPoints === null ||
      // Because interviewer labels are used in drilldowns as soon as chart is
      // rendered, wait for interviewer labels to be available if interviewers are selected. In practice filters
      // load faster than data because they are a simpler query.
      ((analyticsConfig.interviewers.values || []).length > 0 &&
        analyticsConfig.interviewers.labels === undefined) ||
      (dataPoints.length === 0 && visibleInteviewCountQuery.loading),
    commonQueryVariables,
    benchmarkRange,
  };
};

export default useAnalyticsData;
