import { Box, Flex, InputProps } from "@chakra-ui/react";
import React from "react";

import { LimitSelect } from "../Pagination/LimitSelect";
import UncontrolledSearchInput from "./UncontrolledSearchInput";

type SearchBarProps = {
  onSearch: (query: string) => void;
  onLimitChange: (limit: number) => void;
  query?: string;
  limit?: number;
  totalCount?: number;
  limits: number[];
  placeholder?: string;
  tooltip?: React.ReactElement<any, any>;
} & InputProps;

const SearchBar: React.FC<SearchBarProps> = ({
  onSearch,
  onLimitChange,
  query,
  limit,
  totalCount,
  limits,
  tooltip = null,
  placeholder = "Search...",
  ...rest
}) => {
  return (
    <Flex mb={4} flexFlow="row nowrap" alignItems="center" width="100%">
      {tooltip}
      <UncontrolledSearchInput
        maxWidth="450px"
        defaultValue={query}
        placeholder={placeholder}
        onSearch={(newQuery) => {
          if (query !== newQuery) {
            onSearch(newQuery);
          }
        }}
      />
      <Box minW="100px">{totalCount} results</Box>
      <LimitSelect
        limit={limit}
        limits={limits}
        onChange={(e: React.SyntheticEvent<HTMLSelectElement, Event>) => {
          const newLimit = parseInt(e.currentTarget.value, 10);
          if (newLimit !== limit) {
            onLimitChange(newLimit);
          }
        }}
      />
    </Flex>
  );
};

export default SearchBar;
