import colors from "../../../theme/css-color-variables";
import { LabeledTrackerTabGroup, SelectedItem } from "./types";
import { SelectedGroupType, trackerGroupType } from "./utils";

export type TrackerStyles = {
  background?: string;
  hoverBackground?: string;
  borderColor?: string;
  hoverBorderColor?: string;
  textColor?: string;
  borderWidth?: string;
  countTextColor?: string;
  boxShadow?: string;
};
const borderWidth = "1px";
const activeBorderWidth = "3px";
const boxShadows = {
  group: {
    other: {
      active: {
        boxShadowColor: colors.blue[300],
      },
      inactive: {
        boxShadowColor: colors.gray[100],
      },
    },
    questions: {
      active: {
        boxShadowColor: colors.green[300],
      },
      inactive: {
        boxShadowColor: colors.gray[100],
      },
    },
    "action items": {
      active: {
        boxShadowColor: colors.orange[200],
      },
      inactive: {
        boxShadowColor: colors.gray[100],
      },
    },
  },
};

export const TRACKER_STYLES: {
  [key in SelectedGroupType]: TrackerStyles;
} = {
  "action items": {
    hoverBackground: "orange.50",
    borderColor: "transparent",
    hoverBorderColor: "gray.100",
    textColor: "orange.600",
    borderWidth,
    countTextColor: "orange.300",
    boxShadow: `0 0 0 ${borderWidth} ${boxShadows.group["action items"].inactive.boxShadowColor}`,
  },
  questions: {
    hoverBackground: "green.50",
    borderColor: "transparent",
    hoverBorderColor: "gray.100",
    textColor: "green.700",
    borderWidth,
    countTextColor: "green.500",
    boxShadow: `0 0 0 ${borderWidth} ${boxShadows.group.questions.inactive.boxShadowColor}`,
  },
  other: {
    hoverBackground: "blue.50",
    borderColor: "transparent",
    hoverBorderColor: "gray.100",
    textColor: colors.icons.bright,
    borderWidth,
    countTextColor: "blue.400",
    boxShadow: `0 0 0 ${borderWidth} ${boxShadows.group.other.inactive.boxShadowColor}`,
  },
  "action items-active": {
    borderColor: "orange.200",
    background: "white",
    hoverBackground: "orange.50",
    textColor: "orange.600",
    borderWidth: activeBorderWidth,
    countTextColor: "orange.300",
    boxShadow: `0 0 0 ${activeBorderWidth} ${boxShadows.group["action items"].active.boxShadowColor}`,
  },
  "questions-active": {
    borderColor: "green.300",
    background: "white",
    hoverBackground: "green.50",
    textColor: "green.700",
    borderWidth: activeBorderWidth,
    countTextColor: "green.500",
    boxShadow: `0 0 0 ${activeBorderWidth} ${boxShadows.group.questions.active.boxShadowColor}`,
  },
  "other-active": {
    borderColor: "blue.300",
    background: "white",
    hoverBackground: "blue.50",
    textColor: colors.icons.bright,
    borderWidth: activeBorderWidth,
    countTextColor: "blue.400",
    boxShadow: `0 0 0 ${activeBorderWidth} ${boxShadows.group.other.active.boxShadowColor}`,
  },
};

const isSelected = (
  group: LabeledTrackerTabGroup,
  selected: SelectedItem | undefined
): boolean => {
  if (selected) return group.id === selected.id;
  return false;
};

const isSelectedPostFix = (
  group: LabeledTrackerTabGroup,
  selected: SelectedItem | undefined
): "-active" | "" => {
  if (isSelected(group, selected)) return "-active";
  return "";
};

export const getTrackerStyles = (
  group: LabeledTrackerTabGroup,
  selected: SelectedItem | undefined
): TrackerStyles => {
  let key = `${trackerGroupType(group.name)}`;
  key += isSelectedPostFix(group, selected);

  return TRACKER_STYLES[key as SelectedGroupType];
};
