import { Flex } from "@chakra-ui/react";
import React from "react";

import { Alert } from "../../../components";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { Playlist } from "../../graphql";
import DefaultLayout from "../../layouts/DefaultLayout";
import MainContent from "../training/MainContent";
import MyPlaylistsPageSidebar from "./MyPlaylistsPageSidebar";

type MyPlaylistsPageLayoutProps = {
  activePlaylistId?: string;
  activeSort: string;
  children: React.ReactNode;
  error?: string;
  goToPlaylist(playlistId: string): void;
  playlistData: Pick<Playlist, "id" | "title">[];
  setActiveSort: React.Dispatch<React.SetStateAction<string>>;
};

const MyPlaylistsPageLayout: React.FC<MyPlaylistsPageLayoutProps> = ({
  playlistData,
  children,
  activeSort,
  setActiveSort,
  goToPlaylist,
  activePlaylistId,
  error,
}) => {
  const { layoutHeight } = useWindowDimensions();
  const xPad = "8";
  const chakraXPad = `var(--chakra-space-${xPad})`;

  return (
    <DefaultLayout>
      <Flex minHeight={layoutHeight} overflowX="auto">
        <MyPlaylistsPageSidebar
          playlistData={playlistData}
          activeSort={activeSort}
          setActiveSort={setActiveSort}
          goToPlaylist={goToPlaylist}
          activePlaylistId={activePlaylistId}
        />
        <MainContent
          px={xPad}
          pb="6"
          pt={error ? "0" : "6"}
          flex="1 1 auto"
          minW="400px"
        >
          {error && (
            <Alert
              width={`calc(100% + 2 * ${chakraXPad})`}
              ml={`calc(-1 * ${chakraXPad})`}
              pb="4"
              status="error"
              title="Error loading playlist"
              description={error}
            />
          )}
          <Flex
            flexDir="column"
            height="100%"
            mx="auto"
            maxWidth="1012px"
            boxSizing="initial"
          >
            {children}
          </Flex>
        </MainContent>
      </Flex>
    </DefaultLayout>
  );
};

export default MyPlaylistsPageLayout;
