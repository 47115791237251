import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";

import { usePageTracker } from "../../../utils/googleAnalytics";
import CenteredContentLayout from "../../layouts/CenteredContentLayout";

const Forbidden: React.FC<{
  children?: React.ReactNode;
  showLayout?: boolean;
  forbiddenFrom?: string;
}> = ({ showLayout = true, forbiddenFrom = "page", children }) => {
  usePageTracker("forbidden");
  return showLayout ? (
    <CenteredContentLayout>
      <Flex alignItems="center" direction="column">
        <Heading pb="18px">Sorry!</Heading>
        <Text>
          You don&apos;t have permission to view this {forbiddenFrom}.
        </Text>
        {children}
        <Button mt="4" onClick={() => window.history.back()}>
          Go back
        </Button>
      </Flex>
    </CenteredContentLayout>
  ) : (
    <Flex alignItems="center" direction="column" mt={8}>
      <Heading pb="18px">Sorry!</Heading>
      <Text>You don&apos;t have permission to view this {forbiddenFrom}.</Text>
      {children}
      <Button mt="4" onClick={() => window.history.back()}>
        Go back
      </Button>
    </Flex>
  );
};

export default Forbidden;
