import { BoxProps, Flex } from "@chakra-ui/react";
import React from "react";

import Step from "./Step";
import { StepperStep } from "./types";

type StepperProps = {
  steps: StepperStep[];
} & BoxProps;

/**
 * A series of pointed rectangle steps, as part of a wizard workflow.
 */
const Stepper: React.FC<StepperProps> = ({ steps, ...boxProps }) => {
  return (
    <Flex
      {...boxProps}
      borderTopWidth="1px"
      borderBottomWidth="1px"
      borderColor="gray.100"
      height="66px"
    >
      <Flex width="100%" maxWidth="1012px" ml="auto" mr="auto">
        {steps.map((step, index) => (
          <Step
            marginTop="-1px"
            key={step.label}
            step={step}
            first={index === 0}
            zIndex={steps.length - index}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default Stepper;
