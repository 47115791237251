import { Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";

const NoCandidateAlerts: React.FC = () => (
  <Flex direction="column" alignItems="center" py="4">
    <Icon
      width="208"
      height="121"
      viewBox="0 0 208 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.2891 80.6892V82.6033"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeLinecap="round"
      />
      <path
        d="M23.9902 81.7261L25.5543 83.3361"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeLinecap="round"
      />
      <path
        d="M22.1602 86.0122H24.3717"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeLinecap="round"
      />
      <path
        d="M23.8105 90.3745L25.3746 88.7644"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeLinecap="round"
      />
      <path
        d="M28.2891 90.8975V88.9834"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeLinecap="round"
      />
      <path
        d="M31.4792 89.6215L30.2031 88.3455"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeLinecap="round"
      />
      <path
        d="M32.7546 81.9653L31.4785 83.2414"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeLinecap="round"
      />
      <path
        d="M34.0312 86.4314H32.1172"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeLinecap="round"
      />
      <path
        d="M92.4551 10.6023V11.8783"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeLinecap="round"
      />
      <path
        d="M88.6641 14.4575H89.9401"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeLinecap="round"
      />
      <path
        d="M92.4551 18.2587V16.9827"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeLinecap="round"
      />
      <path
        d="M95.6452 14.4304H94.3691"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeLinecap="round"
      />
      <path
        d="M9.93359 41.064V42.978"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.19141 46.8064H6.10547"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M9.93359 51.9104V49.9963"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M15.0371 46.8064H13.123"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M146.144 110.633C147.669 110.633 148.906 111.87 148.906 113.395C148.906 114.921 147.669 116.157 146.144 116.157C144.619 116.157 143.383 114.921 143.383 113.395"
        stroke="#E5E7EA"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M168.42 85.5742C170.06 85.0627 172.573 83.0576 173.155 80.9001C173.682 82.8073 175.894 85.0627 177.889 85.2334C175.643 86.0288 173.495 88.3915 173.155 90.2477C172.923 88.3558 169.952 85.85 168.42 85.5742Z"
        fill="#EBECEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.117188 109.782C0.937249 109.527 2.19334 108.524 2.48421 107.446C2.74799 108.399 3.85351 109.527 4.85123 109.612C3.72838 110.009 2.65483 111.191 2.48421 112.119C2.3683 111.173 0.883084 109.92 0.117188 109.782Z"
        fill="#EBECEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8468 21.0282C23.8468 22.2115 22.888 23.1699 21.7056 23.1699C20.5226 23.1699 19.5645 22.2115 19.5645 21.0282C19.5645 19.8455 20.5226 18.8865 21.7056 18.8865C22.888 18.8865 23.8468 19.8455 23.8468 21.0282Z"
        fill="#D1D8DF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M207.884 17.0805C207.884 18.649 206.613 19.9201 205.046 19.9201C203.478 19.9201 202.207 18.649 202.207 17.0805C202.207 15.512 203.478 14.241 205.046 14.241C206.613 14.241 207.884 15.512 207.884 17.0805Z"
        fill="#EBECEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.3521 113.973C55.3521 114.962 54.551 115.764 53.5625 115.764C52.5745 115.764 51.7734 114.962 51.7734 113.973C51.7734 112.985 52.5745 112.183 53.5625 112.183C54.551 112.183 55.3521 112.985 55.3521 113.973Z"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.2862 7.26915C54.2862 8.45243 53.3275 9.41087 52.1451 9.41087C50.9626 9.41087 50.0039 8.45243 50.0039 7.26915C50.0039 6.08642 50.9626 5.12744 52.1451 5.12744C53.3275 5.12744 54.2862 6.08642 54.2862 7.26915Z"
        stroke="#D2D8DF"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M181.895 119.826C180.527 120.347 178.995 119.659 178.475 118.291C177.954 116.923 178.641 115.39 180.01 114.87C181.377 114.35 182.909 115.037 183.429 116.405C183.95 117.774 183.263 119.305 181.895 119.826Z"
        stroke="#E5E7EA"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M142.989 3.05509C143.75 3.17302 144.24 3.85952 144.085 4.58843L142.085 13.9458C141.929 14.6747 141.186 15.17 140.425 15.0521C139.664 14.9342 139.174 14.2477 139.33 13.5188L141.329 4.16135C141.485 3.43245 142.228 2.93716 142.989 3.05509Z"
        fill="#E5E7EA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M149.453 29.6908C149.612 28.8507 150.325 28.2818 151.046 28.4202L160.307 30.1963C161.028 30.3347 161.484 31.1278 161.326 31.9679C161.167 32.808 160.454 33.3768 159.733 33.2385L150.472 31.4624C149.751 31.324 149.295 30.5308 149.453 29.6908Z"
        fill="#E5E7EA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.776 6.23717C125.402 5.79179 126.224 5.93725 126.613 6.56207L131.588 14.5538C131.977 15.1786 131.785 16.0462 131.159 16.4915C130.534 16.9369 129.711 16.7915 129.322 16.1666L124.348 8.17493C123.959 7.55011 124.15 6.68255 124.776 6.23717Z"
        fill="#E5E7EA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.203 13.4657C158.592 14.0905 158.4 14.9581 157.774 15.4035L149.773 21.1001C149.147 21.5455 148.324 21.4 147.935 20.7752C147.547 20.1504 147.738 19.2828 148.364 18.8374L156.366 13.1408C156.992 12.6954 157.814 12.8409 158.203 13.4657Z"
        fill="#E5E7EA"
      />
      <rect
        opacity="0.7"
        x="39.3086"
        y="23.8337"
        width="101.293"
        height="38.7297"
        rx="4"
        fill="#E5E7EA"
      />
      <path
        opacity="0.6"
        d="M54.9225 50.3914C58.9528 50.3914 62.2201 47.114 62.2201 43.0711C62.2201 39.0282 58.9528 35.7507 54.9225 35.7507C50.8922 35.7507 47.625 39.0282 47.625 43.0711C47.625 47.114 50.8922 50.3914 54.9225 50.3914Z"
        fill="#B8BEC5"
      />
      <path
        opacity="0.47"
        d="M103.497 33.7646H70.4974C69.6747 33.7646 69.0078 34.4316 69.0078 35.2543C69.0078 36.0769 69.6747 36.7439 70.4974 36.7439H103.497C104.319 36.7439 104.986 36.0769 104.986 35.2543C104.986 34.4316 104.319 33.7646 103.497 33.7646Z"
        fill="#B8BEC5"
      />
      <path
        opacity="0.47"
        d="M117.074 41.4253H70.4974C69.6747 41.4253 69.0078 42.0922 69.0078 42.9149C69.0078 43.7376 69.6747 44.4045 70.4974 44.4045H117.074C117.896 44.4045 118.563 43.7376 118.563 42.9149C118.563 42.0922 117.896 41.4253 117.074 41.4253Z"
        fill="#B8BEC5"
      />
      <path
        opacity="0.47"
        d="M100.103 49.2566H70.4974C69.6747 49.2566 69.0078 49.9235 69.0078 50.7462C69.0078 51.5689 69.6747 52.2358 70.4974 52.2358H100.103C100.925 52.2358 101.592 51.5689 101.592 50.7462C101.592 49.9235 100.925 49.2566 100.103 49.2566Z"
        fill="#B8BEC5"
      />
      <rect
        opacity="0.7"
        x="65.1289"
        y="68.522"
        width="101.293"
        height="38.7297"
        rx="4"
        fill="#E5E7EA"
      />
      <path
        opacity="0.6"
        d="M80.7428 95.0794C84.7731 95.0794 88.0404 91.802 88.0404 87.7591C88.0404 83.7161 84.7731 80.4387 80.7428 80.4387C76.7125 80.4387 73.4453 83.7161 73.4453 87.7591C73.4453 91.802 76.7125 95.0794 80.7428 95.0794Z"
        fill="#B8BEC5"
      />
      <path
        opacity="0.47"
        d="M129.317 78.4526H96.3177C95.495 78.4526 94.8281 79.1196 94.8281 79.9422C94.8281 80.7649 95.495 81.4318 96.3177 81.4318H129.317C130.14 81.4318 130.807 80.7649 130.807 79.9422C130.807 79.1196 130.14 78.4526 129.317 78.4526Z"
        fill="#B8BEC5"
      />
      <path
        opacity="0.47"
        d="M142.894 86.1135H96.3177C95.495 86.1135 94.8281 86.7804 94.8281 87.6031C94.8281 88.4258 95.495 89.0927 96.3177 89.0927H142.894C143.716 89.0927 144.383 88.4258 144.383 87.6031C144.383 86.7804 143.716 86.1135 142.894 86.1135Z"
        fill="#B8BEC5"
      />
      <path
        opacity="0.47"
        d="M125.923 93.9446H96.3177C95.495 93.9446 94.8281 94.6115 94.8281 95.4342C94.8281 96.2569 95.495 96.9238 96.3177 96.9238H125.923C126.746 96.9238 127.412 96.2569 127.412 95.4342C127.412 94.6115 126.746 93.9446 125.923 93.9446Z"
        fill="#B8BEC5"
      />
    </Icon>
    <Text
      mt="5"
      mb="3"
      fontSize="lg"
      fontWeight="semibold"
      color="gray.700"
      lineHeight="6"
    >
      No alerts!
    </Text>
    <Text fontSize="md" color="gray.600" lineHeight="5">
      Not subscribed to any candidate alerts.
    </Text>
  </Flex>
);

export default NoCandidateAlerts;
