import { CurrentUserFragment } from "../main/graphql";

export const canManageThisHiringTeam = (
  currentUser: CurrentUserFragment,
  positionId: string
): boolean => {
  // Able to manage any hiring team
  if (currentUser.userRole?.canManageHiringTeam) {
    return true;
  }

  // Specifically the Hiring Team Manager
  const positionMembership = currentUser.memberships.find(
    ({ position }) => position.id === positionId
  );
  return !!positionMembership?.role.canManageHiringTeam;
};
