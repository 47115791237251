import { Box, BoxProps } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import DefaultLayout from "./DefaultLayout";

type DefaultPageLayoutProps = PropsWithChildren<BoxProps>;

/**
 * Like `DefaultLayout`, but with some layout assumptions made
 * for `children`
 *  - `children` will be in an area with a min-height to take up the
 *     rest of the viewport after the page header (so that the total
 *     min-height of the page is 100vh)
 *  - A `maxW` (or `maxWidth`) can be applied, above which `children`
 *    will be centered on the page horizontally
 *
 * This layout is similar to:
 * ```
 * <DefaultLayout>
 *   <PageContent>
 *     {children}
 *   </PageContent>
 * </DefaultLayout>
 * ```
 * but `PageContent` comes with a pagePadding that we don't want
 *
 * TODO: Many of today's usages of `<DefaultLayout />` could be replaced
 *       with this component.
 */
const DefaultPageLayout: React.FC<DefaultPageLayoutProps> = ({
  children,
  maxW,
  maxWidth,
  ...styles
}) => {
  const { layoutHeight } = useWindowDimensions();

  return (
    <DefaultLayout>
      <Box minH={layoutHeight} bg="white" display="flex" {...styles}>
        <Box maxW={maxW || maxWidth} marginX="auto" flex="1">
          {children}
        </Box>
      </Box>
    </DefaultLayout>
  );
};

export default DefaultPageLayout;
