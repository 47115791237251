import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

const EmptyAIIcon = (props: BoxProps): JSX.Element => (
  <Box {...props}>
    <svg fill="none" width="376" height="136" viewBox="0 0 376 136">
      <g filter="url(#filter0_d_845_20603)">
        <path
          d="M278.5 14.001V30.001M270.5 22.0019L286.5 22.0019M39.5 122.001V130.001M35.5 126.002H43.5M316.5 66.999V130.999M284.5 99.0016H348.5M79.5007 57.001V97.001M59.5 77.0026H99.5M15.5 10.001V34.001M3.5 22.0019H27.5M356.5 17.001V49.001M340.5 33.0022L372.5 33.0021"
          stroke="#FFCBAE"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <g filter="url(#filter1_d_845_20603)">
          <path
            d="M167.5 17.001V25.001M147.5 45.001H139.5M235.5 45.001H227.5M147.5 65.001H139.5M235.5 65.001H227.5M147.5 85.001H139.5M235.5 85.001H227.5M167.5 105.001V113.001M187.5 17.001V25.001M187.5 105.001V113.001M207.5 17.001V25.001M207.5 105.001V113.001M159.5 105.001H215.5C218.683 105.001 221.735 103.737 223.985 101.486C226.236 99.2358 227.5 96.1836 227.5 93.001V37.001C227.5 33.8184 226.236 30.7661 223.985 28.5157C221.735 26.2653 218.683 25.001 215.5 25.001H159.5C156.317 25.001 153.265 26.2653 151.015 28.5157C148.764 30.7661 147.5 33.8184 147.5 37.001V93.001C147.5 96.1836 148.764 99.2358 151.015 101.486C153.265 103.737 156.317 105.001 159.5 105.001ZM163.5 41.001H211.5V89.001H163.5V41.001Z"
            stroke="#4FD1C5"
            strokeWidth="8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_845_20603"
          x="0"
          y="0.000976562"
          width="376"
          height="135.498"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_845_20603"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_845_20603"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_845_20603"
          x="133.5"
          y="12.001"
          width="108"
          height="108"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_845_20603"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_845_20603"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </Box>
);

export default EmptyAIIcon;
