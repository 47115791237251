export type SegmentName = "all" | "M" | "F" | "U";

export const segmentLabels: { [key in SegmentName]: string } = {
  all: "All",
  M: "Inferred Male",
  F: "Inferred Female",
  U: "Unknown Gender",
};

// Color does not fit within existing colors scales but is needed for clarity.
// This should be moved into src/theme/colors.ts once approved for general use.
export const dataVizColorRanges = {
  hipsterSalmon: {
    100: "#FFE5E2",
    200: "##FECCC5",
    300: "#FEB2A9",
    400: "#FD998C",
    500: "#FD7F6F",
    600: "#DD6F61",
    700: "#BE5F53",
    800: "#9E4F45",
    900: "#7F4038",
  },
  meadowBlossomBlue: {
    100: "#E5EFF7",
    200: "#CBDFEE",
    300: "#B2D0E6",
    400: "#98C0DD",
    500: "#7EB0D5",
    600: "#6E9ABA",
    700: "#5F84A0",
    800: "#4F6E85",
    900: "#3F586B",
  },
  lastOfLettuce: {
    100: "#F0F9DF",
    200: "#E0F3C0",
    300: "#D1ECA0",
    400: "#C1E681",
    500: "#B2E061",
    600: "#9CC455",
    700: "#86A849",
    800: "#6F8C3D",
    900: "#597031",
  },
  lavenderSweater: {
    100: "#F2E5F2",
    200: "#E5CBE5",
    300: "#D7B2D8",
    400: "#CA98CB",
    500: "#BD7EBE",
    600: "#A56EA6",
    700: "#8E5F8F",
    800: "#764F77",
    900: "#5F3F5F",
  },
  goldenRainYellow: {
    100: "#FFF0DE",
    200: "#FFE1BD",
    300: "#FFD39C",
    400: "#FFC47B",
    500: "#FFB55A",
    600: "#DF9E4F",
    700: "#BF8844",
    800: "#9F7138",
    900: "#805B2D",
  },
  caduceusGold: {
    100: "#FFFCE0",
    200: "#FFF8C1",
    300: "#FFF5A3",
    400: "#FFF184",
    500: "#FFEE65",
    600: "#DFD058",
    700: "#BFB34C",
    800: "#9F953F",
    900: "#807733",
  },
  beatrice: {
    100: "#F2F1F8",
    200: "#E5E3F1",
    300: "#D8D5E9",
    400: "#CBC7E2",
    500: "#BEB9DB",
    600: "#A6A2C0",
    700: "#8F8BA4",
    800: "#777489",
    900: "#5F5D6E",
  },
  classicRose: {
    100: "#FFF5FA",
    200: "#FEEBF5",
    300: "#FEE0EF",
    400: "#FDD6EA",
    500: "#FDCCE5",
    600: "#DDB3C8",
    700: "#BE99AC",
    800: "#9E808F",
    900: "#7F6673",
  },
  greenDaze: {
    100: "#E8F6F4",
    200: "#D1EDE9",
    300: "#B9E5DD",
    400: "#A2DCD2",
    500: "#8BD3C7",
    600: "#7AB9AE",
    700: "#689E95",
    800: "#57847C",
    900: "#466A64",
  },
};

export const themedSegmentColors = (
  colors: any
): { [key in SegmentName]: string } => ({
  all: dataVizColorRanges.beatrice["600"],
  M: dataVizColorRanges.lavenderSweater["600"],
  F: dataVizColorRanges.goldenRainYellow["600"],
  U: dataVizColorRanges.greenDaze["600"],
});

export const themedSegmentColorsFocused = (
  colors: any
): { [key in SegmentName]: string } => ({
  all: dataVizColorRanges.beatrice["400"],
  M: dataVizColorRanges.lavenderSweater["400"],
  F: dataVizColorRanges.goldenRainYellow["400"],
  U: dataVizColorRanges.greenDaze["400"],
});
