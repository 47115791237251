import { Flex, Text, Textarea } from "@chakra-ui/react";
import React from "react";

import { successToast, useToast } from "../../../components";
import {
  ScorecardItem,
  useUpdateScorecardCompetencyMutation,
} from "../../graphql";
import CompetencyStarRating from "../CompetencyStarRating";

interface ScorecardCompetencyProps {
  competency: Pick<
    ScorecardItem,
    "id" | "itemText" | "score" | "notes" | "scoringEnabled"
  >;
}

const ScorecardCompetencyListItem: React.FC<ScorecardCompetencyProps> = ({
  competency,
}) => {
  const [updateScorecardCompetency] = useUpdateScorecardCompetencyMutation();
  const toast = useToast();
  return (
    <Flex
      width="100%"
      direction="column"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="8"
      p="4"
    >
      <Flex justifyContent="space-between" width="100%" gap="2">
        <Text fontWeight="600" fontSize="sm" color="gray.800">
          {competency.itemText}
        </Text>
        {competency.scoringEnabled && (
          <CompetencyStarRating size={5} competency={competency} />
        )}
      </Flex>
      {(competency.score || 0) > 0 && (
        <Flex width="100%" mt="3">
          <Textarea
            borderRadius="6"
            px="3"
            fontSize="sm"
            defaultValue={competency.notes || ""}
            placeholder="Explain your score"
            onBlur={(e) => {
              if (e.target.value === competency.notes) return;
              updateScorecardCompetency({
                variables: {
                  scorecardCompetencyId: competency.id,
                  score: competency.score || 0,
                  notes: e.target.value,
                },
                optimisticResponse: {
                  updateScorecardCompetency: {
                    __typename: "UpdateScorecardCompetency",
                    scorecardItem: {
                      __typename: "ScorecardItem",
                      id: competency.id,
                      score: competency.score,
                      notes: competency.notes,
                      updatedAt: new Date().toISOString(),
                    },
                  },
                },
                onCompleted: () => {
                  successToast(toast, "Scores updated", { isClosable: true });
                },
              });
            }}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default ScorecardCompetencyListItem;
