import { Box, Button, Flex, IconButton, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { HiOutlineXCircle } from "react-icons/hi";

import { RouterLink, SortableTable } from "../../../components";
import { formatDate } from "../../../utils/datetime";
import { CallGuide, CallGuideUser } from "../../graphql";
import useUpdateCallGuide from "../../graphql/hooks/CallGuide/useUpdateCallGuide";
import useLink from "../../hooks/useLink";

interface AssignedUsersProps {
  callGuide: CallGuide;
  onEdit(): void;
}

const AssignedUsers: React.FC<AssignedUsersProps> = ({ callGuide, onEdit }) => {
  const [updateCallGuide] = useUpdateCallGuide({});

  if (!callGuide.assignedUsers.length) {
    return (
      <Flex flexDir="column" alignItems="center" py={5}>
        <Text
          as="p"
          fontSize="sm"
          fontWeight="400"
          color="gray.600"
          lineHeight="20px"
          align="center"
          maxWidth={["80%", "70%"]}
        >
          Assign this guide to interviewers. Interviewers will automatically see
          this guide in the interview assistant when interviewing with
          BrightHire.
        </Text>
        <Button mt={6} variant="solid" size="sm" onClick={onEdit}>
          Assign interviewers
        </Button>
      </Flex>
    );
  }

  return (
    <>
      <Flex alignItems="center" justifyContent="flex-end" pb={4}>
        <Button variant="solid" size="sm" onClick={onEdit}>
          Assign interviewers
        </Button>
      </Flex>
      <SortableTable
        data={callGuide.assignedUsers}
        initialSort={{ id: "Name", desc: false }}
        columns={[
          {
            Header: "Name",
            accessor: (cgu: CallGuideUser) => cgu.user?.lastName,
            Cell: ({
              row: { original: cgu },
            }: {
              row: { original: CallGuideUser };
            }) => <Box>{cgu?.user?.fullName}</Box>,
          },
          {
            Header: "Email",
            Cell: ({
              row: { original: cgu },
            }: {
              row: { original: CallGuideUser };
            }) => (
              <RouterLink
                to={useLink({ type: "userInfo", userId: cgu?.user?.id })}
              >
                {cgu?.user?.email}
              </RouterLink>
            ),
          },
          {
            Header: "Date Assigned",
            Cell: ({
              row: { original: cgu },
            }: {
              row: { original: CallGuideUser };
            }) => {
              return (
                <Flex alignItems="center" justifyContent="space-between">
                  <Box>{formatDate(cgu.createdAt)}</Box>
                  <Tooltip label="Unassign Interviewer">
                    <IconButton
                      variant="icon"
                      aria-label="Unassign Interviewer"
                      color="red.400"
                      icon={<HiOutlineXCircle size={20} />}
                      onClick={() => {
                        updateCallGuide({
                          variables: {
                            id: callGuide.id,
                            assignedUserIds: callGuide.assignedUsers
                              .map((cgu) => cgu.userId)
                              .filter((id) => id !== cgu.userId),
                          },
                        });
                      }}
                    />
                  </Tooltip>
                </Flex>
              );
            },
          },
        ]}
        width="100%"
      />
    </>
  );
};

export default AssignedUsers;
