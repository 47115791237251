import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { connectRange } from "react-instantsearch-dom";

import {
  endDateInSeconds,
  reduceToDays,
  secondsToDate,
  startDateInSeconds,
} from "../../utils/datetime";
import { useSendGAEvent } from "../../utils/googleAnalytics";
import { CustomDateInput, DatePicker } from "../DatePicker";

interface DateRangeProps {
  min: number;
  max: number;
  currentRefinement: { min: number; max: number };
  canRefine: boolean;
  refine: (range: { min: number; max: number }) => void;
}

const DateRange: React.FC<DateRangeProps> = ({
  min,
  max,
  currentRefinement,
  canRefine,
  refine,
}) => {
  const sendGAEvent = useSendGAEvent();
  if (min === undefined || min === null || max === undefined || max === null) {
    return null;
  }

  const handleStartDateChange = (startDate: Date): void => {
    sendGAEvent("filter", "search", "date_range");
    refine({
      min: startDate ? Math.max(startDateInSeconds(startDate), min) : min,
      max: currentRefinement.max,
    });
  };

  const handleEndDateChange = (endDate: Date): void => {
    sendGAEvent("filter", "search", "date_range");
    refine({
      min: currentRefinement.min,
      max: endDate ? Math.min(endDateInSeconds(endDate) - 1, max) : max, // 1 MS less than a full day
    });
  };

  return (
    <Flex width="100%" verticalAlign="middle">
      <DatePicker
        selected={reduceToDays(secondsToDate(currentRefinement.min))}
        minDate={reduceToDays(secondsToDate(min))}
        maxDate={reduceToDays(secondsToDate(currentRefinement.max))}
        onChange={handleStartDateChange}
        disabled={!canRefine}
        customInput={<CustomDateInput />}
      />
      <Box m="auto" textAlign="center">
        to
      </Box>
      <DatePicker
        selected={reduceToDays(secondsToDate(currentRefinement.max))}
        minDate={reduceToDays(secondsToDate(currentRefinement.min))}
        maxDate={reduceToDays(secondsToDate(max))}
        onChange={handleEndDateChange}
        disabled={!canRefine}
        customInput={<CustomDateInput />}
      />
    </Flex>
  );
};

export default connectRange(DateRange);
