import { Box, Flex, Heading } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { Card, LoadingIndicator } from "../../../../components";
import { itemAnimation, listAnimation } from "../../../../components/Animation";
import useIntersectionObserver from "../../../../hooks/useIntersectionObserver";
import { getOffsetDateAsISOString } from "../../../../utils/datetime";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import {
  AddBrightHireToInterviewsMutation,
  ScheduledInterviewListItemFragment,
  useCurrentUserScheduledInterviewsQuery,
} from "../../../graphql";
import useInterval from "../../../hooks/useInterval";
import AddBrightHireBanner from "./AddBrightHireBanner";
import { EmptyState } from "./EmptyState";
import InterviewButtonGroup from "./InterviewButtonGroup";
import ScheduledInterviewsListItem from "./ScheduledInterviewsListItem";

const LONG_REFRESH_DELAY = 1000 * 60 * 5; // 5 minutes
const SHORT_REFRESH_DELAY = 1000 * 10; // 10 seconds
const PAGE_LIMIT = 5;

const UpcomingInterviews: React.FC = () => {
  const [bannerLoad, setBannerLoad] = useState<number>(1);
  const [refreshDelay, setRefreshDelay] = useState<number>(LONG_REFRESH_DELAY);
  const [updateInterviewIds, setUpdateInterviewIds] = useState<string[]>([]);
  const [failedInterviewIds, setFailedInterviewIds] = useState<string[]>([]);
  const [date] = useState<string>(getOffsetDateAsISOString({ hours: -1 }));
  const [addedBrightHireResults, setAddedBrightHireResults] = useState<
    AddBrightHireToInterviewsMutation | undefined
  >(undefined);
  const sentinelRef = useRef<HTMLDivElement>(null);
  const sentinelInView = useIntersectionObserver(sentinelRef, {
    rootMargin: "20%",
  });

  const sendGAEvent = useSendGAEvent();
  const onClickLink = (itemType: string): void =>
    sendGAEvent("Link to", "home_page", itemType, "Upcoming (mobile)");

  const { data, loading, error, fetchMore, refetch } =
    useCurrentUserScheduledInterviewsQuery({
      variables: {
        start: date,
        end: null,
        positionId: null,
        pagination: {
          limit: PAGE_LIMIT,
        },
      },
      onError: () => {
        reloadBanner();
      },
      fetchPolicy: "cache-and-network",
    });

  const scheduledInterviews: ScheduledInterviewListItemFragment[] =
    data?.currentUser?.scheduledInterviews?.results || [];
  const pageInfo = data?.currentUser?.scheduledInterviews.pageInfo || {
    hasNextPage: false,
    hasPreviousPage: false,
  };

  useEffect(() => {
    // Deal with page initialization
    if (scheduledInterviews.length > 1) {
      reloadBanner();
    }

    // If all potential updates for visible interviews are resolved, go back
    // to the long delay and reload the banner
    if (refreshDelay === SHORT_REFRESH_DELAY) {
      const vals = scheduledInterviews.map((l) => l.isImported);
      const allImported = (arr: boolean[]): boolean => arr.every(Boolean);
      if (allImported(vals)) {
        setUpdateInterviewIds([]);
        setRefreshDelay(LONG_REFRESH_DELAY);
      }
    }
  }, [scheduledInterviews]);

  useEffect(() => {
    if (addedBrightHireResults?.addBrightHireToInterviews) {
      const res = addedBrightHireResults?.addBrightHireToInterviews;

      // Handle updating interviews
      if (res?.scheduledInterviews?.length) {
        const ids = res.scheduledInterviews
          .map((i) => i.id)
          .concat(updateInterviewIds);
        setUpdateInterviewIds(ids);
        setRefreshDelay(SHORT_REFRESH_DELAY);
      }

      // Handle failed interviews
      if (res?.errors?.length) {
        const ids = res.errors.map((i) => i.id);
        setFailedInterviewIds(ids.concat(failedInterviewIds));
      }

      // Reload the banner
      reloadBanner();
    }
  }, [addedBrightHireResults]);

  /**
   * Force a particular scheduled interview to appear updating
   * @param id Scheduled interview ID
   */
  const showUpdating = (
    id: string
  ): "updating" | "updateFailed" | undefined => {
    const failedInterviewIds =
      addedBrightHireResults?.addBrightHireToInterviews?.errors?.map((e) => {
        return e.id;
      }) ?? [];
    if (updateInterviewIds.includes(id)) {
      return "updating";
    }
    if (failedInterviewIds.includes(id)) {
      return "updateFailed";
    }
    return undefined;
  };

  /**
   * Run when the user wants to load another page of results
   */
  const onLoadMore = (): void => {
    fetchMore({
      variables: {
        start: date,
        end: null,
        positionId: null,
        pagination: {
          limit: scheduledInterviews.length + PAGE_LIMIT,
        },
      },
    });
  };

  useEffect(() => {
    if (sentinelInView && pageInfo.hasNextPage) {
      onLoadMore();
    }
  }, [sentinelInView]);

  /**
   * Polling + fetchMore is a little quirky with the cache,
   * so we use useInterval to implement pollInterval
   */
  const refresh = useCallback(() => {
    refetch({
      pagination: {
        limit: Math.max(scheduledInterviews.length, PAGE_LIMIT),
      },
    });
  }, [scheduledInterviews.length]);

  useInterval(refresh, refreshDelay);

  const reloadBanner = (): void => {
    setBannerLoad(bannerLoad + 1);
  };

  return (
    <Flex direction="column" gap="4" data-testid="upcoming-interviews" mb="12">
      <Box>
        <InterviewButtonGroup />
      </Box>
      <Heading
        lineHeight="7"
        size="md"
        fontWeight="semibold"
        data-testid="homepage-module-title-upcoming-interviews"
        data-tour-id="upcoming"
      >
        Upcoming interviews
      </Heading>

      <Card
        pt={0}
        pb={0}
        px={0}
        variant="transparent"
        maxW="100%"
        overflowX="auto"
      >
        <AddBrightHireBanner
          onAddBrightHire={(results) => setAddedBrightHireResults(results)}
          updateInterviewIds={updateInterviewIds}
          loadCount={bannerLoad}
        />
        {!error && (
          <motion.div
            initial="hidden"
            animate="visible"
            variants={listAnimation}
            key="upcoming-interviews-list"
          >
            {scheduledInterviews.map((scheduledInterview, i) => {
              return (
                <motion.div
                  variants={itemAnimation}
                  data-testid={`upcoming-interviews-row-${i + 1}`}
                  key={`upcoming-interviews-row-${i + 1}`}
                >
                  <ScheduledInterviewsListItem
                    scheduledInterview={scheduledInterview}
                    listPosition={i + 1}
                    key={scheduledInterview.id}
                    onAddBrightHire={(results) =>
                      setAddedBrightHireResults(results)
                    }
                    showUpdating={showUpdating(scheduledInterview.id)}
                    onClickCandidate={() => onClickLink("Candidate")}
                    onClickPosition={() => onClickLink("Position")}
                    onClickInterview={() => onClickLink("Interview")}
                  />
                </motion.div>
              );
            })}
          </motion.div>
        )}
        <Box width="100%" mb="2" ref={sentinelRef} />
        {!loading && !error && pageInfo.hasNextPage === false && (
          <Box my={2} color="gray.500">
            No more items to show
          </Box>
        )}
        {loading && (
          <Flex width="100%" height="100%">
            <LoadingIndicator py={6} />
          </Flex>
        )}
        {!loading && !error && scheduledInterviews.length === 0 && (
          <EmptyState
            heading="Quickly get up to speed on the candidate you'll be meeting with"
            description="You have no upcoming interviews synced from your calendar or ATS."
            section="upcoming"
          />
        )}
        {!loading && error && (
          <EmptyState
            heading="Something went wrong while fetching your upcoming interviews"
            description="Please try and refresh the page"
            section="upcoming"
          />
        )}
      </Card>
    </Flex>
  );
};

export default UpcomingInterviews;
