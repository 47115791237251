import { Flex } from "@chakra-ui/react";
import React from "react";

export const SharedWithMeEmptyState: React.FC<{
  heading: string;
}> = ({ heading }) => {
  return (
    <Flex width="100%" mt="120" alignItems="center" direction="column">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="121"
        viewBox="0 0 204 121"
        fill="none"
      >
        <path
          d="M27.8772 78.9952V80.8691"
          stroke="#D2D8DF"
          strokeOpacity="0.9"
          strokeLinecap="round"
        />
        <path
          d="M23.6685 80.0105L25.1997 81.5868"
          stroke="#D2D8DF"
          strokeOpacity="0.9"
          strokeLinecap="round"
        />
        <path
          d="M21.8777 84.2064H24.0428"
          stroke="#D2D8DF"
          strokeOpacity="0.9"
          strokeLinecap="round"
        />
        <path
          d="M23.4933 88.4773L25.0245 86.901"
          stroke="#D2D8DF"
          strokeOpacity="0.9"
          strokeLinecap="round"
        />
        <path
          d="M27.8772 88.9894V87.1155"
          stroke="#D2D8DF"
          strokeOpacity="0.9"
          strokeLinecap="round"
        />
        <path
          d="M31.0002 87.7401L29.751 86.4908"
          stroke="#D2D8DF"
          strokeOpacity="0.9"
          strokeLinecap="round"
        />
        <path
          d="M32.2495 80.2445L31.0002 81.4938"
          stroke="#D2D8DF"
          strokeOpacity="0.9"
          strokeLinecap="round"
        />
        <path
          d="M33.4988 84.6168H31.6249"
          stroke="#D2D8DF"
          strokeOpacity="0.9"
          strokeLinecap="round"
        />
        <path
          d="M90.698 10.3796V11.6289"
          stroke="#D2D8DF"
          strokeOpacity="0.9"
          strokeLinecap="round"
        />
        <path
          d="M86.9861 14.1541H88.2353"
          stroke="#D2D8DF"
          strokeOpacity="0.9"
          strokeLinecap="round"
        />
        <path
          d="M90.698 17.8751V16.6259"
          stroke="#D2D8DF"
          strokeOpacity="0.9"
          strokeLinecap="round"
        />
        <path
          d="M93.8212 14.1274H92.5719"
          stroke="#D2D8DF"
          strokeOpacity="0.9"
          strokeLinecap="round"
        />
        <path
          d="M18.2413 40.2019V42.0758"
          stroke="#D2D8DF"
          strokeOpacity="0.9"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M12.6198 45.8235H14.4936"
          stroke="#D2D8DF"
          strokeOpacity="0.9"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M18.2413 50.8205V48.9467"
          stroke="#D2D8DF"
          strokeOpacity="0.9"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M23.2384 45.8235H21.3645"
          stroke="#D2D8DF"
          strokeOpacity="0.9"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M143.259 108.31C144.752 108.31 145.962 109.521 145.962 111.014C145.962 112.508 144.752 113.718 143.259 113.718C141.766 113.718 140.555 112.508 140.555 111.014"
          stroke="#D2D8DF"
          strokeOpacity="0.9"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M165.067 83.7778C166.673 83.2771 169.133 81.314 169.703 79.2019C170.219 81.069 172.384 83.2771 174.338 83.4442C172.139 84.2229 170.036 86.536 169.703 88.3533C169.476 86.501 166.568 84.0478 165.067 83.7778Z"
          fill="#EBECEE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.297607 107.477C1.10046 107.227 2.33018 106.246 2.61494 105.19C2.87319 106.123 3.9555 107.227 4.93228 107.311C3.833 107.7 2.78198 108.856 2.61494 109.765C2.50146 108.839 1.04743 107.612 0.297607 107.477Z"
          fill="#EBECEE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1963 20.5867C15.1963 21.7452 14.2577 22.6835 13.1001 22.6835C11.942 22.6835 11.0039 21.7452 11.0039 20.5867C11.0039 19.4288 11.942 18.49 13.1001 18.49C14.2577 18.49 15.1963 19.4288 15.1963 20.5867Z"
          fill="#D1D8DF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M203.702 16.7218C203.702 18.2574 202.458 19.5018 200.924 19.5018C199.388 19.5018 198.144 18.2574 198.144 16.7218C198.144 15.1863 199.388 13.9419 200.924 13.9419C202.458 13.9419 203.702 15.1863 203.702 16.7218Z"
          fill="#EBECEE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54.3724 118.248C54.3724 119.216 53.5881 120 52.6203 120C51.6531 120 50.8688 119.216 50.8688 118.248C50.8688 117.279 51.6531 116.495 52.6203 116.495C53.5881 116.495 54.3724 117.279 54.3724 118.248Z"
          stroke="#D2D8DF"
          strokeOpacity="0.9"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.33 7.11641C53.33 8.27485 52.3914 9.21316 51.2338 9.21316C50.0762 9.21316 49.1376 8.27485 49.1376 7.11641C49.1376 5.9585 50.0762 5.01965 51.2338 5.01965C52.3914 5.01965 53.33 5.9585 53.33 7.11641Z"
          stroke="#D2D8DF"
          strokeOpacity="0.9"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M178.26 117.311C176.92 117.82 175.421 117.147 174.911 115.808C174.402 114.468 175.074 112.968 176.414 112.459C177.753 111.949 179.252 112.622 179.762 113.962C180.272 115.301 179.599 116.801 178.26 117.311Z"
          stroke="#D2D8DF"
          strokeOpacity="0.9"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M138.238 2.90297C138.96 3.01503 139.427 3.66734 139.279 4.35993L137.379 13.2512C137.231 13.9438 136.524 14.4144 135.801 14.3024C135.079 14.1903 134.612 13.538 134.76 12.8454L136.66 3.95413C136.808 3.26153 137.515 2.79092 138.238 2.90297Z"
          fill="#E5E7EA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M144.38 28.212C144.531 27.4138 145.209 26.8733 145.894 27.0048L154.693 28.6924C155.379 28.8239 155.812 29.5775 155.662 30.3757C155.511 31.174 154.833 31.7145 154.148 31.583L145.348 29.8954C144.663 29.7639 144.23 29.0103 144.38 28.212Z"
          fill="#E5E7EA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M120.931 5.92635C121.526 5.50316 122.307 5.64137 122.677 6.23507L127.404 13.8287C127.773 14.4224 127.591 15.2467 126.996 15.6699C126.402 16.0931 125.62 15.9549 125.251 15.3612L120.524 7.76758C120.154 7.17389 120.337 6.34954 120.931 5.92635Z"
          fill="#E5E7EA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M152.694 12.7947C153.063 13.3884 152.881 14.2127 152.286 14.6359L144.683 20.0488C144.089 20.472 143.307 20.3337 142.938 19.7401C142.568 19.1464 142.75 18.322 143.345 17.8988L150.948 12.486C151.542 12.0628 152.324 12.201 152.694 12.7947Z"
          fill="#E5E7EA"
        />
        <rect
          opacity="0.7"
          x="39.7223"
          y="22.6464"
          width="96.2473"
          height="36.8004"
          rx="4"
          fill="#E5E7EA"
        />
        <path
          opacity="0.6"
          d="M54.5578 47.881C58.3873 47.881 61.4918 44.7668 61.4918 40.9253C61.4918 37.0838 58.3873 33.9696 54.5578 33.9696C50.7282 33.9696 47.6238 37.0838 47.6238 40.9253C47.6238 44.7668 50.7282 47.881 54.5578 47.881Z"
          fill="#B8BEC5"
        />
        <path
          opacity="0.47"
          d="M100.713 32.0824H69.3575C68.5758 32.0824 67.9421 32.7161 67.9421 33.4978C67.9421 34.2795 68.5758 34.9132 69.3575 34.9132H100.713C101.495 34.9132 102.128 34.2795 102.128 33.4978C102.128 32.7161 101.495 32.0824 100.713 32.0824Z"
          fill="#B8BEC5"
        />
        <path
          opacity="0.47"
          d="M113.614 39.3617H69.3575C68.5758 39.3617 67.9421 39.9954 67.9421 40.7771C67.9421 41.5588 68.5758 42.1925 69.3575 42.1925H113.614C114.395 42.1925 115.029 41.5588 115.029 40.7771C115.029 39.9954 114.395 39.3617 113.614 39.3617Z"
          fill="#B8BEC5"
        />
        <path
          opacity="0.47"
          d="M97.4879 46.8026H69.3575C68.5758 46.8026 67.9421 47.4363 67.9421 48.218C67.9421 48.9997 68.5758 49.6334 69.3575 49.6334H97.4879C98.2696 49.6334 98.9033 48.9997 98.9033 48.218C98.9033 47.4363 98.2696 46.8026 97.4879 46.8026Z"
          fill="#B8BEC5"
        />
        <rect
          opacity="0.7"
          x="64.2559"
          y="65.1084"
          width="96.2473"
          height="36.8004"
          rx="4"
          fill="#E5E7EA"
        />
        <path
          opacity="0.6"
          d="M79.0915 90.343C82.921 90.343 86.0255 87.2288 86.0255 83.3873C86.0255 79.5458 82.921 76.4316 79.0915 76.4316C75.2619 76.4316 72.1575 79.5458 72.1575 83.3873C72.1575 87.2288 75.2619 90.343 79.0915 90.343Z"
          fill="#B8BEC5"
        />
        <path
          opacity="0.47"
          d="M125.247 74.5444H93.8911C93.1094 74.5444 92.4757 75.1781 92.4757 75.9598C92.4757 76.7415 93.1094 77.3752 93.8911 77.3752H125.247C126.028 77.3752 126.662 76.7415 126.662 75.9598C126.662 75.1781 126.028 74.5444 125.247 74.5444Z"
          fill="#B8BEC5"
        />
        <path
          opacity="0.47"
          d="M138.147 81.8237H93.8911C93.1094 81.8237 92.4757 82.4574 92.4757 83.2391C92.4757 84.0208 93.1094 84.6545 93.8911 84.6545H138.147C138.929 84.6545 139.563 84.0208 139.563 83.2391C139.563 82.4574 138.929 81.8237 138.147 81.8237Z"
          fill="#B8BEC5"
        />
        <path
          opacity="0.47"
          d="M122.021 89.2646H93.8911C93.1094 89.2646 92.4757 89.8983 92.4757 90.68C92.4757 91.4618 93.1094 92.0955 93.8911 92.0955H122.021C122.803 92.0955 123.437 91.4618 123.437 90.68C123.437 89.8983 122.803 89.2646 122.021 89.2646Z"
          fill="#B8BEC5"
        />
      </svg>
      <Flex
        justifyContent="center"
        fontSize="sm"
        fontWeight="500"
        width="100%"
        color="gray.700"
        mt={3}
      >
        {heading}
      </Flex>
    </Flex>
  );
};
