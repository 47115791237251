import {
  Box,
  Flex,
  Grid,
  Heading,
  Stat,
  StatHelpText,
  StatLabel,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";

import {
  LoadingIndicator,
  RouterLink,
  SortableTable,
  useToast,
} from "../../../components";
import { formatRelativeDate } from "../../../utils/datetime";
import {
  AddMembershipButton,
  PositionRoleSelect,
  RemoveUserFromHiringTeamButton,
  UserRoleSelect,
} from "../../components";
import { Membership, useUserQuery } from "../../graphql";
import useLink from "../../hooks/useLink";
import SettingsPageContainer from "../settings/shared/SettingsPageContainer";

type MembershipListItem = Pick<
  Membership,
  "position" | "role" | "user" | "createdAt"
>;

const UserInfoPage: React.FC = () => {
  const toast = useToast();
  const params = useParams() as { id: string };
  const userId = params.id;
  const { data, loading } = useUserQuery({
    variables: {
      id: userId,
    },
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
  });

  if (!data?.user || loading) {
    return <LoadingIndicator mt="25%" />;
  }

  const { user } = data;

  return (
    <SettingsPageContainer heading={user.fullName} maxW="unset">
      <Box>
        <Flex>
          <Heading size="sm" pb={4}>
            Details
          </Heading>
        </Flex>
        <Grid
          maxW="700px"
          gridGap={1}
          gridTemplateColumns="1fr 1fr"
          gridTemplateRows="1fr 1fr"
        >
          <UserStat label="Email" value={user.email} />
          <UserStat
            label="Is Active"
            value={
              user.signUpCompletedAt
                ? `Yes, since ${formatRelativeDate(user.signUpCompletedAt)}`
                : "No"
            }
          />
          <UserStat
            label="Calendar Synced"
            value={user.isCalendarAuthorized ? "Yes" : "No"}
          />
          <UserStat label="Phone Number" value={user.phoneNumber || ""} />
          <UserStat
            label="Invited By"
            value={user.invitedBy?.email ?? "Onboarding"}
          />
          <UserStat
            label="Calling Number"
            value={user.voipPhoneNumber || user.phoneNumber || ""}
          />
          <UserStat
            label="Date Added"
            value={formatRelativeDate(user.createdAt)}
          />
          <Stat>
            <StatLabel>Role</StatLabel>
            <UserRoleSelect
              userId={userId}
              defaultValue={user.userRole?.id}
              showDescription
            />
          </Stat>
        </Grid>
        <Flex alignItems="center" mt="10" mb="3">
          <Heading as="h2" size="sm" mr="4">
            Hiring Teams
          </Heading>
          <AddMembershipButton
            userIds={[userId]}
            filterPositionIds={user.memberships.map((m) => m.position.id)}
          />
        </Flex>
        {user.memberships.length < 1 ? (
          <Text size="sm">Not on any Hiring Teams</Text>
        ) : (
          <SortableTable<Membership>
            columns={[
              {
                Header: "Position",
                accessor: (membership) => membership.position.displayTitle,
                id: "position.displayTitle",
                Cell: ({
                  row: { original: membership },
                }: {
                  row: { original: MembershipListItem };
                }) => {
                  const link = useLink({
                    type: "hiringTeam",
                    positionId: membership.position.id,
                  });
                  return (
                    <RouterLink to={link}>
                      {membership.position.displayTitle}
                    </RouterLink>
                  );
                },
              },
              {
                Header: "Team Role",
                Cell: ({
                  row: { original: membership },
                }: {
                  row: { original: MembershipListItem };
                }) => {
                  return <PositionRoleSelect membership={membership} />;
                },
              },
              {
                Header: "Added",
                accessor: "createdAt",
                Cell: ({
                  row: { original: membership },
                }: {
                  row: { original: MembershipListItem };
                }) => <>{formatRelativeDate(membership.createdAt)}</>,
              },
              {
                Header: "Remove",
                accessor: "updatedAt",
                Cell: ({
                  row: { original: membership },
                }: {
                  row: { original: MembershipListItem };
                }) => {
                  return (
                    <RemoveUserFromHiringTeamButton membership={membership} />
                  );
                },
              },
            ]}
            data={user.memberships as Membership[]}
            initialSort={{ id: "title", desc: false }}
          />
        )}
      </Box>
    </SettingsPageContainer>
  );
};

const UserStat: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => {
  return (
    <Stat>
      <StatLabel>{label}</StatLabel>
      <StatHelpText>{value}</StatHelpText>
    </Stat>
  );
};

export default UserInfoPage;
