import { Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import { RouterLink } from "../../../components";
import { Thumbnail } from "../../components";
import { PlaylistShareListItemFragment } from "../../graphql";
import BaseListItem from "../home/Interviews/BaseListItem";

interface PlaylistListItemProps {
  share: PlaylistShareListItemFragment;
  displayDate?: string;
  size?: "auto" | "sm" | "md";
  onPlaylistLinkClick?(): void;
}

export const PlaylistListItem: React.FC<PlaylistListItemProps> = ({
  share,
  displayDate,
  size = "md",
  onPlaylistLinkClick,
}) => {
  const navigate = useNavigate();
  const { relativeUrl, firstClip } = share;
  const playlistName = share.playlist.title.length
    ? share.playlist.title
    : "Playlist";

  const playlistInfo = (
    <Flex direction="column" mr="6" minW={0} overflow="hidden" gap="1.5">
      <Heading
        as="h6"
        size="sm"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
        lineHeight="6"
      >
        <RouterLink
          to={relativeUrl}
          color={{ base: "blue.600", lg: "gray.800" }}
          _hover={{ textDecoration: "underline", color: "inherit" }}
          onClick={onPlaylistLinkClick}
        >
          {playlistName}
        </RouterLink>
      </Heading>
      <Text
        fontSize="sm"
        color="gray.600"
        fontWeight="medium"
        lineHeight="5"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
      >
        Created by {share.playlist.createdBy.fullName}
      </Text>
      <Text
        fontSize="sm"
        color="gray.500"
        fontWeight="medium"
        lineHeight="5"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
      >
        {displayDate}
      </Text>
    </Flex>
  );
  return (
    <BaseListItem
      leftCol={
        <Flex flex="1" flexFlow="row nowrap">
          <Thumbnail
            size={size}
            isPlayable
            imageUrl={firstClip.thumbnailImageUrl}
            audioOnly={!firstClip.thumbnailImageUrl}
            time={firstClip.duration}
            isClip
            mr={5}
            onClick={() => {
              navigate(relativeUrl);
              onPlaylistLinkClick?.();
            }}
          />
          {playlistInfo}
        </Flex>
      }
      mobile={playlistInfo}
    />
  );
};
