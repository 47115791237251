import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

import { CallbackRef, MediaListeners } from "../Interview/useMediaPlayer";
import { VideoCallbackRef } from "./useVideoState";

export type VideoFullScreenControls = {
  isFullscreenSupported: boolean;
  isFullscreen: boolean;
  toggleFullscreen: (() => Promise<void> | undefined) | undefined;
};

type VideoPlayerBetaProps = {
  height?: string | number;
  src?: string;
  mediaPlayerRef: CallbackRef;
  videoRef: VideoCallbackRef;
  isVideoVisible: boolean;
  onClick?: BoxProps["onClick"];
  containerStyle?: BoxProps;
  preload?: "none" | "metadata" | "auto";
} & MediaListeners;

const VideoPlayerBeta: React.FC<VideoPlayerBetaProps> = ({
  src,
  height,
  mediaPlayerRef,
  videoRef,
  isVideoVisible,
  onClick,
  containerStyle,
  preload,
  ...listeners
}) => {
  return (
    <Box
      bg="black"
      width="100%"
      position="relative"
      hidden={!isVideoVisible}
      onClick={onClick}
      height={height}
      {...containerStyle}
    >
      <video
        onContextMenu={(e) => e.preventDefault()}
        disableRemotePlayback
        ref={(element: any) => {
          mediaPlayerRef(element);
          videoRef(element);
        }}
        playsInline
        controlsList="nodownload"
        preload={preload}
        src={src}
        {...listeners}
        style={{
          display: "block",
          height,
          margin: "auto",
        }}
      />
    </Box>
  );
};

export default VideoPlayerBeta;
