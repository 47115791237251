import { Box, BoxProps, Flex } from "@chakra-ui/react";
import React, { useMemo } from "react";

import { secondsToHHMMSS } from "../../../utils/media";

export type VideoTimeProps = BoxProps & {
  time: number;
  duration: number | undefined;
};

const VideoTime: React.FC<VideoTimeProps> = ({ time, duration, ...styles }) => {
  const timeStr = useMemo(() => secondsToHHMMSS(time), [Math.floor(time)]);
  const durationStr = useMemo(() => secondsToHHMMSS(duration), [duration]);

  return (
    <Flex color="white" fontSize="sm" fontWeight="medium" {...styles}>
      {duration && (
        <>
          <Box>{timeStr}</Box>
          &nbsp;/&nbsp;
        </>
      )}
      <Box>{durationStr}</Box>
    </Flex>
  );
};

export default VideoTime;
