import { ApolloError } from "@apollo/client";
import { Box, Flex, Grid, Icon, Text, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { HiOutlineFunnel } from "react-icons/hi2";

import { Alert, LoadingIndicator } from "../../../components";
import { useIsSmallScreen } from "../../../hooks/useIsSmallScreen";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import {
  EmptyHighlightFilteredState,
  EmptyHighlightUnfilteredState,
} from "./EmptyState";
import Highlight from "./Highlight";
import HighlightsFilters, { HighlightsFiltersProps } from "./HighlightsFilters";
import HighlightsFiltersMobile from "./HighlightsFiltersMobile";
import { HighlightModal } from "./useHighlightModal";
import { hasError } from "./utils";

export interface HighlightsTabProps {
  filterState: HighlightsFiltersProps;
  totalHighlights: number;
  loading?: boolean;
  error?: ApolloError;
  setActiveHighlight: HighlightModal["setActiveHighlight"];
  smartFiltersGenerating: boolean;
}

const HighlightsTab: React.FC<HighlightsTabProps> = ({
  filterState,
  totalHighlights,
  loading = false,
  error,
  setActiveHighlight,
  smartFiltersGenerating,
}) => {
  const isSmallScreen = useIsSmallScreen();
  const isDesktop = !isSmallScreen;
  const { layoutHeight } = useWindowDimensions();

  const { matchingHighlights: highlights } = filterState;
  const numHighlights = highlights.length;
  const numHighlightsText = `Showing ${numHighlights} of ${totalHighlights} highlight${
    totalHighlights !== 1 ? "s" : ""
  }`;

  const emptyState = filterState.hasActiveFilters() ? (
    <EmptyHighlightFilteredState />
  ) : (
    <EmptyHighlightUnfilteredState />
  );

  const { onOpen: showMobileFilters, ...mobileFilters } = useDisclosure();

  return (
    <Flex>
      {isDesktop && (
        <HighlightsFilters
          {...filterState}
          smartFiltersGenerating={smartFiltersGenerating}
          maxW={270}
          minW={250}
          pt="8"
          pr="6"
          mr="2"
          alignSelf="start"
          position="sticky"
          top="8"
          // MDN cites this as a performance + accessibility improvement for `position: sticky;` elements:
          // https://developer.mozilla.org/en-US/docs/Web/CSS/position#performance_accessibility
          willChange="transform"
          overflowY="auto"
          maxH={layoutHeight}
          data-tour-id="candidate-page-highlights-filters"
        />
      )}

      <Box flex="1" py={{ base: "6", lg: "8" }}>
        <Box>
          {isDesktop && (
            <Text fontSize="2xl" fontWeight="medium" color="gray.600" mb="6">
              {numHighlightsText}
            </Text>
          )}

          {isSmallScreen && (
            <Flex mb="8">
              <Text fontSize="sm" fontWeight="semibold" color="gray.800">
                {numHighlightsText}
              </Text>

              <Icon
                as={HiOutlineFunnel}
                boxSize="6"
                color="gray.500"
                ml="auto"
                onClick={showMobileFilters}
              />
            </Flex>
          )}
        </Box>

        {loading && <LoadingIndicator h="auto" mt="20vh" />}

        {!loading && error && (
          <Alert
            status="error"
            description={
              hasError(error, "FORBIDDEN")
                ? "You don't have permission to view this highlight"
                : hasError(error, "NOT_FOUND")
                ? "We can't find the page you're looking for"
                : "Error loading highlight"
            }
          />
        )}

        {!loading &&
          !error &&
          (numHighlights === 0 ? (
            emptyState
          ) : (
            <Grid
              gap={{ base: "8", sm: "6" }}
              templateColumns={{
                base: "1fr",
                sm: `repeat(2, 1fr)`,
                md: `repeat(3, 1fr)`,
                lg: `repeat(2, 1fr)`,
                xl: `repeat(3, 1fr)`,
                "2xl": `repeat(4, 1fr)`,
              }}
            >
              {highlights.map((highlight, idx) => (
                <Highlight
                  key={highlight.id}
                  highlight={highlight}
                  onClick={() =>
                    setActiveHighlight(highlight.id, "highlights_tab")
                  }
                  data-tour-id={`candidate-page-highlight-${idx}`}
                />
              ))}
            </Grid>
          ))}
      </Box>

      {isSmallScreen && (
        <HighlightsFiltersMobile
          {...mobileFilters}
          {...filterState}
          smartFiltersGenerating={smartFiltersGenerating}
        />
      )}
    </Flex>
  );
};

export default HighlightsTab;
