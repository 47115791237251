import { ApolloError, MutationTuple } from "@apollo/client";
import { DataProxy } from "@apollo/client/cache/core/types/DataProxy";

import {
  DeleteCallGuideMutation,
  DeleteCallGuideMutationVariables,
  useDeleteCallGuideMutation,
} from "../..";
import {
  removeFromCurrentUserQuery,
  removeFromCurrentUserSharedCallGuidesQuery,
  removeFromPositionQuery,
  removeFromSharedCallGuidesQuery,
} from "./utils";

export default function useDeleteCallGuide({
  onCompleted,
  onError,
}: {
  onCompleted?: (data: DeleteCallGuideMutation) => void;
  onError?: (error: ApolloError) => void;
}): [
  MutationTuple<DeleteCallGuideMutation, DeleteCallGuideMutationVariables>[0],
  { loading: boolean; error: ApolloError | undefined }
] {
  const [deleteCallGuide, { error, loading }] = useDeleteCallGuideMutation({
    onCompleted,
    onError,
    update(cache: DataProxy, { data: deleteCallGuideData }) {
      const deletedCallGuide = deleteCallGuideData?.deleteCallGuide?.callGuide;
      if (deletedCallGuide) {
        removeFromCurrentUserQuery(cache, deletedCallGuide.id);
        removeFromSharedCallGuidesQuery(cache, deletedCallGuide.id);
        removeFromCurrentUserSharedCallGuidesQuery(cache, deletedCallGuide.id);
        if (deletedCallGuide.positionId) {
          removeFromPositionQuery(
            cache,
            deletedCallGuide.id,
            deletedCallGuide.positionId
          );
        }
      }
    },
  });

  return [deleteCallGuide, { loading, error }];
}
