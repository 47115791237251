import config from "../config";

// Do not register service workers for test env as this conflicts with
// cypress-audit/lighthouse testing
// See https://github.com/mfrachet/cypress-audit/issues/39
export const registerServiceWorker = (): void => {
  if (
    "serviceWorker" in navigator &&
    "register" in navigator.serviceWorker &&
    ["production", "staging", "development"].includes(config.appEnv)
  ) {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register("/static/dist/sw.js", {
          scope: "/",
        })
        .then(() => {
          // eslint-disable-next-line no-console
          console.info("Service worker registered.");
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error("Error during service worker registration:", error);
        });
    });
  }
};

export const unregister = (): void => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  }
};
