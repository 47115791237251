import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  TabsProps,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { useIsSmallScreen } from "../../../../hooks/useIsSmallScreen";
import { asArray } from "../../../../utils/array";

interface TabContentProps<T extends string> {
  id: T;
  displayName: JSX.Element | string;
}

export const TabContent = <T extends string, C extends JSX.Element>({
  children,
}: TabContentProps<T> & { children: C }): C => {
  return children;
};

type TabContent<T extends string> = React.ReactElement<TabContentProps<T>>;
type Child<T extends string> =
  | TabContent<T>
  | string
  | number
  | boolean
  | null
  | undefined;

interface TabsLayoutProps<T extends string>
  extends Omit<TabsProps, "index" | "onChange" | "defaultIndex"> {
  currentTab?: T;
  onTabChange?(tab: T): void;
  children: Child<T>[] | Child<T>;
  tabRightChildren?: React.ReactNode;
}

const TabsLayout = <T extends string>({
  currentTab,
  onTabChange,
  isLazy = true,
  lazyBehavior = "keepMounted",
  children,
  tabRightChildren,
  ...tabsProps
}: TabsLayoutProps<T>): JSX.Element => {
  const { tabs, tabIds, tabNames } = useTabData(children);
  const isSmallScreen = useIsSmallScreen();

  const [tabIndex, setTabIndexState] = useState(0);
  const setTabIndex = (idx: number): void => {
    if (idx !== tabIndex) {
      setTabIndexState(idx);
      onTabChange?.(tabIds[idx]);
    }
  };

  // Set tab based on the `currentTab` prop
  useEffect(() => {
    if (currentTab) {
      const idx = tabIds.findIndex((tabId) => tabId === currentTab);
      if (idx !== -1) {
        setTabIndex(idx);
      }
    }
  }, [currentTab, tabIds]);

  return (
    <Tabs
      display="flex"
      flexDir="column"
      height="100%"
      width="100%"
      index={tabIndex}
      onChange={setTabIndex}
      isLazy={isLazy}
      lazyBehavior={lazyBehavior}
      {...tabsProps}
    >
      <Flex
        alignItems="center"
        direction={isSmallScreen ? "column" : "row"}
        mb="4"
      >
        <Box flex="1" />
        <TabList
          bg="white"
          borderRadius="base"
          display="flex"
          height="40px"
          justifyContent="center"
          overflow="hidden"
          alignItems="center"
          borderBottom="none"
          flex="1"
          mx={isSmallScreen ? "4" : "0"}
          width={isSmallScreen ? "100%" : "auto"}
        >
          {tabNames.map((tabName, idx) => (
            <Tab
              _first={{
                borderLeft: "1px solid",
                borderTopLeftRadius: "base",
                borderBottomLeftRadius: "base",
              }}
              _hover={{
                color: "blue.600",
                backgroundColor: "blue.100",
              }}
              _last={{
                borderTopRightRadius: "base",
                borderBottomRightRadius: "base",
              }}
              _selected={{
                background: "gray.50",
                color: "gray.800 !important",
                fontWeight: "600",
                boxShadow: "sm",
              }}
              background="white"
              color="blue.600 "
              border="1px solid"
              borderLeft="none"
              borderColor="gray.100 !important"
              borderRadius="none"
              data-testid={`${tabIds[idx]}-tab`}
              h="40px"
              key={tabIds[idx]}
              p="8px 3px"
              w="176px"
              flexGrow={isSmallScreen ? "1" : "0"}
              fontWeight="500"
              mb={isSmallScreen && tabRightChildren ? "3" : "0"}
            >
              <Box fontSize="14px" lineHeight="20px">
                {tabName}
              </Box>
            </Tab>
          ))}
        </TabList>
        <Flex
          flex="1"
          justifyContent="flex-end"
          width={isSmallScreen ? "100%" : "auto"}
        >
          {tabRightChildren || null}
        </Flex>
      </Flex>

      <TabPanels overflowY="auto" flex="1">
        {tabs.map((tab, idx) => (
          <TabPanel key={tabIds[idx]} h="100%">
            {tab}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default TabsLayout;

/**
 * Filter out falsy children and return tabs along with names and ids
 */
function useTabData<T extends string>(
  children: Child<T>[] | Child<T>
): { tabs: TabContent<T>[]; tabIds: T[]; tabNames: (string | JSX.Element)[] } {
  const tabs = asArray(children).filter((c) => !!c) as TabContent<T>[];

  const [tabIds, tabNames] = tabs.reduce(
    ([ids, names], tab) => [
      ids.concat(tab.props.id),
      names.concat(tab.props.displayName),
    ],
    [[], []] as [T[], (JSX.Element | string)[]]
  );

  return { tabs, tabIds, tabNames };
}
