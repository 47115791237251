import { ApolloError } from "@apollo/client";
import { Box, Divider, Text } from "@chakra-ui/react";
import React from "react";

import { Alert, LoadingIndicator, useTheme } from "../../../../components";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import {
  CallSpeakerFragment,
  TranscriptSegmentFragment,
} from "../../../graphql";
import ClearFilter from "../TrackerClearFilter";
import TrackerKeyword from "../TrackerKeyword";
import { TrackerMatch } from "../TrackerMatch";
import { TrackerTabState } from "../types";

type TrackerSegmentData = {
  keyword?: string | null;
  segments: TranscriptSegmentFragment[];
};

export type TrackerSegmentViewProps = {
  trackerSegments?: TrackerSegmentData[] | null;
  speakers?: CallSpeakerFragment[] | null;
  speakerMenuDisabled: boolean;
  trackerTabState: TrackerTabState;
  setTrackerTabState: (trackerTabState: TrackerTabState | undefined) => void;
  seek: (time: number) => void;
  clipId?: string;
  loading?: boolean;
  error?: ApolloError | null;
};

const TrackerSegmentView: React.FC<TrackerSegmentViewProps> = ({
  trackerSegments,
  speakers,
  speakerMenuDisabled,
  trackerTabState,
  setTrackerTabState,
  seek,
  clipId,
  loading,
  error,
}) => {
  const { colors } = useTheme();
  const sendGAEvent = useSendGAEvent();

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return (
      <Box>
        <Alert
          status="error"
          title="Error loading trackers"
          description={error.message}
          reload
        />
      </Box>
    );
  }

  if (!trackerSegments || !speakers) {
    return null;
  }

  const { selectedTracker } = trackerTabState;

  const numResults = trackerSegments.reduce((prev, curr) => {
    return prev + curr.segments.length;
  }, 0);

  const segmentsWithKeyword = trackerSegments
    .flatMap(
      (trackerSegment) =>
        trackerSegment.segments.map((s) => ({
          segment: s,
          keyword: trackerSegment.keyword || "",
        })) || []
    )
    .filter(
      (s) =>
        selectedTracker.speakerTag === undefined ||
        s.segment.speakerTag === selectedTracker.speakerTag
    )
    .sort(
      (a, b) => a.segment.words[0].startTime - b.segment.words[0].startTime
    );
  return (
    <>
      <Box px="4">
        <Box pb="2" data-testid="tracker-segment-view-result-count">
          <Box cursor="pointer" display="inline-block">
            <Text fontWeight="bold" display="inline" fontSize="sm">
              {numResults} result{numResults !== 1 ? "s" : ""} found for{" "}
            </Text>
            <Text
              fontWeight="bold"
              display="inline"
              fontSize="sm"
              padding="0 0 1px"
              borderBottom={`2px solid ${colors.blue[300]}`}
            >
              {selectedTracker.group.label}
            </Text>
          </Box>
        </Box>
        {trackerTabState.labeledTrackerTabGroup?.name === "Questions" &&
          trackerTabState?.filteredKeywords &&
          trackerTabState?.filteredKeywords?.length > 0 &&
          trackerTabState.filteredKeywords.map((kw) => {
            return (
              kw.speakerTag !== undefined && (
                <TrackerKeyword
                  // Because we split keywords, add speakerTag for unique key
                  key={`${kw?.id || ""}-${kw?.speakerTag || ""}`}
                  trackerKeyword={kw}
                  trackerGroup={trackerTabState.labeledTrackerTabGroup}
                  setTrackerTabState={setTrackerTabState}
                  trackerTabState={trackerTabState}
                  isActiveSpeaker={
                    kw.speakerTag ===
                    trackerTabState?.selectedTracker?.speakerTag
                  }
                />
              )
            );
          })}
        <ClearFilter
          setTrackerTabState={setTrackerTabState}
          trackerTabState={trackerTabState}
        />
      </Box>
      <Box
        data-testid="tracker-segment-view"
        display="flex"
        flexDirection="column"
      >
        <Box overflow="auto">
          {segmentsWithKeyword.map((segmentWithKeyword, index) => {
            const { segment, keyword } = segmentWithKeyword;
            if (!keyword) {
              return null;
            }
            const speaker = speakers[segment.speakerTag - 1];
            if (!speaker) {
              return null;
            }
            return (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={`${segment.id}-${keyword}-${index}`}>
                <TrackerMatch
                  segment={segment}
                  keyword={keyword}
                  seek={(time: number) => {
                    seek(time);
                    sendGAEvent(
                      "tracker_seek",
                      "call_review",
                      selectedTracker.group.name,
                      keyword
                    );
                  }}
                  speaker={speaker}
                  speakerMenuDisabled={speakerMenuDisabled}
                  clipId={clipId}
                />
                {index !== segmentsWithKeyword.length - 1 && (
                  <Box height="1px" background="" px="4">
                    <Divider color="gray.100" />
                  </Box>
                )}
              </React.Fragment>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default TrackerSegmentView;
