import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

import { useUpdateClientMutation } from "../../graphql";
import ClientForm, { Client } from "./ClientForm";

interface EditClientModalProps {
  client: Client;
  isOpen: boolean;
  onSave: (client: Client) => void;
  onClose: () => void;
}

const EditClientModal: React.FC<EditClientModalProps> = ({
  client,
  isOpen,
  onSave,
  onClose,
}) => {
  const [updateClient, { loading: updateLoading, error: updateError }] =
    useUpdateClientMutation({
      onCompleted: ({ updateClient }) => {
        if (updateClient?.client) {
          onSave(updateClient.client);
        }
      },
    });

  const handleSubmit = async (formData: { name: string }): Promise<void> => {
    if (client) {
      await updateClient({ variables: { id: client.id, ...formData } });
    }
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Edit position group</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ClientForm
              name={client?.name}
              error={updateError}
              isLoading={updateLoading}
              onSubmit={handleSubmit}
              onCancel={onClose}
            />
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default EditClientModal;
