import React from "react";
import {
  Bar,
  BarChart as RCBarChart,
  CartesianGrid,
  CartesianGridProps,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from "recharts";

import { useTheme } from "..";

interface BarChartProps {
  barChartProps: React.ComponentProps<typeof RCBarChart>;
  categories: string[];
  cartesianGridProps?: CartesianGridProps & { ref: undefined };
  xAxisProps?: XAxisProps;
  yAxisProps?: YAxisProps;
}

const BarChart: React.FC<BarChartProps> = ({
  barChartProps,
  categories,
  cartesianGridProps,
  xAxisProps,
  yAxisProps,
}) => {
  const theme = useTheme();
  const { colors } = theme;

  const selectColorByKey = (dataKey: string): string => {
    switch (dataKey) {
      case "candidate":
        return colors.blue[500];
      case "interviewer":
        return colors.cyan[400];
      default:
        return colors.gray[500];
    }
  };

  return (
    <RCBarChart
      width={550}
      height={300}
      margin={{ top: 5, right: 20, left: 20, bottom: 5 }}
      {...barChartProps}
    >
      <CartesianGrid strokeDasharray="3 3" {...cartesianGridProps} />
      <XAxis dataKey="date" stroke={colors.gray[500]} {...xAxisProps} />
      <YAxis allowDecimals={false} stroke={colors.gray[500]} {...yAxisProps} />
      <Tooltip />
      {categories.map((category) => {
        return (
          <Bar
            dataKey={category}
            key={category}
            stackId="a"
            fill={selectColorByKey(category)}
          />
        );
      })}
    </RCBarChart>
  );
};

export default BarChart;
