import { Box, BoxProps, Heading } from "@chakra-ui/react";
import React from "react";

import { EditableText } from "..";

type PageTitleProps = {
  titleText: string;
  secondaryText?: string;
  editable?: boolean;
  onTitleSubmit?: (newValue: string) => void;
} & BoxProps;

/**
 * Page title with optional editability
 */
const PageTitle: React.FC<PageTitleProps> = ({
  titleText,
  secondaryText,
  editable,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTitleSubmit = () => {},
  ...boxProps
}) => {
  return (
    <Box mt={[2, 2, 0]} mb={[8, 8, 10]} mr={2} {...boxProps}>
      {secondaryText && (
        <Heading as="h3" size="xs" color="gray.500" mb={2}>
          {secondaryText}
        </Heading>
      )}
      <Heading as="h1" fontSize="24px">
        <EditableText
          defaultValue={titleText}
          onSubmit={onTitleSubmit}
          maxLength={100}
          isDisabled={!editable}
          showIcon="hover"
          width="100%"
        />
      </Heading>
    </Box>
  );
};

export default PageTitle;
