import { errorToast, useToast } from "../../../../../components";
import { useSendGAEvent } from "../../../../../utils/googleAnalytics";
import {
  AddNewCallNoteMutation,
  CallNoteFragment,
  CallNoteType,
  CallNoteVisibility,
  ClipNotesQuery,
  useClipNotesQuery,
} from "../../../../graphql";
import useAddCallNote from "../../../../graphql/hooks/useAddCallNote";
import { NotesTabProps } from "../../../Recording/SidebarTabs/NotesTab";

type ClipNotesParams = {
  clipId: string;
  callId: string;
  onCallNotesQueryCompleted?: (data: ClipNotesQuery) => void;
  onAddCallNoteCompleted?: (data: AddNewCallNoteMutation) => void;
};

type ClipNotesReturn = Pick<
  NotesTabProps,
  "questions" | "generalNotes" | "onAddNote" | "error"
> & {
  notes: CallNoteFragment[];
};

const useClipNotes = ({
  callId,
  clipId,
  onCallNotesQueryCompleted,
  onAddCallNoteCompleted,
}: ClipNotesParams): ClipNotesReturn => {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();

  const { data, error } = useClipNotesQuery({
    variables: { clipId },
    fetchPolicy: "cache-first",
    onCompleted: onCallNotesQueryCompleted,
  });
  const questions = data?.clip?.questions ?? [];
  const generalNotes = data?.clip?.generalNotes ?? [];
  const allNotes = questions.reduce(
    (acc, q) => acc.concat(q.questionNotes),
    generalNotes
  );

  const [addNote] = useAddCallNote({
    call: { id: callId, __typename: "Call" },
    clipId,
    onCompleted: onAddCallNoteCompleted,
    onError() {
      errorToast(toast, "There was a problem adding your note");
    },
  });

  return {
    generalNotes,
    questions,
    notes: allNotes,
    error,

    onAddNote: async ({ text, type, time, questionId }) => {
      if (text.trim() === "" && type === CallNoteType.Note) {
        return;
      }
      sendGAEvent("call_note_added", "call_review");
      await addNote({
        variables: {
          callId,
          clipId,
          text,
          type,
          time,
          questionId,
          visibility: CallNoteVisibility.Public,
        },
      });
    },
  };
};

export default useClipNotes;
