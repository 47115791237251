import { pluck } from "../../../../utils/array";
import {
  CandidatePositionScorecardFragment,
  ScorecardItem,
} from "../../../graphql";

type InterviewerScores = {
  id: any;
  fullName: string;
  scorecards: Omit<CandidatePositionScorecardFragment, "interviewer">[];
};

type CompetencyScores = {
  id: any;
  itemText: string;
  scorecards: Array<
    Pick<CandidatePositionScorecardFragment, "interviewer"> &
      Pick<ScorecardItem, "score" | "notes">
  >;
  averageScore?: number;
};

type AverageScoresReturn = {
  /** The overall interview scores with nulls filtered out */
  scores: number[];
  /** Minimum overall interview score */
  minScore: number;
  /** Maximum overall interview score */
  maxScore: number;
  /** Average overall interview score */
  averageInterviewScore: string;
  /** Scores grouped by interviewer */
  interviewers: InterviewerScores[];
  /** Scores grouped by competency */
  competencies: CompetencyScores[];
  /** Average score for each competency */
  averageCompetencyScores: number[];
  /** Minimum score for competencies */
  minCompetencyScore: number;
  /** Maximum score for competencies */
  maxCompetencyScore: number;
};

/**
 * Groups candidate position scorecards by interviewer and by competency,
 * and returns min, max, and average on those.
 */
const useAverageScores = (
  scorecards: CandidatePositionScorecardFragment[]
): AverageScoresReturn => {
  const interviewers: InterviewerScores[] = [];
  const competencies: CompetencyScores[] = [];

  scorecards.forEach(({ interviewer, ...scorecard }) => {
    // Gather scores by interviewer
    let idx = interviewers.findIndex(({ id }) => id === interviewer.id);
    if (idx === -1) {
      interviewers.push({ ...interviewer, scorecards: [] });
      idx = interviewers.length - 1;
    }
    interviewers[idx].scorecards.push(scorecard);

    // Gather scores by competency
    scorecard.competencies.forEach((competency) => {
      idx = competencies.findIndex((c) => c.itemText === competency.itemText);
      const { id, itemText, score, notes } = competency;
      if (idx === -1) {
        competencies.push({ id, itemText, scorecards: [] });
        idx = competencies.length - 1;
      }
      competencies[idx].scorecards.push({ interviewer, score, notes });
    });
  });

  // Get average score for each competency
  for (let i = 0; i < competencies.length; i += 1) {
    const scores = getNonEmpty(pluck(competencies[i].scorecards, "score"));
    if (scores.length > 0) {
      competencies[i].averageScore = getAverage(scores);
    }
  }
  const averageCompetencyScores = getNonEmpty(
    pluck(competencies, "averageScore")
  );

  const scorecardsWithScores = scorecards.filter(
    (s) => s.overallScoringEnabled && !!s.overallScore
  );
  const scores = getNonEmpty(pluck(scorecardsWithScores, "overallScore"));

  return {
    scores,
    minScore: Math.min(...scores),
    maxScore: Math.max(...scores),
    averageInterviewScore:
      scores.length > 0 ? getAverage(scores).toFixed(1) : "",

    interviewers,
    competencies,

    averageCompetencyScores,
    minCompetencyScore: Math.min(...averageCompetencyScores),
    maxCompetencyScore: Math.max(...averageCompetencyScores),
  };
};

export default useAverageScores;

const getNonEmpty = (arr: Array<number | null | undefined>): number[] =>
  arr.filter((s) => !!s) as number[];

const getAverage = (arr: number[]): number =>
  arr.reduce((total, val) => val + total) / arr.length;
