import React from "react";

import { useToast } from "../../../components";
import {
  OrganizationFragment,
  useUpdateOrganizationTeamsNotetakerMutation,
} from "../../graphql";
import MsftTeamsSettingsForm from "./MsftTeamsSettingsForm";
import SettingsPageContainer from "./shared/SettingsPageContainer";

interface TeamsNotetakerSettingsProps {
  organization: OrganizationFragment;
}

const MsftTeamsSettings: React.FC<TeamsNotetakerSettingsProps> = ({
  organization,
}) => {
  const toast = useToast();

  const [updateTeamsNotetakerSettings, { loading, error }] =
    useUpdateOrganizationTeamsNotetakerMutation({
      onCompleted: (data) => {
        if (data?.updateOrganizationTeamsNotetaker?.organization) {
          toast({
            status: "success",
            title: "Microsoft Teams Notetaker Settings",
            description: "Saved!",
          });
        }
      },
      onError: () => {
        toast({
          status: "error",
          title: "Microsoft Teams Notetaker Settings",
          description:
            error?.message ?? "There was a problem - please try again",
        });
      },
    });

  return (
    <SettingsPageContainer heading="Microsoft Teams notetaker">
      <MsftTeamsSettingsForm
        teamsNotetakerEnabled={organization.teamsNotetakerEnabled}
        teamsNotetakerDisclaimer={organization.teamsNotetakerDisclaimer}
        teamsNotetakerDisclaimerType={organization.teamsNotetakerDisclaimerType}
        defaultDisclaimer={organization.notetakerRecorderDisclaimerDefault}
        showVirtualOnsiteSetting={organization.showVirtualOnsiteSettingMsTeams}
        virtualOnsiteEnabled={organization.virtualOnsiteEnabledMsTeams}
        isLoading={loading}
        error={error}
        onSubmit={(formData) => {
          updateTeamsNotetakerSettings({ variables: formData });
        }}
      />
    </SettingsPageContainer>
  );
};

export default MsftTeamsSettings;
