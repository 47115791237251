import { Box, Flex } from "@chakra-ui/react";
import React from "react";

import { useTheme } from "../../../components";

interface WizardFooterType {
  children: React.ReactNode;
}

const WizardFooter: React.FC<WizardFooterType> = ({ children }) => {
  const theme = useTheme();

  return (
    <Flex
      marginTop="auto"
      height="80px"
      borderTopWidth="1px"
      borderTopColor="gray.100"
      alignItems="center"
      px="10"
      flexShrink={0}
      boxShadow={`0px -4px 32px ${theme.colors.gray[50]}`}
      position="sticky"
      bottom="0"
      backgroundColor={theme.colors.white}
    >
      <Box display="block" width="100%" maxWidth="1012px" ml="auto" mr="auto">
        {children}
      </Box>
    </Flex>
  );
};

export default WizardFooter;
