import {
  Box,
  Flex,
  IconButton,
  Text,
  Tooltip as ChakraTooltip,
  TooltipProps,
} from "@chakra-ui/react";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { HiOutlineInformationCircle } from "react-icons/hi";
import {
  Bar,
  BarChart,
  Cell,
  Customized,
  Label,
  Legend,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useTheme } from "../../../../components";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { stringToTitleCase } from "../../../../utils/string";
import { AnalyticsDimension, MetricName } from "../../../graphql";
import { calculateAverage } from "../averages";
import { integerFormats, MetricConfig, ValueFormat } from "../MetricConfig";
import { ChartSort, DataPoint, PivotedDataPoint } from "../types";
import { SingleSelect } from "../useAnalyticsConfig";
import { MetricBenchmarkRange } from "../useAnalyticsData";
import { formatValue } from "../utils";
import { transformData } from "./pivot";
import {
  segmentLabels,
  SegmentName,
  themedSegmentColors,
  themedSegmentColorsFocused,
} from "./segmentPresentation";
import TooltipContent from "./TooltipContent";

// recharts does not accept rem units for radius, so hardcode to radii.sm
const barBorderRadius = 2;

export type MetricChartProps = {
  limit: number;
  metric: MetricName;
  primaryDimension: SingleSelect<AnalyticsDimension>;
  secondaryDimension: AnalyticsDimension;
  sort: ChartSort;
  data: DataPoint[];
  performanceOverlay?: boolean;
  drilldownEnabled?: boolean;
  onDrilldown: (entry: PivotedDataPoint) => void;
  benchmarkRange?: MetricBenchmarkRange;
};

type AxisLabel = {
  value: string;
  offset: number;
  coordinate: number;
  index: number;
};

// Neither of these colors fit within our color scales, and are only used
// within analytics because of special needs for colors.
const avgColor = "#BD7EBE";

const spaceBetweenXLabels = 12;
const barSize = 8;
const verticalAxisPaddingTop = 20;
const verticalAxisPaddingBottom = 20;

const MetricChart: React.FC<MetricChartProps> = ({
  metric,
  limit,
  sort,
  primaryDimension,
  secondaryDimension,
  data,
  performanceOverlay,
  drilldownEnabled,
  onDrilldown,
  benchmarkRange,
}) => {
  const { valueFormat, avgValueFormat, countMetric } = MetricConfig[metric];
  const theme = useTheme();
  const { colors, fontSizes } = theme;
  const segmentColorsFocused = themedSegmentColorsFocused(colors);
  const sendGAEvent = useSendGAEvent();

  useEffect(
    () => sendGAEvent("metric_chart_loaded", "analytics", metric),
    [data]
  );

  // Recharts doesn't have a way to style fill color of a bar on hover,
  // so manually track which bar is focused.
  const [focusBarIndex, setFocusBarIndex] = useState<number | undefined>();

  // Keep track of the hovered Y axis label so we can show a tooltip.
  const [hoveredYLabel, setHoveredYLabel] = useState<AxisLabel | undefined>();

  const hasSecondaryDimension = secondaryDimension !== AnalyticsDimension.None;

  const transformedData = transformData(data, secondaryDimension, sort, limit);

  const avgValue = calculateAverage(data, metric);
  const avgLabel = `Avg: ${formatValue(avgValue, avgValueFormat)}`;

  const segmentNames = new Set<SegmentName>();
  transformedData.forEach((p) => {
    Object.keys(p.data).forEach((segment) =>
      segmentNames.add(segment as SegmentName)
    );
  });
  const sortedSegmentNames = [...segmentNames.values()].sort((a, b) =>
    a.localeCompare(b)
  );
  const hasMultipleSegments = sortedSegmentNames.length > 1;

  // Unstacked bar charts of count metrics (e.g. #interviews vs. % talk time)
  // look odd because the per-interviweer average is far to the right of the
  // segmented unstacked bars.
  const showAvg = !countMetric || !hasMultipleSegments;

  // Filter all non-values
  const definedData = data.filter(
    (d) => d.value !== undefined && d.value !== null
  );
  const dataMax = definedData.reduce(
    (prev, cur) => Math.max(prev, cur.value || 0),
    0
  );

  const xDomain = getDomainForMetric(metric, valueFormat);

  let chartHeight =
    // For an unknown reason, a secondaryDimensino chart is comrpessed on the
    // first datapoint, and this additional padding results in it having a
    // consistent size.
    (hasMultipleSegments ? 100 : 70) +
    verticalAxisPaddingTop +
    verticalAxisPaddingBottom +
    transformedData.length *
      (!hasMultipleSegments
        ? spaceBetweenXLabels + barSize
        : // Additional padding between labels for readability when only
          // one label has data.
          spaceBetweenXLabels * 1.5 + barSize * sortedSegmentNames.length);

  chartHeight = Math.max(chartHeight, 160);

  return (
    <div data-testid="analytics-chart">
      <ResponsiveContainer height={chartHeight}>
        <BarChart
          margin={{
            top: 20,
            right: 0,
            left: 0,
            bottom: 20,
          }}
          style={{
            cursor:
              focusBarIndex !== undefined && drilldownEnabled
                ? "pointer"
                : "default",
          }}
          onClick={(chartState) => {
            if (chartState === null) {
              return;
            }
            const { activePayload } = chartState;
            if (drilldownEnabled && activePayload) {
              onDrilldown(activePayload[0].payload as PivotedDataPoint);
            }
          }}
          layout="vertical"
          data={transformedData}
          barGap={0}
          barSize={barSize}
          onMouseLeave={() => {
            if (focusBarIndex !== undefined) {
              setFocusBarIndex(undefined);
            }
          }}
          onMouseMove={(state) => {
            if (state.isTooltipActive) {
              if (state.activeTooltipIndex !== focusBarIndex) {
                setFocusBarIndex(state.activeTooltipIndex);
              }
            } else if (focusBarIndex !== undefined) {
              setFocusBarIndex(undefined);
            }
          }}
        >
          {benchmarkRange?.lowerBound && benchmarkRange.upperBound && (
            <>
              <ReferenceArea
                yAxisId="numericalY"
                x1={benchmarkRange.lowerBound}
                x2={benchmarkRange.upperBound}
                y1={0}
                y2={1}
                strokeOpacity={0}
                fill={colors.gray[50]}
                fillOpacity={1}
                ifOverflow="extendDomain"
              />
              <Customized
                component={(props) => (
                  <BenchmarkRangeLabel
                    benchmarkLowerBound={benchmarkRange.lowerBound}
                    benchmarkUpperBound={benchmarkRange.upperBound}
                    valueFormat={valueFormat}
                    customizedProps={props}
                  />
                )}
              />
            </>
          )}
          <Tooltip
            isAnimationActive={false}
            cursor={{ fill: colors.gray[100] }}
            content={({ active, payload, label }) => {
              if (!active || !payload) return null;
              return (
                <TooltipContent
                  payload={payload}
                  label={label}
                  metric={metric}
                  secondaryDimension={secondaryDimension}
                />
              );
            }}
          />
          {sortedSegmentNames.map((segmentName) => (
            <Bar
              key={segmentName}
              isAnimationActive={false}
              dataKey={`data.${segmentName}.value`}
              name={segmentLabels[segmentName]}
              fill={segmentColorsFocused[segmentName] /* used for legend */}
              radius={[0, barBorderRadius, barBorderRadius, 0]}
            >
              {transformedData.map((entry, index) => (
                <Cell
                  // eslint-disable-next-line react/no-array-index-key
                  key={`cell-${index}-y1`}
                  fill={barColor(
                    entry.data[segmentName]?.avgPerformance,
                    colors,
                    performanceOverlay,
                    segmentName,
                    focusBarIndex === index
                  )}
                />
              ))}
            </Bar>
          ))}
          <XAxis
            type="number"
            allowDecimals={!integerFormats.includes(valueFormat)}
            fontSize={fontSizes.xs}
            stroke={colors.gray[300]}
            format=""
            domain={xDomain}
            tick={{ fontSize: fontSizes.xs, fill: colors.gray[700] }}
            tickFormatter={(value) => formatValue(value, valueFormat)}
          />
          <YAxis
            type="category"
            dataKey="label"
            tickFormatter={(value) => {
              const limit = 18;
              if (value.length > limit) {
                return `${value.slice(0, limit - 3)}...`;
              }
              return value;
            }}
            width={150}
            stroke={colors.gray[300]}
            interval={0}
            tick={{ fontSize: fontSizes.xs, fill: colors.gray[700] }}
            padding={{
              top: verticalAxisPaddingTop,
              bottom: verticalAxisPaddingBottom,
            }}
            label={{
              value: stringToTitleCase(
                primaryDimension.value.replace("JOB_", "Interview ")
              ),
              angle: -90,
              // "insideBottomLeft" places the label near to the bottom on short
              // charts so that the label does not get cut off while
              // "insideLeft" places it near the middle on all other charts
              position: chartHeight < 180 ? "insideBottomLeft" : "insideLeft",
              fill: colors.gray[400],
              offset: 10,
            }}
            onMouseEnter={(label) => {
              setHoveredYLabel(label as unknown as AxisLabel);
            }}
            // onMouseLeave={() => {
            //   setHoveredYLabel(undefined);
            // }}
          />
          {hoveredYLabel && (
            <Customized
              component={() => <AxisLabelTooltip {...hoveredYLabel} />}
            />
          )}

          {/* Hidden numerical yAxis for easier ReferenceArea height control */}
          <YAxis
            type="number"
            domain={[
              (dataMin: number) => {
                return 0;
              },
              (dataMax: number) => {
                return 1;
              },
            ]}
            yAxisId="numericalY"
            tickCount={0}
            hide
          />
          {showAvg && (
            <ReferenceLine
              x={avgValue}
              stroke={avgColor}
              strokeWidth={2}
              className="analytics-tour-benchmark-line"
              label={
                <Label
                  value={avgLabel}
                  position={
                    avgValue / dataMax > 0.5
                      ? "insideTopRight"
                      : "insideTopLeft"
                  }
                  fontSize={fontSizes.xs}
                  fontWeight={400}
                  fill={avgColor}
                  offset={10}
                  color={avgColor}
                />
              }
            />
          )}
          {hasSecondaryDimension && (
            <Legend
              verticalAlign="bottom"
              wrapperStyle={{ fontSize: fontSizes.xs }}
              iconType="circle"
            />
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const BenchmarkRangeLabel: React.FC<{
  benchmarkLowerBound: number;
  benchmarkUpperBound: number;
  valueFormat: ValueFormat;
  customizedProps: any; // recharts customized props
}> = (props) => {
  const theme = useTheme();
  const { colors, fontSizes } = theme;
  const {
    benchmarkLowerBound,
    benchmarkUpperBound,
    customizedProps,
    valueFormat,
  } = props;
  const { width, offset, xAxisMap } = customizedProps;

  const ref = useRef<HTMLDivElement>(null);
  const [labelWidth, setLabelWidth] = useState(0);
  const labelHeight = verticalAxisPaddingTop;

  const xAxisScale = xAxisMap[0].scale;
  const benchmarkRange: number[] = [
    xAxisScale(benchmarkLowerBound),
    xAxisScale(benchmarkUpperBound),
  ];
  const benchmarkCenter = (benchmarkRange[0] + benchmarkRange[1]) / 2;
  let labelStart = benchmarkCenter - labelWidth / 2;

  if (labelStart < offset.left) {
    labelStart = offset.left;
  } else if (labelStart + labelWidth > width) {
    labelStart = width - labelWidth;
  }

  useLayoutEffect(() => {
    if (ref.current) {
      setLabelWidth(ref.current.offsetWidth);
    }
  }, []);

  const formattedLowerBound = formatValue(benchmarkLowerBound, valueFormat);
  const formattedUpperBound = formatValue(benchmarkUpperBound, valueFormat);

  return (
    <foreignObject x={0} y={0} width={width} height={labelHeight}>
      <Flex alignItems="center" ml={labelStart}>
        <Flex ref={ref}>
          <Text
            fontSize={fontSizes.xs}
            fontWeight={400}
            color={colors.gray[600]}
            whiteSpace="nowrap"
          >
            Benchmark Range
          </Text>
          <ChakraRechartsTooltip
            label={
              <>
                Fifty percent of interviews fall within this benchmark range of{" "}
                {formattedLowerBound} to {formattedUpperBound} based on data
                from our customer base.
              </>
            }
            placement="top"
          >
            <IconButton
              aria-label="Benchmark description"
              variant="unstyled"
              size="xxs"
              ml="1"
              color="gray.800"
              icon={<HiOutlineInformationCircle />}
            />
          </ChakraRechartsTooltip>
        </Flex>
      </Flex>
    </foreignObject>
  );
};

const AxisLabelTooltip: React.FC<AxisLabel> = ({ value, coordinate }) => {
  const theme = useTheme();
  const { colors } = theme;

  return (
    <foreignObject
      x={50}
      y={coordinate + 10}
      width={1}
      height={1}
      style={{ overflow: "visible" }}
    >
      <Text
        fontSize="xs"
        fontWeight="600"
        color={colors.gray[700]}
        whiteSpace="nowrap"
        backgroundColor={colors.white}
        px={2}
        borderRadius={4}
        border={`1px solid ${colors.gray[100]}`}
        display="inline-block"
      >
        {value}
      </Text>
    </foreignObject>
  );
};

const ChakraRechartsTooltip: React.FC<TooltipProps> = ({
  children,
  ...props
}) => (
  <Box lineHeight={0}>
    <ChakraTooltip
      bg="white"
      p="2"
      borderRadius="md"
      border="1px solid"
      borderColor="gray.200"
      color="gray.800"
      boxShadow="none"
      fontSize="xs"
      shouldWrapChildren
      {...props}
    >
      {children}
    </ChakraTooltip>
  </Box>
);

/**
 * Conditionally shows either the primary bar color or a performance-based color.
 */
const barColor = (
  performance: number | undefined | null,
  colors: any,
  performanceOverlay: boolean | undefined,
  segmentName: SegmentName,
  focused: boolean
): string => {
  if (!performanceOverlay) {
    if (focused) {
      return themedSegmentColorsFocused(colors)[segmentName];
    }
    return themedSegmentColors(colors)[segmentName];
  }

  if (performance === undefined || performance === null) {
    return colors.gray[400];
  }

  if (performance >= 2.01) {
    return colors.green[500];
  }
  if (performance >= 1.5) {
    return colors.yellow[400];
  }
  return colors.red[300];
};

// Get the domain for the metric chart.
const getDomainForMetric = (
  metric: MetricName,
  valueFormat: string
): string[] | number[] | undefined => {
  if (valueFormat === "percent") {
    return [0, 1];
  }
  switch (metric) {
    case MetricName.ScorecardCompletionTime:
      return [0, 168];
    default:
      return undefined;
  }
};

export default MetricChart;
