import {
  Button,
  ButtonGroup,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react";
import React, { useState } from "react";
import ResizeTextarea from "react-textarea-autosize";

export type TrainingProgramNameModalFocus = "name" | "description";

type TrainingProgramNameModalProps = {
  onClose(): void;
  onSave(name: string, description: string): void;
  mode: "create" | "edit";
  initialName?: string;
  initialDescription?: string;
  initialFocus?: TrainingProgramNameModalFocus;
};

const TrainingNameModal: React.FC<TrainingProgramNameModalProps> = (props) => {
  return (
    <Modal size="lg" isOpen onClose={props.onClose} isCentered>
      <ModalOverlay>
        <TrainingNameModalContent {...props} />
      </ModalOverlay>
    </Modal>
  );
};

export default TrainingNameModal;

export const TrainingNameModalContent: React.FC<
  TrainingProgramNameModalProps
> = ({
  mode,
  initialName,
  onClose,
  onSave,
  initialDescription,
  initialFocus,
}) => {
  const [name, setName] = useState<string>(initialName || "");
  const [description, setDescription] = useState<string>(
    initialDescription || ""
  );
  return (
    <ModalContent>
      <ModalHeader
        borderBottomWidth="1px"
        borderColor="gray.100"
        fontSize="xl"
        fontWeight="500"
        p="6"
      >
        {mode === "create" ? "New" : "Edit"} Training Program
      </ModalHeader>
      <ModalCloseButton top="6" />
      <ModalBody mt="4" pb="2" px="8">
        <Input
          data-tour-id={
            mode === "create" ? "training-title-program" : undefined
          }
          placeholder="Title this Training Program"
          mb="5"
          fontSize="sm"
          autoFocus={initialFocus === "name"}
          onChange={(e) => setName(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              onClose();
              onSave(name.trim(), description.trim());
            }
          }}
          defaultValue={initialName}
        />
        <Textarea
          fontSize="sm"
          placeholder="Add a description (optional)"
          as={ResizeTextarea}
          minRows={1}
          minHeight="0"
          resize="none"
          overflow="hidden"
          autoFocus={initialFocus === "description"}
          onFocus={(e) => {
            const target = e.target as HTMLTextAreaElement;
            const endIdx = target.value?.length ?? 0;
            target.setSelectionRange(endIdx, endIdx);
          }}
          onChange={(e) => setDescription(e.target.value)}
          defaultValue={initialDescription}
        />
      </ModalBody>
      <ModalFooter px="8">
        <ButtonGroup size="sm" spacing="2">
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            // isLoading={loading}
            isDisabled={name.trim() === ""}
            onClick={() => {
              onClose();
              onSave(name.trim(), description.trim());
            }}
          >
            {mode === "create" ? "Create" : "Save"}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </ModalContent>
  );
};
