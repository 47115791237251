import React from "react";

const BotIcon: React.FC<{ size?: number }> = ({ size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8068 1.54059C12.8068 1.94918 12.4755 2.2804 12.0669 2.2804C11.6584 2.2804 11.3271 1.94918 11.3271 1.54059C11.3271 1.13201 11.6584 0.800781 12.0669 0.800781C12.4755 0.800781 12.8068 1.13201 12.8068 1.54059Z"
        fill="#9F7AEA"
      />
      <path
        d="M11.3078 3.35185C11.3078 2.94368 11.6394 2.61204 12.0476 2.61204C12.4494 2.61204 12.7874 2.94368 12.7874 3.35185C12.7874 3.76002 12.4558 4.09166 12.0476 4.09166C11.6394 4.09166 11.3078 3.76002 11.3078 3.35185Z"
        fill="#9F7AEA"
      />
      <path
        d="M12.5516 4.62739C12.2709 4.34677 11.7862 4.34677 11.5056 4.62739C11.3653 4.7677 11.2888 4.95265 11.2888 5.14399L11.2633 7.82261V7.89277C11.2824 8.28818 11.6013 8.60069 12.0031 8.60069C12.4049 8.60069 12.7238 8.28819 12.7429 7.88001L12.7748 5.14399C12.762 4.95265 12.6855 4.7677 12.5516 4.62739Z"
        fill="#9F7AEA"
      />
      <path
        d="M11.1548 7.84812L10.2747 5.34169C10.2109 5.15674 10.077 5.00367 9.8984 4.91439C9.7262 4.83148 9.52212 4.81872 9.33716 4.8825C9.15221 4.94628 8.99915 5.08021 8.90986 5.25878C8.82057 5.43736 8.81419 5.63507 8.87797 5.8264L9.77085 8.34558C9.86651 8.65809 10.1535 8.87493 10.4788 8.87493C10.8869 8.87493 11.2186 8.54329 11.2186 8.13512C11.2122 8.02032 11.1867 7.90552 11.1548 7.84812Z"
        fill="#9F7AEA"
      />
      <path
        d="M17.1243 6.31111C17.1753 6.35575 17.2391 6.43228 17.2838 6.52157C17.3348 6.61086 17.3603 6.70652 17.373 6.81494C17.3794 6.91061 17.3667 7.01265 17.3348 7.10832C17.3029 7.1976 17.2519 7.28689 17.1881 7.36342L15.4087 9.44255C15.2684 9.60199 15.0643 9.69766 14.8475 9.69766C14.4393 9.69766 14.1077 9.36602 14.1077 8.95785C14.1077 8.75376 14.1842 8.56881 14.3309 8.4285L16.0529 6.38126C16.1103 6.30473 16.1868 6.24095 16.2761 6.19631C16.3717 6.14529 16.4674 6.11978 16.5695 6.1134C16.6715 6.10064 16.7735 6.11978 16.8692 6.15166C16.9649 6.18993 17.0478 6.24095 17.1243 6.31111Z"
        fill="#9F7AEA"
      />
      <path
        d="M15.1409 5.29706C15.058 5.11848 14.9113 4.97817 14.7264 4.90802C14.5414 4.83786 14.3437 4.84424 14.1652 4.92715C13.9866 5.01006 13.8463 5.15675 13.7761 5.3417L12.845 7.82262C12.8003 7.92466 12.7748 8.02671 12.7748 8.1415C12.7748 8.54968 13.1065 8.88132 13.5146 8.88132C13.7889 8.88132 14.044 8.72825 14.1715 8.4859C14.1798 8.47355 14.1853 8.46119 14.1917 8.44712C14.1952 8.4394 14.1989 8.43116 14.2034 8.42212L15.1664 5.86467C15.2302 5.67972 15.2238 5.47563 15.1409 5.29706Z"
        fill="#9F7AEA"
      />
      <path
        d="M7.9789 6.33024L9.69449 8.40936C9.80929 8.53054 9.87945 8.70911 9.87945 8.89407C9.87945 9.30224 9.54781 9.63388 9.13964 9.63388C8.89091 9.63388 8.66769 9.50632 8.52738 9.30224L6.83729 7.26776C6.77989 7.1976 6.72887 7.10831 6.70336 7.01903C6.67147 6.92336 6.66509 6.8277 6.67147 6.73203C6.68423 6.62999 6.70974 6.5407 6.75438 6.45141C6.79902 6.3685 6.8628 6.29197 6.93933 6.22819C7.01587 6.17079 7.09878 6.11977 7.19444 6.09426C7.29011 6.06875 7.38577 6.06237 7.48144 6.06875C7.5771 6.07513 7.67277 6.10064 7.75568 6.14528C7.83859 6.1963 7.91512 6.2537 7.9789 6.33024Z"
        fill="#9F7AEA"
      />
      <path
        d="M8.5399 9.44892L6.19929 8.05221C6.03347 7.95654 5.83576 7.92465 5.64443 7.97568C5.4531 8.0267 5.29365 8.1415 5.19161 8.31369C5.14059 8.3966 5.1087 8.49227 5.09595 8.58793C5.08319 8.6836 5.08957 8.77926 5.1087 8.87493C5.13421 8.97059 5.17248 9.05988 5.22988 9.13641C5.28728 9.21295 5.36381 9.27672 5.44672 9.32775L7.75544 10.6989C7.87662 10.7755 8.01055 10.8137 8.15086 10.8137C8.55903 10.8137 8.89067 10.4821 8.89067 10.0739C8.88429 9.8252 8.76312 9.59561 8.5399 9.44892Z"
        fill="#9F7AEA"
      />
      <path
        d="M7.72992 10.7946C7.7363 10.801 7.80007 10.8138 7.80645 10.8138C8.13809 10.9158 8.34855 11.1964 8.35493 11.5217C8.35493 11.9298 8.02329 12.2615 7.61512 12.2615C7.60876 12.2615 7.59286 12.2599 7.57695 12.2583C7.56107 12.2567 7.54494 12.2551 7.53859 12.2551C7.52042 12.2528 7.50549 12.2514 7.49293 12.2501C7.47028 12.2479 7.45522 12.2465 7.44292 12.2424L4.80894 11.7449C4.61761 11.7066 4.44541 11.5982 4.33699 11.4324C4.22857 11.2666 4.18392 11.0752 4.22219 10.8775C4.26046 10.6862 4.37526 10.514 4.5347 10.4056C4.70052 10.2972 4.89185 10.2525 5.08956 10.2908L7.72992 10.7946Z"
        fill="#9F7AEA"
      />
      <path
        d="M7.55167 12.3316C7.52616 12.3316 7.50066 12.338 7.47515 12.3444L4.83477 12.7845C4.73911 12.7972 4.64344 12.8227 4.55416 12.8737C4.46487 12.9184 4.38834 12.9822 4.33094 13.0651C4.26716 13.1416 4.22252 13.2373 4.20338 13.3329C4.17787 13.4286 4.17787 13.5306 4.19063 13.6263C4.20976 13.7284 4.24165 13.824 4.29905 13.9069C4.35007 13.9898 4.4266 14.0664 4.50951 14.1174C4.59242 14.1684 4.68171 14.2131 4.78375 14.2322C4.83477 14.2449 4.87942 14.2449 4.93044 14.2449C4.98146 14.2449 5.0261 14.2386 5.06437 14.2322L7.76851 13.7857C7.8004 13.7794 7.82591 13.773 7.85142 13.7602C8.15117 13.6582 8.35526 13.3776 8.35526 13.0587C8.34888 12.6569 8.01724 12.3444 7.55167 12.3316Z"
        fill="#9F7AEA"
      />
      <path
        d="M7.72998 13.9005C7.81289 13.8304 7.96596 13.7794 8.13178 13.7794C8.53995 13.7794 8.87159 14.1046 8.85883 14.5128C8.85883 14.7552 8.73766 14.9848 8.53357 15.1251C8.53038 15.1283 8.51922 15.1346 8.50806 15.141C8.49691 15.1474 8.48574 15.1538 8.48255 15.157L6.12281 16.4963C6.01438 16.56 5.88683 16.5919 5.75928 16.5919C5.6955 16.5919 5.62535 16.5856 5.56157 16.5664C5.37662 16.5154 5.21717 16.3942 5.12151 16.222C5.02584 16.0498 5.00033 15.8521 5.05773 15.6608C5.10875 15.4758 5.23631 15.31 5.40213 15.2144L7.72998 13.9005Z"
        fill="#9F7AEA"
      />
      <path
        d="M9.11372 14.9656C8.93515 14.9656 8.75657 15.0358 8.62264 15.157C8.60351 15.1697 8.58438 15.1888 8.57162 15.208L6.79224 17.2552C6.72209 17.3317 6.67107 17.4147 6.6328 17.5103C6.60091 17.606 6.58178 17.708 6.59454 17.8101C6.60091 17.9121 6.62642 18.0078 6.67745 18.1034C6.72209 18.1927 6.79224 18.2756 6.84964 18.3203C6.92618 18.3904 7.00909 18.4415 7.10475 18.467C7.18128 18.4925 7.26419 18.5052 7.3471 18.5052H7.39813C7.50017 18.4989 7.59583 18.4734 7.68512 18.4287C7.77441 18.3841 7.85094 18.3203 7.91472 18.2501L9.61756 16.2603C9.77063 16.12 9.85991 15.9223 9.85991 15.7118C9.85354 15.2909 9.5219 14.9656 9.11372 14.9656Z"
        fill="#9F7AEA"
      />
      <path
        d="M9.74538 16.2412C9.83466 15.9542 10.1217 15.7373 10.4469 15.7373C10.8551 15.7373 11.1867 16.069 11.174 16.4835C11.174 16.5792 11.1548 16.6748 11.1166 16.7641L10.1727 19.2961C10.1344 19.3854 10.0834 19.4683 10.0196 19.5384C9.95584 19.6086 9.87931 19.666 9.79002 19.7042C9.69436 19.7489 9.59869 19.7744 9.50302 19.7744H9.48389C9.3946 19.7744 9.30532 19.7553 9.22241 19.7234C9.13312 19.6851 9.05021 19.6341 8.98005 19.5703C8.9099 19.5002 8.8525 19.4236 8.81423 19.3343C8.77597 19.2387 8.75046 19.143 8.75046 19.0473C8.75046 18.9517 8.76321 18.856 8.80148 18.7667L9.74538 16.2412Z"
        fill="#9F7AEA"
      />
      <path
        d="M12.7045 16.694C12.679 16.3113 12.3538 16.0116 11.9711 16.0116C11.5884 16.0116 11.2632 16.3113 11.2313 16.7195L11.1994 19.4555C11.1994 19.6468 11.2759 19.8318 11.4099 19.9785C11.5438 20.1188 11.7287 20.2017 11.9328 20.2017C12.1305 20.2017 12.3155 20.1252 12.4558 19.9912C12.5961 19.8573 12.6726 19.6724 12.679 19.4746L12.7109 16.7322C12.7045 16.7195 12.7045 16.7004 12.7045 16.694Z"
        fill="#9F7AEA"
      />
      <path
        d="M14.1782 16.2284L15.0774 18.7986C15.1412 18.9963 15.1221 19.194 15.0328 19.3535C14.9435 19.5257 14.7968 19.6532 14.6182 19.717C14.5417 19.7489 14.4588 19.7616 14.3759 19.7616C14.2738 19.7616 14.1654 19.7425 14.0698 19.6979C13.8912 19.6213 13.7573 19.481 13.6871 19.3025L12.7815 16.7259C12.756 16.6493 12.7432 16.5664 12.7432 16.4835C12.7432 16.0753 13.0748 15.7437 13.483 15.7437C13.77 15.7437 14.0251 15.9095 14.1463 16.1646C14.159 16.1838 14.1718 16.2029 14.1782 16.2284Z"
        fill="#9F7AEA"
      />
      <path
        d="M17.2521 17.5996C17.2202 17.5039 17.1755 17.4147 17.1118 17.3381L15.4153 15.31C15.275 15.1059 15.0518 14.9911 14.8094 14.9911C14.4012 14.9911 14.0696 15.3228 14.0696 15.7309C14.0696 15.9414 14.1589 16.1391 14.2737 16.2348L15.951 18.282C16.0148 18.3585 16.0913 18.4287 16.1742 18.4797C16.2635 18.5307 16.3592 18.5626 16.4612 18.569C16.474 18.569 16.4867 18.5706 16.4995 18.5722C16.5122 18.5738 16.525 18.5754 16.5378 18.5754C16.6143 18.5754 16.6908 18.5626 16.761 18.5435C16.8567 18.5116 16.9459 18.4606 17.0033 18.4096C17.0799 18.3458 17.15 18.2693 17.1947 18.18C17.2393 18.0907 17.2712 18.0014 17.284 17.893C17.2967 17.7909 17.284 17.6953 17.2521 17.5996Z"
        fill="#9F7AEA"
      />
      <path
        d="M18.7443 15.4758C18.808 15.5587 18.8527 15.6544 18.8782 15.7565C18.9037 15.8521 18.9101 15.9542 18.8909 16.0562C18.8718 16.1582 18.8335 16.2603 18.7761 16.3432C18.7379 16.407 18.6677 16.4835 18.5912 16.5409C18.5147 16.5919 18.4254 16.6366 18.3297 16.6621C18.2659 16.6812 18.2085 16.6876 18.1448 16.6876C18.1065 16.6876 18.0746 16.6876 18.0363 16.6812C17.9407 16.6685 17.8514 16.6366 17.7685 16.5856L15.4087 15.1825C15.3992 15.1761 15.3896 15.1681 15.38 15.1601C15.3705 15.1522 15.3609 15.1442 15.3513 15.1378C15.1664 14.9975 15.058 14.787 15.058 14.5511C15.058 14.1429 15.3896 13.8113 15.7978 13.8113C15.9508 13.8113 16.0975 13.8559 16.2251 13.9452L18.521 15.2845C18.6039 15.3291 18.6805 15.3929 18.7443 15.4758Z"
        fill="#9F7AEA"
      />
      <path
        d="M19.6434 13.1735C19.535 13.0141 19.3628 12.9056 19.1714 12.8674L16.4864 12.3699C16.4673 12.3635 16.4418 12.3635 16.4227 12.3635C16.4031 12.3635 16.3836 12.3611 16.3685 12.3593C16.3589 12.3581 16.3511 12.3572 16.3461 12.3572C15.938 12.3572 15.6063 12.6888 15.6063 13.097C15.6063 13.4541 15.8614 13.7602 16.2122 13.824L18.8972 14.3215C18.9418 14.3279 18.9865 14.3342 19.0311 14.3342C19.1778 14.3342 19.3245 14.2896 19.4521 14.2003C19.6115 14.0855 19.7263 13.9197 19.7582 13.7284C19.7965 13.537 19.7582 13.3329 19.6434 13.1735Z"
        fill="#9F7AEA"
      />
      <path
        d="M19.4587 10.5012C19.5352 10.5523 19.6053 10.6224 19.6564 10.7053C19.7138 10.7946 19.7457 10.8839 19.7775 10.9796C19.7903 11.0752 19.7903 11.1709 19.7712 11.2666C19.752 11.3559 19.7138 11.4451 19.6564 11.5281C19.5926 11.611 19.5288 11.6811 19.4459 11.7321C19.363 11.7895 19.2737 11.8214 19.178 11.8406L16.4675 12.2806C16.4484 12.287 16.4165 12.287 16.3974 12.287H16.3655C15.9573 12.287 15.6257 11.9554 15.6257 11.5472C15.6257 11.1964 15.8616 10.903 16.1997 10.8265L18.9166 10.3801C19.0058 10.3673 19.1015 10.3673 19.1972 10.3864C19.2865 10.4056 19.3758 10.4438 19.4587 10.5012Z"
        fill="#9F7AEA"
      />
      <path
        d="M18.8719 8.40936C18.8209 8.32007 18.7571 8.24354 18.6742 8.17976C18.5913 8.11598 18.502 8.07134 18.4 8.04583C18.2979 8.02032 18.1959 8.01394 18.0938 8.03307C17.9918 8.05221 17.9025 8.0841 17.8196 8.13512L15.4726 9.49356C15.4684 9.49633 15.464 9.4991 15.4594 9.50193C15.443 9.51215 15.4252 9.52323 15.4152 9.53821C15.2302 9.67852 15.1218 9.89536 15.1218 10.125C15.1218 10.5331 15.4535 10.8648 15.8616 10.8648C15.9509 10.8648 16.0402 10.852 16.1231 10.8201C16.1486 10.8137 16.1741 10.801 16.1996 10.7882L18.5721 9.44892C18.6614 9.3979 18.7443 9.33412 18.8017 9.25759C18.8655 9.17468 18.9102 9.08539 18.9357 8.98335C18.9612 8.88131 18.9676 8.78564 18.9548 8.6836C18.9484 8.57518 18.9165 8.47951 18.8719 8.40936Z"
        fill="#9F7AEA"
      />
      <path
        d="M8.97997 4.60189C9.38855 4.60189 9.71978 4.27066 9.71978 3.86207C9.71978 3.45349 9.38855 3.12226 8.97997 3.12226C8.57138 3.12226 8.24015 3.45349 8.24015 3.86207C8.24015 4.27066 8.57138 4.60189 8.97997 4.60189Z"
        fill="#9F7AEA"
      />
      <path
        d="M7.00956 5.39909C7.00956 5.80768 6.67834 6.1389 6.26975 6.1389C5.86116 6.1389 5.52994 5.80768 5.52994 5.39909C5.52994 4.9905 5.86116 4.65928 6.26975 4.65928C6.67834 4.65928 7.00956 4.9905 7.00956 5.39909Z"
        fill="#9F7AEA"
      />
      <path
        d="M4.25435 8.51141C4.66293 8.51141 4.99416 8.18018 4.99416 7.7716C4.99416 7.36301 4.66293 7.03179 4.25435 7.03179C3.84576 7.03179 3.51453 7.36301 3.51453 7.7716C3.51453 8.18018 3.84576 8.51141 4.25435 8.51141Z"
        fill="#9F7AEA"
      />
      <path
        d="M2.13685 10.3546C2.13685 10.7631 1.80563 11.0944 1.39704 11.0944C0.988452 11.0944 0.657227 10.7631 0.657227 10.3546C0.657227 9.94597 0.988452 9.61475 1.39704 9.61475C1.80563 9.61475 2.13685 9.94597 2.13685 10.3546Z"
        fill="#9F7AEA"
      />
      <path
        d="M3.16987 9.95276C2.7617 9.95276 2.43006 10.2844 2.43006 10.6926C2.43006 11.1007 2.7617 11.4324 3.16987 11.4324C3.57804 11.4324 3.90968 11.1007 3.90968 10.6926C3.90968 10.2844 3.57804 9.95276 3.16987 9.95276Z"
        fill="#9F7AEA"
      />
      <path
        d="M2.4049 13.7985C2.4049 13.3903 2.73654 13.0587 3.14471 13.0587C3.55288 13.0587 3.88452 13.3903 3.88452 13.7985C3.88452 14.2067 3.55288 14.5383 3.14471 14.5383C2.73654 14.5383 2.4049 14.2067 2.4049 13.7985Z"
        fill="#9F7AEA"
      />
      <path
        d="M4.18403 15.9924C3.77585 15.9924 3.44421 16.3241 3.44421 16.7322C3.44421 17.1404 3.77585 17.4721 4.18403 17.4721C4.5922 17.4721 4.92384 17.1404 4.92384 16.7322C4.92384 16.3241 4.5922 15.9924 4.18403 15.9924Z"
        fill="#9F7AEA"
      />
      <path
        d="M6.90763 19.1303C6.90763 19.5388 6.57641 19.8701 6.16782 19.8701C5.75923 19.8701 5.42801 19.5388 5.42801 19.1303C5.42801 18.7217 5.75923 18.3904 6.16782 18.3904C6.57641 18.3904 6.90763 18.7217 6.90763 19.1303Z"
        fill="#9F7AEA"
      />
      <path
        d="M8.85287 21.4454C9.26146 21.4454 9.59269 21.1141 9.59269 20.7055C9.59269 20.297 9.26146 19.9657 8.85287 19.9657C8.44429 19.9657 8.11306 20.297 8.11306 20.7055C8.11306 21.1141 8.44429 21.4454 8.85287 21.4454Z"
        fill="#9F7AEA"
      />
      <path
        d="M11.1742 21.2668C11.1742 20.8586 11.5059 20.527 11.914 20.527C12.3222 20.527 12.6539 20.8586 12.6539 21.2668C12.6539 21.675 12.3222 22.0066 11.914 22.0066C11.5059 22.0066 11.1742 21.675 11.1742 21.2668Z"
        fill="#9F7AEA"
      />
      <path
        d="M14.9881 21.49C15.3967 21.49 15.7279 21.1588 15.7279 20.7502C15.7279 20.3416 15.3967 20.0104 14.9881 20.0104C14.5795 20.0104 14.2483 20.3416 14.2483 20.7502C14.2483 21.1588 14.5795 21.49 14.9881 21.49Z"
        fill="#9F7AEA"
      />
      <path
        d="M18.4252 19.2132C18.4252 19.6218 18.094 19.953 17.6854 19.953C17.2768 19.953 16.9456 19.6218 16.9456 19.2132C16.9456 18.8046 17.2768 18.4734 17.6854 18.4734C18.094 18.4734 18.4252 18.8046 18.4252 19.2132Z"
        fill="#9F7AEA"
      />
      <path
        d="M21.2698 17.032C20.8616 17.032 20.53 17.3636 20.53 17.7718C20.53 18.18 20.8616 18.5116 21.2698 18.5116C21.678 18.5116 22.0096 18.18 22.0096 17.7718C22.0096 17.3636 21.6716 17.032 21.2698 17.032Z"
        fill="#9F7AEA"
      />
      <path
        d="M21.5316 13.9261C21.5316 14.3346 21.2003 14.6659 20.7918 14.6659C20.3832 14.6659 20.0519 14.3346 20.0519 13.9261C20.0519 13.5175 20.3832 13.1863 20.7918 13.1863C21.2003 13.1863 21.5316 13.5175 21.5316 13.9261Z"
        fill="#9F7AEA"
      />
      <path
        d="M20.8169 11.5536C21.2255 11.5536 21.5567 11.2223 21.5567 10.8137C21.5567 10.4052 21.2255 10.0739 20.8169 10.0739C20.4083 10.0739 20.0771 10.4052 20.0771 10.8137C20.0771 11.2223 20.4083 11.5536 20.8169 11.5536Z"
        fill="#9F7AEA"
      />
      <path
        d="M23.3425 10.5204C23.3425 10.929 23.0112 11.2602 22.6027 11.2602C22.1941 11.2602 21.8628 10.929 21.8628 10.5204C21.8628 10.1118 22.1941 9.78057 22.6027 9.78057C23.0112 9.78057 23.3425 10.1118 23.3425 10.5204Z"
        fill="#9F7AEA"
      />
      <path
        d="M19.7712 8.6262C20.1797 8.6262 20.511 8.29498 20.511 7.88639C20.511 7.4778 20.1797 7.14658 19.7712 7.14658C19.3626 7.14658 19.0313 7.4778 19.0313 7.88639C19.0313 8.29498 19.3626 8.6262 19.7712 8.6262Z"
        fill="#9F7AEA"
      />
      <path
        d="M18.5336 5.482C18.5336 5.89059 18.2024 6.22181 17.7938 6.22181C17.3852 6.22181 17.054 5.89059 17.054 5.482C17.054 5.07341 17.3852 4.74219 17.7938 4.74219C18.2024 4.74219 18.5336 5.07341 18.5336 5.482Z"
        fill="#9F7AEA"
      />
      <path
        d="M18.9673 3.37736C18.5591 3.37736 18.2275 3.709 18.2275 4.11717C18.2275 4.52535 18.5591 4.85699 18.9673 4.85699C19.3755 4.85699 19.7071 4.52535 19.7071 4.11717C19.7071 3.709 19.3755 3.37736 18.9673 3.37736Z"
        fill="#9F7AEA"
      />
      <path
        d="M15.8428 3.91309C15.8428 4.32168 15.5115 4.6529 15.103 4.6529C14.6944 4.6529 14.3631 4.32168 14.3631 3.91309C14.3631 3.5045 14.6944 3.17328 15.103 3.17328C15.5115 3.17328 15.8428 3.5045 15.8428 3.91309Z"
        fill="#9F7AEA"
      />
      <path
        d="M19.7073 16.1072C19.2991 16.1072 18.9675 16.4389 18.9675 16.847C18.9675 17.2552 19.2991 17.5869 19.7073 17.5869C20.1155 17.5869 20.4471 17.2552 20.4471 16.847C20.4471 16.4389 20.1155 16.1072 19.7073 16.1072Z"
        fill="#9F7AEA"
      />
      <path
        d="M16.3273 22.4594C16.3273 22.868 15.996 23.1992 15.5874 23.1992C15.1789 23.1992 14.8476 22.868 14.8476 22.4594C14.8476 22.0508 15.1789 21.7196 15.5874 21.7196C15.996 21.7196 16.3273 22.0508 16.3273 22.4594Z"
        fill="#9F7AEA"
      />
      <path
        d="M8.21484 21.6622C7.80666 21.6622 7.47502 21.9938 7.47502 22.402C7.47502 22.8102 7.80666 23.1418 8.21484 23.1418C8.62301 23.1418 8.95465 22.8102 8.95465 22.402C8.95465 21.9938 8.62301 21.6622 8.21484 21.6622Z"
        fill="#9F7AEA"
      />
      <path
        d="M1.87524 17.6187C1.87524 17.2106 2.20688 16.8789 2.61505 16.8789C3.02323 16.8789 3.35487 17.2106 3.35487 17.6187C3.35487 18.0269 3.02323 18.3586 2.61505 18.3586C2.20688 18.3586 1.87524 18.0269 1.87524 17.6187Z"
        fill="#9F7AEA"
      />
      <path
        d="M5.12786 4.74219C5.53645 4.74219 5.86767 4.41097 5.86767 4.00238C5.86767 3.59379 5.53645 3.26257 5.12786 3.26257C4.71927 3.26257 4.38805 3.59379 4.38805 4.00238C4.38805 4.41097 4.71927 4.74219 5.12786 4.74219Z"
        fill="#9F7AEA"
      />
    </svg>
  );
};

export default BotIcon;
