import { CallAiSummaryQuery } from "../../../../../graphql";

export type CallAiSummary = NonNullable<CallAiSummaryQuery["callAiSummary"]>;

export type SummaryCallSection = NonNullable<CallAiSummary["headers"]>[0];

export type SummaryCustomTopic = NonNullable<CallAiSummary["customTopics"]>[0];

export type SummaryCallNote = NonNullable<
  NonNullable<SummaryCallSection>["notes"]
>[0];

export enum NoteLength {
  Long = "Long",
  Med = "Med",
  Short = "Short",
}

export enum NoteFormat {
  Paragraph = "Paragraph",
  Bullets = "Bullets",
}

export interface NoteContent {
  note: SummaryCallNote;
  sectionTitle: string;
}
export interface NoteMap {
  [key: string]: NoteContent;
}
