import { ApolloError } from "@apollo/client";
import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";

import { Alert } from "../../../components";
import { Client as ClientType } from "../../graphql";

export type Client = Pick<ClientType, "id" | "name">;

export interface FormData {
  name: string;
}

interface ClientFormProps {
  name?: string;
  error: ApolloError | undefined;
  isLoading: boolean;
  onSubmit: (formData: FormData) => void;
  onCancel: () => void;
}

const ClientForm: React.FC<ClientFormProps> = ({
  name = "",
  error,
  isLoading,
  onSubmit,
  onCancel,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name,
    },
  });
  const submitClient = (event: React.FormEvent<HTMLFormElement>): any => {
    event.stopPropagation();
    return handleSubmit((formData): void => onSubmit(formData))(event);
  };
  return (
    <form onSubmit={submitClient}>
      {error?.graphQLErrors?.map(({ message }, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Alert mb={6} key={i} status="error" description={message} />
      ))}
      <VStack spacing="8">
        <FormControl id="name" isRequired isInvalid={errors.name !== undefined}>
          <FormLabel>Name</FormLabel>
          <Input {...register("name")} />
          {errors.name !== undefined && (
            <FormErrorMessage>{errors.name.message}</FormErrorMessage>
          )}
        </FormControl>
        <ButtonGroup spacing={2} alignSelf="end">
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" isLoading={isLoading}>
            Save
          </Button>
        </ButtonGroup>
      </VStack>
    </form>
  );
};

export default ClientForm;
