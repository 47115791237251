import { Flex, GridItem, Text } from "@chakra-ui/react";
import React from "react";

export const PolicyGridBorder: React.FC<{ noBottomMargin?: boolean }> = ({
  noBottomMargin = false,
}) => (
  <GridItem
    colSpan={6}
    bgColor="gray.100"
    height="1px"
    mb={noBottomMargin ? "0" : "5"}
  />
);

export const PolicyGridHeader: React.FC<{
  columnTitles: string[];
  columnCount: number;
}> = ({ columnCount, columnTitles }) => {
  const spacerWidth = columnCount - columnTitles.length;
  const titles = columnTitles.map((title) => (
    <GridItem colSpan={1} key={title}>
      <Text
        align="center"
        color="gray.800"
        fontSize="sm"
        fontWeight="500"
        mb="4"
      >
        {title}
      </Text>
    </GridItem>
  ));
  return (
    <>
      <GridItem colSpan={spacerWidth} />
      {titles}
    </>
  );
};

export const PolicySectionGridHeader: React.FC<{ text: string }> = ({
  text,
}) => (
  <GridItem colSpan={6}>
    <Text fontSize="xs" color="gray.500" fontWeight="500" my="6">
      {text}
    </Text>
  </GridItem>
);

export const PolicySectionGridRow: React.FC<{
  text: string;
  basicCell?: React.ReactNode;
  recruiterCell?: React.ReactNode;
  adminCell?: React.ReactNode;
  noBottomMargin?: boolean;
}> = ({
  text,
  basicCell,
  recruiterCell,
  adminCell,
  noBottomMargin = false,
}) => (
  <>
    <GridItem colSpan={3} pb="5">
      <Flex color="gray.900" fontSize="sm" fontWeight="400">
        {text}
      </Flex>
    </GridItem>
    <GridItem colSpan={1} pb="5">
      <Flex justifyContent="center" alignContent="center">
        {basicCell}
      </Flex>
    </GridItem>
    <GridItem colSpan={1} pb="5">
      <Flex justifyContent="center" alignContent="center">
        {recruiterCell}
      </Flex>
    </GridItem>
    <GridItem colSpan={1} pb="5">
      <Flex justifyContent="center" alignContent="center">
        {adminCell}
      </Flex>
    </GridItem>
    <PolicyGridBorder noBottomMargin={noBottomMargin} />
  </>
);
