import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Icon,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { HiStar } from "react-icons/hi2";

export type ExpandableGroupListItem = {
  id: string;
  heading: string;
  notes?: string | null;
  score?: number | null;
};
interface ExpandableGroupProps {
  groupHeader: string;
  subHeader?: string;
  scoringEnabled: boolean;
  score?: number | null;
  listItems: ExpandableGroupListItem[];
}

const ExpandableGroup: React.FC<ExpandableGroupProps> = ({
  groupHeader,
  subHeader,
  listItems,
  scoringEnabled,
  score,
}) => {
  const hasItems = listItems.length > 0;

  return (
    <Accordion allowToggle>
      <AccordionItem
        _last={{ border: "none" }}
        pointerEvents={!hasItems ? "none" : undefined}
      >
        {({ isExpanded }) => (
          <>
            <h2>
              <AccordionButton
                pl="0"
                borderRadius="base"
                _hover={{ bg: "blue.50" }}
              >
                <AccordionIcon
                  color={hasItems ? "gray.300" : "gray.100"}
                  boxSize="6"
                  alignSelf="flex-start"
                  transform={isExpanded ? "rotate(0deg)" : "rotate(-90deg)"}
                />
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  textAlign="start"
                  gap="3"
                >
                  <Flex direction="column">
                    <Text color="gray.700" fontSize="sm" fontWeight="500">
                      {groupHeader}
                    </Text>
                    <Text color="gray.600" fontSize="xs">
                      {subHeader}
                    </Text>
                  </Flex>
                  <Flex alignItems="center" whiteSpace="nowrap">
                    {scoringEnabled && score && (
                      <>
                        <Text
                          as="span"
                          fontWeight="500"
                          fontSize="sm"
                          color="gray.800"
                          pr="1"
                        >
                          {score.toFixed(1)}
                        </Text>
                        <Icon as={HiStar} color="blue.500" />
                      </>
                    )}
                    {scoringEnabled && !score && (
                      <Text
                        as="span"
                        fontWeight="400"
                        fontSize="sm"
                        color="gray.500"
                      >
                        Pending score
                      </Text>
                    )}
                    {!scoringEnabled && (
                      <Text
                        as="i"
                        fontWeight="400"
                        fontSize="sm"
                        color="gray.400"
                      >
                        Scoring disabled
                      </Text>
                    )}
                  </Flex>
                </Flex>
              </AccordionButton>
            </h2>
            <AccordionPanel pb={1} px="0">
              <Flex direction="column" gap="2">
                {listItems.map((item) => (
                  <Flex
                    key={item.id}
                    width="100%"
                    direction="column"
                    bg="gray.50"
                    px="4"
                    borderRadius="6"
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      pt="3"
                    >
                      <Text
                        color="gray.900"
                        fontWeight="400"
                        fontSize="sm"
                        mr="3"
                      >
                        {item.heading}
                      </Text>
                      <Flex alignItems="center" whiteSpace="nowrap">
                        {item.score && (
                          <>
                            <Text
                              as="span"
                              fontWeight="500"
                              fontSize="sm"
                              color="gray.800"
                              pr="1"
                            >
                              {item.score.toFixed(1)}
                            </Text>
                            <Icon as={HiStar} color="blue.500" />
                          </>
                        )}
                        {!item.score && (
                          <Text
                            as="span"
                            fontWeight="400"
                            fontSize="sm"
                            color="gray.500"
                          >
                            Pending score
                          </Text>
                        )}
                      </Flex>
                    </Flex>
                    {item.notes && (
                      <Flex
                        color="gray.700"
                        fontWeight="400"
                        pt="2"
                        pb="4"
                        fontSize="sm"
                        alignItems="center"
                      >
                        {item.notes}
                      </Flex>
                    )}
                    {!item.notes && (
                      <Flex
                        as="i"
                        color="gray.500"
                        fontWeight="400"
                        pt="2"
                        pb="4"
                        fontSize="sm"
                        alignItems="center"
                      >
                        No notes
                      </Flex>
                    )}
                  </Flex>
                ))}
              </Flex>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default ExpandableGroup;
