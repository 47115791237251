import { Box, Button, Heading, Text, Wrap, WrapItem } from "@chakra-ui/react";
import React from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import {
  Alert,
  CenteredCardLayout,
  LoadingIndicator,
  useToast,
} from "../../../components";
import { usePageTracker } from "../../../utils/googleAnalytics";
import {
  useScheduledInterviewerOptOutMutation,
  useScheduledInterviewerQuery,
} from "../../graphql";

const InterviewerOptOutPage: React.FC = () => {
  usePageTracker("interviewer_opt_out");
  const navigate = useNavigate();
  const { interviewerId } = useParams() as { interviewerId: string };
  const {
    data: scheduledInterviewerData,
    loading: scheduledInterviewerLoading,
    error: scheduledInterviewerError,
  } = useScheduledInterviewerQuery({
    variables: { id: interviewerId },
  });
  const toast = useToast();
  const [
    updateScheduledInterviewer,
    {
      error: scheduledInterviewerOptOutError,
      loading: scheduledInterviewerOptOutLoading,
    },
  ] = useScheduledInterviewerOptOutMutation({
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
  });

  const scheduledInterviewer = scheduledInterviewerData?.scheduledInterviewer;

  if (scheduledInterviewerLoading) {
    return (
      <CenteredCardLayout>
        <Box maxW="600px" mt="8">
          <LoadingIndicator />
        </Box>
      </CenteredCardLayout>
    );
  }

  if (scheduledInterviewerError || scheduledInterviewerOptOutError) {
    // eslint-disable-next-line no-console
    console.error(scheduledInterviewerError);
    return (
      <Alert
        status="error"
        description="Error loading scheduled interviewer"
        reload
      />
    );
  }

  if (!scheduledInterviewer) return <Navigate to="/not-found" />;

  return (
    <CenteredCardLayout containerProps={{ alignItems: "center" }}>
      <Box maxW="600px">
        {scheduledInterviewer.recordingDisabled ? (
          <>
            <img src="/static/images/videocam-off-sharp.svg" width="45px" />
            <Heading size="md" lineHeight="lg" my="6">
              You’ve successfully opted out of BrightHire recording this
              interview.
            </Heading>
            <Text>
              Your interview, {scheduledInterviewer.scheduledInterview?.name}
              will not be recorded, saved, or summarized in BrightHire.
            </Text>
            <Button
              mt="10"
              variant="outline"
              isLoading={scheduledInterviewerOptOutLoading}
              onClick={() =>
                updateScheduledInterviewer({
                  variables: { id: interviewerId, recordingDisabled: false },
                })
              }
            >
              Opt back in
            </Button>
          </>
        ) : (
          <>
            <img src="/static/images/videocam-off-sharp.svg" width="45px" />
            <Heading size="md" lineHeight="lg" my="6">
              Are you sure you want to opt out of BrightHire recording this
              interview?
            </Heading>
            <Text>
              By opting out,{" "}
              {scheduledInterviewer.scheduledInterview?.name ??
                "you're upcoming interview"}{" "}
              will not be recorded, saved, or summarized in BrightHire.
            </Text>
            <Text>
              Alternatively, you can pause the recording at any point in the
              conversation, and those moments will not be recorded or saved to
              BrightHire.
            </Text>
            <Wrap mt="10" spacing="4">
              <WrapItem>
                <Button
                  variant="danger"
                  isLoading={scheduledInterviewerOptOutLoading}
                  onClick={() =>
                    updateScheduledInterviewer({
                      variables: {
                        id: interviewerId,
                        recordingDisabled: true,
                      },
                    })
                  }
                >
                  Opt out of recording
                </Button>
              </WrapItem>
              <Wrap>
                <Button variant="outline" onClick={() => navigate("/")}>
                  Cancel
                </Button>
              </Wrap>
            </Wrap>
          </>
        )}
      </Box>
    </CenteredCardLayout>
  );
};

InterviewerOptOutPage.displayName = "InterviewerOptOutPage";
export default InterviewerOptOutPage;
