import { Box, Center, Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { IoDocumentTextSharp } from "react-icons/io5";

import { CallNote, CallNoteType } from "../../graphql";
import { noteTypeColor, noteTypeIcon } from "../CallNotes/utils";

type Note = Pick<CallNote, "id" | "type">;

const REACTIONS: CallNoteType[] = [
  CallNoteType.ThumbsDown,
  CallNoteType.Flag,
  CallNoteType.ThumbsUp,
  CallNoteType.Star,
  CallNoteType.Note,
];

interface ReactionsGroupProps {
  callId: string;
  notes: Array<Pick<Note, "type">>;
  borderColor?: string;
  /*
  / Reactions group needs to know if we're only fetching current user notes only
  / because it fetches full notes for popover lazily for perf
  / The summary count and notes in popover need to agree
  */
  currentUserNotesOnly: boolean;
}

const ReactionsGroup: React.FC<ReactionsGroupProps> = ({
  callId,
  notes,
  currentUserNotesOnly,
  borderColor = "white",
}) => {
  const renderNotesSummary = (notes: any[] = []): any => (
    <Flex direction="row" alignItems="center" fontSize="xs" color="gray.500">
      <Icon as={IoDocumentTextSharp} color="gray.300" mr="3" />
      {notes.length === 0
        ? "No notes or reactions"
        : `View ${notes.length} Note${notes.length > 1 ? "s" : ""}`}
    </Flex>
  );

  if (notes.length === 0) {
    return renderNotesSummary();
  }

  const interviewReactions = notes.filter(
    (n) => REACTIONS.indexOf(n.type) > -1
  );
  const interviewNotes = notes.filter((n) => n.type === CallNoteType.Note);

  return (
    <Box
      display="inline-flex"
      alignItems="center"
      borderRadius="base"
      color="gray.600"
      fontSize="14px"
      onClick={(e) => e.stopPropagation()}
    >
      {interviewReactions.length > 0 ? (
        <>
          {REACTIONS.filter((r) =>
            interviewReactions.find((ir) => r === ir.type)
          ).map((type, i) => (
            <Center key={type} h="4" mr="1" ml={i === 0 ? "0" : "1"}>
              <Icon
                boxSize={type === CallNoteType.Star ? "19px" : "16px"}
                color={noteTypeColor(type)}
                as={noteTypeIcon(type)}
              />
              <Text m={1}>
                {`${
                  interviewReactions.filter((irs) => irs.type === type).length
                }`}
              </Text>
            </Center>
          ))}
        </>
      ) : (
        <>{renderNotesSummary(interviewNotes)}</>
      )}
    </Box>
  );
};

export default ReactionsGroup;
