import {
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";

import { useToast } from "../../../components";
import {
  CurrentUserScheduledInterviewsDocument,
  useCreateScheduledInterviewMutation,
} from "../../graphql";
import { ScheduledInterviewForm } from "../ScheduledInterviewForm";
import { FormValues } from "../ScheduledInterviewForm/ScheduledInterviewForm";

const AddScheduledInterviewModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const toast = useToast();
  const [createScheduledInterview, { loading }] =
    useCreateScheduledInterviewMutation({
      onError: (err) => {
        toast({
          title: "Error",
          description: err.message,
          status: "error",
        });
      },
      onCompleted: (data) => {
        const scheduledInterview =
          data.createScheduledInterview?.scheduledInterview;
        if (!scheduledInterview) return;
        toast({
          title: "Success",
          description: (
            <Text>
              Interview added for candidate. &nbsp;
              {scheduledInterview.candidate && (
                <Link
                  fontWeight="semibold"
                  href={`/candidate/${scheduledInterview.candidate.id}`}
                >
                  View candidate page
                </Link>
              )}
            </Text>
          ),
          status: "success",
          isClosable: true,
        });
        onClose();
      },
      refetchQueries: [CurrentUserScheduledInterviewsDocument],
    });
  const onSubmit = (formValues: FormValues): void => {
    createScheduledInterview({
      variables: {
        scheduledInterviewInput: formValues,
      },
    });
  };
  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay>
        <ModalContent>
          <ModalHeader data-testid="scheduled-interview-modal-header">
            Add BrightHire to an Interview
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody height="auto" overflow="scroll" pb={12}>
            <ScheduledInterviewForm onSubmit={onSubmit} isLoading={loading} />
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default AddScheduledInterviewModal;
