import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const CypressHistoryPatch: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.Cypress) {
      window.cyNavigate = navigate;
    }
  }, [navigate]);

  return null;
};
