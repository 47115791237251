import {
  Checkbox,
  HStack,
  Link,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { SortingRule } from "react-table";

import { LoadingIndicator, SortableTable } from "../../../components";
import { PageOptions } from "../../../hooks/useOffsetPagination";
import { formatDate } from "../../../utils/datetime";
import { buildLeverLink } from "../../../utils/lever";
import { Position, PositionJbpListItemFragment } from "../../graphql";

interface AutoJoinByPositionListProps {
  positions: Array<PositionJbpListItemFragment>;
  updatePositionsAutoJoin: (
    positionIds: Array<PositionJbpListItemFragment>,
    onOff: boolean
  ) => Promise<any>;
  pageOptions?: PageOptions;
  sortBy: SortingRule<string>;
}

const AutoJoinByPositionList: React.FC<AutoJoinByPositionListProps> = ({
  positions,
  updatePositionsAutoJoin,
  pageOptions,
  sortBy,
}) => {
  return (
    <SortableTable<Position>
      columns={[
        {
          Header: "Req ID",
          accessor: "requisitionId",
          id: "requisitionId",
          Cell: ({
            row: { original: position },
          }: {
            row: { original: PositionJbpListItemFragment };
          }) => {
            if (!position.requisitionId) return null;
            return (
              <Text overflowWrap="break-word" maxW="100px">
                {position.requisitionId}
              </Text>
            );
          },
        },
        {
          Header: "Title",
          accessor: "title",
          id: "title",
          Cell: ({
            row: { original: position },
          }: {
            row: { original: PositionJbpListItemFragment };
          }) => {
            let positionLink = `/position/${position.id}`;
            if (position.leverPostingId) {
              positionLink = buildLeverLink(position.leverPostingId);
            }
            if (position.greenhouseLink) {
              positionLink = position.greenhouseLink;
            }
            return (
              <Link
                href={positionLink}
                display="flex"
                alignItems="center"
                maxWidth="275px"
              >
                {position.title}
              </Link>
            );
          },
        },
        {
          Header: "Locations",
          accessor: "locations",
          id: "locations",
          disableSortBy: true,
          Cell: ({
            row: { original: position },
          }: {
            row: { original: PositionJbpListItemFragment };
          }) => {
            if (!position.locations) return null;
            if (position.locations.length === 0) return null;
            if (position.locations.length === 1)
              return (
                <Text overflowWrap="break-word" maxW="200px">
                  {position.locations[0]}
                </Text>
              );
            return (
              <UnorderedList maxW="200px">
                {position.locations.map((l) => (
                  <ListItem key={l}>{l}</ListItem>
                ))}
              </UnorderedList>
            );
          },
        },
        {
          Header: "Position Group",
          accessor: (position) => position.client.name,
          id: "client.name",
          Cell: ({
            row: { original: position },
          }: {
            row: { original: PositionJbpListItemFragment };
          }) => {
            if (!position.client.name) return null;
            return (
              <Text overflowWrap="break-word" maxW="200px">
                {position.client.name}
              </Text>
            );
          },
        },
        {
          Header: "Status",
          accessor: "atsStatus",
          disableSortBy: true,
          Cell: ({
            row: { original: position },
          }: {
            row: { original: PositionJbpListItemFragment };
          }) => {
            if (!position.atsStatus) return null;
            return (
              <Text overflowWrap="break-word" maxW="100px">
                {position.atsStatus}
              </Text>
            );
          },
        },
        {
          Header: "Added",
          accessor: "createdAt",
          Cell: ({
            row: { original: position },
          }: {
            row: { original: PositionJbpListItemFragment };
          }) => (
            <Text style={{ fontVariantNumeric: "tabular-nums" }} maxW="102px">
              {formatDate(position.createdAt, {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              })}
            </Text>
          ),
        },
        {
          Header: "Auto Join",
          accessor: "autoJoinInterview",
          Cell: ({
            row: { original: position },
          }: {
            row: { original: PositionJbpListItemFragment };
          }) => {
            const [loading, setLoading] = useState(false);
            return (
              <HStack align="baseline" spacing="3" maxW="120px">
                {loading ? (
                  <LoadingIndicator
                    size="xs"
                    alignSelf="flex-start"
                    width="inherit"
                  />
                ) : (
                  <Checkbox
                    id={`auto-join-check-${position.id}`}
                    alignSelf="center"
                    isChecked={position.autoJoinInterview}
                    onChange={() => {
                      setLoading(true);
                      updatePositionsAutoJoin(
                        [position],
                        !position.autoJoinInterview
                      )
                        .then(() => {
                          setLoading(false);
                        })
                        .catch(() => {
                          setLoading(false);
                        });
                    }}
                  />
                )}
              </HStack>
            );
          },
        },
      ]}
      columnWidths={[
        "102px",
        "275px",
        "200px",
        "200px",
        "100px",
        "102px",
        "120px",
      ]}
      data={positions as Position[]}
      autoResetSortBy={false}
      initialSort={sortBy}
      pageOptions={pageOptions}
      manualPagination
      width="1100px"
    />
  );
};

export default AutoJoinByPositionList;
