import {
  Box,
  Flex,
  Link,
  SystemProps,
  Text,
  useDisclosure,
  usePopper,
} from "@chakra-ui/react";
import React from "react";
import { isMobile } from "react-device-detect";

import { formatDuration } from "../../../../../utils/datetime";
import CallNotesSection from "../../../CallNotes/beta/CallNotesSection";
import { noteTimestampedLink } from "../../../CallNotes/utils";
import MonospacedText from "../../../MonospacedText";
import ScorecardNoteQuestionMenu from "./ScorecardNoteQuestionMenu";
import { NoteItem, NotesV3CommonProps } from "./types";

type ScorecardNoteQuestionProps = {
  item: NoteItem;
  containerProps?: SystemProps;
  onMarkQuestion?: (markedTime: number, scorecardQuestionId: string) => void;
} & NotesV3CommonProps;

const ScorecardNoteQuestion: React.FC<ScorecardNoteQuestionProps> = ({
  item,
  containerProps,
  onMarkQuestion,
  callId,
  clipId,
  callDuration,
  interviewerIds,
  playerTime,
  onAddNote,
  onClickTimestamp,
  notesReadOnly,
  // currentNoteId,
  setClipRange,
}) => {
  const {
    isOpen: isHovering,
    onOpen: startHover,
    onClose: stopHover,
  } = useDisclosure({
    isOpen: isMobile ? false : undefined,
  });

  const { popperRef, referenceRef } = usePopper({
    placement: "top-end",
    offset: [0, -10],
    modifiers: [
      {
        name: "preventOverflow",
        options: {
          padding: { left: 24, right: 24 },
        },
      },
    ],
  });

  return (
    <Flex direction="column" position="relative" {...containerProps}>
      {!notesReadOnly && item.marked && (
        <ScorecardNoteQuestionMenu
          callId={callId}
          item={item}
          callDuration={callDuration}
          setClipRange={setClipRange}
          popperRef={popperRef}
          hidden={!isHovering}
          onMouseEnter={startHover}
          onMouseLeave={stopHover}
        />
      )}
      <Flex
        direction="row"
        gap="4"
        px="4"
        py="3"
        bg={item.marked ? "blue.50" : undefined}
        _hover={{
          bg: "blue.50",
        }}
        ref={referenceRef}
        onMouseEnter={startHover}
        onMouseLeave={stopHover}
      >
        {item.marked && (
          <Link
            alignSelf="stretch"
            fontWeight="500"
            minWidth="12"
            onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              e.preventDefault();
              onClickTimestamp?.(item.markedTime ?? 0);
            }}
            color="blue.600"
            href={noteTimestampedLink(
              {
                callId,
                time: item.markedTime ?? 0,
              },
              item.markedTime ?? 0
            )}
          >
            <MonospacedText text={formatDuration(item.markedTime ?? 0)} />
          </Link>
        )}
        <Box flex="1">
          <Text
            cursor={!item.marked ? "pointer" : undefined}
            whiteSpace="pre-wrap"
            onClick={() => {
              onMarkQuestion?.(playerTime, item.id);
            }}
          >
            {item.itemText}
          </Text>
        </Box>
      </Flex>
      {item.marked && (
        <CallNotesSection
          callId={callId}
          clipId={clipId}
          callDuration={callDuration}
          interviewerIds={interviewerIds}
          notes={item.questionNotes}
          playerTime={playerTime}
          onAddNote={onAddNote}
          onClickTimestamp={onClickTimestamp}
          setClipRange={setClipRange}
          // currentNoteId={currentNoteId}
          notesReadOnly={notesReadOnly}
        />
      )}
    </Flex>
  );
};

export default ScorecardNoteQuestion;
