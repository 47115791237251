import { CallAiSummaryFormat } from "../../../../../graphql";

export const NotesFormatLabels = {
  [CallAiSummaryFormat.Qanda]: "Interview notes",
  [CallAiSummaryFormat.Intake]: "Intake",
  [CallAiSummaryFormat.Debrief]: "Candidate debrief",
  [CallAiSummaryFormat.Sales]: "Sales call",
  [CallAiSummaryFormat.Scorecard]: "Scorecard",
  [CallAiSummaryFormat.ProductDemoFeedback]: "Product research",
  [CallAiSummaryFormat.Writeup]: "Summary",
  [CallAiSummaryFormat.Tldr]: "Interview summary",
  [CallAiSummaryFormat.Custom]: "Custom template",
};
