import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import {
  Alert,
  errorToast,
  successToast,
  useToast,
} from "../../../../components";
import useDebounce from "../../../../hooks/useDebounce";
import useSelectTheme from "../../../../hooks/useSelectTheme";
import {
  CallBetaFragment,
  Maybe,
  useCandidateNamesLazyQuery,
  useOrgPositionsQuery,
  useUpdateCallMutation,
} from "../../../graphql";

export type EditCallDetailField = "candidate" | "position" | "name";

type EditCallDetailsFormData = {
  candidate: Maybe<{
    id: string;
    fullName: string;
  }>;
  position: Maybe<{
    id: string;
    displayTitle: string;
  }>;
  name: string;
};

type EditCallDetailsModalProps = {
  call: Pick<CallBetaFragment, "id" | "candidate" | "position" | "name">;
  focusedField?: EditCallDetailField | null;
  onClose(): void;
  onUpdate(callDetails: EditCallDetailsFormData): Promise<unknown>;
};

const EditCallDetailsModal: React.FC<EditCallDetailsModalProps> = ({
  call,
  onClose,
  onUpdate,
  focusedField,
}) => {
  const toast = useToast();
  const selectTheme = useSelectTheme();
  const [awaitingUpdate, setAwaitingUpdate] = useState(false);
  const [hasCandidate, setHasCandidate] = useState(false);

  useEffect(() => setHasCandidate(!!call.candidate?.id), []);

  const { register, handleSubmit, control, resetField } =
    useForm<EditCallDetailsFormData>({
      defaultValues: {
        candidate: call.candidate ?? null,
        position: call.position ?? null,
        name: call.name ?? "",
      },
    });

  /**
   * If a new candidate or position is created from the
   * creatable select, reset that field
   */
  useEffect(() => {
    if (call.candidate?.id) {
      resetField("candidate", { defaultValue: call.candidate });
    }
    if (call.position?.id) {
      resetField("position", { defaultValue: call.position });
    }
  }, [call.candidate?.id, call.position?.id]);

  const modal = useDisclosure({ isOpen: true, onClose });

  const onSubmitEditDetails = handleSubmit(async (values) => {
    setAwaitingUpdate(true);
    await onUpdate(values);
    setAwaitingUpdate(false);
    modal.onClose();
  });

  const [candidateSearchTerm, setCandidateSearchTerm] = useState("");
  const debouncedCandidateSearchTerm = useDebounce(candidateSearchTerm, 250);
  const [getCandidates, { data: candidatesData, loading: candidatesLoading }] =
    useCandidateNamesLazyQuery({
      variables: {
        pagination: {
          page: 1,
          limit: 15,
          orderBy: "lastCallAt desc",
          searchTerm: debouncedCandidateSearchTerm,
        },
      },
      onError: () => errorToast(toast, "Error loading candidates"),
    });
  useEffect(() => {
    if (!call.candidate?.id) {
      getCandidates();
    }
  }, [call.candidate?.id]);
  const candidates = candidatesData?.candidates.results ?? [];

  const [positionSearchTerm, setPositionSearchTerm] = useState("");
  const debouncedPositionSearchTerm = useDebounce(positionSearchTerm, 250);
  const { data: positionsData, loading: positionsLoading } =
    useOrgPositionsQuery({
      variables: {
        pagination: {
          page: 1,
          limit: 15,
          orderBy: "lastCallAt desc",
          searchTerm: debouncedPositionSearchTerm,
        },
      },
      onError: () => errorToast(toast, "Error loading positions"),
    });
  const positions =
    positionsData?.currentUser?.organization.positions.results ?? [];

  const [updateCall, { loading: addingCandidate }] = useUpdateCallMutation({
    onCompleted(data) {
      if (data.updateCall?.call.candidate) {
        successToast(toast, "Candidate successfully added to interview");
      }
    },
  });

  const addCandidate = async (fullName: string): Promise<void> => {
    const [firstName, ...lastNameParts] = fullName
      .split(" ")
      .filter((s) => !!s);
    const lastName = lastNameParts.join(" ");

    if (!addingCandidate && firstName) {
      // Remove current candidate from call, if present.
      // Without this, the current candidate's name will be updated
      // to the new candidate's name, and no new candidate will be created
      if (call.candidate?.id) {
        await updateCall({
          variables: {
            id: call.id,
            candidateId: "",
          },
        });
      }

      updateCall({
        variables: {
          id: call.id,
          candidateFirstName: firstName,
          candidateLastName: lastName,
        },
      });
    }
  };

  return (
    <Modal {...modal} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="semibold" fontSize="22px">
          Update Interview Details
        </ModalHeader>
        <ModalCloseButton color="gray.600" />

        <form onSubmit={onSubmitEditDetails}>
          <ModalBody>
            <FormControl mb="4" display={hasCandidate ? "none" : undefined}>
              <FormLabel>Candidate</FormLabel>
              <Controller
                name="candidate"
                control={control}
                render={({ field }) => (
                  <CreatableSelect
                    {...selectTheme}
                    {...field}
                    autoFocus={focusedField === "candidate"}
                    placeholder="Select Candidate"
                    isClearable
                    onInputChange={setCandidateSearchTerm}
                    onCreateOption={addCandidate}
                    options={candidates}
                    isLoading={candidatesLoading || addingCandidate}
                    noOptionsMessage={() => "No candidates found"}
                    createOptionPosition="first"
                    getOptionLabel={(candidate) =>
                      candidate.fullName || "Candidate"
                    }
                    getOptionValue={(candidate) => candidate.id}
                    getNewOptionData={(fullName) => ({
                      id: "",
                      fullName: `Create "${fullName}"`,
                    })}
                  />
                )}
              />
            </FormControl>

            <FormControl mb="4">
              <FormLabel>Position</FormLabel>
              <Controller
                name="position"
                control={control}
                render={({ field }) => (
                  <>
                    {/*
                    TODO: Position creation is currently disabled since that
                          requires a "Position Group" / `clientId` field
                    */}
                    <Select
                      {...selectTheme}
                      {...field}
                      autoFocus={focusedField === "position"}
                      placeholder="Select Position"
                      onInputChange={setPositionSearchTerm}
                      aria-label="position-select"
                      isClearable
                      // onCreateOption={}
                      options={positions}
                      isLoading={positionsLoading}
                      noOptionsMessage={() => "No positions found"}
                      // createOptionPosition="first"
                      getOptionLabel={(position) => position.displayTitle}
                      getOptionValue={(position) => position.id}
                      // getNewOptionData={(displayTitle) => ({
                      //   id: "",
                      //   displayTitle: `Create "${displayTitle}"`,
                      // })}
                    />
                    {field.value?.id && field.value.id !== call.position?.id && (
                      <FormHelperText>
                        <Alert
                          status="warning"
                          description={`
                          Updating the position for this interview changes its visibility.
                          It will now be visible to team members and hiring team admins in
                          ${field.value?.displayTitle || "the new position"}
                          `}
                        />
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Interview Name</FormLabel>
              <Input
                {...register("name")}
                placeholder="Set Name"
                autoFocus={focusedField === "name"}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter px="6" py="4" borderTopWidth="1px">
            <Button
              variant="white"
              mr="2"
              onClick={modal.onClose}
              fontWeight="medium"
              fontSize="sm"
              type="button"
              disabled={awaitingUpdate}
            >
              Cancel
            </Button>

            <Button
              fontWeight="medium"
              fontSize="sm"
              type="submit"
              isLoading={awaitingUpdate}
              data-testid="confirmation-modal-submit"
            >
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default EditCallDetailsModal;
