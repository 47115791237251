import { extendTheme } from "@chakra-ui/react";
import { Styles } from "@chakra-ui/theme-tools";

import { breakpoints } from "./breakpoints";
import colors from "./colors";
import components from "./components";
import colorVars from "./css-color-variables";

const space = {
  "4.5": "1.125rem",
  pagePadding: "2.5rem",
  pagePaddingMobile: "1.5rem",
};

const sizes = {
  /**
   * DEPRECATED: This hard-coded value is counter to making mobile responsive
   *             designs. Please do not use it! We should work to remove its
   *             few existing usages going forward
   */
  pageMinWidth: "900px",
  pageMinWidthMobile: "100%",
  pagePadding: {
    base: "pagePaddingMobile",
    md: "pagePadding",
  },
};

const borders = {
  gray: {
    100: `1px solid ${colorVars.gray[100]} !important`,
    200: `1px solid ${colorVars.gray[200]} !important`,
    300: `1px solid ${colorVars.gray[300]} !important`,
  },
};

const shadows = {
  tight: "2px 3px 3px rgba(0, 0, 0, 0.06)",
  subtle: "1px 3px 20px rgba(0, 0, 0, 0.2)",
  elevate: "2px 3px 10px rgba(0, 0, 0, 0.08)",
  top: "0 -5px 5px -5px rgba(0,0,0,0.1)",
  right: "5px 0 5px -5px rgba(0,0,0,0.1)",
  bottom: "0 5px 5px -5px rgba(0,0,0,0.1)",
  left: "-5px 0 5px -5px rgba(0,0,0,0.1)",
};

const overlays = {
  light: "rgba(0, 0, 0, .06)",
  lightHover: "rgba(0, 0, 0, .24)",
  medium: "rgba(26, 32, 44, 0.5)",
};

const styles: Styles = {
  global: {
    html: {
      height: "100%", // For iframe presentation on sidepanel
      WebkitFontSmoothing: "auto",
    },
    body: {
      fontFamily: "body",
      color: "primary",
      bg: "pageBackground",
      lineHeight: "base",
    },
    "*::placeholder": {
      color: "placeholder",
    },
    "*, *::before, &::after": {
      borderColor: "border",
    },
    ".pageheader-nav-menu [data-popper-placement='bottom-start']": {
      left: "0 !important",
      right: "0 !important",
    },
    ".chakra-menu__icon-wrapper > svg": {
      verticalAlign: "middle",
    },
    ".you-shall-not-drag": {
      cursor: "not-allowed !important",
    },
  },
};

const theme = extendTheme({
  borders,
  breakpoints,
  colors,
  components,
  space,
  sizes,
  shadows,
  overlays,
  styles,
});

export const interTheme = extendTheme({
  borders,
  breakpoints,
  colors,
  components,
  fonts: {
    heading: "Inter, sans-serif",
    body: "Inter, sans-serif",
  },
  space,
  sizes,
  shadows,
  overlays,
  styles,
});

export type Theme = typeof theme;

export default theme;
