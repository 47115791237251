import {
  Box,
  Button,
  Checkbox,
  Flex,
  Image,
  Input,
  Text,
} from "@chakra-ui/react";
import React from "react";

import { CheckmarkIcon } from "../../../components/Icons/CheckmarkIcon";
import QuestionMenu from "./QuestionMenu";
import { TrainingQuestion } from "./types";
import { TrainingApi } from "./useTrainingApi";

type AssessmentStepProps = {
  trainingProgramId: string;
  trainingQuestions: TrainingQuestion[];
  assessmentEnabled: boolean;
  trainingApi: TrainingApi;
  setAutoSaveInProgress: (autoSaveInProgress: boolean) => void;
};

const AssessmentStep: React.FC<AssessmentStepProps> = ({
  trainingProgramId,
  trainingQuestions,
  assessmentEnabled,
  trainingApi,
  setAutoSaveInProgress,
}) => {
  return (
    <Box>
      <Flex
        background="linear-gradient(90deg, #E9F7FA 0%, #F0FBFA 100%);"
        pl="10"
      >
        <Box py="56px">
          <Text mb="6" color="gray.600" pr="20">
            Trainees can answer a brief learning assessment alongside each
            recording in this program using the questions you choose below.
            Submitted assessments will be visible for any admin to review in the
            &apos;Trainees&apos; tab, and each trainee will be manually marked
            &apos;complete&apos; by an admin upon review.
          </Text>
          <Checkbox
            data-tour-id="training-enable-assessment"
            display="block"
            isChecked={assessmentEnabled}
            onChange={() => {
              trainingApi.questions.enable(
                trainingProgramId,
                !assessmentEnabled
              );
              setAutoSaveInProgress(true);
            }}
            lineHeight="16px"
            variant="ghostOutline"
            spacing="4"
            icon={<CheckmarkIcon width="9px" height="6px" />}
          >
            <Text
              display="inline"
              fontSize="sm"
              fontWeight={500}
              color="color.900"
            >
              Include assessment questions for this program
            </Text>
          </Checkbox>
        </Box>

        <Box // Crop image
          overflow="hidden"
          flexShrink={0}
          mt="3"
          mb="-44px"
          mx="auto"
        >
          <Image src="/static/images/training_assement.png" width="370px" />
        </Box>
      </Flex>
      {!assessmentEnabled && (
        <Box
          borderColor="gray.100"
          borderRadius="4px"
          borderWidth="1px"
          ml="10"
          mt="10"
          maxWidth="536px"
        >
          <Text
            padding="4"
            fontSize="sm"
            borderColor="blue.600"
            borderLeftWidth="2px"
            borderRadius="4px"
          >
            If no assessment questions are included, trainees will just be asked
            to watch the recordings in this program, and will be automatically
            marked ‘complete’ after viewing all recordings.
          </Text>
        </Box>
      )}
      {assessmentEnabled && (
        <Box mt="10" ml="10" data-tour-id="training-assessment-questions">
          <Text fontWeight={500} mb="6">
            What questions should a trainee answer alongside each recording?
          </Text>
          <Button
            variant="ghost"
            size="sm"
            mb="6"
            onClick={() => {
              const maxOrder = trainingQuestions
                .map((q) => q.order)
                .reduce((prev, cur) => Math.max(prev, cur), 0);
              trainingApi.questions.add(trainingProgramId, "", maxOrder + 1);
            }}
          >
            + Add a question
          </Button>
          <Button
            variant="chalk"
            size="sm"
            mb="6"
            onClick={() => {
              trainingApi.questions.reset(trainingProgramId);
              setAutoSaveInProgress(true);
            }}
            ml="4"
          >
            Reset questions
          </Button>
          {trainingQuestions.map((question, idx) => (
            <Flex mb="4" alignItems="center" key={question.id}>
              <Input
                defaultValue={question.question}
                maxWidth="625px"
                fontSize="sm"
                onBlur={(e) => {
                  const newQuestion = e.target.value;
                  if (newQuestion !== question.question) {
                    trainingApi.questions.update(question.id, e.target.value);
                    setAutoSaveInProgress(true);
                  }
                }}
              />
              <QuestionMenu
                trainingProgramId={trainingProgramId}
                trainingApi={trainingApi}
                trainingQuestions={trainingQuestions}
                question={question}
                listPosition={idx}
                menuDisabled={trainingQuestions.length === 1}
                setAutoSaveInProgress={setAutoSaveInProgress}
                onDelete={() => {
                  trainingApi.questions.remove(question.id);
                  setAutoSaveInProgress(true);
                }}
              />
            </Flex>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default AssessmentStep;
