import { useEffect } from "react";

import { useViewHighlightMutation } from "../../graphql";
import useMouseHasMoved from "../../hooks/useMouseHasMoved";
import { CandidatePositionChapter, isChapter } from "./compare/types";
import { CallHighlight } from "./types";
import { isAiNoteHighlight } from "./utils";

/**
 * Sends a `viewHighlight` mutation if the mouse has moved and we
 * have not yet sent the mutation for this highlight (in this session)
 */
const useViewHighlight = (
  item: CallHighlight | CandidatePositionChapter
): void => {
  const mouseHasMoved = useMouseHasMoved();
  const [viewHighlight, { called: alreadyLogged }] = useViewHighlightMutation({
    variables: {
      callId: isChapter(item) ? item.callId : item.call.id,
      highlightId: item.id,
      highlightType: isChapter(item)
        ? "Chapter"
        : isAiNoteHighlight(item)
        ? "AiNoteHighlight"
        : "CallNoteHighlight",
    },
  });

  useEffect(() => {
    if (mouseHasMoved && !alreadyLogged) {
      viewHighlight();
    }
  }, [mouseHasMoved, alreadyLogged]);
};

export default useViewHighlight;
