import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

// TODO: migrate to main/components once this has gone through
// design polish

const MainContent: React.FC<BoxProps> = ({ children, ...boxProps }) => {
  return (
    <Box
      bg="white"
      flex="1"
      borderLeftWidth="1px"
      borderLeftColor="gray.100"
      minWidth="0"
      pt="7"
      overflow="auto"
      {...boxProps}
    >
      {children}
    </Box>
  );
};

export default MainContent;
