import { AlertStatus, Box, Flex, Icon, Spacer, Text } from "@chakra-ui/react";
import React from "react";
import { MdCheckCircle, MdError, MdInfo, MdWarning } from "react-icons/md";

const StatusIcons = {
  info: MdInfo,
  success: MdCheckCircle,
  warning: MdWarning,
  error: MdError,
  loading: null,
  default: null,
};

const colorMap: { [key: string]: string } = {
  info: "blue.600",
  success: "blue.600",
  warning: "yellow.600",
  error: "red.500",
  default: "gray.700",
};

export interface CallBannerProps {
  onClose: () => void;
  description: string | React.ReactNode;
  status: AlertStatus | "default";
  icon?: any;
  showHideButton?: boolean;
}

const CallBanner: React.FC<CallBannerProps> = ({
  onClose,
  description,
  status,
  icon,
  showHideButton = true,
}) => {
  return (
    <Flex bg={colorMap[status]} p={3} mx={2} borderRadius={4} align="center">
      <Icon w="6" h="6" mr={6} as={icon || StatusIcons[status]} color="white" />
      <Box color="white">{description}</Box>
      <Spacer />
      {showHideButton && (
        <Box color="white" cursor="pointer" ml="2">
          <Text as="u" onClick={onClose}>
            Hide
          </Text>
        </Box>
      )}
    </Flex>
  );
};
export default CallBanner;
