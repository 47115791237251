import { copy } from "../../../../../../utils/clipboard";
import { formatDuration } from "../../../../../../utils/datetime";
import { callTimestampedLink } from "../../../../CallNotes/utils";
import { NoteFormat, NoteLength, NoteMap, SummaryCallNote } from "./types";

export const getTextByLength = (
  note: SummaryCallNote,
  noteLength: NoteLength
): string => {
  if (noteLength === NoteLength.Med && note.variation1) {
    return note.variation1;
  }
  if (noteLength === NoteLength.Short && note.variation2) {
    return note.variation2;
  }
  return note.text;
};

type CopySummaryParams = {
  callId: string;
  selectedNotes: string[];
  notesMap: NoteMap;
  defaultNoteLength: NoteLength;
  individualNoteLengths: Record<string, NoteLength>;
  noteFormat: NoteFormat;
  includeTimestamps?: boolean;
};

export const copySummary = ({
  callId,
  selectedNotes,
  notesMap,
  defaultNoteLength,
  individualNoteLengths,
  noteFormat,
  includeTimestamps,
}: CopySummaryParams): void => {
  let plain = "";
  let html = "";

  selectedNotes.forEach((noteId) => {
    const section = notesMap[noteId];
    if (!section.note.text) return;

    const lengthForNote = individualNoteLengths[noteId] ?? defaultNoteLength;
    const noteText = getTextByLength(section.note, lengthForNote);

    if (noteFormat === NoteFormat.Paragraph) {
      const timeStamps = section.note.startTimes.filter((s) => !!s) as number[];
      if (timeStamps.length > 0 && includeTimestamps) {
        const formattedTimeStamps = timeStamps.map((s) => formatDuration(s));
        plain += `${noteText} (${formattedTimeStamps.join(", ")}) `;
        html += `${noteText} (${timeStamps
          .map(
            (timeStamp, i) =>
              `<a href="${callTimestampedLink(callId, timeStamp)}">${
                formattedTimeStamps[i]
              }</a>`
          )
          .join(", ")}) `;
      } else {
        html += `${noteText} `;
        plain += `${noteText} `;
      }
    } else if (noteFormat === NoteFormat.Bullets) {
      const noteTitle = section.note.tag ?? section.sectionTitle;
      const timeStamps = section.note.startTimes.filter((s) => !!s) as number[];

      if (timeStamps.length > 0 && includeTimestamps) {
        const formattedTimeStamps = timeStamps.map((s) => formatDuration(s));

        plain += `${noteTitle} (${formattedTimeStamps.join(", ")}):\n`;
        html += `<strong>${noteTitle}</strong> (${timeStamps
          .map(
            (timeStamp, i) =>
              `<a href="${callTimestampedLink(callId, timeStamp)}">${
                formattedTimeStamps[i]
              }</a>`
          )
          .join(", ")}):<br>`;
      } else {
        plain += `${noteTitle}:\n`;
        html += `<strong>${noteTitle}</strong><br>`;
      }

      html += "<ul>";
      noteText.split(".").forEach((bullet) => {
        if (!bullet) return;
        html += `<li>${bullet}</li>`;
        plain += `- ${bullet.trim()}\n`;
      });
      html += "</ul>";
      plain += "\n";
      html += "<br>";
    }
  });

  copy({ plain, html });
};
