import { Box, Grid, SystemProps } from "@chakra-ui/react";
import React from "react";

import { useIsSmallScreen } from "../../../../hooks/useIsSmallScreen";

type BaseListItemProps = Omit<SystemProps, "gridTemplateColumns"> & {
  /**
   * Shares space with `centerCol`, if present. Else, fills all available space
   */
  leftCol: React.ReactNode;
  /**
   * If present, will give three-column layout. Else `leftCol` will
   * fill available space
   */
  centerCol?: React.ReactNode;
  /**
   * Fixed-sized (3-col) or auto-sized (2-col) rightmost column
   */
  rightCol?: React.ReactNode;
  /**
   * Content to display if viewport is smaller than `"lg"` or if `showMobile` is `true`
   */
  mobile: React.ReactNode;
  /**
   * Allows opting-in to mobile view on breakpoints >= `"lg"`.
   *
   * Note: mobile view will always be used (and this prop will be ignored)
   * on breakpoints < "lg"
   */
  showMobile?: boolean;
};

/**
 * Lays out basic structure for a list item on desktop and mobile.
 * Can be 2 or 3 columns on desktop, and mobile is just a box
 */
const BaseListItem: React.FC<BaseListItemProps> = ({
  leftCol,
  centerCol,
  rightCol,
  mobile,
  showMobile,
  ...styles
}) => {
  const isSmallScreen = useIsSmallScreen();
  if (showMobile || isSmallScreen) {
    return (
      <Box
        p={4}
        mb={3}
        border="1px solid"
        borderColor="gray.200"
        borderRadius="lg"
        {...styles}
      >
        {mobile}
      </Box>
    );
  }

  const threeColLayout = "minmax(0, 2fr) minmax(0, 1fr) 190px";
  const twoColLayout = "1fr auto";

  return (
    <Grid
      templateColumns={centerCol ? threeColLayout : twoColLayout}
      gap={3}
      p={4}
      mb={3}
      border="1px solid"
      borderColor="gray.100"
      borderRadius="lg"
      fontWeight="semibold"
      {...styles}
    >
      <Box>{leftCol}</Box>
      <Box hidden={!centerCol}>{centerCol}</Box>
      <Box>{rightCol}</Box>
    </Grid>
  );
};

export default BaseListItem;
