import React, { SVGAttributes } from "react";

type IconProps = Pick<
  SVGAttributes<SVGSVGElement>,
  "width" | "height" | "fill"
> & {
  marginRight?: string;
};

export const SmartrecruitersIcon: React.FC<IconProps> = ({
  width,
  height,
  fill,
  marginRight = "0.4rem",
}) => {
  return (
    <svg
      width={width || "90px"}
      height={height || "110px"}
      viewBox="0 0 90 110" // 81.82% ratio
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: "inline", marginRight }}
    >
      <path
        d="M38.495 0.553129C40.695 0.253129 42.895 0.153142 44.995 0.253142C46.195 0.0531417 47.395 0.0531783 48.595 0.353178C61.695 1.45318 73.695 8.15318 81.395 18.8532C87.695 27.5532 90.295 38.4531 88.795 49.0531C87.295 58.1531 82.995 66.5531 76.395 73.0531C70.095 79.3531 65.795 87.3531 63.895 96.0531C63.595 98.4531 62.995 100.753 62.195 102.953C60.595 105.653 58.095 107.653 55.095 108.453C52.695 109.153 50.295 109.653 47.795 109.853C45.595 109.653 43.395 109.653 41.195 109.753C36.995 109.653 32.895 108.353 29.495 105.953C27.495 104.353 26.295 101.953 25.995 99.4532C24.595 89.5532 19.995 80.3531 12.995 73.2531C6.095 66.5531 1.695 57.8532 0.394998 48.3532C0.0949977 46.6532 -0.00500536 44.9531 0.0949946 43.2531C0.0949946 33.1531 3.695 23.4532 10.295 15.8532C17.495 7.45318 27.595 1.95313 38.495 0.553129Z"
        fill={fill || "#0BB147"}
      />
      <path
        d="M43.195 20.0532C47.995 19.6532 52.695 20.5533 56.995 22.7533C59.895 24.3533 61.995 27.0533 62.695 30.3533C62.995 32.0533 62.995 33.8532 62.895 35.5532C62.795 36.1532 62.295 36.6533 61.695 36.6533H55.495C54.995 36.6533 54.495 36.3533 54.295 35.8533C53.795 34.0533 54.595 32.0533 53.695 30.3533C52.895 29.0533 51.695 28.0533 50.195 27.6533C47.595 26.9533 44.895 26.7532 42.195 27.0532C40.195 27.0532 38.195 27.7533 36.495 28.9533C35.495 29.8533 34.995 31.0533 34.995 32.3533C34.795 33.5533 34.995 34.7533 35.395 35.9533C36.195 37.3533 37.595 38.3533 39.095 38.7533C44.395 40.6533 49.795 42.4533 55.095 44.3533C57.595 45.2533 59.895 46.7533 61.695 48.7533C62.895 50.3533 63.595 52.2533 63.595 54.2533V59.9533C63.595 62.4533 62.595 64.9533 60.895 66.7533C59.195 68.5533 56.995 69.8532 54.695 70.5532C50.895 71.7532 46.895 72.2533 42.895 71.9533C38.395 71.9533 34.095 70.8532 30.195 68.5532C27.495 66.8532 25.695 63.9533 25.495 60.6533C25.495 59.3533 25.395 57.9532 25.495 56.5532C25.595 55.6532 26.595 55.4533 27.395 55.4533H32.495C33.195 55.3533 33.895 55.8532 33.995 56.5532C34.295 58.3532 33.595 60.5532 34.895 62.0532C36.695 63.7532 39.095 64.6533 41.595 64.7533C44.795 65.1533 48.095 64.8533 51.295 63.8533C52.895 63.3533 54.195 62.0533 54.595 60.4533C54.695 59.0533 54.695 57.7533 54.695 56.2533C54.595 55.2533 54.095 54.2532 53.295 53.5532C52.195 52.7532 50.895 52.0533 49.595 51.7533C44.495 49.9533 39.395 48.2533 34.295 46.4533C30.695 45.2533 27.995 42.4533 26.695 38.8533C26.295 37.3533 26.195 35.8533 26.295 34.3533C26.195 32.5533 26.295 30.7533 26.795 28.9533C27.795 25.9533 29.995 23.5533 32.895 22.2533C35.995 20.8533 39.595 20.0532 43.195 20.0532Z"
        fill="#FDFEFD"
      />
    </svg>
  );
};

export default SmartrecruitersIcon;
