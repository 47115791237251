import { useSearchParams } from "react-router-dom";

/**
 * Mimics `React.setState` but the value is stored as
 * a URL search param under `key`
 */
export const useUrlState = <T extends string>({
  key,
  defaultVal,
  replace = true,
  onChange,
}: {
  key: string;
  defaultVal?: T;
  replace?: boolean;
  onChange?(val: T): void;
}): [T, (val: T | null) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();

  return [
    (searchParams.get(key) as T) ?? defaultVal,
    (val) => {
      if (val) searchParams.set(key, val as string);
      else searchParams.delete(key);
      setSearchParams(searchParams, { replace });
      onChange?.(val as T);
    },
  ];
};

type TabUrlStateParams = {
  /**
   * The names of the tabs as they should appear in the URL
   */
  tabNames: string[];
  /**
   * The name of the URL search param to use for the current tab.
   * Default is `"tab"`
   */
  urlKey?: string;
  /**
   * Callback to be fired whenever the tab changes
   */
  onTabChange?(i: number): void;
};

/**
 * Sets the current tab name in the URL and returns a
 * `setState` pair for the current tab index
 */
const useTabUrlState = ({
  tabNames,
  urlKey = "tab",
  onTabChange,
}: TabUrlStateParams): [number, (i: number) => void] => {
  const lowerCasedTabNames = tabNames.map((s) => s.toLowerCase());
  const [tabName, setTabName] = useUrlState({ key: urlKey });
  const tabIndex =
    tabName && lowerCasedTabNames.includes(tabName)
      ? lowerCasedTabNames.indexOf(tabName)
      : 0;

  return [
    tabIndex,
    (i) => {
      onTabChange?.(i);
      setTabName(i > 0 ? lowerCasedTabNames[i] : null);
    },
  ];
};

export default useTabUrlState;
