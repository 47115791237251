import { useEffect, useMemo, useState } from "react";

import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { escapeRegExp } from "../../../../utils/string";
import { TranscriptSegmentFragment } from "../../../graphql";

export type SegmentPosition = {
  startIndex: number;
  endIndex: number;
  scrollIndex: number;
};

export type MatchPosition = {
  isActiveMatch: boolean;
  startIndex: number;
  endIndex: number;
  segmentPosition?: SegmentPosition;
};

const useTranscriptSearchBeta = (): {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  calculateSegmentPositions: (transcript: TranscriptSegmentFragment[]) => void;
  matchPositions: MatchPosition[];
  segmentPositions: SegmentPosition[];
  activeMatchIndex: number;
  activeMatchPosition: MatchPosition | null;
  goToNextSearchResult: () => void;
  goToPrevSearchResult: () => void;
} => {
  const [segmentPositions, setSegmentPositions] = useState<SegmentPosition[]>(
    []
  );
  const [matchPositions, setMatchPositions] = useState<MatchPosition[]>([]);
  const [activeMatchIndex, setActiveMatchIndex] = useState(0);
  const sendGAEvent = useSendGAEvent();

  const goToNextSearchResult = (): void => {
    if (matchPositions.length < 2) {
      return;
    }
    let nextIndex = activeMatchIndex + 1;
    if (nextIndex >= matchPositions.length) {
      nextIndex = 0;
    }
    setActiveMatchIndex(nextIndex);
  };

  const goToPrevSearchResult = (): void => {
    if (matchPositions.length < 2) {
      return;
    }
    let nextIndex = activeMatchIndex - 1;
    if (nextIndex < 0) {
      nextIndex = matchPositions.length - 1;
    }
    setActiveMatchIndex(nextIndex);
  };

  const [transcriptText, setTranscriptText] = useState<string>("");
  const calculateSegmentPositions = (
    transcript: TranscriptSegmentFragment[]
  ): void => {
    let fullText = "";
    const segmentPositions: SegmentPosition[] = [];
    let startIndex = 0;
    transcript.forEach((segment, index) => {
      const sentence = segment.words
        .reduce((previousValue, currentValue) => {
          return `${previousValue} ${currentValue.word}`;
        }, "")
        .trim();
      fullText += `${sentence}`;
      segmentPositions.push({
        startIndex,
        endIndex: startIndex + (sentence.length - 1),
        scrollIndex: index,
      });
      startIndex += sentence.length;
    });
    setTranscriptText(fullText.toLowerCase());
    setSegmentPositions(segmentPositions);
  };

  const searchTranscript = (searchRegex: any): void => {
    setMatchPositions([]);
    setActiveMatchIndex(0);
    if (!searchRegex) {
      return;
    }
    const matches = [...transcriptText.matchAll(searchRegex)];
    const matchPositions = matches
      .filter((match) => typeof match.index !== "undefined")
      .map((match, index) => {
        const segmentPosition = findSegmentPosition(
          segmentPositions,
          match.index ?? 0
        );
        return {
          isActiveMatch: index === activeMatchIndex,
          startIndex: match.index ?? 0,
          endIndex: (match.index ?? 0) + (match[0].length - 1),
          segmentPosition,
        };
      });
    setMatchPositions(matchPositions);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const searchRegex = useMemo(() => {
    const term = searchTerm.toLowerCase().trim();
    if (term.length > 0) {
      sendGAEvent("transcript_search", "call_review", term);
    }
    return term.length ? new RegExp(`${escapeRegExp(term)}`, "g") : null;
  }, [searchTerm]);

  useEffect(() => searchTranscript(searchRegex), [searchRegex]);

  const recalculateActiveMatch = (activeMatchIndex: number): void => {
    setMatchPositions(
      matchPositions.map((match, index) => {
        return { ...match, isActiveMatch: index === activeMatchIndex };
      })
    );
  };

  useEffect(() => recalculateActiveMatch(activeMatchIndex), [activeMatchIndex]);

  return {
    calculateSegmentPositions,
    searchTerm,
    setSearchTerm,
    matchPositions,
    segmentPositions,
    activeMatchIndex,
    activeMatchPosition: matchPositions.length
      ? matchPositions[activeMatchIndex]
      : null,
    goToNextSearchResult,
    goToPrevSearchResult,
  };
};

/**
 * Given a search match's start index, find the corresponding segment it belongs to
 * @param segmentPositions Array of segment positions, generated when we index the transcript
 * @param matchIndex The index of the first character of the search match in the full transcript
 */
const findSegmentPosition = (
  segmentPositions: Array<SegmentPosition>,
  matchIndex: number
): SegmentPosition | undefined => {
  return segmentPositions.find(
    (pos) => pos.startIndex <= matchIndex && pos.endIndex >= matchIndex
  );
};

export default useTranscriptSearchBeta;
