import { Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";

import { useSendGAEvent } from "../../../../../utils/googleAnalytics";
import {
  CallBetaFragment,
  Candidate,
  InterviewerScorecardFragment,
  ScorecardItem,
} from "../../../../graphql";
import OtherUsersScore from "./OtherUsersScore";
import ViewCandidateScoresButton from "./ViewCandidateScoresButton";

type OtherUserScorecard = Pick<
  InterviewerScorecardFragment,
  | "id"
  | "overallScoringEnabled"
  | "overallNotes"
  | "overallScore"
  | "interviewer"
> & {
  competencies: Pick<ScorecardItem, "id" | "itemText" | "score" | "notes">[];
};

type OtherUsersScoresProps = {
  scorecards: OtherUserScorecard[];
  /** Whether to include a "View candidate" link */
  showViewCandidate?: boolean;
  call: Pick<CallBetaFragment, "id" | "candidate" | "position" | "startTime">;
  candidate?: Pick<Candidate, "id">;
};

/** Shows scorecards for interviewers other than the current user  */
const OtherUsersScores: React.FC<OtherUsersScoresProps> = ({
  scorecards,
  showViewCandidate,
  call,
  candidate,
}) => {
  const sendGAEvent = useSendGAEvent();
  useEffect(() => {
    sendGAEvent("review_scores", "scoring", "Hiring Manager Review");
  }, []);
  return (
    <Flex direction="column" gap="3">
      <Flex alignSelf="flex-end" alignItems="center" my="0.5">
        {showViewCandidate && candidate && (
          <ViewCandidateScoresButton size="sm" candidateId={candidate.id} />
        )}
      </Flex>
      {scorecards.map((scorecard) => (
        <OtherUsersScore key={scorecard.id} call={call} scorecard={scorecard} />
      ))}
    </Flex>
  );
};

export default OtherUsersScores;
