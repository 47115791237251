import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React from "react";
import { HiOutlinePencil } from "react-icons/hi";
import { MdContentCopy, MdDelete, MdMoreVert, MdRemove } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import { useToast } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { CallGuideFragment } from "../../graphql";
import useDeleteCallGuide from "../../graphql/hooks/CallGuide/useDeleteCallGuide";
import useDuplicateCallGuide from "../../graphql/hooks/CallGuide/useDuplicateCallGuide";
import useUpdateCallGuide from "../../graphql/hooks/CallGuide/useUpdateCallGuide";
import useCurrentUser from "../../hooks/useCurrentUser";
import EditCallGuideModal from "../../pages/guide/EditGuideModal";

interface CallGuideMenuProps {
  callGuide: CallGuideFragment;
  showRemove?: boolean;
  redirectOnDelete?: boolean;
}

const CallGuideMenu: React.FC<CallGuideMenuProps> = ({
  callGuide,
  showRemove = false,
  redirectOnDelete = false,
}) => {
  /**
   * Whether the Delete modal is open
   */
  const [isOpen, setIsOpen] = React.useState(false);
  const sendGAEvent = useSendGAEvent();

  /**
   * Whether the Edit modal is open
   */
  const [editingGuide, setEditingGuide] = React.useState(false);

  const cancelRef = React.useRef<HTMLButtonElement | null>(null);
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const toast = useToast();
  const [duplicateCallGuide] = useDuplicateCallGuide({
    onError: () => toast({ status: "error", title: "Error copying guide" }),
    onCompleted: ({ duplicateCallGuide }) => {
      const callGuide = duplicateCallGuide?.callGuide;
      if (callGuide) {
        navigate(`/guide/${callGuide.id}`);
      }
    },
  });

  const [updateCallGuide] = useUpdateCallGuide({
    onError: () => toast({ status: "error", title: "Error updating guide" }),
    positionId: callGuide.position?.id,
  });

  const [deleteCallGuide, { loading: deleteCallGuideLoading }] =
    useDeleteCallGuide({
      onError: (err) =>
        toast({ status: "error", title: "Error deleting guide" }),
      onCompleted: () => {
        setIsOpen(false);
        if (redirectOnDelete) {
          navigate("/guides", { replace: true });
        } else {
          window.location.reload();
        }
      },
    });

  return (
    <>
      <EditCallGuideModal
        callGuide={callGuide}
        isOpen={editingGuide}
        onClose={() => setEditingGuide(false)}
      />
      <Menu placement="right-start">
        {() => (
          <>
            <MenuButton data-testid="callguide-menu" textTransform="capitalize">
              <Icon
                as={MdMoreVert}
                display="flex"
                alignItems="center"
                h="6"
                w="6"
              />
            </MenuButton>
            <MenuList>
              {callGuide.canEdit && !callGuide.atsId && (
                <MenuItem
                  icon={<HiOutlinePencil fontSize="16px" />}
                  color="blue.600"
                  fontSize="sm"
                  fontWeight="500"
                  onClick={() => setEditingGuide(true)}
                >
                  Edit
                </MenuItem>
              )}
              <MenuItem
                icon={<MdContentCopy fontSize="16px" />}
                color="blue.600"
                fontSize="sm"
                fontWeight="500"
                onClick={() => {
                  sendGAEvent("copy", "call_guides");
                  duplicateCallGuide({ variables: { id: callGuide.id } });
                }}
              >
                Duplicate
              </MenuItem>
              <MenuDivider />
              {showRemove && (
                <MenuItem
                  icon={<MdRemove fontSize="16px" />}
                  color="blue.600"
                  fontSize="sm"
                  fontWeight="500"
                  isDisabled={callGuide.creator?.id !== currentUser.id}
                  onClick={() => {
                    sendGAEvent("position", "call_guides", "remove");
                    updateCallGuide({
                      variables: { id: callGuide.id, positionId: "" },
                    });
                  }}
                >
                  Remove
                </MenuItem>
              )}
              <MenuItem
                color="error"
                fontSize="sm"
                fontWeight="500"
                icon={<MdDelete fontSize="16px" />}
                isDisabled={callGuide.creator?.id !== currentUser.id}
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                Delete
              </MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Guide
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete <strong>{callGuide.name}</strong>?
              This cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                variant="outline"
                size="sm"
                ref={cancelRef}
                isDisabled={deleteCallGuideLoading}
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                size="sm"
                data-testid="delete-guide-button"
                isLoading={deleteCallGuideLoading}
                onClick={() => {
                  sendGAEvent("delete", "call_guides");
                  deleteCallGuide({ variables: { id: callGuide.id } });
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

CallGuideMenu.displayName = "CallGuideMenu";
export default CallGuideMenu;
