import {
  Box,
  Icon,
  IconProps,
  useDisclosure,
  usePopper,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { IconType } from "react-icons";
import { IoIosHelpCircle } from "react-icons/io";

import useLongPress from "../../main/hooks/useLongPress";
import colorVars from "../../theme/css-color-variables";

const DEFAULT_ICON = IoIosHelpCircle;

type Timeout = ReturnType<typeof setTimeout>;

/**
 * Properties for BrightHire rich tooltips
 */
export type TooltipProps = {
  /**
   * An ID for this tooltip, must be unique if used with dismissBehavior="self"
   * otherwise is just present on data-tooltip-key
   */
  id: string;

  /**
   * Where to place the popup relative to the trigger
   * @default auto
   */
  placement?:
    | "auto"
    | "auto-start"
    | "auto-end"
    | "bottom"
    | "bottom-start"
    | "bottom-end"
    | "left"
    | "left-start"
    | "left-end"
    | "right"
    | "right-start"
    | "right-end"
    | "top"
    | "top-start"
    | "top-end";
  /**
   * The element used to trigger the tooltip. Interchangeable with icon. If both
   * are provided, trigger takes precedence.
   */
  trigger?: JSX.Element;

  /**
   * The icon used to trigger the tooltip. Interchangeable with trigger. If both
   * are provided, trigger takes precedence. If neither is provided, the default
   * icon is used.
   */
  icon?: IconType;

  /**
   * Milliseconds to wait before triggering the popup
   */
  openDelay?: number;

  /**
   * How the tooltip should be dismissed
   * * **auto**   Toggles on mouseenter and mouseleave, relative to the trigger.
   * * **self**   Toggles on mouseenter and mouseleave, relative to the trigger
   *              & the popup taken together as one element.
   * * **action** Toggles on click of the trigger
   * @default auto
   */
  dismissBehavior?: "auto" | "self" | "click";

  /**
   * How the tooltip should be dismissed
   * * **none**      Always hidden.
   * * **longPress** Toggles on long press / release.
   * * **action**    Opens on click of the trigger, hides on click outside.
   * @default auto
   */
  mobileBehavior?: "none" | "longPress" | "click";

  /**
   * Color scheme for this tooltip
   */
  colorScheme?: "dark" | "white";

  /**
   * An optional action to take when clicking the trigger, but only if
   * dismissBehavior is not set to "click"
   */
  action?: () => void;
} & Omit<IconProps, "icon">;

type TooltipColorReference = {
  [key: string]: { text: string; background: string };
};
const colors: TooltipColorReference = {
  dark: { text: "white", background: colorVars.gray[500] },
  white: { text: "black", background: "white" },
};

/**
 * BrightHire rich tooltips. Child elements are placed in the actual tooltip and
 * can be as simple as a span or as complicated as you like. The trigger (supply
 * either a trigger element or an icon to use as a trigger) is placed in an
 * inline-flex.
 * @param {TooltipProps} properties
 */
export const Tooltip: React.FC<TooltipProps> = ({
  id,
  placement = "auto",
  trigger,
  icon = DEFAULT_ICON,
  openDelay,
  dismissBehavior = "auto",
  colorScheme,
  action,
  children,
  ...rest
}: TooltipProps) => {
  const { isOpen, onToggle, ...disclosure } = useDisclosure();

  const [openTimeout, setOpenTimeout] = useState<Timeout | null>(null);
  const onOpen = (): void => {
    clearTimeout(openTimeout ?? undefined);
    setOpenTimeout(setTimeout(disclosure.onOpen, openDelay ?? 0));
  };
  const onClose = (): void => {
    clearTimeout(openTimeout ?? undefined);
    disclosure.onClose();
  };

  const { popperRef, referenceRef, getArrowProps } = usePopper({
    placement,
    modifiers: [
      {
        name: "preventOverflow",
        options: {
          padding: { left: 24, right: 24 },
        },
      },
    ],
  });
  const triggerElement = trigger || (
    <Icon as={icon} color="gray.400" {...rest} />
  );

  const childTypes =
    React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) {
        return "text";
      }
      if (child.type === "span") {
        return "span";
      }
    }) ?? [];

  const [isTextOrSpan, setIsTextOrSpan] = useState<boolean>(
    childTypes.length === 1 && ["text", "span"].includes(childTypes[0])
  );

  useEffect(() => {
    setIsTextOrSpan(
      childTypes.length === 1 && ["text", "span"].includes(childTypes[0])
    );
  }, [childTypes.length, childTypes[0], children]);

  const [color] = useState<string>(
    colorScheme ?? (isTextOrSpan ? "dark" : "white")
  );
  // Default for rich tooltips is white, default for spans/plaintext is dark
  const [backgroundColor] = useState<string>(
    (rest.bgColor as string) || colors[color].background
  );
  const [foregroundColor] = useState<string>(colors[color].text);
  const [borderTop, setBorderTop] = useState<string>("none");
  const [borderLeft, setBorderLeft] = useState<string>(
    "10px solid transparent"
  );
  const [borderRight, setBorderRight] = useState<string>(
    "10px solid transparent"
  );
  const [borderBottom, setBorderBottom] = useState<string>(
    `10px solid transparent ${backgroundColor}`
  );

  useEffect(() => {
    if (isOpen) {
      if (placement.startsWith("top")) {
        setBorderTop(`10px solid ${backgroundColor}`);
        setBorderLeft("10px solid transparent");
        setBorderRight("10px solid transparent");
        setBorderBottom("none");
      } else if (placement.startsWith("left")) {
        setBorderTop("10px solid transparent");
        setBorderLeft(`10px solid ${backgroundColor}`);
        setBorderRight("none");
        setBorderBottom("10px solid transparent");
      } else if (placement.startsWith("right")) {
        setBorderTop("10px solid transparent");
        setBorderLeft("none");
        setBorderRight(`10px solid ${backgroundColor}`);
        setBorderBottom("10px solid transparent");
      } else if (placement.startsWith("auto")) {
        setBorderTop("none");
        setBorderLeft("none");
        setBorderRight("none");
        setBorderBottom("none");
      }
    }
  }, [placement]);

  /**
   * Event handler for tooltips that should only be dismissed when the user
   * mouses out of the popper/reference area. If tooltips are dismissed too
   * easily, add the id to important elements you'd like it to stick around for.
   * @param event
   */
  const selfHandler = (event: any): void => {
    try {
      const t = event.target;
      const r = event.relatedTarget;
      const k = "data-tooltip-key";
      const tt = t.getAttribute(k);
      const tp = t.parentElement?.getAttribute(k);
      const to = t.offsetParent?.getAttribute(k);
      const rt = r?.getAttribute(k);
      const rp = r?.parentElement?.getAttribute(k);
      const ro = r?.offsetParent?.getAttribute(k);
      if (
        dismissBehavior === "self" &&
        ![tt, tp, to, rt, rp, ro].includes(id)
      ) {
        document.removeEventListener("mouseleave", selfHandler, true);
        onClose();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("Error checking tooltip key");
    }
  };

  /**
   * Mouseenter behavior for auto and self dismissals
   */
  const onMouseEnter = (): void => {
    if (dismissBehavior === "auto") {
      onOpen();
    } else if (dismissBehavior === "self") {
      document.addEventListener("mouseleave", selfHandler, true);
      onOpen();
    }
  };

  /**
   * Mouseleave behavior for auto and self dismissals
   */
  const onMouseLeave = (): void => {
    if (dismissBehavior === "auto") {
      onClose();
    }
  };

  /**
   * Click behavior for click-only dismissals
   */
  const onClick = (): void => {
    if (dismissBehavior === "click" || isMobile) {
      onToggle();
    } else if (action) {
      action();
    }
  };

  /**
   * For use on mobile
   */
  const onLongPress = useLongPress(
    () => {
      onOpen();
    },
    () => {
      onClose();
    },
    {
      onMouseLeave: () => {
        onMouseLeave();
      },
    }
  );

  const contents = isTextOrSpan ? (
    <div style={{ padding: (rest.padding as string) || "6px 8px" }}>
      {children}
    </div>
  ) : (
    children
  );

  return (
    <>
      <Box
        display="inline-flex"
        key={`${id}-ref`}
        data-tooltip-key={id}
        ref={referenceRef}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        {...onLongPress}
      >
        {triggerElement}
      </Box>
      {isOpen && (
        <Box
          ref={popperRef}
          key={`${id}-popper`}
          data-tooltip-key={id}
          bg={backgroundColor}
          color={foregroundColor}
          fontSize={rest.fontSize || (isTextOrSpan ? "small" : "auto")}
          width={isTextOrSpan ? "auto" : "380px"}
          maxWidth={rest.maxW || { base: "90%", lg: "380px" }}
          borderRadius="base"
          zIndex={10}
          boxShadow="subtle"
        >
          <div
            {...getArrowProps({
              style: {
                borderTop,
                borderRight,
                borderBottom,
                borderLeft,
              },
            })}
          />
          {contents}
        </Box>
      )}
    </>
  );
};
