import {
  Box,
  Divider,
  Flex,
  Heading,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import React from "react";
import { IconType } from "react-icons";
import { HiOutlineInformationCircle } from "react-icons/hi";

import { Alert } from "../../graphql";
import DashboardCardAlert from "./DashboardCardAlert";
import DashboardCardInfoContent from "./DashboardCardInfoContent";
import DashboardCardStat, { CardStat } from "./DashboardCardStat";
import { DashboardConfig } from "./types";

export type DashboardCardProps = {
  title: string;
  icon: IconType;
  stats: Array<CardStat>;
  alerts: Array<Alert>;
  infoContent?: JSX.Element;
  category: string;
  dashboardConfig: DashboardConfig;
};

const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  icon,
  stats,
  alerts = [],
  infoContent,
  category,
  dashboardConfig,
}) => {
  const bg =
    "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(245, 246, 247, 0.2) 100%), rgba(255, 255, 255, 0.8);";
  const boxShadow = "0 0 4px rgba(0, 0, 0, 0.1);";
  return (
    <Flex
      flexDirection="column"
      minW="500px"
      borderRadius="base"
      bg={bg}
      boxShadow={boxShadow}
      p="2"
      pt="6"
      data-testid={`analytics--dashboard-card-${category}`}
    >
      {/* Header */}
      <Flex color="gray.500" align="center" position="relative" mx="4" mb="1">
        <Icon as={icon} mr="1" />
        <Heading
          as="h4"
          flex="1"
          textTransform="uppercase"
          fontWeight="700"
          fontSize="16.8px" // non-system styles
          lineHeight="24px" // non-system styles
          mr="5"
        >
          {title}
        </Heading>
        {infoContent && (
          <Popover trigger="hover" placement="bottom">
            <PopoverTrigger>
              <Box>
                <Icon as={HiOutlineInformationCircle} color="gray.300" />
              </Box>
            </PopoverTrigger>
            <PopoverContent
              minWidth="300px"
              borderColor="gray.100"
              bg="white"
              p="4"
            >
              <DashboardCardInfoContent>{infoContent}</DashboardCardInfoContent>
            </PopoverContent>
          </Popover>
        )}
      </Flex>
      {/* Content */}
      <Flex mx="2" align="flex-start" alignItems="stretch">
        {stats.map((cardStat) => (
          <DashboardCardStat
            key={cardStat.title}
            stat={cardStat}
            dashboardConfig={dashboardConfig}
          />
        ))}
      </Flex>
      {/* Alerts */}
      {alerts.slice(0, 3).map((alert, i) => (
        <Box key={alert.id}>
          <DashboardCardAlert
            alert={alert}
            title={title}
            category={category}
            dashboardConfig={dashboardConfig}
          />
          {i + 1 < alerts.length && <Divider borderColor="gray.100" my="0" />}
        </Box>
      ))}
    </Flex>
  );
};

export default DashboardCard;
