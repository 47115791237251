import { ApolloError } from "@apollo/client";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";

import { Alert } from "../../../components";

export interface FormData {
  clientId: string;
  clientSecret: string;
  username: string;
  password: string;
}

interface BullhornCredentialsFormProps {
  error: ApolloError | undefined;
  isLoading: boolean;
  onSubmit: (formData: FormData) => void;
}

const BullhornCredentialsForm: React.FC<BullhornCredentialsFormProps> = ({
  error,
  isLoading,
  onSubmit,
}) => {
  const { register, handleSubmit } = useForm<FormData>();

  return (
    <form
      onSubmit={handleSubmit((formData) => {
        onSubmit(formData);
      })}
    >
      {error?.graphQLErrors?.map(({ message }, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Alert mb={6} key={i} status="error" description={message} />
      ))}
      <VStack spacing="8" align="start" maxW="400">
        <FormControl id="clientId" isRequired>
          <FormLabel>Client Id</FormLabel>
          <Input {...register("clientId")} />
          <FormHelperText color="placeholder">
            Client ID received from bullhorn support.
          </FormHelperText>
        </FormControl>
        <FormControl id="clientSecret" isRequired>
          <FormLabel>Client Secret</FormLabel>
          <Input {...register("clientSecret")} type="password" />
          <FormHelperText color="placeholder">
            Client Secret received from bullhorn support.
          </FormHelperText>
        </FormControl>
        <FormControl id="username" isRequired>
          <FormLabel>Username</FormLabel>
          <Input {...register("username")} />
          <FormHelperText color="placeholder">
            Bullhorn username with API permissions
          </FormHelperText>
        </FormControl>
        <FormControl id="password" isRequired>
          <FormLabel>Password</FormLabel>
          <Input {...register("password")} type="password" />
          <FormHelperText color="placeholder">
            Password for the user listed above.
          </FormHelperText>
        </FormControl>
        <Button type="submit" isLoading={isLoading}>
          Save
        </Button>
      </VStack>
    </form>
  );
};

export default BullhornCredentialsForm;
