import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  StyleProps,
  Text,
  Textarea,
} from "@chakra-ui/react";
import LogRocket from "logrocket";
import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import ResizeTextarea from "react-textarea-autosize";

import { CallTraining } from "../../../graphql";
import TrainingTabFooter from "./TrainingTabFooter";

export type TrainingTabProps = StyleProps & {
  callTraining: Pick<
    CallTraining,
    | "questions"
    | "lastSubmittedAt"
    | "trainingProgramItemId"
    | "deletedQuestions"
    | "canSubmit"
  >;
  onAnswer(
    answers: { questionId: string; answer: string; itemId: string }[]
  ): void;
};

const TrainingTab: React.FC<TrainingTabProps> = ({
  callTraining: {
    questions,
    deletedQuestions,
    lastSubmittedAt,
    trainingProgramItemId,
    canSubmit,
  },
  onAnswer,
  ...styles
}) => {
  useEffect(() => LogRocket.track("training-assessment-tab"), []);

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid, errors },
  } = useForm({
    mode: "all",
  });

  const listRef = useRef<HTMLDivElement>(null);

  if (questions.length === 0) {
    return <ProgramWithoutAssessments />;
  }

  const onSubmit = handleSubmit((val) => {
    const answers = Object.keys(val).map((questionId) => ({
      questionId,
      answer: val[questionId],
      itemId: trainingProgramItemId,
    }));
    onAnswer(answers);
  });

  return (
    <Flex
      as="form"
      flexDir="column"
      onSubmit={onSubmit}
      position="relative"
      {...styles}
    >
      <Box flexShrink={1} overflow="auto" ref={listRef}>
        {questions.map((q) => (
          <Box pb={5} px={5} key={q.questionId}>
            <FormControl isInvalid={errors[q.questionId] !== undefined}>
              <FormLabel
                htmlFor={q.questionId}
                fontSize="sm"
                fontWeight="500"
                pl={4}
                color="gray.900"
                mb={4}
              >
                {q.question}
              </FormLabel>
              <Textarea
                {...register(q.questionId, {
                  required: true,
                  minLength: 1,
                })}
                defaultValue={q.answer}
                isInvalid={errors[q.questionId] !== undefined}
                placeholder="Type something..."
                display="inline-block"
                borderWidth="1px"
                borderColor="gray.100"
                borderStyle="solid"
                variant="outline"
                fontWeight="400"
                fontSize="sm"
                as={ResizeTextarea}
                overflow="hidden"
                resize="none"
                p={4}
              />
              {errors[q.questionId] !== undefined && (
                <FormErrorMessage pl={4}>
                  A response is required.
                </FormErrorMessage>
              )}
            </FormControl>
          </Box>
        ))}
        {deletedQuestions.length > 0 && (
          <Box bg="gray.50">
            <Box px={5} py={2} mb={4} background="gray.100">
              <Text fontSize="sm" pl="4" fontWeight="500" color="gray.800">
                Deleted Questions
              </Text>
            </Box>
            {deletedQuestions.map((q) => (
              <Box pb={5} px={5} key={q.questionId}>
                <FormControl isDisabled>
                  <FormLabel
                    htmlFor={q.questionId}
                    fontSize="sm"
                    fontWeight="500"
                    pl={4}
                    color="gray.900"
                    mb={4}
                    _disabled={{ color: "gray.900" }}
                  >
                    {q.question}
                  </FormLabel>
                  <Textarea
                    name={q.questionId}
                    defaultValue={q.answer}
                    placeholder=""
                    display="inline-block"
                    borderWidth="1px"
                    borderColor="gray.100"
                    borderStyle="solid"
                    variant="outline"
                    fontWeight="400"
                    fontSize="sm"
                    as={ResizeTextarea}
                    overflow="hidden"
                    resize="none"
                    p={4}
                    _disabled={{ bg: "gray.50" }}
                    _hover={{ borderColor: "gray.100" }}
                  />
                </FormControl>
              </Box>
            ))}
          </Box>
        )}
      </Box>

      <TrainingTabFooter
        mt="auto"
        tooltip={
          canSubmit
            ? undefined
            : "You must be a trainee in this program to submit"
        }
        isDisabled={!(isDirty && isValid && canSubmit)}
        lastSubmittedAt={lastSubmittedAt ?? null}
        questionsListEl={listRef.current}
      />
    </Flex>
  );
};

export default TrainingTab;

const ProgramWithoutAssessments: React.FC = () => (
  <Box
    display="flex"
    flexDir="column"
    alignItems="center"
    px={10}
    mt={98}
    ml="auto"
    mr="auto"
  >
    <img src="/static/images/book-circle-shadow.svg" width="96px" />
    <Text fontSize="md">
      To be marked complete in this program, simply watch each recording in
      full. You will be marked complete automatically.
    </Text>
  </Box>
);
