import { Center, Divider, Image } from "@chakra-ui/react";
import React from "react";

type AnalyticsHeroProps = {
  children: React.ReactNode;
  imgSrc: string;
};

const AnalyticsHeroFrame: React.FC<AnalyticsHeroProps> = ({
  imgSrc,
  children,
}) => {
  return (
    <>
      <Divider my="6" />
      <Image src={imgSrc} mx="100px" />
      <Center
        mt="12"
        fontWeight="400"
        fontSize="lg"
        color="gray.600"
        textAlign="center"
      >
        {children}
      </Center>
    </>
  );
};

export default AnalyticsHeroFrame;
