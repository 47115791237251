export const formatBytes = (bytes: number): string => {
  const mb = (bytes / 1048576).toFixed(2);
  return `${mb} MB`;
};

export const formatRatio = (ratio: number): string => {
  if (ratio < 0.1) {
    return `${(ratio * 100).toPrecision(1)}%`;
  }
  return ratio === 1 ? "100%" : `${(ratio * 100).toPrecision(2)}%`;
};

export const formatRate = (rate: number): string => {
  return `${rate.toFixed(2)}`;
};

export const isNumeric = (string: string): boolean => {
  if (typeof string !== "string") return false;
  return !Number.isNaN(string) && !Number.isNaN(parseFloat(string));
};
