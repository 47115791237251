import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
} from "@chakra-ui/react";
import React from "react";
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi2";
import { matchPath, useLocation } from "react-router-dom";

import { SettingsNavLink, SettingsNavLinkBase } from "./SettingsNavLink";

type NavItem = {
  displayName: string;
  to: string;
};

type SettingsNavAccordionProps = {
  /** This is always shown, next to the expand / collapse caret */
  heading: React.ReactNode;
  subItems: NavItem[];
};

/** Renders a collapsible accordion for grouped / nested nav items */
const SettingsNavAccordion: React.FC<SettingsNavAccordionProps> = ({
  heading,
  subItems,
}) => {
  const { pathname } = useLocation();

  const childrenAreActive = subItems.some((item) =>
    matchPath(item.to, pathname)
  );

  return (
    <Accordion allowToggle>
      <AccordionItem border="0">
        {({ isExpanded }) => (
          <>
            <SettingsNavLinkBase
              as={AccordionButton}
              isActive={!isExpanded && childrenAreActive}
            >
              <Box flex="1" textAlign="left">
                {heading}
              </Box>
              {isExpanded ? <HiOutlineChevronUp /> : <HiOutlineChevronDown />}
            </SettingsNavLinkBase>

            <AccordionPanel p={0}>
              <Flex flexDirection="column">
                {subItems.map(({ displayName, to }) => (
                  <SettingsNavLink key={displayName} to={to} pl="12">
                    {displayName}
                  </SettingsNavLink>
                ))}
              </Flex>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default SettingsNavAccordion;
