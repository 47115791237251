import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import { IoCut, IoLinkSharp } from "react-icons/io5";
import { MdDeleteForever, MdReply } from "react-icons/md";

import { useToast } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import {
  CallNoteFragment,
  CallNoteType,
  useUpdateCallNoteMutation,
} from "../../graphql";
import { ClipRange } from "../Interview/Clip/types";
import { clipDefaultEndTime } from "../Interview/Clip/utils";
import useCopyLink from "./useCopyLink";
import VisibilityMenu from "./VisibilityMenu";

type CallNoteHoverMenuProps = {
  note: CallNoteFragment;
  onDelete: (id: string) => void;
  noteIsDeleting?: boolean;
  onClickTimestamp?: (time: number) => void;
  limitedNoteControls?: boolean;
  setClipRange?(clipRange: ClipRange | null, adjustByWordTime: boolean): void;
  duration?: number | null | undefined;
  onReplyOpen: () => void;
  readOnly: boolean;
};
const CallNoteHoverMenu: React.FC<CallNoteHoverMenuProps> = ({
  onClickTimestamp,
  note,
  setClipRange,
  duration,
  onDelete,
  limitedNoteControls,
  noteIsDeleting,
  onReplyOpen,
  readOnly,
}) => {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();
  const timestamp = note.highlightStartTime
    ? Math.floor(note.highlightStartTime)
    : note.time;
  const { handleCopyLink } = useCopyLink(note, timestamp);
  const [updateNote] = useUpdateCallNoteMutation({
    onError: (error) =>
      toast({
        title: "Error updating note.",
        description: error.message,
        status: "error",
      }),
  });
  return (
    <Box gridArea="menu" position="relative">
      <Flex alignItems="center" position="absolute" right="0" bottom="-4px">
        {onClickTimestamp && (
          <Tooltip label="Link to this Moment">
            <Button
              leftIcon={<IoLinkSharp size="14" />}
              aria-label="Copy Link"
              variant="outline"
              size="sm"
              fontSize="12px"
              colorScheme="gray"
              borderRadius="md"
              mr={1}
              height="2.25rem"
              px={2}
              onClick={handleCopyLink}
            >
              Copy link
            </Button>
          </Tooltip>
        )}
        <ButtonGroup
          size="sm"
          spacing="0"
          bg="white"
          borderColor="border"
          borderWidth="1px"
          borderRadius="md"
          variant="ghost"
          colorScheme="gray"
          p="1px"
        >
          {note.type !== CallNoteType.Comment && setClipRange && (
            <Tooltip label="Create clip">
              <IconButton
                aria-label="Create clip"
                icon={<IoCut />}
                onClick={() => {
                  if (setClipRange) {
                    if (note.highlightStartTime && note.highlightEndTime) {
                      setClipRange(
                        {
                          start: note.highlightStartTime,
                          end: note.highlightEndTime,
                        },
                        false
                      );
                    } else {
                      setClipRange(
                        {
                          start: note.time > 15 ? note.time - 15 : note.time,
                          end: clipDefaultEndTime(duration, note.time),
                        },
                        true
                      );
                    }
                    sendGAEvent(
                      "clip_creation_start",
                      "call_review",
                      "clip_from_note"
                    );
                  }
                }}
              />
            </Tooltip>
          )}
          {!limitedNoteControls && (
            <Tooltip label="Reply">
              <IconButton
                aria-label="reply"
                icon={<MdReply />}
                data-testid="call-note-reply"
                onClick={onReplyOpen}
              />
            </Tooltip>
          )}
          {!readOnly && (
            <>
              <VisibilityMenu
                visibility={note.visibility}
                onChange={(visibility) =>
                  updateNote({
                    variables: {
                      id: note.id,
                      visibility,
                    },
                  })
                }
              />
              <Tooltip label="Delete">
                <IconButton
                  aria-label="Delete"
                  icon={<MdDeleteForever />}
                  data-testid="delete-button"
                  onClick={() => onDelete(note.id)}
                  isDisabled={noteIsDeleting}
                />
              </Tooltip>
            </>
          )}
        </ButtonGroup>
      </Flex>
    </Box>
  );
};

export default CallNoteHoverMenu;
