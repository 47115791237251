import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  LoadingIndicator,
  RouterLink,
  SpotlightSearchBase,
  SpotlightSearchState,
} from "../../../components";
import useDebouncedCallback from "../../../hooks/useDebouncedCallback";
import { SearchResults } from "../Search";
import { SpotlightSearchResult } from "./SpotlightSearchResult";

type SpotlightSearchProps = {
  onOpen?(): void;
  onClose?(): void;
  children(searchState: SpotlightSearchState): React.ReactNode;
};

export const SpotlightSearch: React.FC<SpotlightSearchProps> = ({
  onOpen,
  onClose,
  children,
}) => {
  const navigate = useNavigate();

  const [hasQuery, setHasQuery] = useState(false);
  /**
   * `queryJustTouched` and the 350ms debounce is to provide a loading
   * indicator instantly upon typing, and it is necessary because of
   * the 350ms debounce on `<SearchBox />`
   * */
  const [queryJustTouched, setQueryJustTouched] = useState(false);
  const onResults = useDebouncedCallback(() => setQueryJustTouched(false), 350);
  const [searchUrl, setSearchUrl] = useState("/search");

  return (
    <SpotlightSearchBase
      showResults={hasQuery}
      onChange={(query) => {
        if (query) {
          setHasQuery(true);
          setQueryJustTouched(true);
        } else {
          setHasQuery(false);
        }
      }}
      onEnter={() => {
        if (!hasQuery) {
          navigate("/search");
        }
      }}
      onSearchUrl={setSearchUrl}
      onGoToSearch={() => navigate(searchUrl)}
      results={
        <SearchResults
          gap="3"
          render={(hit) => <SpotlightSearchResult hit={hit} />}
          onResults={onResults}
          noResults={
            queryJustTouched ? (
              <LoadingIndicator py={6} delay={0} />
            ) : (
              <Box textAlign="center" mt="2" mb="4" color="gray.600">
                No results found. Check the full{" "}
                <RouterLink to={searchUrl} fontWeight="inherit">
                  search page
                </RouterLink>
              </Box>
            )
          }
        />
      }
      onOpen={onOpen}
      onClose={() => {
        onClose?.();
        setHasQuery(false);
      }}
    >
      {children}
    </SpotlightSearchBase>
  );
};
