import { Box, Text, TextProps } from "@chakra-ui/react";
import React, { useMemo } from "react";

type HighlightTextProps = {
  text: string;
  highlight: string;
} & TextProps;

const HighlightText: React.FC<HighlightTextProps> = ({
  text,
  highlight,
  ...rest
}) => {
  const re = useMemo(() => new RegExp(`(${highlight})`, "gi"), [highlight]);
  return (
    <Text {...rest}>
      {text.split(re).map((part, i) => (
        <Box
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          as="span"
          fontWeight={
            part.toLowerCase() === highlight.toLowerCase() ? "bold" : undefined
          }
        >
          {part}
        </Box>
      ))}
    </Text>
  );
};

export default HighlightText;
