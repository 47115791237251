import {
  Box,
  Flex,
  Icon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Tooltip,
  UnorderedList,
  useDisclosure,
  useEventListener,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import {
  HiOutlineChevronLeft,
  HiOutlineChevronRight,
  HiOutlineX,
} from "react-icons/hi";

import { AudioImage, RouterLink } from "../../../components";
import { pluck } from "../../../utils/array";
import { formatDate } from "../../../utils/datetime";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import useMediaPlayer from "../Interview/useMediaPlayer";
import VideoControls from "../Interview/VideoControls";
import VideoPlayerProgressBar from "../Interview/VideoPlayerProgressBar";
import { Video } from "../Video";
import { CallHighlight } from "./types";
import useViewHighlight from "./useViewHighlight";
import { getCallName, isAiNoteHighlight } from "./utils";

const MAX_VIDEO_WIDTH = 800;
const DEFAULT_VIDEO_HEIGHT = 450;

type MediaSize = { height: number; width: number };

const useMediaSize = (
  initialSize?: { height?: number | null; width?: number | null } | null
): [MediaSize, (s: MediaSize) => void] => {
  const placeholderSize: MediaSize = {
    width: MAX_VIDEO_WIDTH,
    height: DEFAULT_VIDEO_HEIGHT,
  };

  if (initialSize?.width && initialSize?.height) {
    placeholderSize.width = Math.min(initialSize.width, MAX_VIDEO_WIDTH);
    placeholderSize.height =
      (initialSize.height * placeholderSize.width) / initialSize.width;
  }

  const [mediaSize, setMediaSize] = useState<MediaSize>(placeholderSize);

  return [
    mediaSize,
    (incomingSize) => {
      const width = Math.min(incomingSize.width, MAX_VIDEO_WIDTH);
      const height = (incomingSize.height * width) / incomingSize.width;
      if (width !== mediaSize.width || height !== mediaSize.height)
        setMediaSize({ width, height });
    },
  ];
};

export type HighlightModalProps = {
  highlight: CallHighlight;
  onClose(): void;
  onGoForward?(): void;
  onGoBack?(): void;
  tooltipLabels?: string[];
};

const HighlightModal: React.FC<HighlightModalProps> = ({
  highlight,
  onClose,
  onGoForward,
  onGoBack,
  tooltipLabels = ["Go back", "Go forward"],
}) => {
  const { call } = highlight;

  useViewHighlight(highlight);

  const [mediaSize, updateSize] = useMediaSize(call.streamableVideo);

  const [mediaPlayerRef, listeners, player] = useMediaPlayer({
    onLoadedMetadata(event) {
      const { videoWidth, videoHeight } = event.target as HTMLVideoElement;
      if (videoWidth && videoHeight) {
        updateSize({ width: videoWidth, height: videoHeight });
      }
    },
  });

  const { duration, seek } = player;

  const videoSrc = call.streamableVideo?.url;
  const audioSrc = call.streamableAudio?.url;
  const mediaSrc = videoSrc ?? audioSrc;

  useEffect(() => {
    if (duration && highlight.startTime) {
      seek(highlight.startTime);
    }
  }, [duration, highlight.startTime, seek]);

  const {
    isOpen: isHovering,
    onOpen: onMouseEnter,
    onClose: onMouseLeave,
  } = useDisclosure();
  const revealControlsOnHover = { onMouseEnter, onMouseLeave };
  const sendGAEvent = useSendGAEvent();

  const showControls = isHovering || !player.playing;
  const renderControls = useCallback(
    (bg = "linear-gradient(transparent 0%, black 50%)") =>
      showControls ? (
        <Box
          pos="absolute"
          bottom="0"
          width="100%"
          px="2"
          bg={bg}
          {...revealControlsOnHover}
        >
          <VideoControls isVideoVisible player={player} h="10" />
          <VideoPlayerProgressBar
            duration={duration ?? 0}
            value={player.time}
            seek={seek}
            disabled={!duration}
          />
        </Box>
      ) : null,
    [showControls, player, duration, player.time]
  );

  useEventListener("keydown", (e) => {
    if (e.code === "ArrowRight") onGoForward?.();
    if (e.code === "ArrowLeft") onGoBack?.();
  });

  return (
    <Modal
      isOpen
      onClose={onClose}
      variant="closeOutside"
      closeOnEsc
      returnFocusOnClose={false}
      isCentered
    >
      <ModalOverlay bgColor="blackAlpha.700" />
      <ModalContent
        data-testid="highlight-modal"
        maxW="unset"
        maxH="85vh"
        w={mediaSize.width}
        mx="8"
        my="0"
        background="transparent"
      >
        <ModalCloseButton as={HiOutlineX} />

        {!!onGoBack && (
          <SkipControl onClick={onGoBack} label={tooltipLabels[0]} />
        )}
        {!!onGoForward && (
          <SkipControl right onClick={onGoForward} label={tooltipLabels[1]} />
        )}

        <Box borderTopRadius="2xl" overflow="hidden">
          {videoSrc && (
            <Video
              ref={mediaPlayerRef}
              src={mediaSrc}
              {...listeners}
              screenControls={false}
              height={`${mediaSize.height}px`}
              maxHeight="43vh"
              renderControls={renderControls}
              {...revealControlsOnHover}
            />
          )}

          {!videoSrc && audioSrc && (
            <Flex
              height="300px"
              position="relative"
              backgroundImage="/static/images/rectangle.png"
              backgroundSize="cover"
              alignItems="center"
              justifyContent="center"
            >
              <AudioImage
                width="100%"
                height="100%"
                style={{ color: "white" }}
                {...revealControlsOnHover}
              />
              <audio ref={mediaPlayerRef} src={mediaSrc} {...listeners} />
              {renderControls("linear-gradient(transparent 0%, #393f47 100%)")}
            </Flex>
          )}
        </Box>

        <ModalBody
          bg="gray.800"
          p={0}
          fontSize="md"
          borderBottomRadius="2xl"
          overflow="hidden"
        >
          <VStack alignItems="stretch" fontSize="sm" color="gray.50">
            <Box pt={5} px={6} pb={5}>
              <Box
                fontWeight="semibold"
                maxH="32"
                overflowY="auto"
                fontSize="lg"
                mb={2}
              >
                {highlight.text}
              </Box>
              <Box
                fontSize="md"
                maxH="40"
                overflowY="auto"
                style={{ colorScheme: "dark" }}
              >
                {isAiNoteHighlight(highlight) ? (
                  <UnorderedList
                    pr={4}
                    fontSize="sm"
                    pl={2}
                    fontWeight="medium"
                    lineHeight={6}
                  >
                    {highlight.description.split("\n").map((item) => (
                      <ListItem key={item}>{item}</ListItem>
                    ))}
                  </UnorderedList>
                ) : (
                  highlight.description
                )}
              </Box>
            </Box>

            <Flex
              flexDir="row"
              background="gray.900"
              color="gray.300"
              alignItems="center"
              px={6}
              py={4}
              gap={2}
            >
              <Box whiteSpace="nowrap">
                {formatDate(call.createdAt, {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
              </Box>
              <SpacerDot size={4.5} />
              <RouterLink
                to={`/interview/${call.id}?t=${highlight.startTime}`}
                color="blue.200"
                _hover={{ color: "blue.300" }}
                fontWeight="normal"
                onClick={() => {
                  sendGAEvent("view_interview", "candidate", "highlight_modal");
                }}
              >
                {getCallName(call)}
              </RouterLink>
              <SpacerDot size={4.5} />
              <Box>{pluck(call.interviewers, "fullName").join(", ")}</Box>
              {isAiNoteHighlight(highlight) && (
                <>
                  <SpacerDot size={4.5} />
                  <AiHighlightTag />
                </>
              )}
            </Flex>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default HighlightModal;

export const AiHighlightTag: React.FC = () => {
  return (
    <Box
      display="inline-block"
      px={1}
      py={0.5}
      borderRadius="2px"
      color="blue.200"
      background="blue.900"
      fontSize="2xs"
      whiteSpace="nowrap"
    >
      AI HIGHLIGHT
    </Box>
  );
};

type SpacerDotProps = {
  size: number;
};

const SpacerDot: React.FC<SpacerDotProps> = ({ size }) => {
  return (
    <Box
      width={`${size}px`}
      height={`${size}px`}
      display="inline-block"
      borderRadius="100%"
      background="gray.300"
    />
  );
};

interface SkipControlProps {
  right?: boolean;
  onClick?: () => void;
  label: string;
}

const SkipControl: React.FC<SkipControlProps> = ({ right, onClick, label }) => {
  const isDisabled = !onClick;

  return (
    <Flex
      position="absolute"
      h="16"
      right={right ? 0 : undefined}
      top={{ base: "calc(100% + 24px)", lg: "50%" }}
      transform={{
        base: undefined,
        lg: right ? "translate(100%, -50%)" : "translate(-100%, -50%)",
      }}
    >
      {right && <Box w="12" />}
      <Tooltip label={label} placement="top" isDisabled={isDisabled}>
        <Box cursor={isDisabled ? undefined : "pointer"} onClick={onClick}>
          <Icon
            as={right ? HiOutlineChevronRight : HiOutlineChevronLeft}
            transitionProperty="color"
            transitionDuration="normal"
            color={isDisabled ? "gray.600" : "white"}
            _hover={{ color: isDisabled ? "gray.600" : "blue.500" }}
            _focus={{ color: isDisabled ? "gray.600" : "blue.700" }}
            _active={{ color: isDisabled ? "gray.600" : "blue.400" }}
            boxSize="16"
          />
        </Box>
      </Tooltip>
      {!right && <Box w="12" />}
    </Flex>
  );
};
