import { Flex, Grid, Text, useMediaQuery, useTheme } from "@chakra-ui/react";
import Color from "color";
import React from "react";

import { usePageTracker } from "../../../utils/googleAnalytics";
import { showIntercom } from "../../../utils/intercom";
import { DefaultPageLayout } from "../../layouts";
import { ContactUs, DocsAndTutorials, InAppSupport, WhatsNew } from "./icons";
import SupportItem from "./SupportItem";

const SupportPage: React.FC = () => {
  usePageTracker("support");
  const { colors } = useTheme();

  const bg = Color(colors.blue[500]).alpha(0.03).toString();

  const [canFit4, canFit2] = useMediaQuery([
    /**
     * These are a combo of the 230px min-width on the `<SupportItem />`s,
     * the 24px gap between items, and whatever padding exists on the page
     */
    "(min-width: 1272px)",
    "(min-width: 628px)",
  ]);
  const numCols = canFit4 ? 4 : canFit2 ? 2 : 1;

  return (
    <DefaultPageLayout bg={bg} p="8">
      <Flex direction="column" gap="6" h="100%" w="100%">
        <Text
          as="h1"
          color="gray.800"
          fontSize="3xl"
          fontWeight="medium"
          lineHeight="10"
        >
          Help center
        </Text>
        <Flex flex="1" direction="column" gap="8" justifyContent="center">
          <Text
            as="h2"
            fontSize="2xl"
            fontWeight="medium"
            lineHeight="8"
            textAlign="center"
          >
            How can we help?
          </Text>
          <Grid
            gap="6"
            mx="auto"
            templateColumns={`repeat(${numCols}, minmax(270px, 300px))`}
          >
            <SupportItem
              icon={<InAppSupport />}
              heading="In-app support"
              subHeading="Send us a message or access help in the application."
              onClick={showIntercom}
            />
            <SupportItem
              icon={<DocsAndTutorials />}
              heading="Docs & tutorials"
              subHeading="Check out our FAQs, how-to videos, and help articles."
              to="https://help.brighthire.ai"
              isExternal
              target="_blank"
            />

            <SupportItem
              icon={<WhatsNew />}
              heading="What's new"
              subHeading="Learn about our recently released products & features."
              to="https://help.brighthire.ai/en/articles/5398565-brighthire-product-updates"
              isExternal
              target="_blank"
            />
            <SupportItem
              icon={<ContactUs />}
              heading="Contact us"
              subHeading="Get in touch by emailing us."
              to="mailto:support@brighthire.ai"
              isExternal
            />
          </Grid>
        </Flex>
      </Flex>
    </DefaultPageLayout>
  );
};

export default SupportPage;
