import { Box, Flex } from "@chakra-ui/react";
import React from "react";

import TopicTag from "./TopicTag";
import { SummaryCallSection, SummaryCustomTopic } from "./types";

type SummarySectionProps = {
  section: SummaryCallSection;
  selectedNoteIds: string[];
  onSelect: (n: string) => void;
  onHover?: (id: string) => void;
  offHover?: (id: string) => void;
  /** If specified, the "add custom topic" tag will be shown */
  onCreateCustomTopic?(): void;
  addCustomTopic?: (t: string, questions: string[]) => void;
  customTopicMap?: Map<string, SummaryCustomTopic>;
};

/** Displays a section's header and its associated notes */
const SummarySection: React.FC<SummarySectionProps> = ({
  section,
  selectedNoteIds,
  onSelect,
  onHover,
  offHover,
  onCreateCustomTopic,
  addCustomTopic,
  customTopicMap,
}) => {
  return (
    <Box>
      <Box fontSize="10px" fontWeight={600} pb="1" textTransform="uppercase">
        {section.text}
      </Box>
      <Flex gap="2" wrap="wrap">
        {section.notes?.map(
          (n) =>
            n.tag && (
              <TopicTag
                id={n.id}
                key={n.id}
                text={n.tag}
                isSelected={selectedNoteIds.includes(n.id)}
                isDisabled={!n.text}
                onClick={() => {
                  onSelect(n.id);
                }}
                onMouseEnter={() => onHover?.(n.id)}
                onMouseLeave={() => offHover?.(n.id)}
                customTopic={
                  customTopicMap ? customTopicMap.get(n.tag || "") : undefined
                }
                addCustomTopic={addCustomTopic}
              />
            )
        )}
        {onCreateCustomTopic && (
          <TopicTag
            id=""
            text="+ Create a topic"
            onClick={onCreateCustomTopic}
            color="blue.600"
            borderColor="blue.600"
          />
        )}
      </Flex>
    </Box>
  );
};

export default SummarySection;
