import useCacheState from "../../hooks/useCacheState";

type SidebarState = {
  isOpen: boolean;
  skipAnimationDelay: boolean;
  expandedItems: string[];
};

/**
 * Returns a `setState`-like pair of the initial sidebar state
 */
const useSidebarState = (): [
  SidebarState,
  (s: Partial<SidebarState>) => void
] => {
  const initialState: SidebarState = {
    isOpen: false,
    skipAnimationDelay: false,
    expandedItems: [],
  };

  const [state, setState] = useCacheState("sidebarState", initialState);

  return [state, (s) => setState({ ...state, ...s })];
};

export default useSidebarState;
