import {
  CallHighlightFragment,
  CandidateHighlightFragment,
  CandidateInterviewFragment,
} from "../../graphql";

export enum InterviewMenuOptions {
  SHARE = "share",
  DELETE = "delete",
}

export type CandidateCall = CandidateInterviewFragment &
  CandidateHighlightFragment;

export type CallHighlight = CallHighlightFragment & {
  call: CandidateCall;
};
