import { useEffect, useState } from "react";

import { Breakpoint, breakpoints } from "../theme/breakpoints";

const sm = parseInt(breakpoints.sm ?? "480", 10);
const md = parseInt(breakpoints.md ?? "768", 10);
const lg = parseInt(breakpoints.lg ?? "1024", 10);
const xl = parseInt(breakpoints.xl ?? "1200", 10);
const dblxl = parseInt(breakpoints["2xl"] ?? "1500", 10);

const getCurrentBreakpoint = (): Breakpoint => {
  const width = window.innerWidth;
  if (width < sm) return "base";
  if (width < md) return "sm";
  if (width < lg) return "md";
  if (width < xl) return "lg";
  if (width < dblxl) return "xl";
  return "2xl";
};

/**
 * Chakra-compatible text representation of the current window breakpoint,
 * inclusive of any window resize activity
 * @returns one of
 * * base
 * * sm
 * * md
 * * lg
 * * xl
 * * 2xl
 */
function useCurrentBreakpoint(
  callback?: (size: Breakpoint) => void
): Breakpoint {
  const [currentBreakpoint, setCurrentBreakpoint] = useState(
    getCurrentBreakpoint()
  );

  useEffect(() => {
    const handleResize = (): void => {
      const bp = getCurrentBreakpoint();
      setCurrentBreakpoint(bp);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    callback?.(currentBreakpoint);
  }, [currentBreakpoint]);

  return currentBreakpoint;
}

export default useCurrentBreakpoint;
