import { Box, Flex, Icon } from "@chakra-ui/react";
import React from "react";
import { MdCheckCircle, MdError, MdWarning } from "react-icons/md";

interface StatusWithTextProps {
  passed: boolean;
  label: string;
  warn?: boolean;
}

const StatusWithText: React.FC<StatusWithTextProps> = ({
  passed,
  label,
  warn = false,
}) => (
  <Flex mb={4} alignItems="center">
    {passed ? (
      <Icon as={MdCheckCircle} color="green.600" />
    ) : warn ? (
      <Icon as={MdWarning} color="yellow.400" />
    ) : (
      <Icon as={MdError} color="red.600" />
    )}
    <Box ml={2}>{label}</Box>
  </Flex>
);

export default StatusWithText;
