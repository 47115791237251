import { ApolloError, MutationTuple } from "@apollo/client";

import {
  CurrentUserDocument,
  CurrentUserFragment,
  CurrentUserQuery,
  UpdateCurrentUserMutation,
  UpdateCurrentUserMutationVariables,
  useUpdateCurrentUserMutation,
} from "..";

export default function useUpdateCurrentUser({
  onCompleted,
  onError,
}: {
  onCompleted?: (currentUser: CurrentUserFragment) => void;
  onError?: (error: ApolloError) => void;
} = {}): [
  MutationTuple<
    UpdateCurrentUserMutation,
    UpdateCurrentUserMutationVariables
  >[0],
  { loading: boolean; error: ApolloError | undefined }
] {
  const [updateCurrentUser, { loading, error }] = useUpdateCurrentUserMutation({
    update(cache, { data: currentUserData }) {
      const currentUser = currentUserData?.updateCurrentUser?.currentUser;
      if (currentUser) {
        cache.writeQuery<CurrentUserQuery>({
          query: CurrentUserDocument,
          data: { currentUser },
        });
      }
    },
    onCompleted: ({ updateCurrentUser }) => {
      const currentUser = updateCurrentUser?.currentUser;
      if (currentUser && onCompleted) {
        onCompleted(currentUser);
      }
    },
    onError,
  });
  return [updateCurrentUser, { loading, error }];
}
