import {
  Box,
  Button,
  ButtonProps,
  Divider,
  Flex,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Text,
} from "@chakra-ui/react";
import LogRocket from "logrocket";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";

import { Alert, LoadingIndicator } from "../../../../components";
import {
  formatDuration,
  formatDurationShort,
  timestampToSeconds,
} from "../../../../utils/datetime";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import {
  ClipFragment,
  TranscriptSegmentFragment,
  useCallTranscriptBetaQuery,
  useClipCallMutation,
} from "../../../graphql";
import { Video, VideoControls } from "../../Video";
import useMediaPlayer from "../useMediaPlayer";
import ClipSlider from "./ClipSlider";
import ClipTranscript from "./ClipTranscript";
import { ClipRange } from "./types";
import useClipMaker from "./useClipMaker";
import { getSafeRange } from "./utils";

type CreateClipProps = {
  callId: string;
  initialClipRange: ClipRange;
  mediaSrc: string;
  onClip: (clip: ClipFragment) => void;
  onClipAndShare: (clip: ClipFragment) => void;
  onClipAndClipAgain: (clip: ClipFragment) => void;
  videoDisplayMode: string;
  onClose(): void;
};

const secondsToDurationString = (seconds: number): string => {
  return formatDuration(Math.round(seconds));
};

type ClipAction = "clip" | "clipAndShare" | "clipAndClipAgain";

const FooterButton: React.FC<ButtonProps> = ({
  children,
  ...props
}): JSX.Element => (
  <Button variant="white" fontSize="sm" fontWeight="medium" {...props}>
    {children}
  </Button>
);

const CreateClip: React.FC<CreateClipProps> = ({
  callId,
  mediaSrc,
  initialClipRange,
  onClip,
  onClipAndShare,
  onClipAndClipAgain,
  videoDisplayMode,
  onClose,
}) => {
  const sendGAEvent = useSendGAEvent();
  const [mediaPlayerRef, listeners, player] = useMediaPlayer();
  const {
    playRange,
    setPlayRange,
    narrowMediaPlayer,
    setIsDraggingSlider,
    onSliderChange,
  } = useClipMaker(initialClipRange, player);

  const [clipName, setClipName] = useState("");
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const [clipStart, setClipStart] = useState<string>(
    secondsToDurationString(playRange.start)
  );
  const [clipEnd, setClipEnd] = useState<string>(
    secondsToDurationString(playRange.end)
  );
  const duration = useMemo(
    () => formatDurationShort(playRange.end - playRange.start),
    [playRange.start, playRange.end]
  );

  useEffect(() => {
    setClipStart(secondsToDurationString(playRange.start));
    setClipEnd(secondsToDurationString(playRange.end));
  }, [playRange.start, playRange.end, secondsToDurationString]);

  const setClipStartCallback = useCallback(
    (value: number) => {
      if (!value || value === playRange.start) {
        return;
      }
      setPlayRange((clipPlayRange) => {
        const { start: safeStart, end: safeEnd } = getSafeRange(
          {
            start: value,
            end: clipPlayRange.end,
          },
          {
            start: value,
            end: value + 30,
          }
        );
        return {
          start: safeStart,
          play: Math.max(clipPlayRange.play, safeStart),
          end: safeEnd,
        };
      });
      sendGAEvent("clip_input_start_changed", "call_review");
    },
    [playRange.start, playRange.end]
  );

  const setClipEndCallback = useCallback(
    (value: number) => {
      if (!value || value === playRange.end) {
        return;
      }
      setPlayRange((clipPlayRange) => {
        const { start: safeStart, end: safeEnd } = getSafeRange(
          {
            start: clipPlayRange.start,
            end: value,
          },
          {
            start: Math.max(0, value - 30),
            end: value,
          }
        );
        return {
          start: safeStart,
          play: Math.max(clipPlayRange.play, safeEnd),
          end: safeEnd,
        };
      });
      sendGAEvent("clip_input_end_changed", "call_review");
    },
    [playRange.start, playRange.end]
  );

  const { loading, error, data, stopPolling } = useCallTranscriptBetaQuery({
    variables: { callId },
    pollInterval: 5000,
  });

  const [clipCall, { error: mutationError, loading: creatingClip }] =
    useClipCallMutation({
      variables: {
        callId,
        clipStart: playRange.start,
        clipEnd: playRange.end,
        name: clipName,
      },
      update(cache, { data }) {
        const clip = data?.clipCall?.clip;
        if (clip) {
          cache.modify({
            id: cache.identify({ __typename: "Call", id: callId }),
            fields: {
              clipsCount: (existing: number) => (existing ?? 0) + 1,
              callClips: (existing) => {
                return [...existing, clip];
              },
            },
          });
        }
      },
    });

  const [currentAction, setCurrentAction] = useState<ClipAction | null>(null);

  useEffect(() => {
    if (error || data) stopPolling();
  }, [error, data]);

  if (loading || error || !data) {
    return <LoadingIndicator m="auto" size="lg" />;
  }

  const createClip = (action: ClipAction): void => {
    const onCompleted =
      action === "clip"
        ? onClip
        : action === "clipAndShare"
        ? onClipAndShare
        : action === "clipAndClipAgain"
        ? onClipAndClipAgain
        : undefined;

    setCurrentAction(action);
    clipCall({
      onCompleted: (data) => {
        const clip = data.clipCall?.clip;
        setCurrentAction(null);
        if (clip) {
          onCompleted?.(clip);
          if (action === "clipAndClipAgain") setClipName("");
          const eventName = snakeCase(action);
          LogRocket.track(eventName);
          sendGAEvent(eventName, "call_review");
        }
      },
    });
  };

  const speakers = data.call?.speakers || [];
  const transcript: TranscriptSegmentFragment[] = data.call?.transcript || [];

  const renderControls = (): any => (
    <VideoControls
      isHovering={isHovering}
      disabled={false}
      player={{
        ...player,
        play: () => {
          // Wrap around to start if playing at end of the clip. The extra second
          // avoids some edge cases where the player time is epsilon less than
          // the end of the play range.
          if (player.time + 1 >= playRange.end) {
            player.seek(playRange.start);
          }
          player.play();
        },
      }}
      onClose={() => null}
      variant="clips"
    />
  );

  return (
    <>
      <ModalHeader fontWeight="600">Create Clip</ModalHeader>

      <ModalCloseButton top="6" right="6" />

      <ModalBody
        display="grid"
        minH={0}
        p="0"
        borderTopWidth="1px"
        borderTopColor="gray.100"
      >
        <Box px="8" mt="6" mb="4">
          <Input
            value={clipName}
            fontSize="sm"
            placeholder="Example Title: Candidate Background"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setClipName(event.currentTarget.value);
            }}
            borderRadius="base"
            autoFocus
          />
        </Box>

        <Flex px="8">
          <Box pr="6" flex="9">
            <Flex
              height="140px"
              width="100%"
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
              justifyContent="center"
              background="black"
              borderRadius="2px"
              pb="1px"
              onClick={(e: any) => {
                // For mobile compatibility, clicking on the video but outside
                // any controls should toggle the hidden controls. Additionally,
                // pointerEvents is set on various elements here as well as in
                // Video & AudioControls to maximize tappable surface area
                if (isMobile && e.target.tagName === "VIDEO") {
                  setIsHovering(!isHovering);
                }
              }}
            >
              <Video
                ref={mediaPlayerRef}
                src={mediaSrc}
                {...listeners}
                renderControls={renderControls}
                screenControls={false}
                autoPlay={false}
                videoDisplayMode={videoDisplayMode}
                variation="rounded"
                height="140px"
              />
            </Flex>

            <Box pl="1" pr="3px" mt="2">
              <ClipSlider
                playRange={playRange}
                videoDuration={player.duration}
                onDragStartStop={setIsDraggingSlider}
                onChange={onSliderChange}
              />
            </Box>
          </Box>

          <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            flex="11"
          >
            <Flex alignItems="center">
              <Text fontSize="sm" mr="2" color="gray.600">
                Start
              </Text>
              <Input
                value={clipStart}
                placeholder="Start of clip"
                maxWidth="5rem"
                padding="0"
                fontSize="sm"
                textAlign="center"
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                  if (event.key === "Enter") {
                    event.currentTarget.blur();
                  }
                }}
                onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setClipStartCallback(
                    timestampToSeconds(event.currentTarget.value)
                  );
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setClipStart(event.currentTarget.value);
                }}
              />

              <Text fontSize="sm" ml="6" mr="2" color="gray.600">
                End
              </Text>
              <Input
                value={clipEnd}
                maxWidth="5rem"
                padding="0"
                fontSize="sm"
                textAlign="center"
                placeholder="End of clip"
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                  if (event.key === "Enter") {
                    event.currentTarget.blur();
                  }
                }}
                onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setClipEndCallback(
                    timestampToSeconds(event.currentTarget.value)
                  );
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setClipEnd(event.currentTarget.value);
                }}
              />
            </Flex>

            <Text mt="6" fontSize="sm" color="gray.600">
              Duration: {duration}
            </Text>
          </Flex>
        </Flex>

        <Divider mx="8" mt="4" w="auto" />

        <ClipTranscript
          playRange={playRange}
          narrowMediaPlayer={narrowMediaPlayer}
          setPlayRange={setPlayRange}
          speakerLabels={speakers.map((speaker) => speaker.label)}
          transcript={transcript}
          visible
          boxShadow="none"
          py="6"
        />

        <Divider mx="8" w="auto" />

        <Box px="8">
          {mutationError && (
            <Alert
              status="error"
              description={mutationError.message}
              pt="2"
              canHide
            />
          )}
          <Box my="8" display="flex" alignItems="center">
            <FooterButton onClick={onClose}>Cancel</FooterButton>

            <FooterButton
              onClick={() => createClip("clipAndClipAgain")}
              isLoading={creatingClip && currentAction === "clipAndClipAgain"}
              disabled={creatingClip}
              ml="auto"
            >
              Save &amp; create another
            </FooterButton>
            <FooterButton
              onClick={() => createClip("clipAndShare")}
              isLoading={creatingClip && currentAction === "clipAndShare"}
              disabled={creatingClip}
              ml="6"
            >
              Save &amp; share
            </FooterButton>
            <FooterButton
              ml="6"
              px="7"
              onClick={() => createClip("clip")}
              isLoading={creatingClip && currentAction === "clip"}
              disabled={creatingClip}
              variant={undefined}
            >
              Save clip
            </FooterButton>
          </Box>
        </Box>
      </ModalBody>
    </>
  );
};

export default CreateClip;

function snakeCase(s: string): string {
  return s.replace(
    /([a-z])([A-Z])/g,
    (_, l1, l2) => `${l1}_${l2.toLowerCase()}`
  );
}
