// Current possible values from GH:

import {
  GreenhouseScheduledInterviewFragment,
  ScheduledInterviewer,
  User,
} from "../admin/graphql";

type ScheduledInterviewerPreview = Pick<ScheduledInterviewer, "id"> & {
  user: Pick<User, "id" | "email" | "fullName" | "greenhouseId">;
};

// definitely_not, no, yes, strong_yes, no_decision
export const GH_RATING_DEFINITELY_NOT = "definitely_not";
export const GH_RATING_NO = "no";
export const GH_RATING_YES = "yes";
export const GH_RATING_STRONG_YES = "strong_yes";
export const GH_RATING_NO_DECISION = "no_decision";

export const formatGreenhouseRating = (rating: string): string => {
  switch (rating) {
    case GH_RATING_DEFINITELY_NOT:
      return "Definitely not";
    case GH_RATING_NO:
      return "No";
    case GH_RATING_YES:
      return "Yes";
    case GH_RATING_STRONG_YES:
      return "Strong yes";
    case GH_RATING_NO_DECISION:
      return "No decision";
    default:
      return "Unknown rating";
  }
};

export const getGreenhouseInterviewers = (
  greenhouseInterview?: GreenhouseScheduledInterviewFragment | null,
  scheduledInterviewers?: ScheduledInterviewerPreview[]
): {
  email: string;
  id: number;
}[] => {
  const rawGreenhouseInterview = greenhouseInterview?.interview
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      JSON.parse(greenhouseInterview?.interview)
    : null;
  let rawGreenhouseInterviewers = (rawGreenhouseInterview?.interviewers ??
    []) as { email: string; id: number }[];
  rawGreenhouseInterviewers = rawGreenhouseInterviewers.filter((rgi) => {
    let found = false;
    scheduledInterviewers?.forEach((si) => {
      if (si.user.greenhouseId === rgi.id) found = true;
    });
    return !found;
  });
  return rawGreenhouseInterviewers;
};
