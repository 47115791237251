import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { IoTrashBin } from "react-icons/io5";

import { ClipFragment, useDeleteClipMutation } from "../../../graphql";

interface DeleteClipButtonProps {
  clip: Pick<ClipFragment, "id" | "callId" | "userId" | "canDelete">;
  isDisabled: boolean;
  buttonStyle?: string;
  onSuccess?: () => void;
  onError: () => void;
}

const DeleteClipButton: React.FC<DeleteClipButtonProps> = ({
  clip,
  onSuccess,
  onError,
  isDisabled,
  buttonStyle = "button",
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);
  const [deleteClip, { error: mutationError, loading: deletingClip }] =
    useDeleteClipMutation({
      update(cache, { data }) {
        const deletedClip = data?.deleteClip?.clip;
        const call = deletedClip?.call;

        if (call) {
          cache.modify({
            id: cache.identify(call),
            fields: {
              clipsCount: (existing: number) => existing - 1,
              callClips: (existing: { id: string }[]) =>
                existing.filter(({ id }) => id !== clip.id),
            },
          });
        }

        if (deletedClip) {
          cache.evict({
            id: cache.identify(deletedClip),
          });
          cache.gc();
        }
      },
      onCompleted: (data) => {
        if (data?.deleteClip?.clip) {
          if (onSuccess) onSuccess();
        }
      },
    });
  // Only clip creator can delete clip at the moment
  const { canDelete } = clip;

  const handleDelete = (): void => {
    deleteClip({ variables: { id: clip.id } });
  };

  useEffect(() => {
    if (mutationError) {
      onClose();
      onError();
    }
  }, [mutationError]);

  return (
    <>
      {buttonStyle === "menuItem" ? (
        <Flex
          flexFlow="row nowrap"
          alignItems="center"
          px="3"
          onClick={canDelete ? onOpen : undefined}
          width="full"
          color={canDelete ? "red.500" : "gray.700"}
        >
          <IoTrashBin size="16px" />
          <Box ml="3" fontWeight="500">
            Delete Clip
            {!canDelete && (
              <Box fontWeight="400" fontSize="12px" width="100%">
                Please contact clip creator to request deletion of this clip.
              </Box>
            )}
          </Box>
        </Flex>
      ) : (
        <Button
          variant="dangerOutline"
          size="sm"
          onClick={onOpen}
          isDisabled={isDisabled}
        >
          Delete clip
        </Button>
      )}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Are you sure you want to delete this clip?
            </AlertDialogHeader>
            <AlertDialogBody>
              Deleting the clip will also permanently delete the clip recording
              and clip notes, interview data will not be affected.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                variant="outline"
                size="sm"
                ref={cancelRef}
                onClick={onClose}
                isDisabled={deletingClip}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                size="sm"
                data-testid="clip-delete-confirm-button"
                isLoading={deletingClip}
                onClick={handleDelete}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default DeleteClipButton;
