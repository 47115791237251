import { Button, Flex, HStack, Icon, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { HiOutlineStar, HiStar } from "react-icons/hi";

import PopoverBeta from "../../../../components/Popover/Popover";
import { CandidateRatingBreakdown } from "../../../graphql";

const fillInMissingRatings = (
  ratingBreakdown: CandidateRatingBreakdown[]
): CandidateRatingBreakdown[] => {
  return [5, 4, 3, 2, 1].map((rating) => {
    const inBreakdown = ratingBreakdown.find((r) => r.rating === rating);
    return inBreakdown || { count: 0, rating };
  });
};

const StarIcon: React.FC<{ filled: boolean }> = ({ filled }) => (
  <Icon
    w="16px"
    h="16px"
    color="yellow.500"
    strokeWidth={filled ? "1px" : "1.5px"}
    as={filled ? HiStar : HiOutlineStar}
    pointerEvents="none"
  />
);

const StarList: React.FC<{ number: number }> = ({ number }) => {
  const emptyCount = Math.max(5 - number, 0);
  const mapStars = (number: number, filled: boolean): React.ReactElement[] =>
    [...Array(number)].map((_, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <StarIcon key={`${idx}_${filled.toString()}`} filled={filled} />
    ));
  return (
    <HStack spacing={0.5}>
      {mapStars(number, true).concat(mapStars(emptyCount, false))}
    </HStack>
  );
};

const CandidateStarRating: React.FC<{
  rating: number;
  ratingBreakdown: CandidateRatingBreakdown[];
}> = ({ rating, ratingBreakdown }) => {
  const button = (
    <Button variant="linkGray" textTransform="unset" p={0} height="unset">
      <Flex alignItems="center">
        <Text fontSize="xs" fontWeight="400" my={0} mr={2}>
          Overall rating
        </Text>
        <StarList number={rating} />
      </Flex>
    </Button>
  );
  const breakdown = fillInMissingRatings(ratingBreakdown);
  return (
    <PopoverBeta
      button={button}
      contentProps={{ maxWidth: "140px", borderRadius: "8px" }}
    >
      <VStack spacing={0}>
        {breakdown.map((rating) => (
          <Flex key={rating.rating} alignItems="center" py={1.5}>
            <StarList number={rating.rating} />
            <Text ml={3} color="black" fontSize="sm" fontWeight="500">
              {rating.count}
            </Text>
          </Flex>
        ))}
      </VStack>
    </PopoverBeta>
  );
};

export default CandidateStarRating;
