import {
  Slider,
  SliderFilledTrack,
  SliderProps,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";
import React from "react";

interface VolumeControlProps {
  volume: number;
  disabled: boolean;
  setVolume: (volume: number) => void;
}

const VolumeControl: React.FC<VolumeControlProps & SliderProps> = ({
  setVolume,
  volume,
  disabled,
  ...sliderProps
}) => {
  const color = disabled ? "gray.100" : "blue.200";
  return (
    <Slider
      value={volume * 100}
      onChange={(value) => setVolume(value / 100)}
      max={100}
      width="12"
      isDisabled={disabled}
      cursor={disabled ? "not-allowed" : "pointer"}
      focusThumbOnChange={false}
      {...sliderProps}
    >
      <SliderTrack bg={color}>
        {!disabled && <SliderFilledTrack bg="blue.600" />}
      </SliderTrack>
      {!disabled && <SliderThumb width="3" height="3" />}
    </Slider>
  );
};

export default VolumeControl;
