import { Box, Flex, Icon, Image, SystemProps } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";
import { MdExitToApp } from "react-icons/md";

import { Breakpoint } from "../../theme/breakpoints";
import { Card, CardProps } from "../Card";
import { RouterLinkBox } from "../RouterLink";

type CenteredCardLayoutProps = {
  /**
   * If provided, an absolutely-positioned "Sign Out" UI will be put on
   * the top right of the page
   */
  onSignOut?(): void;
  containerProps?: SystemProps;
  cardProps?: CardProps;
  /** The breakpoint above which the card layout will be used. Defaults to `"md"` */
  breakOn?: Breakpoint;
  /** URL to apply to the BH logo */
  logoLinkUrl?: string;
};

/**
 * Renders `children` in a card with a BH logo that is centered on the page.
 * No page navigation is included.
 *
 * On mobile viewports, the card is omitted and content is put at the top
 * of the page under a BH logo.
 *
 * An optional "Sign out" UI will be absolutely positioned in the top right
 * if `onSignOut` is provided
 */
export const CenteredCardLayout: React.FC<
  PropsWithChildren<CenteredCardLayoutProps>
> = ({
  onSignOut,
  containerProps,
  cardProps,
  breakOn = "md",
  logoLinkUrl,
  children,
}) => {
  let logo = <Image src="/static/images/logo_wordmark_blue.svg" w="150px" />;
  if (logoLinkUrl) {
    logo = (
      <RouterLinkBox to={logoLinkUrl} isExternal>
        {logo}
      </RouterLinkBox>
    );
  }

  return (
    <Flex
      direction="column"
      alignItems={{ base: "initial", [breakOn]: "center" }}
      justifyContent={{ base: "initial", [breakOn]: "center" }}
      w="100%"
      minH="100vh"
      py="2"
      px="4"
      {...containerProps}
    >
      {onSignOut && (
        <Flex
          onClick={onSignOut}
          cursor="pointer"
          pos="absolute"
          right="5"
          top={{ base: "3", [breakOn]: "4" }}
          alignItems="center"
          color="gray.900"
        >
          <Icon as={MdExitToApp} mr="1" />
          Sign out
        </Flex>
      )}

      <Card
        // Hide the card on small screens
        display={{ base: "contents", [breakOn]: "flex" }}
        flexDirection="column"
        py={{ base: "5", [breakOn]: "8" }}
        px={{ base: "5", [breakOn]: "8" }}
        maxW="min(700px, 90vw)"
        minW={{ base: "0", sm: "400px" }}
        {...cardProps}
      >
        <Box alignSelf="center">{logo}</Box>
        {children}
      </Card>
    </Flex>
  );
};
