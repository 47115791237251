import { Box, Text } from "@chakra-ui/react";
import React, { ReactElement } from "react";

import { LoadingIndicator } from "../../../components";
import { SelectDateRangeState } from "../../../components/SelectDateRange/SelectDateRange";
import { AnalyticsDimension, MetricName } from "../../graphql";
import { MetricConfig } from "./MetricConfig";
import { DataPoint } from "./types";
import { calculateMetricStats, formatValue } from "./utils";

export type MetricSummaryProps = {
  metric: MetricName;
  primaryDimension: AnalyticsDimension;
  data?: DataPoint[];
  loading: boolean;
  dateRange: SelectDateRangeState;
};

/**
 * A question and answer description of data.
 */
const MetricSummary: React.FC<MetricSummaryProps> = ({
  primaryDimension,
  metric,
  data,
  loading,
  dateRange,
}) => {
  const { question, valueFormat, avgValueFormat, answer } =
    MetricConfig[metric];

  const dataPoints = data || [];
  const { totalInterviews, numXValues, metricAverageValue, metricTotalValue } =
    calculateMetricStats(dataPoints, metric);

  const xValueLabel = primaryDimension
    .toLocaleLowerCase()
    .replace("job_", "interview ");

  const durationMessage: ReactElement =
    dateRange.selected === "1" ? (
      <span>over the last 6 months</span>
    ) : dateRange.selected === "2" ? (
      <span>over the last 90 days</span>
    ) : dateRange.selected === "3" ? (
      <span>over the last 30 days</span>
    ) : dateRange.selected === "4" ? (
      <span>over the last 7 days</span>
    ) : (
      <span>
        between {dateRange.start.toLocaleDateString()} and{" "}
        {dateRange.end.toLocaleDateString()}
      </span>
    );

  const formatOptions = { verbose: true };
  const formattedAnswer = answer(
    formatValue(metricTotalValue, valueFormat, formatOptions),
    totalInterviews,
    durationMessage,
    formatValue(metricAverageValue, avgValueFormat, formatOptions),
    numXValues,
    xValueLabel
  );

  return (
    <Box my="4" ml="6" min-height="56px">
      <Text color="gray.800" fontWeight="700">
        {question}
      </Text>
      {loading && (
        <Box height="24px" mt="2" pr="6">
          <LoadingIndicator size="sm" />
        </Box>
      )}
      {!loading && (
        <Box color="gray.800" mt="2">
          {formattedAnswer}
        </Box>
      )}
    </Box>
  );
};

export default MetricSummary;
