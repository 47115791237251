import { Conditional } from "../../utils/types";
import { FilteredHits, KeywordHit } from "./types";

export const filterHits = (
  hits: KeywordHit[],
  keywordList: string[],
  conditional: Conditional
): FilteredHits => {
  const fHits: FilteredHits = {
    groupedHits: hits,
    candidateHits: [],
    interviewerHits: [],
  };
  const subdividedKeywordList: string[] = [];
  keywordList.forEach((keyword) => {
    const words = keyword.split(" ");
    subdividedKeywordList.push(...words);
  });

  hits.forEach((hit) => {
    if (!hit) {
      return;
    }
    if (
      (hit.candidateMatchedWords.size > 0 && conditional === Conditional.OR) ||
      (hit.candidateMatchedWords.size === subdividedKeywordList.length &&
        conditional === Conditional.AND)
    ) {
      fHits.candidateHits.push({
        ...hit,
        transcriptSegments: hit.candidateSegments,
      });
    }
    if (
      (hit.interviewerMatchedWords.size > 0 &&
        conditional === Conditional.OR) ||
      (hit.interviewerMatchedWords.size === subdividedKeywordList.length &&
        conditional === Conditional.AND)
    ) {
      fHits.interviewerHits.push({
        ...hit,
        transcriptSegments: hit.interviewSegments,
      });
    }
  });

  return fHits;
};
