import invariant from "invariant";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useSendGAEvent } from "../../../utils/googleAnalytics";
import useCurrentUser from "../../hooks/useCurrentUser";
import { FilterOption, HighlightsFiltersProps } from "./HighlightsFilters";
import { CallHighlight, CandidateCall } from "./types";
import {
  getCallName,
  getHighlightSearchString,
  getTagDisplayText,
  isAiNoteHighlight,
  isGuideHighlight,
} from "./utils";

const INTERVIEW_URL_KEY = "interviews";
const INTERVIEWER_URL_KEY = "interviewers";
const TYPE_URL_KEY = "type";
const SEARCH_URL_KEY = "search";
const SMART_FILTER_URL_KEY = "smartFilters";

/**
 * Gather all highlights from a list of calls, and link each highlight's call
 * to the highlight
 */
const getHighlightsFromCalls = (calls: CandidateCall[]): CallHighlight[] =>
  calls.reduce<CallHighlight[]>(
    (highlights, call) =>
      highlights.concat(
        call.highlights.map((highlight) => ({ ...highlight, call }))
      ),
    []
  );

export type HighlightFilters = HighlightsFiltersProps & {
  /**
   * Sets page to "Highlights" tab and shows highlights for the given call
   */
  showHighlightsForInterview(id: string): void;
  allHighlights: CallHighlight[];
};

export const useHighlightFilters = (
  calls: CandidateCall[]
): HighlightFilters => {
  const sendGAEvent = useSendGAEvent();
  const allHighlights = getHighlightsFromCalls(calls);
  const currentUser = useCurrentUser();

  const [searchParams, setSearchParams] = useSearchParams();

  const filterSearchQuery =
    searchParams.get(SEARCH_URL_KEY)?.toLocaleLowerCase() ?? "";
  const filterInterviews =
    searchParams.get(INTERVIEW_URL_KEY)?.split(",") ?? [];
  const filterInterviewers =
    searchParams.get(INTERVIEWER_URL_KEY)?.split(",") ?? [];
  const filterHighlightTypes = searchParams.get(TYPE_URL_KEY)?.split(",") ?? [];
  const filterSmartFilters =
    searchParams.get(SMART_FILTER_URL_KEY)?.split(",") ?? [];

  const matchesSearchFilter = (highlight: CallHighlight): boolean =>
    filterSearchQuery.length === 0 ||
    getHighlightSearchString(highlight).includes(
      searchQuery.toLocaleLowerCase()
    );

  const matchesTypeFilter = (highlight: CallHighlight): boolean =>
    filterHighlightTypes.length === 0 ||
    (isGuideHighlight(highlight) && filterHighlightTypes.includes("cue")) ||
    (isAiNoteHighlight(highlight) && filterHighlightTypes.includes("ai"));

  const matchesTagFilter = (highlight: CallHighlight): boolean =>
    filterSmartFilters.length === 0 ||
    (isAiNoteHighlight(highlight) &&
      (highlight.tags || []).some((tag) => filterSmartFilters.includes(tag)));

  const matchesInterviewFilter = ({ id }: CandidateCall): boolean =>
    filterInterviews.length === 0 || filterInterviews.includes(id);

  const matchesInterviewerFilter = ({ interviewers }: CandidateCall): boolean =>
    filterInterviewers.length === 0 ||
    filterInterviewers.some((id) => interviewers.find((i) => i.id === id));

  const [searchQuery, setSearchQuery] = useState(
    decodeURIComponent(filterSearchQuery)
  );

  // Filtering occurs in two passes.
  // First, filter down the base list of calls to those that match the Interview/Interviewer checkbox filters.
  const matchingCalls = calls.filter(
    (call) => matchesInterviewFilter(call) && matchesInterviewerFilter(call)
  );

  // Second, filter the list of highlights from the already-filtered calls to only those that match the search query and type filter(s).
  const matchingHighlights = getHighlightsFromCalls(matchingCalls).filter(
    (highlight) =>
      matchesTypeFilter(highlight) &&
      matchesSearchFilter(highlight) &&
      matchesTagFilter(highlight)
  );
  const { allowAiNotes } = currentUser.organization;

  const typeFilterOptions: FilterOption[] = [
    ...(allowAiNotes
      ? [
          {
            id: "ai",
            displayName: "AI highlights",
            active: filterHighlightTypes.includes("ai"),
            count: matchingHighlights.filter((highlight) =>
              isAiNoteHighlight(highlight)
            ).length,
          },
        ]
      : []),
    {
      id: "cue",
      displayName: "Guide highlights",
      active: filterHighlightTypes.includes("cue"),
      count: matchingHighlights.filter((highlight) =>
        isGuideHighlight(highlight)
      ).length,
    },
  ];

  const interviewFilterOptions = calls.reduce((prev, cur) => {
    const nameKey = getCallName(cur);
    return prev.concat({
      id: cur.id,
      displayName: nameKey,
      active: filterInterviews.includes(cur.id),
      count: matchingHighlights.filter(
        (highlight) => highlight.call.id === cur.id
      ).length,
    });
  }, [] as FilterOption[]);

  const interviewerFilterOptions = calls.reduce((prev, cur) => {
    cur.interviewers.forEach((i) => {
      if (!prev.find((o) => o.id === i.id))
        prev.push({
          id: i.id,
          displayName: i.fullName,
          active: filterInterviewers.includes(i.id),
          count: matchingHighlights.filter((highlight) =>
            highlight?.call?.interviewers.find((o) => o.fullName === i.fullName)
          ).length,
        });
    });
    return prev;
  }, [] as FilterOption[]);

  const smartFilterOptions = calls.reduce((prev, cur) => {
    cur.highlights.forEach((h) => {
      if (!isAiNoteHighlight(h)) return;
      if (h.tags && h.tags.length > 0) {
        h.tags.forEach((t) => {
          if (!prev.find((o) => o.id === t))
            prev.push({
              id: t,
              displayName: getTagDisplayText(t),
              active: filterSmartFilters.includes(t),
              count: matchingHighlights.filter(
                (highlight) =>
                  isAiNoteHighlight(highlight) &&
                  highlight?.tags?.find((o) => o === t)
              ).length,
            });
        });
      }
    });
    return prev;
  }, [] as FilterOption[]);

  return {
    interviewFilterOptions,
    toggleFilterInterview(interview) {
      sendGAEvent("highlight_filter", "candidate", "interview");
      const i = filterInterviews.indexOf(interview.id);
      if (i === -1) filterInterviews.push(interview.id);
      else filterInterviews.splice(i, 1);

      if (filterInterviews.length)
        searchParams.set(INTERVIEW_URL_KEY, filterInterviews.join(","));
      else searchParams.delete(INTERVIEW_URL_KEY);

      setSearchParams(searchParams, { replace: true });
    },

    interviewerFilterOptions,
    toggleFilterInterviewer(interviewer) {
      sendGAEvent("highlight_filter", "candidate", "interviewer");
      const i = filterInterviewers.indexOf(interviewer.id);
      if (i === -1) filterInterviewers.push(interviewer.id);
      else filterInterviewers.splice(i, 1);

      if (filterInterviewers.length)
        searchParams.set(INTERVIEWER_URL_KEY, filterInterviewers.join(","));
      else searchParams.delete(INTERVIEWER_URL_KEY);

      setSearchParams(searchParams, { replace: true });
    },

    typeFilterOptions,
    toggleFilterHighlightType(highlightType) {
      sendGAEvent("highlight_filter", "candidate", "highlight_type");
      const i = filterHighlightTypes.indexOf(highlightType.id);
      if (i === -1) filterHighlightTypes.push(highlightType.id);
      else filterHighlightTypes.splice(i, 1);

      if (filterHighlightTypes.length)
        searchParams.set(TYPE_URL_KEY, filterHighlightTypes.join(","));
      else searchParams.delete(TYPE_URL_KEY);

      setSearchParams(searchParams, { replace: true });
    },

    smartFilterOptions,
    toggleFilterSmartFilters(smartFilter) {
      sendGAEvent("highlight_filter", "candidate", "smart_filter");
      const i = filterSmartFilters.indexOf(smartFilter.id);
      if (i === -1) filterSmartFilters.push(smartFilter.id);
      else filterSmartFilters.splice(i, 1);

      if (filterSmartFilters.length)
        searchParams.set(SMART_FILTER_URL_KEY, filterSmartFilters.join(","));
      else searchParams.delete(SMART_FILTER_URL_KEY);

      setSearchParams(searchParams, { replace: true });
    },

    allHighlights,
    matchingHighlights,

    showHighlightsForInterview(callId) {
      sendGAEvent("view_highlights_for_call", "candidate");
      const call = calls.find((call) => call.id === callId);
      invariant(call, `Failed to find matching highlights for ${callId}`);

      searchParams.set("tab", "highlights");
      searchParams.set(INTERVIEW_URL_KEY, call.id);
      setSearchParams(searchParams, { replace: true });
    },

    hasActiveFilters() {
      return !!(
        filterSearchQuery.length ||
        filterInterviews.length ||
        filterInterviewers.length ||
        filterHighlightTypes.length
      );
    },

    clearFilters() {
      sendGAEvent("highlight_filter", "candidate", "clear_all");
      searchParams.delete(INTERVIEW_URL_KEY);
      searchParams.delete(INTERVIEWER_URL_KEY);
      searchParams.delete(TYPE_URL_KEY);
      searchParams.delete(SEARCH_URL_KEY);
      searchParams.delete(SMART_FILTER_URL_KEY);
      setSearchParams(searchParams, { replace: true });
      setSearchQuery("");
    },

    searchQuery,

    updateSearchQuery(query) {
      sendGAEvent("highlight_filter", "candidate", "input", query);
      if (query.length) {
        searchParams.set(SEARCH_URL_KEY, encodeURIComponent(query));
      } else {
        searchParams.delete(SEARCH_URL_KEY);
      }
      setSearchParams(searchParams, { replace: true });
      setSearchQuery(query);
    },
  };
};
