import { Box, Button, Input, Text } from "@chakra-ui/react";
import { transparentize } from "@chakra-ui/theme-tools";
import React, { useState } from "react";

import { RouterLink } from "../../../components";
import useToast, { errorToast } from "../../../components/useToast/useToast";
import TrainingListItem from "./TrainingListItem";
import { TrainingListItemType } from "./types";
import { TrainingApi } from "./useTrainingApi";
import { parseCallAndClipFromUrl, parseInternalUrlType } from "./utils";

type RecordingStepProps = {
  trainingProgramId: string;
  trainingListItems: TrainingListItemType[];
  trainingApi: TrainingApi;
  setAutoSaveInProgress: (autoSaveInProgress: boolean) => void;
};

const RecordingStep: React.FC<RecordingStepProps> = ({
  trainingProgramId,
  trainingListItems,
  trainingApi,
  setAutoSaveInProgress,
}) => {
  const toast = useToast();
  const [recordingUrl, setRecordingUrl] = useState("");

  const onSubmit = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    const callAndClipId = parseCallAndClipFromUrl(recordingUrl);
    if (!callAndClipId) {
      const urlType = parseInternalUrlType(recordingUrl);
      const failureReason =
        urlType === "invalid"
          ? `This is not a URL.`
          : `This looks like ${
              urlType.match(/\b[aeiou]/) ? "an" : "a"
            } ${urlType} page link.`;
      errorToast(
        toast,
        `Must be a valid BrightHire recording or clip URL. ${failureReason}`
      );
      return;
    }
    trainingApi.recording.add(
      trainingProgramId,
      callAndClipId.callId,
      callAndClipId.clipId
    );
    setRecordingUrl("");
    setAutoSaveInProgress(true);
  };

  return (
    <Box minHeight="0" overflowY="auto" overflowX="hidden" mb="-1px">
      <Box px="10">
        <Text color="gray.700" pt="10" pb="6">
          We recommend adding 2-3 recordings that are great examples of this
          interview, as well as some clips that highlight specific skills you
          deem important, such as pitching the company, handling common
          objections, and more.
        </Text>
        <form
          onSubmit={onSubmit}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            border: "1px solid #CBD5E0",
            borderRadius: "4px",
            padding: "6px",
          }}
        >
          <Input
            fontSize="sm"
            placeholder="Paste URL to a BrightHire recording or clip"
            onChange={(e) => setRecordingUrl(e.target.value)}
            value={recordingUrl}
            border="none"
            focusBorderColor="transparent"
            data-tour-id="training-add-recording"
            data-testid="training-add-recording"
          />
          <Button
            ml="4"
            type="submit"
            disabled={recordingUrl === ""}
            background={transparentize("blue.600", 0.1) as unknown as string}
            color="blue.600"
            fontWeight={600}
            _hover={{
              background:
                recordingUrl === ""
                  ? "gray.100"
                  : (transparentize("blue.600", 0.2) as unknown as string),
            }}
          >
            Add
          </Button>
        </form>
        <Box mt="6" mb="10">
          <RouterLink
            to="/search"
            fontSize="md"
            color="blue.600"
            fontWeight={500}
            px={4}
            py={3}
            borderRadius="4px"
            target="_blank"
            _hover={{
              background: transparentize("blue.600", 0.1) as unknown as string,
              textDecoration: "none",
            }}
          >
            Search Recordings
          </RouterLink>
        </Box>
      </Box>
      {trainingListItems.length > 0 && (
        <Box borderTop="gray.100">
          {trainingListItems.map((trainingItem, i) => (
            <TrainingListItem
              key={trainingItem.id}
              trainingItem={trainingItem}
              listLength={trainingListItems.length}
              listPosition={i}
              trainingApi={trainingApi}
              trainingProgramId={trainingProgramId}
              setAutoSaveInProgress={setAutoSaveInProgress}
              trainingListItems={trainingListItems}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default RecordingStep;
