import { Box, List, ListItem } from "@chakra-ui/react";
import React, { useEffect, useMemo, useRef } from "react";

import { CallNoteFragment, CallNoteType } from "../../graphql";
import { ClipRange } from "../Interview/Clip/types";
import CallNote from "./CallNote";
import CallNoteEmptyState from "./CallNoteEmptyState";
import { getNoteStyleProps } from "./utils";

type CallNoteListProps = {
  /**
   * The complete notes to display
   */
  notes: Array<CallNoteFragment>;
  interviewerIds?: Array<string>;
  /**
   * Start time, as referenced by the t= parameter when linking to an interview
   */
  startTime?: number;
  onClickTimestamp?: (time: number) => void;
  showAvatar?: boolean;
  /**
   * The note that should currently be highlighted
   */
  currentNoteId?: string;
  notesReadOnly?: boolean;
  showNotesEmptyState?: boolean;
  limitedNoteControls?: boolean;
  maxHeight?: string;
  paddingTop?: number;
  timestampLeft?: boolean;
  setClipRange?(clipRange: ClipRange | null, adjustByWordTime: boolean): void;
  duration?: number | null | undefined;
  clipNotes?: boolean;
  onDeleteNote: (id: string) => void;
  noteIsDeleting?: boolean;
  disableAutoScroll?: boolean;
  /** Allows for optional customization of the timestamp UI */
  timestampComponent?(note: CallNoteFragment): React.ReactNode;
};

const CallNoteList: React.FC<CallNoteListProps> = ({
  notes,
  interviewerIds,
  startTime,
  onClickTimestamp,
  showAvatar = true,
  currentNoteId,
  notesReadOnly,
  showNotesEmptyState = false,
  limitedNoteControls = false,
  maxHeight,
  paddingTop,
  timestampLeft,
  setClipRange,
  duration,
  clipNotes,
  onDeleteNote,
  noteIsDeleting,
  disableAutoScroll,
  timestampComponent,
}) => {
  /**
   * Ref to the original set of notes
   */
  const prevNotesRef = useRef<CallNoteFragment[]>();

  // When notes change, reset the ref
  useEffect(() => {
    prevNotesRef.current = [...notes];
  }, [notes]);

  /**
   * Find a note to scroll to
   */
  const scrollToNote = useMemo(() => {
    // If there is a note that isn't in the ref, pick that
    const note = notes.find(
      (n) =>
        prevNotesRef.current &&
        !prevNotesRef.current.some((pn) => n.id === pn.id)
    );
    if (note) {
      return note;
    }

    // If there's a start time but no current note, find the one that's closest
    if (startTime && !currentNoteId) {
      const reversedNotes = [...notes];
      reversedNotes.reverse();
      return reversedNotes.find((note) => note.time <= startTime);
    }

    // If there's a current note, pick that
    const currentNote = notes.find(
      (n) => currentNoteId && n.id === currentNoteId
    );
    if (currentNote) {
      return currentNote;
    }
  }, [notes, currentNoteId]);

  const noteRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (noteRef.current && !disableAutoScroll) {
      noteRef.current.scrollIntoView({
        block: "nearest",
        inline: "nearest",
        behavior: "smooth",
      });
    }
  }, [scrollToNote, noteRef.current]);

  const longDuration = Math.max(...notes.map(({ time }) => time)) >= 3600;
  const { minW } = getNoteStyleProps(
    timestampLeft,
    !!longDuration
  ).timestampCol;
  const templateColumns = timestampLeft
    ? `${minW} 56px auto 1px`
    : `56px auto 1px ${minW}`;

  return (
    <Box
      fontSize="sm"
      pt={paddingTop ?? 0}
      flex="0 1 auto"
      maxHeight={{
        base: maxHeight ?? "auto",
      }}
    >
      <>
        {showNotesEmptyState && <CallNoteEmptyState isForClip={clipNotes} />}
        <List>
          {notes
            .filter((note) => note.type !== CallNoteType.Cue)
            .map((note, index) => (
              <ListItem key={note.id}>
                <CallNote
                  key={note.id}
                  data-testid={`call-note-${index}`}
                  ref={scrollToNote?.id === note.id ? noteRef : undefined}
                  note={note}
                  interviewerIds={interviewerIds}
                  onDelete={onDeleteNote}
                  noteIsDeleting={noteIsDeleting}
                  timestampLeft={timestampLeft}
                  onClickTimestamp={onClickTimestamp}
                  showAvatar={showAvatar}
                  notesReadOnly={notesReadOnly}
                  limitedNoteControls={limitedNoteControls}
                  px="2"
                  py="1"
                  boxShadow={
                    currentNoteId === note.id
                      ? `inset 2px 0px 0px 0px rgba(79, 175, 253, 0.5)`
                      : undefined
                  }
                  templateColumns={templateColumns}
                  setClipRange={setClipRange}
                  duration={duration}
                  color="gray.700"
                  timestampComponent={timestampComponent?.(note)}
                />
              </ListItem>
            ))}
        </List>
      </>
    </Box>
  );
};

CallNoteList.displayName = "CallNoteList";

export default React.memo(CallNoteList);
