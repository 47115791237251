import {
  ExternalCallFragment,
  ExternalClipFragment,
  ExternalUserViewCallMutation,
  ExternalUserViewClipMutation,
} from "../../../graphql";

export type ExternalRecording = (
  | ExternalCallFragment
  | ExternalClipFragment
) & { transcriptLanguageCode?: string | null };

export type Topic = ExternalRecording["topics"][number];

export function isExternalUserViewCall(
  data: ExternalUserViewCallMutation | ExternalUserViewClipMutation
): data is ExternalUserViewCallMutation {
  return (
    (data as ExternalUserViewCallMutation).externalUserViewCall !== undefined
  );
}
