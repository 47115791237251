import "rc-slider/assets/index.css";

import { Range } from "rc-slider";
import React from "react";

import colors from "../../theme/css-color-variables";

interface RangeSliderProps {
  min: number;
  max: number;
  values: { min: number; max: number };
  onChange: (min: number, max: number) => void;
  onAfterChange: (min: number, max: number) => void;
  isDisabled?: boolean;
}

const RangeSlider: React.FC<RangeSliderProps> = ({
  min,
  max,
  values,
  onChange,
  onAfterChange,
  isDisabled = false,
}) => {
  const color = isDisabled ? colors.secondary : colors.blue[600];

  return (
    <Range
      trackStyle={[{ backgroundColor: color }]}
      handleStyle={[
        {
          marginTop: "-4px",
          height: "11px",
          width: "11px",
          borderColor: color,
          backgroundColor: color,
        },
        {
          marginTop: "-4px",
          height: "11px",
          width: "11px",
          borderColor: color,
          backgroundColor: color,
        },
      ]}
      railStyle={{
        backgroundColor: colors.blue[200],
      }}
      min={min}
      max={max}
      value={[values.min, values.max]}
      allowCross={false}
      disabled={isDisabled}
      onChange={(values) => {
        onChange(values[0], values[1]);
      }}
      onAfterChange={(values) => {
        onAfterChange(values[0], values[1]);
      }}
    />
  );
};

export default RangeSlider;
