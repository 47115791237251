import {
  Box,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMobileOrientation } from "react-device-detect";

import { AudioImage } from "../../../components";
import { useFullscreen } from "../../../hooks/useFullscreen";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { pluck } from "../../../utils/array";
import { formatDate } from "../../../utils/datetime";
import useMediaPlayer from "../Interview/useMediaPlayer";
import VideoControlsMobile from "../Interview/VideoControlsMobile";
import { VideoPlayerProgressBarProps } from "../Interview/VideoPlayerProgressBar";
import TruncatedText from "../TruncatedText";
import VideoPlayer from "../Video/VideoPlayerBeta";
import { AiHighlightTag } from "./HighlightModal";
import { CallHighlight } from "./types";
import useViewHighlight from "./useViewHighlight";
import { getCallName, isAiNoteHighlight } from "./utils";

export type HighlightModalMobileProps = {
  highlight: CallHighlight;
  onClose(): void;
};

const HighlightModalMobile: React.FC<HighlightModalMobileProps> = ({
  highlight,
  onClose,
}) => {
  const { call } = highlight;
  const { absoluteLayoutHeight } = useWindowDimensions();

  const { isLandscape } = useMobileOrientation();
  const videoRef = useRef<HTMLElement | null>(null);
  const { isFullscreen, requestFullscreen, exitFullscreen } =
    useFullscreen(videoRef);

  useEffect(() => {
    if (isLandscape) {
      requestFullscreen?.();
    } else if (isFullscreen) {
      exitFullscreen?.();
    }
  }, [isLandscape]);

  useEffect(() => {
    if (isLandscape && !isFullscreen) onClose();
  }, [isFullscreen]);

  useViewHighlight(highlight);

  const [mediaPlayerRef, listeners, player] = useMediaPlayer();
  const progressBarProps: VideoPlayerProgressBarProps = {
    duration: player.duration ?? 0,
    value: player.time,
    seek: player.seek,
    disabled: false,
  };

  const { duration, seek } = player;

  useEffect(() => {
    if (duration && highlight.startTime) {
      seek(highlight.startTime);
    }
  }, [duration, highlight.startTime, seek]);

  const [shouldShowScreenControls, setShouldShowScreenControls] =
    useState(true);
  const [hideScreenControlsTimeout, setHideScreenControlsTimeout] =
    useState<NodeJS.Timeout>();

  const onVideoPlayerClick = useCallback(() => {
    if (player.playing) {
      setShouldShowScreenControls(true);
      clearTimeout(hideScreenControlsTimeout);
      setHideScreenControlsTimeout(
        setTimeout(() => {
          setShouldShowScreenControls(false);
        }, 3000)
      );
    }
  }, [player.playing, hideScreenControlsTimeout, player]);

  useEffect(() => {
    if (!player.playing) {
      setShouldShowScreenControls(true);
      clearTimeout(hideScreenControlsTimeout);
    } else {
      setShouldShowScreenControls(false);
    }
  }, [player.playing]);

  return (
    <Modal isOpen onClose={onClose} size="full" returnFocusOnClose={false}>
      <ModalOverlay color="red" />
      <ModalContent bg="black" maxH={absoluteLayoutHeight}>
        <ModalCloseButton boxSize="6" color="white" zIndex="1" />

        <ModalBody
          p="0"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box
            position="relative"
            onMouseEnter={() => setShouldShowScreenControls(true)}
            onMouseLeave={() => setShouldShowScreenControls(false)}
          >
            {call.streamableVideo && (
              <>
                <VideoPlayer
                  src={call.streamableVideo.url}
                  mediaPlayerRef={mediaPlayerRef}
                  videoRef={(el) => {
                    videoRef.current = el;
                  }}
                  isVideoVisible
                  {...listeners}
                />
                <VideoControlsMobile
                  variant="compact"
                  player={player}
                  progressBarProps={progressBarProps}
                  onClick={onVideoPlayerClick}
                  opacity={shouldShowScreenControls ? 1 : 0}
                  transition="opacity 300ms"
                />
              </>
            )}
            {call.streamableAudio && !call.streamableVideo && (
              <>
                <AudioImage
                  width="100%"
                  height="100%"
                  style={{ color: "white" }}
                  // {...revealControlsOnHover}
                />
                <audio
                  ref={mediaPlayerRef}
                  src={call.streamableAudio.url}
                  {...listeners}
                />
                <VideoControlsMobile
                  variant="compact"
                  player={player}
                  progressBarProps={progressBarProps}
                  onClick={onVideoPlayerClick}
                  opacity={shouldShowScreenControls ? 1 : 0}
                  transition="opacity 300ms"
                />
              </>
            )}
          </Box>

          {!isLandscape && (
            <VStack
              spacing="1"
              alignItems="start"
              fontSize="sm"
              color="gray.50"
              lineHeight="5"
              bg="gray.800"
              py={5}
              px={6}
              maxH={`${
                absoluteLayoutHeight - (videoRef.current?.offsetHeight || 0)
              }px`}
              overflowY="scroll"
            >
              <TruncatedText
                text={highlight.text}
                noOfLines={3}
                color="white"
                fontWeight="semibold"
                fontSize="lg"
                maxH="32"
                mb={2}
              />
              <Box>
                <Box fontSize="md" style={{ colorScheme: "dark" }}>
                  {isAiNoteHighlight(highlight) ? (
                    <UnorderedList
                      pr={4}
                      fontSize="sm"
                      pl={2}
                      fontWeight="medium"
                      lineHeight={6}
                    >
                      {highlight.description.split("\n").map((item) => (
                        <ListItem key={item}>{item}</ListItem>
                      ))}
                    </UnorderedList>
                  ) : (
                    highlight.description
                  )}
                </Box>
                <Box mt="6">{getCallName(call)}</Box>

                <Box>{pluck(call.interviewers, "fullName").join(", ")}</Box>

                <Box color="gray.300" fontSize="xs" lineHeight="4">
                  {formatDate(call.createdAt, {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </Box>

                {isAiNoteHighlight(highlight) && (
                  <Box mt="2">
                    <AiHighlightTag />
                  </Box>
                )}
              </Box>
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default HighlightModalMobile;
