import { Box, Flex, Icon, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import {
  HiOutlineBriefcase,
  HiOutlineUser,
  HiOutlineUserGroup,
} from "react-icons/hi2";

import { RouterLink, StarRating } from "../../../components";
import { useIsSmallScreen } from "../../../hooks/useIsSmallScreen";
import { pluck } from "../../../utils/array";
import {
  formatDateSeconds,
  formatDuration,
  formatRelativeDate,
  MS_PER_DAY,
} from "../../../utils/datetime";
import { escapeSearchResults } from "../../../utils/escape";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import useCurrentUser from "../../hooks/useCurrentUser";
import MonospacedText from "../MonospacedText";
import { SearchHit } from "../Search/types";

type SpotlightSearchResultProps = {
  hit: SearchHit;
};

export const SpotlightSearchResult: React.FC<SpotlightSearchResultProps> = ({
  hit,
}) => {
  const isSmallScreen = useIsSmallScreen();
  const sendGAEvent = useSendGAEvent();
  const currentUser = useCurrentUser();

  const hasCandidate = !!hit.candidate;
  const hasInterviewers = !!hit.interviewers && hit.interviewers.length > 0;

  const renderDotSpacer = (): React.ReactNode => (
    <Text mx="1" color="gray.300">
      •
    </Text>
  );

  const showNoteHours = hit.notes.some((note) => note.time >= 3600);
  const showTranscriptHours = hit.transcriptSegments.some(
    (segment) => segment.startTime >= 3600
  );

  const callStartTime = `${formatRelativeDate(
    new Date(hit.startTime * 1000).toISOString(),
    MS_PER_DAY * 30
  )}`;

  const candidateName =
    hit.candidate?.name ||
    hit.candidate?.phoneNumber ||
    hit.candidate?.email ||
    "Candidate";

  const interviewerNames = hit.interviewers
    ? pluck(hit.interviewers, "name")
    : [];
  const maxInterviewersToShow = 3;
  const showInterviewerOverflow =
    interviewerNames.length > maxInterviewersToShow;

  let displayedInterviewers = interviewerNames
    .slice(0, maxInterviewersToShow)
    .join(", ");
  if (showInterviewerOverflow) {
    displayedInterviewers += ` +${
      interviewerNames.length - maxInterviewersToShow
    } more`;
  }
  const interviewersTooltip = showInterviewerOverflow
    ? interviewerNames.map((name) => <p>{name}</p>)
    : undefined;

  let position = hit.position?.displayTitle ?? "";
  if (position && hit.stage) {
    position += ` (${hit.stage})`;
  }

  return (
    <Flex
      flexDirection="column"
      gap="2"
      pb="3"
      color="gray.900"
      borderBottom="1px"
      borderColor="gray.200"
      fontSize="sm"
      lineHeight="5"
    >
      <Flex alignItems="center" justifyContent="space-between">
        <RouterLink
          to={`/interview/${hit.callId}`}
          dangerouslySetInnerHTML={{
            __html: escapeSearchResults(hit.name || "Interview"),
          }}
          onClick={() => sendGAEvent("follow_link", "search", "call")}
          fontWeight="semibold"
          variant="bright"
        />
        <Tooltip
          label={formatDateSeconds(hit.startTime)}
          aria-label={formatDateSeconds(hit.startTime)}
          placement="bottom-start"
        >
          <Text fontSize="xs" color="gray.400">
            {callStartTime}
          </Text>
        </Tooltip>
      </Flex>

      <Flex mt="-0.5" wrap="wrap" gap="1">
        {hit.candidate && (
          <Flex alignItems="center" gap="1">
            <Icon as={HiOutlineUser} boxSize="4" color="gray.500" />
            <RouterLink
              to={`/candidate/${hit.candidate.id}`}
              dangerouslySetInnerHTML={{
                __html: escapeSearchResults(candidateName),
              }}
              onClick={() => sendGAEvent("follow_link", "search", "candidate")}
              color="gray.900"
              fontWeight="inherit"
              variant="bright"
            />
          </Flex>
        )}

        {hit.interviewers && hit.interviewers.length > 0 && (
          <>
            {hasCandidate && renderDotSpacer()}
            <Flex alignItems="center" gap="1">
              <Icon as={HiOutlineUserGroup} boxSize="4" color="gray.500" />
              <Tooltip
                label={interviewersTooltip}
                aria-label={
                  showInterviewerOverflow
                    ? interviewerNames.join(", ")
                    : undefined
                }
                placement="bottom-start"
              >
                <Text
                  as="span"
                  color="gray.600"
                  dangerouslySetInnerHTML={{
                    __html: escapeSearchResults(displayedInterviewers),
                  }}
                />
              </Tooltip>
            </Flex>
          </>
        )}

        {hit.position && (
          <>
            {(hasCandidate || hasInterviewers) && renderDotSpacer()}
            <Flex alignItems="center" gap="1">
              <Icon as={HiOutlineBriefcase} boxSize="4" color="gray.500" />
              <RouterLink
                to={`/position/${hit.position.id}`}
                dangerouslySetInnerHTML={{
                  __html: escapeSearchResults(position),
                }}
                onClick={() => sendGAEvent("follow_link", "search", "position")}
                color="gray.900"
                fontWeight="inherit"
                variant="bright"
              />
            </Flex>
          </>
        )}

        {hit.rating && currentUser.organization.showStarRatings ? (
          <Box ml="auto" pl="2">
            <StarRating
              rating={hit.rating}
              readOnly
              ratingDescriptions={currentUser.organization.ratingDescriptions}
            />
          </Box>
        ) : null}
      </Flex>

      {!isSmallScreen &&
        hit.notes?.map((note) => (
          <Flex pl="5" gap="4" key={note.id}>
            <RouterLink
              to={`/interview/${hit.callId}?t=${note.time}`}
              onClick={() => sendGAEvent("follow_link", "search", "note")}
              width={showNoteHours ? "65px" : "40px"}
              fontWeight="inherit"
              variant="bright"
            >
              <MonospacedText
                text={formatDuration(note.time, true, showNoteHours)}
              />
            </RouterLink>

            <Box
              as="span"
              {...(note.type === "CUE"
                ? {
                    px: 2.5,
                    pb: 0.5,
                    borderRadius: "full",
                    bg: "gray.100",
                    color: "gray.700",
                  }
                : { color: "gray.600" })}
              dangerouslySetInnerHTML={{
                __html: escapeSearchResults(note.text),
              }}
            />
          </Flex>
        ))}

      {!isSmallScreen &&
        hit.transcriptSegments?.map((segment) => (
          <Flex pl="5" gap="4" key={`${segment.speaker}${segment.startTime}`}>
            <RouterLink
              to={`/interview/${hit.callId}?t=${segment.startTime}`}
              onClick={() => sendGAEvent("follow_link", "search", "transcript")}
              width={showTranscriptHours ? "65px" : "40px"}
              fontWeight="inherit"
              variant="bright"
            >
              <MonospacedText
                text={formatDuration(
                  segment.startTime,
                  true,
                  showTranscriptHours
                )}
              />
            </RouterLink>

            <Box color="gray.600">
              [{segment.speaker}]&nbsp;
              <Box
                as="span"
                dangerouslySetInnerHTML={{
                  __html: escapeSearchResults(segment.text),
                }}
              />
            </Box>
          </Flex>
        ))}
    </Flex>
  );
};
