import { ApolloError, MutationTuple } from "@apollo/client";

import {
  AddCallGuideMutation,
  AddCallGuideMutationVariables,
  CallGuideVisibility,
  useAddCallGuideMutation,
} from "../..";
import {
  removeFromSharedCallGuidesQuery,
  updateCurrentUserQuery,
  updatePositionQuery,
  updateSharedCallGuidesQuery,
} from "./utils";

export default function useAddCallGuide({
  onCompleted,
  onError,
}: {
  onCompleted?: (data: AddCallGuideMutation) => void;
  onError?: (error: ApolloError) => void;
}): [
  MutationTuple<AddCallGuideMutation, AddCallGuideMutationVariables>[0],
  { loading: boolean; error: ApolloError | undefined }
] {
  const [addCallGuide, { error, loading }] = useAddCallGuideMutation({
    onCompleted,
    onError,
    update(cache, { data: addCallGuideData }) {
      const callGuide = addCallGuideData?.addCallGuide?.callGuide;
      if (callGuide) {
        updateCurrentUserQuery(cache, callGuide);
        if (callGuide.visibility === CallGuideVisibility.Public) {
          updateSharedCallGuidesQuery(cache, callGuide);
        } else {
          removeFromSharedCallGuidesQuery(cache, callGuide.id);
        }
        if (callGuide.position?.id) {
          updatePositionQuery(cache, callGuide, callGuide.position.id);
        }
      }
    },
  });

  return [addCallGuide, { loading, error }];
}
