import { Flex, Image } from "@chakra-ui/react";
import React from "react";

export const EmptyState: React.FC<{
  heading: string;
  description: string;
  section: "upcoming" | "completed";
}> = ({ heading, description, section }) => {
  return (
    <Flex
      width="100%"
      p={4}
      border="1px solid"
      borderColor="gray.100"
      borderRadius="lg"
      direction={{ base: "column", lg: "row" }}
      gap={{ base: "3", lg: "5" }}
      alignItems="center"
    >
      <Image
        w="170"
        src={`/static/images/${section}-interviews-empty-state.png`}
      />

      <Flex
        direction="column"
        justifyContent="center"
        gap={{ base: "2", lg: "1.5" }}
        textAlign="center"
      >
        <Flex color="gray.500" fontSize="lg" fontWeight="700" lineHeight="6">
          {heading}
        </Flex>
        <Flex color="gray.600" fontSize="md" fontWeight="400" lineHeight="5">
          {description}
        </Flex>
      </Flex>
    </Flex>
  );
};
