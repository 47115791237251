import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";

import { MetricName } from "../../graphql";
import { MetricConfig } from "./MetricConfig";

type AnalyticsInfoModalProps = {
  metric: MetricName;
};

const AnalyticsInfoModal: React.FC<AnalyticsInfoModalProps> = ({ metric }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dangerousHTMLString = MetricConfig[metric].detailsHTML;
  if (dangerousHTMLString.length === 0) return null;
  return (
    <>
      <Button variant="link" size="sm" fontWeight="400" onClick={onOpen}>
        Details
      </Button>

      <Modal closeOnOverlayClick isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            display="flex"
            flexDir="row"
            alignItems="center"
            justifyContent="space-between"
            px={6}
            borderBottom="1px"
            borderBottomColor="gray.100"
            fontWeight="500"
          >
            <Text fontWeight="500">Report Details</Text>
            <ModalCloseButton position="relative" top="0" right="0" />
          </ModalHeader>
          <ModalBody pl={8} pr={8} pt={8} pb={5}>
            <div
              // Content is static html without thread, this is just for bolding.
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: dangerousHTMLString,
              }}
            />
          </ModalBody>

          <ModalFooter justifyContent="center" mb="10">
            <Button variant="link" size="sm" fontWeight="500" onClick={onClose}>
              Got it
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AnalyticsInfoModal;
