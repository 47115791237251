import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  MenuItem,
  MenuItemProps,
} from "@chakra-ui/react";
import React from "react";
import { IoLockClosed } from "react-icons/io5";

import { useToast } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { useKeepCallMutation } from "../../graphql";

interface KeepCallButtonProps extends Omit<MenuItemProps, "onClick"> {
  call: { id: string };
}

const KeepCallButton: React.FC<KeepCallButtonProps> = ({
  call,
  ...menuItemProps
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const sendGAEvent = useSendGAEvent();

  const cancelRef = React.useRef<HTMLButtonElement | null>(null);
  const toast = useToast();

  const [keepCalls, { loading }] = useKeepCallMutation({
    onCompleted: () => {
      toast({
        title: "Success",
        status: "success",
      });
      setIsModalOpen(false);
    },
    onError: () => {
      toast({
        title: "Error",
        description: "Operation failed. Please try again later.",
        status: "error",
      });
    },
  });

  return (
    <>
      <MenuItem
        data-testid="call-settings-menu-keep"
        color="blue.600"
        icon={<IoLockClosed />}
        isDisabled={loading}
        onClick={() => {
          setIsModalOpen(true);
        }}
        {...menuItemProps}
      >
        Prevent Deletion
      </MenuItem>
      <AlertDialog
        isOpen={isModalOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsModalOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Prevent deletion of this interview?
            </AlertDialogHeader>
            <AlertDialogBody>
              This will prevent this interview from being deleted by your
              organization&apos;s data retention policy, but will still allow
              this interview to be manually deleted.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                variant="outline"
                size="sm"
                ref={cancelRef}
                isDisabled={loading}
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                size="sm"
                data-testid="call-delete-confirm-button"
                isLoading={loading}
                onClick={() => {
                  sendGAEvent("delete", "call_review");
                  keepCalls({
                    variables: { id: call.id },
                  });
                }}
                ml={3}
              >
                Prevent deletion
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

KeepCallButton.displayName = "KeepCallButton";

export default KeepCallButton;
