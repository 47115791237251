import semver from "semver";

import useFeatureFlag from "../main/graphql/hooks/useFeatureFlag";
import useCurrentUser from "../main/hooks/useCurrentUser";

const featureVersionMap = {
  // eslint-disable-next-line camelcase
  paste_scorecard: "2.2.10",
  "one-click-scorecard": "2.2.24",
};

type ExtensionFeature = keyof typeof featureVersionMap;

function useBrowserExtensionFeatureEnabled(
  extensionFeatureName: ExtensionFeature,
  options?: { checkFeatureFlag?: boolean }
): boolean {
  const featureFlagEnabled = useFeatureFlag(extensionFeatureName);
  const currentUser = useCurrentUser();
  const { browserExtensionVersion } = currentUser;
  if (!browserExtensionVersion) {
    return false;
  }
  const hasRelevantVersion = semver.gte(
    browserExtensionVersion,
    featureVersionMap[extensionFeatureName]
  );
  const checkFeatureFlag = options?.checkFeatureFlag ?? true;
  if (checkFeatureFlag) {
    return featureFlagEnabled && hasRelevantVersion;
  }
  return hasRelevantVersion;
}

export default useBrowserExtensionFeatureEnabled;
