import { ApolloError } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Playlist, usePlaylistNavigationInfoLazyQuery } from "..";

interface UsePlaylist {
  next: undefined | (() => void);
  prev: undefined | (() => void);
  loading: boolean;
  error: ApolloError | undefined;
  currentIdx: number;
  playlist: Pick<Playlist, "id" | "title" | "clipCount">;
}

export function usePlaylist(): UsePlaylist | null {
  const navigate = useNavigate();

  const { callId, clipId } = useParams() as Record<string, string>;
  const [searchParams] = useSearchParams();

  const playlistId = searchParams.get("playlistId");

  const [fetchPlaylist, { data, loading, error, previousData }] =
    usePlaylistNavigationInfoLazyQuery({
      onError: Sentry.captureException,
    });

  useEffect(() => {
    if (callId && clipId && playlistId) {
      fetchPlaylist({ variables: { callId, clipId, playlistId } });
    }
  }, [callId, clipId, playlistId]);

  const { playlist, nextClipUrl, previousClipUrl, currentClipIdx } =
    data?.playlistNavigationInfo ?? previousData?.playlistNavigationInfo ?? {};

  if (!playlist) {
    return null;
  }

  return {
    next: nextClipUrl ? () => navigate(nextClipUrl) : undefined,
    prev: previousClipUrl ? () => navigate(previousClipUrl) : undefined,
    loading,
    error,
    currentIdx: currentClipIdx ?? 0,
    playlist,
  };
}
