import { useMemo } from "react";

import { CallNoteFragment, CallQuestionFragment } from "../../../graphql";
import { notesListNoteTypes } from "../utils";

type Note = Pick<
  CallNoteFragment,
  "id" | "type" | "time" | "highlightStartTime"
>;

const useCurrentNote = (
  generalNotes: Note[],
  questions: Pick<CallQuestionFragment, "questionNotes">[],
  time: number
): string => {
  const allNotes = generalNotes.concat(
    questions.reduce((notes, q) => notes.concat(q.questionNotes), [] as Note[])
  );

  const notes = allNotes
    .filter((n) => notesListNoteTypes.includes(n.type))
    .sort((a, b) => (a.time > b.time ? -1 : a.time < b.time ? 1 : 0));

  return useMemo(() => {
    const adjustedTime = Math.floor(time);

    for (let i = 0; i < notes.length; i++) {
      const note = notes[i];
      const matchesTime =
        note.time <= adjustedTime ||
        (note.highlightStartTime && note.highlightStartTime <= adjustedTime);

      if (matchesTime) {
        return note.id as string;
      }
    }

    return "";
  }, [notes, Math.floor(time)]);
};

export default useCurrentNote;
