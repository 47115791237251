import { StyleFunctionProps, theme as origTheme } from "@chakra-ui/react";

export default {
  variants: {
    solid: (props: StyleFunctionProps) => {
      if (props.status !== "warning") {
        return origTheme?.components?.Alert?.variants?.subtle(props);
      }
      return {
        container: {
          bg: "yellow.100",
          color: "gray.900",
          "--alert-fg": "var(--chakra-colors-yellow-700)",
        },
      };
    },
  },
};
