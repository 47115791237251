import { useEffect, useRef } from "react";

/**
 * Runs the callback after a delay, and resets the delay if
 * the event is triggered again before the rest delay resolves.
 */
function useDebouncedCallback(callback: () => void, delay: number): () => void {
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  function cleanUp(): void {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }

  // make sure our timeout gets cleared if
  // our consuming component gets unmounted
  useEffect(() => cleanUp, []);

  return function debouncedCallback() {
    // Clear debounce timer
    cleanUp();

    // Start delaying again
    timeout.current = setTimeout(() => {
      callback();
    }, delay);
  };
}

export default useDebouncedCallback;
