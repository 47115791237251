import {
  Button,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

export interface ModalProps {
  completeButtonText: string;
  headerText: string;
  isOpen: boolean;
  isLoading?: boolean;
  isDestructive?: boolean;
  children: React.ReactNode;
  onClose: () => void;
  onComplete: () => void;
  cancelButtonText?: string;
}

const Modal: React.FC<ModalProps> = ({
  children,
  headerText,
  completeButtonText,
  isOpen,
  isLoading,
  isDestructive,
  onClose,
  onComplete,
  cancelButtonText = "Cancel",
}) => (
  <ChakraModal isOpen={isOpen} closeOnOverlayClick={false} onClose={onClose}>
    <ModalOverlay>
      <ModalContent borderRadius="24px">
        <ModalHeader
          color="gray.900"
          fontWeight="600"
          fontSize="2xl"
          lineHeight="8"
          // maxWidth="90%"
          pl="6"
          pr="72px"
          pt="6"
          pb="4"
        >
          {headerText}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody px="10" pt="8" pb="14">
          {children}
        </ModalBody>
        <ModalFooter
          borderTop="1px"
          borderTopColor="gray.100"
          justifyContent="space-between"
          px={6}
        >
          <Button variant="ghost" fontWeight="500" onClick={onClose}>
            {cancelButtonText}
          </Button>
          <Button
            fontWeight="500"
            textTransform="unset"
            variant={isDestructive ? "danger" : undefined}
            borderRadius="8px"
            isLoading={isLoading}
            onClick={() => {
              onComplete();
              onClose();
            }}
          >
            {completeButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </ModalOverlay>
  </ChakraModal>
);

export default Modal;
