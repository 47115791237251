const generalEmailGuidelines = `# General Email Guidelines

## Using the Transcript
* Use the transcript to personalize the email. Use the candidate's name, the interviewer's name, and the position name if available.
* Do not fabricate any details that are not clearly present in the Transcript. This email should be based on the actual written transcript, not best guesses. It would be confusing to the candidate to receive an email that doesn't match the interview they just had.
* Do not reference timestamps from the transcript in the email. The candidate will not have the transcript and it will be confusing to them.
* Write the email from the first person perspective of the provider user. The provided user will be the email sender. Do not guess at the user's own job title.  If the current user was in the interview transcript, write it as if the current user was one of the interviewers in the transcript. Otherwise, do not write the email as if the current user was in the interview.
* Do not leave placeholders, always fill in the names from the transcript. "[Your Name]" is not acceptable unless the currente user's name is not specified.

## Email Length
* Aim for a total of 4 sentences. The goal is to be short, concise, and clear. Less is more.
* Avoid long paragraphs and long sentences.
* While communication should be professional, it should not be stuffy or overly formal. It should be warm and friendly, but not overly casual.
* Again, do not write the whole email with a single monolithic paragraph, split it into two paragraphs.
* Sentences should be concise and clear. Aboid verbosity and wordiness.
* Each paragraph should be about 2 short sentences long by default.
* Again, write readable, clear sentences, with few commas.

## Email Default Tone
* Write the email in a professional but plain spoken way. Avoid buzzwords, jargon, and corporate speak.
* Write in a warm and friendly tone, that reads naturally.

## Sentence Style
* Write complete sentences
* Balance readability of short sentences and flow of medium length sentences
* Focus on clear readability in a writing style designed to be clear, accurate, consumable
* Avoid sentences with several clauses and commas. Clear, separate, complete sentences are easier to understand.
* Write in a natural flowing way that is pleasant to read.`;

const advanceEmail = `Write an email to inform the candidate the company is proceeding with their candidacy to the next step in the interview process. Ideally in a warm and excited tone.

# Email Contents

## Paragraph 1
* Share the good news. This should be simple, clear, and direct.
* Offer specifics based on the actual transcript as to why the company was impressed and/or excited

## Paragraph 2
* Mention next steps if referenced in the transcript, and schedule next steps. Do not fabricate specific timelines or availability of not mentioned in the transcript. It is fine to ask for availability if it was not mentioned in the transcript.
* If follow up items were mentioned in the transcript, they can be mentioned if appropriate for this email.

${generalEmailGuidelines}`;

const rejectEmail = `Write an email to inform a candidate the company is not proceeding with their candidacy. Ideally in a warm and empathetic tone.

# Email Contents

## Paragraph 1
* Informing the candiate of the decision. This should be simple, clear, and direct.

## Paragraph 2
* Inviting the candidate to re-apply for future roles that might be a fit
* Letting the candidate know that they'll keep the candidate in mind for future roles that might be a fit

Due to company legal policy, we must NEVER provide reasons for the rejection. At no point should any information be used from the transcript except for these specific exceptions:
* name of candidate
* name of interviewer
* name of position

${generalEmailGuidelines}`;

const keepWarmEmail = `Write an email to keep a candidate warm while the interview decision process continues. For context, they have already interviewed at least once, and at least some of the next steps and decisions after that interview have not yet been decided. The ideal outcome is that the candidate feels that they are still top of mind and that the company is still interested in them. However, the interviewer is not able to reveal any new information or decisions in this email. Avoid any negativity or guessing what that the candidate might be feeling.

# Email Contents

## Paragraph 1
* Personal greeting, and mention that the candidate is still being considered, and a decision is in progress.
* Reference the transcript for any personalizable details to show a bit of rapport. Focus less on particular achievements or fit, and more on anything position to connect on in a warm and friendly way.  If the candidate mentioned their own timeline for interviewing or other companies they are interviewing with, acknowledge that.

## Paragraph 2
* Let the user know that the the email author will reach out to them as soon as there is news.

${generalEmailGuidelines}`;

const reconnectEmail = `Write an email to reconnect with past candidate by referencing past conversation to personalize the outreach.

# Email Contents

## Paragraph 1
* Personal greeting, and mention the intention to reconnect now. This should be simple, clear, and direct.
* Reference the transcript for specifics the candidate was excited about or demonstrated interest in. If candidate timing was mentioned, reference that.

## Paragraph 2
* Asking for availability to reconnect

${generalEmailGuidelines}`;

export type EmailType = "advance" | "reject" | "reconnect" | "keepWarm";

export const emailPrompt: { [key in EmailType]: string } = {
  advance: advanceEmail,
  reject: rejectEmail,
  reconnect: reconnectEmail,
  keepWarm: keepWarmEmail,
};
