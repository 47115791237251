import {
  Flex,
  StyleProps,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useEffect } from "react";

import { Alert, LoadingIndicator } from "../../../../../components";
import { useSendGAEvent } from "../../../../../utils/googleAnalytics";
import {
  CallBetaFragment,
  Candidate,
  InterviewerScorecardFragment,
  ScorecardItem,
  useViewCallScorecardMutation,
} from "../../../../graphql";
import useMouseHasMoved from "../../../../hooks/useMouseHasMoved";
import CurrentUserScores from "./CurrentUserScores";
import OtherUsersScores from "./OtherUsersScores";
import ViewCandidateScoresButton from "./ViewCandidateScoresButton";

type ScoresTabScorecard = Pick<
  InterviewerScorecardFragment,
  | "id"
  | "overallScoringEnabled"
  | "overallNotes"
  | "overallScore"
  | "updatedAt"
  | "interviewer"
> & {
  competencies:
    | Pick<
        ScorecardItem,
        "id" | "itemText" | "score" | "notes" | "updatedAt"
      >[];
};

export type ScoresTabProps = {
  currentUserScorecard?: ScoresTabScorecard;
  otherUsersScorecards?: ScoresTabScorecard[];
  showViewCandidate?: boolean;
  loading?: boolean;
  error?: string;
  call: Pick<CallBetaFragment, "id" | "candidate" | "position" | "startTime">;
  candidate?: Pick<Candidate, "id">;
} & StyleProps;

/**
 * Sidebar tab for scorecards.
 *
 * If `currentUserScorecard` and `otherUsersScorecards` have content,
 * this component will render tabs to switch between them. Otherwise,
 * only the one with content will be shown.
 */
const ScoresTab: React.FC<ScoresTabProps> = ({
  currentUserScorecard,
  otherUsersScorecards = [],
  showViewCandidate,
  call,
  candidate,
  loading,
  error,
  ...styles
}) => {
  const sendGaEvent = useSendGAEvent();
  const mouseHasMoved = useMouseHasMoved();
  const [viewScorecard] = useViewCallScorecardMutation();
  useEffect(() => {
    if (currentUserScorecard && call && mouseHasMoved) {
      viewScorecard({
        variables: { callId: call.id, scorecardId: currentUserScorecard.id },
      });
    }
  }, [mouseHasMoved]);

  useEffect(() => {
    if (error) {
      sendGaEvent("error", "scoring", "Interview sidebar", error);
    }
  }, [error]);

  if (loading) {
    return <LoadingIndicator h="50%" />;
  }

  if (error) {
    return <Alert status="error" description={error} mt="4" />;
  }

  const showTabs = currentUserScorecard && otherUsersScorecards.length > 0;

  return (
    <Tabs px="4" isLazy defaultIndex={currentUserScorecard ? 0 : 1} {...styles}>
      <Flex
        gap="2"
        alignItems="center"
        borderColor="gray.200"
        borderBottomWidth="1px"
        display={showTabs ? undefined : "none"}
      >
        <TabList flex="1" border="none">
          <StyledTab>My scores</StyledTab>
          <StyledTab>Other scores</StyledTab>
        </TabList>
        {candidate && (
          <ViewCandidateScoresButton size="sm" candidateId={candidate.id} />
        )}
      </Flex>

      <TabPanels>
        <TabPanel>
          {currentUserScorecard && (
            <CurrentUserScores
              scorecard={currentUserScorecard}
              showViewCandidate={showTabs ? false : showViewCandidate}
              call={call}
              candidate={candidate}
            />
          )}
        </TabPanel>
        <TabPanel>
          <OtherUsersScores
            scorecards={otherUsersScorecards}
            showViewCandidate={!showTabs}
            call={call}
            candidate={candidate}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default ScoresTab;

const StyledTab: typeof Tab = (props) => (
  <Tab
    py="2"
    px="4"
    fontSize="sm"
    fontWeight="medium"
    color="gray.700"
    _hover={{ borderColor: "gray.200" }}
    _active={{ bg: "gray.50", borderColor: "blue.500" }}
    _selected={{
      fontWeight: "semibold",
      color: "blue.600",
      borderColor: "blue.600",
    }}
    {...props}
  />
);
