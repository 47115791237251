import {
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";

import { PageTitle } from "../../../components";
import { usePageTracker, useSendGAEvent } from "../../../utils/googleAnalytics";
import { AddCallGuideModal } from "../../components";
import { CallGuideListItemFragment } from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";
import { DefaultPageLayout } from "../../layouts";
import GuidesList from "./GuidesList";

const StyledTab: typeof Tab = (props) => <Tab pb="2.5" mb="0" {...props} />;

const GuidesPage: React.FC = () => {
  usePageTracker("call_guides");
  const currentUser = useCurrentUser();
  const sendGAEvent = useSendGAEvent();
  const theme = useTheme();
  const { pagePadding } = theme.sizes;
  const addCallGuideDisclosure = useDisclosure();
  const navigate = useNavigate();
  const location = useLocation();
  const showAddCallGuide = location.pathname === "/guides/new";
  const canAssignGuides =
    currentUser.organization.assignGuidesToInterviewersEnabled &&
    currentUser.userRole?.canManageHiringTeam;

  const pickTabIndex = (pathname: string): number => {
    if (pathname.includes("shared")) {
      return 1;
    }
    if (pathname.includes("assigned")) {
      return 2;
    }
    return 0;
  };

  const [activeTab, setActiveTab] = useState<number>(
    pickTabIndex(location.pathname)
  );

  const handleChangeTab = (index: number): void => {
    if (index === 0) {
      navigate(
        { pathname: "/guides", search: location.search },
        { replace: true }
      );
    } else if (index === 1) {
      navigate(
        { pathname: "/guides/shared", search: location.search },
        { replace: true }
      );
    } else {
      navigate(
        { pathname: "/guides/assigned", search: location.search },
        { replace: true }
      );
    }
    setActiveTab(index);
  };

  const handleClose = (): void => {
    navigate("/guides", { replace: true });
    addCallGuideDisclosure.onClose();
  };

  const handleSaveCallGuide = (callGuide: CallGuideListItemFragment): void => {
    sendGAEvent("create", "call_guides");
    navigate(`/guide/${callGuide.id}`, { replace: true });
  };

  useEffect(() => {
    if (showAddCallGuide && !addCallGuideDisclosure.isOpen) {
      addCallGuideDisclosure.onOpen();
    }
  }, [location]);
  return (
    <>
      <AddCallGuideModal
        isOpen={addCallGuideDisclosure.isOpen}
        onClose={handleClose}
        onSave={handleSaveCallGuide}
      />
      <DefaultPageLayout maxW="min(100%, 1400px)" p={pagePadding}>
        <Flex alignItems="center" justifyContent="space-between">
          <PageTitle mb="20px" titleText="Interview Guides" />
          <Button
            bg="blue.500"
            ml={4}
            size="sm"
            leftIcon={<MdAdd />}
            onClick={() => {
              navigate("/guides/new");
            }}
          >
            New guide
          </Button>
        </Flex>
        <Tabs isLazy index={activeTab} onChange={handleChangeTab}>
          <TabList borderBottom="1px solid" borderColor="gray.200">
            <StyledTab>My Guides</StyledTab>
            <StyledTab>Shared Guides</StyledTab>
            <StyledTab hidden={!canAssignGuides}>Assigned Guides</StyledTab>
          </TabList>

          <TabPanels>
            <TabPanel data-testid="my-call-guides-panel">
              <GuidesList category="my" active={activeTab === 0} />
            </TabPanel>
            <TabPanel data-testid="shared-call-guides-panel">
              <GuidesList category="shared" active={activeTab === 1} />
            </TabPanel>
            <TabPanel data-testid="assigned-call-guides-panel">
              <GuidesList category="assigned" active={activeTab === 2} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </DefaultPageLayout>
    </>
  );
};

export default GuidesPage;
