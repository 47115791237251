import { ApolloError } from "@apollo/client";

import {
  CallSpeakerOption,
  useCallSpeakerOptionsQuery,
} from "../../../graphql";

type CallSpeakerOptionsProps = {
  callId: string;
};

type CallSpeakerOptionsReturn = {
  speakerOptions?: Pick<CallSpeakerOption, "id" | "label">[];
  loading?: boolean;
  error?: ApolloError;
};

const useCallSpeakerOptions = ({
  callId,
}: CallSpeakerOptionsProps): CallSpeakerOptionsReturn => {
  const { data, loading, error } = useCallSpeakerOptionsQuery({
    variables: { id: callId },
  });

  return {
    speakerOptions: data?.call?.speakerOptions ?? [],
    loading,
    error,
  };
};

export default useCallSpeakerOptions;
