import LogRocket from "logrocket";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { LoadingIndicator } from "../../../components";
import { useTrainingProgramManagementListItemsQuery } from "../../graphql";
import DefaultLayout from "../../layouts/DefaultLayout";
import ErrorPage from "./ErrorPage";
import ManageTrainingRedirectPageView from "./ManageTrainingRedirectPageView";
import { ManageTrainingProgramSidebarItem } from "./ManageTrainingSidebar";
import { useTrainingApi } from "./useTrainingApi";
import { manageTrainingRoute } from "./utils";

/**
 * Landing page for the Training > Manage header navgitation. Redirects
 * to a training program if one exists.
 */
const ManageTrainingRedirectPage: React.FC = () => {
  useEffect(() => LogRocket.track("training-manage-page"), []);

  const { data, loading, error } = useTrainingProgramManagementListItemsQuery();

  const trainingPrograms: ManageTrainingProgramSidebarItem[] =
    data?.trainingProgramManagementListItems || [];

  useNavigateToFirstProgram(trainingPrograms);
  const trainingApi = useTrainingApi();

  if (loading || !data) {
    return (
      <DefaultLayout>
        <LoadingIndicator />
      </DefaultLayout>
    );
  }

  if (error) {
    return <ErrorPage title="Error loading training program" error={error} />;
  }

  return (
    <ManageTrainingRedirectPageView
      trainingPrograms={trainingPrograms}
      onCreateProgram={trainingApi.program.create}
      traineeCount={data.traineeCount}
    />
  );
};

// This page routes to a specific training program if possible
const useNavigateToFirstProgram = (
  trainingPrograms: { id: string }[]
): void => {
  const navigate = useNavigate();
  useEffect(() => {
    if (trainingPrograms.length > 0) {
      navigate(manageTrainingRoute(trainingPrograms[0].id), {
        replace: true,
      });
    }
  }, [trainingPrograms]);
};

export default ManageTrainingRedirectPage;
