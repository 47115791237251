import { Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import { useToast } from "../../../../components";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import {
  CandidateFragment,
  useRedactCandidateMutation,
} from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";
import Modal from "../../Modal/Modal";

interface DeleteCandidateModalProps {
  candidate: CandidateFragment;
  isOpen: boolean;
  onClose: () => void;
}

const DeleteCandidateModal: React.FC<DeleteCandidateModalProps> = ({
  candidate,
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate();
  const toast = useToast();
  const currentUser = useCurrentUser();
  const sendGAEvent = useSendGAEvent();

  const [deleteCandidate, { loading }] = useRedactCandidateMutation({
    onCompleted: () => {
      navigate("/", { replace: true });
    },
    onError: () => {
      toast({
        title: "Error",
        description:
          "This candidate could not be deleted. Please try again later.",
        status: "error",
      });
    },
  });

  const canDeleteCandidates = currentUser.userRole?.canDeleteCandidates;
  if (!canDeleteCandidates) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      isLoading={loading}
      isDestructive
      headerText="Are you sure you want to delete this candidate?"
      completeButtonText="Delete candidate"
      data-testid="candidate-delete-confirm-button"
      onComplete={() => {
        sendGAEvent("delete", "candidates");
        deleteCandidate({
          variables: { id: candidate.id },
        });
      }}
      onClose={onClose}
    >
      <Text color="gray.800" fontSize="sm">
        This will permanently delete their recordings and interview data. This
        cannot be undone.
      </Text>
    </Modal>
  );
};

DeleteCandidateModal.displayName = "DeleteCandidateModal";

export default DeleteCandidateModal;
