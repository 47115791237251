export type InterviewViewAreaNames = "header" | "main" | "sidebar" | "hidden";

export type Application = "app" | "extension";

export type InterviewViewSectionName =
  | "header"
  | "video"
  | "media-player"
  | "transcript"
  | "notes"
  | "trackers"
  | "clips"
  | "assessment"
  | "controls";

export type InterviewViewSection = {
  name: InterviewViewSectionName;
  area:
    | InterviewViewAreaNames
    | {
        base?: InterviewViewAreaNames;
        sm?: InterviewViewAreaNames;
        md?: InterviewViewAreaNames;
        lg?: InterviewViewAreaNames;
        xl?: InterviewViewAreaNames;
        "2xl"?: InterviewViewAreaNames;
      };
  order: number;
};

export enum VideoDisplayMode {
  NO_RECORDING = "no-recording",
  PROCESSING = "processing",
  IN_PROGRESS = "in-progress",
  VIDEO = "video",
  AUDIO = "audio",
}
