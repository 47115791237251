import {
  FormControl,
  FormHelperText,
  Select,
  SelectProps,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";

import { PlanUserRole, UserRole } from "../../graphql";

// component is shared for both UserRole and PlanUserRole select components
type SelectUserRole =
  | Pick<UserRole, "id" | "name" | "formattedPermissions" | "formattedName">
  | Pick<PlanUserRole, "id" | "name" | "formattedPermissions" | "formattedName">
  | {
      id: string;
      name: string;
      formattedPermissions: string;
      formattedName: string;
    }; // null option

type RoleSelectProps = {
  userRole?: SelectUserRole;
  userRoles?: SelectUserRole[];
  formId: string;
  showDescription?: boolean;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  defaultValue: string;
  canModify?: boolean | null;
  selectProps?: SelectProps & { "data-testid"?: string };
};

const RoleSelect: React.FC<RoleSelectProps> = ({
  userRole,
  formId,
  showDescription,
  onChange,
  defaultValue,
  userRoles,
  canModify,
  selectProps,
}) => (
  <FormControl id={formId}>
    <Tooltip
      label={
        !showDescription
          ? userRole?.formattedPermissions ?? undefined
          : undefined
      }
      openDelay={250}
    >
      <Select
        size="sm"
        width="max-content"
        onChange={onChange}
        value={userRole?.id}
        defaultValue={defaultValue}
        disabled={!canModify}
        {...selectProps}
      >
        {userRoles?.map((r) => (
          <option key={r.id} value={r.id}>
            {r.formattedName}
          </option>
        ))}
      </Select>
    </Tooltip>
    {showDescription && (
      <FormHelperText>{userRole?.formattedPermissions}</FormHelperText>
    )}
  </FormControl>
);

export default RoleSelect;
