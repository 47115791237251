import { Box, Flex, Select, Stack } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { useAlgoliaAllCalls } from "../../hooks/useAlgoliaAllCalls";
import {
  endDateInSeconds,
  MS_PER_DAY,
  reduceToDays,
  startDateInSeconds,
} from "../../utils/datetime";
import { useSendGAEvent } from "../../utils/googleAnalytics";
import { formatKeywordList } from "../../utils/string";
import { Conditional } from "../../utils/types";
import { LoadingIndicator } from "..";
import BarChart from "../Chart/BarChart";
import { prepareTimeSeriesBar } from "../Chart/prepareData";
import RadialBarChart from "../Chart/RadialBarChart";
import { RadialBarDataPoint } from "../Chart/types";
import {
  defaultKeywordTagLabelProps,
  defaultKeywordTagProps,
  KeywordPill,
} from "../KeywordPill";
import { SelectDateRangeState } from "../SelectDateRange/SelectDateRange";
import { filterHits } from "./filterHits";
import KeywordBox from "./KeywordBox";
import KeywordResults from "./KeywordResults";
import { DataCategories, FilteredHits, KeywordHit } from "./types";

const buildQuery = (values: string[]): string => {
  return values.reduce((acc: string, value: string, idx: number) => {
    const tempValue = `"${value}"`;
    if (idx === 0) {
      return acc.concat(tempValue);
    }
    return acc.concat(" ", tempValue);
  }, "");
};

const callRatio = (selected: number, all: number): number => {
  return Math.round((selected / all) * 100);
};

const barGraphDataCategoryMap = {
  [DataCategories.ALL]: ["candidate", "interviewer"],
  [DataCategories.CANDIDATE]: ["candidate"],
  [DataCategories.INTERVIEWER]: ["interviewer"],
};

interface TopicsTabProps {
  searchApiKey: string | undefined;
  dateRangeState: SelectDateRangeState;
  numSignificantCalls: number;
  dateLoading: boolean;
}

const TopicsTab: React.FC<TopicsTabProps> = ({
  searchApiKey,
  dateRangeState,
  numSignificantCalls,
  dateLoading,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  // Algolia config
  const startTime = startDateInSeconds(dateRangeState.start);
  const endTime = endDateInSeconds(dateRangeState.end);

  const searchParameters = {
    hitsPerPage: 200,
    highlightPreTag: "<mark>",
    highlightPostTag: "</mark>",
    attributesToSnippet: ["transcript_segments.text:40"],
    snippetEllipsisText: "…",
    restrictSearchableAttributes: ["transcript_segments.text"],
    numericFilters: [
      `call_timestamp_seconds >= ${startTime}`,
      `call_timestamp_seconds < ${endTime}`,
    ],
  };
  // Algolia search
  const { groupedHits, loadingCalls, requestSearch } = useAlgoliaAllCalls(
    undefined,
    searchApiKey,
    undefined,
    {
      ...searchParameters,
    }
  );
  const [conditional, setConditional] = useState<Conditional>(Conditional.AND);
  const handleSearch = (values: string[]): void => {
    const startTime = startDateInSeconds(dateRangeState.start);
    const endTime = endDateInSeconds(dateRangeState.end);

    requestSearch({
      query: buildQuery(values),
      numericFilters: [
        `call_timestamp_seconds >= ${startTime}`,
        `call_timestamp_seconds < ${endTime}`,
      ],
      optionalWords: conditional === Conditional.OR ? buildQuery(values) : "",
    });
  };

  // Data
  const [keywordList, setKeywordList] = useState<string[]>([]);
  const [dataCategory, setDataCategory] = useState<DataCategories>(
    DataCategories.ALL
  );
  const filteredHits = useMemo<FilteredHits>(() => {
    return filterHits(groupedHits, keywordList, conditional);
  }, [groupedHits]);

  const displayedData = useMemo<KeywordHit[]>(() => {
    if (dataCategory === DataCategories.CANDIDATE) {
      return filteredHits.candidateHits;
    }
    if (dataCategory === DataCategories.INTERVIEWER) {
      return filteredHits.interviewerHits;
    }
    return filteredHits.groupedHits;
  }, [filteredHits, dataCategory]);

  const graphData = useMemo(() => {
    if (groupedHits.length === 0) {
      setLoading(false);
    }
    const dateRangeEnd = new Date(dateRangeState.end.getTime() + MS_PER_DAY);
    return prepareTimeSeriesBar(
      filteredHits,
      reduceToDays(dateRangeState.start),
      reduceToDays(dateRangeEnd)
    );
  }, [groupedHits]);

  const callRatioCount = useMemo((): number => {
    if (dateLoading === false) {
      setLoading(false);
      return Math.min(
        100,
        callRatio(displayedData.length, numSignificantCalls)
      );
    }
    return 0;
  }, [displayedData, numSignificantCalls, dateLoading]);
  const radialBarData = useMemo((): RadialBarDataPoint[] => {
    return [
      { name: "all", count: 100, fill: "white" },
      {
        name: "keyword",
        count: callRatioCount,
        fill: "#02b899",
      },
    ];
  }, [callRatioCount]);

  // UI BASED STATE CHANGES

  const navigate = useNavigate();
  const location = useLocation();

  // Process keyword
  const onEnter = (tempValue: string): void => {
    const value = tempValue.replace(/"/g, "");
    // No duplicates or Empty Selections
    if (keywordList.includes(value)) {
      return;
    }
    if (value.length < 1) {
      return;
    }
    searchParams.append("keyword", value);
    navigate(`/insights/topics?${searchParams.toString()}`);
    setKeywordList((prevKeywordList) => {
      return [...prevKeywordList, value];
    });
  };
  // Remove keyword
  const onClose = (value: string): void => {
    const updatedKeywordsList = keywordList.filter(
      (keyword: string) => keyword !== value
    );
    const urlParams = new URLSearchParams(
      updatedKeywordsList.map((keyword) => ["keyword", keyword])
    );
    navigate(`/insights/topics?${urlParams.toString()}`);
    setKeywordList(updatedKeywordsList);
  };

  const handleConditional = (
    e: React.SyntheticEvent<HTMLSelectElement, Event>
  ): void => {
    searchParams.delete("conditional");
    searchParams.append("conditional", e.currentTarget.value);
    navigate(`/insights/topics?${searchParams.toString()}`);
    setConditional(
      Conditional[e.currentTarget.value as keyof typeof Conditional]
    );
  };

  const handleFilter = (
    e: React.SyntheticEvent<HTMLSelectElement, Event>
  ): void => {
    searchParams.delete("filter");
    searchParams.append("filter", e.currentTarget.value);
    navigate(`/insights/topics?${searchParams.toString()}`);
    setDataCategory(
      DataCategories[e.currentTarget.value as keyof typeof DataCategories]
    );
  };

  // INITIAL - Set initial state on load/reload
  useEffect(() => {
    const filter = searchParams.get("filter");
    if (filter) {
      setDataCategory(DataCategories[filter as keyof typeof DataCategories]);
    }
    const currentConditional = searchParams.get("conditional");
    if (currentConditional) {
      setConditional(
        Conditional[currentConditional as keyof typeof Conditional]
      );
    }
    const keywords = searchParams.getAll("keyword");
    setKeywordList(keywords);
  }, []);

  const sendGAEvent = useSendGAEvent();
  // Update keyword list state
  useEffect(() => {
    if (keywordList.length > 0 && location.pathname === "/insights/topics") {
      setLoading(true);
      handleSearch(keywordList);
      sendGAEvent("search", "keyword_search", "keywords", keywordList.join());
    }
  }, [keywordList, dateRangeState, conditional]);

  return (
    <Box>
      <Flex p="4" direction="row" flexWrap="wrap" justifyContent="start">
        <Box>
          <Box borderWidth="1px" p="4">
            <Box pb="2">Enter Keywords</Box>
            <KeywordBox
              onEnter={onEnter}
              isDisabled={loading || searchApiKey === undefined}
              placeholder={
                keywordList.length < 1
                  ? "Diversity, teamwork, communication, etc."
                  : "Add another keyword"
              }
            />
            {loading ? <LoadingIndicator pt="4" /> : ""}
          </Box>
          <Stack p="4">
            <Box>Selected Keywords</Box>
            {keywordList.map((keyword) => (
              <Box key={keyword}>
                <KeywordPill
                  text={keyword}
                  closeButton
                  onClose={onClose}
                  tagProps={defaultKeywordTagProps}
                  tagLabelProps={defaultKeywordTagLabelProps}
                />
              </Box>
            ))}
          </Stack>
          {keywordList.length < 1 ? (
            ""
          ) : (
            <>
              <Box p="4">
                <Box pb="2">Includes</Box>
                <Select
                  name="conditional"
                  id="conditional"
                  value={conditional}
                  onChange={handleConditional}
                  maxWidth="300"
                >
                  <option value={Conditional.AND}>All Selected Keywords</option>
                  <option value={Conditional.OR}>Any Selected Keywords</option>
                </Select>
              </Box>
              <Box p="4">
                <Box pb="2">Filter Results</Box>
                <Select
                  name="speaker"
                  id="speaker"
                  value={dataCategory}
                  onChange={handleFilter}
                >
                  <option value={DataCategories.ALL}>All</option>
                  <option value={DataCategories.CANDIDATE}>Candidate</option>
                  <option value={DataCategories.INTERVIEWER}>
                    Interviewer
                  </option>
                </Select>
              </Box>
            </>
          )}
        </Box>
        {keywordList.length > 0 && displayedData.length < 1 && !loadingCalls ? (
          <Box pt="4" pl="8" fontStyle="italic">
            No results for {formatKeywordList(keywordList, conditional)}
          </Box>
        ) : (
          ""
        )}
        {keywordList.length < 1 && searchApiKey ? (
          <Box pt="4" pl="8">
            Search keywords to see trends and insights.
          </Box>
        ) : (
          ""
        )}
        {!searchApiKey ? (
          <Box pt="4" pl="8" fontStyle="italic">
            Keyword search is currently unavailable.
          </Box>
        ) : (
          ""
        )}
        {loading || keywordList.length < 1 || displayedData.length < 1 ? (
          ""
        ) : (
          <Stack pl="10" pr="5" pt="2" mb="4" align="center">
            <RadialBarChart data={radialBarData} />
            <Box textAlign="center" maxWidth={200}>
              {displayedData.length} of{" "}
              {numSignificantCalls > displayedData.length
                ? numSignificantCalls
                : displayedData.length}{" "}
              interviews contain {formatKeywordList(keywordList, conditional)}
            </Box>
          </Stack>
        )}
        {loading || keywordList.length < 1 || displayedData.length < 1 ? (
          ""
        ) : (
          <BarChart
            barChartProps={{ data: graphData }}
            categories={barGraphDataCategoryMap[dataCategory]}
            yAxisProps={{
              domain: [0, "dataMax + 2"],
            }}
            xAxisProps={{ padding: { left: 10, right: 10 } }}
          />
        )}
      </Flex>
      {loading || keywordList.length < 1 || displayedData.length < 1 ? (
        ""
      ) : (
        <Box pl="8" pr="8">
          <KeywordResults hits={displayedData} />
        </Box>
      )}
    </Box>
  );
};

export default TopicsTab;
