import { Box, Center } from "@chakra-ui/react";
import React from "react";
import { SortingRule } from "react-table";

import {
  RouterLink,
  SortableTable,
  SortableTableColumn,
} from "../../../components";
import useCurrentBreakpoint from "../../../hooks/useCurrentBreakpoint";
import { formatRelativeDate } from "../../../utils/datetime";
import { PageOptions } from "../../../utils/types";
import { CallGuideListItemFragment } from "../../graphql";

const largeScreenBreakpoints = ["lg", "xl", "2xl"];
const smallScreenBreakpoints = ["base", "sm"];
interface CallGuideListProps {
  callGuides: Array<CallGuideListItemFragment>;
  emptyState?: string;
  pageOptions?: PageOptions;
  loading?: boolean;
  sortBy: SortingRule<string>;
  showSharing?: boolean;
  showOwner?: boolean;
  showPosition?: boolean;
  showRemove?: boolean;
}

const CallGuideList: React.FC<CallGuideListProps> = ({
  callGuides,
  emptyState,
  pageOptions,
  loading,
  sortBy,
  showOwner = false,
  showPosition = false,
}) => {
  const currentBreakpoint = useCurrentBreakpoint();
  const shouldShowCompactView =
    smallScreenBreakpoints.includes(currentBreakpoint);

  if (callGuides.length < 1 && !loading) {
    return (
      <Center h="100px" p={5} bg="gray.50" borderRadius="base" fontSize="sm">
        {emptyState ?? "There are no results to show"}
      </Center>
    );
  }

  const columns: Array<SortableTableColumn<CallGuideListItemFragment>> = [
    {
      Header: "Guide Name",
      accessor: "name",
      Cell: ({
        row: { original: callGuide },
      }: {
        row: { original: CallGuideListItemFragment };
      }) => (
        <RouterLink to={`/guide/${callGuide.id}`}>
          {callGuide.name ?? "Untitled"}
        </RouterLink>
      ),
    },
    {
      Header: "Position",
      accessor: (guide) => guide.position?.displayTitle,
      id: "position.displayTitle",
      show: showPosition,
      Cell: ({
        row: { original: callGuide },
      }: {
        row: { original: CallGuideListItemFragment };
      }) => {
        if (!callGuide.position) {
          return <>Nope</>;
        }
        return (
          <RouterLink to={`/position/${callGuide.position?.id}`}>
            {callGuide.position?.displayTitle}
          </RouterLink>
        );
      },
    },
    {
      Header: "Owner",
      accessor: (guide) => guide.creator?.fullName,
      id: "creator.fullName",
      show: showOwner,
      showEmpty: false,
      Cell: ({
        row: { original: callGuide },
      }: {
        row: { original: CallGuideListItemFragment };
      }) => {
        if (callGuide.creator) {
          return <>{callGuide.creator.fullName}</>;
        }
        return null;
      },
    },
    {
      Header: "Last Updated",
      accessor: "updatedAt",
      Cell: ({
        row: { original: callGuide },
      }: {
        row: { original: CallGuideListItemFragment };
      }) => (
        <>
          {(shouldShowCompactView ? "Last updated " : "") +
            formatRelativeDate(callGuide.updatedAt)}
        </>
      ),
    },
  ];

  if (callGuides.length < 1 && !loading) {
    return (
      <Box bgColor="gray.50" mt={4} borderRadius={4}>
        <Center fontSize="14px" color="gray.600" p={10}>
          There are no search results to show here
        </Center>
      </Box>
    );
  }

  return (
    <SortableTable
      columns={columns}
      data={callGuides}
      manualPagination
      pageOptions={pageOptions}
      initialSort={sortBy}
      width="100%"
      loading={false}
      includeSpacerColumn={largeScreenBreakpoints.includes(currentBreakpoint)}
      shouldShowCompactView={shouldShowCompactView}
    />
  );
};

CallGuideList.displayName = "CallGuideList";
export default CallGuideList;
