import { Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { HiOutlineStar, HiStar } from "react-icons/hi2";

import { RouterLink } from "../../../components";
import { CandidateCall } from "../Candidate/types";

interface ScoringButtonProps {
  call: Pick<
    CandidateCall,
    | "id"
    | "needsCurrentUserScore"
    | "averageScore"
    | "hasPendingScores"
    | "myScore"
  >;
  shouldShowAverageScore?: boolean;
}

const ScoringButton: React.FC<ScoringButtonProps> = ({
  call,
  shouldShowAverageScore = true,
}) => {
  if (call?.needsCurrentUserScore) {
    return (
      <RouterLink
        to={call ? `/interview/${call.id}?tab=scores` : "#"}
        target="_blank"
        variant="outlineButton"
      >
        <Flex direction="row" alignItems="center">
          <Icon as={HiOutlineStar} color="blue.500" mr="1" boxSize="4" />
          Add score
        </Flex>
      </RouterLink>
    );
  }
  // If the 'averageScore' field is populated, this means that the user has hiring team privileges.
  if (call?.averageScore && shouldShowAverageScore) {
    const score = call?.hasPendingScores ? (
      "Pending"
    ) : (
      <Text as="span" fontWeight="500">
        {call?.averageScore?.toFixed(1)}
      </Text>
    );
    return (
      <Flex direction="row" alignItems="center">
        <Text color="gray.500" fontWeight="400" fontSize="sm" mr="1">
          Avg. score: {score}{" "}
        </Text>
        {!call?.hasPendingScores && <Icon as={HiStar} color="blue.500" />}
      </Flex>
    );
  }
  if (call?.myScore) {
    return (
      <Flex direction="row" alignItems="center">
        <Text color="gray.500" fontWeight="400" fontSize="sm" mr="1">
          My score:{" "}
          <Text as="span" fontWeight="500">
            {call?.myScore?.toFixed(1)}
          </Text>{" "}
        </Text>
        <Icon as={HiStar} color="blue.500" />
      </Flex>
    );
  }
  return null;
};

export default ScoringButton;
