import { useToast } from "@chakra-ui/react";
import React, { forwardRef, useEffect, useMemo, useState } from "react";
import ReactSelect from "react-select";

import useDebounce from "../../../hooks/useDebounce";
import useSelectTheme from "../../../hooks/useSelectTheme";
import { useInterviewersLazyQuery } from "../../graphql";

type UserSelectProps = {
  userIds: string[] | undefined;
  disabled?: boolean;
  onSelect: (options: string[]) => void;
  customStyles?: Record<string, any>;
  idsToExclude?: string[];
  placeholder?: string;
};

const UserSelect: React.ForwardRefRenderFunction<any, UserSelectProps> = (
  { userIds, onSelect, customStyles, placeholder, idsToExclude = [] },
  ref
) => {
  const toast = useToast();
  const [theme, styles] = useSelectTheme(customStyles);
  const [interviewersQuery, { data }] = useInterviewersLazyQuery({
    onError: () => {
      toast({
        title: "Error loading interviewers",
        status: "error",
        isClosable: true,
        position: "top",
      });
    },
  });
  const [query] = useState("");
  const debouncedQuery = useDebounce<string>(query, 200);
  useEffect(() => {
    interviewersQuery({
      variables: { query: debouncedQuery, signUpCompleted: false },
    });
  }, [debouncedQuery]);

  const interviewers = data?.currentUser?.organization.users;

  const sortedOptions = useMemo(() => {
    if (!interviewers?.length) {
      return [];
    }
    let options = [...interviewers]
      .sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""))
      .map((interviewer) => ({
        label: `${interviewer.fullName} - ${interviewer.email}`,
        value: interviewer.id,
      }));

    if (idsToExclude.length > 0) {
      options = options.filter(
        (interviewer) => !idsToExclude.includes(interviewer.value)
      );
    }
    return options;
  }, [interviewers, idsToExclude]);

  const formattedValue = useMemo(() => {
    if (!interviewers?.length) {
      return [];
    }
    return userIds
      ?.map((interviewerId) =>
        interviewers?.find((interviewer) => interviewer.id === interviewerId)
      )
      .map((interviewer) => ({
        label: interviewer?.fullName,
        value: interviewer?.id,
      }))
      .filter(
        (interviewer) =>
          interviewer.label !== undefined && interviewer.value !== undefined
      );
  }, [interviewers, userIds]);

  return (
    <ReactSelect
      ref={ref}
      theme={theme}
      styles={styles}
      value={formattedValue}
      isMulti
      options={sortedOptions}
      closeMenuOnSelect={false}
      placeholder={placeholder}
      onChange={(value) => onSelect(value.map((o) => o.value))}
    />
  );
};

export default forwardRef(UserSelect);
