import { Box, Button } from "@chakra-ui/react";
import React from "react";

import { LoadingIndicator } from "../../../components/LoadingIndicator";
import { CandidateAlertFeedListItemFragment } from "../../graphql";
import { CandidateAlert } from "./CandidateAlert";
import NoCandidateAlerts from "./NoCandidateAlerts";

export const CandidateAlertList: React.FC<{
  alerts: CandidateAlertFeedListItemFragment[];
  loading: boolean;
  showLoadMore: boolean;
  onLoadMore: () => void;
  handleAlertRead: (ids: string[]) => void;
}> = ({ alerts, loading, showLoadMore, onLoadMore, handleAlertRead }) => {
  if (alerts.length === 0 && !loading) {
    return <NoCandidateAlerts />;
  }
  return (
    <Box>
      {loading && <LoadingIndicator delay={0} py="2" />}
      {!loading && (
        <>
          {alerts.map((a, idx) => {
            return (
              <CandidateAlert
                key={a.id}
                isFirst={idx === 0}
                isLast={idx === alerts.length - 1}
                handleAlertRead={handleAlertRead}
                {...a}
              />
            );
          })}
          {showLoadMore && (
            <Button
              variant="ghost"
              colorScheme="gray"
              size="sm"
              fontWeight="medium"
              marginTop="4"
              marginLeft="1"
              onClick={onLoadMore}
            >
              Load more
            </Button>
          )}
        </>
      )}
    </Box>
  );
};
