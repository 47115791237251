import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Playlist } from "../../graphql";

type UseManagePlaylistsNavigation = {
  activePlaylistId?: string;
  goToPlaylist(playlistId: string): void;
  goToPlaylistManagementHome(): void;
};

export function useManagePlaylistsNavigation(
  playlists: Pick<Playlist, "id">[]
): UseManagePlaylistsNavigation {
  const navigate = useNavigate();
  const { playlistId: activePlaylistId } = useParams<{ playlistId?: string }>();

  const playlistIds = playlists?.map(({ id }) => id);

  // Navigate to first playlist if current playlist
  // is not in list of sidebar playlists
  useEffect(() => {
    const firstPlaylistId = playlistIds?.[0];

    if (firstPlaylistId && !activePlaylistId) {
      navigate(
        {
          pathname: `/playlist/${firstPlaylistId}`,
          search: window.location.search,
        },
        { replace: true }
      );
    }
  }, [playlistIds, activePlaylistId]);

  return {
    activePlaylistId,
    goToPlaylist(pid) {
      navigate({
        pathname: `/playlist/${pid}`,
        search: window.location.search,
      });
    },
    goToPlaylistManagementHome() {
      navigate(
        {
          pathname: "/playlists",
          search: window.location.search,
        },
        { replace: true }
      );
    },
  };
}
