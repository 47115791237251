import React, { SVGAttributes } from "react";

import { useTheme } from "..";

type IconProps = Pick<
  SVGAttributes<SVGSVGElement>,
  "width" | "height" | "style"
>;

export const AudioImageWide: React.FC<IconProps> = ({
  width = "682",
  height = "120",
  style,
}) => {
  const { colors } = useTheme();
  const barFillColor = style?.color ?? colors.blue[800];
  return (
    <svg
      width={style?.width ?? width}
      height={style?.height ?? height}
      style={{ ...style }}
      viewBox="0 0 266 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="682"
        height={style?.height ?? "120"}
        fill={style?.backgroundColor ?? colors.gray[800]}
      />
      <path
        opacity="0.5"
        d="M133.75 23C133.75 22.5858 133.414 22.25 133 22.25C132.586 22.25 132.25 22.5858 132.25 23L133.75 23ZM132.25 33C132.25 33.4142 132.586 33.75 133 33.75C133.414 33.75 133.75 33.4142 133.75 33L132.25 33ZM132.25 23L132.25 33L133.75 33L133.75 23L132.25 23Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M163.875 8C163.875 7.51675 163.483 7.125 163 7.125C162.517 7.125 162.125 7.51675 162.125 8H163.875ZM162.125 48C162.125 48.4832 162.517 48.875 163 48.875C163.483 48.875 163.875 48.4832 163.875 48H162.125ZM162.125 8V48H163.875V8H162.125Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M176 8C176 7.44772 175.552 7 175 7C174.448 7 174 7.44772 174 8H176ZM174 48C174 48.5523 174.448 49 175 49C175.552 49 176 48.5523 176 48H174ZM174 8V48H176V8H174Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M169.875 2C169.875 1.51675 169.483 1.125 169 1.125C168.517 1.125 168.125 1.51675 168.125 2H169.875ZM168.125 54C168.125 54.4832 168.517 54.875 169 54.875C169.483 54.875 169.875 54.4832 169.875 54H168.125ZM168.125 2V54H169.875V2H168.125Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M145.75 24C145.75 23.5858 145.414 23.25 145 23.25C144.586 23.25 144.25 23.5858 144.25 24H145.75ZM144.25 32C144.25 32.4142 144.586 32.75 145 32.75C145.414 32.75 145.75 32.4142 145.75 32H144.25ZM144.25 24V32H145.75V24H144.25Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M139.75 26C139.75 25.5858 139.414 25.25 139 25.25C138.586 25.25 138.25 25.5858 138.25 26H139.75ZM138.25 30C138.25 30.4142 138.586 30.75 139 30.75C139.414 30.75 139.75 30.4142 139.75 30H138.25ZM138.25 26V30H139.75V26H138.25Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M157.875 16C157.875 15.5168 157.483 15.125 157 15.125C156.517 15.125 156.125 15.5168 156.125 16H157.875ZM156.125 40C156.125 40.4832 156.517 40.875 157 40.875C157.483 40.875 157.875 40.4832 157.875 40H156.125ZM156.125 16V40H157.875V16H156.125Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M182 16C182 15.4477 181.552 15 181 15C180.448 15 180 15.4477 180 16H182ZM180 40C180 40.5523 180.448 41 181 41C181.552 41 182 40.5523 182 40H180ZM180 16V40H182V16H180Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M151.75 20C151.75 19.5858 151.414 19.25 151 19.25C150.586 19.25 150.25 19.5858 150.25 20H151.75ZM150.25 36C150.25 36.4142 150.586 36.75 151 36.75C151.414 36.75 151.75 36.4142 151.75 36H150.25ZM150.25 20V36H151.75V20H150.25Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M188 20C188 19.4477 187.552 19 187 19C186.448 19 186 19.4477 186 20H188ZM186 36C186 36.5523 186.448 37 187 37C187.552 37 188 36.5523 188 36H186ZM186 20V36H188V20H186Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M200 26C200 25.4477 199.552 25 199 25C198.448 25 198 25.4477 198 26H200ZM198 30C198 30.5523 198.448 31 199 31C199.552 31 200 30.5523 200 30H198ZM198 26V30H200V26H198Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M194 24C194 23.4477 193.552 23 193 23C192.448 23 192 23.4477 192 24H194ZM192 32C192 32.5523 192.448 33 193 33C193.552 33 194 32.5523 194 32H192ZM192 24V32H194V24H192Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M62 23C62 22.4477 61.5523 22 61 22C60.4477 22 60 22.4477 60 23L62 23ZM60 33C60 33.5523 60.4477 34 61 34C61.5523 34 62 33.5523 62 33L60 33ZM60 23L60 33L62 33L62 23L60 23Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M92 8C92 7.44772 91.5523 7 91 7C90.4477 7 90 7.44772 90 8H92ZM90 48C90 48.5523 90.4477 49 91 49C91.5523 49 92 48.5523 92 48H90ZM90 8V48H92V8H90Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M103.875 8C103.875 7.51675 103.483 7.125 103 7.125C102.517 7.125 102.125 7.51675 102.125 8H103.875ZM102.125 48C102.125 48.4832 102.517 48.875 103 48.875C103.483 48.875 103.875 48.4832 103.875 48H102.125ZM102.125 8V48H103.875V8H102.125Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M97.875 2C97.875 1.51675 97.4832 1.125 97 1.125C96.5168 1.125 96.125 1.51675 96.125 2H97.875ZM96.125 54C96.125 54.4832 96.5168 54.875 97 54.875C97.4832 54.875 97.875 54.4832 97.875 54H96.125ZM96.125 2V54H97.875V2H96.125Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M74 24C74 23.4477 73.5523 23 73 23C72.4477 23 72 23.4477 72 24H74ZM72 32C72 32.5523 72.4477 33 73 33C73.5523 33 74 32.5523 74 32H72ZM72 24V32H74V24H72Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M68 26C68 25.4477 67.5523 25 67 25C66.4477 25 66 25.4477 66 26H68ZM66 30C66 30.5523 66.4477 31 67 31C67.5523 31 68 30.5523 68 30H66ZM66 26V30H68V26H66Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M86 16C86 15.4477 85.5523 15 85 15C84.4477 15 84 15.4477 84 16H86ZM84 40C84 40.5523 84.4477 41 85 41C85.5523 41 86 40.5523 86 40H84ZM84 16V40H86V16H84Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M109.875 16C109.875 15.5168 109.483 15.125 109 15.125C108.517 15.125 108.125 15.5168 108.125 16H109.875ZM108.125 40C108.125 40.4832 108.517 40.875 109 40.875C109.483 40.875 109.875 40.4832 109.875 40H108.125ZM108.125 16V40H109.875V16H108.125Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M80 20C80 19.4477 79.5523 19 79 19C78.4477 19 78 19.4477 78 20H80ZM78 36C78 36.5523 78.4477 37 79 37C79.5523 37 80 36.5523 80 36H78ZM78 20V36H80V20H78Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M115.75 20C115.75 19.5858 115.414 19.25 115 19.25C114.586 19.25 114.25 19.5858 114.25 20H115.75ZM114.25 36C114.25 36.4142 114.586 36.75 115 36.75C115.414 36.75 115.75 36.4142 115.75 36H114.25ZM114.25 20V36H115.75V20H114.25Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M127.75 26C127.75 25.5858 127.414 25.25 127 25.25C126.586 25.25 126.25 25.5858 126.25 26H127.75ZM126.25 30C126.25 30.4142 126.586 30.75 127 30.75C127.414 30.75 127.75 30.4142 127.75 30H126.25ZM126.25 26V30H127.75V26H126.25Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M121.75 24C121.75 23.5858 121.414 23.25 121 23.25C120.586 23.25 120.25 23.5858 120.25 24H121.75ZM120.25 32C120.25 32.4142 120.586 32.75 121 32.75C121.414 32.75 121.75 32.4142 121.75 32H120.25ZM120.25 24V32H121.75V24H120.25Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M20 8C20 7.44772 19.5523 7 19 7C18.4477 7 18 7.44772 18 8H20ZM18 48C18 48.5523 18.4477 49 19 49C19.5523 49 20 48.5523 20 48H18ZM18 8V48H20V8H18Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M32 8C32 7.44772 31.5523 7 31 7C30.4477 7 30 7.44772 30 8H32ZM30 48C30 48.5523 30.4477 49 31 49C31.5523 49 32 48.5523 32 48H30ZM30 8V48H32V8H30Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M26 2C26 1.44772 25.5523 1 25 1C24.4477 1 24 1.44772 24 2H26ZM24 54C24 54.5523 24.4477 55 25 55C25.5523 55 26 54.5523 26 54H24ZM24 2V54H26V2H24Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M2 24C2 23.4477 1.55228 23 1 23C0.447715 23 0 23.4477 0 24H2ZM0 32C0 32.5523 0.447715 33 1 33C1.55228 33 2 32.5523 2 32H0ZM0 24V32H2V24H0Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M14 16C14 15.4477 13.5523 15 13 15C12.4477 15 12 15.4477 12 16H14ZM12 40C12 40.5523 12.4477 41 13 41C13.5523 41 14 40.5523 14 40H12ZM12 16V40H14V16H12Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M38 16C38 15.4477 37.5523 15 37 15C36.4477 15 36 15.4477 36 16H38ZM36 40C36 40.5523 36.4477 41 37 41C37.5523 41 38 40.5523 38 40H36ZM36 16V40H38V16H36Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M8 20C8 19.4477 7.55228 19 7 19C6.44772 19 6 19.4477 6 20H8ZM6 36C6 36.5523 6.44772 37 7 37C7.55228 37 8 36.5523 8 36H6ZM6 20V36H8V20H6Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M44 20C44 19.4477 43.5523 19 43 19C42.4477 19 42 19.4477 42 20H44ZM42 36C42 36.5523 42.4477 37 43 37C43.5523 37 44 36.5523 44 36H42ZM42 20V36H44V20H42Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M56 26C56 25.4477 55.5523 25 55 25C54.4477 25 54 25.4477 54 26H56ZM54 30C54 30.5523 54.4477 31 55 31C55.5523 31 56 30.5523 56 30H54ZM54 26V30H56V26H54Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M50 24C50 23.4477 49.5523 23 49 23C48.4477 23 48 23.4477 48 24H50ZM48 32C48 32.5523 48.4477 33 49 33C49.5523 33 50 32.5523 50 32H48ZM48 24V32H50V24H48Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M206 23C206 22.4477 205.552 22 205 22C204.448 22 204 22.4477 204 23L206 23ZM204 33C204 33.5523 204.448 34 205 34C205.552 34 206 33.5523 206 33L204 33ZM204 23L204 33L206 33L206 23L204 23Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M236 8C236 7.44772 235.552 7 235 7C234.448 7 234 7.44772 234 8H236ZM234 48C234 48.5523 234.448 49 235 49C235.552 49 236 48.5523 236 48H234ZM234 8V48H236V8H234Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M248 8C248 7.44772 247.552 7 247 7C246.448 7 246 7.44772 246 8H248ZM246 48C246 48.5523 246.448 49 247 49C247.552 49 248 48.5523 248 48H246ZM246 8V48H248V8H246Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M242 2C242 1.44772 241.552 1 241 1C240.448 1 240 1.44772 240 2H242ZM240 54C240 54.5523 240.448 55 241 55C241.552 55 242 54.5523 242 54H240ZM240 2V54H242V2H240Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M218 24C218 23.4477 217.552 23 217 23C216.448 23 216 23.4477 216 24H218ZM216 32C216 32.5523 216.448 33 217 33C217.552 33 218 32.5523 218 32H216ZM216 24V32H218V24H216Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M212 26C212 25.4477 211.552 25 211 25C210.448 25 210 25.4477 210 26H212ZM210 30C210 30.5523 210.448 31 211 31C211.552 31 212 30.5523 212 30H210ZM210 26V30H212V26H210Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M230 16C230 15.4477 229.552 15 229 15C228.448 15 228 15.4477 228 16H230ZM228 40C228 40.5523 228.448 41 229 41C229.552 41 230 40.5523 230 40H228ZM228 16V40H230V16H228Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M254 16C254 15.4477 253.552 15 253 15C252.448 15 252 15.4477 252 16H254ZM252 40C252 40.5523 252.448 41 253 41C253.552 41 254 40.5523 254 40H252ZM252 16V40H254V16H252Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M224 20C224 19.4477 223.552 19 223 19C222.448 19 222 19.4477 222 20H224ZM222 36C222 36.5523 222.448 37 223 37C223.552 37 224 36.5523 224 36H222ZM222 20V36H224V20H222Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M260 20C260 19.4477 259.552 19 259 19C258.448 19 258 19.4477 258 20H260ZM258 36C258 36.5523 258.448 37 259 37C259.552 37 260 36.5523 260 36H258ZM258 20V36H260V20H258Z"
        fill={barFillColor}
      />
      <path
        opacity="0.5"
        d="M266 24C266 23.4477 265.552 23 265 23C264.448 23 264 23.4477 264 24H266ZM264 32C264 32.5523 264.448 33 265 33C265.552 33 266 32.5523 266 32H264ZM264 24V32H266V24H264Z"
        fill={barFillColor}
      />
    </svg>
  );
};
