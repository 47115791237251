import { Box, Center, Flex, Icon, Tooltip } from "@chakra-ui/react";
import React from "react";
import { HiOutlineInformationCircle, HiStar } from "react-icons/hi2";
import { SortingRule } from "react-table";

import {
  RouterLink,
  SortableTable,
  SortableTableColumn,
} from "../../../components";
import useCurrentBreakpoint from "../../../hooks/useCurrentBreakpoint";
import { formatDate } from "../../../utils/datetime";
import { PageOptions } from "../../../utils/types";
import { CandidateListItemFragment } from "../../graphql";

const largeScreenBreakpoints = ["lg", "xl", "2xl"];
const smallScreenBreakpoints = ["base", "sm"];
interface CandidateListProps {
  candidates: Array<CandidateListItemFragment>;
  emptyState?: string;
  pageOptions?: PageOptions;
  loading?: boolean;
  sortBy: SortingRule<string>;
  positionId?: string;
}

const CandidateList: React.FC<CandidateListProps> = ({
  candidates,
  emptyState,
  pageOptions,
  loading,
  sortBy,
  positionId,
}) => {
  const currentBreakpoint = useCurrentBreakpoint();
  const shouldShowCompactView =
    smallScreenBreakpoints.includes(currentBreakpoint);

  const columns: Array<SortableTableColumn<CandidateListItemFragment>> = [
    {
      Header: "Candidate Name",
      accessor: "fullName",
      Cell: ({
        row: { original: candidate },
      }: {
        row: { original: CandidateListItemFragment };
      }) => (
        <RouterLink
          to={
            positionId
              ? `/candidate/${candidate.id}/position/${positionId}`
              : `/candidate/${candidate.id}`
          }
        >
          {candidate.fullName || "Candidate"}
        </RouterLink>
      ),
    },
    {
      Header: "Interviews",
      accessor: "callCount",
      show: !shouldShowCompactView,
      id: "callCount",
    },
    {
      Header: "Last Interview",
      accessor: (candidate) => candidate.lastCall?.name,
      id: "lastCall.name",
    },
    {
      Header: "Last Interview Date",
      accessor: (candidate) => candidate.lastCall?.createdAt,
      id: "lastCall.createdAt",
      Cell: ({
        row: { original: candidate },
      }: {
        row: { original: CandidateListItemFragment };
      }) => (
        <>
          {(shouldShowCompactView ? "Last interview " : "") +
            formatDate(candidate?.lastCall?.createdAt, {
              month: "short",
              day: "numeric",
              year: "numeric",
            })}
        </>
      ),
    },
    {
      Header: () => (
        <Flex alignItems="center" gap="1.5">
          Avg. interview score
          <Tooltip
            label={
              <>
                <b>Average interview score</b> is calculated from the overall
                interview scores given by interviewers for this candidate in
                this position
              </>
            }
            variant="light"
            maxW="300"
            placement="bottom-end"
          >
            <Flex>
              <Icon as={HiOutlineInformationCircle} boxSize="4" />
            </Flex>
          </Tooltip>
        </Flex>
      ),
      show:
        sortBy.id === "candidate.positionAverageScore" ||
        candidates.some((c) => !!c.positionAverageScore),
      id: "candidate.positionAverageScore",
      accessor: (candidate) => {
        const score = candidate.positionAverageScore;
        if (!score) return null;
        return (
          <Flex alignItems="center">
            <Box w="6">{score.toFixed(1)}</Box>
            <Icon as={HiStar} color="blue.500" boxSize="4" />
          </Flex>
        );
      },
    },
  ];

  if (candidates.length < 1 && !loading) {
    return (
      <Center h="100px" p={5} bg="gray.50" borderRadius="base" fontSize="sm">
        {emptyState ?? "There are no results to show"}
      </Center>
    );
  }

  return (
    <SortableTable
      columns={columns}
      data={candidates}
      manualPagination
      pageOptions={pageOptions}
      initialSort={sortBy}
      width="100%"
      loading={false}
      includeSpacerColumn={largeScreenBreakpoints.includes(currentBreakpoint)}
      shouldShowCompactView={shouldShowCompactView}
    />
  );
};

CandidateList.displayName = "CandidateList";
export default CandidateList;
