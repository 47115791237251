import { Flex, StyleProps, Text } from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { asArray } from "../../utils/array";
import { isString } from "../../utils/string";
import AILoading from "./AILoading";

type ExtendedLoadingMessageProps = StyleProps & {
  /**
   * Either single static message or an array of messages to be shown in sequence
   */
  text?: string | string[];
  /**
   * If `text` is an array, the approximate time each message will be shown
   */
  timePerMessage?: number;
  /**
   * Set to `true` to loop back to the first message after showing the last message
   */
  cycle?: boolean;
  /**
   * Set to `true` to disable an animated ellipsis after the text
   */
  disableEllipsis?: boolean;
};

/**
 * An animated loading indicator that can cycle through loading messages. Best
 * suited for long-running tasks.
 */
export const ExtendedLoadingMessage: React.FC<ExtendedLoadingMessageProps> = ({
  text = "Loading",
  timePerMessage = 2010,
  cycle = false,
  disableEllipsis = false,
  ...styles
}) => {
  const textKey = isString(text) ? text : text.join();
  const textChoices = useMemo(() => asArray(text), [textKey]);

  const [curTextIdx, setCurTextIdx] = useState(0);
  const [nextTextIdx, setNextTextIdx] = useState<number | null>(null);

  const getNextTextIdx = useCallback(() => {
    const nextIdx = curTextIdx + 1;
    if (cycle) return nextIdx % textChoices.length;
    if (nextIdx < textChoices.length) return nextIdx;
    return null;
  }, [curTextIdx, textChoices, cycle]);

  /**
   * If multiple text choices, transition through them with opacity changes with
   * +- 750ms of random offset to timing
   */
  useEffect(() => {
    if (textChoices.length < 2) return;

    const nextIdx = getNextTextIdx();
    if (nextIdx !== null) {
      const randTimeOffset = (Math.random() - 0.5) * 1500;
      const timeForMessage = timePerMessage + randTimeOffset;
      const timeout = setTimeout(() => setNextTextIdx(nextIdx), timeForMessage);
      return () => clearTimeout(timeout);
    }
  }, [textChoices, getNextTextIdx, timePerMessage]);

  /**
   * Clear the ellipsis when the current text changes,
   * then start a timer to add successive "."s to it
   */
  const [ellipsis, setEllipsis] = useState("");
  useEffect(() => {
    setEllipsis("");
    const interval = setInterval(() => {
      setEllipsis((e) => (e.length < 3 ? `${e}.` : "."));
    }, 480);
    return () => clearInterval(interval);
  }, [curTextIdx]);
  const showEllipsis = !disableEllipsis;

  return (
    <Flex direction="column" alignItems="center" {...styles}>
      <AILoading />
      <Text
        mt="4"
        fontSize="lg"
        fontWeight="medium"
        color="gray.400"
        transition="opacity 110ms"
        opacity={nextTextIdx ? "0" : undefined}
        onTransitionEnd={() => {
          if (nextTextIdx) {
            setCurTextIdx(nextTextIdx);
            setNextTextIdx(null);
          }
        }}
        _after={{
          content: showEllipsis ? `"${ellipsis}"` : undefined,
          display: "inline-block",
          w: 4,
        }}
      >
        {textChoices[curTextIdx] ?? ""}
      </Text>
    </Flex>
  );
};
