import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Divider,
  Flex,
  Heading,
  Image,
  Select,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { HiOutlineHome } from "react-icons/hi2";
import { Navigate } from "react-router-dom";

import {
  LoadingIndicator,
  PageContent,
  RouterLink,
  SortableTable,
  SortableTableColumn,
} from "../../../../components";
import { SearchBox } from "../../../../components/Search";
import useDebounce from "../../../../hooks/useDebounce";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { CheckMarkCircle } from "../../../../plan/components/JobDescriptionAnalyzer/icons";
import { formatDate } from "../../../../utils/datetime";
import {
  RedactionModelTopic,
  RedactionPredictionFragment,
  useOrganizationRedactionPredictionsLazyQuery,
  useViewRedactionPredictionMutation,
} from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { DefaultLayout } from "../../../layouts";

const DataRedactionLog: React.FC = () => {
  const currentUser = useCurrentUser();
  const { dataRedactionPredictionEnabled } = currentUser.organization;
  const { percentHeight } = useWindowDimensions();
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce<string>(searchTerm, 200);
  const [reasonFilter, setReasonFilter] = useState("");
  const [pageNumber, setPageNumber] = useState(1);

  const [getOrgPredictions, { data, loading }] =
    useOrganizationRedactionPredictionsLazyQuery();

  const [viewRedactionPrediction] = useViewRedactionPredictionMutation();

  useEffect(() => {
    getOrgPredictions({
      variables: {
        pagination: {
          limit: 25,
          page: pageNumber,
          searchTerm,
        },
        topic: reasonFilter,
      },
    });
  }, [debouncedSearchTerm, reasonFilter, pageNumber]);

  const predictions =
    data?.currentUser?.organization.redactionPredictions.results || [];
  const pageInfo =
    data?.currentUser?.organization.redactionPredictions.pageInfo;
  const reasonFilters =
    data?.currentUser?.organization.redactionPredictions.topicFilters;
  const pageOptions = {
    currentPage: pageNumber,
    totalPages: pageInfo?.totalPages || 0,
    handlePageNumberChange: (pageNumber: number) => setPageNumber(pageNumber),
  };

  const columns: Array<SortableTableColumn<RedactionPredictionFragment>> = [
    {
      Header: "",
      id: "hasViewed",
      Cell: ({
        row: { original: prediction },
      }: {
        row: { original: RedactionPredictionFragment };
      }) => (
        <Box>
          {prediction.viewed ? (
            <Tooltip shouldWrapChildren label="Viewed">
              <CheckMarkCircle boxSize={5} />
            </Tooltip>
          ) : null}
        </Box>
      ),
    },
    {
      Header: "Timestamp",
      Cell: ({
        row: { original: prediction },
      }: {
        row: { original: RedactionPredictionFragment };
      }) => (
        <Text color="gray.800" fontWeight="500">
          {formatDate(prediction.call?.startTime, {
            year: "numeric",
            month: "short",
            day: "numeric",
          })}
        </Text>
      ),
    },
    {
      Header: "Interview title",
      Cell: ({
        row: { original: prediction },
      }: {
        row: { original: RedactionPredictionFragment };
      }) => (
        <RouterLink
          to={`/interview/${prediction.call?.id}?t=${prediction.startTime}`}
          color="blue.600"
          fontWeight="500"
          fontSize="sm"
          target="_blank"
          onClick={() => {
            viewRedactionPrediction({
              variables: {
                callId: prediction.call?.id,
                predictionId: prediction.id,
              },
            });
          }}
        >
          {prediction.call?.name || "Interview"}
        </RouterLink>
      ),
    },
    {
      Header: "Speaker",
      Cell: ({
        row: { original: prediction },
      }: {
        row: { original: RedactionPredictionFragment };
      }) => <Text>{prediction.speaker?.label || "Speaker"}</Text>,
    },
    {
      Header: "Speaker type",
      Cell: ({
        row: { original: prediction },
      }: {
        row: { original: RedactionPredictionFragment };
      }) => (
        <Text>
          {prediction.modelTopic === RedactionModelTopic.EeocHighRiskQuestions
            ? "Interviewer"
            : "Candidate"}
        </Text>
      ),
    },
    {
      Header: "Detected content",
      accessor: "text",
    },
    {
      Header: "Detection reason",
      accessor: "friendlyTopic",
    },
  ];

  if (!dataRedactionPredictionEnabled) {
    return <Navigate to="/" />;
  }

  const reasonOptions = reasonFilters?.map((filter) => (
    <option key={filter.value} value={filter.value}>
      {filter.label}
    </option>
  ));

  return (
    <DefaultLayout>
      <PageContent>
        <Breadcrumb
          fontSize="sm"
          mb="7"
          color="gray.500"
          separator={<Text color="gray.300">/</Text>}
          spacing="2"
        >
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/">
              <HiOutlineHome size={16} />
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              fontWeight="400"
              to="/settings/compliance-check"
            >
              Org Settings
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink
              as={RouterLink}
              to="#"
              color="gray.900"
              fontWeight="500"
            >
              Compliance Check Log
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Heading as="h3" size="lg" fontWeight="600" mb={7}>
          Compliance Check Log
        </Heading>
        <Text fontWeight="400" color="gray.800" fontSize="sm" mb={8}>
          When Compliance Check is enabled, all future interviews will be
          processed. This feature is not retroactive and will not process
          interviews that occurred in the past. Our compliance check technology
          is state-of-the-art, but it&apos;s not perfect. Even when compliance
          check is enabled, Admins with the &quot;View Compliance Check&quot;
          permission will have visibility of any redacted content to ensure
          accuracy.
        </Text>
        <Divider />
        <Flex
          my={6}
          direction="row"
          alignItems="flex-end"
          justifyContent="space-between"
          gap="2"
        >
          <Box>
            <Text fontSize="small" pb={1} color="gray.600">
              Detection reason
            </Text>
            <Select
              placeholder="Select..."
              borderRadius="2px"
              minWidth="260px"
              value={reasonFilter}
              onChange={(e) => setReasonFilter(e.target.value)}
            >
              {reasonOptions}
            </Select>
          </Box>
          <SearchBox
            placeholder="Search"
            borderRadius="2px"
            py={2.5}
            px={2}
            fontSize="sm"
            h="10"
            minWidth="320px"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Flex>
        {loading && (
          <Flex
            height={percentHeight(50)}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <LoadingIndicator />
          </Flex>
        )}
        {!loading && (
          <>
            {predictions.length ? (
              <SortableTable<RedactionPredictionFragment>
                columns={columns}
                data={predictions}
                initialSort={{ id: "createdAt", desc: true }}
                pageOptions={pageOptions}
                columnWidths={[
                  "2%",
                  "10%",
                  "12.5%",
                  "15%",
                  "12.5%",
                  "35.5%",
                  "12.5%",
                ]}
                manualPagination
              />
            ) : (
              <Flex
                height={percentHeight(50)}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Image
                  width="25%"
                  src="/static/images/empty-list-items-generic.svg"
                />
                <Text fontSize="sm" fontWeight="400" color="gray.700" mt="12">
                  No redactions found
                </Text>
              </Flex>
            )}
          </>
        )}
      </PageContent>
    </DefaultLayout>
  );
};

export default DataRedactionLog;
