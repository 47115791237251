import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

export const InAppSupport: React.FC<IconProps> = (props) => (
  <Icon
    width="200px"
    height="124px"
    viewBox="0 0 200 124"
    fill="none"
    {...props}
  >
    <g>
      <g>
        <path
          d="M44.8984 106.879V116.78"
          stroke="#7FC6FE"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M54.522 105.09C42.4817 110.085 28.6823 104.295 23.7168 92.1821C18.7513 80.0709 24.5078 66.1901 36.548 61.1953L54.522 105.09Z"
          stroke="#7FC6FE"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.3"
          d="M54.522 105.09C42.4817 110.085 28.6823 104.295 23.7168 92.1821C18.7513 80.0709 24.5078 66.1901 36.548 61.1953L54.522 105.09Z"
          fill="#AEDEFF"
        />
        <path
          d="M45.6672 63.0938C47.1612 63.0938 48.6772 63.2706 50.0614 63.58C52.5442 64.1325 56.3452 67.4255 56.3452 67.4255C56.3452 67.4255 60.8493 69.8345 62.3214 72.0446C64.4526 75.2492 65.705 79.1183 65.705 83.2512C65.705 94.39 56.7407 103.409 45.6672 103.409C35.1415 103.409 26.4848 95.1635 25.6719 84.7761C25.6279 84.2457 25.6279 83.8037 25.6279 83.2512C25.6279 81.8367 27.2758 79.5824 27.2758 79.5824C27.2758 79.5824 26.8363 76.1789 27.4735 74.7851C30.6593 67.9117 37.6242 63.0938 45.6672 63.0938Z"
          fill="#AEDEFF"
        />
        <path
          d="M32.2634 89.1546C33.9332 96.4699 36.2182 92.2708 38.1956 94.6798C41.3814 98.5474 44.4369 100.271 46.8098 99.9177C49.1827 99.5641 45.0082 96.7352 45.0082 92.1824C45.0082 87.6296 43.6899 84.7123 40.2609 84.204C36.8334 83.6957 35.8007 87.3644 33.6915 86.2594C32.1315 85.4195 36.5038 80.911 36.8554 76.9328C37.4046 70.9862 27.8471 74.6771 27.4736 74.7876C27.4736 74.7876 27.4517 74.7876 27.4517 74.8097C26.0235 77.9274 25.3864 81.353 25.65 84.7786C25.65 84.7786 25.65 84.7786 25.65 84.8007C28.9237 88.9999 31.4065 85.3974 32.2634 89.1546Z"
          fill="white"
        />
        <path
          d="M50.0616 63.582C49.5124 63.803 49.051 64.0904 48.5896 64.4219C44.1953 67.4718 42.5255 74.1904 45.0083 75.3176C47.469 76.4462 50.567 72.2234 52.6762 71.4941C56.3234 70.2565 55.7742 73.5716 53.7308 74.5882C51.6875 75.6049 48.282 75.627 47.6009 78.3689C46.8978 81.1978 49.7321 81.9272 52.874 82.325C56.0158 82.7228 56.3234 93.3312 58.8062 92.403C61.2889 91.4747 63.7937 84.8887 61.7943 76.9324C61.1132 74.2346 61.5526 72.8423 62.2996 72.0687V72.0466C59.4434 67.7591 55.0711 64.7313 50.0616 63.582Z"
          fill="white"
        />
        <path
          d="M40.3484 114.727H49.6656C51.9726 114.727 53.8402 116.605 53.8402 118.926V119.058H36.1738V118.926C36.1738 116.605 38.0414 114.727 40.3484 114.727Z"
          fill="#DDF5FF"
        />
      </g>
      <g>
        <path
          d="M71.2739 119.035L71.3809 118.468C71.851 115.885 74.158 113.926 76.755 113.926H100.406L99.4967 119.065H71.2725L71.2739 119.035Z"
          fill="#4FAFFD"
        />
        <path
          d="M80.3047 119.037L85.131 92.0664H113.355L109.764 112.11C109.043 116.056 105.536 119.037 101.557 119.037H80.3047Z"
          fill="#4FAFFD"
        />
        <path
          opacity="0.25"
          d="M82.666 105.807L85.1312 92.0664H113.355L110.2 109.639L82.666 105.807Z"
          fill="black"
        />
        <path
          d="M60.6046 103.42C56.3158 103.42 52.5719 101.746 50.0687 98.7081C47.5376 95.614 46.5723 91.5253 47.3398 87.1243L55.4604 41.8987C57.0599 32.9272 65.6492 25.6016 74.5681 25.6016H147.753C152.043 25.6016 155.787 27.2768 158.29 30.3149C160.82 33.409 161.787 37.4977 161.019 41.8987L152.897 87.1243C151.299 96.0958 142.71 103.42 133.791 103.42H60.6046Z"
          fill="#4FAFFD"
        />
        <path
          d="M55.1523 82.9779L62.0821 44.3103C63.2392 37.9217 69.3443 32.7266 75.6955 32.7266H144.11C147.159 32.7266 149.821 33.9185 151.625 36.077C153.43 38.2621 154.131 41.1868 153.556 44.3103L146.598 82.9779H55.1523Z"
          fill="white"
        />
        <path
          d="M98.3356 97.1722C97.3205 97.1722 96.4314 96.8039 95.8382 96.0937C94.5155 94.5894 94.87 92.0625 96.6555 90.4727C97.5329 89.6771 98.6812 89.1953 99.7827 89.1953C100.798 89.1953 101.687 89.5637 102.28 90.2738C103.603 91.7782 103.248 94.305 101.463 95.8948C100.556 96.7184 99.4371 97.1722 98.3356 97.1722Z"
          fill="#2398FB"
        />
        <path
          d="M82.9365 103.988H111.161"
          stroke="#0263B0"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g>
        <path
          d="M116.435 50.1087L89.2988 45.3438L99.9666 70.8378L102.764 63.7464L110.914 70.2941L118.318 60.9705L110.169 54.4242L116.435 50.1087Z"
          fill="#7FC6FE"
        />
      </g>
      <g>
        <path
          d="M163.629 16.7266H148.802C143.348 16.7266 138.916 21.1865 138.916 26.6705C138.916 31.5533 142.44 35.7008 147.228 36.4803V41.587L154.476 36.6143H163.629C169.083 36.6143 173.515 32.1559 173.515 26.6705C173.515 21.1644 169.083 16.7266 163.629 16.7266Z"
          fill="#AEDEFF"
        />
        <path
          d="M150.663 27.8722C151.411 27.8722 152.015 27.2637 152.015 26.5123C152.015 25.7609 151.411 25.1523 150.663 25.1523C149.916 25.1523 149.312 25.7609 149.312 26.5123C149.312 27.2637 149.916 27.8722 150.663 27.8722Z"
          fill="white"
        />
        <path
          d="M156.205 27.8722C156.952 27.8722 157.556 27.2637 157.556 26.5123C157.556 25.7609 156.952 25.1523 156.205 25.1523C155.457 25.1523 154.853 25.7609 154.853 26.5123C154.853 27.2637 155.457 27.8722 156.205 27.8722Z"
          fill="white"
        />
        <path
          d="M161.768 27.8722C162.515 27.8722 163.12 27.2637 163.12 26.5123C163.12 25.7609 162.515 25.1523 161.768 25.1523C161.021 25.1523 160.416 25.7609 160.416 26.5123C160.416 27.2637 161.021 27.8722 161.768 27.8722Z"
          fill="white"
        />
      </g>
      <g>
        <path
          d="M154.783 100.676H173.177C173.385 100.676 173.438 100.965 173.23 101.043C171.487 101.592 168.001 102.823 164.305 104.785C160.689 106.696 156.864 109.313 154.288 112.663H142.372C142.372 112.663 142.841 110.701 144.557 108.24C146.274 105.753 149.293 102.796 154.367 100.78C154.496 100.702 154.653 100.676 154.783 100.676Z"
          fill="#7FC6FE"
        />
        <path
          opacity="0.3"
          d="M164.305 104.758C160.715 106.669 156.891 109.26 154.314 112.636H142.398C142.398 112.636 142.841 110.673 144.557 108.213L164.305 104.758Z"
          fill="#0B526F"
        />
        <path
          d="M180.229 118.498H152.571V112.452C152.571 112.452 158.581 108.316 166.387 108.316C172.969 108.316 178.304 111.274 179.786 112.19C180.047 112.348 180.202 112.635 180.202 112.949L180.229 118.498Z"
          fill="#7FC6FE"
        />
        <path
          d="M152.702 118.499H124.94V112.505C124.94 112.454 124.965 112.427 124.992 112.401C125.591 112.009 131.366 108.293 138.756 108.293C146.562 108.293 152.702 112.427 152.702 112.427V118.499Z"
          fill="#7FC6FE"
        />
        <path
          d="M125.069 112.452C125.069 112.452 131.081 108.316 138.886 108.316C146.692 108.316 152.702 112.452 152.702 112.452V118.498"
          stroke="#0263B0"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g>
        <path
          d="M11.7812 123.208H188.161V118.566H11.7812V123.208Z"
          fill="white"
        />
      </g>
    </g>
  </Icon>
);

export const DocsAndTutorials: React.FC<IconProps> = (props) => (
  <Icon
    width="200px"
    height="124px"
    viewBox="0 0 200 124"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_3121_271489)">
      <path
        d="M154.876 92.3008C152.312 103.801 154.548 117.637 159.23 118.194C163.671 118.728 165.145 107.588 163.802 104.685C162.788 102.492 160.845 104.729 162.798 109.377C164.587 113.622 169.825 122.319 174.735 122.1C179.645 121.882 175.402 113.054 175.402 113.054L194.715 120.202"
        stroke="#0263B0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.56 10.5586C148.986 13.7741 148.454 19.6195 142.635 21.6653C136.868 23.6846 133.946 22.7819 129.136 27.0063C124.937 30.7012 125.308 38.1676 125.308 38.1676L139.499 41.5159C139.499 41.5159 139.552 36.228 142.662 33.6232C148.135 29.0271 154.832 31.6039 159.854 25.3587C163.68 20.5768 162.857 14.1723 162.857 14.1723L148.56 10.5586Z"
        fill="white"
      />
      <path
        d="M180.722 84.3735L168.424 91.2367C168.415 91.4019 168.071 97.2635 157.835 104.709C147.446 112.25 124.419 126.449 120.294 122.076C116.309 117.855 122.559 113.475 125.301 111.691C127.892 110.003 135.674 104.419 136.556 103.782L136.558 103.761C135.009 104.488 113.812 114.475 109.26 116.415C104.031 118.653 100.932 117.726 99.8333 115.331C98.5515 112.49 101.395 109.925 103.956 108.441C106.388 107.034 125.868 96.2133 127.745 95.1705V95.1499C125.63 96.0349 102.4 105.69 96.4128 107.785C90.1514 109.968 87.8164 107.053 87.3105 105.618C86.8046 104.184 86.7825 101.368 90.4788 99.156C93.6191 97.268 118.378 86.4488 120.581 85.4857C120.601 85.4871 120.582 85.465 120.582 85.465C118.498 86.1243 97.2461 92.7545 92.5424 93.6306C87.5893 94.5569 85.0744 91.1143 86.1084 87.8958C87.2442 84.3514 94.9599 81.6639 99.2344 79.8541C103.51 78.0443 121.486 69.8802 123.373 69.0247C125.259 68.1692 127.472 66.2325 125.333 64.3209C123.196 62.4108 115.582 58.6864 113.268 52.956C110.933 47.2256 115.989 46.7182 117.016 46.8952C119.93 47.3982 140.645 52.81 143.79 53.2613C146.934 53.7127 150.15 54.3956 153.672 52.7348C157.11 51.1093 160.748 49.2051 160.941 49.0915C160.941 49.0915 160.941 49.0915 160.962 49.0915L180.722 84.3735Z"
        fill="#AEDEFF"
      />
      <path
        d="M180.685 84.2871L160.958 49.1526C160.919 49.1098 160.857 49.1068 160.833 49.1673C160.556 50.5199 159.178 60.0145 165.578 70.4471C172.419 81.5642 179.456 84.0171 180.597 84.3859C180.657 84.411 180.703 84.3505 180.685 84.2871Z"
        fill="#53CCFE"
      />
      <path
        opacity="0.2"
        d="M180.685 84.2871L160.958 49.1526C160.919 49.1098 160.857 49.1068 160.833 49.1673C160.556 50.5199 159.178 60.0145 165.578 70.4471C172.419 81.5642 179.456 84.0171 180.597 84.3859C180.657 84.411 180.703 84.3505 180.685 84.2871Z"
        fill="#4FAFFD"
      />
      <path
        d="M113.369 48.1588C115.685 49.417 121.567 52.1826 123.378 48.3624C120.312 47.6042 117.878 47.0246 117.017 46.8756C116.4 46.7827 114.447 46.9095 113.369 48.1588Z"
        fill="#7FC6FE"
      />
      <path
        opacity="0.2"
        d="M125.28 111.691L139.61 107.23L136.622 103.723C136.622 103.723 128.022 109.906 125.28 111.691Z"
        fill="#565656"
      />
      <path
        opacity="0.2"
        d="M130.361 98.6382L127.872 95.0938C127.872 95.0938 113.273 103.205 106.751 106.845C110.202 105.739 130.361 98.6382 130.361 98.6382Z"
        fill="#565656"
      />
      <path
        opacity="0.2"
        d="M123.794 89.4399L120.729 85.4102C120.729 85.4102 103.659 92.856 95.0039 96.9034C101.206 95.4623 123.794 89.4399 123.794 89.4399Z"
        fill="#565656"
      />
      <path
        d="M140.024 107.21C133.388 101.776 126.477 94.836 120.141 83.9727"
        stroke="#0263B0"
        strokeLinecap="round"
      />
      <path
        d="M5.47168 61.0862C9.61053 57.4385 17.2835 52.5135 23.7086 56.6951C23.7086 56.6951 17.3882 48.4351 20.6052 44.578C24.8089 39.5261 37.1768 53.2569 35.3213 55.6154C33.4141 58.0256 29.1484 47.2404 31.3801 41.246C33.1412 36.5289 41.5472 43.6045 42.8998 47.7861"
        stroke="#0263B0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.5489 7.47266C52.2111 10.0229 52.633 14.6589 57.2483 16.2814C61.8208 17.8832 64.1395 17.1664 67.9538 20.5176C71.2829 23.4469 70.9879 29.369 70.9879 29.369L59.7351 32.024C59.7351 32.024 59.6923 27.8306 57.2276 25.7656C52.8852 22.1194 47.5752 24.1638 43.5912 19.2107C40.5572 15.417 41.2106 10.3386 41.2106 10.3386L52.5489 7.47266Z"
        fill="white"
      />
      <path
        d="M106.861 78.1048L74.3465 46.153L63.5085 35.5195H63.4569L51.3416 47.8395L35.4421 63.9951L29.4609 71.8159V71.8674L73.989 120.126H74.0388L110.591 83.1662V83.1147L106.861 78.1048Z"
        fill="#4FAFFD"
      />
      <path
        d="M35.4415 64.043L28.1811 71.4047C25.9324 73.7068 25.9324 77.4375 28.2327 79.688L71.124 121.862C71.4816 122.222 72.0954 122.222 72.453 121.862L83.138 110.974L35.4415 64.043Z"
        fill="#0772D0"
      />
      <path
        d="M71.8911 122.683L71.176 122.02C69.0802 119.924 69.0286 116.549 71.1244 114.453L106.86 78.1055L111.308 82.5015L114.886 86.0294C115.039 86.1824 115.039 86.4884 114.886 86.6432L79.4575 122.683C77.4133 124.729 74.0901 124.779 71.8911 122.683Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3121_271489">
        <rect width="200" height="124" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

export const WhatsNew: React.FC<IconProps> = (props) => (
  <Icon
    width="200px"
    height="124px"
    viewBox="0 0 200 124"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_3121_271522)">
      <path
        d="M166.107 112.01L172.068 123.559L172.301 108.853L189.577 117.889L176.469 98.1799L194.909 96.8884L181.427 91.1719"
        stroke="#0263B0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.518 29.503L130.608 40.583L141.766 32.6583L144.636 45.5572L147.363 31.9363L158.6 35.42L150.421 27.6827L160.93 21.6407L147.917 22.646L149.375 12.3359L142.924 21.6102L133.904 12.9156L138.676 24.1365L123.474 24.969L137.518 29.503Z"
        fill="white"
      />
      <path
        d="M179.874 86.4399L164.891 55.6055L155.924 59.6528C155.924 59.6528 143.141 57.141 136.485 60.5404C129.326 64.2071 124.498 74.356 124.498 74.356C124.498 74.356 108.402 84.1024 99.1766 89.5938C89.9511 95.0851 87.2394 99.9721 88.9606 102.917C91.5682 107.319 96.6527 106.177 104.8 101.989C111.268 98.6676 118.835 94.2963 118.835 94.2963C118.835 94.2963 118.48 103.338 126.774 103.957C126.774 103.957 128.91 110.722 135.819 110.215C135.819 110.215 138.668 117.547 147.828 114.147C150.375 113.225 159.896 108.551 158.615 103.691C158.615 103.691 167.792 97.8976 170.961 90.8518L179.874 86.4399Z"
        fill="#AEDEFF"
      />
      <path
        d="M126.773 103.955C133.572 102.059 146.778 94.5946 144.067 88.5353C143.132 86.4462 141.309 85.6458 139.028 85.8462"
        stroke="#0263B0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.819 110.214C144.183 107.98 153.849 100.242 150.752 95.1417C148.524 91.4736 143.395 93.3679 143.395 93.3679"
        stroke="#0263B0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M157.828 107.237C160.775 103.399 156.39 97.6079 150.55 100.092"
        stroke="#0263B0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M124.638 76.621C123.38 80.7105 129.094 90.8361 131.012 93.9943C133.815 98.6097 136.847 95.8451 137.984 92.8205C139.894 87.7156 138.612 81.203 138.612 81.203C141.492 79.6209 144.097 77.6394 146.364 75.2656"
        stroke="#0263B0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.462 92.0056L128.849 90.1562"
        stroke="#0263B0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M155.924 59.6523L170.962 90.8514"
        stroke="#0263B0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.2188 57.4828L27.9795 45.3945L27.7359 60.7878L9.65325 51.3304L23.3734 71.9593L4.07129 73.3104L18.1832 79.2942"
        stroke="#0263B0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.2372 19.9669L45.457 22.2026L54.4067 24.3934L49.3882 31.7166L57.6437 26.8223L58.913 35.4574L62.2102 27.0504L68.2427 30.7578L64.1281 24.1188L71.9434 20.3547L63.2726 20.0816L66.5214 13.0649L59.5484 18.4386L54.5666 11.1836L55.2372 19.9669Z"
        fill="white"
      />
      <path
        d="M57.9756 67.4589H101.108L110.427 51.5703H67.2319L57.9756 67.4589Z"
        fill="#0263B0"
      />
      <path
        d="M29.6329 67.7495L58.1971 68.0778L48.7192 51.5703H20.3135L29.6329 67.7495Z"
        fill="#0263B0"
      />
      <path
        d="M57.3711 67.4594L101.14 67.3867L101.171 122.833H57.5927L57.3711 67.4594Z"
        fill="#4FAFFD"
      />
      <path
        opacity="0.2"
        d="M101.171 83.9317L101.14 67.3516L57.9756 67.4605V71.932L101.171 83.9317Z"
        fill="black"
      />
      <path
        d="M29.442 67.457H57.9754V122.831L29.3789 122.758L29.442 67.457Z"
        fill="#0772D0"
      />
      <path
        d="M57.9756 67.457V122.831"
        stroke="#0263B0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3121_271522">
        <rect width="200" height="124" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

export const ContactUs: React.FC<IconProps> = (props) => (
  <Icon
    width="200px"
    height="124px"
    viewBox="0 0 200 124"
    fill="none"
    {...props}
  >
    <path
      d="M164.039 101.984L188.47 117.864"
      stroke="#0263B0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M167.234 93.4727L183.717 97.1333"
      stroke="#0263B0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M158.225 28.9812L144.419 17.3008L124.738 40.8288L158.225 28.9812Z"
      fill="white"
    />
    <path
      d="M159.572 112.423L96.2344 110.249L119.661 51.447L130.903 68.4994L161.948 48.2383L179.488 75.0359L148.443 95.2971L159.572 112.423Z"
      fill="#AEDEFF"
    />
    <path
      d="M32.5873 64.4261L11.4395 48.8711"
      stroke="#0263B0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.2701 71.9189L14.6328 67.7695"
      stroke="#0263B0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.8848 22.3794L56.5471 11.668L74.595 33.2452L43.8848 22.3794Z"
      fill="white"
    />
    <path
      d="M65.9989 39.0133L93.0402 53.5866C96.9106 55.6705 99.7339 59.266 100.863 63.5047L108.466 92.1341C110.743 100.687 105.642 109.49 97.051 111.758L60.331 121.502C51.7489 123.787 42.9484 118.682 40.6614 110.113L33.0583 81.4836C31.9304 77.2449 32.61 72.7088 34.9339 68.9914L51.163 42.9432C54.3084 37.9296 60.7935 36.1942 65.9989 39.0133Z"
      fill="#DDF5FF"
    />
    <path
      d="M59.1378 43.0452C57.6637 43.4334 56.3996 44.3741 55.5782 45.6689L39.3477 71.7186C37.7927 74.236 37.3401 77.2747 38.0949 80.1421L45.7065 108.787C47.2543 114.59 53.2032 118.024 59.0073 116.505L95.7345 106.734C101.544 105.19 104.983 99.2487 103.462 93.4545L95.8437 64.835C95.0719 61.9762 93.1693 59.5693 90.5702 58.1469L63.5444 43.5637C62.1938 42.8384 60.6275 42.6486 59.1378 43.0452ZM57.8056 38.0331C60.5608 37.3035 63.4792 37.652 65.9989 39.012L93.0402 53.5853C96.9106 55.6692 99.7339 59.2647 100.863 63.5033L108.466 92.1327C110.743 100.685 105.642 109.488 97.051 111.756L60.331 121.5C51.7489 123.785 42.9484 118.681 40.6614 110.112L33.0583 81.4823C31.9304 77.2436 32.61 72.7074 34.9339 68.9901L51.163 42.9418C52.6811 40.5094 55.049 38.7641 57.8056 38.0331Z"
      fill="#4FAFFD"
    />
    <path
      opacity="0.2"
      d="M50.9062 76.2641L89.0664 66.1406L98.7337 102.481L60.5736 112.603L50.9062 76.2641Z"
      fill="#505050"
    />
    <path
      d="M46.957 74.1143L85.1171 63.9922L94.7845 100.331L56.6244 110.453L46.957 74.1143Z"
      fill="#0772D0"
    />
    <path
      d="M72.5186 93.3348L100.667 62.7461L108.473 92.1065C110.75 100.659 105.648 109.462 97.0573 111.73L60.3528 121.466C51.7708 123.749 42.9717 118.645 40.6932 110.093L32.8887 80.7321L72.5186 93.3348Z"
      fill="#4FAFFD"
    />
    <path
      d="M70.1757 84.5156L108.998 96.886C108.705 103.914 103.877 109.933 97.0667 111.747L60.3537 121.465C53.5451 123.277 46.3535 120.445 42.6094 114.489L70.1757 84.5156Z"
      fill="#4FAFFD"
    />
    <path
      d="M81.2285 101.313L68.9251 104.577C67.1843 105.03 66.1614 106.81 66.6154 108.547C67.0694 110.285 68.8514 111.306 70.5922 110.853L82.8956 107.59C84.6364 107.137 85.6579 105.358 85.2053 103.619C84.7414 101.866 82.9594 100.844 81.2285 101.313Z"
      fill="#DDF5FF"
    />
    <path
      d="M80.549 104.828L71.2676 107.295"
      stroke="#0263B0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M109.081 96.9929L78.4492 87.0352"
      stroke="#0263B0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64.3499 90.8477L42.7012 114.352"
      stroke="#0263B0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M96.4062 66.5733L100.129 62.6406"
      stroke="#0263B0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.5275 82.1114L33.2285 80.6055"
      stroke="#0263B0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
