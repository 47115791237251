import { ApolloClient, ApolloProvider } from "@apollo/client";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { CypressHistoryPatch, ThemedChakraProvider } from "./components";

interface Props {
  apolloClient: ApolloClient<any>;
  children: React.ReactNode;
  basename?: string;
}

const App: React.FC<Props> = ({ apolloClient, basename, children }) => {
  return (
    <ApolloProvider client={apolloClient}>
      <Router basename={basename}>
        <CypressHistoryPatch />
        <ThemedChakraProvider>{children}</ThemedChakraProvider>
      </Router>
    </ApolloProvider>
  );
};

export default App;
