import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useTheme } from "..";
import { DataKey, UserMetrics } from "./types";
import { userMetricAverage } from "./userMetricAverage";

// This type isn't exported from recharts for some reason
type AxisDomainItem =
  | string
  | number
  | Function // eslint-disable-line @typescript-eslint/ban-types
  | "auto"
  | "dataMin"
  | "dataMax";

interface UserChartProps {
  users: Array<UserMetrics>;
  barPropsList: Array<{ dataKey: DataKey; name: string; color?: string }>;
  unit?: string;
  formatter?: (value: number) => string;
  domain?: [AxisDomainItem, AxisDomainItem];
}

const UserChart: React.FC<UserChartProps> = ({
  users,
  barPropsList,
  unit,
  formatter,
  domain,
}) => {
  const { colors } = useTheme();
  const defaultBarColor = colors.blue[600];
  const avgColor = colors.blue[700];
  const textColor = colors.primary;
  const chartHeight = 100 + users.length * 40;

  const { dataKey } = barPropsList[0];
  const average = userMetricAverage(users, dataKey);
  const usersSorted = [...users].sort((a, b) => {
    return (a.callingMetrics[dataKey] ?? 0) < (b.callingMetrics[dataKey] ?? 0)
      ? 1
      : -1;
  });

  const labelValue =
    `Team Avg: ${formatter ? formatter(average) : average.toFixed(1)}` +
    `${unit ?? ""}`;

  return (
    <BarChart
      width={700}
      margin={{ top: 20, right: 50, left: 20, bottom: 20 }}
      height={chartHeight}
      layout="vertical"
      data={usersSorted}
    >
      <Tooltip
        formatter={(value: any) => {
          if (formatter) {
            return formatter(value as number);
          }
          return value;
        }}
      />
      <ReferenceLine
        x={average}
        stroke={avgColor}
        strokeDasharray="2"
        label={
          <Label
            value={labelValue}
            position="top"
            fontSize="xs"
            fontWeight={600}
            fill={avgColor}
          />
        }
      />
      <CartesianGrid strokeDasharray="2 2" strokeOpacity=".2" />
      <XAxis
        type="number"
        tickLine={false}
        tickCount={6}
        domain={domain}
        tickFormatter={formatter}
        unit={unit}
      />
      <YAxis
        type="category"
        dataKey="fullName"
        width={150}
        tickLine={false}
        tick={{ fontSize: 14, fontWeight: "bold", fill: textColor }}
      />
      {barPropsList.map((barProps, index) => (
        <Bar
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          dataKey={`callingMetrics.${barProps.dataKey}`}
          name={barProps.name}
          maxBarSize={15}
          fill={barProps.color ?? defaultBarColor}
        />
      ))}
    </BarChart>
  );
};

export default UserChart;
