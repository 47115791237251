/* eslint-disable @typescript-eslint/ban-types */
import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

import { Card } from "../Card";

const TH: React.FC<BoxProps> = (props) => (
  <Box
    as="th"
    textAlign="left"
    fontSize="xs"
    fontWeight="500"
    color="gray.500"
    px={4}
    py={2}
    {...props}
  />
);

const TR: React.FC<{ noBorder?: boolean } & BoxProps> = ({
  noBorder,
  ...props
}) => (
  <Box
    as="tr"
    borderBottom={noBorder ? "0" : "1px"}
    borderColor="gray.100"
    {...props}
  />
);

const TD: React.FC<BoxProps> = (props) => (
  <Box as="td" fontSize="sm" lineHeight="19px" px={4} py={4} {...props} />
);

export interface TableNewerColumn {
  header: string;
  id?: string;
  accessor?: string;
  cell?: (row: any, idx: number) => React.ReactNode;
}

export interface TableNewerRow {
  data: Record<string, any>;
  key: string;
  children?: React.ReactNode[];
}

export interface TableNewerProps {
  columns: Array<TableNewerColumn>;
  data: Array<TableNewerRow>;
  columnWidths?: Record<number, any>;
  onClickRow?: (value: TableNewerRow) => void;
}

const renderCell = (
  column: TableNewerColumn,
  row: { [key: string]: any },
  idx: number
): React.ReactNode => {
  if (column.cell) {
    return column.cell(row, idx);
  }
  if (column.accessor) {
    return row[column.accessor];
  }
  throw new Error("missing Table column Cell or accessor");
};

export const TableNewer: React.FC<TableNewerProps> = ({
  columns,
  data,
  columnWidths,
  onClickRow,
}) => {
  return (
    <Card style={{ boxShadow: "none" }}>
      <Box border="1px" borderColor="gray.100" borderRadius={4} overflow="auto">
        <Box
          as="table"
          width="full"
          display="table"
          border="0"
          sx={{ tableLayout: "auto" }}
        >
          <Box
            as="thead"
            backgroundColor="gray.50"
            borderBottom="1px"
            borderColor="gray.100"
          >
            <tr>
              {columns.map((column, i) => (
                <TH
                  width={columnWidths ? columnWidths[i] : null}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${column.header}_${i}`}
                >
                  {column.header}
                </TH>
              ))}
            </tr>
          </Box>
          <tbody>
            {data.map((row, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={row.key}>
                <TR
                  data-testid={`table-row-${i}`}
                  onClick={() => {
                    if (onClickRow) {
                      onClickRow(row);
                    }
                  }}
                  cursor={onClickRow ? "pointer" : "default"}
                  _hover={{
                    bg: onClickRow ? "gray.50" : "transparent",
                  }}
                  _last={{ borderBottom: "0" }}
                >
                  {columns.map((column) => (
                    <TD key={column.id ?? column.accessor}>
                      {renderCell(column, row, i)}
                    </TD>
                  ))}
                </TR>
                {row.children}
              </React.Fragment>
            ))}
          </tbody>
        </Box>
      </Box>
    </Card>
  );
};
