import { switchAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {},
  track: {
    bg: "gray.300",
    _checked: {
      bg: "blue.600",
    },
  },
});

export default defineMultiStyleConfig({ baseStyle });
