import { FormControl, FormLabel, FormLabelProps } from "@chakra-ui/react";
import React from "react";

const FilterLabel: React.FC<
  FormLabelProps & React.LabelHTMLAttributes<any>
> = ({ children, id, ...rest }) => (
  <FormControl id={id}>
    <FormLabel fontSize="sm" fontWeight="semibold" {...rest}>
      {children}
    </FormLabel>
  </FormControl>
);

export default FilterLabel;
