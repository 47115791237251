import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

import AllTraineesManagement from "./AllTraineesManagement";
import ManageTrainingLayout from "./ManageTrainingLayout";
import { ManageTrainingProgramSidebarItem } from "./ManageTrainingSidebar";
import { TraineeStatus } from "./types";
import { TrainingApi } from "./useTrainingApi";

type AllTraineesPageViewProps = {
  trainingPrograms: ManageTrainingProgramSidebarItem[];
  trainingApi: TrainingApi;
  trainees: TraineeStatus[];
  traineeCount: number;
};

const AllTraineesPageView: React.FC<AllTraineesPageViewProps> = ({
  trainingPrograms,
  trainingApi,
  trainees,
  traineeCount,
}) => {
  return (
    <ManageTrainingLayout
      trainingApi={trainingApi}
      trainingPrograms={trainingPrograms}
      allTrainees
      traineeCount={traineeCount}
    >
      <Flex flexDir="column" height="100%">
        <Box mt="2" mb="0">
          <Flex
            flexDir="row"
            alignItems="center"
            width="100%"
            maxWidth="1012px"
            ml="auto"
            mr="auto"
          >
            <Text
              fontSize="xl"
              fontWeight="medium"
              ml="10"
              mr="4"
              display="inline-block"
            >
              All Trainees
            </Text>
          </Flex>

          <Box mt="7">
            <Box width="100%" maxWidth="1012px" ml="auto" mr="auto">
              <AllTraineesManagement
                trainees={trainees}
                trainingApi={trainingApi}
              />
            </Box>
          </Box>
        </Box>
      </Flex>
    </ManageTrainingLayout>
  );
};

export default AllTraineesPageView;
