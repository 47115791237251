import { canManageThisHiringTeam } from "../../../../utils/currentUser";
import {
  CallBetaFragment,
  CallCandidateFragment,
  CandidateLinkType,
} from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { ATSLinks } from "../../Recording";

type CandidateLinkParams = Pick<
  CallBetaFragment,
  | "interviewerIds"
  | "greenhouseScorecardLinkWithId"
  | "leverInterviewLinkWithId"
  | "smartrecruitersInterviewLink"
  | "customAtsUrl"
> & {
  position?: { id: string } | null;
  candidate?: CallCandidateFragment | null;
};

const useCandidateLinks = ({
  interviewerIds,
  candidate,
  position,
  greenhouseScorecardLinkWithId,
  leverInterviewLinkWithId,
  smartrecruitersInterviewLink,
  customAtsUrl,
}: CandidateLinkParams): ATSLinks => {
  const currentUser = useCurrentUser();
  const result: ATSLinks = {};

  const canManageOrganizationSettings =
    currentUser.userRole?.canManageOrganizationSettings;
  const canManageHiringTeam =
    position?.id && canManageThisHiringTeam(currentUser, position.id);

  const isManager = canManageOrganizationSettings || canManageHiringTeam;
  const isInterviewer = interviewerIds.some(
    (interviewerId) => interviewerId === currentUser.id
  );

  if (greenhouseScorecardLinkWithId) {
    const href = isInterviewer
      ? greenhouseScorecardLinkWithId
      : candidate?.greenhouseLink && isManager
      ? `${candidate.greenhouseLink}#activity_feed`
      : null;

    if (href) {
      result.greenhouse = { href, tooltipLabel: "Greenhouse" };
    }
  }

  if (leverInterviewLinkWithId) {
    const href = isInterviewer
      ? leverInterviewLinkWithId
      : candidate?.lastLeverOpportunityId && isManager
      ? `https://hire.lever.co/candidates/${candidate.lastLeverOpportunityId}/feedback`
      : null;

    if (href) {
      result.lever = { href, tooltipLabel: "Lever" };
    }
  }

  if (smartrecruitersInterviewLink) {
    result.smartRecruiters = {
      href: smartrecruitersInterviewLink,
      tooltipLabel: "SmartRecruiters",
    };
  }

  if (customAtsUrl) {
    result.custom = { href: customAtsUrl, tooltipLabel: "Open in your ATS" };
  }

  const linkedIn = candidate?.candidateLinks.find(
    ({ type }) => type === CandidateLinkType.Linkedin
  )?.link;

  if (linkedIn || candidate?.fullName) {
    const href =
      linkedIn ||
      `https://www.linkedin.com/search/results/people/?keywords=${candidate.fullName}`;

    result.linkedIn = { href, tooltipLabel: "LinkedIn" };
  }

  return result;
};

export default useCandidateLinks;
