import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import useFeatureFlag from "../../../graphql/hooks/useFeatureFlag";
import { getMatchesOverThreshold, getMatchingChapters } from "./match";
import { CandidatePositionChapter, Match } from "./types";

export type CompareTabParams = {
  matchID: string;
  setActiveMatchID: (id: string) => void;
  thresholdMatches: Match[];
  matchingChapters: Match[];
};

export const useCompareTabParams = (
  candidateChapters: CandidatePositionChapter[],
  compareCandidateChapters: CandidatePositionChapter[],
  useV2Matching = false,
  currentThreshold = 0.87
): CompareTabParams => {
  const [searchParams, setSearchParams] = useSearchParams();
  const matchIdParam = searchParams.get("matchId") || "";
  const [matchID, setMatchID] = useState<string>(matchIdParam);

  const useCommutativeMatch = useFeatureFlag(
    "candidate_compare:commutative_matching"
  );

  const setActiveMatchID = (id: string): void => {
    setMatchID(id);
    searchParams.set("matchId", id);
    setSearchParams(searchParams, { replace: true });
  };

  const matchingChapters = useMemo(() => {
    return getMatchingChapters(
      candidateChapters || [],
      compareCandidateChapters || [],
      useCommutativeMatch,
      useV2Matching
    );
  }, [candidateChapters, compareCandidateChapters, useV2Matching]);

  useEffect(() => {
    if (!matchID && matchingChapters.length) {
      const firstID = matchingChapters[0].id;
      setActiveMatchID(firstID);
    }
  }, [matchID, matchingChapters]);

  const thresholdMatches = useMemo(
    () => getMatchesOverThreshold(matchingChapters, currentThreshold),
    [matchingChapters, currentThreshold]
  );

  return {
    matchID,
    setActiveMatchID,
    thresholdMatches,
    matchingChapters,
  };
};
