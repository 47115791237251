import { ApolloError, MutationTuple } from "@apollo/client";

import {
  DeleteCallNoteMutation,
  DeleteCallNoteMutationVariables,
  useDeleteCallNoteMutation,
} from "..";

export default function useDeleteCallNote({
  onCompleted,
  onError,
}: {
  onCompleted?: (data: DeleteCallNoteMutation) => void;
  onError?: (error: ApolloError) => void;
}): [
  MutationTuple<DeleteCallNoteMutation, DeleteCallNoteMutationVariables>[0],
  { loading: boolean; error?: ApolloError }
] {
  return useDeleteCallNoteMutation({
    update(cache, { data }) {
      const callNote = data?.deleteCallNote?.callNote;
      if (callNote) {
        const callNoteId = cache.identify(callNote);
        if (callNoteId) {
          cache.evict({ id: callNoteId });
          cache.gc();
        }
      }
    },
    onCompleted,
    onError,
  });
}
