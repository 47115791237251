import { ApolloCache, StoreObject } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import invariant from "invariant";
import { useState } from "react";

import { errorToast, successToast, useToast } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import {
  useCallShareLazyQuery,
  useRemoveExternalCallShareMutation,
  useRemoveShareMutation,
  useRenewExternalCallShareMutation,
  useShareCallExternallyMutation,
  useShareCallMutation,
  useUpdateCallVisibilityMutation,
} from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";
import { Share, ShareModalProps } from "./types";

type UseCallShareModalParams = {
  callId: string;
  onClose(): void;
};

type UseCallShareModal = Omit<
  ShareModalProps,
  "callId" | "onClose" | "visibility" | "visibilityLevels" | "positionId"
> & {
  getCallShareData(): Promise<any>;
  hasSelection: boolean;
  loading: boolean;
};

/**
 * Like useSharePlaylistModal() and useClipShareModal(), this hook sets
 * up all of the queries and mutations required to run the share modal for
 * a call and returns them as props that can be passed to the share modal
 */
export function useCallShareModal({
  callId,
  onClose,
}: UseCallShareModalParams): UseCallShareModal {
  const toast = useToast();
  const [hasSelection, setHasSelection] = useState(false);
  const currentUser = useCurrentUser();
  const sendGAEvent = useSendGAEvent();

  // query data
  const [getCallShareData, { data, loading }] = useCallShareLazyQuery({
    variables: { id: callId },
    onError: (err) => {
      Sentry.captureException(err);
      onClose();
      errorToast(toast, `There was a problem sharing this interview`);
    },
  });

  const {
    trainingProgramCount,
    externalCallShares,
    canShare,
    canShareExternal,
    callShares,
    shareableUsers,
    visibleTo,
  } = data?.call ?? {
    canShare: false,
  };

  const internalShares: Share[] = callShares || [];
  const externalShares: Share[] = externalCallShares || [];
  const sharedWithUnique = externalShares
    .concat(internalShares)
    .filter(
      (share, index, shares) =>
        shares.findIndex((s) => s.sharedTo?.id === share.sharedTo?.id) === index
    );

  // visibility mutations
  const [updateCallVisibility] = useUpdateCallVisibilityMutation({
    onError: (err) => {
      errorToast(toast, `Error updating interview privacy: ${err.message}`);
    },
    onCompleted: (data) => {
      if (data?.updateCall) {
        successToast(toast, "Interview visibility updated.");
      }
    },
  });

  // share mutations helpers
  const updateCache = <T extends StoreObject>(
    cache: ApolloCache<any>,
    call: T | undefined,
    field: keyof T
  ): void => {
    if (call) {
      cache.modify({
        id: cache.identify(call),
        fields: {
          [field]: () => call[field],
        },
      });
    }
  };

  // share mutations
  const [shareCall, { loading: shareMutationLoading }] = useShareCallMutation({
    update(cache, { data }) {
      const call = data?.shareCall?.call;
      updateCache(cache, call, "callShares");
    },
    onError: (err) => {
      errorToast(toast, `Failed to share interview: ${err.message}`);
    },
    onCompleted: (data) => {
      if (data?.shareCall) {
        successToast(toast, "Interview shared");
        onClose();
      }
    },
  });

  const [removeShare, { loading: removeShareLoading }] = useRemoveShareMutation(
    {
      update(cache, { data }) {
        const call = data?.removeShare?.call;
        updateCache(cache, call, "callShares");
      },
      onError: (err) => {
        errorToast(toast, `Failed to remove share: ${err.message}`);
      },
      onCompleted: (data) => {
        if (data?.removeShare) {
          successToast(toast, "Removed share");
        }
      },
    }
  );

  // external share mutations
  const [shareCallExternally, { loading: externalShareMutationLoading }] =
    useShareCallExternallyMutation({
      update(cache, { data }) {
        const call = data?.shareCallExternally?.call;
        updateCache(cache, call, "externalCallShares");
      },
      onError(err) {
        errorToast(
          toast,
          `Failed to share interview externally: ${err.message}`
        );
      },
      onCompleted(data) {
        if (data.shareCallExternally) {
          successToast(toast, "Interview shared externally");
          onClose();
        }
      },
    });

  const [renewExternalShare] = useRenewExternalCallShareMutation({
    update(cache, { data }) {
      const call = data?.renewExternalCallShare?.call;
      updateCache(cache, call, "externalCallShares");
    },
    onError(err) {
      errorToast(toast, `Failed to renew external share: ${err.message}`);
    },
    onCompleted(data) {
      if (data.renewExternalCallShare) {
        successToast(toast, "Successfully renewed external share");
      }
    },
  });

  const [removeExternalShare, { loading: removeExternalShareLoading }] =
    useRemoveExternalCallShareMutation({
      update(cache, { data }) {
        const call = data?.removeExternalCallShare?.call;
        updateCache(cache, call, "externalCallShares");
      },
      onError: (err) => {
        errorToast(toast, `Failed to remove external share: ${err.message}`);
      },
      onCompleted: (data) => {
        if (data?.removeExternalCallShare) {
          successToast(toast, "Removed external share");
        }
      },
    });

  return {
    // these are used by the calling component
    getCallShareData,
    hasSelection,
    loading,

    // these are the relevant share modal props
    canShare,
    canShareExternal,
    externalShareDuration:
      currentUser.organization.externalShareDefaultDurationDays,
    trainingProgramCount,
    shareableUsers,
    sharedWith: sharedWithUnique,
    optionsLoading: loading,
    shareLoading: shareMutationLoading || externalShareMutationLoading,
    visibleTo,
    modalTitle: "Share Interview",

    onChangeVisibility(visibility) {
      sendGAEvent("update_visibility", "call_review", visibility);
      updateCallVisibility({
        variables: { id: callId, visibility },
      });
    },
    onSelection(selection) {
      setHasSelection(!!selection.length);
    },

    onShare(shareToUserIds, message) {
      if (!shareToUserIds.length) return;
      shareCall({
        variables: {
          id: callId,
          shareToUserIds,
          message,
        },
      });
    },
    removeShare({ id }) {
      if (!removeShareLoading) {
        removeShare({ variables: { id } });
      }
    },

    onShareExternal(shareToEmails, message) {
      invariant(shareToEmails.length > 0, "Must share to one or more emails");
      sendGAEvent("sharing_external", "call_review", "call_link");
      shareCallExternally({ variables: { callId, shareToEmails, message } });
    },
    renewExternalShare({ id }) {
      renewExternalShare({ variables: { id } });
    },
    async removeExternalShare({ id }) {
      if (!removeExternalShareLoading) {
        await removeExternalShare({ variables: { id } });
      }
    },
  };
}
