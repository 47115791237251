import LogRocket from "logrocket";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import useToast, {
  errorToast,
  successToast,
} from "../../../components/useToast/useToast";
import {
  useAddTrainingProgramQuestionMutation,
  useAddTrainingProgramTraineesMutation,
  useCreateTrainingProgramItemMutation,
  useCreateTrainingProgramMutation,
  useDeleteAllTrainingProgramTraineesMutation,
  useDeleteTrainingProgramItemMutation,
  useDeleteTrainingProgramMutation,
  useDeleteTrainingProgramQuestionMutation,
  useDeleteTrainingProgramTraineeMutation,
  useDescribeTrainingProgramItemMutation,
  useEnableTrainingProgramAssessmentMutation,
  useLaunchTrainingProgramMutation,
  useMarkTrainingProgramTraineeCompleteMutation,
  useNameTrainingProgramMutation,
  useReorderTrainingProgramItemsMutation,
  useReorderTrainingProgramQuestionsMutation,
  useResetTrainingProgramQuestionsMutation,
  useTrainingProgramManagementListItemsQuery,
  useUpdateTrainingProgramQuestionMutation,
} from "../../graphql";
import { manageTrainingRoute } from "./utils";

export type TrainingApi = {
  program: {
    create(name: string, description: string): void;
    delete(id: string): void;
    rename(id: string, name: string, description: string): void;
    launch(id: string): void;
  };
  recording: {
    add(programId: string, callId: string, clipId?: string): void;
    describe(id: string, description: string): void;
    remove(id: string): void;
    reorder(trainingProgramId: string, trainingProgramItemIds: string[]): void;
  };
  questions: {
    enable(programId: string, enabled: boolean): void;
    add(programId: string, text: string, order: number): void;
    remove(questionId: string): void;
    update(questionId: string, text: string): void;
    reset(programId: string): void;
    reorder(
      trainingProgramId: string,
      trainingProgramQuestionIds: string[]
    ): void;
  };
  trainee: {
    add(programId: string, userIds: string[]): void;
    delete(traineeId: string, options?: Record<string, any>): void;
    deleteAll(programId: string): void;
    markComplete(traineeId: string): void;
    markIncomplete(traineeId: string): void;
  };
};

export const useTrainingApi = (
  refetchTrainingPrograms?: ReturnType<
    typeof useTrainingProgramManagementListItemsQuery
  >["refetch"]
): TrainingApi => {
  const toast = useToast();
  const navigate = useNavigate();

  const [createTrainingProgramItem] = useCreateTrainingProgramItemMutation({
    onCompleted: () => {
      refetchTrainingPrograms?.();
    },
    onError: (err) => {
      errorToast(
        toast,
        `Error adding item to training program: ${err.message}`
      );
    },
  });

  const [deleteTrainingProgram] = useDeleteTrainingProgramMutation({
    onCompleted: async () => {
      let navTo = "/training/manage";

      if (refetchTrainingPrograms) {
        const { data } = await refetchTrainingPrograms();
        const programId = data?.trainingProgramManagementListItems[0]?.id;
        if (programId) navTo += `/${programId}`;
      }

      navigate({
        pathname: navTo,
        search: window.location.search,
      });
      successToast(toast, "Deleted training program");
    },
    onError: (err) => {
      errorToast(toast, `Error removing training program: ${err.message}`);
    },
  });

  const [nameTrainingProgram] = useNameTrainingProgramMutation({
    onError: (err) => {
      errorToast(toast, `Error renaming training program: ${err.message}`);
    },
  });

  const [deleteTrainingProgramItem] = useDeleteTrainingProgramItemMutation({
    onError: (err) => {
      errorToast(toast, `Error removing trainingProgram item: ${err.message}`);
    },
    refetchQueries: ["TrainingProgramTrainees"],
  });

  const [createTrainingProgramMutation] = useCreateTrainingProgramMutation({
    onCompleted: (data) => {
      if (data.createTrainingProgram?.trainingProgram) {
        navigate({
          pathname: manageTrainingRoute(
            data.createTrainingProgram.trainingProgram.id
          ),
          search: window.location.search,
        });
        refetchTrainingPrograms?.();
      }
    },
  });

  const [enableTrainingProgramAssessment] =
    useEnableTrainingProgramAssessmentMutation({
      onError: () => errorToast(toast, "Error enabling assessments"),
    });

  const [addTrainingProgramQuestion] = useAddTrainingProgramQuestionMutation({
    onError: () => errorToast(toast, "Error adding question"),
  });

  const [deleteTrainingProgramQuestion] =
    useDeleteTrainingProgramQuestionMutation({
      onError: () => errorToast(toast, "Error deleting question"),
    });

  const [updateTrainingProgramQuestion] =
    useUpdateTrainingProgramQuestionMutation({
      onError: () => errorToast(toast, "Error updating question"),
      refetchQueries: ["TrainingProgram"],
    });

  const [resetTrainingProgramQuestions] =
    useResetTrainingProgramQuestionsMutation({
      onError: () => errorToast(toast, "Error resetting questions"),
    });

  const [reorderTrainingProgramQuestions] =
    useReorderTrainingProgramQuestionsMutation({
      onError: () => errorToast(toast, "Error reordering questions"),
    });

  const [launchTrainingProgram] = useLaunchTrainingProgramMutation({
    onCompleted: () => {
      refetchTrainingPrograms?.();
    },
    onError: (err) =>
      errorToast(toast, `Error launching training program: ${err.message}`),
  });

  const [addTrainingProgramTrainees] = useAddTrainingProgramTraineesMutation({
    onError: (err) =>
      errorToast(toast, `Error adding trainees: ${err.message}`),
  });

  const [deleteTrainingProgramTrainee] =
    useDeleteTrainingProgramTraineeMutation({
      onError: (err) =>
        errorToast(toast, `Error deleting trainees: ${err.message}`),
      update: (cache, { data }) => {
        const deletedTraineeId =
          data?.deleteTrainingProgramTrainee?.deletedTraineeId;
        if (deletedTraineeId) {
          const cacheId = cache.identify({
            id: deletedTraineeId,
            __typename: "TrainingProgramTrainee",
          });
          cache.evict({ id: cacheId });
          cache.gc();
        }
      },
    });

  const [deleteAllTrainingProgramTrainees] =
    useDeleteAllTrainingProgramTraineesMutation({
      onError: (err) =>
        errorToast(toast, `Error deleting all trainees: ${err.message}`),
    });

  const [markTrainingProgramTraineeComplete] =
    useMarkTrainingProgramTraineeCompleteMutation({
      onCompleted: () => {
        refetchTrainingPrograms?.();
      },
      onError: (err) =>
        errorToast(
          toast,
          `Error marking trainingProgram trainee complete: ${err.message}`
        ),
    });

  const [describeTrainingProgramItemMutation] =
    useDescribeTrainingProgramItemMutation({
      onError: (err) =>
        errorToast(toast, `Error describing recording: ${err.message}`),
    });

  const [reorderTrainingProgramItemsMutation] =
    useReorderTrainingProgramItemsMutation({
      onError: (err: { message: any }) =>
        errorToast(toast, `Error changing recording order: ${err.message}`),
    });

  return useMemo(
    () => ({
      program: {
        create: (name: string, description: string) => {
          createTrainingProgramMutation({
            variables: { name, description },
          });
          LogRocket.track("training-create-program");
        },
        delete: (id: string) => {
          deleteTrainingProgram({ variables: { id } });
          LogRocket.track("training-delete-program");
        },
        rename: (id: string, name: string, description: string) => {
          nameTrainingProgram({
            variables: { id, name, description },
            onCompleted() {
              refetchTrainingPrograms?.();
            },
          });
          LogRocket.track("training-rename-program");
        },
        launch: (id: string) => {
          launchTrainingProgram({ variables: { id } });
          LogRocket.track("training-launch-program");
        },
      },
      recording: {
        add: (programId, callId, clipId) => {
          createTrainingProgramItem({
            variables: { trainingProgramId: programId, callId, clipId },
          });
          LogRocket.track("training-add-recording");
        },
        remove: (id: string) => {
          deleteTrainingProgramItem({ variables: { id } });
          LogRocket.track("training-remove-recording");
        },
        describe: (programId: string, description: string) => {
          describeTrainingProgramItemMutation({
            variables: { id: programId, description },
          });
          LogRocket.track("training-describe-recording");
        },
        reorder: (
          trainingProgramId: string,
          trainingProgramItemIds: string[]
        ) => {
          reorderTrainingProgramItemsMutation({
            variables: { trainingProgramId, trainingProgramItemIds },
          });
          LogRocket.track("training-reorder-recording");
        },
      },
      questions: {
        enable: (programId: string, enabled: boolean) => {
          enableTrainingProgramAssessment({
            variables: { id: programId, enabled },
            optimisticResponse: {
              enableTrainingProgramAssessment: {
                __typename: "EnableTrainingProgramAssessment",
                trainingProgram: {
                  __typename: "TrainingProgram",
                  id: programId,
                  assessmentEnabled: enabled,
                  trainingProgramQuestions: [],
                },
              },
            },
          });
          LogRocket.track(
            enabled
              ? "training-enabled-questions"
              : "training-disabled-questions"
          );
        },
        add: (programId: string, text: string, order: number) => {
          addTrainingProgramQuestion({
            variables: { trainingProgramId: programId, question: text, order },
          });
          LogRocket.track("training-add-question");
        },
        remove: (questionId: string) => {
          deleteTrainingProgramQuestion({ variables: { id: questionId } });
          LogRocket.track("training-remove-question");
        },
        update: (questionId: string, text: string) => {
          updateTrainingProgramQuestion({
            variables: { id: questionId, question: text },
          });
          LogRocket.track("training-edit-question");
        },
        reset: (programId: string) => {
          resetTrainingProgramQuestions({
            variables: { trainingProgramId: programId },
          });
          LogRocket.track("training-reset-questions");
        },
        reorder: (
          trainingProgramId: string,
          trainingProgramQuestionIds: string[]
        ) => {
          reorderTrainingProgramQuestions({
            variables: { trainingProgramId, trainingProgramQuestionIds },
          });
          LogRocket.track("training-reorder-questions");
        },
      },
      trainee: {
        add: (programId: string, userIds: string[]) => {
          addTrainingProgramTrainees({
            variables: { trainingProgramId: programId, userIds },
          });
          LogRocket.track("training-add-trainee");
        },
        delete: (traineeId: string, options = {}) => {
          deleteTrainingProgramTrainee({ variables: { id: traineeId } });
          if (options?.toast) {
            successToast(toast, "Trainee removed");
          }
          LogRocket.track("training-remove-trainee");
        },
        deleteAll: (trainingProgramId: string) => {
          deleteAllTrainingProgramTrainees({
            variables: { id: trainingProgramId },
          });
          LogRocket.track("training-remove-all-trainees");
        },
        markComplete: (traineeId: string) => {
          markTrainingProgramTraineeComplete({
            variables: { id: traineeId, complete: true },
          });
          successToast(toast, "Trainee was marked completed");
          LogRocket.track("training-mark-complete");
        },
        markIncomplete: (traineeId: string) => {
          markTrainingProgramTraineeComplete({
            variables: { id: traineeId, complete: false },
          });
          successToast(toast, "Trainee was marked incomplete");
          LogRocket.track("training-mark-incomplete");
        },
      },
    }),
    [createTrainingProgramItem]
  );
};
