import {
  callTimestampedLink,
  iconForNote,
  noteTimestampedLink,
} from "../main/components/CallNotes/utils";
import { CallNote, CallNoteType, CallType } from "../main/graphql";
import { formatDate, formatDuration } from "./datetime";

export enum CallStatus {
  Pending = "PENDING",
  Disclaimer = "DISCLAIMER",
  InProgress = "IN_PROGRESS",
  Cancelled = "CANCELLED",
  Completed = "COMPLETED",
  Busy = "BUSY",
  NoAnswer = "NO_ANSWER",
  Failed = "FAILED",
}

export enum TranscriptionStatus {
  Pending = "PENDING",
  InProgress = "IN_PROGRESS",
  Completed = "COMPLETED",
  Failed = "FAILED",
}

/* Corresponds to CallType enum on server */
export enum CommunicationType {
  Phone = "PHONE",
  Video = "VIDEO",
  InPerson = "IN_PERSON",
}

/* Corresponds to call.communication_type on server */
const communicationType = (callType: CallType): CommunicationType => {
  switch (callType) {
    case CallType.Browser:
      return CommunicationType.Phone;
    case CallType.Incoming:
      return CommunicationType.Phone;
    case CallType.Outgoing:
      return CommunicationType.Phone;
    case CallType.Zoom:
      return CommunicationType.Video;
    case CallType.ZoomNotetaker:
      return CommunicationType.Video;
    case CallType.GoogleMeet:
      return CommunicationType.Video;
    case CallType.TeamsNotetaker:
      return CommunicationType.Video;
    case CallType.InPersonAudio:
      return CommunicationType.InPerson;
  }
};

export const isVideo = (callType: CallType): boolean => {
  return communicationType(callType) === CommunicationType.Video;
};

export const isPhone = (callType: CallType): boolean => {
  return communicationType(callType) === CommunicationType.Phone;
};

export const isInPerson = (callType: CallType): boolean =>
  callType === CallType.InPersonAudio;

export const isInProgress = (callStatus: CallStatus): boolean =>
  callStatus === CallStatus.InProgress;

export const formatStatus = (call: {
  status: CallStatus;
  transcriptionStatus?: TranscriptionStatus | null;
}): string => {
  const { status, transcriptionStatus } = call;
  if (transcriptionStatus === "IN_PROGRESS") {
    return "transcribing";
  }
  if (transcriptionStatus === "FAILED") {
    return "error";
  }
  if (transcriptionStatus === "COMPLETED") {
    return "transcribed";
  }
  return status.toLowerCase().replace("_", " ");
};

export const formatDetails = (
  call: null | {
    id?: string;
    candidate?: { fullName?: string; phoneNumber?: string | null } | null;
    position?: null | { displayTitle?: string };
    startTime?: string | null;
    callStartTime?: string | null;
  },
  { withoutURL, html }: { withoutURL?: boolean; html?: boolean } = {}
): string => {
  const name = call?.candidate?.fullName;
  const position = call?.position?.displayTitle;
  const phone = call?.candidate?.phoneNumber || "";
  const date = formatDate(call?.callStartTime || call?.startTime);
  const url = call?.id
    ? `${window.location.protocol}//${window.location.host}/interview/${call?.id}`
    : "";
  const details = [
    name,
    position,
    phone,
    date,
    withoutURL ? null : html ? `<a href=${url}>View in BrightHire</a>` : url,
  ]
    .filter((s) => !!s)
    .join(html ? "<br>" : "\n");

  return details.trim();
};

/**
 * Construct the HTML for a link to a call note, with the note's time
 * as the link's text
 *
 * E.g. `<a href="...">15:20</a>`
 */
export const buildNoteTimestampLink = (
  note: Pick<CallNote, "time" | "callId">
): string => {
  const href = noteTimestampedLink(note);
  const text = formatDuration(note.time);
  return `<a href=${href}>${text}</a>`;
};

export const buildCallTimestampLink = (
  callId: string,
  time: number
): string => {
  const href = callTimestampedLink(callId, time);
  const text = formatDuration(time);
  return `<a href=${href}>${text}</a>`;
};

export const formatTimestamps = (
  timestamps: Array<Pick<CallNote, "time" | "callId">>,
  html = false
): string => {
  let text = "";
  if (timestamps.length > 0) {
    text = text.concat("Topic started at ");
    text = text.concat(
      timestamps
        .map((t) => (html ? buildNoteTimestampLink(t) : formatDuration(t.time)))
        .join(", ")
    );
  }
  return text;
};

export const formatNote = (
  note: Pick<CallNote, "type" | "text" | "callId" | "time">,
  html = false
): string => {
  const sep = html ? "<br>" : "\n";
  let { text } = note;
  if (html) text += ` ${buildNoteTimestampLink(note)}`;
  switch (note.type) {
    case CallNoteType.Note:
      return `${sep}- ${text}`;
    case CallNoteType.Cue:
      return `${sep}${sep}${text}`;
    case CallNoteType.Star:
    case CallNoteType.ThumbsUp:
    case CallNoteType.ThumbsDown:
    case CallNoteType.Flag:
      return `${sep}- ${iconForNote(note)} ${text}`;
    default:
      return `${sep}- ${text}`;
  }
};

/* For displaying the kind of call to a user */
export const callTypeLabel = (callType: CallType): string => {
  switch (callType) {
    case CallType.Zoom:
      return "Zoom";
    case CallType.ZoomNotetaker:
      return "Zoom";
    case CallType.GoogleMeet:
      return "Google Meet";
    case CallType.TeamsNotetaker:
      return "Teams";
    case CallType.Browser:
      return "Phone";
    case CallType.Incoming:
      return "Phone";
    case CallType.Outgoing:
      return "Phone";
    case CallType.InPersonAudio:
      return "In-Person";
  }
};
