import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  SystemProps,
  Text,
  TextProps,
} from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";

import useAccordionBugfix from "../../hooks/useChakraAccordionBugfix";
import { RouterLinkBox } from "../RouterLink";
import AnimateExpansion from "./AnimateExpansion";

export type SubNavItem = Omit<NavItem, "icon" | "subItems"> & { to: string };

export type NavItem = {
  /** The icon for the top-level nav item */
  icon: React.FC;
  /** The text that will be shown next to the icon when the sidebar is expanded */
  label: string;
  /** If `false`, an "Upgrade" tag will be shown */
  enabled?: boolean;
  /** If provided, the nav item will be a clickable link to this location */
  to?: string;
  /** Specifies that the link should *not* be used within react-router */
  isExternal?: boolean;
  /** Whether this nav item corresponds to the current route */
  isActive?: boolean;
  /** The `target` attribute for the anchor element */
  target?: React.HTMLAttributeAnchorTarget;
  /** Used for `[data-tour-id]` and `[data-test-id]` attributes */
  id?: string;
  /** If provided, the nav item will be an expandable accordion to reveal theses sub items */
  subItems?: SubNavItem[];
  /** Optional content to go at the end of the item when the sidebar is expanded */
  endSlot?: React.ReactNode;
  /** Whether to opt out of interaction styles */
  static?: boolean;
  onClick?(): void;
};

type SidebarNavItemProps = {
  navItem: NavItem;
  sidebarIsOpen: boolean;
  skipAnimationDelay?: boolean;
  /** Whether to reveal `subItems` by default */
  isExpanded?: boolean;
  /** Fired when `subItems` are expanded */
  onExpand?(): void;
  /** Fired when `subItems` are collapsed */
  onCollapse?(): void;
  /** Fired when an item is clicked that does not trigger an expand or collapse */
  onClick?(): void;
  /** Fired repeatedly while `subItems` expansion animation is happening */
  onChangeHeight?(): void;
};

const SidebarNavItem: React.FC<SidebarNavItemProps> = ({
  sidebarIsOpen,
  skipAnimationDelay,
  navItem,
  isExpanded,
  onExpand,
  onCollapse,
  onClick: onClickProp,
  onChangeHeight,
}) => {
  const { icon, label, subItems, target, endSlot, isExternal } = navItem;
  const showUpgrade = navItem.enabled === false;

  const onClick = (): void => {
    onClickProp?.();
    navItem.onClick?.();
  };

  const idAttributes = (
    item: Pick<NavItem, "id" | "label">
  ): Record<string, string> => ({
    "data-tour-id":
      item.id ?? `nav-item-${item.label.toLowerCase().replace(/ /g, "-")}`,
    "data-testid":
      item.id ?? `nav-item-${item.label.toLowerCase().replace(/ /g, "-")}`,
  });

  const textStyles: TextProps = {
    fontSize: "md",
    lineHeight: "5",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    userSelect: "none",
  };

  const interactionStyles: SystemProps = {
    cursor: "pointer",
    _hover: {
      bg: "whiteAlpha.400",
    },
  };
  const activeStyles = (item: NavItem | SubNavItem): SystemProps => {
    if (!sidebarIsOpen) return {};
    let showActive;

    if (isSubNavItem(item)) {
      showActive = isExpanded && item.isActive;
    } else {
      showActive =
        !isExpanded &&
        (item.isActive || item.subItems?.some((subItem) => subItem.isActive));
    }

    return {
      fontWeight: showActive ? "semibold" : "medium",
      bg: showActive ? "whiteAlpha.300" : undefined,
    };
  };

  let expandedContent = (
    <Flex
      alignItems="center"
      px={{ base: "1.5", lg: "2" }}
      py={{ base: "2", lg: "0.5" }}
      flex="1"
    >
      <UpgradeTag show={showUpgrade} left="-6.66px" bottom="0">
        <Icon
          as={icon}
          strokeWidth="1.5"
          boxSize="6"
          m={{ base: "0", lg: "2.5" }}
          ml={{ base: "0", lg: "2" }}
        />
      </UpgradeTag>
      <Text ml={{ base: "4", lg: "2" }} {...textStyles}>
        {label}
      </Text>
      <Box ml="auto">
        {endSlot}
        {subItems && <AccordionIcon mr="2" />}
      </Box>
    </Flex>
  );

  const accordionBugfix = useAccordionBugfix(isExpanded);

  /**
   * Wrap `expandedContent` in a link if `to` was provided, else wrap it
   * in an accordion if `subItems` was provided
   */
  if (navItem.to) {
    expandedContent = (
      <RouterLinkBox
        to={navItem.to}
        target={target}
        isExternal={isExternal}
        onClick={onClick}
        {...interactionStyles}
        {...activeStyles(navItem)}
        {...idAttributes(navItem)}
      >
        {expandedContent}
      </RouterLinkBox>
    );
  } else if (subItems) {
    expandedContent = (
      <Accordion
        {...accordionBugfix}
        allowToggle
        defaultIndex={isExpanded ? 0 : undefined}
        onChange={(idx) => {
          if (idx === -1) onCollapse?.();
          else onExpand?.();
        }}
      >
        <AccordionItem border="none">
          <h2>
            <AccordionButton
              p="0"
              {...interactionStyles}
              {...activeStyles(navItem)}
              {...idAttributes(navItem)}
            >
              {expandedContent}
            </AccordionButton>
          </h2>
          <AccordionPanel p="0" motionProps={{ onUpdate: onChangeHeight }}>
            {subItems.map((item) => (
              <RouterLinkBox
                key={item.label}
                to={item.to}
                target={item.target}
                isExternal={item.isExternal}
                pl="16"
                py="2"
                onClick={onClick}
                {...interactionStyles}
                {...activeStyles(item)}
                {...textStyles}
                {...idAttributes(item)}
              >
                {item.label}
              </RouterLinkBox>
            ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  } else if (!navItem.static) {
    expandedContent = (
      <Box
        onClick={onClick}
        {...idAttributes(navItem)}
        {...interactionStyles}
        {...activeStyles(navItem)}
      >
        {expandedContent}
      </Box>
    );
  } else {
    expandedContent = (
      <Box onClick={onClick} {...idAttributes(navItem)}>
        {expandedContent}
      </Box>
    );
  }

  return (
    <AnimateExpansion
      isOpen={sidebarIsOpen}
      skipAnimationDelay={skipAnimationDelay}
      style={{ lineHeight: "0" }}
      collapsed={
        <Box
          w="100%"
          onClick={onClick}
          {...idAttributes(navItem)}
          {...interactionStyles}
          {...activeStyles(navItem)}
        >
          <UpgradeTag show={showUpgrade}>
            <Icon
              as={icon}
              strokeWidth="1.5"
              boxSize="6"
              my="3"
              ml="4"
              mr="auto"
            />
          </UpgradeTag>
        </Box>
      }
      expanded={expandedContent}
    />
  );
};

export default SidebarNavItem;

const UpgradeTag: React.FC<
  PropsWithChildren<{ show: boolean } & SystemProps>
> = ({ show, children, ...styles }) =>
  show ? (
    <Box
      pos="relative"
      pb="1"
      zIndex="-1"
      _after={{
        content: `"UPGRADE"`,
        pos: "absolute",
        bottom: "2px",
        left: "1.34px",
        fontSize: "2xs",
        fontWeight: "medium",
        lineHeight: "3",
        borderRadius: "sm",
        color: "white",
        bg: "teal.500",
        px: "0.5",
        ...styles,
      }}
    >
      {children}
    </Box>
  ) : (
    <>{children}</>
  );

const isSubNavItem = (item: NavItem | SubNavItem): item is SubNavItem =>
  (item as any).icon === undefined;
