import {
  AspectRatio,
  Box,
  Flex,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

import { AudioImage } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { CallMedia, Maybe } from "../../graphql";
import { VideoDisplayMode } from "../Interview";
import useMediaPlayer from "../Interview/useMediaPlayer";
import VideoControls from "../Interview/VideoControls";
import VideoPlayerProgressBar from "../Interview/VideoPlayerProgressBar";
import { Thumbnail } from "../Thumbnail";
import useVideoState from "./useVideoState";
import VideoPlayerBeta from "./VideoPlayerBeta";

type VideoWithThumbnailProps = {
  streamableVideo?: Maybe<Omit<CallMedia, "expiration">>;
  streamableAudio?: Maybe<Omit<CallMedia, "expiration">>;
  startTime?: number;
  thumbnailUrl?: string;
  audioOnly: boolean;
};

const VideoWithThumbnail: React.FC<VideoWithThumbnailProps> = ({
  streamableVideo,
  streamableAudio,
  startTime,
  thumbnailUrl,
  audioOnly,
}) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);
  const sendGAEvent = useSendGAEvent();
  const { width, height } = streamableVideo ?? {};
  const defaultAspectRatio = width && height ? width / height : 16 / 9;

  const [aspectRatio, setAspectRatio] = useState<number>(defaultAspectRatio);

  const { colors } = useTheme();
  const {
    isOpen: isHovering,
    onOpen: onMouseEnter,
    onClose: onMouseLeave,
  } = useDisclosure({
    // Disable hover tracking for mobile devices
    isOpen: isMobile ? false : undefined,
  });

  const onAspectRatio = (ar: number): void => {
    if (aspectRatio === undefined || ar > aspectRatio) {
      setAspectRatio(ar);
    }
  };

  const { videoCallbackRef, fullScreenControls, containerRef } = useVideoState(
    VideoDisplayMode.VIDEO
  );
  const [mediaPlayerRef, listeners, player] = useMediaPlayer({
    onLoadedMetadata(event) {
      const { videoWidth, videoHeight } = event.target as HTMLVideoElement;
      if (videoWidth && videoHeight) {
        onAspectRatio(videoWidth / videoHeight);
      }
    },
  });

  const { duration, seek } = player;

  useEffect(() => {
    if (duration && startTime && isVideoPlaying) {
      seek(startTime);
      player.play();
    } else {
      player.pause();
    }
  }, [duration, startTime, seek, isVideoPlaying]);

  const url = streamableVideo?.url;
  const [mediaSrc, setMediaSrc] = useState(url);
  if (mediaSrc?.split("?")[0] !== url?.split("?")[0]) {
    setMediaSrc(url);
  }

  return (
    <>
      {!isVideoPlaying && (
        <Thumbnail
          imageUrl={thumbnailUrl}
          audioOnly={audioOnly}
          aspectRatio={aspectRatio}
          time={startTime}
          onClick={() => {
            setIsVideoPlaying(true);
            sendGAEvent("play_highlight", "candidate");
          }}
        />
      )}
      {!audioOnly && (
        <AspectRatio ratio={aspectRatio} hidden={!isVideoPlaying}>
          <Flex
            ref={containerRef}
            direction="column"
            bg="black"
            width="100%"
            borderRadius={8}
            overflow="hidden"
            position="relative"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <VideoPlayerBeta
              mediaPlayerRef={mediaPlayerRef}
              videoRef={videoCallbackRef}
              src={mediaSrc}
              height="100%"
              isVideoVisible={isVideoPlaying}
              preload="metadata"
              containerStyle={{
                borderRadius: "inherit",
              }}
              {...listeners}
            />
            {isHovering && (
              <Box
                position="absolute"
                bottom="0"
                left="2"
                right="2"
                bg={`linear-gradient(transparent, ${colors.blackAlpha[800]})`}
              >
                <VideoControls
                  player={player}
                  isVideoVisible={isVideoPlaying}
                  fullScreenControls={fullScreenControls}
                  zIndex="3"
                  h="10"
                />
                <VideoPlayerProgressBar
                  duration={duration ?? 0}
                  value={player.time}
                  seek={seek}
                  disabled={!duration}
                />
              </Box>
            )}
          </Flex>
        </AspectRatio>
      )}
      {audioOnly && isVideoPlaying && (
        <Flex
          height="220px"
          position="relative"
          backgroundImage="/static/images/rectangle.png"
          backgroundSize="cover"
          alignItems="center"
          justifyContent="center"
        >
          <AudioImage width="100%" height="100%" style={{ color: "white" }} />
          <audio
            ref={mediaPlayerRef}
            src={streamableAudio?.url}
            {...listeners}
          />
          <Box
            pos="absolute"
            bottom="0"
            width="100%"
            px="2"
            bg="linear-gradient(transparent 0%, black 50%)"
          >
            <VideoControls isVideoVisible player={player} h="10" />
            <VideoPlayerProgressBar
              duration={duration ?? 0}
              value={player.time}
              seek={seek}
              disabled={!duration}
            />
          </Box>
        </Flex>
      )}
    </>
  );
};

export default VideoWithThumbnail;
