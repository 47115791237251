import React from "react";

import { Alert } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import BasicAlertDialog from "../../components/BasicAlertDialog";
import {
  CallGuideCue,
  CallGuideDocument,
  CallGuideQuery,
  useDeleteCallGuideCueMutation,
} from "../../graphql";

type Cue = Pick<CallGuideCue, "id">;

interface DeleteCueDialogProps {
  cue: Cue;
  callGuideId: string;
  onClose: () => void;
}

const DeleteCueDialog: React.FC<DeleteCueDialogProps> = ({
  cue,
  callGuideId,
  onClose,
}) => {
  const sendGAEvent = useSendGAEvent();
  const [deleteCue, { error, loading }] = useDeleteCallGuideCueMutation({
    update(cache, { data: deleteCallGuideCueData }) {
      const deletedCueId =
        deleteCallGuideCueData?.deleteCallGuideCue?.callGuideCue.id;
      if (deletedCueId) {
        const cachedData = cache.readQuery<CallGuideQuery>({
          query: CallGuideDocument,
          variables: { id: callGuideId },
        });
        if (!cachedData?.callGuide?.cues) {
          return;
        }
        const updatedCallGuide = {
          ...cachedData,
          callGuide: {
            ...cachedData.callGuide,
            cues: cachedData.callGuide.cues.filter((cue) => {
              return cue.id !== deletedCueId;
            }),
          },
        };
        cache.writeQuery<CallGuideQuery>({
          query: CallGuideDocument,
          variables: { id: callGuideId },
          data: updatedCallGuide,
        });
      }
    },
  });

  return (
    <BasicAlertDialog
      title="Delete this question?"
      body={
        <>
          {error ? (
            <Alert
              status="error"
              description="Error deleting question"
              reload
            />
          ) : (
            "This cannot be undone."
          )}
        </>
      }
      confirmLabel="Delete"
      isSubmitting={loading}
      onCancel={() => onClose()}
      onConfirm={async () => {
        sendGAEvent("cue", "call_guides", "delete");
        await deleteCue({ variables: { id: cue.id } });
        onClose();
      }}
    />
  );
};

export default DeleteCueDialog;
