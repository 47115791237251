import { ApolloError } from "@apollo/client";

import {
  AiNoteHighlight,
  CallHighlightFragment,
  CallNoteHighlight,
  CallNoteType,
  CandidateHighlightFragment,
  CandidateInterviewFragment,
  ScheduledInterviewListItemFragment,
} from "../../graphql";
import { CallHighlight, CandidateCall } from "./types";

/**
 * Adds the highlights from `highlightCalls` to `calls`.
 *
 * `highlightCalls` and `calls` should be two lists representing data
 * from the same calls
 */
export const mergeHighlightsWithCalls = (
  highlightCalls?: CandidateHighlightFragment[],
  calls?: CandidateInterviewFragment[]
): CandidateCall[] | undefined =>
  calls?.map((call) => {
    const highlightCall = highlightCalls?.find((hc) => hc.id === call.id);
    return { ...call, highlights: highlightCall?.highlights ?? [] };
  });

export const hasError = (
  error: ApolloError | undefined,
  code: string
): boolean => {
  return error?.graphQLErrors.some((e) => e.extensions?.code === code) ?? false;
};

export const getHighlightSearchString = (highlight: CallHighlight): string => {
  return `${getCallSearchString(highlight.call)}${
    highlight.text
  }`.toLocaleLowerCase();
};

export const getCallSearchString = (
  call: CandidateInterviewFragment
): string => {
  return `${getCallName(call)}${call.interviewers
    .map((i) => i.fullName)
    .join("")}`.toLocaleLowerCase();
};

export const getCallName = (call: CandidateInterviewFragment): string => {
  return (
    call.name ||
    `Interview with ${call.interviewers.map((i) => i.fullName).join(",")}`
  );
};

export const getScheduledCallName = (
  scheduledInterview: ScheduledInterviewListItemFragment
): string => {
  return (
    scheduledInterview.ghJobStageName ||
    scheduledInterview.leverStage?.text ||
    scheduledInterview.name ||
    `Interview with ${scheduledInterview.scheduledInterviewers
      .map((i) => i.name ?? i.user?.fullName ?? "")
      .join(",")}`
  );
};

export const isAiNoteHighlight = (
  highlight: Pick<CallHighlightFragment, "__typename">
): highlight is AiNoteHighlight =>
  // eslint-disable-next-line no-underscore-dangle
  highlight.__typename === "AiNoteHighlight";

export const isNoteHighlight = (
  highlight: Pick<CallHighlightFragment, "__typename">
): highlight is CallNoteHighlight =>
  // eslint-disable-next-line no-underscore-dangle
  highlight.__typename === "CallNoteHighlight";

export const isGuideHighlight = (
  highlight: CallHighlightFragment
): highlight is CallNoteHighlight =>
  // eslint-disable-next-line no-underscore-dangle
  isNoteHighlight(highlight) && highlight.type === CallNoteType.Cue;

// TAGS
// See tags_list in backend
export const getTagDisplayText = (tag: string): string => {
  switch (tag) {
    case "Background walk-through":
      return "Background walk-through";
    case "Experience questions":
      return "Experience";
    case "Hard skill questions":
      return "Hard skills";
    case "Soft skill questions":
      return "Transferrable skills";
    case "Situational questions":
      return "Situational examples";
    case "Candidate preferences":
      return "Candidate preferences";
    default:
      return "Other";
  }
};
