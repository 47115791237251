import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";
import { IoLink } from "react-icons/io5";

import { useToast } from "../../../components";
import { copy } from "../../../utils/clipboard";
import { useSendGAEvent } from "../../../utils/googleAnalytics";

type CopyCallLinkButtonProps = ButtonProps & {
  callId: string;
  clipId?: string | null;
};

const CopyCallLinkButton: React.FC<CopyCallLinkButtonProps> = ({
  onClick,
  callId,
  clipId,
  ...buttonProps
}) => {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();
  const showShareToast: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (!clipId) sendGAEvent("sharing", "call_review", "call_link");
    let url = `${window.location.protocol}//${window.location.host}/interview/${callId}`;
    if (clipId) url += `/clip/${clipId}`;
    copy(url);
    toast({
      title: `${clipId ? "Clip" : "Interview"} link copied to clipboard`,
      status: "success",
    });
    onClick?.(e);
  };

  return (
    <Button
      variant="ghost"
      py="2.5"
      px="3"
      fontWeight="medium"
      fontSize="sm"
      leftIcon={<IoLink size={20} />}
      {...buttonProps}
      onClick={showShareToast}
    >
      Copy link
    </Button>
  );
};

export default CopyCallLinkButton;
