import {
  Box,
  Link,
  ListItem,
  Text,
  Tooltip,
  UnorderedList,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";

import MonospacedText from "../../../../MonospacedText";
import { NoteFormat, NoteLength, NoteMap } from "./types";
import { getTextByLength } from "./utils";

const MAX_TIMESTAMP_COUNT = 3;

const EllipsNote: React.FC<{
  noteId: string;
  startCollapsed: boolean;
  timestampElements: Map<string, JSX.Element>;
  ellipsisElements: Map<string, string>;
}> = ({ noteId, startCollapsed, ellipsisElements, timestampElements }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(startCollapsed);

  return (
    <Text as="span" verticalAlign="top" fontSize="xs" pr="4px">
      {isCollapsed ? (
        <Box as="span">
          {" "}
          <Tooltip fontSize="xs" label="See all references">
            <Link
              fontWeight="normal"
              onClick={(e) => {
                e.preventDefault();
                setIsCollapsed(false);
              }}
              pt={2}
            >
              <MonospacedText
                display="inline-block"
                verticalAlign="super"
                fontSize="0.6rem"
                text={ellipsisElements.get(noteId) || "see notes"}
              />
            </Link>
          </Tooltip>
        </Box>
      ) : (
        timestampElements.get(noteId)
      )}
    </Text>
  );
};

const ParagraphFormat: React.FC<{
  callId: string;
  hoverId: string | null;
  notesMap: NoteMap;
  selectedNotes: string[];
  noteLength: NoteLength;
  onClickTimestamp(t: number): void;
  individualLengths: { [key: string]: NoteLength };
}> = ({
  callId,
  notesMap,
  selectedNotes,
  noteLength,
  onClickTimestamp,
  hoverId,
  individualLengths,
}) => {
  let idx = 0;

  const filteredNotes = useMemo(
    () =>
      Array.from(selectedNotes).filter((s) => {
        return !!notesMap[s]?.note.text;
      }),
    [notesMap, selectedNotes]
  );

  // Need to pre-construct the full timepstamp elements so that the numbering
  // is correct, regardless if they are collapsed or not
  const { timestampElements, ellipsisElements } = useMemo(() => {
    const timestampElements: Map<string, JSX.Element> = new Map();
    const ellipsisElements: Map<string, string> = new Map();
    filteredNotes.forEach((s) => {
      const note = notesMap[s];
      const startIdx = idx + 1;
      // Full element
      timestampElements.set(
        note.note.id,
        <Box as="span">
          {note.note.startTimes
            .filter((s) => !!s)
            .map((startTime) => {
              const st = startTime ?? 0;
              idx += 1;
              return (
                <React.Fragment key={idx}>
                  {idx > 0 && " "}
                  <Tooltip fontSize="xs" label="Watch this segment">
                    <Link
                      fontWeight="normal"
                      href={`/interview/${callId}?t=${st}`}
                      onClick={(e) => {
                        e.preventDefault();
                        onClickTimestamp(st);
                      }}
                      pt={2}
                    >
                      <MonospacedText
                        display="inline-block"
                        verticalAlign="super"
                        fontSize="0.6rem"
                        text={idx.toString()}
                      />
                    </Link>
                  </Tooltip>
                </React.Fragment>
              );
            })}
        </Box>
      );
      // Collapsed element text
      ellipsisElements.set(note.note.id, `${startIdx}...${idx}`);
    });
    return { timestampElements, ellipsisElements };
  }, [filteredNotes]);

  return (
    <Box pb="16px">
      {filteredNotes.map((s, nidx) => {
        const note = notesMap[s];
        return (
          <Box
            display="inline"
            as="span"
            key={note.note.id}
            bg={note.note.id === hoverId ? "blue.50" : ""}
            color="gray.700"
          >
            <Text as="span">
              {getTextByLength(
                note.note,
                individualLengths[note.note.id] ?? noteLength
              )}
            </Text>
            <EllipsNote
              noteId={note.note.id}
              startCollapsed={note.note.startTimes.length > MAX_TIMESTAMP_COUNT}
              timestampElements={timestampElements}
              ellipsisElements={ellipsisElements}
            />
          </Box>
        );
      })}
    </Box>
  );
};

const BulletFormat: React.FC<{
  callId: string;
  hoverId: string | null;
  notesMap: NoteMap;
  selectedNotes: string[];
  noteLength: NoteLength;
  onClickTimestamp(t: number): void;
  individualLengths: { [key: string]: NoteLength };
}> = ({
  callId,
  notesMap,
  selectedNotes,
  noteLength,
  onClickTimestamp,
  hoverId,
  individualLengths,
}) => {
  let idx = 0;

  const filteredNotes = useMemo(
    () =>
      Array.from(selectedNotes).filter((s) => {
        return !!notesMap[s].note.text;
      }),
    [notesMap, selectedNotes]
  );

  // Need to pre-construct the full timepstamp elements so that the numbering
  // is correct, regardless if they are collapsed or not
  const timestampElements: Map<string, JSX.Element> = new Map();
  const ellipsisElements: Map<string, string> = new Map();
  filteredNotes.forEach((s) => {
    const note = notesMap[s];
    const startIdx = idx + 1;
    // Full element
    timestampElements.set(
      note.note.id,
      <Box as="span">
        {note.note.startTimes
          .filter((s) => !!s)
          .map((startTime) => {
            const st = startTime ?? 0;
            idx += 1;
            return (
              <React.Fragment key={idx}>
                {idx > 0 && " "}
                <Tooltip fontSize="xs" label="Watch this segment">
                  <Link
                    fontWeight="normal"
                    href={`/interview/${callId}?t=${st}`}
                    onClick={(e) => {
                      e.preventDefault();
                      onClickTimestamp(st);
                    }}
                    pt={2}
                  >
                    <MonospacedText
                      display="inline-block"
                      verticalAlign="super"
                      fontSize="0.6rem"
                      text={idx.toString()}
                    />
                  </Link>
                </Tooltip>
              </React.Fragment>
            );
          })}
      </Box>
    );
    // Collapsed element text
    ellipsisElements.set(note.note.id, `${startIdx}...${idx}`);
  });

  return (
    <Box pb="16px">
      {filteredNotes.map((s) => {
        const note = notesMap[s];
        const noteText = getTextByLength(
          note.note,
          individualLengths[note.note.id] ?? noteLength
        );
        // Splitting with space prevents splitting on acronyms
        const bullets = noteText
          .split(". ")
          .filter((s) => {
            if (s.trim()) {
              return true;
            }
            return false;
          })
          .map((s) => {
            if (!s.endsWith(".")) {
              return s.concat(".");
            }
            return s;
          });

        return (
          <Box
            key={note.note.id}
            py={2}
            bg={note.note.id === hoverId ? "blue.50" : ""}
            color="gray.700"
          >
            <Text as="span" pr={1}>
              <Box as="span" fontWeight={600}>
                {note.note.tag ?? note.sectionTitle}
              </Box>
              <EllipsNote
                noteId={note.note.id}
                startCollapsed={
                  note.note.startTimes.length > MAX_TIMESTAMP_COUNT
                }
                timestampElements={timestampElements}
                ellipsisElements={ellipsisElements}
              />
            </Text>
            <UnorderedList>
              {bullets.map((b) => {
                return <ListItem key={b}>{b}</ListItem>;
              })}
            </UnorderedList>
          </Box>
        );
      })}
    </Box>
  );
};

export const SummaryContent: React.FC<{
  callId: string;
  hoverId: string | null;
  notesMap: NoteMap;
  selectedNotes: string[];
  noteLength: NoteLength;
  noteFormat: NoteFormat;
  onClickTimestamp(t: number): void;
  individualLengths: { [key: string]: NoteLength };
}> = ({
  callId,
  hoverId,
  notesMap,
  selectedNotes,
  noteLength,
  noteFormat,
  onClickTimestamp,
  individualLengths,
}) => {
  return (
    <>
      {noteFormat === NoteFormat.Paragraph ? (
        <ParagraphFormat
          callId={callId}
          hoverId={hoverId}
          notesMap={notesMap}
          selectedNotes={selectedNotes}
          noteLength={noteLength}
          onClickTimestamp={onClickTimestamp}
          individualLengths={individualLengths}
        />
      ) : (
        <BulletFormat
          callId={callId}
          hoverId={hoverId}
          notesMap={notesMap}
          selectedNotes={selectedNotes}
          noteLength={noteLength}
          onClickTimestamp={onClickTimestamp}
          individualLengths={individualLengths}
        />
      )}
    </>
  );
};
