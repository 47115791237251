import invariant from "invariant";

import { iconForNote } from "../main/components/CallNotes/utils";
import {
  AiQuestionNoteAnswerItem,
  Ats,
  CallNoteType,
  LeverFeedbackTemplateFieldTypes,
  NotePartsFragment,
  NotesForScorecardQuery,
  NotesForScorecardV3Query,
} from "../main/graphql";
import { buildCallTimestampLink, buildNoteTimestampLink } from "./call";
import { formatDuration } from "./datetime";

export const isSupportedAts = (ats: Ats): boolean =>
  [Ats.Greenhouse, Ats.Lever, Ats.Ashby].includes(ats);

/** Used to differentiate `NotesForScorecardQuery` from `NotesForScorecardV3Query` */
const isV3Call = (call: any): call is NotesForScorecardV3Query["call"] =>
  !!call?.scorecard;

interface CueNotes {
  description?: string;
  cues: string[];
  notes: string[];
}

interface ExtensionNote {
  /**
   * The total number of notes for the section
   */
  count: number;
  /**
   * All notes as a string, to be pasted into a scorecard section
   */
  text: string;
  /**
   * All notes as a string, to be pasted in the general notes
   * section when the note cannot be matched to a scorecard section
   */
  generalText: string;
}

interface QuestionNotes {
  id: string;
  text: string;
  notes: ExtensionNote;
}

export interface AlertConfig {
  type: "success" | "info";
  title: string;
  body?: string;
  action?: {
    actionName: string;
    displayText: string;
  };
  afterAction?: {
    title: string;
    body: string;
  };
  openDelay?: number;
  duration?: number;
}

export interface FillScorecardParams {
  call: NotesForScorecardQuery["call"] | NotesForScorecardV3Query["call"];
  ats: Ats;
  autofill: boolean;
  showConfetti: boolean;
}

export interface FillScorecardPayload {
  generalNotes: ExtensionNote;
  questionNotes: QuestionNotes[];
  autofill: boolean;
  showConfetti: boolean;
}

export interface FillScorecardResponse {
  /**
   * The total number of scorecard / feedback form fields present on the page
   */
  numSections: number;
  /**
   * The number of scorecard / feedback form fields that were
   * able to be matched to call notes
   */
  numMatched: number;
  /**
   * The number of scorecard / feedback form fields that were not
   * able to be matched to call notes
   */
  numUnmatched: number;
  /**
   * The data sent to the extension
   */
  scorecardData: FillScorecardPayload;
}

export function getScorecardData({
  call,
  ats,
  autofill,
  showConfetti,
}: FillScorecardParams): FillScorecardPayload {
  const isGreenhouse = ats === Ats.Greenhouse;
  const isLever = ats === Ats.Lever;
  const isAshby = ats === Ats.Ashby;

  invariant(
    isSupportedAts(ats),
    `Only Greenhouse, Lever, and Ashby are supported, got "${ats}"`
  );

  const isHtmlSupported = isGreenhouse || isAshby;

  const callId = call?.id;
  const generalNotes = gatherNotes(call?.generalNotes);
  const aiScorecardQuestionNotes = call?.aiScorecardQuestionNotes || [];
  let questionNotes: QuestionNotes[];

  if (isV3Call(call)) {
    questionNotes = gatherScorecardNotes(call?.scorecard);
  } else {
    questionNotes = gatherQuestionNotes(call?.questions ?? []);
  }

  handleLeverGeneralNotes();

  return {
    generalNotes,
    questionNotes,
    autofill,
    showConfetti,
  };

  /**
   * Since Lever does not have a "General Notes" section, we put
   * general notes into the first fillable question, if available
   */
  function handleLeverGeneralNotes(): void {
    if (!isLever) return;
    const questions =
      (isV3Call(call) ? call?.scorecard?.questions : call.questions) ?? [];
    const firstPasteableIdx = questions.findIndex(
      (q) => q.leverFieldType === LeverFeedbackTemplateFieldTypes.Textarea
    );

    if (firstPasteableIdx !== -1) {
      const { notes: firstQuestionNotes } = questionNotes[firstPasteableIdx];
      firstQuestionNotes.count += generalNotes.count;
      const separator = "---";
      firstQuestionNotes.text = [
        "General Notes:",
        generalNotes.text,
        separator,
        firstQuestionNotes.text,
      ].join("\n\n");
    }
  }

  function gatherNotes(
    notes: NotePartsFragment[] | undefined,
    timestamp?: number | null,
    description?: string,
    isAtsSingleLineInput?: boolean
  ): ExtensionNote {
    const result: CueNotes = { description, cues: [], notes: [] };

    if (typeof timestamp === "number") {
      result.cues.push(
        isHtmlSupported
          ? buildNoteTimestampLink({ time: timestamp, callId })
          : formatDuration(timestamp)
      );
    }

    (notes || []).forEach((note) => {
      const time = formatDuration(note.time);

      if (note.type === CallNoteType.Cue) {
        result.cues.push(isHtmlSupported ? buildNoteTimestampLink(note) : time);
      } else {
        const link = buildNoteTimestampLink(note);
        const icon = iconForNote(note);
        const { text } = note;

        const join = (parts: string[], sep: string): string =>
          parts.filter((part) => !!part).join(sep);

        result.notes.push(
          isHtmlSupported
            ? join(["•", icon, text, link], "&nbsp;&nbsp;")
            : join([isAtsSingleLineInput ? "" : "•", icon, text], "  ")
        );
      }
    });

    return buildExtensionNote(result, isAtsSingleLineInput);
  }

  function gatherAiNoteAnswerItems(
    notes: Pick<AiQuestionNoteAnswerItem, "id" | "currentText">[],
    questionHeader: string,
    startTime: number,
    isAtsSingleLineInput: boolean,
    callId?: string
  ): ExtensionNote {
    const result: CueNotes = {
      description: questionHeader,
      cues: [],
      notes: [],
    };

    if (notes.length > 0) {
      const join = (parts: string[], sep: string): string =>
        parts.filter((part) => !!part).join(sep);

      const questionHeaderItems = ["✨", questionHeader];
      if (callId) {
        const link = buildCallTimestampLink(callId, startTime);
        const time = formatDuration(startTime);
        questionHeaderItems.push(isHtmlSupported ? link : time);
      }
      result.notes.push(
        isHtmlSupported
          ? join(questionHeaderItems, "&nbsp;&nbsp;")
          : join(questionHeaderItems, "  ")
      );
      notes.forEach((note) => {
        const text = note.currentText;

        result.notes.push(
          isHtmlSupported
            ? join(["•", text], "&nbsp;&nbsp;")
            : join([isAtsSingleLineInput ? "" : "•", text], "  ")
        );
      });
    }

    return buildExtensionNote(result, isAtsSingleLineInput, true);
  }

  function gatherQuestionNotes(
    questions: NonNullable<NotesForScorecardQuery["call"]>["questions"]
  ): QuestionNotes[] {
    const resultMap = new Map<string, QuestionNotes>();
    const result: QuestionNotes[] = questions.map((question) => {
      const questionNote: QuestionNotes = {
        id: question.id,
        notes: gatherNotes(
          question.questionNotes,
          null,
          question.description ?? undefined,
          question.isAtsSingleLineInput
        ),
        text: getQuestionText(question.description ?? ""),
      };
      resultMap.set(questionNote.text, questionNote);
      return questionNote;
    });

    mergeAiScorecardQuestionNotes(result, resultMap);

    return result;
  }

  function gatherScorecardNotes(
    scorecard: NonNullable<NotesForScorecardV3Query["call"]>["scorecard"]
  ): QuestionNotes[] {
    const resultMap = new Map<string, QuestionNotes>();
    const result: QuestionNotes[] =
      scorecard?.questions.map((question) => {
        const questionNote: QuestionNotes = {
          id: question.id,
          notes: gatherNotes(
            question.questionNotes,
            question.marked ? question.markedTime : undefined,
            question.itemText,
            question.isAtsSingleLineInput
          ),
          text: getQuestionText(question.itemText ?? ""),
        };
        resultMap.set(questionNote.text, questionNote);
        return questionNote;
      }) ?? [];

    mergeAiScorecardQuestionNotes(result, resultMap);

    return result;
  }

  function mergeAiScorecardQuestionNotes(
    result: QuestionNotes[],
    resultMap: Map<string, QuestionNotes>
  ): void {
    aiScorecardQuestionNotes.forEach((note) => {
      const questionNote: QuestionNotes = {
        id: note.id,
        notes: gatherAiNoteAnswerItems(
          note.callAiNoteAnswerItems,
          note.aiQuestion ?? "",
          note.startTime,
          note.isAtsSingleLineInput,
          callId
        ),
        text: getQuestionText(note.scorecardQuestion ?? ""),
      };

      // merge with existing notes with the same Scorecard Question
      const currentQuestionNote = resultMap.get(questionNote.text);
      if (!currentQuestionNote) {
        resultMap.set(questionNote.text, questionNote);
        result.push(questionNote);
      } else {
        // Update in place current note
        currentQuestionNote.notes.count += questionNote.notes.count;
        if (currentQuestionNote.notes.text === "") {
          currentQuestionNote.notes.text = questionNote.notes.text;
        } else {
          currentQuestionNote.notes.text =
            currentQuestionNote.notes.text.concat(
              isHtmlSupported
                ? "<br><br>"
                : note.isAtsSingleLineInput
                ? " | "
                : "\n\n",
              questionNote.notes.text
            );
        }
        if (currentQuestionNote.notes.generalText === "") {
          currentQuestionNote.notes.generalText =
            questionNote.notes.generalText;
        } else {
          currentQuestionNote.notes.generalText =
            currentQuestionNote.notes.generalText.concat(
              isHtmlSupported
                ? "<br><br>"
                : note.isAtsSingleLineInput
                ? " | "
                : "\n\n",
              questionNote.notes.generalText
            );
        }
      }
    });
  }

  function buildExtensionNote(
    { cues, notes, description }: CueNotes,
    isAtsSingleLineInput?: boolean,
    questionIncludedInNotes?: boolean
  ): ExtensionNote {
    const result: ExtensionNote = { count: 0, text: "", generalText: "" };

    if (cues.length > 0 && isHtmlSupported) {
      const cueText = `${cues.join(", ")}`;
      result.text += `Topic started at ${cueText}<br><br>`;

      if (description) {
        result.generalText += `"${description}" started at ${cueText}<br>`;
      } else {
        result.generalText += `Topic started at ${cueText}<br>`;
      }

      result.count += cues.length;
    }

    if (notes.length > 0) {
      const noteText = notes.join(
        isHtmlSupported ? "<br>" : isAtsSingleLineInput ? " | " : "\n"
      );
      result.text += noteText;
      result.generalText += noteText;
      result.count += notes.length;
    }

    // Do not want to include the question in the note count
    if (questionIncludedInNotes) {
      result.count -= 1;
    }

    return result;
  }
}
const dummyEl = document.createElement("span");
const getQuestionText = (description: string): string => {
  dummyEl.innerText = description;
  return dummyEl.innerText;
};

/**
 * Alert configs
 */

// Helper functions
const itemNameForAts = (ats: Ats): string =>
  ats === Ats.Greenhouse ? "scorecard" : "feedback form";
const extraNoteLocationForAts = (ats: Ats): string =>
  ats === Ats.Greenhouse
    ? "general notes section, if available"
    : "first available notes section";

/**
 * The alert to be shown when no matching call is found for the scorecard
 */
export const cantFindMatchingCallAlertConfig = (ats: Ats): AlertConfig => {
  const itemName = itemNameForAts(ats);
  return {
    type: "info",
    title: `To import BrightHire notes to this ${itemName}, find and select the corresponding interview.`,
    action: {
      displayText: "Open the BrightHire panel",
      actionName: "openDrawer",
    },
    afterAction: {
      title: `Select the interview that is associated with this ${itemName}.`,
      body: `Use the panel on the right.`,
    },
    body: `on the right and select the interview related to this ${itemName}.`,
  };
};

/**
 * The alert to be shown when the selected call matches 0 scorecard fields
 */
export const noFieldsMatchAlertConfig = (ats: Ats): AlertConfig => {
  const itemName = itemNameForAts(ats);
  const extraNotesLocation = extraNoteLocationForAts(ats);

  return {
    type: "info",
    title: `BrightHire is unable to match the ${itemName} questions to the interview notes.`,
    body: `Pressing 'Import notes' will paste any notes to the ${extraNotesLocation}`,
  };
};

/**
 * The alert to be shown when the selected call matches some, but not all, scorecard fields
 */
export const someFieldsMatchAlertConfig = (
  ats: Ats,
  numMatched: number
): AlertConfig => {
  const itemName = itemNameForAts(ats);
  const extraNotesLocation = extraNoteLocationForAts(ats);

  return {
    type: "info",
    title: `BrightHire is able to match ${numMatched} ${itemName} question${
      numMatched === 1 ? "" : "s"
    } to available interview notes.`,
    body: `Pressing 'Import notes' will paste all notes to their matching
    ${itemName} questions and any unmatched notes will be pasted to the ${extraNotesLocation}, if available.`,
  };
};

/**
 * The alert to be shown when the selected call matches all scorecard fields
 */
export const allFieldsMatchAlertConfig = (ats: Ats): AlertConfig => {
  const itemName = itemNameForAts(ats);

  return {
    type: "success",
    title: `BrightHire can now import notes to your ${itemName}.`,
  };
};

/**
 * The alert to be shown when we have a match but the call has no notes
 */
export const allFieldsMatchButNoUserNotesAlertConfig = (
  ats: Ats
): AlertConfig => {
  const itemName = itemNameForAts(ats);

  return {
    type: "info",
    title: "There are no notes to import",
    body: `To have BrightHire complete your ${itemName} automatically,
    use the Interview Assistant to take notes during your interview.`,
  };
};

/**
 * The alert to be shown after pasting when no notes match
 */
export const postFillNoneMatchAlertConfig = (ats: Ats): AlertConfig => {
  const itemName = itemNameForAts(ats);
  const extraNotesLocation = extraNoteLocationForAts(ats);

  return {
    type: "success",
    title: "All notes were imported successfully!",
    body: `All notes were pasted to the ${extraNotesLocation}.
    Always review your ${itemName} for accuracy before submitting it.`,
  };
};
/**
 * The alert to be shown after pasting a partial match of notes
 */
export const postFillPartialMatchAlertConfig = (
  ats: Ats,
  numSections: number,
  numMatched: number
): AlertConfig => {
  const itemName = itemNameForAts(ats);
  const extraNotesLocation = extraNoteLocationForAts(ats);

  return {
    type: "success",
    title: `We've filled out ${numMatched} of ${numSections} question${
      numSections === 1 ? "" : "s"
    } for you.`,
    body: `Any unmatched notes will be pasted to the ${extraNotesLocation}.
    Always review your ${itemName} for accuracy before submitting it.`,
  };
};

/**
 * The alert to be shown after pasting a full match of notes
 */
export const postFillFullMatchAlertConfig = (ats: Ats): AlertConfig => {
  const itemName = itemNameForAts(ats);

  return {
    type: "success",
    title: `All notes were imported successfully!`,
    body: `Always review your ${itemName} for accuracy before submitting it.`,
  };
};
