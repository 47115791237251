import React, { useEffect } from "react";

import { useIsSmallScreen } from "../../../../hooks/useIsSmallScreen";
import {
  useExternalRecordingTrackersBetaQuery,
  useExternalRecordingTrackerSegmentBetaLazyQuery,
} from "../../../graphql";
import { useExternalShareParams } from "../../../pages/external-share/useExternalShareParams";
import {
  SidebarTab,
  SidebarTabs,
  TrackersTab,
  Transcript,
} from "../../Recording";
import { MediaPlayerInterface } from "../useMediaPlayer";
import ExternalRecordingTopicsTab from "./TopicsTab";
import { ExternalRecording } from "./types";

export enum ExternalRecordingTab {
  TRANSCRIPT = "transcript",
  TOPICS = "topics",
  POIS = "trackers",
}

type ExternalRecordingSidebarProps = {
  recording: ExternalRecording;
  currentTab: ExternalRecordingTab;
  player: MediaPlayerInterface;
  autoScrollEnabled: boolean;
  setAutoScrollEnabled(enabled: boolean): void;
  onTabChange?(t: ExternalRecordingTab): void;
  onSeek(t: number): void;
  onUserChangeTab?(): void;
  isVideoVisible?: boolean;
  hideVideo(): void;
  focusVideo(): void;
  isVideoCall?: boolean;
};

const ExternalRecordingSidebar: React.FC<ExternalRecordingSidebarProps> = ({
  recording,
  currentTab,
  player,
  autoScrollEnabled,
  isVideoCall,
  isVideoVisible,
  hideVideo,
  focusVideo,
  setAutoScrollEnabled,
  onTabChange,
  onUserChangeTab,
  onSeek,
}) => {
  const { shareId, shareType, index } = useExternalShareParams();
  const isSmallScreen = useIsSmallScreen();
  // Prepare external recording POIs
  const trackersQuery = useExternalRecordingTrackersBetaQuery({
    variables: { id: shareId, shareType, index },
  });
  const [getTrackerSegments, trackerSegmentsQuery] =
    useExternalRecordingTrackerSegmentBetaLazyQuery();

  // Tab change logic
  useEffect(() => {
    if (isSmallScreen) {
      onTabChange?.(ExternalRecordingTab.TRANSCRIPT);
    } else if (currentTab === ExternalRecordingTab.TRANSCRIPT) {
      onTabChange?.(ExternalRecordingTab.TOPICS);
    }
  }, [isSmallScreen]);
  return (
    <SidebarTabs
      currentTab={currentTab}
      onTabChange={(newTab: ExternalRecordingTab) => {
        if (newTab !== currentTab) onUserChangeTab?.();
        onTabChange?.(newTab);
      }}
    >
      {isSmallScreen && (
        <SidebarTab
          id={ExternalRecordingTab.TRANSCRIPT}
          displayName="Transcript"
        >
          <Transcript
            transcript={recording.transcript}
            speakers={recording.speakers}
            player={player}
            autoScrollEnabled={autoScrollEnabled}
            setAutoScrollEnabled={setAutoScrollEnabled}
            isVideoCall={isVideoCall}
            isVideoVisible={isVideoVisible}
            hideVideo={hideVideo}
            focusVideo={focusVideo}
            loading={false}
          />
        </SidebarTab>
      )}

      <SidebarTab id={ExternalRecordingTab.TOPICS} displayName="Notes">
        <ExternalRecordingTopicsTab topics={recording.topics} seek={onSeek} />
      </SidebarTab>

      <SidebarTab id={ExternalRecordingTab.POIS} displayName="POIs">
        <TrackersTab
          onSeek={onSeek}
          onTrackerGroupChange={(trackerGroupId) => {
            if (trackerGroupId) {
              getTrackerSegments({
                variables: { id: shareId, shareType, index, trackerGroupId },
              });
            }
          }}
          onTrackerKeywordChange={(trackerKeywordId) => {
            if (trackerKeywordId) {
              getTrackerSegments({
                variables: { id: shareId, shareType, index, trackerKeywordId },
              });
            }
          }}
          trackers={
            trackersQuery?.data?.externalShareView?.externalRecording
              .pointsOfInterest
          }
          trackerSegments={
            trackerSegmentsQuery.data?.externalShareView?.externalRecording
              ?.poiSegments
          }
          speakers={
            trackersQuery.data?.externalShareView?.externalRecording?.speakers
          }
          keywordsFetchData={trackersQuery}
          segmentsFetchData={trackerSegmentsQuery}
          speakerMenuDisabled
          doesCallHaveTranscript={recording.transcript.length > 0}
          transcriptLanguageCode={recording.transcriptLanguageCode ?? "en"}
        />
      </SidebarTab>
    </SidebarTabs>
  );
};

export default ExternalRecordingSidebar;
