import {
  Box,
  Divider,
  Flex,
  Icon,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { IconType } from "react-icons";
import { HiArrowDown, HiArrowUp } from "react-icons/hi";
import {
  IoChatbubbleSharp,
  IoHandRightSharp,
  IoHelpCircleSharp,
  IoSpeedometerSharp,
  IoTimeSharp,
} from "react-icons/io5";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { makeData } from "./utils";

const getKeyDisplayName = (key: string, metric?: string): string => {
  if (metric === "Questions Asked") {
    return key === "primary"
      ? "Your open-ended questions"
      : "Your closed-ended questions";
  }
  return key === "primary" ? "You" : "Secondary";
};
const metrics = [
  {
    name: "Talk Ratio",
    value: "55%",
    changeValence: "up",
    changeColor: "green.500",
    changeText: "1% - great work!",
    refAreaValues: [39, 60],
    data: makeData([
      [35, 55],
      [35, 53],
      [36, 54],
      [39, 52],
      [44, 53],
      [45, 55],
      [49, 55],
      [52, 55],
      [51, 56],
      [52, 55],
      [54, 56],
      [55, 56],
    ]),
    format: (tick: string) => `${tick}%`,
    units: "%",
    icon: IoChatbubbleSharp,
  },
  {
    name: "On-Time Starts",
    value: "85%",
    changeValence: "down",
    changeColor: "red.500",
    changeText: "2% - below suggested",
    refAreaValues: [95, 100],
    data: makeData([
      [77],
      [75],
      [74],
      [76],
      [80],
      [81],
      [81],
      [82],
      [85],
      [86],
      [87],
      [85],
    ]),
    format: (tick: string) => `${tick}%`,
    units: "%",
    icon: IoTimeSharp,
  },
  {
    name: "Questions Asked",
    value: "24",
    changeValence: "down",
    changeColor: "red.500",
    changeText: "6% - below suggested",
    refAreaValues: [17, 20],
    refLineValue: null,
    data: makeData([
      [7, 7],
      [6, 10],
      [7, 6],
      [8, 7],
      [9, 7],
      [11, 8],
      [16, 5],
      [16, 6],
      [15, 6],
      [18, 5],
      [17, 6],
      [16, 6],
    ]),
    format: (tick: string) => tick,
    units: "",
    icon: IoHelpCircleSharp,
  },
  {
    name: "Interruptions",
    value: "22",
    changeValence: "up",
    changeColor: "green.500",
    changeText: "5% - great work!",
    refAreaValues: [16, 23],
    refLineValue: null,
    data: makeData([
      [20],
      [21],
      [23],
      [22],
      [22],
      [26],
      [21],
      [22],
      [22],
      [21],
      [21],
      [22],
    ]),
    format: null,
    units: "",
    icon: IoHandRightSharp,
  },
  {
    name: "Speaking Rate",
    value: "208wpm",
    changeValence: "positive",
    changeText: "2% - above suggested",
    changeColor: "green.500",
    refAreaValues: [160, 197],
    data: makeData([
      [211],
      [215],
      [214],
      [214],
      [212],
      [211],
      [212],
      [204],
      [208],
      [212],
      [213],
      [208],
    ]),
    format: null,
    units: "wpm",
    icon: IoSpeedometerSharp,
  },
];

const AnalyticsMyAnalytics: React.FC = () => {
  return (
    <Flex
      flexDir="column"
      alignItems="flex-start"
      justifyContent="space-between"
      flexWrap="wrap"
      width="882px"
    >
      <Flex
        width="100%"
        flexDir="row"
        alignItems="baseline"
        mb="6"
        align="center"
        justify="space-between"
      >
        <Text fontSize="24px" fontWeight="700" color="gray.700" pr="2">
          My Insights
        </Text>
        <Flex direction="row" align="center" justify="center">
          <Text mr="2" whiteSpace="nowrap">
            Send to me:
          </Text>
          <Select bg="white">
            <option value="email">Daily</option>
            <option value="slack">Weekly</option>
            <option value="sms">Monthly</option>
          </Select>
        </Flex>
      </Flex>
      <Text fontWeight="semibold" color="gray.700" mb="2">
        Tips
      </Text>
      <Tips>
        <Box mb="2">
          <TipTag>Tip</TipTag>
          Your on-time starts are a little below average. Showing up on time is
          proven to improve candidate accept rates.
        </Box>
        <Divider my="2" />
        <Box mb="2">
          <TipTag>Tip</TipTag>
          You’re asking a few more closed-ended questions than recommended.
          While sometimes closed questions are important, open-ended questions
          give candidates more opportunity to share information and context.
        </Box>
      </Tips>
      <Text fontWeight="semibold" color="gray.700" mb="2">
        Metrics
      </Text>
      <Tabs variant="unstyled">
        <TabList mx="-1">
          {metrics.map((metric) => (
            <Tab
              mx="1"
              p="0"
              border="1px solid"
              borderColor="gray.200"
              key={metric.name}
              _selected={{ bg: "blue.200", borderColor: "blue.500" }}
              borderRadius="base"
            >
              <MyAnalyticsCard
                title={metric.name}
                icon={metric.icon}
                value={metric.value}
                changeText={metric.changeText}
                changeColor={metric.changeColor}
                changeValence={metric.changeValence}
              />
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {metrics.map((metric) => (
            <TabPanel key={metric.name}>
              <Box height="500px" mt="10">
                <MetricLineChart metric={metric} />
              </Box>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

const MetricLineChart: React.FC<{ metric: any }> = ({ metric }) => {
  const lightGreen = "#82ca9d";

  const renderColorfulLegendText = (value: string, entry: any): ReactNode => {
    const renderRecommended =
      metric.name === "Questions Asked"
        ? value === "secondary"
        : value === "primary";
    const { color } = entry;
    return (
      <>
        <span style={{ color }}>{getKeyDisplayName(value, metric.name)}</span>
        &nbsp;
        {renderRecommended && (
          <>
            <Box
              display="inline-block"
              w="3"
              h="3"
              mt="2px"
              bg={lightGreen}
              ml="3"
              mr="2"
            />
            <span style={{ color: lightGreen }}>
              Recommended{" "}
              {metric.name === "Questions Asked" ? "open-ended questions" : ""}
            </span>
          </>
        )}
      </>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={metric.data}
        margin={{
          top: 10,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis tickFormatter={metric.format} />
        <Tooltip
          formatter={(value: any, name: string) => {
            return [
              `${value}${metric.units}`,
              getKeyDisplayName(name, metric.name),
            ];
          }}
        />
        <Legend formatter={renderColorfulLegendText} />
        <Line
          type="monotone"
          dataKey="primary"
          stroke="#2398FB"
          strokeWidth={3}
          activeDot={{ r: 8 }}
        />
        {metric.name === "Questions Asked" && (
          <Line type="monotone" dataKey="secondary" stroke="#BD7EBE" />
        )}
        {metric.refAreaValues && (
          <ReferenceArea
            y1={metric.refAreaValues[0]}
            y2={metric.refAreaValues[1]}
            stroke={lightGreen}
            strokeOpacity={0.8}
            fill={lightGreen}
            fillOpacity={0.2}
            ifOverflow="extendDomain"
          />
        )}
        {metric.refLineValue && (
          <ReferenceLine
            y={metric.refLineValue}
            label="Recommended open ended"
            stroke={lightGreen}
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

export type MyAnalyticsCardProps = {
  title: string;
  icon: IconType;
  value: string;
  changeValence: string;
  changeColor: string;
  changeText: string;
};

const MyAnalyticsCard: React.FC<MyAnalyticsCardProps> = ({
  title,
  icon,
  value,
  changeColor,
  changeValence,
  changeText,
}) => {
  const bg =
    "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(245, 246, 247, 0.2) 100%), rgba(255, 255, 255, 0.8);";
  const boxShadow = "0 0 4px rgba(0, 0, 0, 0.1);";
  return (
    <Flex
      flexDirection="column"
      width="168px"
      flex="0 0 168px"
      borderRadius="base"
      bg={bg}
      boxShadow={boxShadow}
      p="2"
      pt="4"
    >
      {/* Header */}
      <Flex color="gray.800" justify="start" position="relative" mx="2" mb="1">
        <Text size="sm" fontWeight="700">
          {title}
        </Text>
      </Flex>
      {/* Content */}
      <Flex
        mx="2"
        my="2"
        align="center"
        justify="start"
        fontSize="xl"
        color="gray.800"
      >
        <Icon as={icon} mr="1" />
        <Box>{value}</Box>
      </Flex>
      <Flex align="center" fontWeight="normal" fontSize="xs">
        <Icon
          as={changeValence === "up" ? HiArrowUp : HiArrowDown}
          mr="1"
          color={changeColor}
        />{" "}
        {changeText}
      </Flex>
    </Flex>
  );
};

const Tips: React.FC<{ children: any }> = ({ children }) => {
  const bg =
    "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(245, 246, 247, 0.2) 100%), rgba(255, 255, 255, 0.8);";
  const boxShadow = "0 0 4px rgba(0, 0, 0, 0.1);";
  return (
    <Box
      width="100%"
      bg={bg}
      boxShadow={boxShadow}
      p="4"
      mb="3"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="base"
      fontSize="sm"
    >
      {children}
    </Box>
  );
};

const TipTag: React.FC<{ children: any }> = ({ children }) => {
  return (
    <Tag
      as={Box}
      position="relative"
      size="sm"
      textTransform="uppercase"
      bg="gray.100"
      color="#505863"
      mr="2"
      px="1"
      py="2px"
      minHeight="default"
      fontSize="9px"
      borderRadius="2px"
      mt="1"
    >
      {children}
    </Tag>
  );
};

export default AnalyticsMyAnalytics;
