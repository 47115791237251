import { Box, Button, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import {
  CenteredCardLayout,
  LoadingIndicator,
  useToast,
} from "../../../components";
import { usePageTracker } from "../../../utils/googleAnalytics";
import {
  useOrganizationNameQuery,
  useSendCandidateEmailVerificationMutation,
} from "../../graphql";
import VerifyEmailForm from "./VerifyEmailForm";

const VerifyEmailPage: React.FC = () => {
  usePageTracker("candidate_email_verification");
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const defaultEmail = searchParams.get("email");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [email, setEmail] = useState<string>(defaultEmail ?? "");
  const { orgId } = useParams() as { orgId: string };
  const state = (location?.state ?? {}) as { error?: Error };
  const { error: locationError } = state;

  const {
    data,
    loading: orgNameLoading,
    error: orgNameError,
  } = useOrganizationNameQuery({
    variables: { orgIdSlug: orgId },
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
  });

  const onResendVerification = (): void => {
    setShowConfirmation(false);
  };

  const [sendCandidateEmail, { loading }] =
    useSendCandidateEmailVerificationMutation({
      onCompleted: ({ sendCandidateEmailVerification }) => {
        if (sendCandidateEmailVerification.success) {
          setShowConfirmation(true);
        }
      },
      onError: (err) => {
        toast({
          title: "Error",
          description: err.message,
          status: "error",
        });
      },
    });

  useEffect(() => {
    if (locationError) {
      toast({
        title: "Error",
        description: locationError.message,
        status: "error",
      });
      navigate(location, { state: {}, replace: true });
    }
  }, []);

  if (orgNameError) {
    if (
      orgNameError.graphQLErrors.some(
        (e: any) => e.extensions?.code === "NOT_FOUND"
      )
    ) {
      return <Navigate to="/not-found" />;
    }
    return null;
  }

  if (orgNameLoading) {
    return (
      <CenteredCardLayout>
        <Box maxW="600px">
          <LoadingIndicator />
        </Box>
      </CenteredCardLayout>
    );
  }

  return (
    <CenteredCardLayout>
      <Box maxW="600px">
        {showConfirmation ? (
          <>
            <Heading as="h3" mb="6" fontSize="xl">
              Verification email sent
            </Heading>
            <Text mb="4">
              An email was sent to {email}. Please remember to check your spam
              and promotional folders.
            </Text>
            <Button
              variant="outline"
              isLoading={loading}
              onClick={onResendVerification}
            >
              Go back
            </Button>
          </>
        ) : (
          <>
            <img src="/static/images/videocam-off-sharp.svg" width="45px" />
            <Heading as="h3" mt="8" mb="6" fontSize="xl">
              Opt-out of interview recordings with{" "}
              {data?.organizationName?.name}.
            </Heading>
            <Text mb="8">
              Enter your email below. If we recognize your email address, we
              will send you a verification email with a link to confirm your
              opt-out.
            </Text>
            <VerifyEmailForm
              email={email}
              isLoading={loading}
              onSubmit={(email) => {
                setEmail(email);
                sendCandidateEmail({ variables: { orgId, email } });
              }}
            />
          </>
        )}
      </Box>
    </CenteredCardLayout>
  );
};

export default VerifyEmailPage;
