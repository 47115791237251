import {
  Button,
  ButtonProps,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { IoArrowRedoSharp } from "react-icons/io5";

import { AsyncModal } from "../../../../components";
import { ClipFragment, ClipListItemFragment, Maybe } from "../../../graphql";
import { ShareModal, useClipShareModal } from "../../ShareModal";

type ShareClipButtonProps = {
  clip: ClipFragment | ClipListItemFragment;
  buttonStyle?: "button" | "menuItem";
  isModalOpen?: boolean;
  onCloseModal(): void;
  onOpenModal(): void;
  positionId?: Maybe<string> | undefined;
} & ButtonProps;

const ShareClipButton: React.FC<ShareClipButtonProps> = ({
  clip,
  buttonStyle = "button",
  isModalOpen,
  onCloseModal,
  onOpenModal,
  positionId,
  ...buttonProps
}) => {
  const close = (): void => {
    onCloseModal();
  };

  const open = (): void => {
    onOpenModal();
  };

  const { onOpen, onClose, isOpen } = useDisclosure({
    isOpen: isModalOpen,
    onClose: close,
    onOpen: open,
  });

  const { getClipShareData, loading, hasSelection, ...shareModalProps } =
    useClipShareModal({ clip, onClose });

  useEffect(() => {
    if (isOpen) {
      getClipShareData();
    }
  }, [isOpen]);

  const trigger =
    buttonStyle === "menuItem" ? (
      <Flex
        flexFlow="row nowrap"
        alignItems="center"
        px="3"
        width="full"
        onClick={onOpen}
      >
        <IoArrowRedoSharp size="16px" />
        <Text ml="3" fontWeight="500" color="gray.900">
          Share Clip
        </Text>
      </Flex>
    ) : (
      <Button
        data-testid="share-button"
        size="sm"
        leftIcon={<IoArrowRedoSharp />}
        onClick={onOpen}
        {...buttonProps}
      >
        Share clip
      </Button>
    );

  return (
    <>
      {trigger}
      <AsyncModal
        isOpen={isOpen}
        onClose={onClose}
        loading={loading}
        scrollBehavior={hasSelection ? "outside" : "inside"}
      >
        <ShareModal
          callId={clip.callId}
          clipId={clip.id}
          onClose={onClose}
          positionId={positionId}
          {...shareModalProps}
        />
      </AsyncModal>
    </>
  );
};

export default ShareClipButton;
