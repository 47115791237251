import {
  AspectRatio,
  Box,
  Flex,
  FlexProps,
  Icon,
  Image,
  StyleProps,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { HiArrowRight, HiOutlineCalendar } from "react-icons/hi";
import { IoCall, IoCut, IoHourglassOutline } from "react-icons/io5";

import { AudioImage, useTheme } from "../../../components";
import VideoCameraDisabled from "../../../components/Icons/VideoCameraDisabled";
import { formatDuration } from "../../../utils/datetime";
import { Maybe } from "../../graphql";
import MonospacedText from "../MonospacedText";
import { ThumbnailSizes } from "../Thumbnail/utils";

const ASPECT_RATIO = 16 / 9;

const OVERLAY_STYLES: FlexProps = {
  position: "absolute",
  left: "0",
  bottom: "0",
  right: "0",
  top: "0",
  width: "100%",
  height: "100%",
  color: "white",
  alignItems: "center",
  justifyContent: "center",
  direction: "column",
};

export type ThumbnailStates =
  | "processing"
  | "inProgress"
  | "missing"
  | undefined;

const Thumbnail: React.FC<{
  size?: string;
  width?: number;
  mr?: number;
  imageUrl?: Maybe<string>;
  isPlayable?: boolean;
  audioOnly?: boolean;
  placeholder?: ThumbnailStates;
  time?: Maybe<number>;
  jumpTo?: boolean;
  onClick?: () => void;
  fullVideoVariant?: boolean;
  timeFontSize?: string | Array<string | null>;
  isClip?: boolean;
  isDisabled?: boolean;
  noPlayIcon?: boolean;
  aspectRatio?: number;
  borderRadius?: StyleProps["borderRadius"];
  showUpcomingOverlay?: boolean;
}> = ({
  size = "auto",
  width,
  mr,
  imageUrl,
  isPlayable = "true",
  audioOnly,
  placeholder = "missing",
  time,
  onClick,
  fullVideoVariant,
  timeFontSize = "xl",
  isClip = false,
  isDisabled = false,
  borderRadius,
  aspectRatio = ASPECT_RATIO,
  showUpcomingOverlay,
}) => {
  const { overlays } = useTheme();
  const [thumbnailSize, setThumbnailSize] = useState(ThumbnailSizes[size]);
  useEffect(() => {
    if (width) setThumbnailSize({ ...thumbnailSize, width: `${width}px` });
  }, [width]);

  const {
    isOpen: isHovering,
    onOpen: onMouseEnter,
    onClose: onMouseLeave,
  } = useDisclosure();

  const placeholders = {
    processing: {
      icon: IoHourglassOutline,
      text: "Processing recording..",
      backgroundImage: "/static/images/processing.png",
    },
    missing: {
      icon: VideoCameraDisabled,
      text: "No Recording",
      backgroundImage: "/static/images/no-recording.png",
    },
    inProgress: {
      icon: IoCall,
      text: "In Progress",
      backgroundImage: "/static/images/rectangle.png",
    },
  };

  const placeholderContent = (
    <Flex
      w="100%"
      h="100%"
      direction="column"
      alignItems="center"
      justifyContent="center"
      color="white"
      backgroundImage={placeholders[placeholder].backgroundImage}
      backgroundSize="cover"
      data-testid="thumbnail-placeholder"
    >
      <Icon as={placeholders[placeholder].icon} h={12} w={12} mt="1" mb="2" />
      <Text as="span" fontSize="xs" lineHeight="150%" fontWeight="bold">
        {placeholders[placeholder].text}
      </Text>
    </Flex>
  );

  const mediaContent = (
    <Box
      height="100%"
      position="relative"
      bg="gray.800"
      data-testid="thumbnail-media"
    >
      {audioOnly && (
        <>
          <Flex
            {...OVERLAY_STYLES}
            backgroundImage="/static/images/rectangle.png"
            backgroundSize="cover"
          />
          <Flex {...OVERLAY_STYLES}>
            <AudioImage />
          </Flex>
        </>
      )}
      {!audioOnly && imageUrl && (
        <Image src={imageUrl} height="100%" fit="cover" />
      )}
      {!isDisabled && (
        <Flex
          {...OVERLAY_STYLES}
          bg={isHovering ? overlays.lightHover : overlays.light}
          transition="background .2s ease-out;"
        >
          {time && (
            <Flex
              direction="row"
              alignItems="center"
              justifyContent="center"
              px="1.5"
              py="1"
              borderRadius="4"
              bg="rgba(0, 0, 0, 0.64)"
              color="white"
              fontSize={timeFontSize}
              fontWeight="500"
              position="absolute"
              bottom={2}
              right={2}
            >
              {!!time && (
                <Box fontSize="sm">
                  <MonospacedText text={formatDuration(time, false)} />
                </Box>
              )}
            </Flex>
          )}
        </Flex>
      )}
      {isClip && !fullVideoVariant && (
        <Flex
          alignItems="center"
          bg="gray.50"
          borderRadius="28px"
          color="gray.600"
          fontSize={thumbnailSize.durationFontSize}
          fontWeight="bold"
          h={thumbnailSize.durationHeight}
          justifyContent="center"
          lineHeight="0"
          ml="auto"
          position="absolute"
          px={thumbnailSize.durationPadding}
          py="0.5"
          right={thumbnailSize.durationInset}
          top={thumbnailSize.durationInset}
        >
          {size !== "sm" && <IoCut size="12" />}
          <Text
            fontSize={size === "sm" ? "xs" : "sm"}
            ml={size === "sm" ? 0 : 1}
          >
            Clip
          </Text>
        </Flex>
      )}
    </Box>
  );

  return (
    <Box
      position="relative"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-testid="thumbnail"
    >
      {isHovering && !showUpcomingOverlay && (
        <>
          <Flex
            {...OVERLAY_STYLES}
            opacity="48%"
            background="blue.600"
            zIndex="3"
            borderRadius={thumbnailSize.borderRadius}
          />
          <Flex {...OVERLAY_STYLES} zIndex="4">
            <Flex width="100%" justifyContent="center">
              <Icon as={HiArrowRight} h={12} w={12} />
            </Flex>
            <Flex
              width="100%"
              justifyContent="center"
              fontWeight="500"
              fontSize="12px"
            >
              Go to interview
            </Flex>
          </Flex>
        </>
      )}
      {showUpcomingOverlay && (
        <Flex {...OVERLAY_STYLES} zIndex="3">
          <Flex width="100%" justifyContent="center">
            <Icon as={HiOutlineCalendar} h={12} w={12} />
          </Flex>
          <Flex width="100%" justifyContent="center" fontSize="12px" mt={2}>
            Upcoming
          </Flex>
        </Flex>
      )}
      <AspectRatio
        flex="0 0 auto"
        mr={mr}
        ratio={aspectRatio}
        width={thumbnailSize.width}
        maxW={thumbnailSize.width}
        borderRadius={borderRadius ?? thumbnailSize.borderRadius}
        overflow="hidden"
        position="relative"
        onClick={onClick}
        cursor="pointer"
      >
        {isPlayable ? mediaContent : placeholderContent}
      </AspectRatio>
    </Box>
  );
};

export default Thumbnail;
