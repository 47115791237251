import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { connectRange } from "react-instantsearch-dom";

import { useSendGAEvent } from "../../utils/googleAnalytics";
import RangeSliderBase from "../RangeSlider";

interface RangeSliderProps {
  attribute: string;
  formatRange?: (min: number, max: number) => string;
  min: number;
  max: number;
  currentRefinement: { min: number; max: number };
  canRefine: boolean;
  refine: (range: { min: number; max: number }) => void;
}

const RangeSlider: React.FC<RangeSliderProps> = ({
  attribute,
  formatRange = (min: number, max: number) =>
    min !== max ? `${min}-${max}` : `${min}`,
  min,
  max,
  currentRefinement,
  canRefine,
  refine,
}) => {
  const [range, setRange] = useState<
    { min: number; max: number } | undefined
  >();
  useEffect(() => {
    const newRange = {
      min: currentRefinement.min ?? min,
      max: currentRefinement.max ?? max,
    };
    if (newRange.min !== undefined && newRange.max !== undefined) {
      setRange(newRange);
    }
  }, [min, max, currentRefinement]);
  const sendGAEvent = useSendGAEvent();

  if (!range) {
    return null;
  }

  const onChange = (min: number, max: number): void => {
    setRange({ min, max });
  };

  const onAfterChange = (min: number, max: number): void => {
    sendGAEvent("filter", "search", attribute);
    refine({ min, max });
  };

  const noRange = min === undefined && max === undefined;

  return (
    <Box px={6}>
      <Box textAlign="center" fontSize="sm" height="21px">
        {!noRange && formatRange(range.min, range.max)}
      </Box>
      <RangeSliderBase
        min={min ?? 0}
        max={max ?? 100}
        values={noRange ? { min: 0, max: 100 } : range}
        onChange={onChange}
        onAfterChange={onAfterChange}
        isDisabled={!canRefine || noRange || min === max}
      />
    </Box>
  );
};

export default connectRange(RangeSlider);
