import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Icon,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Text,
  Textarea,
} from "@chakra-ui/react";
import invariant from "invariant";
import LogRocket from "logrocket";
import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { IoInformationCircle } from "react-icons/io5";
import Select, { components, ControlProps } from "react-select";

import useSelectTheme from "../../../hooks/useSelectTheme";
import { daysRemaining } from "../../../utils/external-shares";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import CopyCallLinkButton from "./CopyCallLinkButton";
import ExternalShareModal from "./ExternalShareModal";
import SharedWithStatic from "./StaticMessage";
import {
  ExternalShare,
  InternalUser,
  isExternalShare,
  isInternalShare,
  Share,
  ShareModalProps,
  ShareUser,
} from "./types";
import UserShareItem from "./UserShareItem";
import VisibilitySelect from "./VisibilitySelect";

type ShareOption = { label: string; value: ShareUser };

const Control: React.FC<ControlProps<ShareOption, true>> = ({
  children,
  ...props
}) => {
  const { hasValue } = props;

  return (
    <components.Control {...props}>
      {!hasValue && (
        <Icon as={FiSearch} w="5" h="5" color="gray.500" ml="4" mr="1" />
      )}
      {children}
    </components.Control>
  );
};

const ShareModal: React.FC<ShareModalProps> = ({
  trainingProgramCount = 0,
  removeExternalShare,
  renewExternalShare,
  onChangeVisibility,
  defaultTitle,
  includeTitleInput = false,
  canShareExternal = false,
  visibilityLevels,
  onShareExternal,
  optionsLoading,
  shareableUsers = [],
  maxSelection = 10,
  shareLoading,
  onSelection: onSelectionProp,
  removeShare,
  sharedWith: sharedWithProp = [],
  visibleTo: visibleToData,
  visibility,
  positionId,
  callId,
  clipId,
  canShare,
  onShare,
  onClose,
  modalTitle = "Share Recording",
  externalShareDuration,
  sharedWithStatic,
  copyLink,
  infoText,
}) => {
  const [selectTheme, selectStyles] = useSelectTheme();
  const [selectedUsers, setSelectedUsers] = useState<InternalUser[]>([]);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState(defaultTitle || "");
  const [isExternal, setIsExternal] = useState(false);
  const [externalShareToRemove, setExternalShareToRemove] =
    useState<ExternalShare | null>(null);
  const externalSharingFeatureEnabled = useFeatureFlag("external_sharing");

  const hasSelection = !!selectedUsers?.length;
  const showExternalShare =
    !hasSelection && canShareExternal && externalSharingFeatureEnabled;

  const onSelection = (selection: InternalUser[]): void => {
    setSelectedUsers(selection);
    onSelectionProp?.(selection);
  };

  const cancel = (): void => {
    onSelection([]);
    setMessage("");
    setIsExternal(false);
  };

  const share = (): void => {
    onShare(
      selectedUsers.map(({ id }) => id),
      message,
      title
    );
  };

  const shareExternal = (
    emails: string[],
    message: string,
    title: string
  ): void => {
    onShareExternal?.(emails, message, title);
    LogRocket.track("external-share-send");
  };

  const getUserValue = (user: InternalUser): ShareOption => ({
    label: `${user.fullName} - ${user.email}`,
    value: user,
  });

  const primaryText = (share: Share): string => {
    if (isInternalShare(share)) return share.sharedTo.fullName;
    if (isExternalShare(share)) return share.sharedTo.email;
    return "";
  };

  const secondaryText = (share: Share): string => {
    if (isInternalShare(share)) return "Invited";
    if (isExternalShare(share)) return daysRemaining(share.daysRemaining);
    return "";
  };

  const isExpired = (share: Share): boolean =>
    isExternalShare(share) && share.isExpired;

  const totalVisibleTo = visibleToData ? visibleToData.resultCount : 0;
  const visibleTo = visibleToData ? visibleToData.results : [];

  if (externalShareToRemove) {
    return (
      <>
        <ModalHeader
          color="gray.900"
          fontWeight="500"
          fontSize="lg"
          borderBottom="1px solid"
          borderBottomColor="gray.100"
        >
          Remove Access to this Recording
        </ModalHeader>
        <ModalCloseButton top="6" right="5" />
        <ModalBody pt="8" pb="0">
          <Text color="gray.700" fontWeight="400" fontSize="sm">
            Are you sure you want to remove access to this recording for{" "}
            {externalShareToRemove.sharedTo.email}?
          </Text>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup size="sm" spacing="2">
            <Button
              variant="ghost"
              fontWeight="500"
              onClick={() => setExternalShareToRemove(null)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="danger"
              fontSize="sm"
              px="3"
              py="3"
              fontWeight="500"
              onClick={async () => {
                await removeExternalShare?.(externalShareToRemove);
                setExternalShareToRemove(null);
              }}
            >
              Remove
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </>
    );
  }

  const showVisibility = visibility && visibilityLevels && onChangeVisibility;
  const sharedWith = canShareExternal
    ? sharedWithProp
    : sharedWithProp.filter(isInternalShare);
  const hasBody = !!(showVisibility || sharedWith.length || visibleTo.length);

  return isExternal ? (
    <ExternalShareModal
      onCancel={cancel}
      onShare={shareExternal}
      shareLoading={shareLoading}
      includeTitleInput={includeTitleInput}
      modalTitle={`${modalTitle} Outside Your Org`}
      externalShareDuration={externalShareDuration}
    />
  ) : (
    <>
      <ModalHeader
        p="6"
        borderBottom="1px solid"
        borderColor="gray.100"
        fontSize="xl"
        fontWeight="medium"
      >
        {modalTitle}
      </ModalHeader>
      <ModalCloseButton top="6" right="5" />
      <Box mx="8" mt="6" mb="0">
        {!canShare ? (
          <>
            <Flex
              alignItems="center"
              bg="gray.50"
              borderColor="gray.100"
              borderWidth="1px"
              borderRadius="base"
              fontSize="sm"
              pl="4"
              py="10px"
              pr="3"
              mb="8"
            >
              <Icon
                as={IoInformationCircle}
                color="orange.400"
                w={5}
                h={5}
                mr="14px"
              />
              You can share this recording link only with the users below.
              Contact a site admin to change your permission level.
            </Flex>
            <Divider />
          </>
        ) : (
          <>
            {/* TODO: Use sparkly new select from Trainees */}
            <Select
              aria-label="share-select-input"
              data-testid="share-select"
              theme={selectTheme}
              styles={selectStyles}
              isClearable
              components={{
                DropdownIndicator: null,
                Control,
              }}
              isMulti
              placeholder="Invite a colleague to view"
              autoFocus
              isLoading={optionsLoading}
              value={selectedUsers.map(getUserValue)}
              options={
                selectedUsers.length < maxSelection
                  ? shareableUsers.map(getUserValue)
                  : []
              }
              onChange={(selectedOptions) => {
                if (!selectedOptions) {
                  onSelection([]);
                  return;
                }
                invariant(
                  Array.isArray(selectedOptions),
                  "Invalid selectedOption"
                );
                onSelection(selectedOptions.map(({ value }) => value));
              }}
            />
            {showExternalShare && (
              <>
                <Button
                  mt="1"
                  ml="2"
                  mb="3"
                  py="2.5"
                  px="3"
                  fontSize="sm"
                  fontWeight="medium"
                  variant="white"
                  onClick={() => {
                    setIsExternal(true);
                    LogRocket.track("external-share-modal-opened");
                  }}
                  textTransform="none"
                >
                  Or share outside your org
                </Button>
                {hasBody && <Divider />}
              </>
            )}
          </>
        )}
      </Box>

      <ModalBody
        pt="5"
        pr="7"
        pb={hasBody ? 2 : 0}
        mr="1"
        overflowY="auto"
        maxH="80"
      >
        {infoText && (
          <Text fontSize="sm" color="gray.500" mb="4">
            {infoText}
          </Text>
        )}
        {hasSelection ? (
          <>
            {includeTitleInput && (
              <Input
                placeholder="Title (optional)"
                value={title}
                data-testid="share-title"
                onChange={(e) => setTitle(e.target.value)}
              />
            )}

            <Textarea
              data-testid="share-message"
              mt="5"
              height="150px"
              placeholder="Add a message (optional)"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </>
        ) : (
          hasBody && (
            <>
              <Text fontWeight="medium" fontSize="sm" mb="4">
                {showVisibility || sharedWithStatic
                  ? "Who can view it?"
                  : "Shared with"}
              </Text>

              {showVisibility && canShare && (
                <VisibilitySelect
                  onChangeVisibility={onChangeVisibility}
                  visibilityLevels={visibilityLevels}
                  visibility={visibility}
                />
              )}

              {sharedWith.map((share, index) => (
                <UserShareItem
                  key={share.sharedTo?.id}
                  user={share.sharedTo}
                  primaryText={primaryText(share)}
                  secondaryText={secondaryText(share)}
                  renewExternalShare={
                    isExpired(share)
                      ? () => renewExternalShare?.(share as ExternalShare)
                      : undefined
                  }
                  removeShare={() => {
                    if (isExternalShare(share)) {
                      setExternalShareToRemove(share);
                    } else if (isInternalShare(share)) {
                      removeShare?.(share);
                    }
                  }}
                  index={index}
                />
              ))}

              {visibleTo.map(({ role, user }, index) => (
                <UserShareItem
                  key={user.id}
                  user={user}
                  primaryText={user.fullName}
                  secondaryText={role}
                  index={sharedWith.length + index}
                />
              ))}

              {showVisibility && !sharedWithStatic && (
                <SharedWithStatic
                  visibility={visibility}
                  trainingProgramCount={trainingProgramCount}
                  numNotShown={totalVisibleTo - visibleTo.length}
                  positionId={positionId}
                />
              )}

              {sharedWithStatic}
            </>
          )
        )}
      </ModalBody>

      {!hasSelection && <Divider mx="8" w="auto" />}

      <ModalFooter py="4">
        {hasSelection ? (
          <ButtonGroup size="sm" spacing="2">
            <Button variant="outline" onClick={cancel}>
              Cancel
            </Button>
            <Button
              type="submit"
              data-testid="share-submit-button"
              isLoading={shareLoading}
              onClick={share}
            >
              Share
            </Button>
          </ButtonGroup>
        ) : (
          <>
            <Button
              variant="white"
              fontSize="sm"
              fontWeight="medium"
              onClick={onClose}
              mr="auto"
            >
              Cancel
            </Button>
            {callId ? (
              <CopyCallLinkButton
                callId={callId}
                clipId={clipId}
                ml="auto"
                mr="6"
              />
            ) : (
              copyLink
            )}

            <Button px="5" fontWeight="medium" fontSize="sm" onClick={onClose}>
              Done
            </Button>
          </>
        )}
      </ModalFooter>
    </>
  );
};

export default ShareModal;
