import { DataProxy } from "@apollo/client/cache/core/types/DataProxy";

import {
  CallGuideDocument,
  CallGuideListItemFragment,
  CallGuideQuery,
  CurrentUserCallGuidesDocument,
  CurrentUserCallGuidesQuery,
  CurrentUserSharedCallGuidesDocument,
  CurrentUserSharedCallGuidesQuery,
  PositionDocument,
  PositionQuery,
  SharedCallGuidesDocument,
  SharedCallGuidesQuery,
} from "../..";

export const updateCurrentUserQuery = (
  cache: DataProxy,
  callGuide: CallGuideListItemFragment
): void => {
  try {
    const cachedUserCallGuides = cache.readQuery<CurrentUserCallGuidesQuery>({
      query: CurrentUserCallGuidesDocument,
    });
    if (cachedUserCallGuides?.currentUser?.callGuides) {
      const updatedUserCallGuides = {
        ...cachedUserCallGuides,
        currentUser: {
          ...cachedUserCallGuides.currentUser,
          callGuides: [
            ...cachedUserCallGuides.currentUser.callGuides,
            callGuide,
          ],
        },
      };
      cache.writeQuery<CurrentUserCallGuidesQuery>({
        query: CurrentUserCallGuidesDocument,
        data: updatedUserCallGuides,
      });
    }
  } catch {
    // ignore Apollo cache misses
  }
};

export const removeFromCurrentUserQuery = (
  cache: DataProxy,
  callGuideId: string
): void => {
  try {
    const cachedUserCallGuides = cache.readQuery<CurrentUserCallGuidesQuery>({
      query: CurrentUserCallGuidesDocument,
    });
    if (cachedUserCallGuides?.currentUser?.callGuides) {
      const updatedUserCallGuides = {
        ...cachedUserCallGuides,
        currentUser: {
          ...cachedUserCallGuides.currentUser,
          callGuides: cachedUserCallGuides.currentUser.callGuides.filter(
            (guide) => guide.id !== callGuideId
          ),
        },
      };

      cache.writeQuery<CurrentUserCallGuidesQuery>({
        query: CurrentUserCallGuidesDocument,
        data: updatedUserCallGuides,
      });
    }
  } catch {
    // ignore Apollo cache misses
  }
};

export const updateSharedCallGuidesQuery = (
  cache: DataProxy,
  callGuide: CallGuideListItemFragment
): void => {
  try {
    const cachedCallGuides = cache.readQuery<SharedCallGuidesQuery>({
      query: SharedCallGuidesDocument,
    });
    if (!cachedCallGuides?.currentUser) {
      return;
    }
    const updatedCallGuides = {
      ...cachedCallGuides,
      currentUser: {
        ...cachedCallGuides.currentUser,
        organization: {
          ...cachedCallGuides.currentUser.organization,
          callGuides: [
            callGuide,
            ...cachedCallGuides.currentUser.organization.callGuides,
          ],
        },
      },
    };
    if (
      cachedCallGuides.currentUser.organization.callGuides.findIndex(
        (guide) => guide.id === callGuide.id
      ) === -1
    )
      cache.writeQuery<SharedCallGuidesQuery>({
        query: SharedCallGuidesDocument,
        data: updatedCallGuides,
      });
  } catch {
    // ignore Apollo cache misses
  }
};

export const updateCurrentUserSharedCallGuidesQuery = (
  cache: DataProxy,
  callGuide: CallGuideListItemFragment
): void => {
  try {
    const cachedCallGuides = cache.readQuery<CurrentUserSharedCallGuidesQuery>({
      query: CurrentUserSharedCallGuidesDocument,
    });
    if (!cachedCallGuides?.currentUser) {
      return;
    }
    const updatedCallGuides = {
      ...cachedCallGuides,
      currentUser: {
        ...cachedCallGuides.currentUser,
        callGuides: [
          callGuide,
          ...cachedCallGuides.currentUser.sharedCallGuides,
        ],
      },
    };
    if (
      cachedCallGuides.currentUser.sharedCallGuides.findIndex(
        (guide) => guide.id === callGuide.id
      ) === -1
    )
      cache.writeQuery<CurrentUserSharedCallGuidesQuery>({
        query: CurrentUserSharedCallGuidesDocument,
        data: updatedCallGuides,
      });
  } catch {
    // ignore Apollo cache misses
  }
};

export const removeFromSharedCallGuidesQuery = (
  cache: DataProxy,
  callGuideId: string
): void => {
  try {
    const cachedCallGuides = cache.readQuery<SharedCallGuidesQuery>({
      query: SharedCallGuidesDocument,
    });
    if (!cachedCallGuides?.currentUser) {
      return;
    }
    const updatedCallGuides = {
      ...cachedCallGuides,
      currentUser: {
        ...cachedCallGuides.currentUser,
        organization: {
          ...cachedCallGuides.currentUser.organization,
          callGuides:
            cachedCallGuides.currentUser.organization.callGuides.filter(
              (guide) => guide.id !== callGuideId
            ),
        },
      },
    };
    cache.writeQuery<SharedCallGuidesQuery>({
      query: SharedCallGuidesDocument,
      data: updatedCallGuides,
    });
  } catch {
    // ignore Apollo cache misses
  }
};

export const removeFromCurrentUserSharedCallGuidesQuery = (
  cache: DataProxy,
  callGuideId: string
): void => {
  try {
    const cachedCallGuides = cache.readQuery<CurrentUserSharedCallGuidesQuery>({
      query: CurrentUserSharedCallGuidesDocument,
    });
    if (!cachedCallGuides?.currentUser) {
      return;
    }
    const updatedCallGuides = {
      ...cachedCallGuides,
      currentUser: {
        ...cachedCallGuides.currentUser,
        callGuides: cachedCallGuides.currentUser.sharedCallGuides.filter(
          (guide) => guide.id !== callGuideId
        ),
      },
    };
    cache.writeQuery<CurrentUserSharedCallGuidesQuery>({
      query: CurrentUserSharedCallGuidesDocument,
      data: updatedCallGuides,
    });
  } catch {
    // ignore Apollo cache misses
  }
};

export const updatePositionQuery = (
  cache: DataProxy,
  callGuide: CallGuideListItemFragment,
  positionId: string
): void => {
  try {
    const cachedPosition = cache.readQuery<PositionQuery>({
      query: PositionDocument,
      variables: { id: positionId },
    });
    if (cachedPosition?.position) {
      const updatedPosition = {
        ...cachedPosition,
        position: {
          ...cachedPosition.position,
          callGuides: [callGuide, ...cachedPosition.position.callGuides],
        },
      };
      cache.writeQuery<PositionQuery>({
        query: PositionDocument,
        data: updatedPosition,
        variables: { id: positionId },
      });
    }
  } catch {
    // ignore Apollo cache misses
  }
};

export const removeFromPositionQuery = (
  cache: DataProxy,
  callGuideId: string,
  positionId: string
): void => {
  try {
    const cachedPosition = cache.readQuery<PositionQuery>({
      query: PositionDocument,
      variables: { id: positionId },
    });
    if (cachedPosition?.position) {
      const updatedPosition = {
        ...cachedPosition,
        position: {
          ...cachedPosition.position,
          callGuides: cachedPosition.position.callGuides.filter(
            (guide) => guide.id !== callGuideId
          ),
        },
      };
      cache.writeQuery<PositionQuery>({
        query: PositionDocument,
        data: updatedPosition,
        variables: { id: positionId },
      });
    }
  } catch {
    // ignore Apollo cache misses
  }
};

export const removeFromCallGuideQuery = (
  cache: DataProxy,
  callGuideId: string
): void => {
  try {
    const cachedGuide = cache.readQuery<CallGuideQuery>({
      query: CallGuideDocument,
      variables: { id: callGuideId },
    });
    if (cachedGuide) {
      cache.writeQuery<CallGuideQuery>({
        query: CallGuideDocument,
        data: { callGuide: null },
        variables: { id: callGuideId },
      });
    }
  } catch {
    // ignore Apollo cache misses
  }
};
