import {
  Button,
  Checkbox,
  Code,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { useToast } from "../../../components";
import {
  CurrentUserFragment,
  LeverSetup,
  useSetupLeverMutation,
} from "../../graphql";

interface FormValues {
  enabled: boolean;
  apiKey: string;
  signatureTokenObj: string | null;
  syncCallGuidesFromAts: boolean;
  createSecretNotes: boolean;
}

interface LeverApiKeySetupFormProps {
  currentUser: CurrentUserFragment;
  leverSetupData?: Pick<
    LeverSetup,
    | "enabled"
    | "apiKey"
    | "signatureTokenObj"
    | "syncCallGuidesFromAts"
    | "createSecretNotes"
  > | null;
}

const LeverApiKeySetupForm: React.FC<LeverApiKeySetupFormProps> = ({
  currentUser,
  leverSetupData,
}) => {
  const toast = useToast();

  const [setupLever] = useSetupLeverMutation({
    onError: (err) => {
      toast({
        title: "Error",
        description: `Failed to setup Lever: ${err.message}`,
        status: "error",
      });
    },
    onCompleted: (data) => {
      const settings = data?.setupLever?.leverSetup;
      if (settings?.valid === true) {
        toast({
          title: "Success",
          description: `Lever integration is fully setup.`,
          status: "success",
        });
      } else if (settings?.apiKey && !settings.signatureTokenObj) {
        toast({
          title: "Incomplete",
          description: "Please save webhook signature tokens to finish setup.",
          status: "info",
        });
      }
    },
  });
  const { register, handleSubmit, setValue } = useForm<FormValues>({});

  const onSubmit = handleSubmit((formValues) => {
    let apiKey;
    if (!formValues.apiKey.includes("XXXX")) {
      apiKey = formValues.apiKey;
    }
    let signatureTokenObj;
    if (
      formValues.signatureTokenObj &&
      !formValues.signatureTokenObj.includes("XXXX")
    ) {
      signatureTokenObj = formValues.signatureTokenObj;
    }
    setupLever({
      variables: {
        enabled: formValues.enabled,
        apiKey,
        signatureTokenObj,
        syncCallGuidesFromAts: formValues.syncCallGuidesFromAts,
        createSecretNotes: formValues.createSecretNotes,
      },
    });
  });

  const exampleSignatureTokenObject = JSON.stringify(
    {
      applicationCreated: "[token]",
      interviewCreated: "[token]",
      interviewUpdated: "[token]",
      interviewDeleted: "[token]",
    },
    null,
    4
  );

  useEffect(() => {
    setValue("enabled", leverSetupData?.enabled || false);
    setValue("apiKey", leverSetupData?.apiKey || "");
    setValue("signatureTokenObj", leverSetupData?.signatureTokenObj || "");
    setValue(
      "syncCallGuidesFromAts",
      leverSetupData?.syncCallGuidesFromAts ?? false
    );
    setValue("createSecretNotes", leverSetupData?.createSecretNotes ?? false);
  }, [leverSetupData]);

  return (
    <form onSubmit={onSubmit}>
      <VStack spacing="8" align="start">
        <FormControl>
          <Checkbox
            {...register("enabled")}
            defaultChecked={leverSetupData?.enabled}
          >
            Enabled
          </Checkbox>
        </FormControl>
        <FormControl>
          <Checkbox
            {...register("syncCallGuidesFromAts")}
            defaultChecked={leverSetupData?.syncCallGuidesFromAts}
          >
            Sync Guides
          </Checkbox>
        </FormControl>
        <FormControl>
          <Checkbox
            {...register("createSecretNotes")}
            defaultChecked={leverSetupData?.createSecretNotes}
          >
            Create Secret Notes
          </Checkbox>
          <FormHelperText>
            If checked, all BrightHire notes created in Lever will only be
            visible to Lever users with Sensitive Information Privileges (SIP)
            for postings applied to candidate.
          </FormHelperText>
        </FormControl>
        <FormControl isRequired>
          <FormLabel htmlFor="apiKey">API Key</FormLabel>
          <Input {...register("apiKey")} />
        </FormControl>
        <Stack spacing={2} alignSelf="stretch">
          <Text fontWeight="bold">Webhook URL</Text>
          <FormLabel>
            Please use this URL when setting up the webhooks in Lever
          </FormLabel>
          <Input
            defaultValue={`https://app.brighthire.ai/callback/lever/webhook/${currentUser.organization.id}`}
          />
        </Stack>
        <FormControl>
          <FormLabel htmlFor="signatureTokenObj">
            Webhook Signature Token Object
          </FormLabel>
          <Textarea {...register("signatureTokenObj")} />
          <Code mt={2} whiteSpace="pre-wrap" display="block">
            {exampleSignatureTokenObject}
          </Code>
        </FormControl>
        <Button type="submit">Save</Button>
      </VStack>
    </form>
  );
};

export default LeverApiKeySetupForm;
